import React, { FC } from 'react';
import { SearchableLocation } from 'types/interfaces';

interface Props {
  suggestedItem: SearchableLocation;
  onClick: Function;
}

const ResultCard: FC<Props> = ({ suggestedItem, onClick }) => {
  function onSuggestedItemClicked() {
    onClick(suggestedItem);
  }
  return (
    <div className="global_search__result_card" role="button" onMouseDown={onSuggestedItemClicked}>
      <p className="result_title">{suggestedItem.name}</p>
    </div>
  );
};

export default ResultCard;
