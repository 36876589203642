/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from 'react';
import { COMPLAINCE_GRAPH_COLORS } from '../compainceSIF.utils';

const ChartOptions = (categories?: string[]) => {
  const colors = [
    COMPLAINCE_GRAPH_COLORS.PENDING,
    COMPLAINCE_GRAPH_COLORS.CLOSED,
    COMPLAINCE_GRAPH_COLORS.DELAYED,
  ];
  const options = useMemo(() => {
    return {
      chart: {
        height: 350,
        type: 'line',
        opacity: 1,
        zoom: {
          enabled: false,
        },
        stacked: true,
      },
      stroke: {
        lineCap: 'round',
      },
      plotOptions: {
        bar: {
          columnWidth: '60%',
        },
        fill: {
          opacity: 1,
          colors,
        },
      },
      colors,
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories,
      },
      yaxis: {
        min: 0,
        max: 100,
        decimalsInFloat: 0,
        labels: {
          formatter(val: number) {
            return `${val.toFixed(0)}%`;
          },
        },
        title: {
          text: '% de tickets cerrados',
          rotate: -90,
          offsetX: 0,
          offsetY: 0,
        },
      },
      legend: {
        show: true,
        showForSingleSeries: false,
        markers: {
          fillColors: colors,
        },
      },
    };
  }, [categories, colors]);
  return options;
};

export default ChartOptions;
