import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';
import { useSelector, useDispatch } from 'react-redux';
import selectDriverManagementSystem from 'store/selectors/driverManagementSystem.selectors';
import ChartDropdown from 'components/Dropdown/ChartDropdown';
import SeparatorWithTitle from 'components/Separator/SeparatorWithTitle';
import { setYearsData } from 'store/actions/driverManagementSystem.actions';
import {
  useGetIncidentsByMonthsCategoriesAndTypeLazyQuery,
  useGetIncidentsByTypePerBuLazyQuery,
} from 'generated/graphql';
import LoadingSpinner from 'components/LoadingSpinner';
import IncidentsLineChart from './IncidentsLineChart';
import IncidentsPieChart from './IncidentsPieChart';
import CurrentMonthBarChart from './CurrentMonthBarChart';
import BarChartPerCategory from './BarChartPerCategory';
import TopCdsDMS from './TopCdsDMS';
import VehicleTopOffenders from './VehicleTopOffenders';
import {
  categorTitleByType,
  customDMSDataSelectorByYearMonthTypeBuAndSubType,
  DMSSelectorTypes,
  DMS_CATEGORY,
  DMSDropDowns,
  yearlyPayloadForIncidentsByMonthsCategoriesAndTypeData,
  yearlyPayloadForIncidentsByTypePerBuData,
  DMSBuList,
} from './dmsHelper';
import './DriverManagmentSystemStyles.scss';

const DriverManagmentSystem = () => {
  const dispatch = useDispatch();

  const driverManagementSystemData = useSelector(selectDriverManagementSystem);

  const { t } = useTranslation();

  const getLatestYearsAndCurrentYear = () => {
    const localDate: Date = new Date();
    const baseYear: number = 2021;
    const currentYear: number = localDate.getUTCFullYear();
    const years: any[] = [];

    for (let i = baseYear; i <= currentYear; i++) {
      const currentYearToString: string = i.toString();

      years.push({
        label: currentYearToString,
        slug: currentYearToString,
        year: i,
      });
    }

    return years.reverse();
  };

  const monthArr = [
    t(TRANSLATIONS.JANUARY),
    t(TRANSLATIONS.FEBRUARY),
    t(TRANSLATIONS.MARCH),
    t(TRANSLATIONS.APRIL),
    t(TRANSLATIONS.MAY),
    t(TRANSLATIONS.JUNE),
    t(TRANSLATIONS.JULY),
    t(TRANSLATIONS.AUGUST),
    t(TRANSLATIONS.SEPTEMBER),
    t(TRANSLATIONS.OCTOBER),
    t(TRANSLATIONS.NOVEMBER),
    t(TRANSLATIONS.DECEMBER),
  ];

  const monthOptions: any = monthArr.map((monthSlug, monthIndex) => ({
    slug: monthSlug,
    label: monthSlug.substring(0, 1).toUpperCase() + monthSlug.substring(1),
    month: monthSlug,
    monthNumber: monthIndex + 1,
  }));

  const yearOptions = getLatestYearsAndCurrentYear();

  /**
  TODO: needs to be refactored as soon as we have kashyapsojitra code merge
  * */

  const getCurrentMonth = () => {
    const localDate: Date = new Date();
    const monthIndexToGet: number = localDate.getUTCMonth();

    return monthOptions[monthIndexToGet];
  };

  const getCurrentYear = () => {
    const localDate: Date = new Date();
    const currentYear: number = localDate.getUTCFullYear();
    const yearDataToReturn = yearOptions.find(year => Number(year.label) === currentYear);
    return yearDataToReturn ?? yearOptions[0];
  };

  const [selectedMonth, setSelectedMonth] = useState(getCurrentMonth());
  const [selectedYear, setSelectedYear] = useState(getCurrentYear());
  const [
    isDataLoadingForIncidentsByMonthsCategoriesAndType,
    setIsDataLoadingForIncidentsByMonthsCategoriesAndType,
  ] = useState<boolean>(false);
  const [
    isDataLoadingForIncidentsByTypePerBuData,
    setIsDataLoadingForIncidentsByTypePerBuData,
  ] = useState<boolean>(false);
  const [
    incidentsByMonthsCategoriesAndTypeError,
    setIncidentsByMonthsCategoriesAndTypeError,
  ] = useState<boolean>(false);
  const [
    incidentsByTypePerBuDataError,
    setIncidentsByTypePerBuDataError,
  ] = useState<boolean>(false);
  const [isDropDownOpen, setIsDropDownOpen] = useState<DMSDropDowns | undefined>(undefined);

  const buList = driverManagementSystemData.getIn(
    [selectedYear.year, selectedMonth.label, DMSBuList.buList],
  )?.toJS();

  const [selectedBu, setSelectedBu] = useState<any>(undefined);

  const [
    callGetIncidentsByMonthsCategoriesAndTypeLazyQuery,
    {
      data: incidentsByMonthsCategoriesAndTypeData,
    },
  ] = useGetIncidentsByMonthsCategoriesAndTypeLazyQuery({
    onError: () => {
      setIsDataLoadingForIncidentsByMonthsCategoriesAndType(false);
      setIncidentsByMonthsCategoriesAndTypeError(true);
    },
  });
  const [
    callGetIncidentsByTypePerBuLazyQuery,
    {
      data: incidentsByTypePerBuData,
    },
  ] = useGetIncidentsByTypePerBuLazyQuery({
    onError: () => {
      setIsDataLoadingForIncidentsByTypePerBuData(false);
      setIncidentsByTypePerBuDataError(true);
    },
  });

  const validMonthOptions: any = useMemo(() => {
    const localDate: Date = new Date();
    const currentYear: number = localDate.getUTCFullYear();
    if (selectedYear.year === currentYear) {
      const currentMonthIndex = localDate.getUTCMonth();
      const possibleMonths = monthOptions.slice(0, currentMonthIndex + 1);
      return possibleMonths;
    }
    return monthOptions;
    // eslint-disable-next-line
  }, [selectedYear]);

  const handleDropMonthChange = ({ key }: { key: any }) => {
    const selectedDropDownMonth = validMonthOptions.find(
      (item: { slug: any; }) => item.slug === key,
    );

    setSelectedBu(undefined);
    setSelectedMonth(selectedDropDownMonth || validMonthOptions[0]);
    setIsDropDownOpen(undefined);
    setIncidentsByMonthsCategoriesAndTypeError(false);
    setIncidentsByTypePerBuDataError(false);
    setIsDataLoadingForIncidentsByMonthsCategoriesAndType(true);

    const selectedMonthAndYearPerWeekData = customDMSDataSelectorByYearMonthTypeBuAndSubType(
      driverManagementSystemData,
      selectedYear.year,
      selectedDropDownMonth.label,
      DMSSelectorTypes.perWeekGraph,
    );
    const selectedMonthPerMonthData = customDMSDataSelectorByYearMonthTypeBuAndSubType(
      driverManagementSystemData,
      selectedYear.year,
      selectedDropDownMonth.label,
      DMSSelectorTypes.perMonthGraph,
    );

    const selectedMonthPerCategoryData = customDMSDataSelectorByYearMonthTypeBuAndSubType(
      driverManagementSystemData,
      selectedYear.year,
      selectedDropDownMonth.label,
      DMSSelectorTypes.perCategoryGraph,
    );

    const selectedMonthTableData = customDMSDataSelectorByYearMonthTypeBuAndSubType(
      driverManagementSystemData,
      selectedYear.year,
      selectedDropDownMonth.label,
      DMSSelectorTypes.table,
    );

    if (
      selectedMonthAndYearPerWeekData
      && selectedMonthPerMonthData
      && selectedMonthPerCategoryData
      && selectedMonthTableData
    ) {
      setTimeout(() => setIsDataLoadingForIncidentsByMonthsCategoriesAndType(false), 100);
    } else {
      callGetIncidentsByMonthsCategoriesAndTypeLazyQuery({
        variables: {
          monthNumber: selectedDropDownMonth.monthNumber,
          year: selectedYear.year,
        },
      });
    }
  };

  const handleDropYearChange = ({ key }: { key: any }) => {
    const selectedDropDownYear = yearOptions.find(item => item.slug === key);
    setSelectedYear(selectedDropDownYear || yearOptions[0]);
    setSelectedBu(undefined);
    setIsDropDownOpen(undefined);
    setIncidentsByMonthsCategoriesAndTypeError(false);
    setIncidentsByTypePerBuDataError(false);
    setIsDataLoadingForIncidentsByMonthsCategoriesAndType(true);

    const currentYear: number = (new Date()).getUTCFullYear();
    if (selectedDropDownYear.year === currentYear) {
      const currentMonthIndex = (new Date()).getMonth();
      if (selectedMonth.monthNumber > currentMonthIndex + 1) return;
    }

    const selectedMonthAndYearPerWeekData = customDMSDataSelectorByYearMonthTypeBuAndSubType(
      driverManagementSystemData,
      selectedDropDownYear.year,
      selectedMonth.label,
      DMSSelectorTypes.perWeekGraph,
    );

    const selectedMonthPerMonthData = customDMSDataSelectorByYearMonthTypeBuAndSubType(
      driverManagementSystemData,
      selectedDropDownYear.year,
      selectedMonth.label,
      DMSSelectorTypes.perMonthGraph,
    );

    const selectedMonthPerCategoryData = customDMSDataSelectorByYearMonthTypeBuAndSubType(
      driverManagementSystemData,
      selectedDropDownYear.year,
      selectedMonth.label,
      DMSSelectorTypes.perCategoryGraph,
    );

    const selectedMonthTableData = customDMSDataSelectorByYearMonthTypeBuAndSubType(
      driverManagementSystemData,
      selectedDropDownYear.year,
      selectedMonth.label,
      DMSSelectorTypes.table,
    );

    if (
      selectedMonthAndYearPerWeekData
      && selectedMonthPerMonthData
      && selectedMonthPerCategoryData
      && selectedMonthTableData
    ) {
      setTimeout(() => setIsDataLoadingForIncidentsByMonthsCategoriesAndType(false), 100);
    } else {
      callGetIncidentsByMonthsCategoriesAndTypeLazyQuery({
        variables: {
          monthNumber: selectedMonth.monthNumber,
          year: selectedDropDownYear.year,
        },
      });
    }
  };

  const handleDropBuChange = ({ key }: { key: any }) => {
    const selectedDropDownBu = buList.find((item: any) => item.slug === key);
    setSelectedBu(selectedDropDownBu || buList[0]);
    setIsDropDownOpen(undefined);
    setIncidentsByTypePerBuDataError(false);
    setIsDataLoadingForIncidentsByTypePerBuData(true);

    const currentSelectedBu = selectedDropDownBu
      ? selectedDropDownBu.bu
      : buList[0].bu;

    const selectedMonthTableDataPerBu = customDMSDataSelectorByYearMonthTypeBuAndSubType(
      driverManagementSystemData,
      selectedYear.year,
      selectedMonth.label,
      DMSSelectorTypes.tablePerBu,
      currentSelectedBu,
    );

    if (selectedMonthTableDataPerBu) {
      setTimeout(() => setIsDataLoadingForIncidentsByTypePerBuData(false), 100);
    } else {
      callGetIncidentsByTypePerBuLazyQuery({
        variables: {
          monthNumber: selectedMonth.monthNumber,
          year: selectedYear.year,
          bu: currentSelectedBu,
        },
      });
    }
  };

  const dropDownOpenCloseHandler = (opened: boolean, dropDownType: DMSDropDowns) => {
    return opened ? setIsDropDownOpen(dropDownType) : setIsDropDownOpen(undefined);
  };

  useEffect(() => {
    if (!selectedBu) {
      setSelectedBu(buList ? (buList.length !== 0 ? buList[0] : undefined) : undefined);
    }
    // eslint-disable-next-line
  }, [buList]);

  useEffect(() => {
    setIsDataLoadingForIncidentsByMonthsCategoriesAndType(true);

    const selectedMonthAndYearPerWeekData = customDMSDataSelectorByYearMonthTypeBuAndSubType(
      driverManagementSystemData,
      selectedYear.year,
      selectedMonth.label,
      DMSSelectorTypes.perWeekGraph,
    );

    const selectedMonthPerMonthData = customDMSDataSelectorByYearMonthTypeBuAndSubType(
      driverManagementSystemData,
      selectedYear.year,
      selectedMonth.label,
      DMSSelectorTypes.perMonthGraph,
    );

    const selectedMonthPerCategoryData = customDMSDataSelectorByYearMonthTypeBuAndSubType(
      driverManagementSystemData,
      selectedYear.year,
      selectedMonth.label,
      DMSSelectorTypes.perCategoryGraph,
    );

    const selectedMonthTableData = customDMSDataSelectorByYearMonthTypeBuAndSubType(
      driverManagementSystemData,
      selectedYear.year,
      selectedMonth.label,
      DMSSelectorTypes.table,
    );

    if (
      selectedMonthAndYearPerWeekData
      && selectedMonthPerMonthData
      && selectedMonthPerCategoryData
      && selectedMonthTableData
    ) {
      setIsDataLoadingForIncidentsByMonthsCategoriesAndType(false);
    } else {
      callGetIncidentsByMonthsCategoriesAndTypeLazyQuery({
        variables: {
          monthNumber: selectedMonth.monthNumber,
          year: selectedYear.year,
        },
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (incidentsByMonthsCategoriesAndTypeData) {
      const selectedyearData = driverManagementSystemData
        .get(selectedYear.year)?.toJS();
      const selectedMonthData = driverManagementSystemData.getIn(
        [selectedYear.year, selectedMonth.label],
      )?.toJS();

      const initialState = {
        ...selectedyearData,
        [selectedMonth.label]: {
          ...selectedMonthData,
        },
      };

      let uniqueBuList: any = [];
      const tempUniqueBuList: any = [];

      if (incidentsByMonthsCategoriesAndTypeData?.topBest.length !== 0) {
        incidentsByMonthsCategoriesAndTypeData.topBest.forEach(item => {
          tempUniqueBuList.push({ label: item?.bu, slug: item?.bu, bu: item?.bu });
        });
      }

      if (incidentsByMonthsCategoriesAndTypeData?.topOffenders.length !== 0) {
        incidentsByMonthsCategoriesAndTypeData.topOffenders.forEach(item => {
          tempUniqueBuList.push({ label: item?.bu, slug: item?.bu, bu: item?.bu });
        });
      }

      if (incidentsByMonthsCategoriesAndTypeData?.topVehicleOffenders.length !== 0) {
        incidentsByMonthsCategoriesAndTypeData.topVehicleOffenders.forEach(item => {
          tempUniqueBuList.push({ label: item?.bu, slug: item?.bu, bu: item?.bu });
        });
      }

      if (tempUniqueBuList.length !== 0) {
        uniqueBuList = tempUniqueBuList.filter(
          (value: any, index: any, self: any) => index === self.findIndex((item: any) => (
            item.label === value.label && item.slug === value.slug
          )),
        );

        setIsDataLoadingForIncidentsByTypePerBuData(true);

        callGetIncidentsByTypePerBuLazyQuery({
          variables: {
            monthNumber: selectedMonth.monthNumber,
            year: selectedYear.year,
            bu: uniqueBuList[0].bu,
          },
        });
      }

      const payload = yearlyPayloadForIncidentsByMonthsCategoriesAndTypeData(
        initialState,
        incidentsByMonthsCategoriesAndTypeData,
        selectedMonth.label,
      );

      Object.assign(payload[selectedMonth.label], { [DMSBuList.buList]: uniqueBuList });

      dispatch(
        setYearsData(
          { year: selectedYear.year, data: payload },
        ),
      );

      setIsDataLoadingForIncidentsByMonthsCategoriesAndType(false);
    }
    // eslint-disable-next-line
  }, [incidentsByMonthsCategoriesAndTypeData]);

  useEffect(() => {
    if (incidentsByTypePerBuData) {
      const selectedyearData = driverManagementSystemData
        .get(selectedYear.year)?.toJS();
      const selectedMonthData = driverManagementSystemData.getIn(
        [selectedYear.year, selectedMonth.label],
      )?.toJS();
      const selectedMonthTableDataPerBu = customDMSDataSelectorByYearMonthTypeBuAndSubType(
        driverManagementSystemData,
        selectedYear.year,
        selectedMonth.label,
        DMSSelectorTypes.tablePerBu,
      );

      const initialState = {
        ...selectedyearData,
        [selectedMonth.label]: {
          ...selectedMonthData,
        },
      };
      const payload = yearlyPayloadForIncidentsByTypePerBuData(
        initialState,
        selectedMonthTableDataPerBu,
        incidentsByTypePerBuData,
        selectedMonth.label,
        selectedBu.bu,
      );

      dispatch(
        setYearsData(
          { year: selectedYear.year, data: payload },
        ),
      );

      setIsDataLoadingForIncidentsByTypePerBuData(false);
    }
    // eslint-disable-next-line
  }, [incidentsByTypePerBuData]);

  useEffect(() => {
    const currentYear: number = (new Date()).getUTCFullYear();
    if (selectedYear.year === currentYear) {
      const currentMonthIndex = (new Date()).getMonth();
      if (selectedMonth.monthNumber > currentMonthIndex + 1) {
        handleDropMonthChange({ key: monthArr[currentMonthIndex] });
      }
    }

  // eslint-disable-next-line
  }, [validMonthOptions]);

  return (
    <div className="container-fluid overview-wrapper dms">
      <h2 className="dms__title">{t(TRANSLATIONS.TELEMETRY)}</h2>
      <div className="headerFlex">
        <div>
          <ChartDropdown
            onChange={handleDropYearChange}
            selectedDropDownItem={selectedYear}
            dropDownItems={yearOptions}
            dropDownOpen={isDropDownOpen === DMSDropDowns.year}
            setDropDownOpen={
              (opened: boolean) => dropDownOpenCloseHandler(opened, DMSDropDowns.year)
            }
            labelClassName="label-default"
          />
        </div>
        <div>
          <ChartDropdown
            onChange={handleDropMonthChange}
            selectedDropDownItem={selectedMonth}
            dropDownItems={validMonthOptions}
            dropDownOpen={isDropDownOpen === DMSDropDowns.month}
            setDropDownOpen={
              (opened: boolean) => dropDownOpenCloseHandler(opened, DMSDropDowns.month)
            }
            labelClassName="label-default"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 card dms__card">
          <span className="dms__card__title">{selectedMonth.label}</span>
          {isDataLoadingForIncidentsByMonthsCategoriesAndType
            ? (<LoadingSpinner height={310} />)
            : (
              <div className="row">
                <div className="col-6">
                  <IncidentsLineChart
                    month={selectedMonth.label}
                    year={selectedYear.year}
                    loading={isDataLoadingForIncidentsByMonthsCategoriesAndType}
                    error={incidentsByMonthsCategoriesAndTypeError}

                  />
                </div>
                <div className="col-6">
                  <IncidentsPieChart
                    loading={isDataLoadingForIncidentsByMonthsCategoriesAndType}
                    month={selectedMonth.label}
                    year={selectedYear.year}
                    error={incidentsByMonthsCategoriesAndTypeError}
                  />
                </div>
              </div>
            )}
        </div>
      </div>
      <div className="row">
        <div className="col-12 card dms__card">
          <span className="dms__card__monthLabel">{`${t(TRANSLATIONS.CURRENT_MONTH)} - ${selectedMonth.label}`}</span>
          <CurrentMonthBarChart
            loading={isDataLoadingForIncidentsByMonthsCategoriesAndType}
            month={selectedMonth.label}
            year={selectedYear.year}
            error={incidentsByMonthsCategoriesAndTypeError}
          />
        </div>
      </div>
      <SeparatorWithTitle title={t(TRANSLATIONS.BY_CATEGORY)} />
      <div className="row">
        {Object.values(DMS_CATEGORY).map((category: string) => (
          <div className="col-6 card dms__card" key={category}>
            <span className="dms__card__monthLabel">{categorTitleByType(category, t)}</span>
            <BarChartPerCategory
              loading={isDataLoadingForIncidentsByMonthsCategoriesAndType}
              month={selectedMonth.label}
              year={selectedYear.year}
              type={category}
              error={incidentsByMonthsCategoriesAndTypeError}
            />
          </div>
        ))}
      </div>
      <div className="dms__best-title">
        <h2>Top best / Top offender para MAZ</h2>
      </div>
      <div className="row">
        <div className="col-6 card dms__card">
          <TopCdsDMS
            isTopOffenders={false}
            loading={isDataLoadingForIncidentsByMonthsCategoriesAndType}
            month={selectedMonth.label}
            year={selectedYear.year}
            error={incidentsByMonthsCategoriesAndTypeError}
          />
        </div>
        <div className="col-6 card dms__card">
          <TopCdsDMS
            isTopOffenders
            loading={isDataLoadingForIncidentsByMonthsCategoriesAndType}
            month={selectedMonth.label}
            year={selectedYear.year}
            error={incidentsByMonthsCategoriesAndTypeError}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-6 card dms__card">
          <VehicleTopOffenders
            loading={isDataLoadingForIncidentsByMonthsCategoriesAndType}
            month={selectedMonth.label}
            year={selectedYear.year}
            error={incidentsByMonthsCategoriesAndTypeError}
          />
        </div>
      </div>
      <SeparatorWithTitle title={t(TRANSLATIONS.TELEMETRY_BY_BU)} />
      {buList ? (
        buList.length !== 0 ? (
          <div className="dms__dropdown">
            <ChartDropdown
              onChange={handleDropBuChange}
              selectedDropDownItem={selectedBu}
              dropDownItems={buList}
              dropDownOpen={isDropDownOpen === DMSDropDowns.bu}
              setDropDownOpen={
              (opened: boolean) => dropDownOpenCloseHandler(opened, DMSDropDowns.bu)
            }
              labelClassName="label-default"
            />
          </div>
        ) : null
      ) : null}
      <div className="row">
        <div className="col-6 card dms__card">
          <TopCdsDMS
            isTopOffenders={false}
            bu={selectedBu ? selectedBu.bu : ''}
            loading={isDataLoadingForIncidentsByTypePerBuData}
            month={selectedMonth.label}
            year={selectedYear.year}
            error={incidentsByTypePerBuDataError}
          />
        </div>
        <div className="col-6 card dms__card">
          <TopCdsDMS
            isTopOffenders
            bu={selectedBu ? selectedBu.bu : ''}
            loading={isDataLoadingForIncidentsByTypePerBuData}
            month={selectedMonth.label}
            year={selectedYear.year}
            error={incidentsByTypePerBuDataError}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-6 card dms__card">
          <VehicleTopOffenders
            bu={selectedBu ? selectedBu.bu : ''}
            loading={isDataLoadingForIncidentsByTypePerBuData}
            month={selectedMonth.label}
            year={selectedYear.year}
            error={incidentsByTypePerBuDataError}
          />
        </div>
      </div>
    </div>
  );
};

export default DriverManagmentSystem;
