import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';
import ImageLinks from 'utils/ImageLinks';
import './CheckBoxWithDescription.scss';

type Props = {
  label?: string;
  selected?: number;
  selectedIcon?: string;
  deSelectedIcon?: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  disabled?: boolean;
  required?: boolean;
  iconStyle?: {};
  data: any;
  isEditing: boolean;
};

type TruncatedState = {
  [id: string]: boolean;
};

const CheckBoxWithDescription = (props: Props) => {
  const {
    selected,
    onClick,
    disabled,
    required,
    iconStyle,
    data,
    isEditing,
  } = props;

  const { emptyRadioUnchecked, yellowRadioChecked } = ImageLinks;
  const [isTruncated, setIsTruncated] = useState<TruncatedState>(() => {
    const initialState: TruncatedState = {};
    data?.forEach((element: any) => {
      initialState[element.id] = true;
    });
    return initialState;
  });
  const maxlenght = 40;
  const { t, i18n } = useTranslation();
  const userLangEN = i18n.language === 'en-US' || i18n.language === 'en';
  const noDescriptionText = `${t(TRANSLATIONS.NO)} ${t(TRANSLATIONS.DESCRIPTION)}`;

  const toggleTruncate = (id: any) => {
    setIsTruncated(prevState => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  return data ? (
    data.map((element: any) => {
      const { name, id, description, descriptionEN } = element;
      const descriptionTransl = userLangEN ? descriptionEN : description;
      const truncatedText = isTruncated[id] ? `${descriptionTransl?.slice(0, maxlenght) || noDescriptionText}...` : descriptionTransl;

      if (!isEditing && id !== selected) {
        return null;
      }

      return (
        <>
          <div
            key={id}
            onClick={() => onClick && onClick(element)}
            className="contentWrapper"
            style={{
              cursor: disabled ? 'not-allowed' : 'pointer',
            }}
            role="presentation"
          >
            <img style={{ ...iconStyle }} src={selected === id ? yellowRadioChecked : emptyRadioUnchecked} alt="Checkbox" />
            <span className="textLabel">
              {required && <p style={{ color: '#E92B36' }}>*</p>}
              {name}
            </span>
          </div>
          <div className="descriptionTextWrapper">
            <p
              className="descriptionText"
              style={{
                marginBottom: isTruncated[id] ? 15 : 0,
              }}
            >
              {truncatedText}
            </p>
            {descriptionTransl?.length > maxlenght && (
              <p
                className="seeMoreText"
                onClick={() => toggleTruncate(id)}
                style={{
                  marginTop: isTruncated[id] ? 15 : 0,
                }}
              >
                {isTruncated[id] ? t(TRANSLATIONS.SEE_MORE) : t(TRANSLATIONS.SEE_LESS) as any}
              </p>
            )}
          </div>
        </>
      );
    })
  ) : null;
};

export default CheckBoxWithDescription;
