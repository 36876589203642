import React, { FC } from 'react';
import './Iframe.scss';

type Props = {
  iframe: string;
  iframeTitle: string;
  pageName?: string;
};

const Iframe: FC<Props> = ({ iframe, iframeTitle, pageName }) => {
  const iframSrc = `${iframe}&filterPaneEnabled=false&navContentPaneEnabled=false`;
  return (
    <div className="iframeContainer">
      <iframe
        title={iframeTitle}
        src={pageName ? `${iframSrc}&pageName=${pageName}` : iframSrc}
        frameBorder="0"
        allowFullScreen
        className="iframe"
      />
    </div>
  );
};

export default Iframe;
