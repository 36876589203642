import React from 'react';
import Header from 'components/Header';
import NavigationDrawerWithSideMenu
  from 'components/NavigationDrawer/NavigationDrawerWithVerticalMenu';
import { RoutesForNavigationDrawerWithVerticalMenu } from 'types/interfaces';

type Props = {
  routes: RoutesForNavigationDrawerWithVerticalMenu[];
  children: any;
  hideLocations?: boolean;
  showSearchbar?: boolean;
};

export default function AnalyticsWrapper({
  routes, children, hideLocations, showSearchbar,
}: Props) {
  return (
    <>
      <Header hideFilters={hideLocations} showSearchbar={showSearchbar} />
      <NavigationDrawerWithSideMenu
        routes={routes}
        openDrawer
      />
      <div style={{ marginLeft: 220, paddingTop: 30, maxHeight: 'calc(100vh - 60px)', overflowY: 'scroll' }}>
        { children }
      </div>
    </>
  );
}
