import React, { useState } from 'react';
import SelectDropdown from 'components/Dropdown/SelectDropdown';
import ImageLinks from 'utils/ImageLinks';
import CapexByMonthsOrProjects from './CapexByMonthsOrProjects';
import CapexPorBu from './CapexPorBu';
import CapexRegionalManagerTable from './CapexTable/CapexRegionalManagerTable';
import './Capex.scss';

type CapexSelector = {
  label: string,
  value: number,
};

const Capex = () => {
  const [searchField, setSeachField] = useState<string>('');
  const [capexSelectorValue, setCapexSelectorValue] = useState<number>(1);
  const [apiType, setApiType] = useState<string>('byBudget');
  const choices: CapexSelector[] = [
    { label: 'Mes', value: 1 },
    { label: 'Proyecto', value: 2 },
  ];

  const handleChange = (event: { target: { value: number } }) => {
    setCapexSelectorValue(event.target.value);
  };

  const handleSearchField = (event: { target: { value: string } }) => {
    setSeachField(event.target.value);
  };

  const handleChangeRadioButton = (event: any) => {
    setApiType(event.target.value);
  };

  return (
    <div className="overview-wrapper">
      <div className="capex-radio-buttons-choice" onChange={handleChangeRadioButton}>
        <div> <input type="radio" value="byBudget" name="type" checked={apiType === 'byBudget'} />  Presupuesto </div>
        <div> <input type="radio" value="byProject" name="type" />  Proyecto </div>
      </div>
      <h1>CAPEX</h1>
      <h2>CAPEX por BU (Desempeño)</h2>
      <div>
        <div className="col-12 card routines-home__card">
          <CapexPorBu type={apiType} />
        </div>
      </div>
      <div>
        <h2> CAPEX por mes o por proyecto</h2>
      </div>
      <div className="margin8 capex-selector capex-por-mes-o-por-proyecto-drop-down">
        <SelectDropdown
          name="download-card"
          value=""
          placeholder={choices[capexSelectorValue - 1].label}
          dropdownContent={choices}
          onChange={handleChange}
          bgColor="white"
        />
      </div>

      <CapexByMonthsOrProjects selected={capexSelectorValue} type={apiType} />
      <div className="capex-table-container">
        <div className="row routines-home__filters-row container-fluid">
          <h1> Detalle por proyecto </h1>
        </div>
        <div>
          <div className="search-box-container">
            <input
              type="search"
              placeholder="Buscar proyecto"
              onChange={handleSearchField}
            />
            <img className="margin8" src={ImageLinks.search_black} alt="search" />
          </div>
        </div>
      </div>
      <div className="row container-fluid">
        <div className="col-12 card routines-home__card">
          <CapexRegionalManagerTable searchField={searchField} type={apiType} />
        </div>
      </div>

    </div>
  );
};

export default Capex;
