/* eslint-disable no-undef */
import React, { Component } from 'react';

export default class Logout extends Component {
  componentDidMount() {
    window.location.replace('/api/v1/logout');
  }

  render() {
    return (
      <div />
    );
  }
}
