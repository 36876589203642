import React from 'react';
import EmptyState from 'components/EmptyState/EmptyState';
import ComplainceChart from '../Chart/ComplainceChart';
import '../ComplainceSIF.scss';

type Props = {
  response: any,
  closed: number[],
  progress: number[],
  pending: number[],
  loading: boolean,
  bu: any
};

const ChartByBU = (props: Props) => {
  const { bu, closed, progress, pending, loading, response } = props;

  if (!response.length && !loading) {
    return <EmptyState className="no-chart-margin" />;
  }

  return (
    <ComplainceChart
      bu={bu}
      closed={closed}
      progress={progress}
      pending={pending}
      loading={loading}
    />
  );
};

export default ChartByBU;
