import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';

import Iframe from 'components/Iframe';
import { useSelector } from 'react-redux';
import { selectGlobalFilters, selectsearchableLocations } from 'store/selectors/globalFilters.selectors';
import ChartTabs from 'components/Tabs/ChartTabs';
import IncidentsPieChart from './IncidentsPieChart';
import TotalIncidentsChart from './TotalIncidentsChart';
import IncidentsBarChart from './IncidentsBarChart';
import IncidentsByRiskTypeBarChart from './IncidentsByRiskTypeBarChart';
import IncidentsByWorkLocationChart from './IncidentsByWorkLocationChart';

export default function AnalyticsIncidentsWrapper() {
  const { t } = useTranslation();
  const filters = useSelector(selectGlobalFilters);
  const userAccessBu = useSelector(selectsearchableLocations);
  const filterUserAccessBu = userAccessBu?.filter((element: any) => element.subType === 'bu');
  const allHaveSupplyInSlug = filterUserAccessBu?.every((element: any) => element?.slug?.includes('supply'));
  const noneHaveSupplyInSlug = filterUserAccessBu?.every((element: any) => !element?.slug?.includes('supply'));

  const slugOfSelectedBu = filters?.getIn(['businessUnit', 'slug']);
  const buIsFactory = slugOfSelectedBu?.includes('supply') || false;
  const buIsNotFactory = slugOfSelectedBu && !slugOfSelectedBu?.includes('supply');

  const tabs = [
    { title: 'Actos', value: '', type: 'Actos', index: 0 },
    { title: 'Condiciones', value: '', type: 'Condiciones', index: 1 },
    { title: 'Usab. Actual', value: '', type: 'Usab. Actual', index: 2 },
    { title: 'Usab. Historico', value: '', type: 'Usab. Historico', index: 3 },
  ];

  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  return (
    <>
      <div className="container-fluid">
        <div className="row" style={{ marginBottom: -14 }}>
          {noneHaveSupplyInSlug || buIsNotFactory
            ? (
              <div className="col-12 px-2 mb-3">
                <div
                  style={{
                    padding: 15,
                    backgroundColor: 'rgb(242, 242, 242)',
                    borderRadius: 10,
                  }}
                >
                  <p
                    style={{
                      fontSize: 13,
                    }}
                  >
                    { t(TRANSLATIONS.SECURE_CONDITION_DISCLAIMER) }
                  </p>
                </div>
              </div>
            ) : null}

          {buIsFactory || allHaveSupplyInSlug
            ? (
              <>
                <ChartTabs
                  tabsItems={tabs}
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab as any}
                  selectedTabStyle="skap__selected-tab"
                  customStyle="skap__tabs-container"
                />
                {selectedTab.index === 0 && (
                <div className="col-12 pl-2">
                  <Iframe
                    iframe="https://app.powerbi.com/view?r=eyJrIjoiNjk5NjY1YmItOWRkNy00ODY2LTkxMDAtNzU0ZTFlMWNiODQ4IiwidCI6ImJlMTliNmNlLTdkMTktNGVkOS05NDgzLWIzM2I5NDI3ZTc4YyJ9"
                    iframeTitle="ACIs v2_reconectado"
                    pageName="ReportSection6746cd2b2bc5104f544f"
                  />
                </div>
                )}
                {selectedTab.index === 1 && (
                <div className="col-12 pl-2">
                  <Iframe
                    iframe="https://app.powerbi.com/view?r=eyJrIjoiNjk5NjY1YmItOWRkNy00ODY2LTkxMDAtNzU0ZTFlMWNiODQ4IiwidCI6ImJlMTliNmNlLTdkMTktNGVkOS05NDgzLWIzM2I5NDI3ZTc4YyJ9"
                    iframeTitle="ACIs v2_reconectado"
                    pageName="ReportSection4d87f18a567b3989c7de"
                  />
                </div>
                )}
                {selectedTab.index === 2 && (
                <div className="col-12 pl-2">
                  <Iframe
                    iframe="https://app.powerbi.com/view?r=eyJrIjoiNjk5NjY1YmItOWRkNy00ODY2LTkxMDAtNzU0ZTFlMWNiODQ4IiwidCI6ImJlMTliNmNlLTdkMTktNGVkOS05NDgzLWIzM2I5NDI3ZTc4YyJ9"
                    iframeTitle="ACIs v2_reconectado"
                    pageName="ReportSection04ceeebb2d960419346b"
                  />
                </div>
                )}
                {selectedTab.index === 3 && (
                <div className="col-12 pl-2">
                  <Iframe
                    iframe="https://app.powerbi.com/view?r=eyJrIjoiNjk5NjY1YmItOWRkNy00ODY2LTkxMDAtNzU0ZTFlMWNiODQ4IiwidCI6ImJlMTliNmNlLTdkMTktNGVkOS05NDgzLWIzM2I5NDI3ZTc4YyJ9"
                    iframeTitle="ACIs v2_reconectado"
                    pageName="ReportSection89452ea782a66cd2142f"
                  />
                </div>
                )}
              </>
            ) : noneHaveSupplyInSlug || buIsNotFactory ? (
              <>
                <div className="col-8 pl-2">
                  <div className="card mb-3 pt-15 px-0">
                    <IncidentsPieChart />
                  </div>
                </div>
                <div className="col-4 pl-2">
                  <div className="card mb-3">
                    <TotalIncidentsChart />
                  </div>
                </div>
                <div className="col-12 pl-2">
                  <div className="card mb-3 pt-15 px-0">
                    <IncidentsBarChart />
                  </div>
                </div>
                <div className="col-6 pl-2">
                  <div className="card mb-3 pt-15 px-0">
                    <IncidentsByRiskTypeBarChart />
                  </div>
                </div>
                <div className="col-6 pl-2">
                  <div className="card mb-3 pt-15 px-0">
                    <IncidentsByWorkLocationChart />
                  </div>
                </div>
              </>
            )
              : (
                <div className="col-12 px-2 mb-3">
                  <div
                    style={{
                      padding: 15,
                      backgroundColor: 'rgb(242, 242, 242)',
                      borderRadius: 10,
                    }}
                  >
                    <p
                      style={{
                        fontSize: 13,
                      }}
                    >
                      { t(TRANSLATIONS.PLEASE_SELECT_A_BU_TO_SHOW_CHARTS) }
                    </p>
                  </div>
                </div>
              ) }
        </div>
      </div>
    </>
  );
}
