import { CreateEditActionLog } from 'generated/graphql';
import React, { useContext } from 'react';

interface ActionLogProps {
  actionLog: CreateEditActionLog;
  children: any;
}
const ActionLogContext = React.createContext<any>({});

export const ActionLogProvider:React.FC<ActionLogProps> = ({ actionLog, children }) => {
  return (
    <ActionLogContext.Provider value={actionLog}>
      {children}
    </ActionLogContext.Provider>
  );
};

export const useActionLogContext = () => {
  return useContext(ActionLogContext);
};
