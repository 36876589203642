import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import LoadingSpinner from 'components/LoadingSpinner';
import Header from 'components/Header';
import PaginatedTable from 'components/PaginatedTable';
import InputWithIcon from 'components/Input/InputWithIcon';
import searchIcon from 'assets/icons/search.svg';
import { selectLocationsToAccess } from 'store/selectors/globalFilters.selectors';
import { useGetCovidUsersForLocationsQuery } from 'generated/graphql';
import { COLOR_CODE_NEW } from 'types/enums';

export default function HRProfileInformation() {
  const [searchText, setSearchText] = useState<string>('');
  const locationsToAccess = useSelector(selectLocationsToAccess);
  const covidUsersForLocations = useGetCovidUsersForLocationsQuery({
    variables: {
      locationsToAccess,
      offset: 5,
      limit: 5,
    },
  });

  const cols = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Employee Number',
      accessor: 'employeeNumber',
    },
    {
      Header: 'Doctor Status',
      accessor: 'doctorStatus',
    },
    {
      Header: 'Quarantine Until',
      accessor: 'quarantineUntil',
    },
    {
      Header: 'Latest Covid Test',
      accessor: 'latestCovidTest',
    },
  ];

  return (
    <>
      <div style={{ marginBottom: 30 }}>
        <Header />
      </div>
      <div className="container-fluid">
        <InputWithIcon
          placeholder="Search"
          onChange={e => setSearchText(e.target.value)}
          value={searchText}
          icon={searchIcon}
          activeBoxShadow="none"
          activeBorderColor="none"
          inactiveBorderColor="none"
          imageStyles={{
            top: '15px',
            width: '15px',
          }}
          style={{
            borderRadius: 5,
            marginBottom: 10,
            maxWidth: 'unset',
            paddingTop: '10px',
            paddingBottom: '10px',
            boxShadow: '0 1px 3px 0 rgba(207, 207, 207, 0.5)',
            backgroundColor: COLOR_CODE_NEW.WHITE,
          }}
        />
        <div className="card">
          { covidUsersForLocations.data
            // eslint-disable-next-line max-len
            ? <PaginatedTable {...{ columns: cols, data: covidUsersForLocations?.data?.getCovidUsersForLocations?.covidUsersForLocation }} />
            : <LoadingSpinner />}
        </div>
      </div>
    </>
  );
}
