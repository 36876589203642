import React, { useState } from 'react';
import Iframe from 'components/Iframe';
import { TRANSLATIONS } from 'types/enums';
import { useTranslation } from 'react-i18next';
import ChartTabs from 'components/Tabs/ChartTabs';
import './UsabilityDPOStyles.scss';

const UsabilityDPO = () => {
  const { t } = useTranslation();

  const tabs = [
    { title: t(TRANSLATIONS.RULES), value: '', type: TRANSLATIONS.RULES, index: 0 },
    { title: t(TRANSLATIONS.OVERVIEW), value: '', type: TRANSLATIONS.OVERVIEW, index: 1 },
    { title: t(TRANSLATIONS.ACTION_PLANS), value: '', type: TRANSLATIONS.ACTION_PLANS, index: 2 },
    { title: t(TRANSLATIONS.ZOOM_ROUTINES), value: '', type: TRANSLATIONS.ZOOM_ROUTINES, index: 3 },
    { title: t(TRANSLATIONS.FIVE_S), value: '', type: TRANSLATIONS.FIVE_S, index: 4 },
    { title: t(TRANSLATIONS.OWD), value: '', type: TRANSLATIONS.OWD, index: 5 },
    { title: t(TRANSLATIONS.SCORECARD), value: '', type: TRANSLATIONS.SCORECARD, index: 6 },
    { title: t(TRANSLATIONS.TEAM_ROOMS), value: '', type: TRANSLATIONS.TEAM_ROOMS, index: 7 },
    { title: t(TRANSLATIONS.WORKSTATION), value: '', type: TRANSLATIONS.WORKSTATION, index: 8 },

  ];
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  return (
    <div className="container-fluid overview-wrapper usability-home">
      <h2 className="usability-home__title">{`${t(TRANSLATIONS.USABILITY_DPO_HEADING)}`}</h2>
      <ChartTabs
        tabsItems={tabs}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab as any}
        selectedTabStyle="selected-tab"
        customStyle="tabs-container"
      />
      {selectedTab.index === 0 && (
      <Iframe
        iframe="https://app.powerbi.com/view?r=eyJrIjoiNTY2MGZkZTgtNTAyOS00ZGQ1LWJjMWItZGE5MmYzMzQ5Y2EyIiwidCI6ImJlMTliNmNlLTdkMTktNGVkOS05NDgzLWIzM2I5NDI3ZTc4YyJ9"
        iframeTitle={t(TRANSLATIONS.RULES)}
        pageName="ReportSection45e1334532dc71c85186"
      />
      )}
      {selectedTab.index === 1 && (
      <Iframe
        iframe="https://app.powerbi.com/view?r=eyJrIjoiNTY2MGZkZTgtNTAyOS00ZGQ1LWJjMWItZGE5MmYzMzQ5Y2EyIiwidCI6ImJlMTliNmNlLTdkMTktNGVkOS05NDgzLWIzM2I5NDI3ZTc4YyJ9"
        iframeTitle={t(TRANSLATIONS.OVERVIEW)}
        pageName="ReportSectiona9eedba59994946b68c4"
      />
      )}
      {selectedTab.index === 2 && (
      <Iframe
        iframe="https://app.powerbi.com/view?r=eyJrIjoiNTY2MGZkZTgtNTAyOS00ZGQ1LWJjMWItZGE5MmYzMzQ5Y2EyIiwidCI6ImJlMTliNmNlLTdkMTktNGVkOS05NDgzLWIzM2I5NDI3ZTc4YyJ9"
        iframeTitle={t(TRANSLATIONS.ACTION_PLANS)}
        pageName="ReportSection5e3e8fd1a666b78d69a9"
      />
      )}
      {selectedTab.index === 3 && (
      <Iframe
        iframe="https://app.powerbi.com/view?r=eyJrIjoiNTY2MGZkZTgtNTAyOS00ZGQ1LWJjMWItZGE5MmYzMzQ5Y2EyIiwidCI6ImJlMTliNmNlLTdkMTktNGVkOS05NDgzLWIzM2I5NDI3ZTc4YyJ9"
        iframeTitle={t(TRANSLATIONS.ZOOM_ROUTINES)}
        pageName="ReportSectionf0c852a6aee8fddcbde8"
      />
      )}
      {selectedTab.index === 4 && (
      <Iframe
        iframe="https://app.powerbi.com/view?r=eyJrIjoiNTY2MGZkZTgtNTAyOS00ZGQ1LWJjMWItZGE5MmYzMzQ5Y2EyIiwidCI6ImJlMTliNmNlLTdkMTktNGVkOS05NDgzLWIzM2I5NDI3ZTc4YyJ9"
        iframeTitle={t(TRANSLATIONS.FIVE_S)}
        pageName="ReportSection?"
      />
      )}
      {selectedTab.index === 5 && (
      <Iframe
        iframe="https://app.powerbi.com/view?r=eyJrIjoiNTY2MGZkZTgtNTAyOS00ZGQ1LWJjMWItZGE5MmYzMzQ5Y2EyIiwidCI6ImJlMTliNmNlLTdkMTktNGVkOS05NDgzLWIzM2I5NDI3ZTc4YyJ9"
        iframeTitle={t(TRANSLATIONS.OWD)}
        pageName="ReportSection32855c5a39aff23ec11d"
      />
      )}
      {selectedTab.index === 6 && (
        <Iframe
          iframe="https://app.powerbi.com/view?r=eyJrIjoiNTY2MGZkZTgtNTAyOS00ZGQ1LWJjMWItZGE5MmYzMzQ5Y2EyIiwidCI6ImJlMTliNmNlLTdkMTktNGVkOS05NDgzLWIzM2I5NDI3ZTc4YyJ9"
          iframeTitle={t(TRANSLATIONS.SCORECARD)}
          pageName="ReportSection6bee07db4f3651febf9a"
        />
      )}
      {selectedTab.index === 7 && (
        <Iframe
          iframe="https://app.powerbi.com/view?r=eyJrIjoiNTY2MGZkZTgtNTAyOS00ZGQ1LWJjMWItZGE5MmYzMzQ5Y2EyIiwidCI6ImJlMTliNmNlLTdkMTktNGVkOS05NDgzLWIzM2I5NDI3ZTc4YyJ9"
          iframeTitle={t(TRANSLATIONS.TEAM_ROOMS)}
          pageName="ReportSection652e263ce04ebe36bf6c"
        />
      )}
      {selectedTab.index === 8 && (
        <Iframe
          iframe="https://app.powerbi.com/view?r=eyJrIjoiNTY2MGZkZTgtNTAyOS00ZGQ1LWJjMWItZGE5MmYzMzQ5Y2EyIiwidCI6ImJlMTliNmNlLTdkMTktNGVkOS05NDgzLWIzM2I5NDI3ZTc4YyJ9"
          iframeTitle={t(TRANSLATIONS.WORKSTATION)}
          pageName="ReportSection9abe9186016e7d491908"
        />
      )}
    </div>
  );
};

export default UsabilityDPO;
