import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';

import Iframe from 'components/Iframe';
import ChartTabs from 'components/Tabs/ChartTabs';

export default function AnalyticsIncidentsWrapper() {
  const { t } = useTranslation();

  const tabs = [
    { title: t(TRANSLATIONS.ENVIRONMENTAL_RISK, { count: 2 }), value: '', type: 'AR', index: 0 },
  ];

  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  return (
    <>
      <div className="container-fluid">
        <div className="row" style={{ marginBottom: -14 }}>
          <>
            <ChartTabs
              tabsItems={tabs}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab as any}
              selectedTabStyle="skap__selected-tab"
              customStyle="skap__tabs-container"
            />
            {selectedTab.index === 0 && (
            <div className="col-12 pl-2">
              <Iframe
                iframe="https://app.powerbi.com/view?r=eyJrIjoiMzliZDk4MTMtMTc2Ni00MDM5LWJkMTctYzdhOGNlOGVmMzEwIiwidCI6ImJlMTliNmNlLTdkMTktNGVkOS05NDgzLWIzM2I5NDI3ZTc4YyJ9"
                iframeTitle="ACIs v2_reconectado"
                pageName="ReportSection6746cd2b2bc5104f544f"
              />
            </div>
            )}
          </>
        </div>
      </div>
    </>
  );
}
