import { TRANSLATIONS } from 'types/enums';
import gauzeIcon from 'assets/icons/gauge-gray.svg';
import gauzeGreenIcon from 'assets/icons/gauge-green.svg';
import homeIcon from 'assets/icons/home-gray.svg';
import homeGreenIcon from 'assets/icons/home-green.svg';
import searchicon from 'assets/icons/search-icon.png';
import executedBeer from 'assets/icons/beer.png';
import analyticsGreenIcon from 'assets/icons/analytics-green.svg';
import analyticsIcon from 'assets/icons/analytics-gray.svg';
import safetyAcademyIcon from 'assets/icons/academy-icon.svg';
import safetyAcademyIconGreen from 'assets/icons/academy-icon-green.svg';
import boardIconGreen from 'assets/icons/board-green.svg';
import boardIcon from 'assets/icons/board-grey.png';
import owdIcon from 'assets/icons/owd.svg';
import owdIconGreen from 'assets/icons/owd-green.svg';
import dotGreen from 'assets/icons/dot.svg';
import { RoutesForNavigationDrawerWithVerticalMenu } from 'types/interfaces';
import ImageLinks from './ImageLinks';
import { userIsSupply,
  userCanReportSIOs,
  userIsBreweryPlantManager,
  canUserCloseSIOS,
  canUserAccessAssignSIOsScreen,
  canUserAssignSIOsExecutors,
  canUserAccessAnalytics3rs,
  canUserAccessOverview,
  canUserAccessAnalytics,
  canUserAccessSearchUsers,
  canUserAccessPdts,
  canUserAccessOwds,
  canUserAccessACIs,
  canUserAccessAnalyticsEnvironmentalRisks,
  canUserAccessSefetyCertificatesT2,
  canUserAccessAnalyticsRoutines,
  canUserAccessCashless,
  canUserAccessPowerBiSiosT2,
  canUserAccessSifRoute,
  canUserAccessComplianceSif,
  canUserAccessAccidentRate,
  canUserAccessStatisticsSif,
  canUserAccessConsequences,
  canUserAccessSafetyTraining,
  canUserAccessCapex,
  canUserAccessGKPI,
  canUserAccessPreventionOfViolence,
  canUserAccessEffectivenessOfWorkstations,
  canUserAccessDMS,
  canUserAccessUsabilitydDPO,
  canUserAccessAnalyticsOWDs,
  canUserAccessffectivenessOfRoutines,
  canUserAccessAnalytics5s,
  canUserAccessAnalyticsSkap,
  canUserAccessAnalyticsAcis,
  canUserAccessBoards,
  canUserAccessSafetyAcademySideBar,
  canUserAccessLocations,
  canUserAccessRoadSafetyResourcesSideBar,
  canUserAccessCriticalRoutesSideBar,
  canUserAccessRoadSafetySideBar,
  canUserAccessRoadSafetyDriverSideBar } from './shared/canUserAccessFeature';

const defaultRoutes = (
  t: any,
  userType: any, location: any, userDetails?: any,
) => {
  const routes: RoutesForNavigationDrawerWithVerticalMenu[] = [];

  const isSupply = userIsSupply(userDetails?.getIn(['roleDetails', 'slug']), userType);
  const canUserReportSIOs = userCanReportSIOs(userDetails?.get('features'), userDetails?.toJS());
  const canUserAccess3rs = canUserAccessAnalytics3rs(userDetails?.get('features'));
  const isBreweryPlanManager = userIsBreweryPlantManager(userDetails?.getIn(['roleDetails', 'slug']), userType);
  const accessDPOAcademy = userDetails?.getIn(['canAccessDPOAcademy', 'isSupervisor']);
  const userIsFromPYLOocation = ['PY'].includes(userDetails?.getIn(['distributionCenter', 'country']));
  const canUserAccessOverviewPage = canUserAccessOverview(userDetails?.get('features'));
  const canUserAccessAnalyticsPage = canUserAccessAnalytics(userDetails?.get('features'));
  const canUserAccessSearchUsersPage = canUserAccessSearchUsers(userDetails?.get('features'));
  const canUserAccessPdtsPage = canUserAccessPdts(userDetails?.get('features'));
  const canUserAccessOwdsPage = canUserAccessOwds(userDetails?.get('features'));
  const canUserAccessACIsPage = canUserAccessACIs(userDetails?.get('features'));
  const canUserAccessBoard = canUserAccessBoards(userDetails?.get('features'));
  const canUserAccessLocationsPage = canUserAccessLocations(userDetails?.get('features'));
  const canUserAccessSubAnalyticsAcis = canUserAccessAnalyticsAcis(userDetails?.get('features'));
  const canUserAccessMainSafetyAcademy = canUserAccessSafetyAcademySideBar(userDetails?.get('features'));
  const canUserAccessAnalyticsEnvironmentalRisksPage = canUserAccessAnalyticsEnvironmentalRisks(userDetails?.get('features'));
  const canUserAccessAnalyticsSkapPage = canUserAccessAnalyticsSkap(userDetails?.get('features'));
  const canUserAccess5sPage = canUserAccessAnalytics5s(userDetails?.get('features'));
  const canUserAccessSefetyCertificatesT2Page = canUserAccessSefetyCertificatesT2(userDetails?.get('features'));
  const canUserAccessAnalyticsRoutinesPage = canUserAccessAnalyticsRoutines(userDetails?.get('features'));
  const canUserAccessCashlessPage = canUserAccessCashless(userDetails?.get('features'));
  const canUserAccessPowerBiSiosT2Page = canUserAccessPowerBiSiosT2(userDetails?.get('features'));
  const canUserAccessSifRoutePage = canUserAccessSifRoute(userDetails?.get('features'));
  const canUserAccessComplianceSifPage = canUserAccessComplianceSif(userDetails?.get('features'));
  const canUserAccessAccidentRatePage = canUserAccessAccidentRate(userDetails?.get('features'));
  const canUserAccessStatisticsSifPage = canUserAccessStatisticsSif(userDetails?.get('features'));
  const canUserAccessConsequencesPage = canUserAccessConsequences(userDetails?.get('features'));
  const canUserAccessSafetyTrainingPage = canUserAccessSafetyTraining(userDetails?.get('features'));
  const canUserAccessCapexPage = canUserAccessCapex(userDetails?.get('features'));
  const canUserAccessGKPIPage = canUserAccessGKPI(userDetails?.get('features'));
  const canUserAccesseffectivenessOfRoutinesPage = canUserAccessffectivenessOfRoutines(userDetails?.get('features'));
  const canUserAccessPreventionOfViolencePage = canUserAccessPreventionOfViolence(userDetails?.get('features'));
  const canUserAccessEffectivenessOfWorkstationsPage = canUserAccessEffectivenessOfWorkstations(userDetails?.get('features'));
  const canUserAccessDMSPage = canUserAccessDMS(userDetails?.get('features'));
  const canUserAccessUsabilitydDPOPage = canUserAccessUsabilitydDPO(userDetails?.get('features'));
  const canUserAccessAnalyticsOWDsPage = canUserAccessAnalyticsOWDs(userDetails?.get('features'));
  const canUserAccessRoadSafetyResourcesSideBarPage = canUserAccessRoadSafetyResourcesSideBar(userDetails?.get('features'));
  const canUserAccessCriticalRoutesSideBarPage = canUserAccessCriticalRoutesSideBar(userDetails?.get('features'));
  const canUserAccessRoadSafetyDriverSideBarPage = canUserAccessRoadSafetyDriverSideBar(userDetails?.get('features'));
  const canUserAccessRoadSafetySideBarPage = canUserAccessRoadSafetySideBar(userDetails?.get('features'));
  const canAccessRouteSafety = userDetails?.get('canAccessRouteSafetyWeb');

  if (isBreweryPlanManager) {
    routes.push(
      {
        listItemText: t(TRANSLATIONS.OVERVIEW),
        routeName: '/analytics/production-planning',
        listItemIcon: gauzeIcon,
        listItemIconActive: gauzeGreenIcon,
      },
      {
        listItemText: 'Agregar rutina',
        routeName: '/upload/daily-routine',
        listItemIcon: executedBeer,
        listItemIconActive: executedBeer,
      },
      {
        listItemText: 'Safety Academy',
        routeName: '/safety-academy',
        listItemIcon: safetyAcademyIcon,
        listItemIconActive: safetyAcademyIcon,
      },
    );
    return routes;
  }
  const analyticsSubRoutes = [] as any;
  const roadSafetySubRoutes = [] as any;
  if (canUserAccessSubAnalyticsAcis) {
    analyticsSubRoutes.push({
      listItemText: t(TRANSLATIONS.ACIS),
      routeName: '/analytics/incidents',
    });
  }

  if (canUserAccessAnalyticsEnvironmentalRisksPage) {
    analyticsSubRoutes.push({
      listItemText: t(TRANSLATIONS.ENVIRONMENTAL_RISK, { count: 2 }),
      routeName: '/analytics/ambiental-risks',
    });
  }

  if (canUserAccessSefetyCertificatesT2Page) {
    analyticsSubRoutes.push({
      listItemText: t(TRANSLATIONS.SAFETY_CERTIFICATES_T2),
      routeName: '/analytics/safety-certificates-t2',
    });
  }
  if (canUserAccessAnalyticsRoutinesPage) {
    analyticsSubRoutes.push({
      listItemText: t(TRANSLATIONS.ROUTINES),
      routeName: '/analytics/routines',
    });
  }
  if (canUserAccessCashlessPage) {
    analyticsSubRoutes.push({
      listItemText: 'Cashless',
      routeName: '/analytics/cashless',
    });
  }
  if (canUserAccess5sPage) {
    analyticsSubRoutes.push({
      listItemText: '5S',
      routeName: '/analytics/5S',
    });
  }

  if (!userIsFromPYLOocation) {
    if (canUserAccessPowerBiSiosT2Page) {
      analyticsSubRoutes.push({
        listItemText: t(TRANSLATIONS.POWER_BI_SIOS_T2),
        routeName: '/analytics/sios-t2',
      });
    }
  }
  if (canUserAccessSifRoutePage) {
    analyticsSubRoutes.push({
      listItemText: t(TRANSLATIONS.SIF_ROUTE),
      routeName: '/analytics/sif-route',
    });
  }

  if (canUserAccessComplianceSifPage) {
    analyticsSubRoutes.push({
      listItemText: t(TRANSLATIONS.SIF_COMPLANICE),
      routeName: '/analytics/sif-complaince',
    });
  }
  if (canUserAccessAccidentRatePage) {
    analyticsSubRoutes.push({
      listItemText: t(TRANSLATIONS.ACCIDENTABILITY),
      routeName: '/analytics/accidentability',
    });
  }
  if (canUserAccessStatisticsSifPage) {
    analyticsSubRoutes.push({
      listItemText: t(TRANSLATIONS.SIF_STATISTICS),
      routeName: '/analytics/sif-statistics',
    });
  }
  if (canUserAccessConsequencesPage) {
    analyticsSubRoutes.push({
      listItemText: t(TRANSLATIONS.CONSEQUENCES),
      routeName: '/analytics/consequences',
    });
  }
  if (canUserAccessSafetyTrainingPage) {
    analyticsSubRoutes.push({
      listItemText: t(TRANSLATIONS.SAFETY_TRAINING),
      routeName: '/analytics/safety-training',
    });
  }
  if (canUserAccessCapexPage) {
    analyticsSubRoutes.push({
      listItemText: 'Capex',
      routeName: '/analytics/capex',
    });
  }
  if (canUserAccessPreventionOfViolencePage) {
    analyticsSubRoutes.push({
      listItemText: t(TRANSLATIONS.VIOLENCE_PREVENTION),
      routeName: '/analytics/violence-prevention',
    });
  }
  if (canUserAccessGKPIPage) {
    analyticsSubRoutes.push({
      listItemText: 'GKPI',
      routeName: '/analytics/GKPI',
    });
  }
  if (canUserAccessAnalyticsSkapPage) {
    analyticsSubRoutes.push({
      listItemText: t(TRANSLATIONS.SKAP),
      routeName: '/analytics/skap',
    });
  }

  if (!userIsFromPYLOocation) {
    if (canUserAccesseffectivenessOfRoutinesPage) {
      analyticsSubRoutes.push({
        listItemText: t(TRANSLATIONS.ROUTINE_EFFECTIVENESS),
        routeName: '/analytics/team-rooms',
      });
    }
    if (canUserAccessEffectivenessOfWorkstationsPage) {
      analyticsSubRoutes.push({
        listItemText: t(TRANSLATIONS.WORKSTATIION_EFFECTIVENESS),
        routeName: '/analytics/work-station',
      });
    }
  }
  if (canUserAccessDMSPage) {
    analyticsSubRoutes.push({
      listItemText: 'DMS',
      routeName: '/analytics/driver-management-system',
    });
  }
  if (canUserAccess3rs) {
    analyticsSubRoutes.push({
      listItemText: '3Rs',
      routeName: '/analytics/3Rs',
    });
  }

  const incidentsSubRoutes = [] as any;
  const incidentsPilotAccess = userDetails?.get('canAccessSIOSWebChangeRequest');

  if (canUserReportSIOs) {
    incidentsSubRoutes.push({
      listItemText: `${t(TRANSLATIONS.REPORT)} ${t(TRANSLATIONS.ACIS)}`,
      routeName: '/ac',
    });
  }
  if (incidentsPilotAccess) {
    if ((isSupply && canUserAccessAssignSIOsScreen(userDetails?.get('features')))) {
      incidentsSubRoutes.push({
        listItemText: canUserAssignSIOsExecutors(userDetails?.get('features'))
          ? `${t(TRANSLATIONS.ASSIGN)} ${t(TRANSLATIONS.ACIS)}`
          : `${t(TRANSLATIONS.UNASSIGNED_SIOS)}`,
        routeName: '/incidents/assign',
      });
    }
    if ((isSupply && canUserCloseSIOS(userDetails?.get('features')))
    ) {
      incidentsSubRoutes.push({
        listItemText: `${t(TRANSLATIONS.CLOSE)} ${t(TRANSLATIONS.ACIS)}`,
        routeName: '/incidents/close',
      });
    }
  }

  routes.push(
    canUserAccessOverviewPage && {
      listItemText: t(TRANSLATIONS.OVERVIEW),
      routeName: '/analytics/overview',
      listItemIcon: gauzeIcon,
      listItemIconActive: gauzeGreenIcon,
    },
    canUserAccessAnalyticsPage && {
      listItemText: 'Analytics',
      routeName: '/analytics',
      listItemIcon: analyticsIcon,
      listItemIconActive: analyticsGreenIcon,
      collapseList:
        location?.get('slug') === 'newlands-brewery'
          ? [
            {
              listItemText: t(TRANSLATIONS.INCIDENTS),
              routeName: '/analytics/incidents',
            },
          ]
          : analyticsSubRoutes,
    },
    canUserAccessSearchUsersPage && {
      listItemText: t(TRANSLATIONS.SEARCH_USERS),
      routeName: '/users-blocked',
      listItemIcon: searchicon,
      listItemIconActive: searchicon,
    },
    canUserAccessLocationsPage && {
      listItemText: t(TRANSLATIONS.DIST_CENTERS),
      routeName: '/distribution-centers',
      listItemIcon: homeIcon,
      listItemIconActive: homeGreenIcon,
    },
  );

  if (
    incidentsSubRoutes?.length
  ) {
    routes.push(canUserAccessACIsPage && {
      listItemText: t(TRANSLATIONS.ACIS),
      routeName: '/incidents',
      listItemIcon: ImageLinks.acis,
      listItemIconActive: ImageLinks.acis2,
      collapseList: incidentsSubRoutes,
    });
  }

  if (canUserAccessMainSafetyAcademy) {
    routes.push({
      listItemText: 'Safety Academy',
      routeName: '/safety-academy',
      listItemIcon: safetyAcademyIcon,
      listItemIconActive: safetyAcademyIconGreen,
    });
  }
  if (accessDPOAcademy) {
    routes.push({
      listItemText: t(TRANSLATIONS.DPO_ACADEMY),
      routeName: '/dpo-academy',
      listItemIcon: safetyAcademyIcon,
      listItemIconActive: safetyAcademyIconGreen,
    });
  }

  if (canUserAccessBoard
  ) {
    routes.push({
      listItemText: t(TRANSLATIONS.BOARDS),
      routeName: '/action-log/board',
      listItemIcon: boardIcon,
      listItemIconActive: boardIconGreen,
    });
  }
  // if (canUserReportSIOs) {
  //   routes.push(
  //     {
  //       listItemText: `${t(TRANSLATIONS.REPORT)} ${t(TRANSLATIONS.INCIDENTS)}`,
  //       routeName: '/ac',
  //       listItemIcon: ACIcon,
  //       listItemIconActive: analyticsGreenIcon,
  //     },
  //   );
  // }

  if (canUserAccessOwdsPage) {
    routes.push({
      listItemText: t(TRANSLATIONS.OWD),
      routeName: '/owd',
      listItemIcon: owdIcon,
      listItemIconActive: owdIconGreen,
    });
  }

  if (canUserAccessUsabilitydDPOPage) {
    analyticsSubRoutes.push({
      listItemText: t(TRANSLATIONS.USABILITY_DPO),
      routeName: '/analytics/UsabilityDPO',
    });
  }

  if (canUserAccessPdtsPage || userDetails?.get('email').includes('arvolution')) {
    routes.push({
      listItemText: t(TRANSLATIONS.PDT),
      routeName: '/pdt',
      listItemIcon: owdIcon,
      listItemIconActive: owdIconGreen,
    });
  }
  if (canUserAccessAnalyticsOWDsPage) {
    analyticsSubRoutes.push({
      listItemText: t(TRANSLATIONS.OWD),
      routeName: '/analytics/owd',
    });
  }
  if (canUserAccessRoadSafetySideBarPage && canAccessRouteSafety) {
    // roadSafetySubRoutes.push({
    //   listItemText: t(TRANSLATIONS.ROAD_SAFETY_PLANNING),
    //   routeName: '/road-safety/planning',
    //   listItemIcon: '',
    //   listItemIconActive: dotGreen,
    // });
    if (canUserAccessCriticalRoutesSideBarPage) {
      roadSafetySubRoutes.push({
        listItemText: t(TRANSLATIONS.CRITICAL_PATHS),
        routeName: '/road-safety/critical-routes',
        listItemIcon: '',
        listItemIconActive: dotGreen,
      });
    }
    if (canUserAccessRoadSafetyDriverSideBarPage) {
      roadSafetySubRoutes.push({
        listItemText: t(TRANSLATIONS.DRIVER_MATRIX),
        routeName: '/road-safety/drivers',
        listItemIcon: '',
        listItemIconActive: dotGreen,
      });
    }

    if (canUserAccessRoadSafetyResourcesSideBarPage) {
      roadSafetySubRoutes.push({
        listItemText: t(TRANSLATIONS.RESOURCES),
        routeName: '/road-safety/resources',
        listItemIcon: '',
        listItemIconActive: dotGreen,
      });
    }

    routes.push({
      listItemText: t(TRANSLATIONS.ROAD_SAFETY),
      routeName: '/road-safety',
      listItemIcon: owdIcon,
      listItemIconActive: owdIconGreen,
      collapseList: roadSafetySubRoutes,
    });
  }
  return routes.filter((eachRoutes => typeof (eachRoutes) === 'object'));
};

// TODO: Build a User-Access framework and use across web-dashboard.

export default defaultRoutes;
