import FilterDropdown from 'components/Dropdown/FilterDropdown';
import { useKpIsListQuery } from 'generated/graphql';
import i18next from 'i18n';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';
import './KPIsListing.scss';

export type KPI = {
  label: string;
  value: string;
};

type Props = {
  selectedKPIs: KPI[],
  setSelectedKPIs: Function,
};

const LANG = i18next.language?.indexOf('en') === 0 ? 'en' : 'es';

const KPIsListing = (props: Props) => {
  const { t } = useTranslation();
  const {
    data,
  } = useKpIsListQuery();
  const [KPIList, setKPIList] = useState<KPI[]>([]);
  const [fullKPIList, setFullKPIList] = useState<KPI[]>([]);
  const { selectedKPIs, setSelectedKPIs } = props;

  function selectOrDeselectKPI(KPI: KPI) {
    const indexOfKPI = selectedKPIs?.indexOf(KPI);
    if (indexOfKPI! >= 0) {
      const temporarySelectedKPIs = selectedKPIs?.filter(
        selectedKPI => selectedKPI.label !== KPI.label,
      );
      setSelectedKPIs(temporarySelectedKPIs);
    } else {
      setSelectedKPIs([...selectedKPIs!, KPI]);
    }
  }

  function handleSearch(text: string) {
    const trimedText = text.trim();
    if (trimedText.length) {
      setKPIList(
        fullKPIList.filter((kpi: KPI) => kpi.label
          .toLowerCase().includes(trimedText.toLowerCase())),
      );
    } else {
      setKPIList(fullKPIList);
    }
  }

  useEffect(() => {
    const structuredKPIsFromData: KPI[] = [];
    (data?.getAllKPI || []).forEach(kpi => {
      const label = (LANG === 'en' ? kpi?.enTitle : kpi?.esTitle) || '';
      const value = kpi?.slug!;
      structuredKPIsFromData.push({ label, value });
    });
    setKPIList(structuredKPIsFromData!);
    setFullKPIList(structuredKPIsFromData!);
  }, [data]);

  function ListHeader() {
    return (
      <div className="filter-headers">
        <h2>KPIs</h2>
        <p>{t(TRANSLATIONS.SELECT_THE_KPIS_YOU_WANT_TO_SEE)}</p>
      </div>
    );
  }

  function ListItem(listItem: KPI) {
    const selected = selectedKPIs.includes(listItem);
    return (
      <div
        className="list-item KPI"
        role="button"
        onClick={() => selectOrDeselectKPI(listItem)}
      >
        <p>{listItem.label}</p>
        <div
          className={`${selected && 'badge'}`}
        />
      </div>
    );
  }

  function EmptyList() {
    return (<p>{t(TRANSLATIONS.NO_RESULTS_FOUND)}</p>);
  }

  return (
    <div>
      <FilterDropdown
        filterName={selectedKPIs.length ? `${selectedKPIs[0].label}${(selectedKPIs.length - 1) ? ` + ${selectedKPIs.length - 1}` : ''}` : t(TRANSLATIONS.ALL_KPIS)}
        ListHeaderComponent={ListHeader}
        ListItem={ListItem}
        listToRender={KPIList}
        selectedItems={selectedKPIs}
        searchBarPlaceholder={t(TRANSLATIONS.SEARCH_KPI)}
        handleSearch={handleSearch}
        EmptyList={EmptyList}
      />
    </div>
  );
};

export default KPIsListing;
