import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import defaultRoutes from 'utils/defaultRoutes';
import HeaderWithNavigation from 'components/HeaderWithNavigation';
import { selectUser, selectUserLocation, selectUserType } from 'store/selectors/user.selectors';
import SafetyAcademyOverview from 'features/analyticsWrapper/Overview/SafetyAcademyOverview/SafetyAcademyOverview';
import SearchPage from './SeearchPage/SearchPage';

import './SafetyAcademyHome.scss';

const SafetyAcademyHome = () => {
  const { t } = useTranslation();
  const userType = useSelector(selectUserType);
  const location = useSelector(selectUserLocation);
  const userDetails = useSelector(selectUser);
  const hasSearched = useSelector((state: any) => state.toJS().safetyAcademy?.hasSearched);

  // Needed to style the whole page white
  const div = document.getElementById('root');

  useEffect(() => {
    if (div && !hasSearched) {
      div.style.background = 'white';
      return () => {
        div.style.background = '#F7F7F7';
      };
    }
  }, [div, hasSearched]);

  return (
    <HeaderWithNavigation
      routes={defaultRoutes(t, userType, location, userDetails)}
      hideLocations
      showSearchbar
    >
      <div className="container-fluid overview-wrapper">
        {hasSearched ? (
          <SearchPage />
        ) : (
          <>
            <div className="safety-academy-home__top col-9">
              <h1 className="safety-academy-home__top__title">Bienvenido a <span>Safety Academy</span></h1>
              <h2 className="safety-academy-home__top__subtitle">Aquí encontraras todo el material sobre seguridad, puedes empezar viendo nuestra selección <br />o puedes utilizar el buscador arriba. </h2>
            </div>
            <div className="safety-academy-home__bottom row">
              <SafetyAcademyOverview fromHome />
            </div>
          </>
        )}
      </div>
    </HeaderWithNavigation>
  );
};

export default SafetyAcademyHome;
