import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import Iframe from 'components/Iframe';
import { TRANSLATIONS } from 'types/enums';
import { useTranslation } from 'react-i18next';
import ChartTabs from 'components/Tabs/ChartTabs';
import axios from 'axios';
import Header from 'components/Header';
import { useSelector } from 'react-redux';
import { selectLocationsToAccess, selectSelectedLocation, selectUserAccesses } from 'store/selectors/globalFilters.selectors';
import Modal from 'components/Modal';
import BasicButton from 'components/Button/BasicButton';
import ProgressBar from 'components/ProgressBar';
import ChartDropdown from 'components/Dropdown/ChartDropdown';
import './WebPortalTeamRooms.scss';
import { generateQueryDates } from 'utils/dateHelpers';
import { selectUser } from 'store/selectors/user.selectors';
import req from 'utils/request';
import { toast } from 'react-toastify';
import ButtonWithTooltip from 'components/Button/ButtonWithTooltip';

export enum DropDownEnums {
  month = 'month',
  year = 'year',
}

export enum TeamRoomsUserCategory {
  CHECK_TO_COACH = 'CheckToCoach',
  SELF_ASSESSMENT = 'SelfAssessment',
}

const validFileExtensions = ['.csv', '.xlsx'];
const Validate = (files: any) => {
  for (let i = 0; i < files.length; i++) {
    const oInput = files[i];

    const sFileName = oInput.name;
    if (sFileName.length > 0) {
      let blnValid = false;
      for (let j = 0; j < validFileExtensions.length; j++) {
        const sCurExtension = validFileExtensions[j];
        if (sFileName.substr(sFileName.length - sCurExtension.length, sCurExtension.length)
          .toLowerCase() === sCurExtension.toLowerCase()) {
          blnValid = true;
          break;
        }
      }

      if (!blnValid) {
        alert(`Sorry, ${sFileName} is invalid, allowed extensions are: ${validFileExtensions.join(', ')}`);
        return false;
      }
    }
  }

  return true;
};

const { CancelToken } = axios;

const WebPortalTeamRooms = () => {
  const { t } = useTranslation();

  const cancelSource = useRef<any>(null);
  const isRequestCancelledManually = useRef<any>(false);

  const userHasAccesses = useSelector(selectUserAccesses)?.length > 0;
  const tooltipMessage = t(TRANSLATIONS.YOU_DONT_HAVE_ACCESS_TO_LOCATION_PLEASE_CONTACT_SUPERUSER);

  const monthArr = [
    t(TRANSLATIONS.JANUARY),
    t(TRANSLATIONS.FEBRUARY),
    t(TRANSLATIONS.MARCH),
    t(TRANSLATIONS.APRIL),
    t(TRANSLATIONS.MAY),
    t(TRANSLATIONS.JUNE),
    t(TRANSLATIONS.JULY),
    t(TRANSLATIONS.AUGUST),
    t(TRANSLATIONS.SEPTEMBER),
    t(TRANSLATIONS.OCTOBER),
    t(TRANSLATIONS.NOVEMBER),
    t(TRANSLATIONS.DECEMBER),
  ];

  const getLatestYearsAndCurrentYear = () => {
    const localDate: Date = new Date();
    const baseYear: number = 2021;
    const currentYear: number = localDate.getUTCFullYear();
    const years: any[] = [];

    for (let i = baseYear; i <= currentYear; i++) {
      const currentYearToString: string = i.toString();

      years.push({
        label: currentYearToString,
        slug: currentYearToString,
        year: i,
      });
    }

    return years.reverse();
  };

  const monthOptions: any = monthArr.map((monthSlug, monthIndex) => ({
    slug: monthSlug,
    label: monthSlug.substring(0, 1).toUpperCase() + monthSlug.substring(1),
    month: monthSlug,
    monthNumber: monthIndex + 1,
  }));

  const yearOptions = getLatestYearsAndCurrentYear();

  const getCurrentMonth = () => {
    const localDate: Date = new Date();
    const monthIndexToGet: number = localDate.getUTCMonth();

    return monthOptions[monthIndexToGet];
  };

  const getCurrentYear = () => {
    const localDate: Date = new Date();
    const currentYear: number = localDate.getUTCFullYear();
    const yearDataToReturn = yearOptions.find(year => Number(year.label) === currentYear);
    return yearDataToReturn ?? yearOptions[0];
  };
  const fileRef = useRef<HTMLInputElement>(null);
  const userCategory = useRef<any>(null);
  const userDetails = useSelector(selectUser);
  const [loading, setLoading] = useState<boolean>(false);
  const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false);
  const [showProgressBar, setShowProgressBar] = useState<boolean>(false);
  const [progressPercentage, setProgressPercentage] = useState<number>(0);
  const [downloadError, setDownloadError] = useState<boolean>(false);
  const [sentViaEmail, setSentViaEmail] = useState<boolean>(false);
  const [isDropDownOpen, setIsDropDownOpen] = useState<DropDownEnums | undefined>(undefined);
  const [selectedMonth, setSelectedMonth] = useState(getCurrentMonth());
  const [selectedYear, setSelectedYear] = useState(getCurrentYear());

  const locations = useSelector(selectLocationsToAccess);
  const isDCSelected = useSelector(selectSelectedLocation);

  const dropDownOpenCloseHandler = (opened: boolean, dropDownType: DropDownEnums) => {
    return opened ? setIsDropDownOpen(dropDownType) : setIsDropDownOpen(undefined);
  };

  const validMonthOptions: any = useMemo(() => {
    const localDate: Date = new Date();
    const currentYear: number = localDate.getUTCFullYear();
    if (selectedYear.year === currentYear) {
      const currentMonthIndex = localDate.getUTCMonth();
      const possibleMonths = monthOptions.slice(0, currentMonthIndex + 1);
      if (isDCSelected?.locationSlug) {
        return [
          { slug: t(TRANSLATIONS.ALL), label: t(TRANSLATIONS.ALL), allMonths: true },
          ...possibleMonths,
        ];
      }
      return possibleMonths;
    }
    if (isDCSelected?.locationSlug) {
      return [
        { slug: t(TRANSLATIONS.ALL), label: t(TRANSLATIONS.ALL), allMonths: true },
        ...monthOptions,
      ];
    }
    return monthOptions;
    // eslint-disable-next-line
  }, [selectedYear, isDCSelected]);

  useEffect(() => {
    if (!isDCSelected?.locationSlug) {
      setSelectedMonth(validMonthOptions[0]);
    }
  // eslint-disable-next-line
  }, [isDCSelected]);

  const tabs = [
    { title: t(TRANSLATIONS.RESULTS), value: '', type: TRANSLATIONS.RESULTS, index: 0 },
    { title: 'GAPs', value: '', type: 'GAPs', index: 1 },
    { title: t(TRANSLATIONS.OPENING_OF_QUESTIONS), value: '', type: TRANSLATIONS.OPENING_OF_QUESTIONS, index: 2 },
    { title: `C2C ${t(TRANSLATIONS.RESULTS)}`, value: '', type: `C2C ${TRANSLATIONS.RESULTS}`, index: 3 },
    { title: 'C2C GAPs', value: '', type: 'C2C GAPs', index: 4 },
  ];
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const closeModal = () => {
    setSentViaEmail(false);
    setDownloadError(false);
    setShowDownloadModal(false);
    setShowProgressBar(false);
    setProgressPercentage(0);
  };

  const onPressCancelRequest = () => {
    setSentViaEmail(false);
    setDownloadError(false);
    setShowDownloadModal(false);
    setShowProgressBar(false);
    setProgressPercentage(0);
    if (cancelSource?.current) {
      isRequestCancelledManually.current = true;
      cancelSource.current.cancel();
    }
  };

  const onClickDownload = () => {
    setShowDownloadModal(true);
  };

  const onClickExtraMaterial = () => {
    window.open(
      'https://arvolution-safety-development.s3.us-east-2.amazonaws.com/Team+Rooms/MaterialExtra.pptx',
      '_blank',
      'noreferrer',
    );
  };

  const downloadExcel = useCallback(
    async () => {
      cancelSource.current = CancelToken.source();
      try {
        setSentViaEmail(false);
        setDownloadError(false);
        setShowDownloadModal(true);
        setShowProgressBar(true);
        const { timezoneOffsetStart } = generateQueryDates();
        const url = `/api/v1/team-rooms/download-excel?locationIds=${JSON.stringify(locations)}&timezoneOffset=${timezoneOffsetStart}`;
        const res = await axios({
          url,
          method: 'GET',
          onDownloadProgress: (progressEvent: any) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setProgressPercentage(percentCompleted);
          },
          params: {
            month: selectedMonth?.monthNumber,
            year: selectedYear?.slug,
            locations: locations?.toString(),
            timezoneOffset: timezoneOffsetStart,
          },
          cancelToken: cancelSource.current.token,
        });
        if (res.data?.sentViaEmail) {
          return setSentViaEmail(true);
        }
        const downloadUrl = res.data;
        const a = document.createElement('a');
        a.href = downloadUrl;
        setShowDownloadModal(false);
        setShowProgressBar(false);
        setProgressPercentage(0);
        a.click();
      } catch (error) {
        if (!isRequestCancelledManually.current) {
          setDownloadError(true);
          setProgressPercentage(0);
          isRequestCancelledManually.current = false;
        }
      }
    }, [locations, selectedMonth, selectedYear],
  );

  const handleDropMonthChange = ({ key }: { key: any }) => {
    const selectedDropDownMonth = validMonthOptions.find(
      (item: { slug: any; }) => item.slug === key,
    );
    setSelectedMonth(selectedDropDownMonth || validMonthOptions[0]);
    setIsDropDownOpen(undefined);
  };

  const handleDropYearChange = ({ key }: { key: any }) => {
    const selectedDropDownYear = yearOptions.find(item => item.slug === key);
    setSelectedYear(selectedDropDownYear || yearOptions[0]);
    setIsDropDownOpen(undefined);
  };

  const handleUploadButton = (uploadUserCategory: TeamRoomsUserCategory) => {
    if (fileRef.current !== null) {
      fileRef.current.click();
      userCategory.current = uploadUserCategory;
    }
  };

  const handleAttachmentsUpload = async (e: any) => {
    const { files } = e.target;
    if (files?.length && Validate(files)) {
      const config = {
        headers: { 'content-type': 'multipart/form-data' },
      };
      const data = new FormData();

      data.set('file', files[0]);
      data.set('userCategory', userCategory.current);

      try {
        setLoading(true);
        await req.post('/api/v1/team-rooms/checklist-excel', data, config);
        setLoading(false);
        toast.success('Uploaded Successfully!');
      } catch (err) {
        setLoading(false);
        toast.error('Error while uploading');
      } finally {
        if (fileRef.current !== null) {
          setLoading(false);
          fileRef.current.value = '';
          userCategory.current = null;
        }
      }
    }
  };

  useEffect(() => {
    const currentYear: number = (new Date()).getUTCFullYear();
    if (selectedYear.year === currentYear) {
      const currentMonthIndex = (new Date()).getMonth();
      if (selectedMonth.monthNumber > currentMonthIndex + 1) {
        handleDropMonthChange({ key: monthArr[currentMonthIndex] });
      }
    }

  // eslint-disable-next-line
  }, [validMonthOptions]);

  return (
    <div>
      {showDownloadModal && (
      <Modal
        modalContainerStyles={{ width: '70vw',
          left: '20%',
          maxHeight: '90vh',
          minHeight: '20vh',
          height: 'fit-content',
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)' }}
      >
        <div className="downloadMoal">
          <p className="downloadMoal__title">{downloadError ? `${t(TRANSLATIONS.SOMETHING_WENT_WRONG)}...` : t(TRANSLATIONS.DOWNLOAD_DATA)}</p>
          {showProgressBar ? (
            <>
              <p className="downloadMoal__downloading_title">{downloadError ? t(TRANSLATIONS.DOWNLOAD_FAIL_RETRY_THE_DOWNLOAD_PLEASE) : sentViaEmail ? t(TRANSLATIONS.THE_DATA_IS_TAKING_A_LONG_TIME_TO_DOWNLOAD_A_DOWNLOAD_LINK_WILL_BE_SENT_TO_YOUR_EMAIL_TO_CONTINUE) : t(TRANSLATIONS.DOWNLOADING_DATA)}</p>
              {!downloadError && !sentViaEmail && (
              <div className="downloadMoal__progress_bar">
                <p className="downloadMoal__progress_bar__title">{progressPercentage}%</p>
                <ProgressBar bgcolor="#4472C4" height={16} progress={progressPercentage} />
              </div>
              )}
            </>
          ) : (
            <>
              <p className="downloadMoal__description">{t(TRANSLATIONS.THE_DATA_FOR_THE_LOCATIONS_THAT_ARE_FILTERED_IN_THE_TOP_FILTER_BAR_WILL_BE_DOWNLOADED)}</p>
              <div className="downloadMoal__header">
                <Header showLogo={false} showSettings={false} />
              </div>
              <div className="downloadMoal__yearFilter">
                <div>
                  <p className="downloadMoal__yearFilter__yearTitle">{t(TRANSLATIONS.YEAR)}</p>
                  <ChartDropdown
                    onChange={handleDropYearChange}
                    selectedDropDownItem={selectedYear}
                    dropDownItems={yearOptions}
                    dropDownOpen={isDropDownOpen === DropDownEnums.year}
                    setDropDownOpen={
                      (opened: boolean) => dropDownOpenCloseHandler(opened, DropDownEnums.year)
                    }
                    labelClassName="label-default"
                    dropdownArrowColor="#2B323B"
                  />
                </div>
                <div>
                  <p className="downloadMoal__yearFilter__monthTitle">{t(TRANSLATIONS.MONTH)}</p>
                  <ChartDropdown
                    onChange={handleDropMonthChange}
                    selectedDropDownItem={selectedMonth}
                    dropDownItems={validMonthOptions}
                    dropDownOpen={isDropDownOpen === DropDownEnums.month}
                    setDropDownOpen={
                      (opened: boolean) => dropDownOpenCloseHandler(opened, DropDownEnums.month)
                    }
                    labelClassName="label-default"
                    dropdownArrowColor="#2B323B"
                  />
                </div>
              </div>
            </>
          )}
          {!sentViaEmail ? (
            <div className="downloadMoal__button_container">
              {downloadError ? (
                <BasicButton text={t(TRANSLATIONS.RETRY_AGAIN)} className="downloadMoal__button_container__download_button" onClick={downloadExcel} />
              ) : (
                <BasicButton disabled={showProgressBar} text={t(TRANSLATIONS.DOWNLOAD)} className="downloadMoal__button_container__download_button" onClick={downloadExcel} />
              )}
              <BasicButton text={t(TRANSLATIONS.CANCEL)} className="downloadMoal__button_container__cancel_button" onClick={showProgressBar ? onPressCancelRequest : closeModal} />
            </div>
          ) : (
            <div className="downloadMoal__continue_button_container">
              <BasicButton text={t(TRANSLATIONS.CONTINUE)} className="downloadMoal__continue_button_container__continue_button" onClick={closeModal} />
            </div>
          )}
        </div>
      </Modal>
      )}
      <div className="chartHeader">
        <h4 className="chartHeader__title">{t(TRANSLATIONS.ROUTINE_EFFECTIVENESS)}</h4>
        <div className="upload_container">
          <ButtonWithTooltip text={t(TRANSLATIONS.DOWNLOAD_DATA)} className="chartHeader__download_button" onClick={onClickDownload} disabled={!userHasAccesses} tooltipMessage={tooltipMessage} />
          <BasicButton text={t(TRANSLATIONS.EXTRA_MATERIAL)} className="chartHeader__download_button" onClick={onClickExtraMaterial} />
          {userDetails?.get('email').includes('arvolution') && (
          <div className="upload_button">
            <BasicButton
              text="Upload Self-Assessment Checklist"
              className="upload_button_filled"
              onClick={() => handleUploadButton(TeamRoomsUserCategory.SELF_ASSESSMENT)}
              disabled={loading}
            />
            <BasicButton
              text="Upload Check-To-Coach Checklist"
              className="upload_button_filled"
              onClick={() => handleUploadButton(TeamRoomsUserCategory.CHECK_TO_COACH)}
              disabled={loading}
            />
            <input
              ref={fileRef}
              className="hidden"
              type="file"
              onChange={handleAttachmentsUpload}
              name="file"
              multiple
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            />
          </div>
          )}
        </div>
      </div>
      <ChartTabs
        tabsItems={tabs}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab as any}
        selectedTabStyle="selected-tab"
        customStyle="tabs-container"
      />
      {selectedTab.index === 0 && (
        <Iframe
          iframe="https://app.powerbi.com/view?r=eyJrIjoiNDZhZWVmM2EtZmI3Zi00YzJlLTgzMGUtN2NjODExZDM5ZTMyIiwidCI6ImJlMTliNmNlLTdkMTktNGVkOS05NDgzLWIzM2I5NDI3ZTc4YyJ9"
          iframeTitle={t(TRANSLATIONS.RESULTS)}
          pageName="ReportSection"
        />
      )}
      {selectedTab.index === 1 && (
        <Iframe
          iframe="https://app.powerbi.com/view?r=eyJrIjoiNDZhZWVmM2EtZmI3Zi00YzJlLTgzMGUtN2NjODExZDM5ZTMyIiwidCI6ImJlMTliNmNlLTdkMTktNGVkOS05NDgzLWIzM2I5NDI3ZTc4YyJ9"
          iframeTitle="GAPs"
          pageName="ReportSectionca92a33be65c29947a67"
        />
      )}
      {selectedTab.index === 2 && (
        <Iframe
          iframe="https://app.powerbi.com/view?r=eyJrIjoiNDZhZWVmM2EtZmI3Zi00YzJlLTgzMGUtN2NjODExZDM5ZTMyIiwidCI6ImJlMTliNmNlLTdkMTktNGVkOS05NDgzLWIzM2I5NDI3ZTc4YyJ9"
          iframeTitle={t(TRANSLATIONS.OPENING_OF_QUESTIONS)}
          pageName="ReportSection34c70d2e6be919673257"
        />
      )}
      {selectedTab.index === 3 && (
        <Iframe
          iframe="https://app.powerbi.com/view?r=eyJrIjoiNDZhZWVmM2EtZmI3Zi00YzJlLTgzMGUtN2NjODExZDM5ZTMyIiwidCI6ImJlMTliNmNlLTdkMTktNGVkOS05NDgzLWIzM2I5NDI3ZTc4YyJ9"
          iframeTitle={`C2C ${t(TRANSLATIONS.RESULTS)}`}
          pageName="ReportSection5d8f27c2d1a30ceedfc5"
        />
      )}
      {selectedTab.index === 4 && (
        <Iframe
          iframe="https://app.powerbi.com/view?r=eyJrIjoiNDZhZWVmM2EtZmI3Zi00YzJlLTgzMGUtN2NjODExZDM5ZTMyIiwidCI6ImJlMTliNmNlLTdkMTktNGVkOS05NDgzLWIzM2I5NDI3ZTc4YyJ9"
          iframeTitle="C2C GAPs"
          pageName="ReportSection1512119d83f7096fff26"
        />
      )}
    </div>
  );
};

export default WebPortalTeamRooms;
