import React from 'react';
import { useCubeQuery } from '@cubejs-client/react';
import { Query } from '@cubejs-client/core';
import Chart from 'react-apexcharts';
import getCashlessQuery from 'utils/CubeObject';
import { getCountryFromISOCode } from 'utils/countryCodes';
import { groupBy } from 'lodash';
import LoadingSpinner from 'components/LoadingSpinner';
import EmptyState from 'components/EmptyState/EmptyState';
import './CashlessBar.scss';

const CashlessBarCAC = ({
  dateRange,
}: { dateRange: string | string[] }) => {
  const { resultSet: cashlessAvgTotal, isLoading } = useCubeQuery(
    getCashlessQuery({
      measure: ['AnalyticsCashless.countGral'],
      dimensions: [
        'AnalyticsCashless.Zones',
        'AnalyticsCashless.ZonesSlug',
        'AnalyticsCashless.locaCountry',
        'AnalyticsCashless.condition',
      ],
      dateOptions: {
        dateRange,
        granularity: 'month',
        dimension: 'AnalyticsCashless.date',
      },
      filterOptions: [{
        dimension: 'AnalyticsCashless.BusinessUnits',
        operator: 'equals',
        values: ['cac'],
      }, {
        dimension: 'AnalyticsCashless.condition',
        operator: 'equals',
        values: [
          '1',
          '0',
          '3',
        ],
      }],
    }) as Query,
  );
  const { resultSet: cashlessAvgReal, isLoading: isLoadingReal } = useCubeQuery(
    getCashlessQuery({
      measure: ['AnalyticsCashless.countGral'],
      dimensions: [
        'AnalyticsCashless.Zones',
        'AnalyticsCashless.ZonesSlug',
        'AnalyticsCashless.locaCountry',
        'AnalyticsCashless.condition',
      ],
      dateOptions: {
        dateRange,
        granularity: 'month',
        dimension: 'AnalyticsCashless.date',
      },
      filterOptions: [{
        dimension: 'AnalyticsCashless.BusinessUnits',
        operator: 'equals',
        values: ['cac'],
      }, {
        dimension: 'AnalyticsCashless.condition',
        operator: 'equals',
        values: [
          '1',
          '3',
        ],
      }],
    }) as Query,
  );

  const data: any[] = [];

  if (cashlessAvgTotal?.tablePivot() && cashlessAvgReal?.tablePivot()) {
    const realData = cashlessAvgReal?.tablePivot();
    const totalData = cashlessAvgTotal?.tablePivot();

    const totalDataG = groupBy(totalData, 'AnalyticsCashless.ZonesSlug');
    const realDataG = groupBy(realData, 'AnalyticsCashless.ZonesSlug');

    Object.entries(totalDataG).forEach(([key, value]) => {
      let name;
      let zone;
      let total = 0;
      let real = 0;
      let label;

      value.forEach(item => {
        name = getCountryFromISOCode(item['AnalyticsCashless.locaCountry'] as string, 'es')?.name;
        total += (item['AnalyticsCashless.countGral'] as number);
        real = realDataG[key]
          ?.filter(i => i['AnalyticsCashless.ZonesSlug'] === key)
          .reduce((acum, current) => acum + (current['AnalyticsCashless.countGral'] as number), 0);
      });

      data.push({
        name,
        zone,
        label,
        average: `${Number((real / total) * 100).toFixed(2)}%`,
      });
    });
  }

  const options = {
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false,
      },
    },
    stroke: {
      lineCap: 'round',
    },
    plotOptions: {
      bar: {
        columnWidth: '60%',
      },
    },
    colors: ['#777DB9', '#F4C44C'],
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: data?.map(location => location.name),
    },
    yaxis: {
      max: 100,
      min: 0,
      labels: {
        formatter(val: number) {
          return `${val.toFixed(0)}%`;
        },
      },
    },
    legend: {
      show: true,
      showForSingleSeries: false,
      customLegendItems: ['Actual', 'Expected'],
      markers: {
        fillColors: ['#777DB9', '#F4C44C'],
      },
    },
  };

  const series = [{
    name: 'Actual',
    data: data?.map((location: any) => location.average),
    type: 'column',
  }, {
    name: 'Budget',
    // 70 is expected to be the current budget for the client
    // they asked us to change: JIRA SFTY-1091
    data: data?.map(() => [70]),
    type: 'line',
  }];

  if (isLoading || isLoadingReal) return <LoadingSpinner height={231} />;

  return (
    <div className="cashless-bar">
      {series[0].data && series[0].data.length > 0 ? (
        <Chart
          type="line"
          // @ts-ignore
          options={options}
          series={series}
          height="300px"
        />
      ) : (
        <EmptyState />
      )}
    </div>
  );
};

export default CashlessBarCAC;
