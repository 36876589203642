import { TFunction } from 'i18next';
import { HEALTH_STATUS } from 'types/enums';

export default (t: TFunction) => [
  {
    label: t(HEALTH_STATUS.STABLE),
    value: HEALTH_STATUS.STABLE,
  },
  {
    label: t(HEALTH_STATUS.STABLE_WITH_OXYGEN),
    value: HEALTH_STATUS.STABLE_WITH_OXYGEN,
  },
  {
    label: t(HEALTH_STATUS.SERIOUS_WITH_OXYGEN),
    value: HEALTH_STATUS.SERIOUS_WITH_OXYGEN,
  },
  {
    label: t(HEALTH_STATUS.SERIOUS_WITHOUT_OXYGEN),
    value: HEALTH_STATUS.SERIOUS_WITHOUT_OXYGEN,
  },
];
