import * as React from 'react';
import { MenuItem, Select } from '@mui/material';
import { format, intervalToDuration } from 'date-fns';
import { useUpdateUserHealthStatusUnAuthenticatedMutation } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';
import quarantineHealthStatusPickerOptions from '../quarantineHealthStatusPickerOptions';
import { getStatusOptions } from '../helper';

type Props = {
  healthStatus: any;
  slug: string;
  userName: string;
  changeStep: (step: number) => void;
  setStatus: React.Dispatch<React.SetStateAction<string>>;
  status: string;
  location: string;
  setLocation: React.Dispatch<React.SetStateAction<string>>;
};
function UpdateStatus(props: Props) {
  const {
    healthStatus,
    slug,
    changeStep,
    userName,
    status,
    setStatus,
    location,
    setLocation,
  } = props;
  const { t } = useTranslation();
  const dropDownOptions = quarantineHealthStatusPickerOptions(t);
  const [updateStatus] = useUpdateUserHealthStatusUnAuthenticatedMutation();

  function handleStatusSelection(event: React.ChangeEvent<{ value: unknown }>) {
    setStatus(event.target.value as string);
  }
  function isButtonDisabled() {
    return !(location !== healthStatus?.location || status !== healthStatus?.status);
  }

  async function handleUpdateStatus() {
    if (isButtonDisabled()) return;
    try {
      const response = await updateStatus({
        variables: {
          location: location || healthStatus.location,
          status: status || healthStatus.status,
          slug,
        },
      });
      if (response.data?.addUserHealthStatusUnAuthenticated.isSuccess) {
        changeStep(1);
      } else {
        setStatus(healthStatus.status);
        setLocation(healthStatus.location);
      }
    } catch (error) {
      console.log('e handleUpdateStatus()', error);
    }
  }
  return (
    <section className="update_page_container">
      <div className="upper_section">
        <p className="text1 mb-5">
          {intervalToDuration({ end: new Date(), start: new Date(healthStatus.createdAt) })?.days
            ? t(TRANSLATIONS.LAST_UPDATED_ON_MONTH_DATE_DAY, {
              month: format(new Date(healthStatus.createdAt), 'MMM'),
              date: format(new Date(healthStatus.createdAt), 'dd'),
              day: format(new Date(healthStatus.createdAt), 'Do'),
            })
            : t(TRANSLATIONS.LAST_UPDATED_TODAY)}
        </p>
        <p className="text1 mb-2">
          {t(TRANSLATIONS.UPDATE_USER_STATUS_TODAY_MONTH_DATE, {
            month: format(new Date(healthStatus.createdAt), 'MMM'),
            date: format(new Date(healthStatus.createdAt), 'dd'),
            user: userName,
          })}
        </p>
      </div>
      <div className="status_section">
        <p className="status_question">{t(TRANSLATIONS.WHERE_IS_USER, { user: userName })}</p>
        <div className="status_option_container">
          {getStatusOptions(t).map(statusOption => (
            <div
              role="presentation"
              onClick={() => setLocation(statusOption.label)}
              className={`single_status_option mt-4 ${
                (location || healthStatus.location) === statusOption.label ? 'active_option' : ''
              }`}
            >
              <img
                className="status_option_image"
                src={statusOption.image}
                alt={statusOption.name}
              />
              <p className="status_option_name"> {statusOption.name}</p>
            </div>
          ))}
        </div>
        <p className="status_question mb-3">{t([TRANSLATIONS.PHYSICAL_STATE])}</p>
        <Select
          inputProps={{ 'aria-label': 'Without label' }}
          id="demo-simple-select"
          value={status || healthStatus.status}
          label="Age"
          className="responsive_select"
          variant="outlined"
          // @ts-ignore
          onChange={handleStatusSelection}
        >
          {dropDownOptions.map(option => (
            <MenuItem key={option.value} className="menu_item" value={option.value}>
              {t(TRANSLATIONS[option.value])}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div className="root_padding">
        <p className="text1 last_text">
          {t(
            // eslint-disable-next-line max-len
            TRANSLATIONS.REMEMBER_THAT_SENDING_THIS_INFORMATION_IS_VERY_IMPORTANT_FOR_US_TO_GIVE_YOU_THE_BEST_POSSIBLE_SUPPORT_THROUGH_THE_QUARANTINE_PROCESS,
          )}
        </p>
      </div>
      <div className="root_padding submit_section">
        <button
          type="button"
          className={`submit_btn ${isButtonDisabled() ? 'btn_disabled' : ''}`}
          onClick={handleUpdateStatus}
        >
          {t(TRANSLATIONS.SAVE)}
        </button>
      </div>
    </section>
  );
}

export default UpdateStatus;
