import React from 'react';
import { useTranslation } from 'react-i18next';

import noData from 'assets/icons/no-data.svg';

import './EmptyState.scss';
import { TRANSLATIONS } from 'types/enums';

interface Props {
  label?: string;
  className?: string;
  description?: string
}

const EmptyState = ({
  label,
  className,
  description,
}: Props) => {
  const { t } = useTranslation();
  return (
    <div className={`empty-state ${className}`}>
      <img src={noData} alt="No data" />
      {
        !label ? (
          <>
            <span>{t(TRANSLATIONS.NO_DATA)}</span>
            <p>{t(TRANSLATIONS.NO_DATA_FOUND_SO_FAR)}</p>
          </>
        ) : (
          <>
            <span>{label}</span>
            <div>{description}</div>
          </>
        )
      }
    </div>
  );
};

export default EmptyState;
