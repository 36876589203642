import React, { useRef, useState, useEffect, useMemo } from 'react';
import './CriticalPaths.scss';
import { useTranslation } from 'react-i18next';
import { ROAD_SAFETY_ROUTE_STATUS, TRANSLATIONS } from 'types/enums';
import { toast } from 'react-toastify';
import Modal from 'components/Modal';
import axios from 'axios';
import ProgressBar from 'components/ProgressBar';
import BasicButton from 'components/Button/BasicButton';
import CustomTable from 'components/Table';
import ImageLinks from 'utils/ImageLinks';
import LoadingSpinner from 'components/LoadingSpinner';
import { canUserAccessRoadSafetyCriticalRoutesActions } from 'utils/shared/canUserAccessFeature';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/selectors/user.selectors';
import { format } from 'date-fns';
import i18next from 'i18next';
import { es, enUS } from 'date-fns/locale';
import Header from 'components/Header';
import ChartDropdown from 'components/Dropdown/ChartDropdown';
import { useNavigate } from 'react-router-dom';
import {
  selectSelectedLocationsSlug,
  selectSelectedLocation,
  selectGlobalFilters,
  selectLocationsToAccess,
} from 'store/selectors/globalFilters.selectors';
import { useGetExternalRoutesByLocationSlugLazyQuery, useGetLatestChecklistAnsweredAtByLocationSlugLazyQuery } from 'generated/graphql';
import { generateQueryDates } from 'utils/dateHelpers';

const { CancelToken } = axios;

export enum CriticalPathDropDowns {
  month = 'month',
  year = 'year',
}

const CriticalPaths = () => {
  const { t } = useTranslation();
  const loggedInUser = useSelector(selectUser);
  const { email: loggedInUserEmail } = loggedInUser.toJS();
  const fileRef = useRef<HTMLInputElement>(null);
  const fileRefForChecklistTemplate = useRef<HTMLInputElement>(null);

  const cancelSource = useRef<any>(null);
  const isRequestCancelledManually = useRef<any>(false);

  const monthArr = [
    t(TRANSLATIONS.JANUARY),
    t(TRANSLATIONS.FEBRUARY),
    t(TRANSLATIONS.MARCH),
    t(TRANSLATIONS.APRIL),
    t(TRANSLATIONS.MAY),
    t(TRANSLATIONS.JUNE),
    t(TRANSLATIONS.JULY),
    t(TRANSLATIONS.AUGUST),
    t(TRANSLATIONS.SEPTEMBER),
    t(TRANSLATIONS.OCTOBER),
    t(TRANSLATIONS.NOVEMBER),
    t(TRANSLATIONS.DECEMBER),
  ];

  const getLatestYearsAndCurrentYear = () => {
    const localDate: Date = new Date();
    const baseYear: number = 2021;
    const currentYear: number = localDate.getUTCFullYear();
    const years: any[] = [];

    for (let i = baseYear; i <= currentYear; i++) {
      const currentYearToString: string = i.toString();

      years.push({
        label: currentYearToString,
        slug: currentYearToString,
        year: i,
      });
    }

    return years.reverse();
  };

  const monthOptions: any = monthArr.map((monthSlug, monthIndex) => ({
    slug: monthSlug,
    label: monthSlug.substring(0, 1).toUpperCase() + monthSlug.substring(1),
    month: monthSlug,
    monthNumber: monthIndex + 1,
  }));

  const yearOptions = getLatestYearsAndCurrentYear();

  const getCurrentMonth = () => {
    const localDate: Date = new Date();
    const monthIndexToGet: number = localDate.getUTCMonth();

    return monthOptions[monthIndexToGet];
  };

  const getCurrentYear = () => {
    const localDate: Date = new Date();
    const currentYear: number = localDate.getUTCFullYear();
    const yearDataToReturn = yearOptions.find(year => Number(year.label) === currentYear);
    return yearDataToReturn ?? yearOptions[0];
  };

  const userDetails = useSelector(selectUser);
  const { location } = useSelector(selectSelectedLocationsSlug);
  const selectedDC = useSelector(selectSelectedLocation);
  const globalFilters = useSelector(selectGlobalFilters);
  const isDCSelected = useSelector(selectSelectedLocation);

  const locations = useSelector(selectLocationsToAccess);

  const [sentViaEmail, setSentViaEmail] = useState<boolean>(false);
  const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
  const [progressPercentage, setProgressPercentage] = useState<number>(0);
  const [uploadError, setUploadError] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<any>('');
  const [currentFileUploadType, setCurrentFileUploadType] = useState<'routesFile' | 'checklistTemplate' | null>(null);
  const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false);
  const [showProgressBar, setShowProgressBar] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [downloadProgressPercentage, setDownloadProgressPercentage] = useState<number>(0);
  const [downloadError, setDownloadError] = useState<boolean>(false);
  const [
    isDropDownOpen,
    setIsDropDownOpen] = useState<CriticalPathDropDowns | undefined>(undefined);
  const [selectedMonth, setSelectedMonth] = useState(getCurrentMonth());
  const [selectedYear, setSelectedYear] = useState(getCurrentYear());

  const canUserAccessRoadSafetyCriticalRoutes = canUserAccessRoadSafetyCriticalRoutesActions(
    userDetails?.get('features'),
    userDetails?.get('position')?.toUpperCase(),
  );

  const navigate = useNavigate();

  const [callGetExternalRoutesByLocationSlugLazyQuery,
    { data: routesData, loading }] = useGetExternalRoutesByLocationSlugLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const [callGetLatestChecklistAnsweredAtByLocationSlugLazyQuery,
    { data: lastUploadedData }] = useGetLatestChecklistAnsweredAtByLocationSlugLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const callGetExternalRoutesByLocationSlugApi = () => {
    callGetExternalRoutesByLocationSlugLazyQuery({
      variables: {
        locationSlug: selectedDC?.locationSlug,
      },
    });
  };

  const callGetLatestChecklistAnsweredAtByLocationSlugApi = () => {
    callGetLatestChecklistAnsweredAtByLocationSlugLazyQuery({
      variables: {
        locationSlug: selectedDC?.locationSlug,
      },
    });
  };

  useEffect(() => {
    if (selectedDC?.locationSlug) {
      callGetLatestChecklistAnsweredAtByLocationSlugApi();
      callGetExternalRoutesByLocationSlugApi();
    }
  // eslint-disable-next-line
  }, [selectedDC?.locationSlug]);

  const handleCriticalPathFileUpload = () => {
    if (fileRef.current !== null) {
      setCurrentFileUploadType('routesFile');
      fileRef.current.click();
    }
  };

  const uploadFile = () => {
    if (fileRef.current !== null) {
      fileRef.current.value = '';
    }
    handleCriticalPathFileUpload();
  };

  const handleChecklistTemplateUpload = () => {
    if (fileRefForChecklistTemplate.current !== null) {
      setCurrentFileUploadType('checklistTemplate');
      fileRefForChecklistTemplate.current.click();
    }
  };

  const uploadChecklistTemplate = () => {
    if (fileRefForChecklistTemplate.current !== null) {
      fileRefForChecklistTemplate.current.value = '';
    }
    handleChecklistTemplateUpload();
  };

  const onPressCancelRequest = () => {
    setShowUploadModal(false);
    setUploadError(false);
    setProgressPercentage(0);
    if (cancelSource?.current) {
      isRequestCancelledManually.current = true;
      cancelSource.current.cancel();
    }
  };

  const onPressCancelDownloadRequest = () => {
    setDownloadError(false);
    setShowDownloadModal(false);
    setShowProgressBar(false);
    setDownloadProgressPercentage(0);
    if (cancelSource?.current) {
      isRequestCancelledManually.current = true;
      cancelSource.current.cancel();
    }
  };

  const closeModal = () => {
    setProgressPercentage(0);
    setShowUploadModal(false);
    setUploadError(false);
    setCurrentFileUploadType(null);
  };

  const onDownloadRS7Excel = async () => {
    cancelSource.current = CancelToken.source();
    try {
      setSentViaEmail(false);
      setDownloadError(false);
      setShowDownloadModal(true);
      setShowProgressBar(true);
      const url = '/api/v1/road-safety/routes-report-rs7';
      const { timezoneOffsetStart } = generateQueryDates();
      const res = await axios({
        url,
        method: 'GET',
        onDownloadProgress: (progressEvent: any) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setProgressPercentage(percentCompleted);
        },
        params: {
          month: selectedMonth?.monthNumber,
          year: selectedYear?.slug,
          locations: locations?.toString(),
          timezoneOffset: timezoneOffsetStart,
        },
        cancelToken: cancelSource.current.token,
      });
      if (res.data?.sentViaEmail) {
        return setSentViaEmail(true);
      }
      const downloadUrl = res.data;
      const a = document.createElement('a');
      a.href = downloadUrl;
      setShowDownloadModal(false);
      setShowProgressBar(false);
      setProgressPercentage(0);
      a.click();
    } catch (error) {
      if (!isRequestCancelledManually.current) {
        setDownloadError(true);
        setProgressPercentage(0);
        isRequestCancelledManually.current = false;
      }
    }
  };

  const handleAttachmentsUpload = async (e: any) => {
    cancelSource.current = CancelToken.source();
    setShowUploadModal(true);
    setUploadError(false);
    setProgressPercentage(0);
    const { files } = e.target;
    if (files?.length) {
      setSelectedFile(files);
      const config = {
        headers: { 'content-type': 'multipart/form-data' },
      };
      const data = new FormData();

      data.set('file', files[0]);
      data.set('uploadedByUserEmail', userDetails?.get('email'));
      data.set('uploadedByUserName', userDetails?.get('name'));
      data.set('locationSlug', selectedDC?.locationSlug);

      try {
        await axios({
          url: currentFileUploadType === 'routesFile' ? '/api/v1/routeSafety/answer-checklist-excel' : '/api/v1/routeSafety/checklist-excel',
          method: 'POST',
          onDownloadProgress: (progressEvent: any) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setProgressPercentage(percentCompleted);
          },
          cancelToken: cancelSource.current.token,
          data,
          params: config,
        });
        toast.success('Uploaded Successfully!');
        closeModal();
        callGetLatestChecklistAnsweredAtByLocationSlugApi();
        callGetExternalRoutesByLocationSlugApi();
        if (fileRef.current !== null) {
          fileRef.current.value = '';
        }
      } catch {
        setUploadError(true);
      }
      // e.target.value = '';
    }
  };

  const handleAttachmentsRetryUpload = async () => {
    cancelSource.current = CancelToken.source();
    setShowUploadModal(true);
    setUploadError(false);
    setProgressPercentage(0);
    if (selectedFile?.length) {
      const config = {
        headers: { 'content-type': 'multipart/form-data' },
      };
      const data = new FormData();

      data.set('file', selectedFile[0]);
      data.set('uploadedByUserEmail', userDetails?.get('email'));
      data.set('uploadedByUserName', userDetails?.get('name'));
      data.set('locationSlug', selectedDC?.locationSlug);

      try {
        await axios({
          url: currentFileUploadType === 'routesFile' ? '/api/v1/routeSafety/answer-checklist-excel' : '/api/v1/routeSafety/checklist-excel',
          method: 'POST',
          onDownloadProgress: (progressEvent: any) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setProgressPercentage(percentCompleted);
          },
          cancelToken: cancelSource.current.token,
          data,
          params: config,
        });
        toast.success('Uploaded Successfully!');
        closeModal();
        callGetLatestChecklistAnsweredAtByLocationSlugApi();
        callGetExternalRoutesByLocationSlugApi();
        if (fileRef.current !== null) {
          fileRef.current.value = '';
        }
      } catch {
        setUploadError(true);
      }
    }
  };

  const navigateToRouteDetials = (routeData: any) => {
    navigate('/road-safety/route-details', { state: { routeData } });
  };

  const routeDetails = (routeData: any) => (
    <div role="button" onClick={() => navigateToRouteDetials(routeData)} style={{ margin: 0 }}>
      <p className="routeDetailTitle">{t(TRANSLATIONS.SEE_ROUTE)}</p>
    </div>
  );

  const tableValues = (value: any, status: any) => {
    let className = 'routeNumberTextDesign';
    let statusName = status;
    if (status === ROAD_SAFETY_ROUTE_STATUS.CRITICAL) {
      className = 'routeNumberDesignError';
      statusName = t(TRANSLATIONS.CRITICAL);
    } else if (status === ROAD_SAFETY_ROUTE_STATUS.AT_RISK) {
      className = 'routeNumberDesignWarning';
      statusName = t(TRANSLATIONS.AT_RISK);
    }
    return (
      <div className="textDesignWrap">
        <div className="textDesign">
          <p>{value}</p>
        </div>
        {status !== ROAD_SAFETY_ROUTE_STATUS.OK ? (
          <div className={className}>
            <p>{statusName}</p>
          </div>
        ) : null}
      </div>
    );
  };

  const tableTextValue = (value: string) => {
    return (
      <div className="textDesign">
        <p>{value}</p>
      </div>
    );
  };

  const ResourcesEmptyPage = () => {
    return (
      globalFilters?.size ? (
        <div className="roadSafetyResources__selectDc">
          <img src={ImageLinks.selectDc} alt="Select Location" />
          <div className="roadSafetyResources__selectDc__info">
            <p className="roadSafetyResources__selectDc__info__title">{t(TRANSLATIONS.SELECT_A_DISTRIBUTION_CENTER)}</p>
            <p className="roadSafetyResources__selectDc__info__subTitle">{t(TRANSLATIONS.IN_ORDER_TO_ACCESS_THE_ROAD_SAFETY_SETTINGS_IT_IS_NECESSARY_TO_SELECT_A_LOCATION_USING_THE_TOP_BAR)}</p>
          </div>
        </div>
      ) : (
        <LoadingSpinner height={30} style={{ position: 'fixed', top: '50%', left: '50%' }} />
      )
    );
  };

  const downloadTemplate = () => {
    window.open('https://arvolution-safety-development.s3.us-east-2.amazonaws.com/roadSafety/RS3/RS3+Plantilla+de+rutas.xlsx', '_blank');
  };

  const handleDropYearChange = ({ key }: { key: any }) => {
    const selectedDropDownYear = yearOptions.find(item => item.slug === key);
    setSelectedYear(selectedDropDownYear || yearOptions[0]);
    setIsDropDownOpen(undefined);
  };

  const dropDownOpenCloseHandler = (opened: boolean, dropDownType: CriticalPathDropDowns) => {
    return opened ? setIsDropDownOpen(dropDownType) : setIsDropDownOpen(undefined);
  };

  const validMonthOptions: any = useMemo(() => {
    const localDate: Date = new Date();
    const currentYear: number = localDate.getUTCFullYear();
    if (selectedYear.year === currentYear) {
      const currentMonthIndex = localDate.getUTCMonth();
      const possibleMonths = monthOptions.slice(0, currentMonthIndex + 1);
      if (isDCSelected?.locationSlug) {
        return [
          { slug: t(TRANSLATIONS.ALL), label: t(TRANSLATIONS.ALL), allMonths: true },
          ...possibleMonths,
        ];
      }
      return possibleMonths;
    }
    if (isDCSelected?.locationSlug) {
      return [
        { slug: t(TRANSLATIONS.ALL), label: t(TRANSLATIONS.ALL), allMonths: true },
        ...monthOptions,
      ];
    }
    return monthOptions;
    // eslint-disable-next-line
  }, [selectedYear, isDCSelected]);

  const handleDropMonthChange = ({ key }: { key: any }) => {
    const selectedDropDownMonth = validMonthOptions.find(
      (item: { slug: any; }) => item.slug === key,
    );
    setSelectedMonth(selectedDropDownMonth || validMonthOptions[0]);
    setIsDropDownOpen(undefined);
  };

  useEffect(() => {
    const currentYear: number = (new Date()).getUTCFullYear();
    if (selectedYear.year === currentYear) {
      const currentMonthIndex = (new Date()).getMonth();
      if (selectedMonth.monthNumber > currentMonthIndex + 1) {
        handleDropMonthChange({ key: monthArr[currentMonthIndex] });
      }
    }

  // eslint-disable-next-line
  }, [validMonthOptions]);

  const closeDownloadModal = () => {
    setDownloadError(false);
    setShowDownloadModal(false);
    setShowProgressBar(false);
    setDownloadProgressPercentage(0);
  };

  const onClickDownload = () => {
    setShowDownloadModal(true);
  };

  return (
    <div className="roadSafetyCriticalRoutes">
      {showUploadModal && (
        <Modal
          modalContainerStyles={{
            width: '60vw',
            height: 'auto',
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'auto',
            padding: '24px',
            maxHeight: '80%',
          }}
        >
          <div className="roadSafetyCriticalRoutes__uploadModal">
            <div className="roadSafetyCriticalRoutes__uploadModal__tileWrap">
              <p className="roadSafetyCriticalRoutes__uploadModal__tileWrap__title">{uploadError ? `${t(TRANSLATIONS.SOMETHING_WENT_WRONG)}...` : `${t(TRANSLATIONS.LOADING_FILE)}...`}</p>
              {uploadError ? (
                <div role="button" onClick={closeModal}>
                  <img src={ImageLinks.deleteCircle} alt="closeModal" />
                </div>
              ) : null}
            </div>
            <>
              {uploadError ? (
                <p className="roadSafetyCriticalRoutes__uploadModal__description">{t(TRANSLATIONS.LOADING_ERROR_PLEASE_RETRY)}</p>
              ) : (
                <div className="roadSafetyCriticalRoutes__uploadModal__progress_bar">
                  <p className="roadSafetyCriticalRoutes__uploadModal__progress_bar__title">{progressPercentage}%</p>
                  <ProgressBar bgcolor="#4472C4" height={16} progress={progressPercentage} />
                </div>
              )}
            </>
            {uploadError ? (
              <div className="roadSafetyCriticalRoutes__uploadModal__button_container">
                <BasicButton text={t(TRANSLATIONS.RETRY_AGAIN)} className="roadSafetyCriticalRoutes__uploadModal__button_container__download_button" onClick={handleAttachmentsRetryUpload} />
                <BasicButton text={t(TRANSLATIONS.UPLOAD_ANOTHER_FILE)} className="roadSafetyCriticalRoutes__uploadModal__button_container__cancel_button" onClick={currentFileUploadType === 'routesFile' ? uploadFile : uploadChecklistTemplate} />
              </div>
            ) : (
              <div className="roadSafetyCriticalRoutes__uploadModal__button_retry_container">
                <BasicButton text={t(TRANSLATIONS.CANCEL)} className="roadSafetyCriticalRoutes__uploadModal__button_retry_container__cancel_button" onClick={!uploadError ? onPressCancelRequest : closeModal} />
              </div>
            )}
          </div>
        </Modal>
      )}
      {showDownloadModal && (
      <Modal
        modalContainerStyles={{ width: '70vw',
          left: '20%',
          maxHeight: '90vh',
          minHeight: '20vh',
          height: 'fit-content',
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)' }}
      >
        <div className="downloadMoal">
          <p className="downloadMoal__title">{downloadError ? `${t(TRANSLATIONS.SOMETHING_WENT_WRONG)}...` : t(TRANSLATIONS.DOWNLOAD_DATA)}</p>
          {showProgressBar ? (
            <>
              <p className="downloadMoal__downloading_title">{downloadError ? t(TRANSLATIONS.DOWNLOAD_FAIL_RETRY_THE_DOWNLOAD_PLEASE) : sentViaEmail ? t(TRANSLATIONS.THE_DATA_IS_TAKING_A_LONG_TIME_TO_DOWNLOAD_A_DOWNLOAD_LINK_WILL_BE_SENT_TO_YOUR_EMAIL_TO_CONTINUE) : t(TRANSLATIONS.DOWNLOADING_DATA)}</p>
              {!downloadError && !sentViaEmail && (
              <div className="downloadMoal__progress_bar">
                <p className="downloadMoal__progress_bar__title">{progressPercentage}%</p>
                <ProgressBar bgcolor="#4472C4" height={16} progress={progressPercentage} />
              </div>
              )}
            </>
          ) : (
            <>
              <p className="downloadMoal__description">{t(TRANSLATIONS.THE_DATA_FOR_THE_LOCATIONS_THAT_ARE_FILTERED_IN_THE_TOP_FILTER_BAR_WILL_BE_DOWNLOADED)}</p>
              <div className="downloadMoal__header">
                <Header showLogo={false} showSettings={false} />
              </div>
              <div className="downloadMoal__yearFilter">
                <div>
                  <p className="downloadMoal__yearFilter__yearTitle">{t(TRANSLATIONS.YEAR)}</p>
                  <ChartDropdown
                    onChange={handleDropYearChange}
                    selectedDropDownItem={selectedYear}
                    dropDownItems={yearOptions}
                    dropDownOpen={isDropDownOpen === CriticalPathDropDowns.year}
                    setDropDownOpen={
                      (opened: boolean) => dropDownOpenCloseHandler(
                        opened, CriticalPathDropDowns.year,
                      )
                    }
                    labelClassName="label-default"
                    dropdownArrowColor="#2B323B"
                  />
                </div>
                <div>
                  <p className="downloadMoal__yearFilter__monthTitle">{t(TRANSLATIONS.MONTH)}</p>
                  <ChartDropdown
                    onChange={handleDropMonthChange}
                    selectedDropDownItem={selectedMonth}
                    dropDownItems={validMonthOptions}
                    dropDownOpen={isDropDownOpen === CriticalPathDropDowns.month}
                    setDropDownOpen={
                      (opened: boolean) => dropDownOpenCloseHandler(
                        opened, CriticalPathDropDowns.month,
                      )
                    }
                    labelClassName="label-default"
                    dropdownArrowColor="#2B323B"
                  />
                </div>
              </div>
            </>
          )}
          <div className="downloadMoal__button_container">
            {downloadError ? (
              <BasicButton text={t(TRANSLATIONS.RETRY_AGAIN)} className="downloadMoal__button_container__download_button" onClick={onDownloadRS7Excel} />
            ) : (
              <BasicButton disabled={showProgressBar} text={t(TRANSLATIONS.DOWNLOAD)} className="downloadMoal__button_container__download_button" onClick={onDownloadRS7Excel} />
            )}
            <BasicButton text={t(TRANSLATIONS.CANCEL)} className="downloadMoal__button_container__cancel_button" onClick={showProgressBar ? onPressCancelDownloadRequest : closeDownloadModal} />
          </div>
        </div>
      </Modal>
      )}
      {location ? (
        <>
          <p className="roadSafetyCriticalRoutes__title">{t(TRANSLATIONS.CRITICAL_PATHS)}</p>
          {canUserAccessRoadSafetyCriticalRoutes ? (
            <div className="roadSafetyCriticalRoutes__driverSection">
              <div className="roadSafetyCriticalRoutes__driverSection__uploadContainer">
                <div>
                  <div role="button" onClick={uploadFile}>
                    <p className="roadSafetyCriticalRoutes__driverSection__uploadContainer__uploadNewCriticalPathFile-title">{t(TRANSLATIONS.UPLOAD_NEW_ROUTE_FILE)}</p>
                  </div>
                  {loggedInUserEmail.includes('arvolution') && (
                    <div role="button" onClick={uploadChecklistTemplate}>
                      <p className="roadSafetyCriticalRoutes__driverSection__uploadContainer__uploadNewCriticalPathFile-title">{t(TRANSLATIONS.UPLOAD_ROAD_SAFETY_CHECKLIST_STRUCTURE)}</p>
                    </div>
                  )}
                </div>
                {lastUploadedData?.getLatestChecklistAnsweredAtByLocationSlug?.at ? (
                  <p className="roadSafetyCriticalRoutes__driverSection__uploadContainer__lastUpdated-title">{format(new Date(lastUploadedData?.getLatestChecklistAnsweredAtByLocationSlug?.at), `'${t(TRANSLATIONS.LAST_UPDATED_ON_RS)}' d ${i18next.language === 'es' ? "'de'" : ''} MMMM, yyyy`, { locale: (i18next.language === 'es' ? es : enUS) })}</p>
                ) : null}
              </div>
              <div className="roadSafetyCriticalRoutes__driverSection__downloadWrap">
                <BasicButton text={t(TRANSLATIONS.DOWNLOAD_DATA)} className="roadSafetyCriticalRoutes__driverSection__downloadWrap__downloadButton" onClick={onClickDownload} />
                <div className="roadSafetyCriticalRoutes__driverSection__downloadWrap__downloadContainer" role="button" onClick={downloadTemplate}>
                  <p className="roadSafetyCriticalRoutes__driverSection__downloadWrap__downloadContainer__downloadTemplate-title">{t(TRANSLATIONS.DOWNLOAD_TEMPLATE)}</p>
                </div>
              </div>
            </div>
          ) : null}

          {(!loading) ? (
            <div style={routesData?.getExternalRoutesByLocationSlug?.length === 0 ? { display: 'none', marginTop: 0 } : {}}>
              <div className="roadSafetyCriticalRoutes__cards-row roadSafetyCriticalRoutes__scroll-view">
                <CustomTable
                  onClickRow={() => {}}
                  header={
                [
                  t(TRANSLATIONS.ROUTE_ID),
                  t(TRANSLATIONS.LAST_UPDATE),
                  t(TRANSLATIONS.LAST_DRIVER),
                  t(TRANSLATIONS.LAST_TRUCK),
                  t(TRANSLATIONS.DESTINATION),
                  '',
                ]
              }
                  rows={
                    routesData?.getExternalRoutesByLocationSlug
                      ? routesData?.getExternalRoutesByLocationSlug?.map((row: any) => {
                        const tableData = row?.content?.metadata;
                        return {
                          rowData: [
                            tableValues(tableData?.route?.externalId, row?.status),
                            tableTextValue(format(row?.updatedAt ? new Date(row?.updatedAt) : new Date(), 'd MMM yyyy')),
                            tableTextValue(tableData?.driver?.name ?? ''),
                            tableTextValue(
                              tableData?.truck?.sapNumber ?? tableData?.truck?.plateNumber,
                            ),
                            tableTextValue(tableData?.destination ?? ''),
                            routeDetails(row),
                          ],
                          index: row!.id,
                        };
                      }) : []
                }
                />
              </div>
            </div>

          ) : (
            <div style={{ height: 400 }}>
              <LoadingSpinner positionTop={200} />
            </div>
          )}
          { routesData?.getExternalRoutesByLocationSlug?.length === 0
        && (
        <div className="roadSafetyCriticalRoutes__no-checklist-container">
          <img src={ImageLinks.noData} style={{ margin: '2rem 0' }} alt="noChecklists" />
          <p className="roadSafetyCriticalRoutes__no-checklist-p">{t(TRANSLATIONS.NO_DATA_LOADED_YET_PRESS_UPLOAD_NEW_CRITICAL_PATH_FILE)}</p>
        </div>
        )}
          <input
            ref={fileRef}
            className="hidden"
            type="file"
            onChange={handleAttachmentsUpload}
            name="file"
            multiple
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          />
          <input
            ref={fileRefForChecklistTemplate}
            className="hidden"
            type="file"
            onChange={handleAttachmentsUpload}
            name="file"
            multiple
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          />
        </>
      ) : (
        <ResourcesEmptyPage />
      )}
    </div>
  );
};

export default CriticalPaths;
