/* eslint-disable @typescript-eslint/no-redeclare */
import React from 'react';
import Chart from 'react-apexcharts';
import { useCubeQuery } from '@cubejs-client/react';
import { groupBy } from 'lodash';
import { CircularProgress } from '@mui/material';
import EmptyState from '../../../../components/EmptyState/EmptyState';
import ChartOptions from './ChartOptions';
import './CashlessBar.scss';

type Parameters = {
  currentMonth: string[];
  lastMonth: string[];
  year: string[];
  level?: string[];
};

type IsAYearBeforeParameters = {
  lastMonth: string[];
  year: string[];
};

// If lastMonth is 'diciembre' or 'december' then we do year - 1 to get the year before
export const isAYearBefore = ({ lastMonth, year }: IsAYearBeforeParameters): string[] => (
  lastMonth[0].toLowerCase() === 'diciembre' || lastMonth[0].toLowerCase() === 'december'
    ? [(Number(year[0]) - 1).toString()]
    : year
);

const Level = ({ currentMonth, lastMonth, level, year }: Parameters) => {
  const {
    resultSet: byLevelCurrentMonth,
    isLoading: isLoadingByLevelCurrentMonth,
  } = useCubeQuery({
    dimensions: [
      'AnalyticsToolkit.percentage',
      'AnalyticsToolkit.level',
      'AnalyticsToolkit.bu',
      'AnalyticsToolkit.year',
      'AnalyticsToolkit.month',
    ],
    filters: [
      {
        dimension: 'AnalyticsToolkit.level',
        operator: 'equals',
        values: level ?? [],
      },
      {
        dimension: 'AnalyticsToolkit.month',
        operator: 'contains',
        values: currentMonth ?? [],
      },
      {
        dimension: 'AnalyticsToolkit.year',
        operator: 'contains',
        values: year ?? [],
      },
    ],
  });

  const {
    resultSet: byLevelLastMonth,
    isLoading: isLoadingByLastMonth,
  } = useCubeQuery({
    dimensions: [
      'AnalyticsToolkit.percentage',
      'AnalyticsToolkit.level',
      'AnalyticsToolkit.bu',
      'AnalyticsToolkit.year',
      'AnalyticsToolkit.month',
    ],
    filters: [
      {
        dimension: 'AnalyticsToolkit.level',
        operator: 'equals',
        values: level ?? [],
      },
      {
        dimension: 'AnalyticsToolkit.month',
        operator: 'contains',
        values: lastMonth ?? [],
      },
      {
        dimension: 'AnalyticsToolkit.year',
        operator: 'contains',
        values: isAYearBefore({ lastMonth, year }),
      },
    ],
  });

  const data: any[] = [];

  if (byLevelLastMonth?.tablePivot() && byLevelCurrentMonth?.tablePivot()) {
    const currentData = byLevelCurrentMonth?.tablePivot();
    const lastData = byLevelLastMonth?.tablePivot();

    const currentDataG = groupBy(currentData, 'AnalyticsToolkit.bu');
    const lastDataG = groupBy(lastData, 'AnalyticsToolkit.bu');

    Object.entries(currentDataG).forEach(([key, value]) => {
      let currentPercentage;
      let lastPercentage;
      value.forEach(item => {
        currentPercentage = item['AnalyticsToolkit.percentage'];
        const lastD = lastDataG[key]?.find(i => i['AnalyticsToolkit.bu'] === key);
        lastPercentage = lastD && lastD['AnalyticsToolkit.percentage'];
      });

      data.push({
        bu: key,
        currentPercentage,
        lastPercentage,
      });
    });
  }

  const buOrder = [
    'MEXICO',
    'CAC',
    'COLOMBIA',
    'PERU',
    'ECUADOR',
    'MAZ',
  ];

  const allBUs = data
    .sort((a, b) => (
      buOrder.indexOf(a.bu.toUpperCase()) > buOrder.indexOf(b.bu.toUpperCase()) ? 1 : -1
    ))
    .map((i: { bu: string }) => i.bu.toUpperCase());

  const goals = new Array(data.length).fill(100);
  const currentPercentage = data.map(i => (
    i.currentPercentage > 0
      ? Number(i.currentPercentage).toFixed(2)
      : '0'));
  const lastPercentage = data.map(i => (
    i.lastPercentage > 0
      ? Number(i.lastPercentage).toFixed(2)
      : '0'));
  const chartData = ChartOptions(allBUs, currentPercentage, lastPercentage, goals);

  if (isLoadingByLastMonth || isLoadingByLevelCurrentMonth) {
    return (
      <div className="violence-prevention">
        <div className="loaderPosition ">
          <div className="row">
            <CircularProgress size={30} />
            <p className="loading_text align-self-center"> Loading</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="violence-prevention">
      <div className="cashless-bar">
        {chartData.series[0].data && chartData.series[0].data.length > 0
          ? (
            // @ts-ignore
            <Chart
              type="line"
              options={chartData.options}
              series={chartData.series}
              height="300px"
            />
          ) : (
            <div style={{ marginTop: '80px' }}>
              <EmptyState />
            </div>
          )}
      </div>
    </div>
  );
};

export default Level;
