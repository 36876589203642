import React, { useMemo } from 'react';
import Chart from 'react-apexcharts';
import LoadingSpinner from 'components/LoadingSpinner';
import ErrorMessage from 'components/ErrorMessage';
import { useSelector } from 'react-redux';
import EmptyState from 'components/EmptyState/EmptyState';
import selectDriverManagementSystem from 'store/selectors/driverManagementSystem.selectors';
import {
  customDMSDataSelectorByYearMonthTypeBuAndSubType,
  DMSSelectorTypes,
} from '../dmsHelper';
import '../DriverManagmentSystemStyles.scss';

type Props = {
  loading: boolean;
  month: string;
  type: string;
  year: number;
  error: boolean;
};

const BarChartPerCategory = ({ loading, month, type, year, error }: Props) => {
  const driverManagementSystemData = useSelector(selectDriverManagementSystem);
  const selectedMonthData = customDMSDataSelectorByYearMonthTypeBuAndSubType(
    driverManagementSystemData,
    year,
    month,
    DMSSelectorTypes.perCategoryGraph,
  );

  const totalIncidents = useMemo(() => {
    const tempTotalIncidents: number[] = [];
    if (!loading) {
      if (selectedMonthData) {
        Object.keys(selectedMonthData[type]).forEach((bu: any) => {
          if (Object.keys(selectedMonthData[type][bu]).length !== 0) {
            tempTotalIncidents.push(selectedMonthData[type][bu].totalIncidents);
          }
        });
        if (tempTotalIncidents.every(item => item === 0)) {
          return [];
        }
        return tempTotalIncidents;
      }
    }
    return tempTotalIncidents;
  }, [loading, selectedMonthData, type]);

  const customOptions = {
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'flat',
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
        colors: ['#333', '#333', '#333', '#333', '#333'],
      },
      // Formatter for showing "0" when there is no data.
      formatter(value: number) {
        if (value === 0) {
          return value;
        }
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: ['MEX', 'EC', 'CO', 'PE', 'CAC'],
    },
    yaxis: {
      title: {
        text: 'Registro',
        style: {
          color: '#002033',
          fontSize: '12px',
          fontFamily: 'Roboto',
          fontWeight: 400,
        },
      },
      labels: {
        formatter: (value: any) => { return value.toLocaleString(); },
      },
    },
    fill: {
      opacity: 1,
    },
    colors: ['#5572B9'],
    legend: {
      show: false,
    },
    chart: {
      toolbar: {
        show: false,
      },
    },
    // Option for removing "series" text coming on tooltip.
    tooltip: {
      y: {
        title: {
          formatter: () => '',
        },
      },
    },
  };

  const chartData = [
    {
      data: totalIncidents,
    },
  ];

  if (error) return <ErrorMessage />;
  if (loading) return <LoadingSpinner height={320} />;
  if (totalIncidents.length === 0) return <EmptyState />;

  return (
    <Chart
      type="bar"
      options={customOptions}
      series={chartData}
      height="320px"
    />
  );
};

export default BarChartPerCategory;
