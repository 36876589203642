import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

import { ReactComponent as ArvolutionLogo } from 'assets/icons/arvolution-logo.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import UserIcon from 'assets/icons/user-icon.svg';
import UserWarningIcon from 'assets/icons/user-warning.svg';

import InputWithIcon from 'components/Input/InputWithIcon';
import HighlightButton from 'components/Button/HighlightButton';
import LoadingSpinner from 'components/LoadingSpinner';
import request from 'utils/request';

import './ResetPassword.scss';
import { TRANSLATIONS } from 'types/enums';

type State = {
  email: string;
  errors: any;
  submitting: boolean;
  emailSent: boolean;
};

class ResetPassword extends PureComponent<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      email: '',
      errors: {},
      submitting: false,
      emailSent: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.enterSubmit = this.enterSubmit.bind(this);
  }

  handleInputChange(e: any) {
    // @ts-ignore
    this.setState({ [e.target.name]: e.target.value });
  }

  enterSubmit(e: React.KeyboardEvent<HTMLFormElement>) {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      e.stopPropagation();
      this.resetPassword();
    }
  }

  async resetPassword() {
    this.setState({ submitting: true, errors: {} });
    const lang = i18next.language;
    try {
      await request.post('/api/v1/user-reset-password', {
        email: this.state.email,
        lng: lang,
      });
      this.setState({ submitting: false, emailSent: true });
    } catch (error) {
      console.log({ error });
      if (!(error as any).message) {
        this.setState({ errors: { message: 'Error de red' }, submitting: false });
      } else {
        this.setState({ errors: { message: this.props.t((error as any).message) },
          submitting: false });
      }
    }
  }

  render() {
    const { errors } = this.state;
    let nonValidationError = '';
    if (!errors.email) {
      nonValidationError = errors.message;
    }

    return (
      <div className="reset-password">
        <div className="container">
          <p className="arvolution-text">ARVOLUTION</p>
          {!this.state.emailSent
            ? (
              <div className="content">
                <div style={{ display: 'flex', alignContent: 'center' }}>
                  <ArvolutionLogo style={{ width: 50 }} />
                  <p className="font-bold" style={{ fontSize: 38, marginLeft: 20 }}>
                    {this.props.t(TRANSLATIONS.LETS_FIND_YOUR_ACC)}
                  </p>
                </div>
                <p className="blue-text">
                  {this.props.t(TRANSLATIONS.TYPE_YOUR_EMAIL)}
                </p>
                <form onSubmit={this.resetPassword} onKeyDown={this.enterSubmit}>
                  <InputWithIcon
                    icon={UserIcon}
                    errorIcon={UserWarningIcon}
                    type="text"
                    placeholder={this.props.t(TRANSLATIONS.EMAIL)}
                    onChange={this.handleInputChange}
                    name="email"
                    error={(errors.email && errors.email.msg) || nonValidationError}
                    errorHeight
                    autoComplete={false}
                  />
                  <div style={{ display: 'flex', marginTop: 40 }}>
                    <HighlightButton
                      text={this.props.t(TRANSLATIONS.SEND_ME_THE_LINK)}
                      className="reset-password-button"
                      onClick={this.resetPassword}
                    />
                    {this.state.submitting
                      ? <LoadingSpinner text="" className="reset-password-spinner" />
                      : null}
                  </div>
                </form>
              </div>
            )
            : (
              <div className="content">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <CheckIcon style={{ width: 70, height: 45 }} />
                  <p className="font-bold" style={{ fontSize: 38, marginLeft: 20 }}>
                    {this.props.t(TRANSLATIONS.EMAIL_SENT)}
                  </p>
                </div>
                <p className="blue-text" style={{ maxWidth: 560 }}>
                  {this.props.t(TRANSLATIONS.YOU_WILL_RECEIVE_AN_EMAIL)}
                  <span style={{ textDecoration: 'underline' }}> {this.state.email} </span>
                  {this.props.t(TRANSLATIONS.TO_RESET_PASSWORD)}
                </p>
              </div>
            )}
          <div className="contact">
            <p style={{ color: '#058AFF' }}>soporte@arvolution.com</p>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ResetPassword);
