import React, { useEffect, useMemo, useState } from 'react';
// @ts-ignore
import ReactTable from 'react-table-legacy';
import EmptyState from 'components/EmptyState/EmptyState';
import Modal from 'components/Modal';
import ImageLinks from 'utils/ImageLinks';
import LoadingSpinner from 'components/LoadingSpinner';
import { add, format, getYear } from 'date-fns';
import {
  getBusinessDatesCount,
  getYearDropDownFromBaseYear,
} from 'utils/dateHelpers';
import ChartDropdown from 'components/Dropdown/ChartDropdown';
import { SelectInfo } from 'rc-menu/lib/interface';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';
import { getClassificationList, getTicketList } from '../ComplainceSIFAPICalls';
import {
  DropYearAndMonthDropDown,
  ComplainceDropDown,
  determineSIFComplainceStatusColor,
} from '../compainceSIF.utils';

const TicketsTable = () => {
  const { t } = useTranslation();

  const yearsDropDown = useMemo(() => getYearDropDownFromBaseYear(), []);
  const statusOptions = [
    { label: 'Status', slug: 'All' },
    { label: 'Cerrados', slug: 'Cerrados' },
    { label: 'Con retraso', slug: 'Con retraso' },
    { label: 'Pendiente', slug: 'Pendiente' },
  ];
  const [classificationOptions, setClassificationOption] = useState([
    { label: 'Classification', slug: 'All' },
  ]);
  const recordTime = [
    { label: 'Record Time', slug: 'All' },
    { label: t(TRANSLATIONS.WITHIN_24_HOURS), slug: 'true' },
    { label: t(TRANSLATIONS.MORE_24_HOURS), slug: 'false' },
  ];

  function selectedYearInitalStateCalculator() {
    const year = getYear(new Date());
    return getYearDropDownFromBaseYear(undefined, undefined, undefined, undefined, [
      year,
    ])[0] as DropYearAndMonthDropDown;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [response, setResponse] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [popup, setPopup] = useState(false);
  const [currentTicket, setCurrentTicket] = useState<any>({});
  const [selectedYear, setSelectedYear] = useState<DropYearAndMonthDropDown>(
    selectedYearInitalStateCalculator,
  );
  const [selectedStatus, setSelectedStatus] = useState<ComplainceDropDown>({
    label: 'Status',
    slug: 'All',
  });
  const [selectedClassification, setSelectedClassification] = useState<ComplainceDropDown>({
    label: 'Classification',
    slug: 'All',
  });
  const [selectedRecordTime, setSelectedRecordTime] = useState<ComplainceDropDown>({
    label: 'Record Time',
    slug: 'All',
  });
  const [yearDropDown, setYearDropDown] = useState(false);
  const [statusDropDown, setStatusDropDown] = useState(false);
  const [recordDropDown, setRecordDropDown] = useState(false);
  const [classificationDropDown, setClassificationDropDown] = useState(false);

  const startDate = new Date(currentTicket?.content?.incidentDate);
  const endDate = new Date();
  const numberOfBusinessDates = getBusinessDatesCount(startDate, endDate);

  const closePopup = () => {
    setPopup(false);
  };

  const handleYearChange = ({ key }: SelectInfo) => {
    setSelectedYear(
      yearsDropDown.find(year => year.slug === Number(key)) as DropYearAndMonthDropDown,
    );
    setYearDropDown(false);
  };

  const handleStatusChange = ({ key }: SelectInfo) => {
    setSelectedStatus(statusOptions.find(year => year.slug === key) as ComplainceDropDown);
    setStatusDropDown(false);
  };

  const handleClassificationChange = ({ key }: SelectInfo) => {
    setSelectedClassification(
      classificationOptions.find(
        classification => classification.slug === key,
      ) as ComplainceDropDown,
    );
    setClassificationDropDown(false);
  };

  const handleRecordTimeChange = ({ key }: SelectInfo) => {
    setSelectedRecordTime(recordTime.find(record => record.slug === key) as ComplainceDropDown);
    setRecordDropDown(false);
  };

  useEffect(() => {
    setIsLoading(true);
    getTicketList(
      selectedYear.slug,
      selectedStatus.slug,
      selectedClassification.slug,
      selectedRecordTime.slug,
    )
      .then((result: any) => {
        setResponse(result?.data || []);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [selectedYear, selectedStatus, selectedRecordTime, selectedClassification]);

  useEffect(() => {
    getClassificationList(selectedYear.slug)
      .then((result: any) => {
        setClassificationOption(() => [
          { label: 'Classification', slug: 'All' },
          ...(result?.classificationList?.map((type: any) => type?.sifTypes))
            .map((opt: any) => ({ label: opt, slug: opt })),
        ]);
      })
      .catch(() => {});
  }, [selectedYear]);

  const OpenModal = () => {
    const getPercentageOfProgress = () => {
      return numberOfBusinessDates > 7 ? 0 : ((8 - numberOfBusinessDates) / 7) * 100;
    };
    return (
      <Modal
        modalContainerStyles={{
          height: '40vh',
        }}
      >
        <>
          <div>
            <div className="capex-close" onClick={closePopup} role="button">
              &times;
            </div>
            <div className="ticket-modal-header">
              <div>
                <p className="font-weight700 font-size20"> Ticket: {currentTicket?.content?.reference}</p>
                <p className="font-weight400 font-size14">
                  {' '}
                  {currentTicket.bu}: {currentTicket.cd}
                </p>
              </div>
              <div>
                <p className="font-weight400 font-size14">
                  {' '}
                  Incidente:{' '}
                  <span className="font-weight700">
                    {format(new Date(currentTicket?.content?.incidentDate), 'MM/dd/yyyy')}
                  </span>
                </p>
                <p className="font-weight400 font-size14 delayed-color">
                  {' '}
                  Registrado:{' '}
                  <span className="font-weight700">
                    {format(
                      new Date(currentTicket?.content?.registrationDate || new Date()),
                      'MM/dd/yyyy',
                    )}
                  </span>
                </p>
              </div>
              <div className="font-weight400 font-size16">
                {' '}
                <div className="font-weight400 font-size14">
                  Vigencia:{' '}
                  <span className="font-weight700">
                    {format(
                      add(new Date(currentTicket?.content?.registrationDate
                         || new Date()), { days: 7 }),
                      'MM/dd/yyyy',
                    )}
                  </span>
                </div>
                <div
                  className={`${
                    currentTicket?.content?.status === 'Cerrados'
                      ? 'closed-color'
                      : numberOfBusinessDates > 7 && currentTicket?.content?.status !== 'Cerrados'
                        ? 'delayed-color'
                        : ''
                  }`}
                >
                  Cierre:{' '}
                  <span className="font-weight700">
                    {currentTicket?.content.status === 'Cerrados'
                      ? format(new Date(currentTicket?.content?.incidentDate), 'MM/dd/yyyy')
                      : getBusinessDatesCount(
                        new Date(currentTicket?.content?.incidentDate),
                        new Date(),
                      ) > 7
                        ? 'Con retraso'
                        : currentTicket?.content?.status}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="capex-popup-bar" style={{ display: 'flex' }}>
              {currentTicket?.content?.status === 'Cerrados' && (
                <div className="capex-popup-progress-success" style={{ width: '100%' }}>
                  Cerrados
                </div>
              )}
              {numberOfBusinessDates > 7 && currentTicket?.content?.status !== 'Cerrados' && (
                <div style={{ width: '100%' }}> Con retraso </div>
              )}
              {numberOfBusinessDates <= 7 && currentTicket?.content?.status === 'Pendiente' && (
                <>
                  <div
                    className="capex-popup-bar"
                    style={{ width: `${100 - getPercentageOfProgress()}%` }}
                  >
                    {' '}
                    {numberOfBusinessDates - 1}{' '}
                  </div>
                  <div
                    className="capex-popup-progress-bar"
                    style={{ width: `${getPercentageOfProgress()}%` }}
                  >
                    {8 - numberOfBusinessDates}{' '}
                  </div>
                </>
              )}
            </div>
            <div className="ticket-modal-details font-size14">
              <p className="font-weight400 capex-popup-second-color">
                {' '}
                Etiqueta:{' '}
                <span
                  className={`font-weight700 ${
                    currentTicket?.content?.etiqueta ? '' : 'disabled-text'
                  }`}
                >
                  {currentTicket?.content?.etiqueta || t(TRANSLATIONS.INFORMATION_NOT_AVAILABLE)}
                </span>
              </p>
              <p className="font-weight400">
                {' '}
                SIF:{' '}
                <span
                  className={`font-weight700 ${
                    currentTicket?.content?.sifType ? '' : 'disabled-text'
                  }`}
                >
                  {currentTicket?.content?.sifType || t(TRANSLATIONS.INFORMATION_NOT_AVAILABLE)}
                </span>
              </p>
            </div>
            {currentTicket?.content?.status !== 'Cerrados' && (
              <p className="font-size14 capex-popup-second-color font-weight700">
                {' '}
                PLAN DE ACCIÓN :
                <p
                  className={`font-weight400 ${
                    currentTicket?.content?.actionPlan ? '' : 'disabled-text'
                  }`}
                >
                  {currentTicket?.content?.actionPlan || '0%'}
                </p>
              </p>
            )}
            {/* <p> Comentarios: </p> */}
          </div>
        </>
      </Modal>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: t(TRANSLATIONS.REFERNCIA),
        id: 'ref',
        accessor: 'ref',
        Cell: (row: any) => <p className="text-left font-normal">{row.value}</p>,
      },
      {
        Header: t(TRANSLATIONS.BU),
        id: 'bu',
        accessor: 'bu',
        Cell: (row: any) => <p className="text-left font-normal">{row.value}</p>,
      },
      {
        Header: `${t(TRANSLATIONS.DATE_INCIDENT)} / ${t(TRANSLATIONS.DATE_REPORTED)} `,
        id: 'incident',
        accessor: 'incident',
        minWidth: 200,
        Cell: (row: any) => (
          <p
            className={`text-left ${
              row?.original?.status === 'Con retraso' ? 'font-weight700' : 'font-normal'
            }`}
            style={{
              color: row?.original?.status === 'Con retraso' ? '#A33223' : '',
            }}
          >
            {row.value}
          </p>
        ),
      },
      {
        Header: 'CD',
        id: 'cd',
        accessor: 'cd',
        style: { whiteSpace: 'normal' },
        Cell: (row: any) => (
          <p className={`text-left font-normal ${row?.value ? '' : 'disabled-text'}`}>
            {row.value}
          </p>
        ),
      },
      {
        Header: t(TRANSLATIONS.VALIDITY),
        id: 'vigencia',
        accessor: 'vigencia',
        Cell: (row: any) => (
          <p
            className={`text-left ${
              row?.original?.status === 'Con retraso'
                ? 'font-weight700'
                : `font-normal ${row?.value ? '' : 'disabled-text'}`
            }`}
            style={{
              color: row?.original?.status === 'Con retraso' ? '#A33223' : '',
            }}
          >
            {row.value}
          </p>
        ),
      },
      {
        Header: t(TRANSLATIONS.NATURE),
        id: 'nature ',
        accessor: 'nature',
        style: { whiteSpace: 'normal' },
        Cell: (row: any) => (
          <p className={`text-left font-normal ${row?.value ? '' : 'disabled-text'}`}>
            {row.value || t(TRANSLATIONS.INFORMATION_NOT_AVAILABLE)}
          </p>
        ),
      },
      {
        Header: t(TRANSLATIONS.CAUSE),
        id: 'condition ',
        accessor: 'condition',
        style: { whiteSpace: 'normal' },
        Cell: (row: any) => (
          <p className={`text-left font-normal ${row?.value ? '' : 'disabled-text'}`}>
            {row.value || t(TRANSLATIONS.INFORMATION_NOT_AVAILABLE)}
          </p>
        ),
      },
      {
        Header: t(TRANSLATIONS.SUB_CAUSE),
        id: 'conditionReason ',
        accessor: 'conditionReason',
        Cell: (row: any) => (
          <p className={`text-left font-normal ${row?.value ? '' : 'disabled-text'}`}>
            {row.value || t(TRANSLATIONS.INFORMATION_NOT_AVAILABLE)}
          </p>
        ),
      },
      {
        Header: t(TRANSLATIONS.STATUS),
        id: 'status',
        accessor: 'status',
        Cell: (row: any) => (
          <div className="text-center cell-two-items">
            <span
              className="text-center  font-bold"
              style={{ color: determineSIFComplainceStatusColor(row.value) }}
            >
              {row.value}
            </span>
            <img
              className="cell-chevron"
              src={ImageLinks.chevron_right}
              alt="upload file"
              onClick={() => {
                setCurrentTicket(row?.original?.item);
                setPopup(true);
              }}
            />
          </div>
        ),
      },
    ],
    [t],
  );

  return (
    <>
      <div className="row">
        <div className="col-6">
          <div className="routines-home__title">Tickets</div>
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <ChartDropdown
          onChange={handleYearChange}
          selectedDropDownItem={selectedYear}
          dropDownItems={getYearDropDownFromBaseYear() as DropYearAndMonthDropDown[]}
          dropDownOpen={yearDropDown}
          setDropDownOpen={setYearDropDown}
          labelClassName="label-default"
        />
        <ChartDropdown
          onChange={handleClassificationChange}
          selectedDropDownItem={selectedClassification}
          dropDownItems={classificationOptions as ComplainceDropDown[]}
          dropDownOpen={classificationDropDown}
          setDropDownOpen={setClassificationDropDown}
          labelClassName="label-default"
        />
        <ChartDropdown
          onChange={handleRecordTimeChange}
          selectedDropDownItem={selectedRecordTime}
          dropDownItems={recordTime as ComplainceDropDown[]}
          dropDownOpen={recordDropDown}
          setDropDownOpen={setRecordDropDown}
          labelClassName="label-default"
        />
        <ChartDropdown
          onChange={handleStatusChange}
          selectedDropDownItem={selectedStatus}
          dropDownItems={statusOptions as ComplainceDropDown[]}
          dropDownOpen={statusDropDown}
          setDropDownOpen={setStatusDropDown}
          labelClassName="label-default"
        />
      </div>
      <div className="table-by-regional-manager card routines-home__card">
        {isLoading ? (
          <LoadingSpinner />
        ) : !response?.length ? (
          <EmptyState />
        ) : (
          <>
            {popup && <OpenModal />}
            <ReactTable
              data={response.map((item: any) => ({
                ref: item?.content?.reference,
                bu: item.bu,
                incident: `${format(
                  new Date(item?.content?.incidentDate),
                  'MM/dd/yyyy',
                )} - ${format(
                  new Date(item?.content?.registrationDate || new Date()),
                  'MM/dd/yyyy',
                )}`,
                cd: item.cd,
                vigencia: `${format(
                  add(new Date(item?.content?.registrationDate || new Date()), { days: 7 }),
                  'MM/dd/yyyy',
                )}`,
                condition: item?.content?.incidentCause,
                conditionReason: item?.content?.conditionReason,
                nature: item?.content?.nature,
                status:
                  item?.content.status === 'Cerrados'
                    ? 'Cerrados'
                    : getBusinessDatesCount(new Date(item?.content?.incidentDate), new Date()) > 7
                      ? 'Con retraso'
                      : item?.content?.status,
                item,
              }))}
              columns={columns}
              showPagination
              pageSizeOptions={[5, 10, 20, 25, 50, 100]}
              className="-striped max-height -pagination"
            />
          </>
        )}
      </div>
    </>
  );
};

export default TicketsTable;
