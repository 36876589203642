import React from 'react';

import './PressurableButton.scss';

const PressurableButton = ({
  pressed,
  label,
  value,
  onClick,
} : {
  pressed: boolean,
  label: string,
  value: string,
  onClick: (value: any) => void,
}) => {
  return (
    <div
      className={`pressed-button ${pressed && 'pressed-button--selected'}`}
      onClick={() => onClick(value)}
      role="presentation"
      key={value}
    >
      {label}
    </div>
  );
};

export default PressurableButton;
