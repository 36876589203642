import React, { FC, useState } from 'react';
import { useTable, usePagination, useRowSelect } from 'react-table';
import PCRDateAssign from 'components/PCRDateAssign';
import Button from 'components/Button/BasicButton';
import './PaginatedTable.scss';

type Props = {
  columns: any;
  data: any;
};
const PaginatedTable: FC<Props> = ({ columns, data }: Props) => {
  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageCount,
    state,
  } = useTable({
    columns,
    data,
    initialState: { pageIndex: 0, pageSize: 5 },
  },
  usePagination,
  useRowSelect);
  const { pageIndex } = state;
  const [updateTest, setUpdateTest] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<Object>({});
  const toggleModal = (val: boolean) => {
    setUpdateTest(() => val);
  };
  const toggleUpdateTestDateModal = (row: any, val: boolean) => {
    // @NOTE:-- here the row.toggleRowSelected works behind the scenes to toggle the
    // row selection. I am going to use this function directly which onChange(e) uses internally.

    setSelectedRow(row);
    row.toggleRowSelected(!row.getToggleRowSelectedProps().checked);
    const rowProps = row.getToggleRowSelectedProps();
    // row.getToggleRowSelectedProps().onChange()
    console.log(rowProps);
    toggleModal(val);
  };

  return (
    <div>
      <div className="expandable-table card">
        {updateTest && (
          <PCRDateAssign
            onOverlayClick={() => toggleUpdateTestDateModal(selectedRow, false)}
            row={selectedRow}
          />
        )}
        <table {...getTableProps()} className="table">
          <thead className="some">
            {headerGroups.map((
              headerGroup: {
                getHeaderGroupProps: () => JSX.IntrinsicAttributes & React.ClassAttributes<
                HTMLTableRowElement
                > & React.HTMLAttributes<HTMLTableRowElement>; headers: any[];
              },
            ) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()} style={{ height: 45 }}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((
              row: {
                getRowProps: () => JSX.IntrinsicAttributes & React.ClassAttributes<
                HTMLTableRowElement
                > & React.HTMLAttributes<HTMLTableRowElement>;
                getToggleRowSelectedProps: () => any;
                cells: any[];
                id: string;
              },
            ) => {
              prepareRow(row as any);
              return (
                <tr {...row.getRowProps()} style={{ cursor: 'pointer' }} className={row.getToggleRowSelectedProps().checked ? 'row clicked' : 'row'} onClick={() => toggleUpdateTestDateModal(row, true)}>
                  {row.cells.map(cell => (
                    <td {...cell.getCellProps()} style={{ height: 45 }}>{cell.value || '__'}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="btn-container">
        <span className="track-page">
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageCount}
          </strong>{' '}
        </span>
        <Button onClick={previousPage} style={{ marginRight: 10 }} text="Previous" disabled={!canPreviousPage} />
        <div className="spacer" />
        <Button onClick={nextPage} text="Next" disabled={!canNextPage} />
      </div>
    </div>
  );
};
export default PaginatedTable;
