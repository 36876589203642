import * as React from 'react';

import './WebsiteVersion.scss';
import websiteVersion from './websiteVersion.json';

function WebsiteVersion() {
  return (
    <p className="version">
      Version :{' '}
      {websiteVersion.featureVersion}.
      {websiteVersion.fixVersion}.
      {websiteVersion.patchVersion}
    </p>
  );
}

export default WebsiteVersion;
