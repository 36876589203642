import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MainNavigationHeader from 'components/MainNavigationHeader';
import LoadingSpinner from 'components/LoadingSpinner';
import UserLineChart from 'components/UserLineChart';
import {
  getIncidents,
  downloadIncidents,
  getTotalIncidentsByTime,
} from 'store/actions/distributionCenter.actions';
import {
  setMonthlyDropdownChange,
} from 'store/actions/timeFilter.actions';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import request from 'utils/request';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { esES, enUS } from '@mui/x-date-pickers/locales';
import i18next from 'i18n';
import moment from 'moment';
import { canUserEditOperations } from 'utils/shared/canUserAccessFeature';
import withParams from 'utils/hoc/withParams';
import IncidentsIndex from './IncidentsIndex';
import { formatTimeLabel } from '../../../../utils/dateHelpers';
import { ReactComponent as LogoutIcon } from '../../../../assets/icons/log-out.svg';
import { TRANSLATIONS } from '../../../../types/enums';
import { ReactComponent as RefreshIcon } from '../../../../assets/icons/refresh.svg';
import * as styles from './DistributionCenterStyles';

import './DistributionCenter.scss';

const DateLocale = i18next.language?.indexOf('en') === 0 ? enUS.components.MuiLocalizationProvider.defaultProps.localeText : esES.components.MuiLocalizationProvider.defaultProps.localeText;

type Props = {
  userName: string;
  incidents: any;
  getTotalIncidentsByTime: any;
  getIncidents: any;
  downloadIncidents: any;
  incidentsByTime: any;
  setMonthlyDropdownChange: any;
  timeFilterOption: any;
  startTimeFilter: any;
  endTimeFilter: any;
  t: any;
  userCountry: string;
  incidentsPaginationInfo: any;
  userData: any;
  params: any;
};

type Filters = {
  limit: number;
  page: number;
};

type State = {
  startDate: any;
  endDate: any;
  startDatePickerOpen: boolean;
  endDatePickerOpen: boolean;
  filters: Filters;
  searchString: string;
  isFactory: boolean;
};

class DistributionCenter extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      startDate: moment().clone().startOf('month').toLocaleString(),
      endDate: new Date(),
      startDatePickerOpen: false,
      endDatePickerOpen: false,
      filters: { limit: 20, page: 1 },
      searchString: '',
      isFactory: false,
    };

    this.downloadIncidentsData = this.downloadIncidentsData.bind(this);
    this.onStartDateChange = this.onStartDateChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleResetClick = this.handleResetClick.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handlePrevious = this.handlePrevious.bind(this);
  }

  componentDidMount() {
    // const { params } = this.props;
    const endDate = moment(new Date());
    endDate.set({ hour: 23, minute: 59, second: 59, millisecond: 0 });
    this.setState({ endDate: endDate.toLocaleString() });
    this.props.setMonthlyDropdownChange(
      { startTime: this.state.startDate, endTime: endDate.toLocaleString() },
    );
    // this.props.getTotalIncidentsByTime(params.distCenter, '', this.state.filters.page, this.state.filters.limit);
    // this.props.getIncidents(params.distCenter, '', this.state.filters.page, this.state.filters.limit);
    this.getCdDetails();
  }

  componentDidUpdate(prevProps: Props) {
    const { params, startTimeFilter, endTimeFilter } = this.props;
    if (
      startTimeFilter !== prevProps.startTimeFilter
      || endTimeFilter !== prevProps.endTimeFilter
    ) {
      this.props.getTotalIncidentsByTime(params.distCenter, '', this.state.filters.page, this.state.filters.limit);
      this.props.getIncidents(params.distCenter, '', this.state.filters.page, this.state.filters.limit);
    }
  }

  onStartDateChange(startDate: Date) {
    const updatedStartDate = moment(startDate);
    updatedStartDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    this.setState({ startDate: updatedStartDate.toLocaleString() });
    this.props.setMonthlyDropdownChange(
      { startTime: updatedStartDate.toLocaleString(), endTime: this.state.endDate },
    );
    this.setState({ startDatePickerOpen: false });
  }

  onEndDateChange(endDate: any) {
    const updatedEndDate = moment(endDate);
    updatedEndDate.set({ hour: 23, minute: 59, second: 59, millisecond: 0 });
    this.setState({ endDate: updatedEndDate.toLocaleString() });
    this.props.setMonthlyDropdownChange(
      { startTime: this.state.startDate, endTime: updatedEndDate.toLocaleString() },
    );
    this.setState({ endDatePickerOpen: false });
  }

  async getCdDetails() {
    const { params, t } = this.props;
    try {
      const { distCenterDetails } = await request.get(`/api/v1/${params.distCenter}/dist-center`);
      const isFactory = distCenterDetails.type === 'FACTORY';
      this.setState({ isFactory });
    } catch (error) {
      toast.error(t(TRANSLATIONS.ERROR_GETTING_DISTRIBUTION_CENTER_INFO));
    }
  }

  async downloadIncidentsData() {
    const { params, incidents } = this.props;
    const { distCenterDetails } = await request.get(`/api/v1/${params.distCenter}/dist-center`);
    const isFactory = distCenterDetails.type === 'FACTORY';
    const location = `distributionCenter=${params.distCenter}`;
    const incidentsIds = incidents?.toJS()?.map((incident: any) => incident?.id).join(',');
    this.props.downloadIncidents(location, isFactory, incidentsIds);
  }

  handleSearch(search: string) {
    this.setState({ filters: { page: 1, limit: 20 }, searchString: search });
    const { params } = this.props;
    this.props.getTotalIncidentsByTime(
      params.distCenter, search, 1, 20,
    );
    this.props.getIncidents(
      params.distCenter, search, 1, 20,
    );
  }

  handleResetClick() {
    const endDate = moment(new Date());
    endDate.set({ hour: 23, minute: 59, second: 59, millisecond: 0 });
    this.setState({ endDate: endDate.toLocaleString() });
    this.setState({
      startDate: moment().clone().startOf('month').toLocaleString(),
      endDate: endDate.toLocaleString(),
      searchString: '',
    });
    this.props.setMonthlyDropdownChange(
      { startTime: moment().clone().startOf('month').toLocaleString(), endTime: endDate.toLocaleString() },
    );
  }

  handleNext() {
    const { params } = this.props;
    this.setState(
      { filters: { ...this.state.filters, page: this.state.filters.page + 1 } },
    );
    this.props.getTotalIncidentsByTime(
      params.distCenter,
      this.state.searchString,
      this.state.filters.page + 1,
      this.state.filters.limit,
    );
    this.props.getIncidents(
      params.distCenter,
      this.state.searchString,
      this.state.filters.page + 1,
      this.state.filters.limit,
    );
  }

  handlePrevious() {
    const { params } = this.props;
    this.setState(
      { filters: { ...this.state.filters, page: this.state.filters.page - 1 } },
    );
    this.props.getTotalIncidentsByTime(
      params.distCenter,
      this.state.searchString,
      this.state.filters.page - 1,
      this.state.filters.limit,
    );
    this.props.getIncidents(
      params.distCenter,
      this.state.searchString,
      this.state.filters.page - 1,
      this.state.filters.limit,
    );
  }

  render() {
    console.log('this.state --> ', this.state);
    const {
      incidents, incidentsByTime, params, t, incidentsPaginationInfo, userData,
    } = this.props;
    const { isFactory } = this.state;
    let labels = [];

    if (incidentsByTime) {
      labels = formatTimeLabel(
        incidentsByTime.get('slots').toJS(),
        incidentsByTime.get('slotsType'),
        new Date().getTimezoneOffset(),
      );
    }
    const accessEditOperation = canUserEditOperations(userData?.toJS())
    && userData?.toJS().hasUserManagementAccess;

    const dropdownOptions = [{
      url: `/distribution-centers/${params.distCenter}/users-access`,
      label: (
        /* use span since it's going to be inside of a p tag. */
        <span
          className="routes_name"
        >
          {`${t(TRANSLATIONS.BLOCK)}/${t(TRANSLATIONS.UNBLOCK)}`}
        </span>
      ),
    }, {
      url: `/distribution-centers/${params.distCenter}/users`,
      label: (
        /* use span since it's going to be inside of a p tag. */
        <span
          className="routes_name"
        >
          {t(TRANSLATIONS.USERS)}
        </span>
      ),
    }, {
      url: '/logout',
      label: (
        /* use span since it's going to be inside of a p tag. */
        <span
          className="routes_name"
        >
          {t(TRANSLATIONS.LOGOUT)}
          <LogoutIcon className="logout_icon" />
        </span>
      ),
    }];

    if (accessEditOperation && isFactory) {
      dropdownOptions.unshift({
        url: `/locations/${params.distCenter}/areas-subareas`,
        label: (
          /* use span since it's going to be inside of a p tag. */
          <span
            className="routes_name"
          >
            {t(TRANSLATIONS.EDIT_OPERATIONS)}
          </span>
        ),
      });
    }

    return (
      <>
        <MainNavigationHeader
          configRoutes={dropdownOptions}
          userName={`${this.props.userName}`}
          distCenter={params.distCenter.replace(/-/g, ' ')}
        />
        <div className="container main-content-margin dist">
          <div className="card row justify-content-between align-items-center p-4">
            <div
              className="row col-12 col-sm-auto  align-items-center clickable clickableOpacity"
              role="button"
              onClick={() => { }}
            >
              <p className="text-big">{t(TRANSLATIONS.YOU_ARE_VIEWING)} <span>{params.distCenter.replace(/-/g, ' ') || 'Safety'}</span></p>
            </div>
            <div className="dateRange">
              <div className="dateRange__startDate">
                <p className="dateRange__startDate__title">{t(TRANSLATIONS.START_DATE)}</p>
                <LocalizationProvider dateAdapter={AdapterDateFns} localeText={DateLocale}>
                  <DatePicker
                    sx={styles.datePicker}
                    format="EEEE dd, MMM"
                    maxDate={new Date(this.state?.endDate)}
                    value={new Date(this.state?.startDate)}
                    onChange={(date: any) => this.onStartDateChange(date)}
                    views={['day', 'month', 'year']}
                  />
                </LocalizationProvider>
              </div>
              <div className="dateRange__endDate">
                <p className="dateRange__endDate__title">{t(TRANSLATIONS.END_DATE)}</p>
                <LocalizationProvider dateAdapter={AdapterDateFns} localeText={DateLocale}>
                  <DatePicker
                    sx={styles.datePicker}
                    format="EEEE dd, MMM"
                    maxDate={new Date()}
                    minDate={new Date(this?.state?.startDate)}
                    value={new Date(this.state?.endDate)}
                    onChange={(date: any) => this.onEndDateChange(date)}
                    views={['day', 'month', 'year']}
                  />
                </LocalizationProvider>
              </div>
              <div
                className="dateRange__resetButton"
                onClick={this.handleResetClick}
                role="presentation"
              >
                <RefreshIcon />
                <p>{t(TRANSLATIONS.RESET)}</p>
              </div>
            </div>
          </div>
          <section>
            <h4>{t(TRANSLATIONS.WEEKLY_REPORTS)}</h4>
            <div className="card row flex-column flex-lg-row justify-content-between align-items-center p-4">
              {incidentsByTime ? (
                <UserLineChart
                  title={t(TRANSLATIONS.ACIS)}
                  data={incidentsByTime.get('slotsMapped').toJS()}
                  immutableData={incidentsByTime.get('slotsMapped')}
                  labels={labels}
                  isAreaChart={false}
                  isCheckBox={false}
                />
              ) : <LoadingSpinner height={341} />}
            </div>
          </section>
          <IncidentsIndex
            incidents={incidents}
            downloadData={this.downloadIncidentsData}
            handleSearch={this.handleSearch}
            search={this.state.searchString}
            setSearch={(search: string) => this.setState({ searchString: search })}
            isFactory={this.state.isFactory}
          />
          <div className="table-footer">
            <Button
              variant="contained"
              onClick={this.handlePrevious}
              disabled={this.state.filters.page < 2}
            >{t(TRANSLATIONS.PREVIOUS)}
            </Button>
            <div className="page">
              <div>
                {this.state.filters.page} / {t(TRANSLATIONS.TOTAL_PAGES)}:&nbsp;
                <span>{incidentsPaginationInfo?.get('totalPages')}</span>
              </div>
            </div>
            <Button
              variant="contained"
              onClick={this.handleNext}
              disabled={incidentsPaginationInfo?.get('totalPages') === 0 ? true : this.state.filters.page === incidentsPaginationInfo?.get('totalPages')}
            >{t(TRANSLATIONS.NEXT)}
            </Button>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    userName: state.getIn(['user', 'name']),
    incidents: state.getIn(['distCenter', 'incidents']),
    incidentsPaginationInfo: state.getIn(['distCenter', 'incidentsPaginationInfo']),
    incidentsByTime: state.getIn(['distCenter', 'incidentsByTime']),
    timeFilterOption: state.getIn(['timeFilter', 'dropdownValue']),
    startTimeFilter: state.getIn(['timeFilter', 'startTime']),
    endTimeFilter: state.getIn(['timeFilter', 'endTime']),
    userCountry: state.getIn(['user', 'distributionCenter', 'country']),
    userData: state.get('user'),
  };
}

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators({
    getIncidents,
    downloadIncidents,
    getTotalIncidentsByTime,
    setMonthlyDropdownChange,
  }, dispatch);
}
// @ts-ignore
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withParams(DistributionCenter)));
