import React, { useState, useRef } from 'react';
import { BaseUser } from 'generated/graphql';
import { useSelector } from 'react-redux';
import getAvatarAlternateUI from 'utils/avatarAlternateUi';
import { getTranslatedDateTimeValue } from 'utils/dateHelpers';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS, COMMENT_CREATE_TYPE } from 'types/enums';
import CommentInput from './CommentInput';
import './CommentSection-styles.scss';
import CommentDeleteModal from './CommentDeleteModal';

interface Props {
  message: string | undefined,
  id: string | undefined,
  user: Pick<BaseUser, 'name' | 'slug' | 'picture'>,
  createdAt: Date | undefined,
  sourceId: string,
  type: COMMENT_CREATE_TYPE;
  customContext: any;
  editCommentsMutation: Function;
  deleteCommentsMutation: Function;
  refetchQueries: Function;
  dynamicKeyName: string;
  errorMessage: string;
}

export default function CommentScreen({ message, id, user, createdAt, sourceId,
  editCommentsMutation, deleteCommentsMutation, refetchQueries, type,
  customContext, dynamicKeyName, errorMessage }: Props) {
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const inputRef = useRef<HTMLSpanElement>(null);
  const loggedInUser = useSelector((state: any) => state.get('user'))?.toJS();
  const commonContext = customContext();
  const { t } = useTranslation();
  const [editComment, editCommentState] = editCommentsMutation();
  const [deleteComment, deleteCommentState] = deleteCommentsMutation();

  async function modifyCommentMessage() {
    const comment = inputRef.current?.innerText;
    if (!comment) return;
    try {
      await editComment({
        variables: {
          [dynamicKeyName]: sourceId,
          commentId: id,
          comment,
        },
        refetchQueries: refetchQueries(sourceId),

        awaitRefetchQueries: true,
      });
      setIsEditing(false);
    } catch (e) {
      console.log(e);
    }
  }

  async function deleteCommentMessage() {
    if (!id) {
      return;
    }
    try {
      await deleteComment({
        variables: {
          commentId: id,
        },
        refetchQueries: refetchQueries(sourceId),
        awaitRefetchQueries: true,
      });
    } catch (e) {
      console.log(errorMessage, e);
    }
  }

  function onCancel() {
    setIsEditing(false);
    if (inputRef.current) {
      inputRef.current.innerText = message || '';
    }
  }

  function toggleIsDeleting() {
    setIsDeleting(previousState => !previousState);
  }

  async function onEditSaveButtonClicked() {
    if (isEditing) {
      await modifyCommentMessage();
      setIsEditing(false);
    } else {
      setIsEditing(true);
    }
  }
  const commenterType = () => {
    if (user.slug === commonContext?.owner?.slug) {
      return t(TRANSLATIONS.OWNER);
    }
    if (commonContext.responsibles?.find(
      (responsible: any) => responsible.slug === user.slug,
    )) {
      return t(TRANSLATIONS.PARTICIPANT);
    }
    return '';
  };
  return (
    <div className="comment-container">
      <div className="user_profile">
        <img
          className="user_profile__image"
          src={user.picture || getAvatarAlternateUI({ name: user.name, isRandomBackground: true })}
          alt={user.name || ''}
        />
      </div>
      <div className="comment_content">
        <div className="comment_content__header">
          <p className="name">
            <span>
              {user.name}
            </span>
            <span>
              {type === COMMENT_CREATE_TYPE.ACTION_LOG ? (
                <>
                  {commenterType().length && <span>&nbsp;&#9679; </span>}
                  {commenterType()}
                </>
              ) : null}
            </span>
          </p>
          <p className="created-at">
            {t(TRANSLATIONS.COMMENT_CREATED_AT, {
              date: getTranslatedDateTimeValue(createdAt, 'LLLL dd, yyyy'),
              time: getTranslatedDateTimeValue(createdAt, 'h:mm a'),
            }) as any}
          </p>
        </div>
        <div className="body">
          {isEditing ? (
            <CommentInput
              comment={message || ''}
              inputRef={inputRef}
              onSave={modifyCommentMessage}
              isLoading={editCommentState.loading}
              onCancel={onCancel}
            />
          ) : (
            <p className="message">{message}</p>
          )}
        </div>
        {loggedInUser.slug === user.slug && !isEditing && (
          <div className="comment-footer">
            {
              !deleteCommentState.loading && (
                <>
                  <span role="button" onClick={onEditSaveButtonClicked}>
                    {t(TRANSLATIONS.EDIT) as any}
                  </span>
                  <span>&#9679;</span>
                </>
              )
            }

            <span role="button" onClick={toggleIsDeleting}>
              {deleteCommentState.loading ? `${t(TRANSLATIONS.DELETING)}...` : t(TRANSLATIONS.DELETE) as any}
            </span>
            <CommentDeleteModal
              isOpen={isDeleting}
              onCancel={toggleIsDeleting}
              onConfirm={deleteCommentMessage}
            />
          </div>
        )}
      </div>
    </div>
  );
}
