import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectUserType, selectUserLocation, selectUser } from 'store/selectors/user.selectors';
import defaultRoutes from 'utils/defaultRoutes';
import SKAP from 'features/analyticsWrapper/SKAP';
import HeaderWithNavigation from 'components/HeaderWithNavigation';
import AnalyticsOverview from './Overview';
import AnalyticsIncidents from './Incidents';
import AnalyticsAmbientalRisk from './AmbientalRisks';
import AnalyticsCertificates from './Certificates';
import AnalyticsProductionPlanning from './AnalyticsProductionPlanning';
import Accidentability from './Accidentability/Accidentability';
import Consequences from './Consequences';
import Cashless from './Cashless/Cashless';
import SifRoute from './SifRoute/SifRoute';
import RoutinesHome from './Routines/RoutinesHome';
import './AnalyticsStyles.scss';
import GKPIRepresentation from '../KPI/GKPI/GKPIRepresentation';
import UploadGKPI from '../KPI/GKPI/UploadGKPI';
import StatisticsSIF from './StatisticsSIF';
import ComplainceSIF from './ComplainceSIF';
import DriverManagmentSystem from './DriverManagmentSystem';
import SafetyTraining from './SafetyTraining';
import VoliencePrevention from './VoliencePrevention';
import Capex from './Capex';
import AnalyticsSiosT2 from './AnalyticsSiosT2/AnalyticsSiosT2';
import OWD from './OWD';
import WebPortalTeamRooms from './TeamRooms/WebPortalTeamRooms';
import WebPortal3Rs from './3Rs/WebPortal3Rs';
import WebPortalWorkStation from './WorkStation/WebPortalWorkStation';
import WebPortal5S from './5S/WebPortal5S';
import UsabilityDPO from './UsabilityDPO/UsabilityDPO';

type Props = {};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function AnalyticsWrapper(props: Props) {
  const { t } = useTranslation();
  const userType = useSelector(selectUserType);
  const userDetails = useSelector(selectUser);
  const location = useSelector(selectUserLocation);

  return (
    <HeaderWithNavigation routes={defaultRoutes(t, userType, location, userDetails)}>
      <Routes>
        <Route path="/analytics/overview" element={<AnalyticsOverview />} />
        <Route path="/analytics/incidents" element={<AnalyticsIncidents />} />
        <Route path="/analytics/ambiental-risks" element={<AnalyticsAmbientalRisk />} />
        <Route path="/analytics/safety-certificates-t2" element={<AnalyticsCertificates />} />
        <Route path="/analytics/production-planning" element={<AnalyticsProductionPlanning />} />
        <Route path="/analytics/routines" element={<RoutinesHome />} />
        <Route path="/analytics/accidentability" element={<Accidentability />} />
        <Route path="/analytics/consequences" element={<Consequences />} />
        <Route path="/analytics/cashless" element={<Cashless />} />
        <Route path="/analytics/5S" element={<WebPortal5S />} />
        <Route path="/analytics/sios-t2" element={<AnalyticsSiosT2 />} />
        <Route path="/analytics/sif-route" element={<SifRoute />} />
        <Route path="/analytics/GKPI" element={<GKPIRepresentation />} />
        <Route path="/analytics/GKPI/upload" element={<UploadGKPI />} />
        <Route path="/analytics/driver-management-system" element={<DriverManagmentSystem />} />
        <Route path="/analytics/sif-statistics" element={<StatisticsSIF />} />
        <Route path="/analytics/sif-complaince" element={<ComplainceSIF />} />
        <Route path="/analytics/safety-training" element={<SafetyTraining />} />
        <Route path="/analytics/Capex" element={<Capex />} />
        <Route path="/analytics/violence-prevention" element={<VoliencePrevention />} />
        <Route path="/analytics/skap" element={<SKAP />} />
        <Route path="/analytics/owd" element={<OWD />} />
        <Route path="/analytics/team-rooms" element={<WebPortalTeamRooms />} />
        <Route path="/analytics/3Rs" element={<WebPortal3Rs />} />
        <Route path="/analytics/work-station" element={<WebPortalWorkStation />} />
        <Route path="/analytics/UsabilityDPO" element={<UsabilityDPO />} />
        <Route path="/*" element={<Navigate to="/analytics/overview" replace />} />
      </Routes>
    </HeaderWithNavigation>
  );
}
