import React from 'react';
import { COMMENT_CREATE_TYPE } from 'types/enums';
import CommentsDisplay from './CommentsDisplay';
import CommentCreate from './CommentCreate';
import './CommentSection-styles.scss';

type CommentSectionProps = {
  type: COMMENT_CREATE_TYPE;
  customContext: any;
  createEditMuation: Function;
  refetchQueries: Function;
  detailedCommentsQuery: Function;
  editCommentsMutation: Function;
  deleteCommentsMutation: Function;
  dynamicKeyName: string;
  errorMessage: string;
  requestIdKey: string;
  responseKey: string;
};

export default function CommentSection(props: CommentSectionProps) {
  return (
    <div className="comment-box">
      <CommentCreate
        type={props.type}
        customContext={props.customContext}
        createEditMuation={props.createEditMuation}
        refetchQueries={props.refetchQueries}
        dynamicKeyName={props.dynamicKeyName}
      />
      <CommentsDisplay
        type={props.type}
        customContext={props.customContext}
        detailedCommentsQuery={props.detailedCommentsQuery}
        editCommentsMutation={props.editCommentsMutation}
        deleteCommentsMutation={props.deleteCommentsMutation}
        refetchQueries={props.refetchQueries}
        dynamicKeyName={props.dynamicKeyName}
        errorMessage={props.errorMessage}
        requestIdKey={props.requestIdKey}
        responseKey={props.responseKey}
      />
    </div>
  );
}
