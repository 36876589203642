import React, { Component } from 'react';
import SelectDropdown from 'components/Dropdown/SelectDropdown';
import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/en-gb';
import i18next from 'i18next';

type Props = {
  onChange: any;
  currentValue: string;
  t?: any;
  bgColor?: string;
};

const dynamicMonths = [
  {
    value: 'currentMonth',
    label: moment().locale('es').format('MMMM YY'),
    englishLabel: moment().locale('en-b').format('MMMM YY'),
    startTime: moment().clone().startOf('month').toISOString(),
    endTime: moment().clone().endOf('month').toISOString(),
  },
];

for (let index = 1; index < 12; index++) {
  const month = moment().subtract(index, 'months');
  dynamicMonths.push({
    value: `currentMonthMinus${index}`,
    label: month.locale('es').format('MMMM YY'),
    englishLabel: month.locale('en-gb').format('MMMM YY'),
    startTime: month.startOf('month').toISOString(),
    endTime: month.endOf('month').toISOString(),
  });
}

export const MonthlyOptions = [...dynamicMonths];
export const OnlyMonthlyOptions = [...dynamicMonths];

export default class MonthPicker extends Component<Props> {
  render() {
    const { onChange, currentValue, bgColor } = this.props;
    const lang = i18next.language;

    const dropdownContent = MonthlyOptions.map(({ value, label, englishLabel }) => {
      const localeLabel = lang.includes('es') ? label : englishLabel;
      return (
        { value, label: localeLabel }
      );
    });

    return (
      <SelectDropdown
        name="Month"
        bgColor={bgColor}
        value={currentValue}
        onChange={(e: any) => onChange(e.target.value)}
        dropdownContent={dropdownContent}
      />
    );
  }
}
