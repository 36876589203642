import React from 'react';
import { APIProvider, Map as MapGM } from '@vis.gl/react-google-maps';

const GoogleMapScreen = ({ height }) => (
  <APIProvider apiKey="API_KEY">
    <MapGM
      style={{ height }}
      defaultCenter={{ lat: 22.54992, lng: 0 }}
      defaultZoom={3}
      gestureHandling={'greedy'}
      disableDefaultUI={true}
    />
  </APIProvider>
);

export default GoogleMapScreen;
