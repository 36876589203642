import React, { FC, useCallback, useState } from 'react';
import { COLOR_CODE_NEW } from 'types/enums';
import InputWithLabel from 'components/Input/InputWithLabel';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import request from 'utils/request';
import useDebounce from 'utils/hooks/useDebounce';
import BasicButton from 'components/Button/BasicButton';
import { format } from 'date-fns/esm';
import LoadingSpinner from 'components/LoadingSpinner';

type Props = {
  removeHeader?: boolean;
};

const AddSupplyRoutine: FC<Props> = () => {
  const [isSaving, setIsSaving] = useState(false);
  const [shortId, setShortId] = useState('');
  const [location, setLocation] = useState<any>();
  const [isSuccess, setIsSuccess] = useState(false);
  const [hl, setHl] = useState('');
  const [date, setDate] = useState<any>();
  const memCallbackLocation = useCallback(async (externalId: any) => {
    setLocation({ name: '...' });
    const res = await request.get('/api/v1/location-from-external-id', {
      externalId,
    });
    setLocation(res.location);
  }, []);

  const getLocationFromServer = useDebounce(memCallbackLocation, 1000);

  const updateShortId = (event: any) => {
    setIsSuccess(false);
    setShortId(event.target.value);
    getLocationFromServer(event.target.value);
  };

  const updateHL = (event: any) => {
    setHl(event.target.value);
    setIsSuccess(false);
  };

  const hasData = date && location?.slug && hl;

  const saveData = async () => {
    if (isSaving) return true;
    setIsSaving(true);
    await request.post(
      `/api/v1/set-daily-production-planning/:user/${location?.slug}`,
      {},
      { hl, day: date },
    );
    setIsSaving(false);
    setHl('');
    setDate('');
    setShortId('');
    setLocation(undefined);
    setIsSuccess(true);
  };

  return (
    <div className="col-md-4">
      <p style={{ fontSize: 16, fontWeight: 'bold' }}>Agregar rutina diaria</p>
      <div className="card">
        <InputWithLabel
          label="Short ID"
          onChange={updateShortId}
          placeholder="Short ID"
          value={shortId}
          error={location === null ? 'Ubicación no encontrada' : undefined}
        />
        <div style={{ height: 40 }}>
          <p>{ location?.name }</p>
        </div>
        <p>Fecha</p>
        <DayPickerInput
          onDayChange={(d: any) => { setDate(d); setIsSuccess(false); }}
          value={date}
        />
        <div style={{ height: 40 }} />
        <InputWithLabel
          label="HL"
          onChange={updateHL}
          placeholder="HL"
          value={hl}
          type="number"
        />
        <div style={{ height: 80, paddingTop: 30 }}>
          { hasData && (
            <p>Se agregaran {hl}HL en {location?.name} el {format(date, 'd MMM yy')}</p>
          )}
          { isSuccess && (
            <p
              style={{ color: COLOR_CODE_NEW.SUCCESS }}
            >
              Información agregada exitosamente!
            </p>
          )}
        </div>

        <div
          style={{
            textAlign: 'right',
            paddingTop: 10,
            display: 'flex-end',
          }}
        >
          { isSaving && (
            <div>
              <LoadingSpinner text=" " />
            </div>
          )}
          <BasicButton
            style={{
              backgroundColor: COLOR_CODE_NEW.PRIMARY,
            }}
            text="Guardar"
            onClick={saveData}
            disabled={!hasData}
          />
        </div>

      </div>
    </div>
  );
};

export default AddSupplyRoutine;
