import LoadingSpinner from 'components/LoadingSpinner';
import { GetTrainingsBy, useGetTrainingsQuery } from 'generated/graphql';
import lodashSum from 'lodash/sum';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import { selectSelectedLocationsSlug } from 'store/selectors/globalFilters.selectors';
import calcPercentage from 'utils/mathHelpers';

const SafetyTrainingChart2 = () => {
  const [chartData, setChartData] = useState<{
    arrayDataPercentage: number[],
    totalTrained: number,
  }>({ arrayDataPercentage: [], totalTrained: 0 });

  const selectedLocations = useSelector(selectSelectedLocationsSlug);

  const { data, loading, error, refetch } = useGetTrainingsQuery({
    variables: {
      data: {
        bu: selectedLocations?.businessUnit || '',
        region: selectedLocations?.zone || '',
        zone: selectedLocations?.subzone || '',
        location: selectedLocations?.location || '',
      },
      by: GetTrainingsBy.Bu,
    },
  });

  const series = [{
    name: 'Ejecutadas',
    data: chartData.arrayDataPercentage,
  }, {
    name: 'Total',
    data: [
      ...Array(chartData.arrayDataPercentage.length).fill(0),
      chartData.totalTrained,
    ],
  }];

  useEffect(() => {
    if (!data) return;
    const percentages: number[] = [];
    const trained: number[] = [];
    const trainings: number[] = [];
    data.getTrainings.forEach(training => {
      percentages.push(
        parseFloat(calcPercentage((training?.usersTrained || 0), (training?.usersToTrain || 0))),
      );
      trainings.push(parseFloat(String(training?.usersToTrain || 0)));
      trained.push(parseFloat(String(training?.usersTrained || 0)));
    });
    setChartData({
      arrayDataPercentage: percentages,
      totalTrained: Math.round((lodashSum(trained) / lodashSum(trainings)) * 100),
    });
  }, [data]);

  useEffect(() => {
    console.log(selectedLocations);
    refetch();
    // eslint-disable-next-line
  }, [
    selectedLocations,
  ]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <div> Something Went Wrong </div>;
  }

  const options = {
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    colors: ['#5572B9', '#A33223'],
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
          position: 'bottom',
          offsetX: -10,
          offsetY: 0,
        },
      },
    }],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10,
      },
    },
    xaxis: {
      categories: [...(data?.getTrainings || []).map(row => row?.name), 'Total'],
    },
    yaxis: {
      max: 100,
      label: {
        show: true,
        align: 'left',
        minWidth: 0,
        maxWidth: 160,
        style: {
          fontSize: '12px',
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 400,
          cssClass: 'apexcharts-yaxis-label',
        },
      },
    },
    legend: {
      position: 'top',
      offsetY: 5,
      offsetX: 240,
    },
    fill: {
      opacity: 1,
    },
  };
  // @ts-ignore
  return <ReactApexChart options={options} series={series} type="bar" height={350} />;
};

export default SafetyTrainingChart2;
