import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import MonthlyTimeFilter from 'components/MonthlyTimeFilter';
import LoadingSpinner from 'components/LoadingSpinner';
import Card from 'components/Cards/LocationCard';
import Disclaimer from 'components/Disclaimer/Disclaimer';
import { fromJS } from 'immutable';
import { selectCurrentAccesLevel } from 'store/selectors/distributionCenters.selectors';
import { setMonthlyDropdownChange } from 'store/actions/timeFilter.actions';
import { selectSelectedLocationsSlug } from 'store/selectors/globalFilters.selectors';
import { FILTER_ACCESS_LEVELS, TRANSLATIONS } from 'types/enums';
import downloadIconBlack from 'assets/icons/download-black.svg';
import { selectTimeFilter } from 'store/selectors/timeFilter.selector';
import { generateQueryDates } from 'utils/dateHelpers';
import './style.scss';
import { setBusinessUnit, setSubzone, setZone } from 'store/actions/globalFilters.actions';
import { NameAndSlug } from 'types/interfaces';
import useGetConsequenceCountsGroupByLocation from '../hooks/useGetConsequenceCountsGroupByLocation';

const ConsequenceReportBoard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [arrayOfLocationToShow, setArrayOfLocationToShow] = useState<any>(null);
  const selectedLocations = useSelector(selectSelectedLocationsSlug);
  const timeFilter = useSelector(selectTimeFilter) as any;
  const accessLevel = useSelector(selectCurrentAccesLevel);

  const [currentMonth, setCurrentMonth] = useState('currentMonth');

  const consequenceCount = useGetConsequenceCountsGroupByLocation({
    startTime: timeFilter.get('startTime'),
    endTime: timeFilter.get('endTime'),
  });

  useEffect(() => {
    dispatch(setMonthlyDropdownChange('currentMonth'));
  }, [dispatch]);

  useEffect(() => {
    let arrOfLocations = null;
    if (consequenceCount.result) {
      console.log(consequenceCount.result);
      const countLogs = fromJS(consequenceCount.result);
      const businessUnitIndex = countLogs.findIndex(
        (bu: any) => bu.get('slug') === selectedLocations.businessUnit,
      );
      const zoneIndex = businessUnitIndex !== -1
        && countLogs
          .getIn([businessUnitIndex, 'zones'])
          .findIndex((zone: any) => zone.get('slug') === selectedLocations.zone);
      const subzoneIndex = zoneIndex !== -1
        && zoneIndex !== false
        && countLogs
          .getIn([businessUnitIndex, 'zones', zoneIndex, 'subzones'])
          .findIndex((subzone: any) => subzone.get('slug') === selectedLocations.subzone);

      if (selectedLocations.subzone) {
        arrOfLocations = countLogs.getIn([
          businessUnitIndex,
          'zones',
          zoneIndex,
          'subzones',
          subzoneIndex,
          'locations',
        ]);
        setArrayOfLocationToShow(arrOfLocations || countLogs);
        return;
      }

      if (selectedLocations.zone) {
        arrOfLocations = countLogs.getIn([businessUnitIndex, 'zones', zoneIndex, 'subzones']);
        setArrayOfLocationToShow(arrOfLocations || countLogs);
        return;
      }

      if (selectedLocations.businessUnit) {
        arrOfLocations = countLogs.getIn([businessUnitIndex, 'zones']);
        setArrayOfLocationToShow(arrOfLocations || countLogs);
        return;
      }

      setArrayOfLocationToShow(arrOfLocations || countLogs);
    }
  }, [consequenceCount.result, selectedLocations]);

  const handleMonthChange = (value: 'typeOf MonthlyOptions') => {
    setCurrentMonth(value);
    dispatch(setMonthlyDropdownChange(value));
  };

  const downloadActionLogsReport = (locationSlug: string) => {
    const params = {
      [accessLevel]: locationSlug,
      ...generateQueryDates(timeFilter.get('startTime'), timeFilter.get('endTime')),
    };
    const queryString = Object.keys(params)
    // @ts-ignore
      .map(key => `${key}=${params[key]}`)
      .join('&');

    window.open(`/api/v1/consequence/report-download?${queryString}`, '_blank');
  };

  const handleCardClick = ({ name, slug }: NameAndSlug) => {
    switch (accessLevel) {
      case FILTER_ACCESS_LEVELS.DISTRIBUTION_CENTER:
        break;
      case FILTER_ACCESS_LEVELS.SUBZONE:
        dispatch(setSubzone({ name, slug }));
        break;
      case FILTER_ACCESS_LEVELS.ZONE:
        dispatch(setZone({ name, slug }));
        break;
      case FILTER_ACCESS_LEVELS.BUSINESS_UNIT:
        dispatch(setBusinessUnit({ name, slug }));
        break;
      default:
        break;
    }
  };

  return (
    <>

      {arrayOfLocationToShow ? (
        <div className="reportBoard-consequence">
          <div>
            <div className="reportBoard-consequence__top-menu">
              <div className="reportBoard-consequence__top-menu__buttons">
                <MonthlyTimeFilter
                  onChange={handleMonthChange}
                  currentValue={currentMonth}
                  t={t}
                  bgColor="transparent"
                />
                {consequenceCount.loading && <LoadingSpinner text=" " height={25} />}
              </div>
            </div>
            <div className="reportBoard-consequence__list">
              {arrayOfLocationToShow?.map((locationToShow: any) => (
                <Card
                  name={locationToShow.get('name')}
                  slug={locationToShow.get('slug')}
                  counter={locationToShow.get('count')}
                  handleClick={handleCardClick}
                  key={locationToShow.get('slug')}
                  downloadComponent={(
                    <div
                      style={{
                        display: 'flex',
                        flexGrow: 1,
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: 3,
                      }}
                      onClick={() => downloadActionLogsReport(locationToShow.get('slug'))}
                      role="button"
                    >
                      <img src={downloadIconBlack} alt="download icon" width={10} />
                      <p
                        style={{ marginLeft: 5 }}
                      >
                        {t(TRANSLATIONS.DOWNLOAD)}
                      </p>
                    </div>
                  )}
                />
              ))}
            </div>
          </div>
          {arrayOfLocationToShow?.size === 0 && (
            <p style={{ textAlign: 'center' }}>
              {t(TRANSLATIONS.YOU_DONT_HAVE_ANY_LOCATION_ASSIGNED_PLEASE_CONTACT_SUPPORT)}
            </p>
          )}
          <div className="extra-notes">
            <Disclaimer message={TRANSLATIONS.USER_ACCESS_DISCLAIMER} />
          </div>
        </div>
      ) : (
        <div className="reportBoard-consequence__loader">
          <LoadingSpinner />
        </div>
      )}
    </>
  );
};

export default ConsequenceReportBoard;
