/* eslint global-require: "off" */

const ImageLinks = {
  logo: require('../assets/icons/logo.svg').default,
  ac: require('../assets/icons/ac.svg').default,
  iconShield: require('../assets/icons/icon-shield.svg').default,
  nutIcon: require('../assets/icons/nut-icon.svg').default,
  lockIcon: require('../assets/icons/lock-icon.svg').default,
  lockWarningIcon: require('../assets/icons/lock-icon-orange.svg').default,
  userIcon: require('../assets/icons/user-icon.svg').default,
  userWarningIcon: require('../assets/icons/user-warning.svg').default,
  goalIcon: require('../assets/icons/goal.svg').default,
  chevronDownIcon: require('../assets/icons/chevron-down.svg').default,
  chevronRightBlueIcon: require('../assets/icons/chevron-right-blue@3x.png'),
  checkIcon: require('../assets/icons/check.svg').default,
  crossIcon: require('../assets/icons/cross.png'),
  userRegularIcon: require('../assets/icons/user-regular.svg').default,
  wetFloorIcon: require('../assets/icons/wet-floor.svg').default,
  timeIcon: require('../assets/icons/time.svg').default,
  progressIcon: require('../assets/icons/progress-icon.svg').default,
  fileIcon: require('../assets/icons/file-icon.svg').default,
  uploadIcon: require('assets/icons/upload-icon.png'),
  warningIcon: require('../assets/icons/warningIcon@3x.png'),
  closeMaterial: require('../assets/icons/close-24px.svg').default,
  leftMaterial: require('../assets/icons/keyboard_arrow_left-24px.svg').default,
  rightMaterial: require('../assets/icons/keyboard_arrow_right-24px.svg').default,
  afterPasswordBackground: require('../assets/icons/after-password-background.svg').default,
  chevronLeft: require('../assets/icons/chevron-left.svg').default,
  guyWithCheckmark: require('../assets/icons/guy-with-checkmark.svg').default,
  home: require('../assets/icons/home.png'),
  hospital: require('../assets/icons/hospital.png'),
  greenSuccess: require('../assets/icons/green-success.png'),
  medicalKit: require('../assets/icons/medical-kit.png'),
  house: require('../assets/icons/house.png'),
  attachFile: require('../assets/icons/attach-files.png'),
  message: require('../assets/icons/message.png'),
  calendar: require('../assets/icons/calendar.png'),
  finished: require('../assets/icons/finished.png'),
  watching: require('../assets/icons/watching.png'),
  settingsColored: require('../assets/icons/settings-color.png'),
  docsOpened: require('../assets/icons/docs-opened.png'),
  searchIcon: require('../assets/icons/search-icon.png'),
  summary: require('../assets/icons/summary.png'),
  grayCross: require('../assets/icons/gray-cross.png'),
  greenCalender: require('../assets/icons/green-calander.png'),
  chevronDown: require('../assets/icons/chevron-down.png'),
  greenChecked: require('../assets/icons/green-check.png'),
  crown: require('../assets/icons/crown.png'),
  delete: require('../assets/icons/delete.png'),
  dpo: {
    positiveShield: require('../assets/icons/positiveShield.png'),
    management: require('../assets/icons/management.png'),
    planning: require('../assets/icons/planning.png'),
    distribution: require('../assets/icons/distribution.png'),
    fleet: require('../assets/icons/fleet.png'),
    stock: require('../assets/icons/stock.png'),
    people: require('../assets/icons/people.png'),
  },
  tick: require('../assets/icons/check.png'),
  edit: require('../assets/icons/edit.png'),
  addBlue: require('../assets/icons/blue-plus.png'),
  greenPlus: require('../assets/icons/green-plus.png'),
  comments: require('../assets/icons/empty-comments.svg').default,
  remove: require('../assets/icons/remove.png'),
  add: require('../assets/icons/add.png'),
  checked: require('../assets/icons/checked.png'),
  search_black: require('../assets/icons/search-black.png'),
  chevron_right: require('../assets/icons/chevron-right.png'),
  emptyCheck: require('../assets/icons/empty-check.svg').default,
  checkRedSelected: require('../assets/icons/check-red-selected.svg').default,
  emptyRadioUnchecked: require('../assets/icons/empty-radio-unchecked.svg').default,
  yellowRadioChecked: require('../assets/icons/yellow-radio-checked.svg').default,
  emailIcon: require('../assets/icons/email-icon.svg').default,
  positionIcon: require('../assets/icons/file-red.svg').default,
  deleteIcon: require('../assets/icons/delete-icon-empty.svg').default,
  editIcon: require('../assets/icons/edit-icon.svg').default,
  leftArrowIcon: require('../assets/icons/arrow-left.svg').default,
  blueFilledCheck: require('../assets/icons/blue-filled-check.svg').default,
  grayEmptyCheck: require('../assets/icons/gray-empty-check.svg').default,
  categoryUser: require('../assets/icons/category-user.png'),
  filter: require('../assets/icons/filters.svg').default,
  errorImage: require('../assets/icons/error-message.png'),
  warehouse: require('assets/icons/werehouse/warehouse.png'),
  factory: require('assets/icons/factory/factory.png'),
  download: require('assets/icons/img/download.png'),
  overwrite: require('assets/icons/img/overwrite.png'),
  upload: require('assets/icons/img/upload.png'),
  file: require('assets/icons/img/file.png'),
  info: require('assets/icons/img/info.png'),
  loadingIcon: require('assets/gif/loading.gif'),
  EmptyImg: require('assets/icons/img/empty-state.png'),
  SelectDC: require('assets/icons/img/Select-DC.png'),
  SelectDCImg: require('assets/icons/img/Select-DCImg.png'),
  selectGlobalLocation: require('assets/icons/select-location.png'),
  eye: require('assets/icons/eye-icon.svg').default,
  eyex2: require('assets/icons/eye/eye@2x.png'),
  location: require('assets/icons/location.svg').default,
  location2: require('assets/icons/location-2.svg').default,
  factory2: require('assets/icons/factory.svg').default,
  more: require('assets/icons/more.svg').default,
  shield: require('assets/icons/shield.svg').default,
  thunder: require('assets/icons/thunder.svg').default,
  thunder2: require('assets/icons/thunder-2.svg').default,
  greenFilledCheck: require('../assets/icons/green-filled-check.svg').default,
  acis: require('assets/icons/shield-2.svg').default,
  acis2: require('assets/icons/shield-3.svg').default,
  acis3: require('assets/icons/shield-4.svg').default,
  whiteDelete: require('assets/icons/whiteDelete.png'),
  chevronDownBlack: require('assets/icons/chevronDownBlack.png'),
  blueBgTick: require('assets/icons/blueBgTick/blueBgTick.png'),
  finances: require('assets/icons/Finances.png'),
  commercial: require('assets/icons/Commercial.png'),
  services: require('assets/icons/Services.png'),
  verticalDots: require('assets/icons/vertical-dots.svg').default,
  deleteGrey: require('assets/icons/deleteGrey.svg').default,
  noData: require('assets/icons/noData.svg').default,
  selectDc: require('assets/icons/SelectDC.svg').default,
  truck3: require('assets/icons/Truck3.svg').default,
  chevronLeftV1: require('assets/icons/chevron-left-1.svg').default,
  chevronRightV1: require('assets/icons/chevron-right-1.svg').default,
  arrowRightV1: require('assets/icons/arrow-right-1.svg').default,
  fileGood: require('assets/icons/file-good.svg').default,
  locationV1: require('assets/icons/location-1.svg').default,
  arrowLeftV1: require('assets/icons/arrow-left-1.svg').default,
  clock: require('assets/icons/clock.svg').default,
  map: require('assets/icons/map.svg').default,
  deleteCircle: require('assets/icons/delete-circleA.svg').default,
  chevronDown2: require('assets/icons/chevron-down2.svg').default,
  inputSearch: require('assets/icons/input-search.svg').default,
  filledCheck: require('assets/icons/filled-check.svg').default,
  chevronDownBlue: require('assets/icons/chevron-down-bluev1.svg').default,
  clockChevronRight: require('assets/icons/clock-chevron-right.svg').default,
  clockChevronLeft: require('assets/icons/clock-chevron-left.svg').default,
  locationBlack: require('assets/icons/location-black.svg').default,
  listHorizontal: require('assets/icons/List-horizontal.svg').default,
  list: require('assets/icons/List.svg').default,
  roadDamaged: require('assets/icons/road-damaged.svg').default,
  roadInclined: require('assets/icons/road-inclined.svg').default,
  roadLarge: require('assets/icons/road-large.svg').default,
  roadCurved: require('assets/icons/road-curved.svg').default,
  mountains: require('assets/icons/mountains.svg').default,
  roadWithoutShoulder: require('assets/icons/road-without-shoulder.svg').default,
  roadUneven: require('assets/icons/road-uneven.svg').default,
  fog: require('assets/icons/fog.svg').default,
  truckV1: require('assets/icons/truck-v1.svg').default,
  construction: require('assets/icons/construction.svg').default,
  animal: require('assets/icons/animal.svg').default,
  greenCheck: require('assets/icons/green-check-icon.png'),
  redCross: require('assets/icons/red-cross-icon.png'),
};

export default ImageLinks;
