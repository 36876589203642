import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectLocationsToShow } from 'store/selectors/distributionCenters.selectors';
import { addMonths, differenceInDays, endOfMonth, format, startOfMonth } from 'date-fns';
import { generateYearOptionsAsPerABInDevCalender, getTranslatedDateTimeValue } from 'utils/dateHelpers';
import ChartDropdown from 'components/Dropdown/ChartDropdown';
import CashlessBar from './CashlessBar/CashlessBar';
// @ts-ignore
import CashlessBarCAC from './CashlessBar/CashlessBarCAC';
import CashlessTable from './CashlessTable/CashlessTable';
import './Cashless.scss';

const CASHLESS_START_DATE = new Date(2021, 6, 1);

let monthOptions: any[] = [];
let cashLessReportMonthDate = CASHLESS_START_DATE;
while (differenceInDays(startOfMonth(new Date()), cashLessReportMonthDate) >= 0) {
  monthOptions.push({
    slug: getTranslatedDateTimeValue(cashLessReportMonthDate, 'MMM yyyy'),
    label: getTranslatedDateTimeValue(cashLessReportMonthDate, 'MMM yyyy'),
    dateRange: [
      format(startOfMonth(cashLessReportMonthDate), 'yyyy-MM-dd'),
      format(endOfMonth(cashLessReportMonthDate), 'yyyy-MM-dd'),
    ],
  });
  cashLessReportMonthDate = addMonths(cashLessReportMonthDate, 1);
}

monthOptions.push(...generateYearOptionsAsPerABInDevCalender(CASHLESS_START_DATE));
monthOptions = monthOptions.reverse();
const Cashless = () => {
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [selectedDropDownItem, setSelectedDropDownItem] = useState(
    monthOptions[0],
  );

  // @ts-ignore
  const handleDropDownChange = ({ key }) => {
    setSelectedDropDownItem(monthOptions.find(item => item.slug === key) || monthOptions[0]);
    setDropDownOpen(false);
  };

  const locationsObject = useSelector(selectLocationsToShow);

  return (
    <div className="container-fluid overview-wrapper">
      <h1 className="cashless__title">Cashless</h1>
      <ChartDropdown
        onChange={handleDropDownChange}
        selectedDropDownItem={selectedDropDownItem}
        dropDownItems={monthOptions}
        dropDownOpen={dropDownOpen}
        setDropDownOpen={setDropDownOpen}
        labelClassName="label-default"
      />
      <div className="row contenWrap">
        <div className="col-12 card overview-card">
          <CashlessBar
            locationsObject={locationsObject}
            dateRange={selectedDropDownItem.dateRange}
          />
        </div>
      </div>
      <h2 className="cashless__title">CAC</h2>
      <div className="row">
        <div className="col-12 card overview-card">
          <CashlessBarCAC dateRange={selectedDropDownItem.dateRange} />
        </div>
      </div>
      <div className="row">
        <div className="col-12 card">
          <CashlessTable />
        </div>
      </div>
    </div>
  );
};

export default Cashless;
