import React, { PureComponent } from 'react';
import { NavLink as Link } from 'react-router-dom';
import ImageLinks from 'utils/ImageLinks';
import HighlightButton from 'components/Button/HighlightButton';
import request from 'utils/request';
import LoadingSpinner from 'components/LoadingSpinner';
import InputWithIcon from 'components/Input/InputWithIcon';

import 'features/internal-users/user/Register/Register.scss';
import { withTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';
import TextWithIcon from 'components/Text/TextWithIcon';
import withParams from 'utils/hoc/withParams';
import PasswordSetSuccess from './PasswordSetSuccess';

type State = {
  password: string;
  passwordConfirm: string;
  submitting: boolean;
  errors: any;
  email: string;
  isLoadingUserInfo: boolean;
  passwordChanged: boolean;
  passwordCriteria?: boolean;
};

class Register extends PureComponent<any, State> {
  missingCriteria = [
    { icon: ImageLinks.info, text: 'CAPITAL_LETTER' },
    { icon: ImageLinks.info, text: 'LOWER_CASE' },
    { icon: ImageLinks.info, text: 'NUMBER' },
    { icon: ImageLinks.info, text: 'SPECIAL_CHARACTER' },
  ];

  constructor(props: any) {
    super(props);
    this.state = {
      password: '',
      passwordConfirm: '',
      submitting: false,
      errors: {},
      email: '',
      isLoadingUserInfo: true,
      passwordChanged: false,
    };
    this.submit = this.submit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.enterSubmit = this.enterSubmit.bind(this);
    this.validatePassword = this.validatePassword.bind(this);
  }

  async componentDidMount() {
    try {
      const d = await request.get(`/api/v1/users/token/${this.props.params.token}`);
      this.setState({
        isLoadingUserInfo: false,
        email: ((d.user && d.user.email) || ''),
      });
    } catch (error) {
      if ((error as any)
        .response?.status === 401) return this.setState({ isLoadingUserInfo: false });
      if ((typeof (error as any).response.data) !== 'object') {
        this.setState({ errors: { message: 'Error de red' }, isLoadingUserInfo: false });
      } else {
        this.setState({ errors: (error as any).response.data, isLoadingUserInfo: false });
      }
    }
  }

  validatePassword(pass: string) {
    const specialCharacters = '@$!%*?&-/()=¿^_´¨,.¢#|';
    const specialCharactersRegex = new RegExp(`[${specialCharacters}]`);

    this.missingCriteria.forEach((criteria, index) => {
      let isCriteriaMet = false;
      let passCriteria = false;
      switch (index) {
        case 0: // Capital letter
          isCriteriaMet = /[A-Z]/.test(pass);
          break;
        case 1: // Lower case
          isCriteriaMet = /[a-z]/.test(pass);
          break;
        case 2: // Number
          isCriteriaMet = /[0-9]/.test(pass);
          break;
        case 3: // Special character
          isCriteriaMet = specialCharactersRegex.test(pass);
          break;
        default:
          break;
      }
      if (/[A-Z]/.test(pass) && /[a-z]/.test(pass) && /[0-9]/.test(pass) && specialCharactersRegex.test(pass)) {
        passCriteria = true;
      }

      criteria.icon = isCriteriaMet ? ImageLinks.checked : ImageLinks.info;

      this.setState({ passwordCriteria: passCriteria });
    });
  }

  async submit() {
    const { t } = this.props;
    const { passwordCriteria } = this.state;
    this.setState({ errors: {} });
    if (this.state.password.length < 7) {
      this.setState({ errors: { password: { msg: t(TRANSLATIONS.PASSWORD_TOO_SHORT) } } });
      return null;
    }
    if (!passwordCriteria) {
      this.setState(
        { errors: { message: t(TRANSLATIONS.VERIFY_PASSWORD_CRITERIA),
          password: '' },
        submitting: false },
      );
      return null;
    }
    if (this.state.password !== this.state.passwordConfirm) {
      this.setState({ errors: { passwordConfirm: { msg: t(TRANSLATIONS.PASSWORD_NO_MATCH) } } });
      return null;
    }
    this.setState({ submitting: true, errors: {} });
    try {
      await request.post('/api/v1/users/register', {
        accessToken: this.props.params.token,
        password: this.state.password,
      });
      this.setState({ passwordChanged: true });
    } catch (error) {
      if ((typeof (error as any).response.data) !== 'object') {
        this.setState({ errors: { message: 'Error de red' }, submitting: false });
      } else {
        this.setState({ errors: (error as any).response.data, submitting: false });
      }
    }
  }

  handleInputChange(e: any) {
    // @ts-ignore
    this.setState({ [e.target.name]: e.target.value });
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    e.target.name === 'password' && this.validatePassword(e.target.value);
  }

  enterSubmit(e: React.KeyboardEvent<HTMLFormElement>) {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      e.stopPropagation();
      this.submit();
    }
  }

  render() {
    const { errors } = this.state;
    const { t } = this.props;
    // 500 error responses are not formatted within input field keys
    // but rather contain a single message key
    let nonValidationError = '';
    if (!errors.email && !errors.password && errors.message) {
      nonValidationError = errors.message;
    }
    if (this.state.passwordChanged) {
      return (
        <PasswordSetSuccess register />
      );
    }
    return (
      <div className="register-bg">
        <div className="register">
          <div className="container">
            <div className="row">
              <div className="col-md-6 offset-md-3 card main-card-container">
                <p className="header-text">
                  {t(TRANSLATIONS.WELCOME_TO)}<br /><span className="font-bold">Safety 360</span>
                </p>
                {nonValidationError ? (
                  <h3 className="error text-center spacing-top">
                    {nonValidationError}
                  </h3>
                ) : null}
                {!this.state.isLoadingUserInfo
                  ? this.state.email === ''
                    ? (
                      <div className="invalid-token">
                        <h3>{nonValidationError || t(TRANSLATIONS.THIS_URL_HAS_EXPIRED)}</h3>
                        <Link to="/" className="link go-to-home"><p>{t(TRANSLATIONS.GO_TO_LOGIN)}</p></Link>
                      </div>
                    )
                    : (
                      <>
                        <p className="welcome-text">
                          {t(TRANSLATIONS.CREATE_YOUR_PASSWORD_TO_ACCESS)}
                        </p>
                        <InputWithIcon
                          icon={ImageLinks.userIcon}
                          errorIcon={ImageLinks.userWarningIcon}
                          type="text"
                          placeholder={t(TRANSLATIONS.EMAIL)}
                          onChange={() => ''}
                          value={this.state.email}
                          error={errors.email && errors.email.msg}
                          errorHeight
                          name="email"
                        />
                        <form onSubmit={this.submit} onKeyDown={this.enterSubmit}>
                          <InputWithIcon
                            icon={ImageLinks.lockIcon}
                            errorIcon={ImageLinks.lockWarningIcon}
                            type="password"
                            placeholder={t(TRANSLATIONS.PASSWORD)}
                            onChange={this.handleInputChange}
                            name="password"
                            errorHeight
                            error={errors.password && errors.password.msg}
                          />
                          <p className="text-margin">
                            {t(TRANSLATIONS.PASSWORD_MUST)}:
                          </p>
                          {this.missingCriteria.map((item, index) => (
                            <>
                              <div className={`centered-container${index === this.missingCriteria.length - 1 ? ' marginBottomToLast' : ''}`}>
                                <TextWithIcon
                                  icon={item.icon}
                                  text={`${t(TRANSLATIONS.CONTAIN)} ${t(item.text)}`}
                                />
                              </div>
                            </>
                          ))}
                          <InputWithIcon
                            icon={ImageLinks.lockIcon}
                            errorIcon={ImageLinks.lockWarningIcon}
                            type="password"
                            placeholder={t(TRANSLATIONS.CONFIRM_PASSWORD)}
                            onChange={this.handleInputChange}
                            name="passwordConfirm"
                            errorHeight
                            error={errors.passwordConfirm && errors.passwordConfirm.msg}
                          />
                        </form>
                        <p className="detail-text">
                          {t(TRANSLATIONS.ALREADY_HAVE_AN_ACCOUNT)} &nbsp;
                          <Link
                            to="/login"
                            className="link"
                            tabIndex={-1}
                          >
                            {t(TRANSLATIONS.LOGIN)}
                          </Link>
                        </p>
                        <HighlightButton
                          text={t(TRANSLATIONS.LOGIN).toUpperCase()}
                          className="main-action"
                          onClick={this.submit}
                        />
                        {this.state.submitting && <LoadingSpinner className="register-spinner" />}
                      </>
                    )
                  : <LoadingSpinner height={397} />}
              </div>
            </div>
            <div className="row">
              <div className="footer">
                <p className="footer-text"> powered by ARVOLUTION </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withParams(Register));
