import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';

function ConcludeStatus() {
  const { t } = useTranslation();

  return (
    <section className="root_padding conclude_container">
      <div className="header_section">
        <p className="header_section__subtitle">{t(TRANSLATIONS.YOUR_QUARANTINE_HAS)}</p>
        <h2 className="header_section__header">{t(TRANSLATIONS.CONCLUDED)} </h2>
      </div>
      <p className="conclude_container_description">
        Estamos sumamente felices de saber que has superado esta etapa. Nos da gusto saber que
        podrás seguir laborando con nosotros. Lo más importante para nosotros es…{' '}
      </p>
    </section>
  );
}

export default ConcludeStatus;
