type FiltersParameters = {
  dimension?: string;
  operator?: string;
  values?: string[] | string;
};

type DateParameters = {
  dimension?: string[] | string;
  granularity?: string
  dateRange?: string[] | string;
};

interface Parameters {
  measure?: string[] | string;
  dateOptions?: DateParameters;
  filterOptions?: FiltersParameters[];
  dimensions?: string[];
  order?: {}
}

const getCubeQuery = (queryParameters: Parameters) => {
  const { measure: measures, dateOptions, dimensions, order } = queryParameters;
  const filters = queryParameters.filterOptions;

  return ({
    measures,
    timeDimensions: dateOptions ? [dateOptions] : undefined,
    dimensions,
    filters,
    order,
  });
};

export default getCubeQuery;
