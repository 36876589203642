import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ChartDropdown from 'components/Dropdown/ChartDropdown';
import { FILTER_ACCESS_LEVELS, TRANSLATIONS } from 'types/enums';
import { generateQueryDates, routinesMonthSelectordropDownItems } from 'utils/dateHelpers';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentAccesLevel } from 'store/selectors/distributionCenters.selectors';
import { selectSelectedLocationsSlug } from 'store/selectors/globalFilters.selectors';
import './RoutinesExcelDownload.scss';
import { downloadRoutinesExcel } from 'store/actions/distributionCenter.actions';
import BasicButton from 'components/Button/BasicButton';
import { SelectInfo } from 'rc-menu/lib/interface';

const dropDownItems = routinesMonthSelectordropDownItems(true);

const RoutinesExcelDownload = () => {
  const { t } = useTranslation();

  const [dropDownOpen, setDropDownOpen] = useState(false);
  const dispatch = useDispatch();
  const [selectedDropDownItem, setSelectedDropDownItem] = useState(dropDownItems[0]);
  const accessLevel = useSelector(selectCurrentAccesLevel);
  const selectedLocations = useSelector(selectSelectedLocationsSlug);

  const handleDropDownChange = ({ key }: SelectInfo) => {
    setSelectedDropDownItem(dropDownItems.find(item => item.slug === key) || dropDownItems[0]);
    setDropDownOpen(false);
  };

  const downloadRoutinesExcelClick = useCallback(() => {
    let params = '';
    if (accessLevel === FILTER_ACCESS_LEVELS.DISTRIBUTION_CENTER) {
      params = `${FILTER_ACCESS_LEVELS.SUBZONE}=${selectedLocations.subzone}`;
    }
    if (accessLevel === FILTER_ACCESS_LEVELS.DISTRIBUTION_CENTER && selectedLocations.location) {
      params = `${FILTER_ACCESS_LEVELS.DISTRIBUTION_CENTER}=${selectedLocations.location}`;
    }
    if (accessLevel === FILTER_ACCESS_LEVELS.SUBZONE) {
      params = `${FILTER_ACCESS_LEVELS.ZONE}=${selectedLocations.zone}`;
    }
    if (accessLevel === FILTER_ACCESS_LEVELS.ZONE) {
      params = `${FILTER_ACCESS_LEVELS.BUSINESS_UNIT}=${selectedLocations.businessUnit}`;
    }
    const timeQuery = generateQueryDates(
      selectedDropDownItem.content.startWeek,
      selectedDropDownItem.content.lastDayOfLastWeek,
    );
    dispatch(downloadRoutinesExcel(params, timeQuery));
  }, [selectedLocations, accessLevel, dispatch, selectedDropDownItem]);

  return (
    <div className="routines-excel-download-container">
      <ChartDropdown
        onChange={handleDropDownChange}
        selectedDropDownItem={selectedDropDownItem}
        dropDownItems={dropDownItems}
        dropDownOpen={dropDownOpen}
        setDropDownOpen={setDropDownOpen}
        labelClassName="label-default"
      />
      <BasicButton
        text={`${t(TRANSLATIONS.DOWNLOAD)} Routines Excel`}
        onClick={downloadRoutinesExcelClick}
        className="routines-download-button"
        background="#F0F0F0"
      />
    </div>
  );
};

export default RoutinesExcelDownload;
