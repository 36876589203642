import ChartDropdown from 'components/Dropdown/ChartDropdown';
import { User_Type as USER_TYPE, GetLocationsDepartmentAreaSubareaDocument } from 'generated/graphql';
import { sortBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';
import { useLazyQuery } from '@apollo/client';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import refresh from '../../../../assets/icons/reset.svg';
import request from '../../../../utils/request';

interface UserFilterProps {
  filters: any,
  setFilters: any
  resetFilters: any
  setSearch: any,
  isLoading: boolean
  showDeletedUsers: boolean
}

const useStyles = makeStyles(() => ({
  // https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90

  root: {
    '& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
      transform: 'translate(34px, 20px) scale(1);',
    },
    '&.Mui-focused .MuiInputLabel-outlined': {
      color: 'transparent',
    },
  },
  inputRoot: {
    color: '#404040',
    flexWrap: 'wrap',
    height: 32,
    fontSize: '12px !important',
    fontWeight: 400,
    '&.MuiOutlinedInput-notchedOutline': {
      borderColor: '#d6d6d6',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#d6d6d6',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#d6d6d6',
    },
  },
}));

const UserFilters = (props: UserFilterProps) => {
  const { filters, setFilters, resetFilters, setSearch, isLoading, showDeletedUsers } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const [distCenterInfo, setDistCenterInfo] = useState<any>();
  const [dropDownOpenLocation, setDropDownOpenLocation] = useState(false);
  const [dropDownOpenType, setDropDownOpenType] = useState(false);
  const [dropDownOpenLogistic, setDropDownOpenLogistic] = useState(false);
  const [availableRoles, setAvailableRoles] = useState<any[]>([]);
  const isSelectedLocationFactory = distCenterInfo?.type === 'FACTORY';

  const dropDownItemsExtInt = [{
    slug: '',
    label: t(TRANSLATIONS.ALL),
  },
  {
    slug: USER_TYPE.Contractor,
    label: t(TRANSLATIONS.EXTERNAL),
  }, {
    slug: USER_TYPE.Internal,
    label: t(TRANSLATIONS.INTERNAL),
  },
  ];
  const [dropDownItemsLocation, setDropdownItemsLocation] = useState([{
    slug: '',
    label: t(TRANSLATIONS.SEARCH_LOCATION),
  }]);
  const [dropDownItemsDepartment, setdropDownItemsDepartment] = useState([{
    slug: '',
    label: t(TRANSLATIONS.SEARCH),
  }]);
  const [dropDownItemsArea, setdropDownItemsArea] = useState([{
    slug: '',
    label: t(TRANSLATIONS.SEARCH),
  }]);
  const [dropDownItemsSubArea, setdropDownItemsSubArea] = useState([{
    slug: '',
    label: t(TRANSLATIONS.SEARCH),
  }]);

  const [dropDownItemsRoles, setDropDownItemsRoles] = useState([{
    slug: '',
    label: t(TRANSLATIONS.SEARCH),
  }]);

  const [areasOfSelectedDepartment, setAreasOfSelectedDepartment] = useState([]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedDropDownItem, setSelectedDropDownItem] = useState(
    dropDownItemsExtInt.find((item: any) => item.slug === filters.userType),
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedLocation, setSelectedLocation] = useState(
    dropDownItemsLocation.find((item: any) => item.slug === filters.locationSlug),
  );

  const [selectedDepartment, setSelectedDepartment] = useState(
    dropDownItemsDepartment.find((item: any) => item.slug === filters.departmentSlug),
  );

  const [selectedArea, setSelectedArea] = useState(
    dropDownItemsArea.find((item: any) => item.slug === filters.areaSlug),
  );

  const [selectedSubArea, setSelectedSubArea] = useState(
    dropDownItemsSubArea.find((item: any) => item.slug === filters.subAreaSlug),
  );

  const [selectedDropDownItemType, setSelectedDropDownItemType] = useState(
    dropDownItemsRoles.find((item: any) => item.slug === filters.roleSlug),
  );

  const handleFilter = (filter: string, value: any) => {
    if (filter === 'locationSlug') {
      setFilters((f: any) => ({ ...f, departmentSlug: '', page: 1 }));
      setFilters((f: any) => ({ ...f, areaSlug: '', page: 1 }));
      setFilters((f: any) => ({ ...f, subAreaSlug: '', page: 1 }));
    }
    if (filter === 'departmentSlug') {
      setFilters((f: any) => ({ ...f, areaSlug: '', page: 1 }));
      setFilters((f: any) => ({ ...f, subAreaSlug: '', page: 1 }));
    }
    if (filter === 'areaSlug') {
      setFilters((f: any) => ({ ...f, subAreaSlug: '', page: 1 }));
    }
    setDropDownOpenLocation(false);
    setDropDownOpenType(false);
    const updatedValue = value === 'item_0' ? '' : value;
    setFilters((f: any) => ({ ...f, [filter]: updatedValue, page: 1 }));
  };

  const handleResetFilters = () => {
    setFilters(resetFilters);
    setSearch('');
  };

  const [getLocationsDepartmentAreaSubarea, { data }] = useLazyQuery(
    GetLocationsDepartmentAreaSubareaDocument,
  );

  useEffect(() => {
    const deps = data?.getLocationsDepartmentAreaSubarea[0]?.departments;
    if (deps) {
      setdropDownItemsDepartment([{
        slug: '',
        label: t(TRANSLATIONS.SEARCH),
      }, ...deps?.map((item: any) => ({ slug: item?.slug, label: item?.name }))]);
    }
  // eslint-disable-next-line
  }, [data?.getLocationsDepartmentAreaSubarea]);

  useEffect(() => {
    const fetchSelectedLocationInfo = async () => {
      const { distCenterDetails }: any = selectedLocation?.slug && (await request.get(`/api/v1/${selectedLocation?.slug}/dist-center`) || {});
      setDistCenterInfo(distCenterDetails);
      if (distCenterDetails?.id) {
        await getLocationsDepartmentAreaSubarea({
          variables: { locationIds: [distCenterDetails?.id] },
        });
      }
    };

    fetchSelectedLocationInfo();
  // eslint-disable-next-line
  }, [selectedLocation]);

  useEffect(() => {
    const setAreasFilters = async () => {
      const filterSelectedDep = await data?.
        getLocationsDepartmentAreaSubarea[0]?.
        departments?.filter((dep: any) => {
          return dep.slug === selectedDepartment?.slug;
        });
      const areasFiltered = filterSelectedDep && filterSelectedDep[0]?.areas;
      await setAreasOfSelectedDepartment(areasFiltered);
      if (areasFiltered) {
        await setdropDownItemsArea([{
          slug: '',
          label: t(TRANSLATIONS.SEARCH),
        }, ...areasFiltered?.map((item: any) => (
          { slug: item?.slug, label: item?.name }))]);
      }
    };
    setAreasFilters();
  // eslint-disable-next-line
  }, [selectedDepartment]);

  useEffect(() => {
    const filterSelectedArea = areasOfSelectedDepartment?.filter((area:any) => {
      return area.slug === selectedArea?.slug;
    });
    const subAreasFiltered = filterSelectedArea && (filterSelectedArea as any)[0]?.subAreas;
    if (subAreasFiltered) {
      setdropDownItemsSubArea([{
        slug: '',
        label: t(TRANSLATIONS.SEARCH),
      }, ...subAreasFiltered?.map((item: any) => (
        { slug: item?.slug, label: item?.name }))]);
    }
  // eslint-disable-next-line
  }, [selectedArea]);

  useEffect(() => {
    if (!availableRoles?.length) return;
    let updatedDropdownRoles = [{
      slug: '',
      label: t(TRANSLATIONS.SEARCH),
    }];
    if (distCenterInfo?.type === 'FACTORY') {
      updatedDropdownRoles = [...updatedDropdownRoles, ...availableRoles?.filter((item: any) => item?.category !== 'LOGISTICS')];
    } else if (distCenterInfo?.type === 'DISTRIBUTION_CENTER') {
      updatedDropdownRoles = [...updatedDropdownRoles, ...availableRoles?.filter((item: any) => item?.category !== 'SUPPLY')];
    } else {
      updatedDropdownRoles = [...updatedDropdownRoles, ...availableRoles];
    }
    setDropDownItemsRoles(updatedDropdownRoles);
  // eslint-disable-next-line
  }, [distCenterInfo, availableRoles]);

  useEffect(() => {
    const fetchLocation = async () => {
      let locations = await request.post('/api/v1/users/locations', {}) || [];
      locations = sortBy(locations, 'name');
      setDropdownItemsLocation([{
        slug: '',
        label: t(TRANSLATIONS.SEARCH_LOCATION),
      }, ...locations.map((item: any) => ({ slug: item?.slug, label: item?.name }))]);
    };
    const fetchRoles = async () => {
      const res = await request.get('/api/v1/users-available-roles');
      const roles = res.roles?.filter((role: any) => role.category).map(
        (item: any) => ({ slug: item?.slug, label: item?.name, category: item?.category }));
      setAvailableRoles(roles);
      setDropDownItemsRoles([{
        slug: '',
        label: t(TRANSLATIONS.SEARCH),
      }, ...roles]);
    };

    fetchLocation();
    fetchRoles();
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setSelectedDropDownItem(dropDownItemsExtInt.find(
      (item: any) => item.slug === filters.userType,
    ));
    setSelectedLocation(dropDownItemsLocation.find(
      (item: any) => item.slug === filters.locationSlug,
    ));
    setSelectedDepartment(dropDownItemsDepartment.find(
      (item: any) => item.slug === filters.departmentSlug,
    ));
    setSelectedArea(dropDownItemsArea.find(
      (item: any) => item.slug === filters.areaSlug,
    ));
    setSelectedSubArea(dropDownItemsSubArea.find(
      (item: any) => item.slug === filters.subAreaSlug,
    ));
    setSelectedDropDownItemType(dropDownItemsRoles.find(
      (item: any) => item.slug === filters.roleSlug,
    ));
  // eslint-disable-next-line
  }, [filters]);

  return (
    <div className="user-filter">
      <div className="filter">
        <div className="label">{`${t(TRANSLATIONS.INTERNAL)}/${t(TRANSLATIONS.EXTERNAL)}`}</div>
        <ChartDropdown
          onChange={({ key }) => handleFilter('userType', key)}
          selectedDropDownItem={selectedDropDownItem}
          dropDownItems={dropDownItemsExtInt}
          dropDownOpen={dropDownOpenLogistic}
          setDropDownOpen={setDropDownOpenLogistic}
          labelClassName="label-default"
          isLoading={isLoading}
          isDisabled={showDeletedUsers}
        />
      </div>
      <div className="filter">
        <div className="label">{t(TRANSLATIONS.LOCATION)}</div>
        <ChartDropdown
          onChange={({ key }) => handleFilter('locationSlug', key)}
          selectedDropDownItem={selectedLocation}
          dropDownItems={dropDownItemsLocation}
          dropDownOpen={dropDownOpenLocation}
          setDropDownOpen={setDropDownOpenLocation}
          labelClassName="label-default"
          isLoading={isLoading}
          isDisabled={showDeletedUsers}
        />
      </div>
      <div className="filter">
        <div className="label">{isSelectedLocationFactory ? t(TRANSLATIONS.DEPARTMENT) : t(TRANSLATIONS.AREA)}</div>
        <Autocomplete
          id="autocomplete-dep"
          onChange={(event, newValue: any) => handleFilter('departmentSlug', newValue?.slug || '')}
          options={dropDownItemsDepartment}
          renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
              size="small"
              style={{ width: 180 }}
            />
          )}
          getOptionLabel={option => option.label}
          value={selectedDepartment}
          classes={classes}
          fullWidth
          disabled={!selectedLocation?.slug}
        />
      </div>
      <div className="filter">
        <div className="label">{isSelectedLocationFactory ? t(TRANSLATIONS.AREA) : t(TRANSLATIONS.SUBAREA)}</div>
        <Autocomplete
          id="autocomplete-area"
          onChange={(event, newValue: any) => handleFilter('areaSlug', newValue?.slug || '')}
          options={dropDownItemsArea}
          renderInput={params => <TextField {...params} variant="outlined" size="small" style={{ width: 180 }} />}
          getOptionLabel={option => option.label}
          value={selectedArea}
          classes={classes}
          fullWidth
          disabled={!selectedDepartment?.slug}
        />
      </div>
      {
        isSelectedLocationFactory
          ? (
            <div className="filter">
              <div className="label">{t(TRANSLATIONS.SUBAREA)}</div>
              <Autocomplete
                id="autocomplete-subarea"
                onChange={(event, newValue: any) => handleFilter('subAreaSlug', newValue?.slug || '')}
                options={dropDownItemsSubArea}
                renderInput={params => <TextField {...params} variant="outlined" size="small" style={{ width: 180 }} />}
                getOptionLabel={option => option.label}
                value={selectedSubArea}
                classes={classes}
                fullWidth
                disabled={!selectedArea?.slug}
              />
            </div>
          )
          : null
      }
      <div className="filter">
        <div className="label">{t(TRANSLATIONS.USER_ROLE_TRANSLATION)}</div>
        <ChartDropdown
          onChange={({ key }) => handleFilter('roleSlug', key)}
          selectedDropDownItem={selectedDropDownItemType}
          dropDownItems={dropDownItemsRoles}
          dropDownOpen={dropDownOpenType}
          setDropDownOpen={setDropDownOpenType}
          labelClassName="label-default"
          isLoading={isLoading}
          isDisabled={showDeletedUsers}
        />
      </div>
      <div className="reset" onClick={handleResetFilters} role="button" style={{ pointerEvents: isLoading ? 'none' : 'auto', opacity: isLoading ? '0.6' : '1' }}>
        <img src={refresh} alt="reset" />
        <span>{t(TRANSLATIONS.REMOVE_FILTERS)}</span>
      </div>
    </div>
  );
};

export default UserFilters;
