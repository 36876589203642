import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type RequireFields<T, K extends keyof T> = { [X in Exclude<keyof T, K>]?: T[X] } & { [P in K]-?: NonNullable<T[P]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
  Void: any;
};


export enum Action_Log_Origin {
  Owd = 'OWD',
  Routine = 'ROUTINE',
  FiveS = 'FIVE_S',
  ActionLog = 'ACTION_LOG',
  ThreeRs = 'THREE_RS',
  TeamRooms = 'TEAM_ROOMS',
  Workstation = 'WORKSTATION'
}

export enum Action_Log_Pillar_Image {
  Safety = 'SAFETY',
  Management = 'MANAGEMENT',
  Planning = 'PLANNING',
  Distribution = 'DISTRIBUTION',
  Fleet = 'FLEET',
  Stock = 'STOCK',
  People = 'PEOPLE',
  Warehouse = 'WAREHOUSE',
  Finanzas = 'FINANZAS',
  Comercial = 'COMERCIAL',
  Servicios = 'SERVICIOS'
}

export enum Action_Log_Status {
  Open = 'OPEN',
  InProgress = 'IN_PROGRESS',
  ForRevision = 'FOR_REVISION',
  Completed = 'COMPLETED'
}

export enum Attachment_Types {
  Image = 'IMAGE',
  Video = 'VIDEO',
  Pdf = 'PDF'
}

export type ActionLogFilters = {
  meeting?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<Maybe<Action_Log_Status>>>;
  KPIs?: Maybe<Array<Maybe<Scalars['String']>>>;
  pillars?: Maybe<Array<Maybe<Action_Log_Pillar_Image>>>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  involvedUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  dueDate?: Maybe<Scalars['Date']>;
  delayed?: Maybe<DelayedActionLogsInDcByStatus>;
};

export type ActionLogsInDcByStatus = {
  __typename?: 'ActionLogsInDCByStatus';
  OPEN: Array<Maybe<CreateEditActionLog>>;
  IN_PROGRESS: Array<Maybe<CreateEditActionLog>>;
  FOR_REVISION: Array<Maybe<CreateEditActionLog>>;
  COMPLETED: Array<Maybe<CreateEditActionLog>>;
};

export type AnalyticsFileUpload = {
  __typename?: 'AnalyticsFileUpload';
  id: Scalars['ID'];
  fileType: File_Types;
  fileS3Url: Scalars['String'];
  uploadedAt?: Maybe<Scalars['Date']>;
};

export type AnsweredCriticalRouteChecklist = {
  __typename?: 'AnsweredCriticalRouteChecklist';
  id: Scalars['ID'];
  content: Scalars['JSON'];
  answeredByUser: BaseUser;
  createdAt: Scalars['Date'];
};

export type AnsweredDpoAcademyChecklist = {
  __typename?: 'AnsweredDPOAcademyChecklist';
  score: Scalars['Float'];
  totalCorrect: Scalars['Int'];
  totalWrong: Scalars['Int'];
  attempt: Scalars['Int'];
  answeredAt: Scalars['Date'];
  dateOfAttemptsOfBackendSync: Array<Scalars['Date']>;
  checklistPassed?: Maybe<Scalars['Boolean']>;
};

export type AnsweredTeamRoomsChecklist = {
  __typename?: 'AnsweredTeamRoomsChecklist';
  id: Scalars['ID'];
  content: Scalars['JSON'];
  answeredByUser: BaseUser;
};

export type AnsweredThreeRsChecklist = {
  __typename?: 'AnsweredThreeRSChecklist';
  id: Scalars['ID'];
  content: Scalars['JSON'];
  answeredByUser: BaseUser;
};

export type AnsweredWorkstationChecklist = {
  __typename?: 'AnsweredWorkstationChecklist';
  id: Scalars['ID'];
  checklistId: Scalars['ID'];
  content: Scalars['JSON'];
  answeredByUser: BaseUser;
};

export type Attachment = {
  __typename?: 'Attachment';
  id: Scalars['ID'];
  url?: Maybe<Scalars['String']>;
  type?: Maybe<Attachment_Types>;
};

export type BaseLocation = {
  __typename?: 'BaseLocation';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  conutry: Scalars['String'];
};

export type BaseUser = {
  __typename?: 'BaseUser';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  shortSlug?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  pin?: Maybe<Scalars['String']>;
  blockedReason?: Maybe<Scalars['String']>;
  lastActiveAt?: Maybe<Scalars['Date']>;
  doctorStatus?: Maybe<Scalars['String']>;
  employeeNumber?: Maybe<Scalars['String']>;
};

export type BuData = {
  __typename?: 'BuData';
  usersToTrain: Scalars['Int'];
  usersTrained: Scalars['Int'];
  name: Scalars['String'];
  position: Scalars['String'];
  percentage: Scalars['String'];
};

export type BusinessUnit = {
  __typename?: 'BusinessUnit';
  id: Scalars['ID'];
  slug?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  businessUnitId?: Maybe<Scalars['Int']>;
  zones?: Maybe<Array<Maybe<Zone>>>;
};

export type BusinessUnitWithActionLogCount = {
  __typename?: 'BusinessUnitWithActionLogCount';
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  actionLogsCount?: Maybe<Scalars['Int']>;
  zones?: Maybe<Array<Maybe<ZoneWithActionLogCount>>>;
};

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}

export type CanAccessTeamRooms = {
  __typename?: 'CanAccessTeamRooms';
  canAccess: Scalars['Boolean'];
  userCategory?: Maybe<Scalars['String']>;
};

export type ChecklistAvailable = {
  __typename?: 'ChecklistAvailable';
  id: Scalars['ID'];
  name: Scalars['String'];
  content: Scalars['JSON'];
};

export type ChecklistInfo = {
  __typename?: 'ChecklistInfo';
  bu?: Maybe<BusinessUnit>;
  department?: Maybe<Department>;
  externalCompany?: Maybe<ExternalCompany>;
  workingDepartment?: Maybe<Department>;
  workingSubDepartment?: Maybe<SubDepartment>;
  areaManager?: Maybe<BaseUser>;
  performer?: Maybe<BaseUser>;
  territoryOwner?: Maybe<BaseUser>;
  inspector?: Maybe<BaseUser>;
  gasTests?: Maybe<Array<Maybe<GetTest>>>;
  status?: Maybe<Scalars['String']>;
  statusReasonContent?: Maybe<StatusReasonContent>;
  userUnderEvaluation?: Maybe<BaseUser>;
  progressPercentage: Scalars['Int'];
  isCompleted?: Maybe<Scalars['Boolean']>;
};

export type ChecklistMaterial = {
  __typename?: 'ChecklistMaterial';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  driveLink: Scalars['String'];
  material: Scalars['JSON'];
};

export enum ChecklistType {
  Pdt = 'PDT',
  Skap = 'SKAP',
  Owd = 'OWD',
  /** If you wana use it, convert fiveS to "5S" before */
  FiveS = 'fiveS',
  ThreeRs = 'ThreeRS',
  TeamRooms = 'TEAM_ROOMS',
  Workstation = 'WORKSTATION',
  RouteSafety = 'ROUTE_SAFETY'
}

export enum ClientType {
  App = 'APP',
  Web = 'WEB'
}

export type Cluster = {
  __typename?: 'Cluster';
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type Comment = {
  __typename?: 'Comment';
  id: Scalars['ID'];
  comment: Scalars['String'];
  user: BaseUser;
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

/** SKAP web: count by autonomous sub-levels */
export type CountByAutonomousLevel = {
  __typename?: 'CountByAutonomousLevel';
  title: Scalars['String'];
  count: Scalars['Int'];
};

/** SKAP web: count by level i.e funcional, technical, autonomous */
export type CountByLevels = {
  __typename?: 'CountByLevels';
  slug: Scalars['String'];
  count: Scalars['Int'];
  percentage: Scalars['Int'];
  total: Scalars['Int'];
};

/** SKAP web: count for each level grouped by position */
export type CountByPosition = {
  __typename?: 'CountByPosition';
  slug: Scalars['String'];
  total: Scalars['Int'];
  impactedCount: Scalars['Int'];
  data: Array<Maybe<ModulePercentage>>;
};

export type CountryAndLocationList = {
  __typename?: 'CountryAndLocationList';
  country?: Maybe<Scalars['String']>;
  locations?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CovidTest = {
  __typename?: 'CovidTest';
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  givenAt?: Maybe<Scalars['Date']>;
  testImage?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['Boolean']>;
  resultAt?: Maybe<Scalars['Date']>;
  approvedBy?: Maybe<Scalars['Int']>;
  needsApproval?: Maybe<Scalars['Boolean']>;
};

export type CovidUser = {
  __typename?: 'CovidUser';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  isAlreadyDiagnosed?: Maybe<Scalars['Boolean']>;
  covidTest?: Maybe<CovidTest>;
};

export type CovidUserAndCount = {
  __typename?: 'CovidUserAndCount';
  count?: Maybe<Scalars['Int']>;
  covidUserList?: Maybe<Array<Maybe<CovidUser>>>;
};

export type CovidUsersForLocation = {
  __typename?: 'CovidUsersForLocation';
  name: Scalars['String'];
  slug: Scalars['String'];
  employeeNumber?: Maybe<Scalars['String']>;
  quarantineUntil?: Maybe<Scalars['Date']>;
  doctorStatus?: Maybe<Scalars['String']>;
  location?: Maybe<LocationName>;
  latestCovidTest?: Maybe<LatestCovidTest>;
};

export type CovidUsersForLocationCount = {
  __typename?: 'CovidUsersForLocationCount';
  count: Scalars['Int'];
  covidUsersForLocation?: Maybe<Array<Maybe<CovidUsersForLocation>>>;
};

export type CreateEditActionLog = {
  __typename?: 'CreateEditActionLog';
  id: Scalars['ID'];
  startDate: Scalars['Date'];
  description: Scalars['String'];
  KPIs: Scalars['String'];
  KPI: Kpi;
  endDate: Scalars['Date'];
  status: Action_Log_Status;
  pillar: Action_Log_Pillar_Image;
  owner: BaseUser;
  location: BaseLocation;
  responsibles: Array<BaseUser>;
  origin?: Maybe<Action_Log_Origin>;
  meeting: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  images: Array<Maybe<ImageInputItemType>>;
  statusUpdateDate?: Maybe<Scalars['Date']>;
  routinesAvailable?: Maybe<RoutinesAvailableNameAndSlug>;
  originText?: Maybe<Scalars['String']>;
  numberOfComments?: Maybe<Scalars['Int']>;
  ManagementTool?: Maybe<ManagementTool>;
  checklistsAvailableId?: Maybe<Scalars['ID']>;
  checklistId?: Maybe<Scalars['ID']>;
};

export type CreateEditActionLogPayload = {
  id?: Maybe<Scalars['ID']>;
  startDate: Scalars['Date'];
  description: Scalars['String'];
  KPIs: Scalars['String'];
  endDate: Scalars['Date'];
  status: Action_Log_Status;
  pillar: Action_Log_Pillar_Image;
  owner?: Maybe<Scalars['String']>;
  meeting: Scalars['String'];
  location: Scalars['String'];
  responsibles: Array<Scalars['String']>;
  origin?: Maybe<Action_Log_Origin>;
  note?: Maybe<Scalars['String']>;
  images: Array<Maybe<ImageInputItem>>;
  routinesAvailableSlug?: Maybe<Scalars['String']>;
  managementTool?: Maybe<Scalars['ID']>;
  checklistsAvailableId?: Maybe<Scalars['ID']>;
  checklistId?: Maybe<Scalars['ID']>;
};

export type CreateEditActionLogResponse = {
  __typename?: 'CreateEditActionLogResponse';
  isSuccess: Scalars['Boolean'];
  id: Scalars['ID'];
};

export type CriticalRouteChecklist = {
  __typename?: 'CriticalRouteChecklist';
  id: Scalars['ID'];
  name: Scalars['String'];
  content: Scalars['JSON'];
  answers: Array<Maybe<AnsweredCriticalRouteChecklist>>;
};

export type CrownChecklistMaterialsResponse = {
  __typename?: 'CrownChecklistMaterialsResponse';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  driveLink: Scalars['String'];
  program: Scalars['String'];
  checklistMaterials: Array<Maybe<ChecklistMaterial>>;
};

export type CrownChecklistsResponse = {
  __typename?: 'CrownChecklistsResponse';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  driveLink: Scalars['String'];
  program: Scalars['String'];
  checklists: Array<DpoAcademyChecklist>;
};

export type DpoAcademyChecklist = {
  __typename?: 'DPOAcademyChecklist';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  content: Scalars['JSON'];
  material: Scalars['JSON'];
  driveLink: Scalars['String'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  response?: Maybe<AnsweredDpoAcademyChecklist>;
};

export enum DpoAcademyPrograms {
  Warehouse = 'Warehouse',
  Delivery = 'Delivery'
}


export type DateRangeInput = {
  startDateTime: Scalars['Date'];
  endDateTime: Scalars['Date'];
};

export type DelayedActionLogsInDcByStatus = {
  isDelayed?: Maybe<Scalars['Boolean']>;
  currentDate: Scalars['Date'];
};

export type Department = {
  __typename?: 'Department';
  id: Scalars['ID'];
  name: Scalars['String'];
  nameEN: Scalars['String'];
  acronymEN: Scalars['String'];
  acronymES: Scalars['String'];
  slug: Scalars['String'];
  subDepartments: Array<Maybe<SubDepartment>>;
};

export type EmergencyContact = {
  __typename?: 'EmergencyContact';
  name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type ExternalCompany = {
  __typename?: 'ExternalCompany';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export enum File_Types {
  Headcount = 'HEADCOUNT',
  Accidentability = 'ACCIDENTABILITY',
  Cashless = 'CASHLESS',
  Capex = 'CAPEX',
  Telemetria = 'TELEMETRIA',
  ViolencePrevention = 'VIOLENCE_PREVENTION',
  RankingSafety = 'RANKING_SAFETY'
}

export type FailedCertificate = {
  __typename?: 'FailedCertificate';
  answers?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type Forklift = {
  __typename?: 'Forklift';
  id?: Maybe<Scalars['ID']>;
  externalId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  forkliftArea?: Maybe<ForkliftArea>;
  sapNumber?: Maybe<Scalars['String']>;
};

export type ForkliftArea = {
  __typename?: 'ForkliftArea';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  totalForklifts?: Maybe<Scalars['Int']>;
};

export type ForkliftRoutine = {
  __typename?: 'ForkliftRoutine';
  id: Scalars['ID'];
  approvedAt?: Maybe<Scalars['Date']>;
  approvedBy?: Maybe<User>;
  createdAt?: Maybe<Scalars['Date']>;
  forklift?: Maybe<Forklift>;
  routine?: Maybe<Routine>;
  isCompliant?: Maybe<Scalars['Boolean']>;
  isApproved?: Maybe<Scalars['Boolean']>;
};

export enum General_User_Roles {
  Doctor = 'doctor',
  CertificatesOnly = 'certificates_only',
  LocationSecurity = 'location_security'
}

export type GkpiDataResponse = {
  __typename?: 'GKPIDataResponse';
  GKPI: Scalars['String'];
  data: Scalars['JSON'];
};

export type GkpiExcelProcessingStatus = {
  __typename?: 'GKPIExcelProcessingStatus';
  status: GkpiFileProcessingStatus;
  message?: Maybe<Scalars['String']>;
};

export enum GkpiFileProcessingStatus {
  Finished = 'FINISHED',
  Failed = 'FAILED'
}

export type GkpiItemContent = {
  __typename?: 'GKPIItemContent';
  AC?: Maybe<Scalars['Float']>;
  BU?: Maybe<Scalars['Float']>;
  LY?: Maybe<Scalars['Float']>;
  AC_VS_LY?: Maybe<Scalars['Float']>;
};

export type GetAll5SChecklistCountByStatus = {
  __typename?: 'GetAll5SChecklistCountByStatus';
  count: Scalars['Int'];
};

export type GetAllOwdChecklists = {
  __typename?: 'GetAllOWDChecklists';
  id: Scalars['ID'];
  name: Scalars['String'];
  content: Scalars['JSON'];
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type GetModulesAndSubmodulesCountResponse = {
  __typename?: 'GetModulesAndSubmodulesCountResponse';
  countByLevels: Array<Maybe<CountByLevels>>;
  countByAutonomousLevel: Array<Maybe<CountByAutonomousLevel>>;
  countByPosition: Array<Maybe<CountByPosition>>;
  countByMonth: Array<Maybe<MonthWiseScore>>;
};

export type GetPdtAccessResponse = {
  __typename?: 'GetPDTAccessResponse';
  canAccessPDT?: Maybe<Scalars['Boolean']>;
};

export enum GetPdtCreationType {
  Today = 'TODAY',
  Yesterday = 'YESTERDAY',
  Any = 'ANY'
}

export type GetPostResponse = {
  __typename?: 'GetPostResponse';
  posts: Array<Maybe<Post>>;
  hasMore: Scalars['Boolean'];
};

export enum GetSifStatsBy {
  Month = 'month',
  Weekday = 'weekday',
  Location = 'location',
  Classification = 'classification',
  Cause = 'cause',
  Nature = 'nature'
}

export type GetTest = {
  __typename?: 'GetTest';
  id: Scalars['ID'];
  content: Scalars['JSON'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export enum GetTrainingsBy {
  Position = 'position',
  Bu = 'bu'
}

export type GetTrainingsPayload = {
  bu?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  zone?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
};

export type GetTruckDriversResponse = {
  __typename?: 'GetTruckDriversResponse';
  user: User;
  status?: Maybe<Scalars['String']>;
  routeId?: Maybe<Scalars['ID']>;
  externalRouteId?: Maybe<Scalars['ID']>;
};

export type HealthStatus = {
  __typename?: 'HealthStatus';
  location?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
};

export type ImageInputItem = {
  id: Scalars['String'];
  url: Scalars['String'];
};

export type ImageInputItemType = {
  __typename?: 'ImageInputItemType';
  id: Scalars['String'];
  url: Scalars['String'];
};

export type ImpactedQuestionsResponse = {
  __typename?: 'ImpactedQuestionsResponse';
  sectionIndex: Scalars['Int'];
  questionIndex: Scalars['Int'];
  value: Scalars['Boolean'];
};

export type Incident = {
  __typename?: 'Incident';
  id: Scalars['ID'];
  typeOfIssue?: Maybe<Scalars['String']>;
  isUnsafe?: Maybe<Scalars['Boolean']>;
  doneByUserId?: Maybe<Scalars['ID']>;
  supervisorId?: Maybe<Scalars['ID']>;
  workLocationId?: Maybe<Scalars['ID']>;
  riskTypeId?: Maybe<Scalars['ID']>;
  riskLevel?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  possibleSolution?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  deletedAt?: Maybe<Scalars['Date']>;
  locationDescription?: Maybe<Scalars['String']>;
  doneByString?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  submittedByString?: Maybe<Scalars['String']>;
  submittedByUserId?: Maybe<Scalars['ID']>;
  closedBy?: Maybe<Scalars['ID']>;
  closedAt?: Maybe<Scalars['Date']>;
  source?: Maybe<Scalars['String']>;
  riskTypeDescription?: Maybe<Scalars['String']>;
  chosenState?: Maybe<Scalars['String']>;
  chosenCity?: Maybe<Scalars['String']>;
  outsideDistCenterIncidentType?: Maybe<Scalars['String']>;
  pointOfSaleName?: Maybe<Scalars['String']>;
  posDescription?: Maybe<Scalars['String']>;
  isExternalGuilty?: Maybe<Scalars['Boolean']>;
  vehicleIdentification?: Maybe<Scalars['String']>;
  incidentDateTime?: Maybe<Scalars['Date']>;
  truckId?: Maybe<Scalars['ID']>;
  assignedTo?: Maybe<Scalars['ID']>;
  locationType?: Maybe<Scalars['String']>;
  subTypeOfIssue?: Maybe<Scalars['String']>;
  closingImage?: Maybe<Scalars['String']>;
  seenAt?: Maybe<Scalars['Date']>;
  assignedByUserId?: Maybe<Scalars['ID']>;
  assignedAt?: Maybe<Scalars['ID']>;
  subLocationId?: Maybe<Scalars['ID']>;
  postponedTill?: Maybe<Scalars['Date']>;
  locationId?: Maybe<Scalars['ID']>;
  isSIF?: Maybe<Scalars['Boolean']>;
  category?: Maybe<Scalars['String']>;
  SAPControlNumber?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['String']>;
  specificSubLocationId?: Maybe<Scalars['ID']>;
  lastActivityAt?: Maybe<Scalars['Date']>;
  returnCause?: Maybe<Scalars['String']>;
  lastActivityWas?: Maybe<Scalars['String']>;
  evidenceClosureText?: Maybe<Scalars['String']>;
  returnedBy?: Maybe<Scalars['ID']>;
  folioNumber?: Maybe<Scalars['String']>;
  causeId?: Maybe<Scalars['ID']>;
  immediateCauseId?: Maybe<Scalars['ID']>;
};

export type IncidentBetweenRange = {
  __typename?: 'IncidentBetweenRange';
  bu: Scalars['String'];
  month: Scalars['String'];
  weeks: Array<Maybe<WeekContent>>;
};

export type IncidentByCategory = {
  __typename?: 'IncidentByCategory';
  categoryName: Scalars['String'];
  bus: Array<Maybe<BuContent>>;
};

export type IncidentByMonth = {
  __typename?: 'IncidentByMonth';
  bu: Scalars['String'];
  months: Array<Maybe<BuContent>>;
};

export type IncidentByType = {
  __typename?: 'IncidentByType';
  bu: Scalars['String'];
  name: Scalars['String'];
  totalIncidents: Scalars['Int'];
};

export type InputFiltersFor5S = {
  areaIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  subAreaIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  userIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  typeOfChecklist?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Kpi = {
  __typename?: 'KPI';
  id: Scalars['ID'];
  slug: Scalars['String'];
  isGlobal: Scalars['Boolean'];
  enTitle: Scalars['String'];
  esTitle: Scalars['String'];
};

export enum Location_Type {
  DistributionCenter = 'DISTRIBUTION_CENTER',
  Factory = 'FACTORY'
}

export enum Logistics_User_Roles {
  ReporterOnlyDpo = 'reporter_only_dpo',
  DistributionCenterManagerDpo = 'distribution_center_manager_dpo',
  SubzoneGeneralManagerDpo = 'subzone_general_manager_dpo',
  CorporateDpo = 'corporate_dpo',
  DoctorReporterOnlyDpo = 'doctor_reporter_only_dpo'
}

export type LatestChecklistAnsweredAt = {
  __typename?: 'LatestChecklistAnsweredAt';
  at?: Maybe<Scalars['Date']>;
};

export type LatestCovidTest = {
  __typename?: 'LatestCovidTest';
  type: Scalars['String'];
};

export type ListSifStatsResponse = {
  __typename?: 'ListSifStatsResponse';
  reference?: Maybe<Scalars['String']>;
  classificationOfInjury?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  incidentCause?: Maybe<Scalars['String']>;
  nature?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  incidentDate?: Maybe<Scalars['Date']>;
  shiftType?: Maybe<Scalars['String']>;
  incidentTime?: Maybe<Scalars['String']>;
};

export type Location = {
  __typename?: 'Location';
  name?: Maybe<Scalars['String']>;
};

export type LocationDetails = {
  __typename?: 'LocationDetails';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  subzone: Subzone;
  lat?: Maybe<Scalars['Int']>;
  long?: Maybe<Scalars['Int']>;
  country?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  bu?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['String']>;
  countryEs?: Maybe<Scalars['String']>;
  hasIncidentEnabled?: Maybe<Scalars['Boolean']>;
  emergencyContanct?: Maybe<Scalars['String']>;
  type?: Maybe<Location_Type>;
  riskLevel?: Maybe<Risk_Level>;
};

export type LocationInfoByChecklistType = {
  __typename?: 'LocationInfoByChecklistType';
  id: Scalars['ID'];
  checklistsToShow?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type LocationName = {
  __typename?: 'LocationName';
  name: Scalars['String'];
};

export type LocationSchema = {
  __typename?: 'LocationSchema';
  lat: Scalars['Float'];
  long: Scalars['Float'];
};

export type LocationWithActionLogCount = {
  __typename?: 'LocationWithActionLogCount';
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  cluster?: Maybe<Cluster>;
  actionLogsCount?: Maybe<Scalars['Int']>;
};

export type ManagementTool = {
  __typename?: 'ManagementTool';
  id: Scalars['ID'];
  slug: Scalars['String'];
  enTitle: Scalars['String'];
  esTitle: Scalars['String'];
};

/** SKAP web: average % for each level */
export type ModulePercentage = {
  __typename?: 'ModulePercentage';
  title: Scalars['String'];
  percentage: Scalars['Int'];
};

/** SKAP web: ramp up score for each month */
export type MonthWiseScore = {
  __typename?: 'MonthWiseScore';
  month: Scalars['String'];
  score: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  _?: Maybe<Scalars['String']>;
  updateUserDoctorStatus: MutationStatus;
  addUserHealthStatus: MutationStatus;
  addUserHealthStatusUnAuthenticated: MutationStatus;
  updateUserInfo: MutationStatus;
  updateForkliftIsBlocked: MutationStatus;
  updateForkliftApproval: MutationStatus;
  userTestDetails: MutationStatus;
  createCovidTest: MutationStatus;
  approveTest: MutationStatus;
  giveTestApproval: MutationStatus;
  setAsEmergencyContact: MutationStatus;
  addVaccinationInformation: MutationStatus;
  updateVaccinationInformation: MutationStatus;
  setVaccineApproval: MutationStatus;
  removeVaccination: MutationStatus;
  createEditActionLog: CreateEditActionLogResponse;
  updateActionLogStatus: MutationStatus;
  deleteActionLog: MutationStatus;
  addOrEditComment: MutationStatus;
  deleteComment: MutationStatus;
  createSignedS3UrlForGKPIUpload: S3SignedUrl;
  startProcessingGKPIFile: GkpiExcelProcessingStatus;
  updateSeenAt: MutationStatus;
  sendNotification: MutationStatus;
  createNote?: Maybe<Scalars['Boolean']>;
  addOrEditCapexComment: MutationStatus;
  deleteCapexComment: MutationStatus;
  updateUserAccessLocation: MutationStatus;
  updateWorkLocations: ResponseUpdateWorkLocations;
  saveSKAPChecklist: MutationStatus;
  savePDTChecklist: MutationStatus;
  createPDTChecklist: Scalars['ID'];
  addOrEditCommentOnChecklist: MutationStatus;
  addSignatureToChecklist: MutationStatus;
  deleteSignatureFromChecklist: MutationStatus;
  updateOperatorOfChecklist: MutationStatus;
  deleteOperatorsOfChecklist: MutationStatus;
  updateStatusOfChecklist: MutationStatus;
  updateSKAPChecklist: MutationStatus;
  save5SChecklist: MutationStatus;
  savePDTSupportCertificate: MutationStatus;
  saveOWDChecklist: MutationStatus;
  saveDPOAcademyChecklistAnswer: CorruptedPayloadIndexes;
  discardCreatedActionLogsOfUncreatedOWD: MutationStatus;
  save3RSChecklistAnswer: Save3RsChecklistAnswerResponse;
  saveTeamRoomsChecklistAnswer: SaveTeamRoomsChecklistAnswerResponse;
  saveWorkstationChecklistAnswer: SaveWorkstationChecklistAnswerResponse;
  delete5SChecklists: MutationStatus;
  update5SChecklists: MutationStatus;
  addOrEditGasTest: MutationStatus;
  addUpdateLocationInfoByChecklistType: MutationStatus;
  saveRouteSafetyChecklistAnswer: MutationStatus;
  deleteRouteSafetyTruckDriver?: Maybe<MutationStatus>;
  assignTruckDriver: MutationStatus;
  assignTruck: MutationStatus;
  changeSecondDepartureStartedAtInPlannedRouteById: MutationStatus;
};


export type MutationUpdateUserDoctorStatusArgs = {
  daysToQuarantine?: Maybe<Scalars['Date']>;
  slug: Scalars['String'];
  doctorStatus: Scalars['String'];
};


export type MutationAddUserHealthStatusArgs = {
  status: Scalars['String'];
  location: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
};


export type MutationAddUserHealthStatusUnAuthenticatedArgs = {
  status: Scalars['String'];
  location: Scalars['String'];
  slug: Scalars['String'];
};


export type MutationUpdateUserInfoArgs = {
  id: Scalars['ID'];
  updatedByUserId: Scalars['ID'];
  general?: Maybe<UsersGeneralInfoPayload>;
  permission?: Maybe<PermissionInfoPayload>;
  roleId?: Maybe<Scalars['ID']>;
  userInfo?: Maybe<UserInfoPayload>;
};


export type MutationUpdateForkliftIsBlockedArgs = {
  slug: Scalars['String'];
  isBlocked: Scalars['Boolean'];
};


export type MutationUpdateForkliftApprovalArgs = {
  forkliftRoutineId: Scalars['Int'];
};


export type MutationUserTestDetailsArgs = {
  slug: Scalars['String'];
  id: Scalars['Int'];
  result?: Maybe<Scalars['Boolean']>;
  reportNumber?: Maybe<Scalars['String']>;
  sendAlert?: Maybe<Scalars['Boolean']>;
  sendReport?: Maybe<Scalars['Boolean']>;
  resultAt?: Maybe<Scalars['Date']>;
  type?: Maybe<Scalars['String']>;
};


export type MutationCreateCovidTestArgs = {
  slug: Scalars['String'];
  testName: Scalars['String'];
  resultAt?: Maybe<Scalars['Date']>;
  testImage?: Maybe<Scalars['String']>;
  needsApproval?: Maybe<Scalars['Boolean']>;
  result?: Maybe<Scalars['Boolean']>;
};


export type MutationApproveTestArgs = {
  id: Scalars['Int'];
  resultAt: Scalars['Date'];
};


export type MutationGiveTestApprovalArgs = {
  id: Scalars['ID'];
  rejectReason?: Maybe<Scalars['String']>;
};


export type MutationSetAsEmergencyContactArgs = {
  contactId: Scalars['ID'];
};


export type MutationAddVaccinationInformationArgs = {
  slug?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  givenAt?: Maybe<Scalars['Date']>;
  image?: Maybe<Scalars['String']>;
  approvedAt?: Maybe<Scalars['Date']>;
  isApproved?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateVaccinationInformationArgs = {
  id: Scalars['ID'];
  givenAt: Scalars['Date'];
  approvedAt: Scalars['Date'];
};


export type MutationSetVaccineApprovalArgs = {
  id: Scalars['ID'];
  givenAt: Scalars['Date'];
  approvedAt?: Maybe<Scalars['Date']>;
  isApproved?: Maybe<Scalars['Boolean']>;
};


export type MutationRemoveVaccinationArgs = {
  id: Scalars['ID'];
};


export type MutationCreateEditActionLogArgs = {
  payload?: Maybe<CreateEditActionLogPayload>;
};


export type MutationUpdateActionLogStatusArgs = {
  actionLogId: Scalars['Int'];
  status: Action_Log_Status;
  isOwnerCheckSurpassed?: Maybe<Scalars['Boolean']>;
};


export type MutationDeleteActionLogArgs = {
  actionLogId: Scalars['Int'];
};


export type MutationAddOrEditCommentArgs = {
  actionLogId: Scalars['ID'];
  comment?: Maybe<Scalars['String']>;
  commentId?: Maybe<Scalars['ID']>;
};


export type MutationDeleteCommentArgs = {
  commentId: Scalars['ID'];
};


export type MutationStartProcessingGkpiFileArgs = {
  fileName: Scalars['String'];
};


export type MutationUpdateSeenAtArgs = {
  ids: Array<Maybe<Scalars['ID']>>;
};


export type MutationSendNotificationArgs = {
  type: Scalars['String'];
};


export type MutationCreateNoteArgs = {
  input: NoteInput;
};


export type MutationAddOrEditCapexCommentArgs = {
  capexId: Scalars['ID'];
  comment: Scalars['String'];
  commentId?: Maybe<Scalars['ID']>;
};


export type MutationDeleteCapexCommentArgs = {
  commentId: Scalars['ID'];
};


export type MutationUpdateUserAccessLocationArgs = {
  userId?: Maybe<Scalars['String']>;
  locationIds: Array<Maybe<Scalars['Int']>>;
};


export type MutationUpdateWorkLocationsArgs = {
  payload: UpdateWorkLocationsPayload;
};


export type MutationSaveSkapChecklistArgs = {
  args: SaveSkapChecklistInput;
};


export type MutationSavePdtChecklistArgs = {
  args: SavePdtChecklistInput;
};


export type MutationCreatePdtChecklistArgs = {
  args?: Maybe<CreatePdtChecklistInput>;
};


export type MutationAddOrEditCommentOnChecklistArgs = {
  args: AddOrEditCommentOnChecklistInput;
};


export type MutationAddSignatureToChecklistArgs = {
  args: AddSignatureToChecklistInput;
};


export type MutationDeleteSignatureFromChecklistArgs = {
  args: DeleteSignatureFromChecklistInput;
};


export type MutationUpdateOperatorOfChecklistArgs = {
  args: UpdateOperatorOfChecklistInput;
};


export type MutationDeleteOperatorsOfChecklistArgs = {
  args: DeleteOperatorsOfChecklistInput;
};


export type MutationUpdateStatusOfChecklistArgs = {
  args: UpdateStatusOfChecklistInput;
};


export type MutationUpdateSkapChecklistArgs = {
  args: UpdateSkapChecklistInput;
};


export type MutationSave5SChecklistArgs = {
  args: Save5SChecklistInput;
};


export type MutationSavePdtSupportCertificateArgs = {
  args?: Maybe<SavePdtSupportCertificateInput>;
};


export type MutationSaveOwdChecklistArgs = {
  args?: Maybe<SaveOwdChecklistInput>;
};


export type MutationSaveDpoAcademyChecklistAnswerArgs = {
  args: SaveBulkDpoAcademyChecklistAnswerInput;
};


export type MutationDiscardCreatedActionLogsOfUncreatedOwdArgs = {
  args?: Maybe<DiscardCreatedActionLogsOfUncreatedOwdInput>;
};


export type MutationSave3RsChecklistAnswerArgs = {
  args: SaveBulk3RsChecklistAnswerInput;
};


export type MutationSaveTeamRoomsChecklistAnswerArgs = {
  args: SaveBulkTeamRoomsChecklistAnswerInput;
};


export type MutationSaveWorkstationChecklistAnswerArgs = {
  args: SaveBulkWorkstationChecklistAnswerInput;
};


export type MutationDelete5SChecklistsArgs = {
  args: Delete5SChecklistsInput;
};


export type MutationUpdate5SChecklistsArgs = {
  args: UserChecklistInput;
};


export type MutationAddOrEditGasTestArgs = {
  args: AddOrEditGasTestInput;
};


export type MutationAddUpdateLocationInfoByChecklistTypeArgs = {
  locationId: Scalars['ID'];
  checklistType?: Maybe<ChecklistType>;
  data: Scalars['JSON'];
};


export type MutationSaveRouteSafetyChecklistAnswerArgs = {
  args: SaveRouteSafetyChecklistAnswerInput;
};


export type MutationDeleteRouteSafetyTruckDriverArgs = {
  driverUserId: Scalars['ID'];
};


export type MutationAssignTruckDriverArgs = {
  plannedRouteId: Scalars['ID'];
  primaryDriverId?: Maybe<Scalars['ID']>;
  secondaryDriverId?: Maybe<Scalars['ID']>;
};


export type MutationAssignTruckArgs = {
  plannedRouteId: Scalars['ID'];
  truckId?: Maybe<Scalars['ID']>;
};


export type MutationChangeSecondDepartureStartedAtInPlannedRouteByIdArgs = {
  plannedRouteId: Scalars['ID'];
  secondDepartureStartedAt: Scalars['Date'];
};

export type MutationStatus = {
  __typename?: 'MutationStatus';
  isSuccess: Scalars['Boolean'];
};

export type Note = {
  __typename?: 'Note';
  id: Scalars['ID'];
  bu: Scalars['String'];
  content: Scalars['String'];
  updatedAt: Scalars['Date'];
  createdAt: Scalars['Date'];
  user?: Maybe<UserNote>;
};

export type NoteInput = {
  bu?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type Notification = {
  __typename?: 'Notification';
  id: Scalars['ID'];
  type: Scalars['String'];
  userId: Scalars['Int'];
  time: Scalars['Date'];
  data: Scalars['JSON'];
  seenAt?: Maybe<Scalars['Date']>;
};

export type NumberOfContacts = {
  __typename?: 'NumberOfContacts';
  count: Scalars['Int'];
};

export type OperatorSignature = {
  __typename?: 'OperatorSignature';
  id: Scalars['ID'];
  signedUser: BaseUser;
  signature: Scalars['String'];
};

export enum OrderingFashion {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type PdtChecklist = {
  __typename?: 'PDTChecklist';
  id: Scalars['ID'];
  checklistName: Scalars['String'];
  checklistCreationDate: Scalars['Date'];
  content: Scalars['JSON'];
  operators?: Maybe<Array<Maybe<BaseUser>>>;
  checklistSignatures?: Maybe<Array<Maybe<OperatorSignature>>>;
  checklistInfo: ChecklistInfo;
};

export type PdtSupportCertificate = {
  __typename?: 'PDTSupportCertificate';
  id: Scalars['ID'];
  questions?: Maybe<Scalars['JSON']>;
  answers?: Maybe<Scalars['JSON']>;
};

export enum Posts_Types {
  News = 'NEWS',
  BestPractices = 'BEST_PRACTICES',
  PreventionCampaigns = 'PREVENTION_CAMPAIGNS'
}

export type PerformingBody = {
  __typename?: 'PerformingBody';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  nameEn?: Maybe<Scalars['String']>;
};

export type PermissionInfoPayload = {
  routines?: Maybe<Scalars['JSON']>;
  type?: Maybe<Scalars['String']>;
  roleSlug?: Maybe<Scalars['String']>;
  autoAssignSios?: Maybe<Scalars['Boolean']>;
};

export type Pillar = {
  __typename?: 'Pillar';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type PlannedRoute = {
  __typename?: 'PlannedRoute';
  id: Scalars['ID'];
  route?: Maybe<Route>;
  truck?: Maybe<Truck>;
  primaryTruckDriver?: Maybe<User>;
  secondaryTruckDriver?: Maybe<User>;
  plannedTruckDriver?: Maybe<User>;
  createdAt?: Maybe<Scalars['Date']>;
  secondDepartureStartedAt?: Maybe<Scalars['Date']>;
  executionDate?: Maybe<Scalars['Date']>;
};

export type PlannedRouteWithAnswer = {
  __typename?: 'PlannedRouteWithAnswer';
  plannedRoute?: Maybe<PlannedRoute>;
  answers?: Maybe<Scalars['JSON']>;
};

export type Post = {
  __typename?: 'Post';
  id: Scalars['ID'];
  title: Scalars['String'];
  subtitle?: Maybe<Scalars['String']>;
  bannerImage?: Maybe<Scalars['String']>;
  content: Scalars['String'];
  author?: Maybe<Scalars['String']>;
  postedAt: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  type?: Maybe<Posts_Types>;
  attachments?: Maybe<Array<Maybe<Attachment>>>;
};

export type QuarantineeInformation = {
  __typename?: 'QuarantineeInformation';
  emergencyContact?: Maybe<EmergencyContact>;
  numberOfContacts?: Maybe<NumberOfContacts>;
};

export type Query = {
  __typename?: 'Query';
  _?: Maybe<Scalars['String']>;
  users?: Maybe<Array<User>>;
  getUserInfo?: Maybe<User>;
  userPositionsByBu?: Maybe<Array<Maybe<UserPositions>>>;
  getUsersList: GetUsersListPaginated;
  quarantineeInformation: QuarantineeInformation;
  getCovidUsersForLocations: CovidUsersForLocationCount;
  userHealthStatus: UserHealthStatus;
  userHealthStatusUnAuthenticated: UserHealthStatus;
  searchCovidUsers: Array<Maybe<CovidUsersForLocation>>;
  searchUsersWithAccessToLocation?: Maybe<Array<Maybe<BaseUser>>>;
  getUserPositionsByBu: Array<Maybe<UserPositionsByBu>>;
  getUserUsualWorkLocation?: Maybe<UsualWorkLocation>;
  forklift?: Maybe<Forklift>;
  forkliftRoutine?: Maybe<Array<Maybe<ForkliftRoutine>>>;
  getAllForklifts: Array<Maybe<Forklift>>;
  forkliftAreasForChecklist?: Maybe<Array<Maybe<ForkliftArea>>>;
  covidUsers: CovidUserAndCount;
  userCovidTestTimeline: TestTimeline;
  emergencyContact?: Maybe<EmergencyContact>;
  userVaccines: Array<Maybe<UserVaccine>>;
  vaccinesForUserLocation: UserVaccinesForLocation;
  vaccinesPendingApproval?: Maybe<UserVaccine>;
  percentageOfVaccinatedUsers?: Maybe<UsersVaccinatedCount>;
  getUsersVaccineForType?: Maybe<Array<Maybe<User>>>;
  actionLogs: Array<Maybe<CreateEditActionLog>>;
  actionLog?: Maybe<CreateEditActionLog>;
  actionLogsCountGroupByLocation: Array<Maybe<BusinessUnitWithActionLogCount>>;
  groupActionLogsInDCByStatus: ActionLogsInDcByStatus;
  comments: Array<Maybe<Comment>>;
  comment: Comment;
  location: LocationSchema;
  getLocations: Array<Maybe<LocationDetails>>;
  locationDetails: LocationDetails;
  userHasAccessToLocation: Scalars['Boolean'];
  getPost?: Maybe<Post>;
  getPosts?: Maybe<GetPostResponse>;
  getUploadedFiles?: Maybe<Array<Maybe<AnalyticsFileUpload>>>;
  getLastUploadedFiles: AnalyticsFileUpload;
  getUploadedFilesByFileType?: Maybe<Array<Maybe<AnalyticsFileUpload>>>;
  getLastUploadedFilesByFileType: AnalyticsFileUpload;
  getAllKPI: Array<Maybe<Kpi>>;
  getS3UrlForGKPIUpload: S3SignedUrl;
  getGKPIData: Array<Maybe<GkpiDataResponse>>;
  getCountryAndLocationList?: Maybe<Array<Maybe<CountryAndLocationList>>>;
  notifications: Array<Maybe<Notification>>;
  notificationCount: Scalars['Int'];
  getNotificationOfTypeByUserSlug?: Maybe<Notification>;
  getTrainings: Array<Maybe<BuData>>;
  getNote?: Maybe<Note>;
  capexComments: Array<Maybe<Comment>>;
  capexComment: Comment;
  getSifStatsByDuration: Array<Maybe<SifStatsDuration>>;
  getSifStats: Array<Maybe<SifStats>>;
  getSifStatsByType: Array<Maybe<SifStatsByTypeResponse>>;
  listAllSifStatsByMonth: Array<Maybe<ListSifStatsResponse>>;
  lastFileUploadDate: Scalars['Date'];
  getIncidentsByCategories: Array<Maybe<IncidentByCategory>>;
  getIncidentsByMonths: Array<Maybe<IncidentByMonth>>;
  getIncidentsByType: Array<Maybe<IncidentByType>>;
  getIncidentsBetweenRange: Array<Maybe<IncidentBetweenRange>>;
  getRoutinesAvailable: Array<Maybe<RoutineAvailable>>;
  getAllPillar: Array<Maybe<Pillar>>;
  getAllManagementTool: Array<Maybe<ManagementTool>>;
  getAccessLocations: Array<Maybe<LocationDetails>>;
  getAllAccessLocations?: Maybe<Scalars['JSON']>;
  canAccessTeamRooms: CanAccessTeamRooms;
  getLocationsDepartmentAreaSubarea: Array<Maybe<LocationsDepartmentAreaSubarea>>;
  getWorkLocations: Array<Maybe<WorkLocation>>;
  getSubLocations: Array<Maybe<SubLocations>>;
  getSpecificSubLocations: Array<Maybe<SpecificSubLocations>>;
  getDepartmentAreaSubareaFiltersForSupply: UserWorkLocationsFiltersForSupply;
  getDepartments: Array<Maybe<Department>>;
  getExternalCompanies: Array<Maybe<ExternalCompany>>;
  searchExternalCompanies: Array<Maybe<ExternalCompany>>;
  getSKAPAvailableChecklistByName: ChecklistAvailable;
  getPDTDefaultAvailableChecklist: ChecklistAvailable;
  getSKAPChecklistById: SkapChecklist;
  getPDTChecklistById: PdtChecklist;
  getSKAPChecklistsOfUserUnderEvaluation: Array<UserUnderEvaluationResponse>;
  getUserChecklistsByMonth: Array<Maybe<UserChecklistsByMonth>>;
  getAll5SChecklistsOfMonthGroupedByStatus: Array<UserChecklistsByStatus>;
  getPDTSByCreationTypeAndPerformingBody: Array<Maybe<RegisteredPdt>>;
  getSKAPModulesAndSubmodulesCount: GetModulesAndSubmodulesCountResponse;
  getPDTSupportCertificate: PdtSupportCertificate;
  getAll5SChecklistCountByStatus: GetAll5SChecklistCountByStatus;
  getAllOWDChecklists: Array<Maybe<GetAllOwdChecklists>>;
  getPDTAccess: GetPdtAccessResponse;
  getDashboardTableData?: Maybe<Scalars['JSON']>;
  fetchAllCrownChecklistsByUserSlug: Array<CrownChecklistsResponse>;
  fetchThreeRSChecklists: ThreeRsChecklist;
  fetchAllCrownChecklistMaterialsByProgram: Array<CrownChecklistMaterialsResponse>;
  fetchTeamRoomsChecklists: Array<Maybe<TeamRoomsChecklist>>;
  fetchWorkstationChecklists: Array<Maybe<WorkstationChecklist>>;
  getImpactedSKAPQuestionsOfUserUnderEvaluation: Array<Maybe<ImpactedQuestionsResponse>>;
  getAll5SByLocationId: Array<Maybe<Scalars['JSON']>>;
  getGasTestsByChecklistId: Array<Maybe<GetTest>>;
  getAllTrucks: Array<Maybe<Truck>>;
  getAllTrucksByLocationId: Array<Maybe<Truck>>;
  getAllTrucksByLocationSlug: Array<Maybe<Truck>>;
  getLastUploadedAtTimestamp?: Maybe<Truck>;
  getUserWorkLocations: Array<Maybe<UserWorkLocations>>;
  getAllLocationsUserPositions?: Maybe<Scalars['JSON']>;
  getDepartmentAreaSubArea?: Maybe<Scalars['JSON']>;
  getIncidentsByLastHour?: Maybe<GetIncidentsByLastHourResponse>;
  fetchLocationInfoByChecklistType: LocationInfoByChecklistType;
  getExternalRoutesByLocationSlug?: Maybe<Array<Maybe<Route>>>;
  getExternalRoutesByLocationId?: Maybe<Array<Maybe<Route>>>;
  getExternalRouteByRouteId?: Maybe<Route>;
  getRouteSafetyChecklistAnswerByRouteId: CriticalRouteChecklist;
  getRouteSafetyChecklistAnswerByLocationSlug: CriticalRouteChecklist;
  getRouteSafetyChecklistByLocationSlug: Array<Scalars['JSON']>;
  getRouteSafetyTruckDriversByLocationSlug?: Maybe<Array<Maybe<User>>>;
  getLastUpdateAtForTruckDriversUpload?: Maybe<UserInfo>;
  getPlannedRoutesByLocationSlug?: Maybe<Array<Maybe<PlannedRoute>>>;
  getIsTruckAvailableByTruckId?: Maybe<Scalars['Boolean']>;
  getTruckDrivers?: Maybe<Array<Maybe<GetTruckDriversResponse>>>;
  getLatestChecklistAnsweredAtByLocationSlug: LatestChecklistAnsweredAt;
  getAptTruckDriversByLocationId?: Maybe<Array<Maybe<User>>>;
  getPlannedRouteWithAnswerByRouteId?: Maybe<PlannedRouteWithAnswer>;
  checkAppUpdateRequired?: Maybe<CheckAppUpdateRequiredResponse>;
};


export type QueryGetUserInfoArgs = {
  id: Scalars['ID'];
};


export type QueryUserPositionsByBuArgs = {
  bu: Scalars['String'];
};


export type QueryGetUsersListArgs = {
  locationID: Scalars['ID'];
  userType?: Maybe<User_Type>;
  search?: Maybe<Scalars['String']>;
  roleType?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryGetCovidUsersForLocationsArgs = {
  locationIds: Array<Scalars['ID']>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
};


export type QueryUserHealthStatusArgs = {
  slug?: Maybe<Scalars['String']>;
};


export type QueryUserHealthStatusUnAuthenticatedArgs = {
  slug: Scalars['String'];
};


export type QuerySearchCovidUsersArgs = {
  search: Scalars['String'];
  locationIds: Array<Scalars['ID']>;
};


export type QuerySearchUsersWithAccessToLocationArgs = {
  searchString: Scalars['String'];
  locationSlug: Scalars['String'];
};


export type QueryGetUserUsualWorkLocationArgs = {
  userId?: Maybe<Scalars['Int']>;
};


export type QueryForkliftArgs = {
  slug: Scalars['String'];
};


export type QueryForkliftRoutineArgs = {
  startTime?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  timezoneOffsetStart?: Maybe<Scalars['String']>;
  timezoneOffsetEnd?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  forkliftRoutineId?: Maybe<Scalars['Int']>;
  pageSize?: Scalars['Int'];
  forkliftArea?: Maybe<Scalars['String']>;
};


export type QueryGetAllForkliftsArgs = {
  onlyWithSapNumber?: Maybe<Scalars['Boolean']>;
};


export type QueryCovidUsersArgs = {
  doctorStatus: Scalars['String'];
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryUserCovidTestTimelineArgs = {
  slug: Scalars['String'];
};


export type QueryUserVaccinesArgs = {
  slug?: Maybe<Scalars['String']>;
};


export type QueryGetUsersVaccineForTypeArgs = {
  type: Scalars['String'];
};


export type QueryActionLogsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  isResponsible?: Maybe<Scalars['Boolean']>;
  locationSlug?: Maybe<Scalars['String']>;
  status?: Maybe<Action_Log_Status>;
  isAll?: Maybe<Scalars['Boolean']>;
  filters?: Maybe<ActionLogFilters>;
};


export type QueryActionLogArgs = {
  id: Scalars['ID'];
  isResponsible?: Maybe<Scalars['Boolean']>;
  isAll?: Maybe<Scalars['Boolean']>;
};


export type QueryActionLogsCountGroupByLocationArgs = {
  startTime: Scalars['Date'];
  endTime: Scalars['Date'];
};


export type QueryGroupActionLogsInDcByStatusArgs = {
  locationSlug?: Maybe<Scalars['String']>;
  startTime: Scalars['Date'];
  endTime: Scalars['Date'];
  filters?: Maybe<ActionLogFilters>;
};


export type QueryCommentsArgs = {
  actionLogId: Scalars['ID'];
  orderByCreatedAt?: Maybe<OrderingFashion>;
};


export type QueryCommentArgs = {
  actionLogId: Scalars['ID'];
  commentId: Scalars['ID'];
};


export type QueryLocationArgs = {
  slug: Scalars['String'];
};


export type QueryGetLocationsArgs = {
  type?: Maybe<Location_Type>;
};


export type QueryLocationDetailsArgs = {
  locationSlug?: Maybe<Scalars['String']>;
};


export type QueryUserHasAccessToLocationArgs = {
  locationSlug: Scalars['String'];
  userSlug?: Maybe<Scalars['String']>;
};


export type QueryGetPostArgs = {
  slug: Scalars['String'];
};


export type QueryGetPostsArgs = {
  author?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  cursor?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  type?: Maybe<Posts_Types>;
  attachmentType?: Maybe<Array<Maybe<Attachment_Types>>>;
};


export type QueryGetUploadedFilesArgs = {
  limit?: Maybe<Scalars['Int']>;
};


export type QueryGetUploadedFilesByFileTypeArgs = {
  fileType: File_Types;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryGetLastUploadedFilesByFileTypeArgs = {
  fileType: File_Types;
};


export type QueryGetAllKpiArgs = {
  isGlobal?: Maybe<Scalars['Boolean']>;
};


export type QueryGetGkpiDataArgs = {
  country?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['Date']>;
  to?: Maybe<Scalars['Date']>;
};


export type QueryNotificationsArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryGetNotificationOfTypeByUserSlugArgs = {
  userShortSlug?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};


export type QueryGetTrainingsArgs = {
  data: GetTrainingsPayload;
  by: GetTrainingsBy;
  isFindOne?: Maybe<Scalars['Boolean']>;
};


export type QueryGetNoteArgs = {
  bu: Scalars['String'];
};


export type QueryCapexCommentsArgs = {
  capexId: Scalars['ID'];
  orderByCreatedAt?: Maybe<OrderingFashion>;
};


export type QueryCapexCommentArgs = {
  capexId: Scalars['ID'];
  commentId: Scalars['ID'];
};


export type QueryGetSifStatsByDurationArgs = {
  monthNumber: Scalars['Int'];
  year: Scalars['Int'];
  filter: FilterParams;
};


export type QueryGetSifStatsArgs = {
  filter: FilterParams;
  by: GetSifStatsBy;
  year: Scalars['Int'];
};


export type QueryGetSifStatsByTypeArgs = {
  filter?: Maybe<FilterParamsByType>;
  year: Scalars['Int'];
};


export type QueryListAllSifStatsByMonthArgs = {
  monthNumber: Scalars['Int'];
  year: Scalars['Int'];
  filter: FilterParams;
};


export type QueryGetIncidentsByCategoriesArgs = {
  monthNumber: Scalars['Int'];
  year: Scalars['Int'];
};


export type QueryGetIncidentsByMonthsArgs = {
  monthNumber: Scalars['Int'];
  year: Scalars['Int'];
};


export type QueryGetIncidentsByTypeArgs = {
  by: By;
  type: Type;
  monthNumber: Scalars['Int'];
  year: Scalars['Int'];
  filterOnCountry?: Maybe<Scalars['String']>;
};


export type QueryGetIncidentsBetweenRangeArgs = {
  monthNumber: Scalars['Int'];
  year: Scalars['Int'];
};


export type QueryGetRoutinesAvailableArgs = {
  forFilter?: Maybe<Scalars['Boolean']>;
};


export type QueryGetAllPillarArgs = {
  pillarsToIgnore?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryGetAllAccessLocationsArgs = {
  id: Scalars['ID'];
};


export type QueryGetLocationsDepartmentAreaSubareaArgs = {
  locationIds: Array<Maybe<Scalars['Int']>>;
};


export type QueryGetWorkLocationsArgs = {
  location: Array<Maybe<Scalars['Int']>>;
  userId?: Maybe<Scalars['Int']>;
};


export type QueryGetSubLocationsArgs = {
  workLocation: Array<Maybe<Scalars['Int']>>;
  userId?: Maybe<Scalars['Int']>;
};


export type QueryGetSpecificSubLocationsArgs = {
  subLocation: Array<Maybe<Scalars['Int']>>;
  userId?: Maybe<Scalars['Int']>;
};


export type QueryGetDepartmentAreaSubareaFiltersForSupplyArgs = {
  userId: Scalars['Int'];
  locationsIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


export type QuerySearchExternalCompaniesArgs = {
  searchString: Scalars['String'];
};


export type QueryGetSkapAvailableChecklistByNameArgs = {
  userUnderEvaluationShortSlug: Scalars['String'];
  name: Scalars['String'];
};


export type QueryGetSkapChecklistByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetPdtChecklistByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetSkapChecklistsOfUserUnderEvaluationArgs = {
  userUnderEvaluationShortSlug: Scalars['String'];
};


export type QueryGetAll5SChecklistsOfMonthGroupedByStatusArgs = {
  locationId?: Maybe<Scalars['ID']>;
  filter?: Maybe<InputFiltersFor5S>;
};


export type QueryGetPdtsByCreationTypeAndPerformingBodyArgs = {
  args: GetPdtInput;
};


export type QueryGetSkapModulesAndSubmodulesCountArgs = {
  filter: GetModuleSubmoduleCountFilterParams;
};


export type QueryGetPdtSupportCertificateArgs = {
  args: GetPdtSupportCertificateInput;
};


export type QueryGetAll5SChecklistCountByStatusArgs = {
  status: Scalars['String'];
  locationId?: Maybe<Scalars['ID']>;
  locationSlug?: Maybe<Scalars['String']>;
};


export type QueryGetAllOwdChecklistsArgs = {
  args: GetAllOwdFilterParams;
};


export type QueryGetDashboardTableDataArgs = {
  locationIds: Array<Maybe<Scalars['ID']>>;
  groupBy?: Maybe<Scalars['JSON']>;
  date?: Maybe<Scalars['JSON']>;
};


export type QueryFetchAllCrownChecklistsByUserSlugArgs = {
  shortSlug: Scalars['String'];
  userVersion: Scalars['String'];
};


export type QueryFetchThreeRsChecklistsArgs = {
  locationId?: Maybe<Scalars['Int']>;
};


export type QueryFetchAllCrownChecklistMaterialsByProgramArgs = {
  programName: DpoAcademyPrograms;
};


export type QueryFetchTeamRoomsChecklistsArgs = {
  locationId?: Maybe<Scalars['Int']>;
};


export type QueryFetchWorkstationChecklistsArgs = {
  locationId?: Maybe<Scalars['Int']>;
};


export type QueryGetImpactedSkapQuestionsOfUserUnderEvaluationArgs = {
  checklistAvailableId: Scalars['ID'];
  userUnderEvaluationShortSlug: Scalars['String'];
};


export type QueryGetAll5SByLocationIdArgs = {
  locationId: Scalars['ID'];
  monthNumber?: Maybe<Scalars['Int']>;
  areaId?: Maybe<Scalars['Int']>;
  subAreaId?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  userSearch?: Maybe<Scalars['String']>;
};


export type QueryGetGasTestsByChecklistIdArgs = {
  checklistId: Scalars['ID'];
};


export type QueryGetAllTrucksByLocationIdArgs = {
  locationId: Scalars['Int'];
  isApt?: Maybe<Scalars['Boolean']>;
  routeID?: Maybe<Scalars['ID']>;
};


export type QueryGetAllTrucksByLocationSlugArgs = {
  locationSlug: Scalars['String'];
};


export type QueryGetLastUploadedAtTimestampArgs = {
  locationSlug: Scalars['String'];
};


export type QueryGetUserWorkLocationsArgs = {
  user: Array<Maybe<Scalars['Int']>>;
};


export type QueryGetDepartmentAreaSubAreaArgs = {
  id: Scalars['ID'];
};


export type QueryGetIncidentsByLastHourArgs = {
  userId: Scalars['ID'];
};


export type QueryFetchLocationInfoByChecklistTypeArgs = {
  locationId: Scalars['ID'];
  checklistType?: Maybe<ChecklistType>;
};


export type QueryGetExternalRoutesByLocationSlugArgs = {
  locationSlug: Scalars['String'];
};


export type QueryGetExternalRoutesByLocationIdArgs = {
  locationId: Scalars['ID'];
};


export type QueryGetExternalRouteByRouteIdArgs = {
  routeId: Scalars['ID'];
};


export type QueryGetRouteSafetyChecklistAnswerByRouteIdArgs = {
  routeId: Scalars['Int'];
};


export type QueryGetRouteSafetyChecklistAnswerByLocationSlugArgs = {
  locationSlug: Scalars['String'];
};


export type QueryGetRouteSafetyChecklistByLocationSlugArgs = {
  locationSlug: Scalars['String'];
};


export type QueryGetRouteSafetyTruckDriversByLocationSlugArgs = {
  locationSlug: Scalars['String'];
};


export type QueryGetLastUpdateAtForTruckDriversUploadArgs = {
  locationSlug: Scalars['String'];
};


export type QueryGetPlannedRoutesByLocationSlugArgs = {
  locationSlug: Scalars['String'];
};


export type QueryGetIsTruckAvailableByTruckIdArgs = {
  truckId: Scalars['Int'];
};


export type QueryGetLatestChecklistAnsweredAtByLocationSlugArgs = {
  locationSlug: Scalars['String'];
};


export type QueryGetAptTruckDriversByLocationIdArgs = {
  locationId: Scalars['ID'];
  routeId: Scalars['ID'];
};


export type QueryGetPlannedRouteWithAnswerByRouteIdArgs = {
  routeId: Scalars['ID'];
};


export type QueryCheckAppUpdateRequiredArgs = {
  versionNo: Scalars['String'];
  platform: Scalars['String'];
};

export enum Risk_Level {
  Low = 'LOW',
  Mid = 'MID',
  High = 'HIGH'
}

export type RegisteredPdt = {
  __typename?: 'RegisteredPDT';
  id: Scalars['ID'];
  createdAt: Scalars['Date'];
  checklistName: Scalars['String'];
  status: Scalars['String'];
  performingBody: PerformingBody;
  description: Scalars['String'];
  externalUsers?: Maybe<Scalars['Int']>;
  checklistDepartment?: Maybe<Scalars['String']>;
};

export type RegisteredPdts = {
  __typename?: 'RegisteredPDTS';
  id: Scalars['ID'];
  createdAt: Scalars['Date'];
  checklistName: Scalars['String'];
  description: Scalars['String'];
  externalUsers?: Maybe<Scalars['Int']>;
  checklistInfo: Scalars['JSON'];
};

export type RejectedChecklist = {
  __typename?: 'RejectedChecklist';
  index?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
};

export type Route = {
  __typename?: 'Route';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  externalRouteId: Scalars['ID'];
  status: Scalars['String'];
  content?: Maybe<Scalars['JSON']>;
  checklistContent?: Maybe<Scalars['JSON']>;
  location?: Maybe<Location>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type Routine = {
  __typename?: 'Routine';
  id: Scalars['ID'];
  answers: Scalars['JSON'];
  userRoutine: UserRoutine;
};

export type RoutineAvailable = {
  __typename?: 'RoutineAvailable';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  frequency?: Maybe<Scalars['String']>;
  content: Scalars['String'];
  isActive?: Maybe<Scalars['Int']>;
};

export type S3SignedUrl = {
  __typename?: 'S3SignedURL';
  preSignedUrl: Scalars['String'];
  fileName: Scalars['String'];
};

export type SkapChecklist = {
  __typename?: 'SKAPChecklist';
  id: Scalars['ID'];
  content: Scalars['JSON'];
  checklistOperators: Array<BaseUser>;
  checklistInfo?: Maybe<ChecklistInfo>;
};

export enum SkapUsersFilterType {
  AllUsers = 'ALL_USERS',
  LastThreeMonths = 'LAST_THREE_MONTHS',
  MoreThanThreeMonths = 'MORE_THAN_THREE_MONTHS'
}

export enum Supply_User_Roles {
  PlantManager = 'plant_manager',
  SafetyManager = 'safety_manager',
  EnvironmentManager = 'environment_manager',
  SafetyEnvironmentManager = 'safety_environment_manager',
  LineManagerSafetyEnvironment = 'line_manager_safety_environment',
  LineManagerSafety = 'line_manager_safety',
  LineManagerEnvironment = 'line_manager_environment',
  AssignerSafetyEnvironment = 'assigner_safety_environment',
  AssignerSafety = 'assigner_safety',
  AssignerEnvironment = 'assigner_environment',
  EjecutorSafetyEnvironment = 'ejecutor_safety_environment',
  EjecutorEnvironment = 'ejecutor_environment',
  EjecutorSafety = 'ejecutor_safety',
  EjecutorContratistaPdt = 'ejecutor_contratista_pdt',
  WebOnly = 'web_only',
  KioskUser = 'kiosk_user',
  ReporterOnly = 'reporter_only',
  CorporateSupply = 'corporate_supply',
  DoctorReporterOnlySupply = 'doctor_reporter_only_supply',
  Zbs = 'zbs'
}

export type Save3RsChecklistAnswerResponse = {
  __typename?: 'Save3RSChecklistAnswerResponse';
  corruptedPayloadIndexes: Array<Scalars['Int']>;
  rejectedChecklist?: Maybe<RejectedChecklist>;
};

export type SaveTeamRoomsChecklistAnswerResponse = {
  __typename?: 'SaveTeamRoomsChecklistAnswerResponse';
  corruptedPayloadIndexes: Array<Scalars['Int']>;
  rejectedChecklist?: Maybe<RejectedChecklist>;
};

export type SaveWorkstationChecklistAnswerResponse = {
  __typename?: 'SaveWorkstationChecklistAnswerResponse';
  corruptedPayloadIndexes: Array<Scalars['Int']>;
  rejectedChecklist?: Maybe<RejectedChecklist>;
};

export type SifStats = {
  __typename?: 'SifStats';
  name: Scalars['String'];
  totalSif: Scalars['Int'];
};

export type SifStatsByTypeResponse = {
  __typename?: 'SifStatsByTypeResponse';
  month: Scalars['String'];
  sifType: Array<Maybe<SifStats>>;
};

export type SifStatsDuration = {
  __typename?: 'SifStatsDuration';
  duration: Scalars['String'];
  totalSif: Scalars['Int'];
};

export type SubDepartment = {
  __typename?: 'SubDepartment';
  id: Scalars['ID'];
  departmentId: Scalars['ID'];
  name: Scalars['String'];
  nameEN: Scalars['String'];
  acronymEN: Scalars['String'];
  acronymES: Scalars['String'];
  slug: Scalars['String'];
};

export type SubLocationDetails = {
  __typename?: 'SubLocationDetails';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  nameEN: Scalars['String'];
};

export type SubModulesData = {
  __typename?: 'SubModulesData';
  content: Scalars['JSON'];
  isCompleted: Scalars['Boolean'];
  name: Scalars['String'];
  progressPercentage: Scalars['Int'];
  slug: Scalars['String'];
  checklistAvailableId: Scalars['ID'];
  checklistId?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['Date']>;
  startedAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  isAnswered: Scalars['Boolean'];
};

export type Subzone = {
  __typename?: 'Subzone';
  name: Scalars['String'];
  slug: Scalars['String'];
  id: Scalars['ID'];
  zone: Zone;
  distributionCenters?: Maybe<Array<Maybe<Location>>>;
};

export type SubzoneWithActionLogCount = {
  __typename?: 'SubzoneWithActionLogCount';
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  actionLogsCount?: Maybe<Scalars['Int']>;
  locations?: Maybe<Array<Maybe<LocationWithActionLogCount>>>;
};

export enum SupportCertificateNames {
  BloqueoDeEnergia = 'BLOQUEO_DE_ENERGIA',
  TrabajoEnAlturas = 'TRABAJO_EN_ALTURAS',
  EspaciosConfinados = 'ESPACIOS_CONFINADOS',
  ObrasDeInfraestructura = 'OBRAS_DE_INFRAESTRUCTURA',
  IzajeDeCargasConGrua = 'IZAJE_DE_CARGAS_CON_GRUA',
  TrabajosElectricos = 'TRABAJOS_ELECTRICOS',
  TrabajosEnCaliente = 'TRABAJOS_EN_CALIENTE',
  DeshabilitarSistemasDispositivosDeSeguridad = 'DESHABILITAR_SISTEMAS_DISPOSITIVOS_DE_SEGURIDAD',
  ManejoSustanciasQuimicasPeligrosasAGranel = 'MANEJO_SUSTANCIAS_QUIMICAS_PELIGROSAS_A_GRANEL'
}

export type TeamRoomsChecklist = {
  __typename?: 'TeamRoomsChecklist';
  id: Scalars['ID'];
  name: Scalars['String'];
  content: Scalars['JSON'];
  answered: Array<Maybe<AnsweredThreeRsChecklist>>;
};

export type TestTimeline = {
  __typename?: 'TestTimeline';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  doctorStatus?: Maybe<Scalars['String']>;
  quarantineUntil?: Maybe<Scalars['Date']>;
  quarantineStart?: Maybe<Scalars['Date']>;
  location?: Maybe<Location>;
  covidTests?: Maybe<Array<Maybe<CovidTest>>>;
  failedCertificate?: Maybe<FailedCertificate>;
  userHealthStatus?: Maybe<HealthStatus>;
};

export type ThreeRsChecklist = {
  __typename?: 'ThreeRSChecklist';
  id: Scalars['ID'];
  name: Scalars['String'];
  content: Scalars['JSON'];
  answered: Array<Maybe<AnsweredThreeRsChecklist>>;
};

export type Truck = {
  __typename?: 'Truck';
  id?: Maybe<Scalars['ID']>;
  sapNumber?: Maybe<Scalars['String']>;
  plateNumber?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['ID']>;
  content?: Maybe<Scalars['JSON']>;
  updatedAt?: Maybe<Scalars['Date']>;
  plannedRoute?: Maybe<PlannedRoute>;
};

export enum User_Creation_Source {
  App = 'APP',
  Website = 'WEBSITE',
  Pin = 'PIN'
}

export enum User_Features {
  CloseSios = 'close_sios',
  ReturnSios = 'return_sios',
  AssignSios = 'assign_sios',
  EditSios = 'edit_sios',
  ReportSios = 'report_sios',
  ReportSiosAr = 'report_sios_ar',
  AssignSiosAr = 'assign_sios_ar',
  WebReportSios = 'web_report_sios',
  SafetyWebAccess = 'safety_web_access',
  SioOnlyReportsWeb = 'sio_only_reports_web',
  Certificates = 'certificates',
  ScanOperations = 'scan_operations',
  Routines = 'routines',
  ActionLogsApp = 'action_logs_app',
  ReceiveTrainings = 'receive_trainings',
  GiveTrainings = 'give_trainings',
  Medic = 'medic',
  AllAnalytics = 'all_analytics',
  SafetyAcademy = 'safety_academy',
  ActionLogsWeb = 'action_logs_web',
  SkapApp = 'skap_app',
  SkapWeb = 'skap_web',
  FiveSApp = 'fiveS_app',
  FiveSWeb = 'fiveS_web',
  PdtApp = 'pdt_app',
  AppSkapFrontLiner = 'app_skap_front_liner',
  AppSkapLineManager = 'app_skap_line_manager',
  CloseUnassignedIncidentSupply = 'close_unassigned_incident_supply',
  CountIncidentsByLocation = 'count_incidents_by_location',
  CountIncidentsByDepartment = 'count_incidents_by_department',
  CountIncidentsByArea = 'count_incidents_by_area',
  CountIncidentsBySubarea = 'count_incidents_by_subarea',
  GetIncidentsByDepartment = 'get_incidents_by_department',
  GetIncidentsByArea = 'get_incidents_by_area',
  GetSifNotificationByLocation = 'get_sif_notification_by_location',
  GetSifNotificationByDepartment = 'get_sif_notification_by_department',
  GetPriorityNotificationByLocation = 'get_priority_notification_by_location',
  GetPriorityNotificationByDepartment = 'get_priority_notification_by_department',
  SaveDepartments = 'save_departments',
  SaveAreas = 'save_areas',
  SaveSubAreas = 'save_sub_areas',
  GetUserDepartments = 'get_user_departments',
  GetUserAreas = 'get_user_areas',
  GetUserSubAreas = 'get_user_sub_areas',
  PostponeIncident = 'postpone_incident',
  CreateUserPin = 'create_user_pin',
  BlockUnblockUsers = 'block_unblock_users',
  AssignSiosScreenMobile = 'assign_sios_screen_mobile',
  EditSiosSif = 'edit_sios_sif',
  EditSiosRiskType = 'edit_sios_risk_type',
  EditClosedSios = 'edit_closed_sios',
  PrefillByLocation = 'prefill_by_location',
  PrefillByDepartment = 'prefill_by_department',
  PrefillByArea = 'prefill_by_area',
  PrefillBySubArea = 'prefill_by_sub_area',
  CanAccessManagerScreenMobile = 'can_access_manager_screen_mobile',
  PrefilterIncidentsByLocation = 'prefilter_incidents_by_location',
  PrefilterIncidentsByDepartment = 'prefilter_incidents_by_department',
  PrefilterIncidentsByArea = 'prefilter_incidents_by_area',
  CanUserSeeAnalyticsMobile = 'can_user_see_analytics_mobile',
  CanUserGetSiosAssigned = 'can_user_get_sios_assigned',
  CanUserGetArAssigned = 'can_user_get_ar_assigned',
  CanSetRoutinesInStorage = 'can_set_routines_in_storage',
  CanAccessRoutines = 'can_access_routines',
  ThreeRsAuditor = 'three_rs_auditor',
  ThreeRsSelfAssessment = 'three_rs_self_assessment',
  TeamRoomsCheckToCoach = 'team_rooms_check_to_coach',
  TeamRoomsSelfAssessment = 'team_rooms_self_assessment',
  SiosLimitPerHour = 'sios_limit_per_hour',
  WorkstationCheckToCoach = 'workstation_check_to_coach',
  WorkstationSelfAssessment = 'workstation_self_assessment',
  SupervisorDpoAcademy = 'supervisor_dpo_academy',
  OperatorDpoAcademyDelivery = 'operator_dpo_academy_delivery',
  OperatorDpoAcademyWarehouse = 'operator_dpo_academy_warehouse',
  DownloadHc = 'download_hc',
  LocationSecurityScreen = 'location_security_screen',
  AnalyticsThreeRs = 'analytics_three_rs',
  DeleteUsers = 'delete_users',
  DownloadGeneralHc = 'download_general_hc',
  DownloadSkapHc = 'download_skap_hc',
  CloseSiosArTemporarily = 'close_sios_ar_temporarily',
  CloseSiosAr = 'close_sios_ar',
  EditOperations = 'edit_operations',
  Overview = 'overview',
  Analytics = 'analytics',
  SearchUsers = 'search_users',
  Acis = 'acis',
  Locations = 'locations',
  Pdts = 'pdts',
  SafetyAcademySidebar = 'safety_academy_sidebar',
  DpoAcademy = 'dpo_academy',
  Boards = 'boards',
  Owd = 'owd',
  AnalyticsAcis = 'analytics_acis',
  AnalyticsEnvironmentalRisks = 'analytics_environmental_risks',
  AnalyticsSefetyCertificatesT2 = 'analytics_sefety_certificates_t2',
  AnalyticsRoutines = 'analytics_routines',
  AnalyticsCashless = 'analytics_cashless',
  AnalyticsFiveS = 'analytics_five_s',
  AnalyticsFiveSSepDec_2023 = 'analytics_five_s_sep_dec_2023',
  AnalyticsPowerBiSiosT2 = 'analytics_power_bi_sios_t2',
  AnalyticsSifRoute = 'analytics_sif_route',
  AnalyticsComplianceSif = 'analytics_compliance_sif',
  AnalyticsAccidents = 'analytics_accidents',
  AnalyticsStatisticsSif = 'analytics_statistics_sif',
  AnalyticsConsequences = 'analytics_consequences',
  AnalyticsSafetyTraining = 'analytics_safety_training',
  AnalyticsCapex = 'analytics_capex',
  AnalyticsPreventionOfViolence = 'analytics_prevention_of_violence',
  AnalyticsGkpi = 'analytics_gkpi',
  AnalyticsSkap = 'analytics_skap',
  AnalyticsEffectivenessOfRoutines = 'analytics_effectiveness_of_routines',
  AnalyticsEffectivenessOfWorkstations = 'analytics_effectiveness_of_workstations',
  AnalyticsDms = 'analytics_dms',
  Analytics_3rs = 'analytics_3rs',
  AnalyticsUsabilityDpo = 'analytics_usability_dpo',
  AnalyticsOwds = 'analytics_owds',
  CreateUserByEmployeeNumber = 'create_user_by_employee_number',
  RoadSafetyWeb = 'road_safety_web',
  ResourcesRoadSafetyWeb = 'resources_road_safety_web',
  DriversRoadSafetyWeb = 'drivers_road_safety_web',
  CriticalRoutesRoadSafetyWeb = 'critical_routes_road_safety_web',
  UpdateRouteRoadSafetyApp = 'update_route_road_safety_app'
}

export enum User_Type {
  Contractor = 'CONTRACTOR',
  Internal = 'INTERNAL'
}

export type UpdateWorkLocationsPayload = {
  areaId?: Maybe<Scalars['Int']>;
  areaName?: Maybe<Scalars['String']>;
  areaNameEn?: Maybe<Scalars['String']>;
  subAreaId?: Maybe<Scalars['Int']>;
  subAreaName?: Maybe<Scalars['String']>;
  subAreaNameEn?: Maybe<Scalars['String']>;
  subLocationId?: Maybe<Scalars['Int']>;
  subLocationName?: Maybe<Scalars['String']>;
  subLocationNameEn?: Maybe<Scalars['String']>;
};


export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  shortSlug?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  pin?: Maybe<Scalars['String']>;
  blockedReason?: Maybe<Scalars['String']>;
  lastActiveAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  doctorStatus?: Maybe<Scalars['String']>;
  employeeNumber?: Maybe<Scalars['String']>;
  quarantineUntil?: Maybe<Scalars['Date']>;
  distributionCenter?: Maybe<LocationDetails>;
  covidTest?: Maybe<CovidTest>;
  vaccines?: Maybe<Array<Maybe<UserVaccine>>>;
  lastVaccine?: Maybe<UserVaccine>;
  info?: Maybe<UserInfo>;
  userRole?: Maybe<UserRole>;
  startWorkingDay?: Maybe<Scalars['Date']>;
  autoAssignSios?: Maybe<Scalars['Boolean']>;
};

export type UserAccessDetails = {
  __typename?: 'UserAccessDetails';
  id: Scalars['ID'];
  distributionCenter: LocationDetails;
};

export type UserChecklistsByMonth = {
  __typename?: 'UserChecklistsByMonth';
  name: Scalars['String'];
  slug: Scalars['String'];
  value: Array<Maybe<UserChecklistsResponseValue>>;
};

export type UserChecklistsByStatus = {
  __typename?: 'UserChecklistsByStatus';
  status: Scalars['String'];
  value: Array<Maybe<UserChecklistsResponseValue>>;
};

export type UserChecklistsResponseValue = {
  __typename?: 'UserChecklistsResponseValue';
  checklistId: Scalars['ID'];
  checklistsAvailableId: Scalars['ID'];
  submittedAt?: Maybe<Scalars['Date']>;
  createdAt: Scalars['Date'];
  name: Scalars['String'];
  slug: Scalars['String'];
  answeredContent?: Maybe<Scalars['JSON']>;
  content: Scalars['JSON'];
  status: Scalars['String'];
  workLocation: Scalars['String'];
  subWorkLocation: Scalars['String'];
  appliedBy: BaseUser;
  assignedTo?: Maybe<BaseUser>;
  truck?: Maybe<Truck>;
  forklift?: Maybe<Forklift>;
  sapNumber?: Maybe<Scalars['String']>;
  numberOfActionLogs: Scalars['Int'];
};

export type UserHealthStatus = {
  __typename?: 'UserHealthStatus';
  quarantineUntil?: Maybe<Scalars['Date']>;
  quarantineStart?: Maybe<Scalars['Date']>;
  healthStatus?: Maybe<HealthStatus>;
  usersLocationMedicalFacility?: Maybe<Array<Maybe<UsersLocationMedicalFacility>>>;
};

export type UserInfo = {
  __typename?: 'UserInfo';
  id: Scalars['ID'];
  userId?: Maybe<Scalars['ID']>;
  content?: Maybe<Scalars['JSON']>;
  latestVersion?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Date']>;
  isAuthorisedForOWD?: Maybe<Scalars['Boolean']>;
  visitorType?: Maybe<Scalars['String']>;
};

export type UserInfoPayload = {
  isAuthorisedForOWD?: Maybe<Scalars['Boolean']>;
  visitorType?: Maybe<Scalars['String']>;
};

export type UserNote = {
  __typename?: 'UserNote';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type UserPositionsByBu = {
  __typename?: 'UserPositionsByBU';
  position: Scalars['String'];
  bu: BusinessUnit;
};

export type UserRoutine = {
  __typename?: 'UserRoutine';
  id: Scalars['ID'];
  user: User;
};

export type UserUnderEvaluationResponse = {
  __typename?: 'UserUnderEvaluationResponse';
  title: Scalars['String'];
  slug: Scalars['String'];
  progressPercentage: Scalars['Int'];
  isCompleted: Scalars['Boolean'];
  startedAt?: Maybe<Scalars['Date']>;
  subModules: Array<SubModulesData>;
};

export type UserVaccine = {
  __typename?: 'UserVaccine';
  id: Scalars['ID'];
  type?: Maybe<Scalars['String']>;
  vaccinatedUser?: Maybe<User>;
  vaccinationGivenBy?: Maybe<BaseUser>;
  givenAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  approvedAt?: Maybe<Scalars['Date']>;
  image?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
};

export type UserVaccinesForLocation = {
  __typename?: 'UserVaccinesForLocation';
  users?: Maybe<Array<Maybe<User>>>;
  count?: Maybe<Scalars['Int']>;
};

export type UsersGeneralInfoPayload = {
  locationId: Scalars['ID'];
  position?: Maybe<Scalars['String']>;
  userWorkLocation?: Maybe<Scalars['JSON']>;
  startWorkingDay?: Maybe<Scalars['Date']>;
};

export type UsersLocationMedicalFacility = {
  __typename?: 'UsersLocationMedicalFacility';
  emergencyContact?: Maybe<Scalars['String']>;
};

export type UsersVaccinatedCount = {
  __typename?: 'UsersVaccinatedCount';
  vaccinated?: Maybe<Scalars['Int']>;
  totalUsers?: Maybe<Scalars['Int']>;
};

export enum Vaccine_Type {
  Pfizer = 'PFIZER',
  Johsons = 'JOHSONS',
  Moderna = 'MODERNA',
  LaChina = 'LA_CHINA'
}


export type WorkLocationDetails = {
  __typename?: 'WorkLocationDetails';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  locationType: Scalars['String'];
  nameEN: Scalars['String'];
};

export type WorkstationChecklist = {
  __typename?: 'WorkstationChecklist';
  id: Scalars['ID'];
  name: Scalars['String'];
  content: Scalars['JSON'];
  answered: Array<Maybe<AnsweredWorkstationChecklist>>;
};

export type Zone = {
  __typename?: 'Zone';
  name: Scalars['String'];
  slug: Scalars['String'];
  id: Scalars['ID'];
  businessUnit?: Maybe<BusinessUnit>;
  subzones?: Maybe<Array<Maybe<Subzone>>>;
};

export type ZoneWithActionLogCount = {
  __typename?: 'ZoneWithActionLogCount';
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  actionLogsCount?: Maybe<Scalars['Int']>;
  subzones?: Maybe<Array<Maybe<SubzoneWithActionLogCount>>>;
};

export type AddOrEditCommentOnChecklistInput = {
  checklistId: Scalars['ID'];
  comment: Scalars['String'];
  commentId?: Maybe<Scalars['ID']>;
  isZBSUser?: Maybe<Scalars['Boolean']>;
};

export type AddOrEditGasTestInput = {
  checklistId: Scalars['ID'];
  content: Scalars['JSON'];
  gasTestId?: Maybe<Scalars['ID']>;
};

export type AddSignatureToChecklistInput = {
  id?: Maybe<Scalars['String']>;
  checklistId: Scalars['ID'];
  signedByUserId: Scalars['ID'];
  signature: Scalars['String'];
};

export type Area = {
  __typename?: 'area';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  nameEN?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  subAreas?: Maybe<Array<Maybe<SubArea>>>;
};

export type BuContent = {
  __typename?: 'buContent';
  name: Scalars['String'];
  totalIncidents: Scalars['Int'];
};

export enum By {
  Cd = 'cd',
  Vehical = 'vehical'
}

export type CheckAppUpdateRequiredResponse = {
  __typename?: 'checkAppUpdateRequiredResponse';
  isUpdateRequired: Scalars['Boolean'];
};

export type CorruptedPayloadIndexes = {
  __typename?: 'corruptedPayloadIndexes';
  corruptedPayloadIndexes: Array<Scalars['Int']>;
};

export type CreatePdtChecklistInput = {
  checklistAvailableSlug: Scalars['String'];
  departmentId?: Maybe<Scalars['ID']>;
  externalCompanyId?: Maybe<Scalars['ID']>;
  pdtGasTestDetails?: Maybe<Scalars['JSON']>;
  areaManagerUserId: Scalars['ID'];
  performerUserId: Scalars['ID'];
  territoryOwnerUserId: Scalars['ID'];
  inspectedByUserId: Scalars['ID'];
  pdtZBSDetails?: Maybe<Scalars['JSON']>;
};

export type Delete5SChecklistsInput = {
  checklistsId?: Maybe<Array<Scalars['ID']>>;
  locationId?: Maybe<Scalars['ID']>;
  date?: Maybe<Scalars['Date']>;
};

export type DeleteOperatorsOfChecklistInput = {
  checklistId: Scalars['ID'];
  operatorUserIds: Array<Scalars['ID']>;
};

export type DeleteSignatureFromChecklistInput = {
  checklistId: Scalars['ID'];
  signatureId?: Maybe<Scalars['ID']>;
};

export type DiscardCreatedActionLogsOfUncreatedOwdInput = {
  actionLogIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type DistributionCenterWorkLocation = {
  __typename?: 'distributionCenterWorkLocation';
  slug?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ErrorUpdateWorkLocationsDetails = {
  __typename?: 'errorUpdateWorkLocationsDetails';
  areaError: Scalars['Boolean'];
  subAreaError: Scalars['Boolean'];
  subLocationError: Scalars['Boolean'];
};

export type FilterParams = {
  bu?: Maybe<Scalars['String']>;
  cause?: Maybe<Scalars['String']>;
  nature?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
};

export type FilterParamsByType = {
  bu?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
};

export type GetAllOwdFilterParams = {
  locationSlug: Scalars['String'];
  source: ClientType;
  pillar?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  userVersion?: Maybe<Scalars['String']>;
  userSearch?: Maybe<Scalars['String']>;
};

export type GetIncidentsByLastHourResponse = {
  __typename?: 'getIncidentsByLastHourResponse';
  hasReachedLimit?: Maybe<Scalars['Boolean']>;
  firstIncidentDate?: Maybe<Scalars['Date']>;
};

export type GetModuleSubmoduleCountFilterParams = {
  users?: Maybe<SkapUsersFilterType>;
  locationIds: Array<Maybe<Scalars['ID']>>;
};

export type GetPdtInput = {
  dateRange?: Maybe<DateRangeInput>;
  performingBodySlug: Scalars['String'];
};

export type GetPdtSupportCertificateInput = {
  checklistId: Scalars['ID'];
  checklistName: SupportCertificateNames;
};

export type GetUsersListPaginated = {
  __typename?: 'getUsersListPaginated';
  users?: Maybe<Array<Maybe<User>>>;
  paginationInfo: PaginationInfo;
};

export type ImpactedSkapQuestionBit = {
  skapChecklistId: Scalars['ID'];
  sectionIndex: Scalars['Int'];
  questionIndex: Scalars['Int'];
  newValue: Scalars['Boolean'];
};

export type LocationsDepartmentAreaSubarea = {
  __typename?: 'locationsDepartmentAreaSubarea';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  departments?: Maybe<Array<Maybe<WorkLocation>>>;
};

export type PaginationInfo = {
  __typename?: 'paginationInfo';
  totalCount?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
  totalPages?: Maybe<Scalars['Int']>;
};

export type Reason = {
  __typename?: 'reason';
  title: Scalars['String'];
  description: Scalars['String'];
};

export type ReasonInput = {
  title: Scalars['String'];
  description: Scalars['String'];
};

export type ResponseUpdateWorkLocations = {
  __typename?: 'responseUpdateWorkLocations';
  MutationStatus: MutationStatus;
  Error?: Maybe<ErrorUpdateWorkLocationsDetails>;
};

export type RoutinesAvailableNameAndSlug = {
  __typename?: 'routinesAvailableNameAndSlug';
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type Save3RsChecklistAnswerInput = {
  checklistAvailableId: Scalars['ID'];
  content: Scalars['JSON'];
  answeredByUserId: Scalars['ID'];
};

export type Save5SChecklistInput = {
  checklistId: Scalars['ID'];
  content: Scalars['JSON'];
  sapNumber?: Maybe<Scalars['String']>;
};

export type SaveBulk3RsChecklistAnswerInput = {
  checklists: Array<Maybe<Save3RsChecklistAnswerInput>>;
};

export type SaveBulkDpoAcademyChecklistAnswerInput = {
  checklists: Array<Maybe<SaveDpoAcademyChecklistAnswerInput>>;
};

export type SaveBulkTeamRoomsChecklistAnswerInput = {
  checklists: Array<Maybe<SaveTeamRoomsChecklistAnswerInput>>;
};

export type SaveBulkWorkstationChecklistAnswerInput = {
  checklists?: Maybe<Array<Maybe<SaveWorkstationChecklistAnswerInput>>>;
};

export type SaveDpoAcademyChecklistAnswerInput = {
  checklistAvailableId: Scalars['ID'];
  content: Scalars['JSON'];
  answeredByUserId: Scalars['ID'];
};

export type SaveOwdChecklistInput = {
  checklistAvailableId: Scalars['ID'];
  content: Scalars['JSON'];
  progressPercentage: Scalars['Float'];
  userUnderEvaluationShortSlug: Scalars['String'];
  impactedSKAPQuestions?: Maybe<Array<Maybe<ImpactedSkapQuestionBit>>>;
  unansweredImpactedSKAPQuestions?: Maybe<Array<Maybe<UnansweredImpactedSkapQuestionBit>>>;
  actionLogs?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type SavePdtChecklistInput = {
  checklistId: Scalars['ID'];
  operatorUserIds?: Maybe<Array<Scalars['ID']>>;
  content: Scalars['JSON'];
  buId?: Maybe<Scalars['ID']>;
  departmentId?: Maybe<Scalars['ID']>;
  externalCompanyId?: Maybe<Scalars['ID']>;
  workingDepartmentId?: Maybe<Scalars['ID']>;
  workingSubDepartmentId?: Maybe<Scalars['ID']>;
  areaManagerUserId: Scalars['ID'];
  performerUserId: Scalars['ID'];
  territoryOwnerUserId: Scalars['ID'];
  inspectedByUserId: Scalars['ID'];
  status?: Maybe<Scalars['String']>;
  statusReasonContent?: Maybe<StatusReasonContentInput>;
};

export type SavePdtSupportCertificateInput = {
  checklistId: Scalars['ID'];
  questions: Scalars['JSON'];
  answers: Scalars['JSON'];
};

export type SaveRouteSafetyChecklistAnswerInput = {
  checklistAvailableId: Scalars['ID'];
  content: Scalars['JSON'];
  answeredByUserId: Scalars['ID'];
};

export type SaveSkapChecklistInput = {
  checklistAvailableId: Scalars['ID'];
  userUnderEvaluationShortSlug: Scalars['String'];
  content: Scalars['JSON'];
  delta: Scalars['JSON'];
  progressPercentage: Scalars['Int'];
};

export type SaveTeamRoomsChecklistAnswerInput = {
  checklistAvailableId: Scalars['ID'];
  content: Scalars['JSON'];
  answeredByUserId: Scalars['ID'];
};

export type SaveWorkstationChecklistAnswerInput = {
  checklistAvailableId: Scalars['ID'];
  content: Scalars['JSON'];
  answeredByUserId: Scalars['ID'];
};

export type SpecificSubLocations = {
  __typename?: 'specificSubLocations';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  nameEn?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  areaId?: Maybe<Scalars['Int']>;
  areaSlug?: Maybe<Scalars['String']>;
  departmentId?: Maybe<Scalars['Int']>;
  departmentSlug?: Maybe<Scalars['String']>;
  SubArea?: Maybe<SubLocationSpecificSubLocation>;
};

export type StatusReasonContent = {
  __typename?: 'statusReasonContent';
  reason: Array<Maybe<Reason>>;
  comment?: Maybe<Scalars['String']>;
};

export type StatusReasonContentInput = {
  reason: Array<Maybe<ReasonInput>>;
  comment?: Maybe<Scalars['String']>;
};

export type SubArea = {
  __typename?: 'subArea';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  nameEn?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type SubLocationSpecificSubLocation = {
  __typename?: 'subLocationSpecificSubLocation';
  slug?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  workLocation?: Maybe<WorkLocationSubLocation>;
};

export type SubLocations = {
  __typename?: 'subLocations';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  nameEN?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  departmentId?: Maybe<Scalars['Int']>;
  departmentSlug?: Maybe<Scalars['String']>;
  workLocation?: Maybe<WorkLocationSubLocation>;
};

export enum Type {
  Best = 'best',
  Offender = 'offender'
}

export type UnansweredImpactedSkapQuestionBit = {
  skapChecklistAvailableId: Scalars['ID'];
  sectionIndex: Scalars['Int'];
  questionIndex: Scalars['Int'];
  value: Scalars['Boolean'];
};

export type UpdateOperatorOfChecklistInput = {
  checklistId: Scalars['ID'];
  operatorUserId: Scalars['ID'];
  oldOperatorUserId?: Maybe<Scalars['ID']>;
};

export type UpdateSkapChecklistInput = {
  checklistId: Scalars['ID'];
  userUnderEvaluationShortSlug: Scalars['String'];
  content: Scalars['JSON'];
  delta: Scalars['JSON'];
  progressPercentage: Scalars['Int'];
};

export type UpdateStatusOfChecklistInput = {
  checklistId: Scalars['ID'];
  status: Scalars['String'];
  statusReasonContent?: Maybe<StatusReasonContentInput>;
  checklistType?: Maybe<ChecklistType>;
  content?: Maybe<Scalars['JSON']>;
  progressPercentage?: Maybe<Scalars['Int']>;
};

export type UserChecklistInput = {
  userChecklistId: Scalars['ID'];
  assignedUserId: Scalars['ID'];
};

export type UserPositions = {
  __typename?: 'userPositions';
  position?: Maybe<Scalars['String']>;
};

export type UserRole = {
  __typename?: 'userRole';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
};

export type UserWorkLocations = {
  __typename?: 'userWorkLocations';
  id?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  workLocation?: Maybe<WorkLocation>;
  subLocation?: Maybe<Area>;
  subArea?: Maybe<SubArea>;
};

export type UserWorkLocationsFiltersForSupply = {
  __typename?: 'userWorkLocationsFiltersForSupply';
  departments?: Maybe<Array<Maybe<WorkLocation>>>;
  areas?: Maybe<Array<Maybe<SubLocations>>>;
  subAreas?: Maybe<Array<Maybe<SpecificSubLocations>>>;
};

export type UsualWorkLocation = {
  __typename?: 'usualWorkLocation';
  country: Scalars['String'];
  businessUnit: Scalars['String'];
  location: Scalars['String'];
};

export type WeekContent = {
  __typename?: 'weekContent';
  week: Scalars['String'];
  totalIncidents: Scalars['Int'];
};

export type WorkLocation = {
  __typename?: 'workLocation';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  nameEN?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['Int']>;
  DEPRECATED_distributionCenter?: Maybe<DistributionCenterWorkLocation>;
  areas?: Maybe<Array<Maybe<Area>>>;
};

export type WorkLocationSubLocation = {
  __typename?: 'workLocationSubLocation';
  slug?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  DEPRECATED_distributionCenter?: Maybe<DistributionCenterWorkLocation>;
};



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type LegacyStitchingResolver<TResult, TParent, TContext, TArgs> = {
  fragment: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type NewStitchingResolver<TResult, TParent, TContext, TArgs> = {
  selectionSet: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type StitchingResolver<TResult, TParent, TContext, TArgs> = LegacyStitchingResolver<TResult, TParent, TContext, TArgs> | NewStitchingResolver<TResult, TParent, TContext, TArgs>;
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>
  | StitchingResolver<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterator<TResult> | Promise<AsyncIterator<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  ACTION_LOG_ORIGIN: ResolverTypeWrapper<any>;
  ACTION_LOG_PILLAR_IMAGE: ResolverTypeWrapper<any>;
  ACTION_LOG_STATUS: ResolverTypeWrapper<any>;
  ATTACHMENT_TYPES: ResolverTypeWrapper<any>;
  ActionLogFilters: ResolverTypeWrapper<any>;
  String: ResolverTypeWrapper<any>;
  ActionLogsInDCByStatus: ResolverTypeWrapper<any>;
  AnalyticsFileUpload: ResolverTypeWrapper<any>;
  ID: ResolverTypeWrapper<any>;
  AnsweredCriticalRouteChecklist: ResolverTypeWrapper<any>;
  AnsweredDPOAcademyChecklist: ResolverTypeWrapper<any>;
  Float: ResolverTypeWrapper<any>;
  Int: ResolverTypeWrapper<any>;
  Boolean: ResolverTypeWrapper<any>;
  AnsweredTeamRoomsChecklist: ResolverTypeWrapper<any>;
  AnsweredThreeRSChecklist: ResolverTypeWrapper<any>;
  AnsweredWorkstationChecklist: ResolverTypeWrapper<any>;
  Attachment: ResolverTypeWrapper<any>;
  BaseLocation: ResolverTypeWrapper<any>;
  BaseUser: ResolverTypeWrapper<any>;
  BuData: ResolverTypeWrapper<any>;
  BusinessUnit: ResolverTypeWrapper<any>;
  BusinessUnitWithActionLogCount: ResolverTypeWrapper<any>;
  CacheControlScope: ResolverTypeWrapper<any>;
  CanAccessTeamRooms: ResolverTypeWrapper<any>;
  ChecklistAvailable: ResolverTypeWrapper<any>;
  ChecklistInfo: ResolverTypeWrapper<any>;
  ChecklistMaterial: ResolverTypeWrapper<any>;
  ChecklistType: ResolverTypeWrapper<any>;
  ClientType: ResolverTypeWrapper<any>;
  Cluster: ResolverTypeWrapper<any>;
  Comment: ResolverTypeWrapper<any>;
  CountByAutonomousLevel: ResolverTypeWrapper<any>;
  CountByLevels: ResolverTypeWrapper<any>;
  CountByPosition: ResolverTypeWrapper<any>;
  CountryAndLocationList: ResolverTypeWrapper<any>;
  CovidTest: ResolverTypeWrapper<any>;
  CovidUser: ResolverTypeWrapper<any>;
  CovidUserAndCount: ResolverTypeWrapper<any>;
  CovidUsersForLocation: ResolverTypeWrapper<any>;
  CovidUsersForLocationCount: ResolverTypeWrapper<any>;
  CreateEditActionLog: ResolverTypeWrapper<any>;
  CreateEditActionLogPayload: ResolverTypeWrapper<any>;
  CreateEditActionLogResponse: ResolverTypeWrapper<any>;
  CriticalRouteChecklist: ResolverTypeWrapper<any>;
  CrownChecklistMaterialsResponse: ResolverTypeWrapper<any>;
  CrownChecklistsResponse: ResolverTypeWrapper<any>;
  DPOAcademyChecklist: ResolverTypeWrapper<any>;
  DPOAcademyPrograms: ResolverTypeWrapper<any>;
  Date: ResolverTypeWrapper<any>;
  DateRangeInput: ResolverTypeWrapper<any>;
  DelayedActionLogsInDCByStatus: ResolverTypeWrapper<any>;
  Department: ResolverTypeWrapper<any>;
  EmergencyContact: ResolverTypeWrapper<any>;
  ExternalCompany: ResolverTypeWrapper<any>;
  FILE_TYPES: ResolverTypeWrapper<any>;
  FailedCertificate: ResolverTypeWrapper<any>;
  Forklift: ResolverTypeWrapper<any>;
  ForkliftArea: ResolverTypeWrapper<any>;
  ForkliftRoutine: ResolverTypeWrapper<any>;
  GENERAL_USER_ROLES: ResolverTypeWrapper<any>;
  GKPIDataResponse: ResolverTypeWrapper<any>;
  GKPIExcelProcessingStatus: ResolverTypeWrapper<any>;
  GKPIFileProcessingStatus: ResolverTypeWrapper<any>;
  GKPIItemContent: ResolverTypeWrapper<any>;
  GetAll5SChecklistCountByStatus: ResolverTypeWrapper<any>;
  GetAllOWDChecklists: ResolverTypeWrapper<any>;
  GetModulesAndSubmodulesCountResponse: ResolverTypeWrapper<any>;
  GetPDTAccessResponse: ResolverTypeWrapper<any>;
  GetPDTCreationType: ResolverTypeWrapper<any>;
  GetPostResponse: ResolverTypeWrapper<any>;
  GetSifStatsBy: ResolverTypeWrapper<any>;
  GetTest: ResolverTypeWrapper<any>;
  GetTrainingsBy: ResolverTypeWrapper<any>;
  GetTrainingsPayload: ResolverTypeWrapper<any>;
  GetTruckDriversResponse: ResolverTypeWrapper<any>;
  HealthStatus: ResolverTypeWrapper<any>;
  ImageInputItem: ResolverTypeWrapper<any>;
  ImageInputItemType: ResolverTypeWrapper<any>;
  ImpactedQuestionsResponse: ResolverTypeWrapper<any>;
  Incident: ResolverTypeWrapper<any>;
  IncidentBetweenRange: ResolverTypeWrapper<any>;
  IncidentByCategory: ResolverTypeWrapper<any>;
  IncidentByMonth: ResolverTypeWrapper<any>;
  IncidentByType: ResolverTypeWrapper<any>;
  InputFiltersFor5S: ResolverTypeWrapper<any>;
  JSON: ResolverTypeWrapper<any>;
  KPI: ResolverTypeWrapper<any>;
  LOCATION_TYPE: ResolverTypeWrapper<any>;
  LOGISTICS_USER_ROLES: ResolverTypeWrapper<any>;
  LatestChecklistAnsweredAt: ResolverTypeWrapper<any>;
  LatestCovidTest: ResolverTypeWrapper<any>;
  ListSifStatsResponse: ResolverTypeWrapper<any>;
  Location: ResolverTypeWrapper<any>;
  LocationDetails: ResolverTypeWrapper<any>;
  LocationInfoByChecklistType: ResolverTypeWrapper<any>;
  LocationName: ResolverTypeWrapper<any>;
  LocationSchema: ResolverTypeWrapper<any>;
  LocationWithActionLogCount: ResolverTypeWrapper<any>;
  ManagementTool: ResolverTypeWrapper<any>;
  ModulePercentage: ResolverTypeWrapper<any>;
  MonthWiseScore: ResolverTypeWrapper<any>;
  Mutation: ResolverTypeWrapper<{}>;
  MutationStatus: ResolverTypeWrapper<any>;
  Note: ResolverTypeWrapper<any>;
  NoteInput: ResolverTypeWrapper<any>;
  Notification: ResolverTypeWrapper<any>;
  NumberOfContacts: ResolverTypeWrapper<any>;
  OperatorSignature: ResolverTypeWrapper<any>;
  OrderingFashion: ResolverTypeWrapper<any>;
  PDTChecklist: ResolverTypeWrapper<any>;
  PDTSupportCertificate: ResolverTypeWrapper<any>;
  POSTS_TYPES: ResolverTypeWrapper<any>;
  PerformingBody: ResolverTypeWrapper<any>;
  PermissionInfoPayload: ResolverTypeWrapper<any>;
  Pillar: ResolverTypeWrapper<any>;
  PlannedRoute: ResolverTypeWrapper<any>;
  PlannedRouteWithAnswer: ResolverTypeWrapper<any>;
  Post: ResolverTypeWrapper<any>;
  QuarantineeInformation: ResolverTypeWrapper<any>;
  Query: ResolverTypeWrapper<{}>;
  RISK_LEVEL: ResolverTypeWrapper<any>;
  RegisteredPDT: ResolverTypeWrapper<any>;
  RegisteredPDTS: ResolverTypeWrapper<any>;
  RejectedChecklist: ResolverTypeWrapper<any>;
  Route: ResolverTypeWrapper<any>;
  Routine: ResolverTypeWrapper<any>;
  RoutineAvailable: ResolverTypeWrapper<any>;
  S3SignedURL: ResolverTypeWrapper<any>;
  SKAPChecklist: ResolverTypeWrapper<any>;
  SKAPUsersFilterType: ResolverTypeWrapper<any>;
  SUPPLY_USER_ROLES: ResolverTypeWrapper<any>;
  Save3RSChecklistAnswerResponse: ResolverTypeWrapper<any>;
  SaveTeamRoomsChecklistAnswerResponse: ResolverTypeWrapper<any>;
  SaveWorkstationChecklistAnswerResponse: ResolverTypeWrapper<any>;
  SifStats: ResolverTypeWrapper<any>;
  SifStatsByTypeResponse: ResolverTypeWrapper<any>;
  SifStatsDuration: ResolverTypeWrapper<any>;
  SubDepartment: ResolverTypeWrapper<any>;
  SubLocationDetails: ResolverTypeWrapper<any>;
  SubModulesData: ResolverTypeWrapper<any>;
  Subzone: ResolverTypeWrapper<any>;
  SubzoneWithActionLogCount: ResolverTypeWrapper<any>;
  SupportCertificateNames: ResolverTypeWrapper<any>;
  TeamRoomsChecklist: ResolverTypeWrapper<any>;
  TestTimeline: ResolverTypeWrapper<any>;
  ThreeRSChecklist: ResolverTypeWrapper<any>;
  Truck: ResolverTypeWrapper<any>;
  USER_CREATION_SOURCE: ResolverTypeWrapper<any>;
  USER_FEATURES: ResolverTypeWrapper<any>;
  USER_TYPE: ResolverTypeWrapper<any>;
  UpdateWorkLocationsPayload: ResolverTypeWrapper<any>;
  Upload: ResolverTypeWrapper<any>;
  User: ResolverTypeWrapper<any>;
  UserAccessDetails: ResolverTypeWrapper<any>;
  UserChecklistsByMonth: ResolverTypeWrapper<any>;
  UserChecklistsByStatus: ResolverTypeWrapper<any>;
  UserChecklistsResponseValue: ResolverTypeWrapper<any>;
  UserHealthStatus: ResolverTypeWrapper<any>;
  UserInfo: ResolverTypeWrapper<any>;
  UserInfoPayload: ResolverTypeWrapper<any>;
  UserNote: ResolverTypeWrapper<any>;
  UserPositionsByBU: ResolverTypeWrapper<any>;
  UserRoutine: ResolverTypeWrapper<any>;
  UserUnderEvaluationResponse: ResolverTypeWrapper<any>;
  UserVaccine: ResolverTypeWrapper<any>;
  UserVaccinesForLocation: ResolverTypeWrapper<any>;
  UsersGeneralInfoPayload: ResolverTypeWrapper<any>;
  UsersLocationMedicalFacility: ResolverTypeWrapper<any>;
  UsersVaccinatedCount: ResolverTypeWrapper<any>;
  VACCINE_TYPE: ResolverTypeWrapper<any>;
  Void: ResolverTypeWrapper<any>;
  WorkLocationDetails: ResolverTypeWrapper<any>;
  WorkstationChecklist: ResolverTypeWrapper<any>;
  Zone: ResolverTypeWrapper<any>;
  ZoneWithActionLogCount: ResolverTypeWrapper<any>;
  addOrEditCommentOnChecklistInput: ResolverTypeWrapper<any>;
  addOrEditGasTestInput: ResolverTypeWrapper<any>;
  addSignatureToChecklistInput: ResolverTypeWrapper<any>;
  area: ResolverTypeWrapper<any>;
  buContent: ResolverTypeWrapper<any>;
  by: ResolverTypeWrapper<any>;
  checkAppUpdateRequiredResponse: ResolverTypeWrapper<any>;
  corruptedPayloadIndexes: ResolverTypeWrapper<any>;
  createPDTChecklistInput: ResolverTypeWrapper<any>;
  delete5SChecklistsInput: ResolverTypeWrapper<any>;
  deleteOperatorsOfChecklistInput: ResolverTypeWrapper<any>;
  deleteSignatureFromChecklistInput: ResolverTypeWrapper<any>;
  discardCreatedActionLogsOfUncreatedOWDInput: ResolverTypeWrapper<any>;
  distributionCenterWorkLocation: ResolverTypeWrapper<any>;
  errorUpdateWorkLocationsDetails: ResolverTypeWrapper<any>;
  filterParams: ResolverTypeWrapper<any>;
  filterParamsByType: ResolverTypeWrapper<any>;
  getAllOWDFilterParams: ResolverTypeWrapper<any>;
  getIncidentsByLastHourResponse: ResolverTypeWrapper<any>;
  getModuleSubmoduleCountFilterParams: ResolverTypeWrapper<any>;
  getPDTInput: ResolverTypeWrapper<any>;
  getPDTSupportCertificateInput: ResolverTypeWrapper<any>;
  getUsersListPaginated: ResolverTypeWrapper<any>;
  impactedSKAPQuestionBit: ResolverTypeWrapper<any>;
  locationsDepartmentAreaSubarea: ResolverTypeWrapper<any>;
  paginationInfo: ResolverTypeWrapper<any>;
  reason: ResolverTypeWrapper<any>;
  reasonInput: ResolverTypeWrapper<any>;
  responseUpdateWorkLocations: ResolverTypeWrapper<any>;
  routinesAvailableNameAndSlug: ResolverTypeWrapper<any>;
  save3RSChecklistAnswerInput: ResolverTypeWrapper<any>;
  save5SChecklistInput: ResolverTypeWrapper<any>;
  saveBulk3RSChecklistAnswerInput: ResolverTypeWrapper<any>;
  saveBulkDPOAcademyChecklistAnswerInput: ResolverTypeWrapper<any>;
  saveBulkTeamRoomsChecklistAnswerInput: ResolverTypeWrapper<any>;
  saveBulkWorkstationChecklistAnswerInput: ResolverTypeWrapper<any>;
  saveDPOAcademyChecklistAnswerInput: ResolverTypeWrapper<any>;
  saveOWDChecklistInput: ResolverTypeWrapper<any>;
  savePDTChecklistInput: ResolverTypeWrapper<any>;
  savePDTSupportCertificateInput: ResolverTypeWrapper<any>;
  saveRouteSafetyChecklistAnswerInput: ResolverTypeWrapper<any>;
  saveSKAPChecklistInput: ResolverTypeWrapper<any>;
  saveTeamRoomsChecklistAnswerInput: ResolverTypeWrapper<any>;
  saveWorkstationChecklistAnswerInput: ResolverTypeWrapper<any>;
  specificSubLocations: ResolverTypeWrapper<any>;
  statusReasonContent: ResolverTypeWrapper<any>;
  statusReasonContentInput: ResolverTypeWrapper<any>;
  subArea: ResolverTypeWrapper<any>;
  subLocationSpecificSubLocation: ResolverTypeWrapper<any>;
  subLocations: ResolverTypeWrapper<any>;
  type: ResolverTypeWrapper<any>;
  unansweredImpactedSKAPQuestionBit: ResolverTypeWrapper<any>;
  updateOperatorOfChecklistInput: ResolverTypeWrapper<any>;
  updateSKAPChecklistInput: ResolverTypeWrapper<any>;
  updateStatusOfChecklistInput: ResolverTypeWrapper<any>;
  userChecklistInput: ResolverTypeWrapper<any>;
  userPositions: ResolverTypeWrapper<any>;
  userRole: ResolverTypeWrapper<any>;
  userWorkLocations: ResolverTypeWrapper<any>;
  userWorkLocationsFiltersForSupply: ResolverTypeWrapper<any>;
  usualWorkLocation: ResolverTypeWrapper<any>;
  weekContent: ResolverTypeWrapper<any>;
  workLocation: ResolverTypeWrapper<any>;
  workLocationSubLocation: ResolverTypeWrapper<any>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  ActionLogFilters: any;
  String: any;
  ActionLogsInDCByStatus: any;
  AnalyticsFileUpload: any;
  ID: any;
  AnsweredCriticalRouteChecklist: any;
  AnsweredDPOAcademyChecklist: any;
  Float: any;
  Int: any;
  Boolean: any;
  AnsweredTeamRoomsChecklist: any;
  AnsweredThreeRSChecklist: any;
  AnsweredWorkstationChecklist: any;
  Attachment: any;
  BaseLocation: any;
  BaseUser: any;
  BuData: any;
  BusinessUnit: any;
  BusinessUnitWithActionLogCount: any;
  CanAccessTeamRooms: any;
  ChecklistAvailable: any;
  ChecklistInfo: any;
  ChecklistMaterial: any;
  Cluster: any;
  Comment: any;
  CountByAutonomousLevel: any;
  CountByLevels: any;
  CountByPosition: any;
  CountryAndLocationList: any;
  CovidTest: any;
  CovidUser: any;
  CovidUserAndCount: any;
  CovidUsersForLocation: any;
  CovidUsersForLocationCount: any;
  CreateEditActionLog: any;
  CreateEditActionLogPayload: any;
  CreateEditActionLogResponse: any;
  CriticalRouteChecklist: any;
  CrownChecklistMaterialsResponse: any;
  CrownChecklistsResponse: any;
  DPOAcademyChecklist: any;
  Date: any;
  DateRangeInput: any;
  DelayedActionLogsInDCByStatus: any;
  Department: any;
  EmergencyContact: any;
  ExternalCompany: any;
  FailedCertificate: any;
  Forklift: any;
  ForkliftArea: any;
  ForkliftRoutine: any;
  GKPIDataResponse: any;
  GKPIExcelProcessingStatus: any;
  GKPIItemContent: any;
  GetAll5SChecklistCountByStatus: any;
  GetAllOWDChecklists: any;
  GetModulesAndSubmodulesCountResponse: any;
  GetPDTAccessResponse: any;
  GetPostResponse: any;
  GetTest: any;
  GetTrainingsPayload: any;
  GetTruckDriversResponse: any;
  HealthStatus: any;
  ImageInputItem: any;
  ImageInputItemType: any;
  ImpactedQuestionsResponse: any;
  Incident: any;
  IncidentBetweenRange: any;
  IncidentByCategory: any;
  IncidentByMonth: any;
  IncidentByType: any;
  InputFiltersFor5S: any;
  JSON: any;
  KPI: any;
  LatestChecklistAnsweredAt: any;
  LatestCovidTest: any;
  ListSifStatsResponse: any;
  Location: any;
  LocationDetails: any;
  LocationInfoByChecklistType: any;
  LocationName: any;
  LocationSchema: any;
  LocationWithActionLogCount: any;
  ManagementTool: any;
  ModulePercentage: any;
  MonthWiseScore: any;
  Mutation: {};
  MutationStatus: any;
  Note: any;
  NoteInput: any;
  Notification: any;
  NumberOfContacts: any;
  OperatorSignature: any;
  PDTChecklist: any;
  PDTSupportCertificate: any;
  PerformingBody: any;
  PermissionInfoPayload: any;
  Pillar: any;
  PlannedRoute: any;
  PlannedRouteWithAnswer: any;
  Post: any;
  QuarantineeInformation: any;
  Query: {};
  RegisteredPDT: any;
  RegisteredPDTS: any;
  RejectedChecklist: any;
  Route: any;
  Routine: any;
  RoutineAvailable: any;
  S3SignedURL: any;
  SKAPChecklist: any;
  Save3RSChecklistAnswerResponse: any;
  SaveTeamRoomsChecklistAnswerResponse: any;
  SaveWorkstationChecklistAnswerResponse: any;
  SifStats: any;
  SifStatsByTypeResponse: any;
  SifStatsDuration: any;
  SubDepartment: any;
  SubLocationDetails: any;
  SubModulesData: any;
  Subzone: any;
  SubzoneWithActionLogCount: any;
  TeamRoomsChecklist: any;
  TestTimeline: any;
  ThreeRSChecklist: any;
  Truck: any;
  UpdateWorkLocationsPayload: any;
  Upload: any;
  User: any;
  UserAccessDetails: any;
  UserChecklistsByMonth: any;
  UserChecklistsByStatus: any;
  UserChecklistsResponseValue: any;
  UserHealthStatus: any;
  UserInfo: any;
  UserInfoPayload: any;
  UserNote: any;
  UserPositionsByBU: any;
  UserRoutine: any;
  UserUnderEvaluationResponse: any;
  UserVaccine: any;
  UserVaccinesForLocation: any;
  UsersGeneralInfoPayload: any;
  UsersLocationMedicalFacility: any;
  UsersVaccinatedCount: any;
  Void: any;
  WorkLocationDetails: any;
  WorkstationChecklist: any;
  Zone: any;
  ZoneWithActionLogCount: any;
  addOrEditCommentOnChecklistInput: any;
  addOrEditGasTestInput: any;
  addSignatureToChecklistInput: any;
  area: any;
  buContent: any;
  checkAppUpdateRequiredResponse: any;
  corruptedPayloadIndexes: any;
  createPDTChecklistInput: any;
  delete5SChecklistsInput: any;
  deleteOperatorsOfChecklistInput: any;
  deleteSignatureFromChecklistInput: any;
  discardCreatedActionLogsOfUncreatedOWDInput: any;
  distributionCenterWorkLocation: any;
  errorUpdateWorkLocationsDetails: any;
  filterParams: any;
  filterParamsByType: any;
  getAllOWDFilterParams: any;
  getIncidentsByLastHourResponse: any;
  getModuleSubmoduleCountFilterParams: any;
  getPDTInput: any;
  getPDTSupportCertificateInput: any;
  getUsersListPaginated: any;
  impactedSKAPQuestionBit: any;
  locationsDepartmentAreaSubarea: any;
  paginationInfo: any;
  reason: any;
  reasonInput: any;
  responseUpdateWorkLocations: any;
  routinesAvailableNameAndSlug: any;
  save3RSChecklistAnswerInput: any;
  save5SChecklistInput: any;
  saveBulk3RSChecklistAnswerInput: any;
  saveBulkDPOAcademyChecklistAnswerInput: any;
  saveBulkTeamRoomsChecklistAnswerInput: any;
  saveBulkWorkstationChecklistAnswerInput: any;
  saveDPOAcademyChecklistAnswerInput: any;
  saveOWDChecklistInput: any;
  savePDTChecklistInput: any;
  savePDTSupportCertificateInput: any;
  saveRouteSafetyChecklistAnswerInput: any;
  saveSKAPChecklistInput: any;
  saveTeamRoomsChecklistAnswerInput: any;
  saveWorkstationChecklistAnswerInput: any;
  specificSubLocations: any;
  statusReasonContent: any;
  statusReasonContentInput: any;
  subArea: any;
  subLocationSpecificSubLocation: any;
  subLocations: any;
  unansweredImpactedSKAPQuestionBit: any;
  updateOperatorOfChecklistInput: any;
  updateSKAPChecklistInput: any;
  updateStatusOfChecklistInput: any;
  userChecklistInput: any;
  userPositions: any;
  userRole: any;
  userWorkLocations: any;
  userWorkLocationsFiltersForSupply: any;
  usualWorkLocation: any;
  weekContent: any;
  workLocation: any;
  workLocationSubLocation: any;
};

export type CacheControlDirectiveArgs = {   maxAge?: Maybe<Scalars['Int']>;
  scope?: Maybe<CacheControlScope>; };

export type CacheControlDirectiveResolver<Result, Parent, ContextType = any, Args = CacheControlDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type ActionLogsInDcByStatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActionLogsInDCByStatus'] = ResolversParentTypes['ActionLogsInDCByStatus']> = {
  OPEN?: Resolver<Array<Maybe<ResolversTypes['CreateEditActionLog']>>, ParentType, ContextType>;
  IN_PROGRESS?: Resolver<Array<Maybe<ResolversTypes['CreateEditActionLog']>>, ParentType, ContextType>;
  FOR_REVISION?: Resolver<Array<Maybe<ResolversTypes['CreateEditActionLog']>>, ParentType, ContextType>;
  COMPLETED?: Resolver<Array<Maybe<ResolversTypes['CreateEditActionLog']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnalyticsFileUploadResolvers<ContextType = any, ParentType extends ResolversParentTypes['AnalyticsFileUpload'] = ResolversParentTypes['AnalyticsFileUpload']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  fileType?: Resolver<ResolversTypes['FILE_TYPES'], ParentType, ContextType>;
  fileS3Url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uploadedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnsweredCriticalRouteChecklistResolvers<ContextType = any, ParentType extends ResolversParentTypes['AnsweredCriticalRouteChecklist'] = ResolversParentTypes['AnsweredCriticalRouteChecklist']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  answeredByUser?: Resolver<ResolversTypes['BaseUser'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnsweredDpoAcademyChecklistResolvers<ContextType = any, ParentType extends ResolversParentTypes['AnsweredDPOAcademyChecklist'] = ResolversParentTypes['AnsweredDPOAcademyChecklist']> = {
  score?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalCorrect?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalWrong?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  attempt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  answeredAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  dateOfAttemptsOfBackendSync?: Resolver<Array<ResolversTypes['Date']>, ParentType, ContextType>;
  checklistPassed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnsweredTeamRoomsChecklistResolvers<ContextType = any, ParentType extends ResolversParentTypes['AnsweredTeamRoomsChecklist'] = ResolversParentTypes['AnsweredTeamRoomsChecklist']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  answeredByUser?: Resolver<ResolversTypes['BaseUser'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnsweredThreeRsChecklistResolvers<ContextType = any, ParentType extends ResolversParentTypes['AnsweredThreeRSChecklist'] = ResolversParentTypes['AnsweredThreeRSChecklist']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  answeredByUser?: Resolver<ResolversTypes['BaseUser'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnsweredWorkstationChecklistResolvers<ContextType = any, ParentType extends ResolversParentTypes['AnsweredWorkstationChecklist'] = ResolversParentTypes['AnsweredWorkstationChecklist']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  checklistId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  answeredByUser?: Resolver<ResolversTypes['BaseUser'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttachmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Attachment'] = ResolversParentTypes['Attachment']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['ATTACHMENT_TYPES']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BaseLocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['BaseLocation'] = ResolversParentTypes['BaseLocation']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  conutry?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BaseUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['BaseUser'] = ResolversParentTypes['BaseUser']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isBlocked?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  shortSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  picture?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pin?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  blockedReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastActiveAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  doctorStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  employeeNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BuDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['BuData'] = ResolversParentTypes['BuData']> = {
  usersToTrain?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  usersTrained?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  position?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  percentage?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BusinessUnitResolvers<ContextType = any, ParentType extends ResolversParentTypes['BusinessUnit'] = ResolversParentTypes['BusinessUnit']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  businessUnitId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  zones?: Resolver<Maybe<Array<Maybe<ResolversTypes['Zone']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BusinessUnitWithActionLogCountResolvers<ContextType = any, ParentType extends ResolversParentTypes['BusinessUnitWithActionLogCount'] = ResolversParentTypes['BusinessUnitWithActionLogCount']> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  actionLogsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  zones?: Resolver<Maybe<Array<Maybe<ResolversTypes['ZoneWithActionLogCount']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CanAccessTeamRoomsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CanAccessTeamRooms'] = ResolversParentTypes['CanAccessTeamRooms']> = {
  canAccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  userCategory?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChecklistAvailableResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChecklistAvailable'] = ResolversParentTypes['ChecklistAvailable']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChecklistInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChecklistInfo'] = ResolversParentTypes['ChecklistInfo']> = {
  bu?: Resolver<Maybe<ResolversTypes['BusinessUnit']>, ParentType, ContextType>;
  department?: Resolver<Maybe<ResolversTypes['Department']>, ParentType, ContextType>;
  externalCompany?: Resolver<Maybe<ResolversTypes['ExternalCompany']>, ParentType, ContextType>;
  workingDepartment?: Resolver<Maybe<ResolversTypes['Department']>, ParentType, ContextType>;
  workingSubDepartment?: Resolver<Maybe<ResolversTypes['SubDepartment']>, ParentType, ContextType>;
  areaManager?: Resolver<Maybe<ResolversTypes['BaseUser']>, ParentType, ContextType>;
  performer?: Resolver<Maybe<ResolversTypes['BaseUser']>, ParentType, ContextType>;
  territoryOwner?: Resolver<Maybe<ResolversTypes['BaseUser']>, ParentType, ContextType>;
  inspector?: Resolver<Maybe<ResolversTypes['BaseUser']>, ParentType, ContextType>;
  gasTests?: Resolver<Maybe<Array<Maybe<ResolversTypes['GetTest']>>>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  statusReasonContent?: Resolver<Maybe<ResolversTypes['statusReasonContent']>, ParentType, ContextType>;
  userUnderEvaluation?: Resolver<Maybe<ResolversTypes['BaseUser']>, ParentType, ContextType>;
  progressPercentage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isCompleted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChecklistMaterialResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChecklistMaterial'] = ResolversParentTypes['ChecklistMaterial']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  driveLink?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  material?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClusterResolvers<ContextType = any, ParentType extends ResolversParentTypes['Cluster'] = ResolversParentTypes['Cluster']> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CommentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Comment'] = ResolversParentTypes['Comment']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  comment?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['BaseUser'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountByAutonomousLevelResolvers<ContextType = any, ParentType extends ResolversParentTypes['CountByAutonomousLevel'] = ResolversParentTypes['CountByAutonomousLevel']> = {
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountByLevelsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CountByLevels'] = ResolversParentTypes['CountByLevels']> = {
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  percentage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountByPositionResolvers<ContextType = any, ParentType extends ResolversParentTypes['CountByPosition'] = ResolversParentTypes['CountByPosition']> = {
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  impactedCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  data?: Resolver<Array<Maybe<ResolversTypes['ModulePercentage']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountryAndLocationListResolvers<ContextType = any, ParentType extends ResolversParentTypes['CountryAndLocationList'] = ResolversParentTypes['CountryAndLocationList']> = {
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  locations?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CovidTestResolvers<ContextType = any, ParentType extends ResolversParentTypes['CovidTest'] = ResolversParentTypes['CovidTest']> = {
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  givenAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  testImage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  resultAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  approvedBy?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  needsApproval?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CovidUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['CovidUser'] = ResolversParentTypes['CovidUser']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  picture?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isAlreadyDiagnosed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  covidTest?: Resolver<Maybe<ResolversTypes['CovidTest']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CovidUserAndCountResolvers<ContextType = any, ParentType extends ResolversParentTypes['CovidUserAndCount'] = ResolversParentTypes['CovidUserAndCount']> = {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  covidUserList?: Resolver<Maybe<Array<Maybe<ResolversTypes['CovidUser']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CovidUsersForLocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CovidUsersForLocation'] = ResolversParentTypes['CovidUsersForLocation']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  employeeNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  quarantineUntil?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  doctorStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['LocationName']>, ParentType, ContextType>;
  latestCovidTest?: Resolver<Maybe<ResolversTypes['LatestCovidTest']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CovidUsersForLocationCountResolvers<ContextType = any, ParentType extends ResolversParentTypes['CovidUsersForLocationCount'] = ResolversParentTypes['CovidUsersForLocationCount']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  covidUsersForLocation?: Resolver<Maybe<Array<Maybe<ResolversTypes['CovidUsersForLocation']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateEditActionLogResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateEditActionLog'] = ResolversParentTypes['CreateEditActionLog']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  KPIs?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  KPI?: Resolver<ResolversTypes['KPI'], ParentType, ContextType>;
  endDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['ACTION_LOG_STATUS'], ParentType, ContextType>;
  pillar?: Resolver<ResolversTypes['ACTION_LOG_PILLAR_IMAGE'], ParentType, ContextType>;
  owner?: Resolver<ResolversTypes['BaseUser'], ParentType, ContextType>;
  location?: Resolver<ResolversTypes['BaseLocation'], ParentType, ContextType>;
  responsibles?: Resolver<Array<ResolversTypes['BaseUser']>, ParentType, ContextType>;
  origin?: Resolver<Maybe<ResolversTypes['ACTION_LOG_ORIGIN']>, ParentType, ContextType>;
  meeting?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  images?: Resolver<Array<Maybe<ResolversTypes['ImageInputItemType']>>, ParentType, ContextType>;
  statusUpdateDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  routinesAvailable?: Resolver<Maybe<ResolversTypes['routinesAvailableNameAndSlug']>, ParentType, ContextType>;
  originText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  numberOfComments?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ManagementTool?: Resolver<Maybe<ResolversTypes['ManagementTool']>, ParentType, ContextType>;
  checklistsAvailableId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  checklistId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateEditActionLogResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateEditActionLogResponse'] = ResolversParentTypes['CreateEditActionLogResponse']> = {
  isSuccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CriticalRouteChecklistResolvers<ContextType = any, ParentType extends ResolversParentTypes['CriticalRouteChecklist'] = ResolversParentTypes['CriticalRouteChecklist']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  answers?: Resolver<Array<Maybe<ResolversTypes['AnsweredCriticalRouteChecklist']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CrownChecklistMaterialsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CrownChecklistMaterialsResponse'] = ResolversParentTypes['CrownChecklistMaterialsResponse']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  driveLink?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  program?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  checklistMaterials?: Resolver<Array<Maybe<ResolversTypes['ChecklistMaterial']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CrownChecklistsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CrownChecklistsResponse'] = ResolversParentTypes['CrownChecklistsResponse']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  driveLink?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  program?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  checklists?: Resolver<Array<ResolversTypes['DPOAcademyChecklist']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DpoAcademyChecklistResolvers<ContextType = any, ParentType extends ResolversParentTypes['DPOAcademyChecklist'] = ResolversParentTypes['DPOAcademyChecklist']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  material?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  driveLink?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  endDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  response?: Resolver<Maybe<ResolversTypes['AnsweredDPOAcademyChecklist']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Date'], any> {
  name: 'Date';
}

export type DepartmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Department'] = ResolversParentTypes['Department']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nameEN?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  acronymEN?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  acronymES?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  subDepartments?: Resolver<Array<Maybe<ResolversTypes['SubDepartment']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmergencyContactResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmergencyContact'] = ResolversParentTypes['EmergencyContact']> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExternalCompanyResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExternalCompany'] = ResolversParentTypes['ExternalCompany']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FailedCertificateResolvers<ContextType = any, ParentType extends ResolversParentTypes['FailedCertificate'] = ResolversParentTypes['FailedCertificate']> = {
  answers?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ForkliftResolvers<ContextType = any, ParentType extends ResolversParentTypes['Forklift'] = ResolversParentTypes['Forklift']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  externalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isBlocked?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  forkliftArea?: Resolver<Maybe<ResolversTypes['ForkliftArea']>, ParentType, ContextType>;
  sapNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ForkliftAreaResolvers<ContextType = any, ParentType extends ResolversParentTypes['ForkliftArea'] = ResolversParentTypes['ForkliftArea']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalForklifts?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ForkliftRoutineResolvers<ContextType = any, ParentType extends ResolversParentTypes['ForkliftRoutine'] = ResolversParentTypes['ForkliftRoutine']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  approvedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  approvedBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  forklift?: Resolver<Maybe<ResolversTypes['Forklift']>, ParentType, ContextType>;
  routine?: Resolver<Maybe<ResolversTypes['Routine']>, ParentType, ContextType>;
  isCompliant?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isApproved?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GkpiDataResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['GKPIDataResponse'] = ResolversParentTypes['GKPIDataResponse']> = {
  GKPI?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  data?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GkpiExcelProcessingStatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['GKPIExcelProcessingStatus'] = ResolversParentTypes['GKPIExcelProcessingStatus']> = {
  status?: Resolver<ResolversTypes['GKPIFileProcessingStatus'], ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GkpiItemContentResolvers<ContextType = any, ParentType extends ResolversParentTypes['GKPIItemContent'] = ResolversParentTypes['GKPIItemContent']> = {
  AC?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  BU?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  AC_VS_LY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetAll5SChecklistCountByStatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetAll5SChecklistCountByStatus'] = ResolversParentTypes['GetAll5SChecklistCountByStatus']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetAllOwdChecklistsResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetAllOWDChecklists'] = ResolversParentTypes['GetAllOWDChecklists']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetModulesAndSubmodulesCountResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetModulesAndSubmodulesCountResponse'] = ResolversParentTypes['GetModulesAndSubmodulesCountResponse']> = {
  countByLevels?: Resolver<Array<Maybe<ResolversTypes['CountByLevels']>>, ParentType, ContextType>;
  countByAutonomousLevel?: Resolver<Array<Maybe<ResolversTypes['CountByAutonomousLevel']>>, ParentType, ContextType>;
  countByPosition?: Resolver<Array<Maybe<ResolversTypes['CountByPosition']>>, ParentType, ContextType>;
  countByMonth?: Resolver<Array<Maybe<ResolversTypes['MonthWiseScore']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetPdtAccessResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetPDTAccessResponse'] = ResolversParentTypes['GetPDTAccessResponse']> = {
  canAccessPDT?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetPostResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetPostResponse'] = ResolversParentTypes['GetPostResponse']> = {
  posts?: Resolver<Array<Maybe<ResolversTypes['Post']>>, ParentType, ContextType>;
  hasMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetTestResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetTest'] = ResolversParentTypes['GetTest']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetTruckDriversResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetTruckDriversResponse'] = ResolversParentTypes['GetTruckDriversResponse']> = {
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  routeId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  externalRouteId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HealthStatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['HealthStatus'] = ResolversParentTypes['HealthStatus']> = {
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImageInputItemTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ImageInputItemType'] = ResolversParentTypes['ImageInputItemType']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImpactedQuestionsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ImpactedQuestionsResponse'] = ResolversParentTypes['ImpactedQuestionsResponse']> = {
  sectionIndex?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  questionIndex?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IncidentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Incident'] = ResolversParentTypes['Incident']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  typeOfIssue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isUnsafe?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  doneByUserId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  supervisorId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  workLocationId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  riskTypeId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  riskLevel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  possibleSolution?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  locationDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  doneByString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  street?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  submittedByString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  submittedByUserId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  closedBy?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  closedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  source?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  riskTypeDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  chosenState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  chosenCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  outsideDistCenterIncidentType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pointOfSaleName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isExternalGuilty?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  vehicleIdentification?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  incidentDateTime?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  truckId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  assignedTo?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  locationType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subTypeOfIssue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  closingImage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  seenAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  assignedByUserId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  assignedAt?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  subLocationId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  postponedTill?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  locationId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  isSIF?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  category?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SAPControlNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  specificSubLocationId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  lastActivityAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  returnCause?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastActivityWas?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  evidenceClosureText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  returnedBy?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  folioNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  causeId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  immediateCauseId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IncidentBetweenRangeResolvers<ContextType = any, ParentType extends ResolversParentTypes['IncidentBetweenRange'] = ResolversParentTypes['IncidentBetweenRange']> = {
  bu?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  month?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  weeks?: Resolver<Array<Maybe<ResolversTypes['weekContent']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IncidentByCategoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['IncidentByCategory'] = ResolversParentTypes['IncidentByCategory']> = {
  categoryName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  bus?: Resolver<Array<Maybe<ResolversTypes['buContent']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IncidentByMonthResolvers<ContextType = any, ParentType extends ResolversParentTypes['IncidentByMonth'] = ResolversParentTypes['IncidentByMonth']> = {
  bu?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  months?: Resolver<Array<Maybe<ResolversTypes['buContent']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IncidentByTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['IncidentByType'] = ResolversParentTypes['IncidentByType']> = {
  bu?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  totalIncidents?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface JsonScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JSON'], any> {
  name: 'JSON';
}

export type KpiResolvers<ContextType = any, ParentType extends ResolversParentTypes['KPI'] = ResolversParentTypes['KPI']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isGlobal?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  enTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  esTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LatestChecklistAnsweredAtResolvers<ContextType = any, ParentType extends ResolversParentTypes['LatestChecklistAnsweredAt'] = ResolversParentTypes['LatestChecklistAnsweredAt']> = {
  at?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LatestCovidTestResolvers<ContextType = any, ParentType extends ResolversParentTypes['LatestCovidTest'] = ResolversParentTypes['LatestCovidTest']> = {
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ListSifStatsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ListSifStatsResponse'] = ResolversParentTypes['ListSifStatsResponse']> = {
  reference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  classificationOfInjury?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  incidentCause?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nature?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  department?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  incidentDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  shiftType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  incidentTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Location'] = ResolversParentTypes['Location']> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['LocationDetails'] = ResolversParentTypes['LocationDetails']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  subzone?: Resolver<ResolversTypes['Subzone'], ParentType, ContextType>;
  lat?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  long?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bu?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  area?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  countryEs?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasIncidentEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  emergencyContanct?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['LOCATION_TYPE']>, ParentType, ContextType>;
  riskLevel?: Resolver<Maybe<ResolversTypes['RISK_LEVEL']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationInfoByChecklistTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['LocationInfoByChecklistType'] = ResolversParentTypes['LocationInfoByChecklistType']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  checklistsToShow?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationNameResolvers<ContextType = any, ParentType extends ResolversParentTypes['LocationName'] = ResolversParentTypes['LocationName']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationSchemaResolvers<ContextType = any, ParentType extends ResolversParentTypes['LocationSchema'] = ResolversParentTypes['LocationSchema']> = {
  lat?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  long?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationWithActionLogCountResolvers<ContextType = any, ParentType extends ResolversParentTypes['LocationWithActionLogCount'] = ResolversParentTypes['LocationWithActionLogCount']> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cluster?: Resolver<Maybe<ResolversTypes['Cluster']>, ParentType, ContextType>;
  actionLogsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManagementToolResolvers<ContextType = any, ParentType extends ResolversParentTypes['ManagementTool'] = ResolversParentTypes['ManagementTool']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  enTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  esTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ModulePercentageResolvers<ContextType = any, ParentType extends ResolversParentTypes['ModulePercentage'] = ResolversParentTypes['ModulePercentage']> = {
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  percentage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MonthWiseScoreResolvers<ContextType = any, ParentType extends ResolversParentTypes['MonthWiseScore'] = ResolversParentTypes['MonthWiseScore']> = {
  month?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  score?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  _?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updateUserDoctorStatus?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationUpdateUserDoctorStatusArgs, 'slug' | 'doctorStatus'>>;
  addUserHealthStatus?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationAddUserHealthStatusArgs, 'status' | 'location'>>;
  addUserHealthStatusUnAuthenticated?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationAddUserHealthStatusUnAuthenticatedArgs, 'status' | 'location' | 'slug'>>;
  updateUserInfo?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationUpdateUserInfoArgs, 'id' | 'updatedByUserId'>>;
  updateForkliftIsBlocked?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationUpdateForkliftIsBlockedArgs, 'slug' | 'isBlocked'>>;
  updateForkliftApproval?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationUpdateForkliftApprovalArgs, 'forkliftRoutineId'>>;
  userTestDetails?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationUserTestDetailsArgs, 'slug' | 'id'>>;
  createCovidTest?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationCreateCovidTestArgs, 'slug' | 'testName'>>;
  approveTest?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationApproveTestArgs, 'id' | 'resultAt'>>;
  giveTestApproval?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationGiveTestApprovalArgs, 'id'>>;
  setAsEmergencyContact?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationSetAsEmergencyContactArgs, 'contactId'>>;
  addVaccinationInformation?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationAddVaccinationInformationArgs, never>>;
  updateVaccinationInformation?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationUpdateVaccinationInformationArgs, 'id' | 'givenAt' | 'approvedAt'>>;
  setVaccineApproval?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationSetVaccineApprovalArgs, 'id' | 'givenAt'>>;
  removeVaccination?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationRemoveVaccinationArgs, 'id'>>;
  createEditActionLog?: Resolver<ResolversTypes['CreateEditActionLogResponse'], ParentType, ContextType, RequireFields<MutationCreateEditActionLogArgs, never>>;
  updateActionLogStatus?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationUpdateActionLogStatusArgs, 'actionLogId' | 'status'>>;
  deleteActionLog?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationDeleteActionLogArgs, 'actionLogId'>>;
  addOrEditComment?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationAddOrEditCommentArgs, 'actionLogId'>>;
  deleteComment?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationDeleteCommentArgs, 'commentId'>>;
  createSignedS3UrlForGKPIUpload?: Resolver<ResolversTypes['S3SignedURL'], ParentType, ContextType>;
  startProcessingGKPIFile?: Resolver<ResolversTypes['GKPIExcelProcessingStatus'], ParentType, ContextType, RequireFields<MutationStartProcessingGkpiFileArgs, 'fileName'>>;
  updateSeenAt?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationUpdateSeenAtArgs, 'ids'>>;
  sendNotification?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationSendNotificationArgs, 'type'>>;
  createNote?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationCreateNoteArgs, 'input'>>;
  addOrEditCapexComment?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationAddOrEditCapexCommentArgs, 'capexId' | 'comment'>>;
  deleteCapexComment?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationDeleteCapexCommentArgs, 'commentId'>>;
  updateUserAccessLocation?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationUpdateUserAccessLocationArgs, 'locationIds'>>;
  updateWorkLocations?: Resolver<ResolversTypes['responseUpdateWorkLocations'], ParentType, ContextType, RequireFields<MutationUpdateWorkLocationsArgs, 'payload'>>;
  saveSKAPChecklist?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationSaveSkapChecklistArgs, 'args'>>;
  savePDTChecklist?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationSavePdtChecklistArgs, 'args'>>;
  createPDTChecklist?: Resolver<ResolversTypes['ID'], ParentType, ContextType, RequireFields<MutationCreatePdtChecklistArgs, never>>;
  addOrEditCommentOnChecklist?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationAddOrEditCommentOnChecklistArgs, 'args'>>;
  addSignatureToChecklist?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationAddSignatureToChecklistArgs, 'args'>>;
  deleteSignatureFromChecklist?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationDeleteSignatureFromChecklistArgs, 'args'>>;
  updateOperatorOfChecklist?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationUpdateOperatorOfChecklistArgs, 'args'>>;
  deleteOperatorsOfChecklist?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationDeleteOperatorsOfChecklistArgs, 'args'>>;
  updateStatusOfChecklist?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationUpdateStatusOfChecklistArgs, 'args'>>;
  updateSKAPChecklist?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationUpdateSkapChecklistArgs, 'args'>>;
  save5SChecklist?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationSave5SChecklistArgs, 'args'>>;
  savePDTSupportCertificate?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationSavePdtSupportCertificateArgs, never>>;
  saveOWDChecklist?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationSaveOwdChecklistArgs, never>>;
  saveDPOAcademyChecklistAnswer?: Resolver<ResolversTypes['corruptedPayloadIndexes'], ParentType, ContextType, RequireFields<MutationSaveDpoAcademyChecklistAnswerArgs, 'args'>>;
  discardCreatedActionLogsOfUncreatedOWD?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationDiscardCreatedActionLogsOfUncreatedOwdArgs, never>>;
  save3RSChecklistAnswer?: Resolver<ResolversTypes['Save3RSChecklistAnswerResponse'], ParentType, ContextType, RequireFields<MutationSave3RsChecklistAnswerArgs, 'args'>>;
  saveTeamRoomsChecklistAnswer?: Resolver<ResolversTypes['SaveTeamRoomsChecklistAnswerResponse'], ParentType, ContextType, RequireFields<MutationSaveTeamRoomsChecklistAnswerArgs, 'args'>>;
  saveWorkstationChecklistAnswer?: Resolver<ResolversTypes['SaveWorkstationChecklistAnswerResponse'], ParentType, ContextType, RequireFields<MutationSaveWorkstationChecklistAnswerArgs, 'args'>>;
  delete5SChecklists?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationDelete5SChecklistsArgs, 'args'>>;
  update5SChecklists?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationUpdate5SChecklistsArgs, 'args'>>;
  addOrEditGasTest?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationAddOrEditGasTestArgs, 'args'>>;
  addUpdateLocationInfoByChecklistType?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationAddUpdateLocationInfoByChecklistTypeArgs, 'locationId' | 'data'>>;
  saveRouteSafetyChecklistAnswer?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationSaveRouteSafetyChecklistAnswerArgs, 'args'>>;
  deleteRouteSafetyTruckDriver?: Resolver<Maybe<ResolversTypes['MutationStatus']>, ParentType, ContextType, RequireFields<MutationDeleteRouteSafetyTruckDriverArgs, 'driverUserId'>>;
  assignTruckDriver?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationAssignTruckDriverArgs, 'plannedRouteId'>>;
  assignTruck?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationAssignTruckArgs, 'plannedRouteId'>>;
  changeSecondDepartureStartedAtInPlannedRouteById?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType, RequireFields<MutationChangeSecondDepartureStartedAtInPlannedRouteByIdArgs, 'plannedRouteId' | 'secondDepartureStartedAt'>>;
};

export type MutationStatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['MutationStatus'] = ResolversParentTypes['MutationStatus']> = {
  isSuccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NoteResolvers<ContextType = any, ParentType extends ResolversParentTypes['Note'] = ResolversParentTypes['Note']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  bu?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['UserNote']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Notification'] = ResolversParentTypes['Notification']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  time?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  data?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  seenAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NumberOfContactsResolvers<ContextType = any, ParentType extends ResolversParentTypes['NumberOfContacts'] = ResolversParentTypes['NumberOfContacts']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OperatorSignatureResolvers<ContextType = any, ParentType extends ResolversParentTypes['OperatorSignature'] = ResolversParentTypes['OperatorSignature']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  signedUser?: Resolver<ResolversTypes['BaseUser'], ParentType, ContextType>;
  signature?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PdtChecklistResolvers<ContextType = any, ParentType extends ResolversParentTypes['PDTChecklist'] = ResolversParentTypes['PDTChecklist']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  checklistName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  checklistCreationDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  operators?: Resolver<Maybe<Array<Maybe<ResolversTypes['BaseUser']>>>, ParentType, ContextType>;
  checklistSignatures?: Resolver<Maybe<Array<Maybe<ResolversTypes['OperatorSignature']>>>, ParentType, ContextType>;
  checklistInfo?: Resolver<ResolversTypes['ChecklistInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PdtSupportCertificateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PDTSupportCertificate'] = ResolversParentTypes['PDTSupportCertificate']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  questions?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  answers?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PerformingBodyResolvers<ContextType = any, ParentType extends ResolversParentTypes['PerformingBody'] = ResolversParentTypes['PerformingBody']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nameEn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PillarResolvers<ContextType = any, ParentType extends ResolversParentTypes['Pillar'] = ResolversParentTypes['Pillar']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlannedRouteResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlannedRoute'] = ResolversParentTypes['PlannedRoute']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  route?: Resolver<Maybe<ResolversTypes['Route']>, ParentType, ContextType>;
  truck?: Resolver<Maybe<ResolversTypes['Truck']>, ParentType, ContextType>;
  primaryTruckDriver?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  secondaryTruckDriver?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  plannedTruckDriver?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  secondDepartureStartedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  executionDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlannedRouteWithAnswerResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlannedRouteWithAnswer'] = ResolversParentTypes['PlannedRouteWithAnswer']> = {
  plannedRoute?: Resolver<Maybe<ResolversTypes['PlannedRoute']>, ParentType, ContextType>;
  answers?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PostResolvers<ContextType = any, ParentType extends ResolversParentTypes['Post'] = ResolversParentTypes['Post']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bannerImage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['POSTS_TYPES']>, ParentType, ContextType>;
  attachments?: Resolver<Maybe<Array<Maybe<ResolversTypes['Attachment']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuarantineeInformationResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuarantineeInformation'] = ResolversParentTypes['QuarantineeInformation']> = {
  emergencyContact?: Resolver<Maybe<ResolversTypes['EmergencyContact']>, ParentType, ContextType>;
  numberOfContacts?: Resolver<Maybe<ResolversTypes['NumberOfContacts']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  _?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  users?: Resolver<Maybe<Array<ResolversTypes['User']>>, ParentType, ContextType>;
  getUserInfo?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<QueryGetUserInfoArgs, 'id'>>;
  userPositionsByBu?: Resolver<Maybe<Array<Maybe<ResolversTypes['userPositions']>>>, ParentType, ContextType, RequireFields<QueryUserPositionsByBuArgs, 'bu'>>;
  getUsersList?: Resolver<ResolversTypes['getUsersListPaginated'], ParentType, ContextType, RequireFields<QueryGetUsersListArgs, 'locationID'>>;
  quarantineeInformation?: Resolver<ResolversTypes['QuarantineeInformation'], ParentType, ContextType>;
  getCovidUsersForLocations?: Resolver<ResolversTypes['CovidUsersForLocationCount'], ParentType, ContextType, RequireFields<QueryGetCovidUsersForLocationsArgs, 'locationIds' | 'offset' | 'limit'>>;
  userHealthStatus?: Resolver<ResolversTypes['UserHealthStatus'], ParentType, ContextType, RequireFields<QueryUserHealthStatusArgs, never>>;
  userHealthStatusUnAuthenticated?: Resolver<ResolversTypes['UserHealthStatus'], ParentType, ContextType, RequireFields<QueryUserHealthStatusUnAuthenticatedArgs, 'slug'>>;
  searchCovidUsers?: Resolver<Array<Maybe<ResolversTypes['CovidUsersForLocation']>>, ParentType, ContextType, RequireFields<QuerySearchCovidUsersArgs, 'search' | 'locationIds'>>;
  searchUsersWithAccessToLocation?: Resolver<Maybe<Array<Maybe<ResolversTypes['BaseUser']>>>, ParentType, ContextType, RequireFields<QuerySearchUsersWithAccessToLocationArgs, 'searchString' | 'locationSlug'>>;
  getUserPositionsByBu?: Resolver<Array<Maybe<ResolversTypes['UserPositionsByBU']>>, ParentType, ContextType>;
  getUserUsualWorkLocation?: Resolver<Maybe<ResolversTypes['usualWorkLocation']>, ParentType, ContextType, RequireFields<QueryGetUserUsualWorkLocationArgs, never>>;
  forklift?: Resolver<Maybe<ResolversTypes['Forklift']>, ParentType, ContextType, RequireFields<QueryForkliftArgs, 'slug'>>;
  forkliftRoutine?: Resolver<Maybe<Array<Maybe<ResolversTypes['ForkliftRoutine']>>>, ParentType, ContextType, RequireFields<QueryForkliftRoutineArgs, 'pageSize'>>;
  getAllForklifts?: Resolver<Array<Maybe<ResolversTypes['Forklift']>>, ParentType, ContextType, RequireFields<QueryGetAllForkliftsArgs, never>>;
  forkliftAreasForChecklist?: Resolver<Maybe<Array<Maybe<ResolversTypes['ForkliftArea']>>>, ParentType, ContextType>;
  covidUsers?: Resolver<ResolversTypes['CovidUserAndCount'], ParentType, ContextType, RequireFields<QueryCovidUsersArgs, 'doctorStatus'>>;
  userCovidTestTimeline?: Resolver<ResolversTypes['TestTimeline'], ParentType, ContextType, RequireFields<QueryUserCovidTestTimelineArgs, 'slug'>>;
  emergencyContact?: Resolver<Maybe<ResolversTypes['EmergencyContact']>, ParentType, ContextType>;
  userVaccines?: Resolver<Array<Maybe<ResolversTypes['UserVaccine']>>, ParentType, ContextType, RequireFields<QueryUserVaccinesArgs, never>>;
  vaccinesForUserLocation?: Resolver<ResolversTypes['UserVaccinesForLocation'], ParentType, ContextType>;
  vaccinesPendingApproval?: Resolver<Maybe<ResolversTypes['UserVaccine']>, ParentType, ContextType>;
  percentageOfVaccinatedUsers?: Resolver<Maybe<ResolversTypes['UsersVaccinatedCount']>, ParentType, ContextType>;
  getUsersVaccineForType?: Resolver<Maybe<Array<Maybe<ResolversTypes['User']>>>, ParentType, ContextType, RequireFields<QueryGetUsersVaccineForTypeArgs, 'type'>>;
  actionLogs?: Resolver<Array<Maybe<ResolversTypes['CreateEditActionLog']>>, ParentType, ContextType, RequireFields<QueryActionLogsArgs, never>>;
  actionLog?: Resolver<Maybe<ResolversTypes['CreateEditActionLog']>, ParentType, ContextType, RequireFields<QueryActionLogArgs, 'id'>>;
  actionLogsCountGroupByLocation?: Resolver<Array<Maybe<ResolversTypes['BusinessUnitWithActionLogCount']>>, ParentType, ContextType, RequireFields<QueryActionLogsCountGroupByLocationArgs, 'startTime' | 'endTime'>>;
  groupActionLogsInDCByStatus?: Resolver<ResolversTypes['ActionLogsInDCByStatus'], ParentType, ContextType, RequireFields<QueryGroupActionLogsInDcByStatusArgs, 'startTime' | 'endTime'>>;
  comments?: Resolver<Array<Maybe<ResolversTypes['Comment']>>, ParentType, ContextType, RequireFields<QueryCommentsArgs, 'actionLogId'>>;
  comment?: Resolver<ResolversTypes['Comment'], ParentType, ContextType, RequireFields<QueryCommentArgs, 'actionLogId' | 'commentId'>>;
  location?: Resolver<ResolversTypes['LocationSchema'], ParentType, ContextType, RequireFields<QueryLocationArgs, 'slug'>>;
  getLocations?: Resolver<Array<Maybe<ResolversTypes['LocationDetails']>>, ParentType, ContextType, RequireFields<QueryGetLocationsArgs, never>>;
  locationDetails?: Resolver<ResolversTypes['LocationDetails'], ParentType, ContextType, RequireFields<QueryLocationDetailsArgs, never>>;
  userHasAccessToLocation?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<QueryUserHasAccessToLocationArgs, 'locationSlug'>>;
  getPost?: Resolver<Maybe<ResolversTypes['Post']>, ParentType, ContextType, RequireFields<QueryGetPostArgs, 'slug'>>;
  getPosts?: Resolver<Maybe<ResolversTypes['GetPostResponse']>, ParentType, ContextType, RequireFields<QueryGetPostsArgs, never>>;
  getUploadedFiles?: Resolver<Maybe<Array<Maybe<ResolversTypes['AnalyticsFileUpload']>>>, ParentType, ContextType, RequireFields<QueryGetUploadedFilesArgs, never>>;
  getLastUploadedFiles?: Resolver<ResolversTypes['AnalyticsFileUpload'], ParentType, ContextType>;
  getUploadedFilesByFileType?: Resolver<Maybe<Array<Maybe<ResolversTypes['AnalyticsFileUpload']>>>, ParentType, ContextType, RequireFields<QueryGetUploadedFilesByFileTypeArgs, 'fileType'>>;
  getLastUploadedFilesByFileType?: Resolver<ResolversTypes['AnalyticsFileUpload'], ParentType, ContextType, RequireFields<QueryGetLastUploadedFilesByFileTypeArgs, 'fileType'>>;
  getAllKPI?: Resolver<Array<Maybe<ResolversTypes['KPI']>>, ParentType, ContextType, RequireFields<QueryGetAllKpiArgs, never>>;
  getS3UrlForGKPIUpload?: Resolver<ResolversTypes['S3SignedURL'], ParentType, ContextType>;
  getGKPIData?: Resolver<Array<Maybe<ResolversTypes['GKPIDataResponse']>>, ParentType, ContextType, RequireFields<QueryGetGkpiDataArgs, never>>;
  getCountryAndLocationList?: Resolver<Maybe<Array<Maybe<ResolversTypes['CountryAndLocationList']>>>, ParentType, ContextType>;
  notifications?: Resolver<Array<Maybe<ResolversTypes['Notification']>>, ParentType, ContextType, RequireFields<QueryNotificationsArgs, never>>;
  notificationCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  getNotificationOfTypeByUserSlug?: Resolver<Maybe<ResolversTypes['Notification']>, ParentType, ContextType, RequireFields<QueryGetNotificationOfTypeByUserSlugArgs, never>>;
  getTrainings?: Resolver<Array<Maybe<ResolversTypes['BuData']>>, ParentType, ContextType, RequireFields<QueryGetTrainingsArgs, 'data' | 'by'>>;
  getNote?: Resolver<Maybe<ResolversTypes['Note']>, ParentType, ContextType, RequireFields<QueryGetNoteArgs, 'bu'>>;
  capexComments?: Resolver<Array<Maybe<ResolversTypes['Comment']>>, ParentType, ContextType, RequireFields<QueryCapexCommentsArgs, 'capexId'>>;
  capexComment?: Resolver<ResolversTypes['Comment'], ParentType, ContextType, RequireFields<QueryCapexCommentArgs, 'capexId' | 'commentId'>>;
  getSifStatsByDuration?: Resolver<Array<Maybe<ResolversTypes['SifStatsDuration']>>, ParentType, ContextType, RequireFields<QueryGetSifStatsByDurationArgs, 'monthNumber' | 'year' | 'filter'>>;
  getSifStats?: Resolver<Array<Maybe<ResolversTypes['SifStats']>>, ParentType, ContextType, RequireFields<QueryGetSifStatsArgs, 'filter' | 'by' | 'year'>>;
  getSifStatsByType?: Resolver<Array<Maybe<ResolversTypes['SifStatsByTypeResponse']>>, ParentType, ContextType, RequireFields<QueryGetSifStatsByTypeArgs, 'year'>>;
  listAllSifStatsByMonth?: Resolver<Array<Maybe<ResolversTypes['ListSifStatsResponse']>>, ParentType, ContextType, RequireFields<QueryListAllSifStatsByMonthArgs, 'monthNumber' | 'year' | 'filter'>>;
  lastFileUploadDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  getIncidentsByCategories?: Resolver<Array<Maybe<ResolversTypes['IncidentByCategory']>>, ParentType, ContextType, RequireFields<QueryGetIncidentsByCategoriesArgs, 'monthNumber' | 'year'>>;
  getIncidentsByMonths?: Resolver<Array<Maybe<ResolversTypes['IncidentByMonth']>>, ParentType, ContextType, RequireFields<QueryGetIncidentsByMonthsArgs, 'monthNumber' | 'year'>>;
  getIncidentsByType?: Resolver<Array<Maybe<ResolversTypes['IncidentByType']>>, ParentType, ContextType, RequireFields<QueryGetIncidentsByTypeArgs, 'by' | 'type' | 'monthNumber' | 'year'>>;
  getIncidentsBetweenRange?: Resolver<Array<Maybe<ResolversTypes['IncidentBetweenRange']>>, ParentType, ContextType, RequireFields<QueryGetIncidentsBetweenRangeArgs, 'monthNumber' | 'year'>>;
  getRoutinesAvailable?: Resolver<Array<Maybe<ResolversTypes['RoutineAvailable']>>, ParentType, ContextType, RequireFields<QueryGetRoutinesAvailableArgs, never>>;
  getAllPillar?: Resolver<Array<Maybe<ResolversTypes['Pillar']>>, ParentType, ContextType, RequireFields<QueryGetAllPillarArgs, never>>;
  getAllManagementTool?: Resolver<Array<Maybe<ResolversTypes['ManagementTool']>>, ParentType, ContextType>;
  getAccessLocations?: Resolver<Array<Maybe<ResolversTypes['LocationDetails']>>, ParentType, ContextType>;
  getAllAccessLocations?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType, RequireFields<QueryGetAllAccessLocationsArgs, 'id'>>;
  canAccessTeamRooms?: Resolver<ResolversTypes['CanAccessTeamRooms'], ParentType, ContextType>;
  getLocationsDepartmentAreaSubarea?: Resolver<Array<Maybe<ResolversTypes['locationsDepartmentAreaSubarea']>>, ParentType, ContextType, RequireFields<QueryGetLocationsDepartmentAreaSubareaArgs, 'locationIds'>>;
  getWorkLocations?: Resolver<Array<Maybe<ResolversTypes['workLocation']>>, ParentType, ContextType, RequireFields<QueryGetWorkLocationsArgs, 'location'>>;
  getSubLocations?: Resolver<Array<Maybe<ResolversTypes['subLocations']>>, ParentType, ContextType, RequireFields<QueryGetSubLocationsArgs, 'workLocation'>>;
  getSpecificSubLocations?: Resolver<Array<Maybe<ResolversTypes['specificSubLocations']>>, ParentType, ContextType, RequireFields<QueryGetSpecificSubLocationsArgs, 'subLocation'>>;
  getDepartmentAreaSubareaFiltersForSupply?: Resolver<ResolversTypes['userWorkLocationsFiltersForSupply'], ParentType, ContextType, RequireFields<QueryGetDepartmentAreaSubareaFiltersForSupplyArgs, 'userId'>>;
  getDepartments?: Resolver<Array<Maybe<ResolversTypes['Department']>>, ParentType, ContextType>;
  getExternalCompanies?: Resolver<Array<Maybe<ResolversTypes['ExternalCompany']>>, ParentType, ContextType>;
  searchExternalCompanies?: Resolver<Array<Maybe<ResolversTypes['ExternalCompany']>>, ParentType, ContextType, RequireFields<QuerySearchExternalCompaniesArgs, 'searchString'>>;
  getSKAPAvailableChecklistByName?: Resolver<ResolversTypes['ChecklistAvailable'], ParentType, ContextType, RequireFields<QueryGetSkapAvailableChecklistByNameArgs, 'userUnderEvaluationShortSlug' | 'name'>>;
  getPDTDefaultAvailableChecklist?: Resolver<ResolversTypes['ChecklistAvailable'], ParentType, ContextType>;
  getSKAPChecklistById?: Resolver<ResolversTypes['SKAPChecklist'], ParentType, ContextType, RequireFields<QueryGetSkapChecklistByIdArgs, 'id'>>;
  getPDTChecklistById?: Resolver<ResolversTypes['PDTChecklist'], ParentType, ContextType, RequireFields<QueryGetPdtChecklistByIdArgs, 'id'>>;
  getSKAPChecklistsOfUserUnderEvaluation?: Resolver<Array<ResolversTypes['UserUnderEvaluationResponse']>, ParentType, ContextType, RequireFields<QueryGetSkapChecklistsOfUserUnderEvaluationArgs, 'userUnderEvaluationShortSlug'>>;
  getUserChecklistsByMonth?: Resolver<Array<Maybe<ResolversTypes['UserChecklistsByMonth']>>, ParentType, ContextType>;
  getAll5SChecklistsOfMonthGroupedByStatus?: Resolver<Array<ResolversTypes['UserChecklistsByStatus']>, ParentType, ContextType, RequireFields<QueryGetAll5SChecklistsOfMonthGroupedByStatusArgs, never>>;
  getPDTSByCreationTypeAndPerformingBody?: Resolver<Array<Maybe<ResolversTypes['RegisteredPDT']>>, ParentType, ContextType, RequireFields<QueryGetPdtsByCreationTypeAndPerformingBodyArgs, 'args'>>;
  getSKAPModulesAndSubmodulesCount?: Resolver<ResolversTypes['GetModulesAndSubmodulesCountResponse'], ParentType, ContextType, RequireFields<QueryGetSkapModulesAndSubmodulesCountArgs, 'filter'>>;
  getPDTSupportCertificate?: Resolver<ResolversTypes['PDTSupportCertificate'], ParentType, ContextType, RequireFields<QueryGetPdtSupportCertificateArgs, 'args'>>;
  getAll5SChecklistCountByStatus?: Resolver<ResolversTypes['GetAll5SChecklistCountByStatus'], ParentType, ContextType, RequireFields<QueryGetAll5SChecklistCountByStatusArgs, 'status'>>;
  getAllOWDChecklists?: Resolver<Array<Maybe<ResolversTypes['GetAllOWDChecklists']>>, ParentType, ContextType, RequireFields<QueryGetAllOwdChecklistsArgs, 'args'>>;
  getPDTAccess?: Resolver<ResolversTypes['GetPDTAccessResponse'], ParentType, ContextType>;
  getDashboardTableData?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType, RequireFields<QueryGetDashboardTableDataArgs, 'locationIds'>>;
  fetchAllCrownChecklistsByUserSlug?: Resolver<Array<ResolversTypes['CrownChecklistsResponse']>, ParentType, ContextType, RequireFields<QueryFetchAllCrownChecklistsByUserSlugArgs, 'shortSlug' | 'userVersion'>>;
  fetchThreeRSChecklists?: Resolver<ResolversTypes['ThreeRSChecklist'], ParentType, ContextType, RequireFields<QueryFetchThreeRsChecklistsArgs, never>>;
  fetchAllCrownChecklistMaterialsByProgram?: Resolver<Array<ResolversTypes['CrownChecklistMaterialsResponse']>, ParentType, ContextType, RequireFields<QueryFetchAllCrownChecklistMaterialsByProgramArgs, 'programName'>>;
  fetchTeamRoomsChecklists?: Resolver<Array<Maybe<ResolversTypes['TeamRoomsChecklist']>>, ParentType, ContextType, RequireFields<QueryFetchTeamRoomsChecklistsArgs, never>>;
  fetchWorkstationChecklists?: Resolver<Array<Maybe<ResolversTypes['WorkstationChecklist']>>, ParentType, ContextType, RequireFields<QueryFetchWorkstationChecklistsArgs, never>>;
  getImpactedSKAPQuestionsOfUserUnderEvaluation?: Resolver<Array<Maybe<ResolversTypes['ImpactedQuestionsResponse']>>, ParentType, ContextType, RequireFields<QueryGetImpactedSkapQuestionsOfUserUnderEvaluationArgs, 'checklistAvailableId' | 'userUnderEvaluationShortSlug'>>;
  getAll5SByLocationId?: Resolver<Array<Maybe<ResolversTypes['JSON']>>, ParentType, ContextType, RequireFields<QueryGetAll5SByLocationIdArgs, 'locationId'>>;
  getGasTestsByChecklistId?: Resolver<Array<Maybe<ResolversTypes['GetTest']>>, ParentType, ContextType, RequireFields<QueryGetGasTestsByChecklistIdArgs, 'checklistId'>>;
  getAllTrucks?: Resolver<Array<Maybe<ResolversTypes['Truck']>>, ParentType, ContextType>;
  getAllTrucksByLocationId?: Resolver<Array<Maybe<ResolversTypes['Truck']>>, ParentType, ContextType, RequireFields<QueryGetAllTrucksByLocationIdArgs, 'locationId'>>;
  getAllTrucksByLocationSlug?: Resolver<Array<Maybe<ResolversTypes['Truck']>>, ParentType, ContextType, RequireFields<QueryGetAllTrucksByLocationSlugArgs, 'locationSlug'>>;
  getLastUploadedAtTimestamp?: Resolver<Maybe<ResolversTypes['Truck']>, ParentType, ContextType, RequireFields<QueryGetLastUploadedAtTimestampArgs, 'locationSlug'>>;
  getUserWorkLocations?: Resolver<Array<Maybe<ResolversTypes['userWorkLocations']>>, ParentType, ContextType, RequireFields<QueryGetUserWorkLocationsArgs, 'user'>>;
  getAllLocationsUserPositions?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  getDepartmentAreaSubArea?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType, RequireFields<QueryGetDepartmentAreaSubAreaArgs, 'id'>>;
  getIncidentsByLastHour?: Resolver<Maybe<ResolversTypes['getIncidentsByLastHourResponse']>, ParentType, ContextType, RequireFields<QueryGetIncidentsByLastHourArgs, 'userId'>>;
  fetchLocationInfoByChecklistType?: Resolver<ResolversTypes['LocationInfoByChecklistType'], ParentType, ContextType, RequireFields<QueryFetchLocationInfoByChecklistTypeArgs, 'locationId'>>;
  getExternalRoutesByLocationSlug?: Resolver<Maybe<Array<Maybe<ResolversTypes['Route']>>>, ParentType, ContextType, RequireFields<QueryGetExternalRoutesByLocationSlugArgs, 'locationSlug'>>;
  getExternalRoutesByLocationId?: Resolver<Maybe<Array<Maybe<ResolversTypes['Route']>>>, ParentType, ContextType, RequireFields<QueryGetExternalRoutesByLocationIdArgs, 'locationId'>>;
  getExternalRouteByRouteId?: Resolver<Maybe<ResolversTypes['Route']>, ParentType, ContextType, RequireFields<QueryGetExternalRouteByRouteIdArgs, 'routeId'>>;
  getRouteSafetyChecklistAnswerByRouteId?: Resolver<ResolversTypes['CriticalRouteChecklist'], ParentType, ContextType, RequireFields<QueryGetRouteSafetyChecklistAnswerByRouteIdArgs, 'routeId'>>;
  getRouteSafetyChecklistAnswerByLocationSlug?: Resolver<ResolversTypes['CriticalRouteChecklist'], ParentType, ContextType, RequireFields<QueryGetRouteSafetyChecklistAnswerByLocationSlugArgs, 'locationSlug'>>;
  getRouteSafetyChecklistByLocationSlug?: Resolver<Array<ResolversTypes['JSON']>, ParentType, ContextType, RequireFields<QueryGetRouteSafetyChecklistByLocationSlugArgs, 'locationSlug'>>;
  getRouteSafetyTruckDriversByLocationSlug?: Resolver<Maybe<Array<Maybe<ResolversTypes['User']>>>, ParentType, ContextType, RequireFields<QueryGetRouteSafetyTruckDriversByLocationSlugArgs, 'locationSlug'>>;
  getLastUpdateAtForTruckDriversUpload?: Resolver<Maybe<ResolversTypes['UserInfo']>, ParentType, ContextType, RequireFields<QueryGetLastUpdateAtForTruckDriversUploadArgs, 'locationSlug'>>;
  getPlannedRoutesByLocationSlug?: Resolver<Maybe<Array<Maybe<ResolversTypes['PlannedRoute']>>>, ParentType, ContextType, RequireFields<QueryGetPlannedRoutesByLocationSlugArgs, 'locationSlug'>>;
  getIsTruckAvailableByTruckId?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<QueryGetIsTruckAvailableByTruckIdArgs, 'truckId'>>;
  getTruckDrivers?: Resolver<Maybe<Array<Maybe<ResolversTypes['GetTruckDriversResponse']>>>, ParentType, ContextType>;
  getLatestChecklistAnsweredAtByLocationSlug?: Resolver<ResolversTypes['LatestChecklistAnsweredAt'], ParentType, ContextType, RequireFields<QueryGetLatestChecklistAnsweredAtByLocationSlugArgs, 'locationSlug'>>;
  getAptTruckDriversByLocationId?: Resolver<Maybe<Array<Maybe<ResolversTypes['User']>>>, ParentType, ContextType, RequireFields<QueryGetAptTruckDriversByLocationIdArgs, 'locationId' | 'routeId'>>;
  getPlannedRouteWithAnswerByRouteId?: Resolver<Maybe<ResolversTypes['PlannedRouteWithAnswer']>, ParentType, ContextType, RequireFields<QueryGetPlannedRouteWithAnswerByRouteIdArgs, 'routeId'>>;
  checkAppUpdateRequired?: Resolver<Maybe<ResolversTypes['checkAppUpdateRequiredResponse']>, ParentType, ContextType, RequireFields<QueryCheckAppUpdateRequiredArgs, 'versionNo' | 'platform'>>;
};

export type RegisteredPdtResolvers<ContextType = any, ParentType extends ResolversParentTypes['RegisteredPDT'] = ResolversParentTypes['RegisteredPDT']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  checklistName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  performingBody?: Resolver<ResolversTypes['PerformingBody'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  externalUsers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  checklistDepartment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RegisteredPdtsResolvers<ContextType = any, ParentType extends ResolversParentTypes['RegisteredPDTS'] = ResolversParentTypes['RegisteredPDTS']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  checklistName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  externalUsers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  checklistInfo?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RejectedChecklistResolvers<ContextType = any, ParentType extends ResolversParentTypes['RejectedChecklist'] = ResolversParentTypes['RejectedChecklist']> = {
  index?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RouteResolvers<ContextType = any, ParentType extends ResolversParentTypes['Route'] = ResolversParentTypes['Route']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalRouteId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  content?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  checklistContent?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoutineResolvers<ContextType = any, ParentType extends ResolversParentTypes['Routine'] = ResolversParentTypes['Routine']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  answers?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  userRoutine?: Resolver<ResolversTypes['UserRoutine'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoutineAvailableResolvers<ContextType = any, ParentType extends ResolversParentTypes['RoutineAvailable'] = ResolversParentTypes['RoutineAvailable']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  frequency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isActive?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type S3SignedUrlResolvers<ContextType = any, ParentType extends ResolversParentTypes['S3SignedURL'] = ResolversParentTypes['S3SignedURL']> = {
  preSignedUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fileName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SkapChecklistResolvers<ContextType = any, ParentType extends ResolversParentTypes['SKAPChecklist'] = ResolversParentTypes['SKAPChecklist']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  checklistOperators?: Resolver<Array<ResolversTypes['BaseUser']>, ParentType, ContextType>;
  checklistInfo?: Resolver<Maybe<ResolversTypes['ChecklistInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Save3RsChecklistAnswerResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['Save3RSChecklistAnswerResponse'] = ResolversParentTypes['Save3RSChecklistAnswerResponse']> = {
  corruptedPayloadIndexes?: Resolver<Array<ResolversTypes['Int']>, ParentType, ContextType>;
  rejectedChecklist?: Resolver<Maybe<ResolversTypes['RejectedChecklist']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SaveTeamRoomsChecklistAnswerResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SaveTeamRoomsChecklistAnswerResponse'] = ResolversParentTypes['SaveTeamRoomsChecklistAnswerResponse']> = {
  corruptedPayloadIndexes?: Resolver<Array<ResolversTypes['Int']>, ParentType, ContextType>;
  rejectedChecklist?: Resolver<Maybe<ResolversTypes['RejectedChecklist']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SaveWorkstationChecklistAnswerResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SaveWorkstationChecklistAnswerResponse'] = ResolversParentTypes['SaveWorkstationChecklistAnswerResponse']> = {
  corruptedPayloadIndexes?: Resolver<Array<ResolversTypes['Int']>, ParentType, ContextType>;
  rejectedChecklist?: Resolver<Maybe<ResolversTypes['RejectedChecklist']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SifStatsResolvers<ContextType = any, ParentType extends ResolversParentTypes['SifStats'] = ResolversParentTypes['SifStats']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  totalSif?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SifStatsByTypeResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SifStatsByTypeResponse'] = ResolversParentTypes['SifStatsByTypeResponse']> = {
  month?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sifType?: Resolver<Array<Maybe<ResolversTypes['SifStats']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SifStatsDurationResolvers<ContextType = any, ParentType extends ResolversParentTypes['SifStatsDuration'] = ResolversParentTypes['SifStatsDuration']> = {
  duration?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  totalSif?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubDepartmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubDepartment'] = ResolversParentTypes['SubDepartment']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  departmentId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nameEN?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  acronymEN?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  acronymES?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubLocationDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubLocationDetails'] = ResolversParentTypes['SubLocationDetails']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nameEN?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubModulesDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubModulesData'] = ResolversParentTypes['SubModulesData']> = {
  content?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  isCompleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  progressPercentage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  checklistAvailableId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  checklistId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  completedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  startedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  isAnswered?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubzoneResolvers<ContextType = any, ParentType extends ResolversParentTypes['Subzone'] = ResolversParentTypes['Subzone']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  zone?: Resolver<ResolversTypes['Zone'], ParentType, ContextType>;
  distributionCenters?: Resolver<Maybe<Array<Maybe<ResolversTypes['Location']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubzoneWithActionLogCountResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubzoneWithActionLogCount'] = ResolversParentTypes['SubzoneWithActionLogCount']> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  actionLogsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  locations?: Resolver<Maybe<Array<Maybe<ResolversTypes['LocationWithActionLogCount']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TeamRoomsChecklistResolvers<ContextType = any, ParentType extends ResolversParentTypes['TeamRoomsChecklist'] = ResolversParentTypes['TeamRoomsChecklist']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  answered?: Resolver<Array<Maybe<ResolversTypes['AnsweredThreeRSChecklist']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TestTimelineResolvers<ContextType = any, ParentType extends ResolversParentTypes['TestTimeline'] = ResolversParentTypes['TestTimeline']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  picture?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  doctorStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  quarantineUntil?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  quarantineStart?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  covidTests?: Resolver<Maybe<Array<Maybe<ResolversTypes['CovidTest']>>>, ParentType, ContextType>;
  failedCertificate?: Resolver<Maybe<ResolversTypes['FailedCertificate']>, ParentType, ContextType>;
  userHealthStatus?: Resolver<Maybe<ResolversTypes['HealthStatus']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ThreeRsChecklistResolvers<ContextType = any, ParentType extends ResolversParentTypes['ThreeRSChecklist'] = ResolversParentTypes['ThreeRSChecklist']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  answered?: Resolver<Array<Maybe<ResolversTypes['AnsweredThreeRSChecklist']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TruckResolvers<ContextType = any, ParentType extends ResolversParentTypes['Truck'] = ResolversParentTypes['Truck']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  sapNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  plateNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  locationId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  content?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  plannedRoute?: Resolver<Maybe<ResolversTypes['PlannedRoute']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface UploadScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Upload'], any> {
  name: 'Upload';
}

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isBlocked?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  shortSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  picture?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pin?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  blockedReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastActiveAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  doctorStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  employeeNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  quarantineUntil?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  distributionCenter?: Resolver<Maybe<ResolversTypes['LocationDetails']>, ParentType, ContextType>;
  covidTest?: Resolver<Maybe<ResolversTypes['CovidTest']>, ParentType, ContextType>;
  vaccines?: Resolver<Maybe<Array<Maybe<ResolversTypes['UserVaccine']>>>, ParentType, ContextType>;
  lastVaccine?: Resolver<Maybe<ResolversTypes['UserVaccine']>, ParentType, ContextType>;
  info?: Resolver<Maybe<ResolversTypes['UserInfo']>, ParentType, ContextType>;
  userRole?: Resolver<Maybe<ResolversTypes['userRole']>, ParentType, ContextType>;
  startWorkingDay?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  autoAssignSios?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserAccessDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserAccessDetails'] = ResolversParentTypes['UserAccessDetails']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  distributionCenter?: Resolver<ResolversTypes['LocationDetails'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserChecklistsByMonthResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserChecklistsByMonth'] = ResolversParentTypes['UserChecklistsByMonth']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<Array<Maybe<ResolversTypes['UserChecklistsResponseValue']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserChecklistsByStatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserChecklistsByStatus'] = ResolversParentTypes['UserChecklistsByStatus']> = {
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<Array<Maybe<ResolversTypes['UserChecklistsResponseValue']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserChecklistsResponseValueResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserChecklistsResponseValue'] = ResolversParentTypes['UserChecklistsResponseValue']> = {
  checklistId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  checklistsAvailableId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  submittedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  answeredContent?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  content?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  workLocation?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  subWorkLocation?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  appliedBy?: Resolver<ResolversTypes['BaseUser'], ParentType, ContextType>;
  assignedTo?: Resolver<Maybe<ResolversTypes['BaseUser']>, ParentType, ContextType>;
  truck?: Resolver<Maybe<ResolversTypes['Truck']>, ParentType, ContextType>;
  forklift?: Resolver<Maybe<ResolversTypes['Forklift']>, ParentType, ContextType>;
  sapNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  numberOfActionLogs?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserHealthStatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserHealthStatus'] = ResolversParentTypes['UserHealthStatus']> = {
  quarantineUntil?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  quarantineStart?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  healthStatus?: Resolver<Maybe<ResolversTypes['HealthStatus']>, ParentType, ContextType>;
  usersLocationMedicalFacility?: Resolver<Maybe<Array<Maybe<ResolversTypes['UsersLocationMedicalFacility']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserInfo'] = ResolversParentTypes['UserInfo']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  content?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  latestVersion?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  isAuthorisedForOWD?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  visitorType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserNoteResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserNote'] = ResolversParentTypes['UserNote']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserPositionsByBuResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserPositionsByBU'] = ResolversParentTypes['UserPositionsByBU']> = {
  position?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  bu?: Resolver<ResolversTypes['BusinessUnit'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserRoutineResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserRoutine'] = ResolversParentTypes['UserRoutine']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserUnderEvaluationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserUnderEvaluationResponse'] = ResolversParentTypes['UserUnderEvaluationResponse']> = {
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  progressPercentage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isCompleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  startedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  subModules?: Resolver<Array<ResolversTypes['SubModulesData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserVaccineResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserVaccine'] = ResolversParentTypes['UserVaccine']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vaccinatedUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  vaccinationGivenBy?: Resolver<Maybe<ResolversTypes['BaseUser']>, ParentType, ContextType>;
  givenAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  approvedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isApproved?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserVaccinesForLocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserVaccinesForLocation'] = ResolversParentTypes['UserVaccinesForLocation']> = {
  users?: Resolver<Maybe<Array<Maybe<ResolversTypes['User']>>>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersLocationMedicalFacilityResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersLocationMedicalFacility'] = ResolversParentTypes['UsersLocationMedicalFacility']> = {
  emergencyContact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersVaccinatedCountResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersVaccinatedCount'] = ResolversParentTypes['UsersVaccinatedCount']> = {
  vaccinated?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalUsers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface VoidScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Void'], any> {
  name: 'Void';
}

export type WorkLocationDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkLocationDetails'] = ResolversParentTypes['WorkLocationDetails']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  locationType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nameEN?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkstationChecklistResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkstationChecklist'] = ResolversParentTypes['WorkstationChecklist']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  answered?: Resolver<Array<Maybe<ResolversTypes['AnsweredWorkstationChecklist']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ZoneResolvers<ContextType = any, ParentType extends ResolversParentTypes['Zone'] = ResolversParentTypes['Zone']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  businessUnit?: Resolver<Maybe<ResolversTypes['BusinessUnit']>, ParentType, ContextType>;
  subzones?: Resolver<Maybe<Array<Maybe<ResolversTypes['Subzone']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ZoneWithActionLogCountResolvers<ContextType = any, ParentType extends ResolversParentTypes['ZoneWithActionLogCount'] = ResolversParentTypes['ZoneWithActionLogCount']> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  actionLogsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  subzones?: Resolver<Maybe<Array<Maybe<ResolversTypes['SubzoneWithActionLogCount']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AreaResolvers<ContextType = any, ParentType extends ResolversParentTypes['area'] = ResolversParentTypes['area']> = {
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nameEN?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subAreas?: Resolver<Maybe<Array<Maybe<ResolversTypes['subArea']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BuContentResolvers<ContextType = any, ParentType extends ResolversParentTypes['buContent'] = ResolversParentTypes['buContent']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  totalIncidents?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CheckAppUpdateRequiredResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['checkAppUpdateRequiredResponse'] = ResolversParentTypes['checkAppUpdateRequiredResponse']> = {
  isUpdateRequired?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CorruptedPayloadIndexesResolvers<ContextType = any, ParentType extends ResolversParentTypes['corruptedPayloadIndexes'] = ResolversParentTypes['corruptedPayloadIndexes']> = {
  corruptedPayloadIndexes?: Resolver<Array<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DistributionCenterWorkLocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['distributionCenterWorkLocation'] = ResolversParentTypes['distributionCenterWorkLocation']> = {
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ErrorUpdateWorkLocationsDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['errorUpdateWorkLocationsDetails'] = ResolversParentTypes['errorUpdateWorkLocationsDetails']> = {
  areaError?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  subAreaError?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  subLocationError?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetIncidentsByLastHourResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['getIncidentsByLastHourResponse'] = ResolversParentTypes['getIncidentsByLastHourResponse']> = {
  hasReachedLimit?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  firstIncidentDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetUsersListPaginatedResolvers<ContextType = any, ParentType extends ResolversParentTypes['getUsersListPaginated'] = ResolversParentTypes['getUsersListPaginated']> = {
  users?: Resolver<Maybe<Array<Maybe<ResolversTypes['User']>>>, ParentType, ContextType>;
  paginationInfo?: Resolver<ResolversTypes['paginationInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationsDepartmentAreaSubareaResolvers<ContextType = any, ParentType extends ResolversParentTypes['locationsDepartmentAreaSubarea'] = ResolversParentTypes['locationsDepartmentAreaSubarea']> = {
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  departments?: Resolver<Maybe<Array<Maybe<ResolversTypes['workLocation']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaginationInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['paginationInfo'] = ResolversParentTypes['paginationInfo']> = {
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  currentPage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalPages?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReasonResolvers<ContextType = any, ParentType extends ResolversParentTypes['reason'] = ResolversParentTypes['reason']> = {
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResponseUpdateWorkLocationsResolvers<ContextType = any, ParentType extends ResolversParentTypes['responseUpdateWorkLocations'] = ResolversParentTypes['responseUpdateWorkLocations']> = {
  MutationStatus?: Resolver<ResolversTypes['MutationStatus'], ParentType, ContextType>;
  Error?: Resolver<Maybe<ResolversTypes['errorUpdateWorkLocationsDetails']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoutinesAvailableNameAndSlugResolvers<ContextType = any, ParentType extends ResolversParentTypes['routinesAvailableNameAndSlug'] = ResolversParentTypes['routinesAvailableNameAndSlug']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpecificSubLocationsResolvers<ContextType = any, ParentType extends ResolversParentTypes['specificSubLocations'] = ResolversParentTypes['specificSubLocations']> = {
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nameEn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  areaId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  areaSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  departmentId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  departmentSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SubArea?: Resolver<Maybe<ResolversTypes['subLocationSpecificSubLocation']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatusReasonContentResolvers<ContextType = any, ParentType extends ResolversParentTypes['statusReasonContent'] = ResolversParentTypes['statusReasonContent']> = {
  reason?: Resolver<Array<Maybe<ResolversTypes['reason']>>, ParentType, ContextType>;
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubAreaResolvers<ContextType = any, ParentType extends ResolversParentTypes['subArea'] = ResolversParentTypes['subArea']> = {
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nameEn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubLocationSpecificSubLocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['subLocationSpecificSubLocation'] = ResolversParentTypes['subLocationSpecificSubLocation']> = {
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  workLocation?: Resolver<Maybe<ResolversTypes['workLocationSubLocation']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubLocationsResolvers<ContextType = any, ParentType extends ResolversParentTypes['subLocations'] = ResolversParentTypes['subLocations']> = {
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nameEN?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  departmentId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  departmentSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  workLocation?: Resolver<Maybe<ResolversTypes['workLocationSubLocation']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserPositionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['userPositions'] = ResolversParentTypes['userPositions']> = {
  position?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserRoleResolvers<ContextType = any, ParentType extends ResolversParentTypes['userRole'] = ResolversParentTypes['userRole']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  category?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserWorkLocationsResolvers<ContextType = any, ParentType extends ResolversParentTypes['userWorkLocations'] = ResolversParentTypes['userWorkLocations']> = {
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  workLocation?: Resolver<Maybe<ResolversTypes['workLocation']>, ParentType, ContextType>;
  subLocation?: Resolver<Maybe<ResolversTypes['area']>, ParentType, ContextType>;
  subArea?: Resolver<Maybe<ResolversTypes['subArea']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserWorkLocationsFiltersForSupplyResolvers<ContextType = any, ParentType extends ResolversParentTypes['userWorkLocationsFiltersForSupply'] = ResolversParentTypes['userWorkLocationsFiltersForSupply']> = {
  departments?: Resolver<Maybe<Array<Maybe<ResolversTypes['workLocation']>>>, ParentType, ContextType>;
  areas?: Resolver<Maybe<Array<Maybe<ResolversTypes['subLocations']>>>, ParentType, ContextType>;
  subAreas?: Resolver<Maybe<Array<Maybe<ResolversTypes['specificSubLocations']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsualWorkLocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['usualWorkLocation'] = ResolversParentTypes['usualWorkLocation']> = {
  country?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  businessUnit?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  location?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WeekContentResolvers<ContextType = any, ParentType extends ResolversParentTypes['weekContent'] = ResolversParentTypes['weekContent']> = {
  week?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  totalIncidents?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkLocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['workLocation'] = ResolversParentTypes['workLocation']> = {
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nameEN?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  locationId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  DEPRECATED_distributionCenter?: Resolver<Maybe<ResolversTypes['distributionCenterWorkLocation']>, ParentType, ContextType>;
  areas?: Resolver<Maybe<Array<Maybe<ResolversTypes['area']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkLocationSubLocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['workLocationSubLocation'] = ResolversParentTypes['workLocationSubLocation']> = {
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DEPRECATED_distributionCenter?: Resolver<Maybe<ResolversTypes['distributionCenterWorkLocation']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  ActionLogsInDCByStatus?: ActionLogsInDcByStatusResolvers<ContextType>;
  AnalyticsFileUpload?: AnalyticsFileUploadResolvers<ContextType>;
  AnsweredCriticalRouteChecklist?: AnsweredCriticalRouteChecklistResolvers<ContextType>;
  AnsweredDPOAcademyChecklist?: AnsweredDpoAcademyChecklistResolvers<ContextType>;
  AnsweredTeamRoomsChecklist?: AnsweredTeamRoomsChecklistResolvers<ContextType>;
  AnsweredThreeRSChecklist?: AnsweredThreeRsChecklistResolvers<ContextType>;
  AnsweredWorkstationChecklist?: AnsweredWorkstationChecklistResolvers<ContextType>;
  Attachment?: AttachmentResolvers<ContextType>;
  BaseLocation?: BaseLocationResolvers<ContextType>;
  BaseUser?: BaseUserResolvers<ContextType>;
  BuData?: BuDataResolvers<ContextType>;
  BusinessUnit?: BusinessUnitResolvers<ContextType>;
  BusinessUnitWithActionLogCount?: BusinessUnitWithActionLogCountResolvers<ContextType>;
  CanAccessTeamRooms?: CanAccessTeamRoomsResolvers<ContextType>;
  ChecklistAvailable?: ChecklistAvailableResolvers<ContextType>;
  ChecklistInfo?: ChecklistInfoResolvers<ContextType>;
  ChecklistMaterial?: ChecklistMaterialResolvers<ContextType>;
  Cluster?: ClusterResolvers<ContextType>;
  Comment?: CommentResolvers<ContextType>;
  CountByAutonomousLevel?: CountByAutonomousLevelResolvers<ContextType>;
  CountByLevels?: CountByLevelsResolvers<ContextType>;
  CountByPosition?: CountByPositionResolvers<ContextType>;
  CountryAndLocationList?: CountryAndLocationListResolvers<ContextType>;
  CovidTest?: CovidTestResolvers<ContextType>;
  CovidUser?: CovidUserResolvers<ContextType>;
  CovidUserAndCount?: CovidUserAndCountResolvers<ContextType>;
  CovidUsersForLocation?: CovidUsersForLocationResolvers<ContextType>;
  CovidUsersForLocationCount?: CovidUsersForLocationCountResolvers<ContextType>;
  CreateEditActionLog?: CreateEditActionLogResolvers<ContextType>;
  CreateEditActionLogResponse?: CreateEditActionLogResponseResolvers<ContextType>;
  CriticalRouteChecklist?: CriticalRouteChecklistResolvers<ContextType>;
  CrownChecklistMaterialsResponse?: CrownChecklistMaterialsResponseResolvers<ContextType>;
  CrownChecklistsResponse?: CrownChecklistsResponseResolvers<ContextType>;
  DPOAcademyChecklist?: DpoAcademyChecklistResolvers<ContextType>;
  Date?: GraphQLScalarType;
  Department?: DepartmentResolvers<ContextType>;
  EmergencyContact?: EmergencyContactResolvers<ContextType>;
  ExternalCompany?: ExternalCompanyResolvers<ContextType>;
  FailedCertificate?: FailedCertificateResolvers<ContextType>;
  Forklift?: ForkliftResolvers<ContextType>;
  ForkliftArea?: ForkliftAreaResolvers<ContextType>;
  ForkliftRoutine?: ForkliftRoutineResolvers<ContextType>;
  GKPIDataResponse?: GkpiDataResponseResolvers<ContextType>;
  GKPIExcelProcessingStatus?: GkpiExcelProcessingStatusResolvers<ContextType>;
  GKPIItemContent?: GkpiItemContentResolvers<ContextType>;
  GetAll5SChecklistCountByStatus?: GetAll5SChecklistCountByStatusResolvers<ContextType>;
  GetAllOWDChecklists?: GetAllOwdChecklistsResolvers<ContextType>;
  GetModulesAndSubmodulesCountResponse?: GetModulesAndSubmodulesCountResponseResolvers<ContextType>;
  GetPDTAccessResponse?: GetPdtAccessResponseResolvers<ContextType>;
  GetPostResponse?: GetPostResponseResolvers<ContextType>;
  GetTest?: GetTestResolvers<ContextType>;
  GetTruckDriversResponse?: GetTruckDriversResponseResolvers<ContextType>;
  HealthStatus?: HealthStatusResolvers<ContextType>;
  ImageInputItemType?: ImageInputItemTypeResolvers<ContextType>;
  ImpactedQuestionsResponse?: ImpactedQuestionsResponseResolvers<ContextType>;
  Incident?: IncidentResolvers<ContextType>;
  IncidentBetweenRange?: IncidentBetweenRangeResolvers<ContextType>;
  IncidentByCategory?: IncidentByCategoryResolvers<ContextType>;
  IncidentByMonth?: IncidentByMonthResolvers<ContextType>;
  IncidentByType?: IncidentByTypeResolvers<ContextType>;
  JSON?: GraphQLScalarType;
  KPI?: KpiResolvers<ContextType>;
  LatestChecklistAnsweredAt?: LatestChecklistAnsweredAtResolvers<ContextType>;
  LatestCovidTest?: LatestCovidTestResolvers<ContextType>;
  ListSifStatsResponse?: ListSifStatsResponseResolvers<ContextType>;
  Location?: LocationResolvers<ContextType>;
  LocationDetails?: LocationDetailsResolvers<ContextType>;
  LocationInfoByChecklistType?: LocationInfoByChecklistTypeResolvers<ContextType>;
  LocationName?: LocationNameResolvers<ContextType>;
  LocationSchema?: LocationSchemaResolvers<ContextType>;
  LocationWithActionLogCount?: LocationWithActionLogCountResolvers<ContextType>;
  ManagementTool?: ManagementToolResolvers<ContextType>;
  ModulePercentage?: ModulePercentageResolvers<ContextType>;
  MonthWiseScore?: MonthWiseScoreResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  MutationStatus?: MutationStatusResolvers<ContextType>;
  Note?: NoteResolvers<ContextType>;
  Notification?: NotificationResolvers<ContextType>;
  NumberOfContacts?: NumberOfContactsResolvers<ContextType>;
  OperatorSignature?: OperatorSignatureResolvers<ContextType>;
  PDTChecklist?: PdtChecklistResolvers<ContextType>;
  PDTSupportCertificate?: PdtSupportCertificateResolvers<ContextType>;
  PerformingBody?: PerformingBodyResolvers<ContextType>;
  Pillar?: PillarResolvers<ContextType>;
  PlannedRoute?: PlannedRouteResolvers<ContextType>;
  PlannedRouteWithAnswer?: PlannedRouteWithAnswerResolvers<ContextType>;
  Post?: PostResolvers<ContextType>;
  QuarantineeInformation?: QuarantineeInformationResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  RegisteredPDT?: RegisteredPdtResolvers<ContextType>;
  RegisteredPDTS?: RegisteredPdtsResolvers<ContextType>;
  RejectedChecklist?: RejectedChecklistResolvers<ContextType>;
  Route?: RouteResolvers<ContextType>;
  Routine?: RoutineResolvers<ContextType>;
  RoutineAvailable?: RoutineAvailableResolvers<ContextType>;
  S3SignedURL?: S3SignedUrlResolvers<ContextType>;
  SKAPChecklist?: SkapChecklistResolvers<ContextType>;
  Save3RSChecklistAnswerResponse?: Save3RsChecklistAnswerResponseResolvers<ContextType>;
  SaveTeamRoomsChecklistAnswerResponse?: SaveTeamRoomsChecklistAnswerResponseResolvers<ContextType>;
  SaveWorkstationChecklistAnswerResponse?: SaveWorkstationChecklistAnswerResponseResolvers<ContextType>;
  SifStats?: SifStatsResolvers<ContextType>;
  SifStatsByTypeResponse?: SifStatsByTypeResponseResolvers<ContextType>;
  SifStatsDuration?: SifStatsDurationResolvers<ContextType>;
  SubDepartment?: SubDepartmentResolvers<ContextType>;
  SubLocationDetails?: SubLocationDetailsResolvers<ContextType>;
  SubModulesData?: SubModulesDataResolvers<ContextType>;
  Subzone?: SubzoneResolvers<ContextType>;
  SubzoneWithActionLogCount?: SubzoneWithActionLogCountResolvers<ContextType>;
  TeamRoomsChecklist?: TeamRoomsChecklistResolvers<ContextType>;
  TestTimeline?: TestTimelineResolvers<ContextType>;
  ThreeRSChecklist?: ThreeRsChecklistResolvers<ContextType>;
  Truck?: TruckResolvers<ContextType>;
  Upload?: GraphQLScalarType;
  User?: UserResolvers<ContextType>;
  UserAccessDetails?: UserAccessDetailsResolvers<ContextType>;
  UserChecklistsByMonth?: UserChecklistsByMonthResolvers<ContextType>;
  UserChecklistsByStatus?: UserChecklistsByStatusResolvers<ContextType>;
  UserChecklistsResponseValue?: UserChecklistsResponseValueResolvers<ContextType>;
  UserHealthStatus?: UserHealthStatusResolvers<ContextType>;
  UserInfo?: UserInfoResolvers<ContextType>;
  UserNote?: UserNoteResolvers<ContextType>;
  UserPositionsByBU?: UserPositionsByBuResolvers<ContextType>;
  UserRoutine?: UserRoutineResolvers<ContextType>;
  UserUnderEvaluationResponse?: UserUnderEvaluationResponseResolvers<ContextType>;
  UserVaccine?: UserVaccineResolvers<ContextType>;
  UserVaccinesForLocation?: UserVaccinesForLocationResolvers<ContextType>;
  UsersLocationMedicalFacility?: UsersLocationMedicalFacilityResolvers<ContextType>;
  UsersVaccinatedCount?: UsersVaccinatedCountResolvers<ContextType>;
  Void?: GraphQLScalarType;
  WorkLocationDetails?: WorkLocationDetailsResolvers<ContextType>;
  WorkstationChecklist?: WorkstationChecklistResolvers<ContextType>;
  Zone?: ZoneResolvers<ContextType>;
  ZoneWithActionLogCount?: ZoneWithActionLogCountResolvers<ContextType>;
  area?: AreaResolvers<ContextType>;
  buContent?: BuContentResolvers<ContextType>;
  checkAppUpdateRequiredResponse?: CheckAppUpdateRequiredResponseResolvers<ContextType>;
  corruptedPayloadIndexes?: CorruptedPayloadIndexesResolvers<ContextType>;
  distributionCenterWorkLocation?: DistributionCenterWorkLocationResolvers<ContextType>;
  errorUpdateWorkLocationsDetails?: ErrorUpdateWorkLocationsDetailsResolvers<ContextType>;
  getIncidentsByLastHourResponse?: GetIncidentsByLastHourResponseResolvers<ContextType>;
  getUsersListPaginated?: GetUsersListPaginatedResolvers<ContextType>;
  locationsDepartmentAreaSubarea?: LocationsDepartmentAreaSubareaResolvers<ContextType>;
  paginationInfo?: PaginationInfoResolvers<ContextType>;
  reason?: ReasonResolvers<ContextType>;
  responseUpdateWorkLocations?: ResponseUpdateWorkLocationsResolvers<ContextType>;
  routinesAvailableNameAndSlug?: RoutinesAvailableNameAndSlugResolvers<ContextType>;
  specificSubLocations?: SpecificSubLocationsResolvers<ContextType>;
  statusReasonContent?: StatusReasonContentResolvers<ContextType>;
  subArea?: SubAreaResolvers<ContextType>;
  subLocationSpecificSubLocation?: SubLocationSpecificSubLocationResolvers<ContextType>;
  subLocations?: SubLocationsResolvers<ContextType>;
  userPositions?: UserPositionsResolvers<ContextType>;
  userRole?: UserRoleResolvers<ContextType>;
  userWorkLocations?: UserWorkLocationsResolvers<ContextType>;
  userWorkLocationsFiltersForSupply?: UserWorkLocationsFiltersForSupplyResolvers<ContextType>;
  usualWorkLocation?: UsualWorkLocationResolvers<ContextType>;
  weekContent?: WeekContentResolvers<ContextType>;
  workLocation?: WorkLocationResolvers<ContextType>;
  workLocationSubLocation?: WorkLocationSubLocationResolvers<ContextType>;
};


/**
 * @deprecated
 * Use "Resolvers" root object instead. If you wish to get "IResolvers", add "typesPrefix: I" to your config.
 */
export type IResolvers<ContextType = any> = Resolvers<ContextType>;
export type DirectiveResolvers<ContextType = any> = {
  cacheControl?: CacheControlDirectiveResolver<any, any, ContextType>;
};


/**
 * @deprecated
 * Use "DirectiveResolvers" root object instead. If you wish to get "IDirectiveResolvers", add "typesPrefix: I" to your config.
 */
export type IDirectiveResolvers<ContextType = any> = DirectiveResolvers<ContextType>;
export type CreateSignedS3UrlForGkpiUploadMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateSignedS3UrlForGkpiUploadMutation = (
  { __typename?: 'Mutation' }
  & { createSignedS3UrlForGKPIUpload: (
    { __typename?: 'S3SignedURL' }
    & Pick<S3SignedUrl, 'preSignedUrl' | 'fileName'>
  ) }
);

export type StartProcessingGkpiFileMutationVariables = Exact<{
  fileName: Scalars['String'];
}>;


export type StartProcessingGkpiFileMutation = (
  { __typename?: 'Mutation' }
  & { startProcessingGKPIFile: (
    { __typename?: 'GKPIExcelProcessingStatus' }
    & Pick<GkpiExcelProcessingStatus, 'status' | 'message'>
  ) }
);

export type GetgkpiDataQueryVariables = Exact<{
  country?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['Date']>;
  to?: Maybe<Scalars['Date']>;
}>;


export type GetgkpiDataQuery = (
  { __typename?: 'Query' }
  & { getGKPIData: Array<Maybe<(
    { __typename?: 'GKPIDataResponse' }
    & Pick<GkpiDataResponse, 'GKPI' | 'data'>
  )>> }
);

export type GetCountryAndLocationListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCountryAndLocationListQuery = (
  { __typename?: 'Query' }
  & { getCountryAndLocationList?: Maybe<Array<Maybe<(
    { __typename?: 'CountryAndLocationList' }
    & Pick<CountryAndLocationList, 'country' | 'locations'>
  )>>> }
);

export type UserHealthStatusUnAuthenticatedQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type UserHealthStatusUnAuthenticatedQuery = (
  { __typename?: 'Query' }
  & { userHealthStatusUnAuthenticated: (
    { __typename?: 'UserHealthStatus' }
    & Pick<UserHealthStatus, 'quarantineUntil' | 'quarantineStart'>
    & { healthStatus?: Maybe<(
      { __typename?: 'HealthStatus' }
      & Pick<HealthStatus, 'location' | 'status' | 'createdAt'>
    )>, usersLocationMedicalFacility?: Maybe<Array<Maybe<(
      { __typename?: 'UsersLocationMedicalFacility' }
      & Pick<UsersLocationMedicalFacility, 'emergencyContact'>
    )>>> }
  ) }
);

export type UpdateUserHealthStatusUnAuthenticatedMutationVariables = Exact<{
  status: Scalars['String'];
  location: Scalars['String'];
  slug: Scalars['String'];
}>;


export type UpdateUserHealthStatusUnAuthenticatedMutation = (
  { __typename?: 'Mutation' }
  & { addUserHealthStatusUnAuthenticated: (
    { __typename?: 'MutationStatus' }
    & Pick<MutationStatus, 'isSuccess'>
  ) }
);

export type GetExternalRoutesByLocationSlugQueryVariables = Exact<{
  locationSlug: Scalars['String'];
}>;


export type GetExternalRoutesByLocationSlugQuery = (
  { __typename?: 'Query' }
  & { getExternalRoutesByLocationSlug?: Maybe<Array<Maybe<(
    { __typename?: 'Route' }
    & Pick<Route, 'id' | 'name' | 'externalRouteId' | 'status' | 'content' | 'updatedAt'>
    & { location?: Maybe<(
      { __typename?: 'Location' }
      & Pick<Location, 'name'>
    )> }
  )>>> }
);

export type GetLatestChecklistAnsweredAtByLocationSlugQueryVariables = Exact<{
  locationSlug: Scalars['String'];
}>;


export type GetLatestChecklistAnsweredAtByLocationSlugQuery = (
  { __typename?: 'Query' }
  & { getLatestChecklistAnsweredAtByLocationSlug: (
    { __typename?: 'LatestChecklistAnsweredAt' }
    & Pick<LatestChecklistAnsweredAt, 'at'>
  ) }
);

export type GetRouteSafetyChecklistAnswerByRouteIdQueryVariables = Exact<{
  routeId: Scalars['Int'];
}>;


export type GetRouteSafetyChecklistAnswerByRouteIdQuery = (
  { __typename?: 'Query' }
  & { getRouteSafetyChecklistAnswerByRouteId: (
    { __typename?: 'CriticalRouteChecklist' }
    & Pick<CriticalRouteChecklist, 'id' | 'name' | 'content'>
    & { answers: Array<Maybe<(
      { __typename?: 'AnsweredCriticalRouteChecklist' }
      & Pick<AnsweredCriticalRouteChecklist, 'id' | 'content' | 'createdAt'>
      & { answeredByUser: (
        { __typename?: 'BaseUser' }
        & Pick<BaseUser, 'email' | 'picture' | 'name'>
      ) }
    )>> }
  ) }
);

export type GetRouteSafetyChecklistAnswerByLocationSlugQueryVariables = Exact<{
  locationSlug: Scalars['String'];
}>;


export type GetRouteSafetyChecklistAnswerByLocationSlugQuery = (
  { __typename?: 'Query' }
  & { getRouteSafetyChecklistAnswerByLocationSlug: (
    { __typename?: 'CriticalRouteChecklist' }
    & Pick<CriticalRouteChecklist, 'id' | 'name' | 'content'>
    & { answers: Array<Maybe<(
      { __typename?: 'AnsweredCriticalRouteChecklist' }
      & Pick<AnsweredCriticalRouteChecklist, 'id' | 'content' | 'createdAt'>
      & { answeredByUser: (
        { __typename?: 'BaseUser' }
        & Pick<BaseUser, 'email' | 'picture' | 'name'>
      ) }
    )>> }
  ) }
);

export type GetRouteSafetyChecklistByLocationSlugQueryVariables = Exact<{
  locationSlug: Scalars['String'];
}>;


export type GetRouteSafetyChecklistByLocationSlugQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getRouteSafetyChecklistByLocationSlug'>
);

export type GetLastUpdateAtForTruckDriversUploadQueryVariables = Exact<{
  locationSlug: Scalars['String'];
}>;


export type GetLastUpdateAtForTruckDriversUploadQuery = (
  { __typename?: 'Query' }
  & { getLastUpdateAtForTruckDriversUpload?: Maybe<(
    { __typename?: 'UserInfo' }
    & Pick<UserInfo, 'updatedAt'>
  )> }
);

export type GetRouteSafetyTruckDriversByLocationSlugQueryVariables = Exact<{
  locationSlug: Scalars['String'];
}>;


export type GetRouteSafetyTruckDriversByLocationSlugQuery = (
  { __typename?: 'Query' }
  & { getRouteSafetyTruckDriversByLocationSlug?: Maybe<Array<Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name'>
    & { info?: Maybe<(
      { __typename?: 'UserInfo' }
      & Pick<UserInfo, 'content'>
    )> }
  )>>> }
);

export type DeleteRouteSafetyTruckDriverMutationVariables = Exact<{
  driverUserId: Scalars['ID'];
}>;


export type DeleteRouteSafetyTruckDriverMutation = (
  { __typename?: 'Mutation' }
  & { deleteRouteSafetyTruckDriver?: Maybe<(
    { __typename?: 'MutationStatus' }
    & Pick<MutationStatus, 'isSuccess'>
  )> }
);

export type GetPlannedRoutesByLocationSlugQueryVariables = Exact<{
  locationSlug: Scalars['String'];
}>;


export type GetPlannedRoutesByLocationSlugQuery = (
  { __typename?: 'Query' }
  & { getPlannedRoutesByLocationSlug?: Maybe<Array<Maybe<(
    { __typename?: 'PlannedRoute' }
    & Pick<PlannedRoute, 'id' | 'createdAt' | 'secondDepartureStartedAt'>
    & { route?: Maybe<(
      { __typename?: 'Route' }
      & Pick<Route, 'id' | 'name' | 'externalRouteId' | 'status' | 'content'>
      & { location?: Maybe<(
        { __typename?: 'Location' }
        & Pick<Location, 'name'>
      )> }
    )>, primaryTruckDriver?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'name' | 'id'>
      & { info?: Maybe<(
        { __typename?: 'UserInfo' }
        & Pick<UserInfo, 'content'>
      )> }
    )>, secondaryTruckDriver?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'name' | 'id'>
      & { info?: Maybe<(
        { __typename?: 'UserInfo' }
        & Pick<UserInfo, 'content'>
      )> }
    )>, plannedTruckDriver?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name'>
      & { info?: Maybe<(
        { __typename?: 'UserInfo' }
        & Pick<UserInfo, 'content'>
      )> }
    )>, truck?: Maybe<(
      { __typename?: 'Truck' }
      & Pick<Truck, 'sapNumber' | 'plateNumber' | 'content'>
    )> }
  )>>> }
);

export type GetAllTrucksByLocationSlugQueryVariables = Exact<{
  locationSlug: Scalars['String'];
}>;


export type GetAllTrucksByLocationSlugQuery = (
  { __typename?: 'Query' }
  & { getAllTrucksByLocationSlug: Array<Maybe<(
    { __typename?: 'Truck' }
    & Pick<Truck, 'id' | 'sapNumber' | 'plateNumber' | 'locationId' | 'content' | 'updatedAt'>
    & { plannedRoute?: Maybe<(
      { __typename?: 'PlannedRoute' }
      & Pick<PlannedRoute, 'id'>
      & { route?: Maybe<(
        { __typename?: 'Route' }
        & Pick<Route, 'id' | 'externalRouteId'>
      )> }
    )> }
  )>> }
);

export type GetTruckDriversQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTruckDriversQuery = (
  { __typename?: 'Query' }
  & { getTruckDrivers?: Maybe<Array<Maybe<(
    { __typename?: 'GetTruckDriversResponse' }
    & Pick<GetTruckDriversResponse, 'status' | 'routeId' | 'externalRouteId'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'picture'>
      & { info?: Maybe<(
        { __typename?: 'UserInfo' }
        & Pick<UserInfo, 'content'>
      )> }
    ) }
  )>>> }
);

export type AssignTruckDriverMutationVariables = Exact<{
  plannedRouteId: Scalars['ID'];
  primaryDriverId?: Maybe<Scalars['ID']>;
  secondaryDriverId?: Maybe<Scalars['ID']>;
}>;


export type AssignTruckDriverMutation = (
  { __typename?: 'Mutation' }
  & { assignTruckDriver: (
    { __typename?: 'MutationStatus' }
    & Pick<MutationStatus, 'isSuccess'>
  ) }
);

export type AssignTruckMutationVariables = Exact<{
  plannedRouteId: Scalars['ID'];
  truckId?: Maybe<Scalars['ID']>;
}>;


export type AssignTruckMutation = (
  { __typename?: 'Mutation' }
  & { assignTruck: (
    { __typename?: 'MutationStatus' }
    & Pick<MutationStatus, 'isSuccess'>
  ) }
);

export type ChangeSecondDepartureStartedAtInPlannedRouteByIdMutationVariables = Exact<{
  plannedRouteId: Scalars['ID'];
  secondDepartureStartedAt: Scalars['Date'];
}>;


export type ChangeSecondDepartureStartedAtInPlannedRouteByIdMutation = (
  { __typename?: 'Mutation' }
  & { changeSecondDepartureStartedAtInPlannedRouteById: (
    { __typename?: 'MutationStatus' }
    & Pick<MutationStatus, 'isSuccess'>
  ) }
);

export type GetLastUploadedAtTimestampQueryVariables = Exact<{
  locationSlug: Scalars['String'];
}>;


export type GetLastUploadedAtTimestampQuery = (
  { __typename?: 'Query' }
  & { getLastUploadedAtTimestamp?: Maybe<(
    { __typename?: 'Truck' }
    & Pick<Truck, 'updatedAt'>
  )> }
);

export type GetSkapModulesAndSubmodulesCountQueryVariables = Exact<{
  filter: GetModuleSubmoduleCountFilterParams;
}>;


export type GetSkapModulesAndSubmodulesCountQuery = (
  { __typename?: 'Query' }
  & { getSKAPModulesAndSubmodulesCount: (
    { __typename?: 'GetModulesAndSubmodulesCountResponse' }
    & { countByLevels: Array<Maybe<(
      { __typename?: 'CountByLevels' }
      & Pick<CountByLevels, 'slug' | 'count' | 'percentage' | 'total'>
    )>>, countByAutonomousLevel: Array<Maybe<(
      { __typename?: 'CountByAutonomousLevel' }
      & Pick<CountByAutonomousLevel, 'title' | 'count'>
    )>>, countByPosition: Array<Maybe<(
      { __typename?: 'CountByPosition' }
      & Pick<CountByPosition, 'slug' | 'total' | 'impactedCount'>
      & { data: Array<Maybe<(
        { __typename?: 'ModulePercentage' }
        & Pick<ModulePercentage, 'title' | 'percentage'>
      )>> }
    )>>, countByMonth: Array<Maybe<(
      { __typename?: 'MonthWiseScore' }
      & Pick<MonthWiseScore, 'month' | 'score'>
    )>> }
  ) }
);

export type SendSkap_01NotificationMutationVariables = Exact<{ [key: string]: never; }>;


export type SendSkap_01NotificationMutation = (
  { __typename?: 'Mutation' }
  & { sendNotificationToLineManager: (
    { __typename?: 'MutationStatus' }
    & Pick<MutationStatus, 'isSuccess'>
  ), sendNotificationToFrontLiners: (
    { __typename?: 'MutationStatus' }
    & Pick<MutationStatus, 'isSuccess'>
  ) }
);

export type KpIsListQueryVariables = Exact<{
  isGlobal?: Maybe<Scalars['Boolean']>;
}>;


export type KpIsListQuery = (
  { __typename?: 'Query' }
  & { getAllKPI: Array<Maybe<(
    { __typename?: 'KPI' }
    & Pick<Kpi, 'id' | 'slug' | 'enTitle' | 'esTitle'>
  )>> }
);

export type GetRoutineAvailableInfoQueryVariables = Exact<{
  forFilter?: Maybe<Scalars['Boolean']>;
}>;


export type GetRoutineAvailableInfoQuery = (
  { __typename?: 'Query' }
  & { getRoutinesAvailable: Array<Maybe<(
    { __typename?: 'RoutineAvailable' }
    & Pick<RoutineAvailable, 'name' | 'slug'>
  )>> }
);

export type GetAllManagementToolQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllManagementToolQuery = (
  { __typename?: 'Query' }
  & { getAllManagementTool: Array<Maybe<(
    { __typename?: 'ManagementTool' }
    & Pick<ManagementTool, 'id' | 'slug' | 'enTitle' | 'esTitle'>
  )>> }
);

export type SearchedUsersWithAccessToLocationQueryVariables = Exact<{
  searchString: Scalars['String'];
  locationSlug: Scalars['String'];
}>;


export type SearchedUsersWithAccessToLocationQuery = (
  { __typename?: 'Query' }
  & { searchUsersWithAccessToLocation?: Maybe<Array<Maybe<(
    { __typename?: 'BaseUser' }
    & Pick<BaseUser, 'name' | 'slug' | 'id' | 'picture'>
  )>>> }
);

export type ActionLogsCountGroupByLocationQueryQueryVariables = Exact<{
  startTime: Scalars['Date'];
  endTime: Scalars['Date'];
}>;


export type ActionLogsCountGroupByLocationQueryQuery = (
  { __typename?: 'Query' }
  & { actionLogsCountGroupByLocation: Array<Maybe<(
    { __typename?: 'BusinessUnitWithActionLogCount' }
    & Pick<BusinessUnitWithActionLogCount, 'name' | 'slug' | 'actionLogsCount'>
    & { zones?: Maybe<Array<Maybe<(
      { __typename?: 'ZoneWithActionLogCount' }
      & Pick<ZoneWithActionLogCount, 'name' | 'slug' | 'actionLogsCount'>
      & { subzones?: Maybe<Array<Maybe<(
        { __typename?: 'SubzoneWithActionLogCount' }
        & Pick<SubzoneWithActionLogCount, 'name' | 'slug' | 'actionLogsCount'>
        & { locations?: Maybe<Array<Maybe<(
          { __typename?: 'LocationWithActionLogCount' }
          & Pick<LocationWithActionLogCount, 'name' | 'slug' | 'actionLogsCount'>
          & { cluster?: Maybe<(
            { __typename?: 'Cluster' }
            & Pick<Cluster, 'name' | 'slug'>
          )> }
        )>>> }
      )>>> }
    )>>> }
  )>> }
);

export type ActionLogsInDcByStatusFragment = (
  { __typename?: 'CreateEditActionLog' }
  & Pick<CreateEditActionLog, 'id' | 'numberOfComments' | 'description' | 'endDate' | 'status' | 'pillar' | 'originText' | 'origin'>
  & { owner: (
    { __typename?: 'BaseUser' }
    & Pick<BaseUser, 'picture' | 'id' | 'name' | 'slug'>
  ), responsibles: Array<(
    { __typename?: 'BaseUser' }
    & Pick<BaseUser, 'id' | 'picture' | 'name' | 'slug'>
  )>, images: Array<Maybe<(
    { __typename?: 'ImageInputItemType' }
    & Pick<ImageInputItemType, 'id' | 'url'>
  )>>, routinesAvailable?: Maybe<(
    { __typename?: 'routinesAvailableNameAndSlug' }
    & Pick<RoutinesAvailableNameAndSlug, 'name' | 'slug'>
  )> }
);

export type AllActionLogForLocationQueryVariables = Exact<{
  actionLogsLocationSlug?: Maybe<Scalars['String']>;
  startTime: Scalars['Date'];
  endTime: Scalars['Date'];
  filters?: Maybe<ActionLogFilters>;
}>;


export type AllActionLogForLocationQuery = (
  { __typename?: 'Query' }
  & { groupActionLogsInDCByStatus: (
    { __typename?: 'ActionLogsInDCByStatus' }
    & { OPEN: Array<Maybe<(
      { __typename?: 'CreateEditActionLog' }
      & ActionLogsInDcByStatusFragment
    )>>, IN_PROGRESS: Array<Maybe<(
      { __typename?: 'CreateEditActionLog' }
      & ActionLogsInDcByStatusFragment
    )>>, FOR_REVISION: Array<Maybe<(
      { __typename?: 'CreateEditActionLog' }
      & ActionLogsInDcByStatusFragment
    )>>, COMPLETED: Array<Maybe<(
      { __typename?: 'CreateEditActionLog' }
      & ActionLogsInDcByStatusFragment
    )>> }
  ) }
);

export type AllCommentsForActionLogQueryVariables = Exact<{
  commentsActionLogId: Scalars['ID'];
}>;


export type AllCommentsForActionLogQuery = (
  { __typename?: 'Query' }
  & { comments: Array<Maybe<(
    { __typename?: 'Comment' }
    & Pick<Comment, 'id' | 'comment'>
  )>> }
);

export type AddOrEditActionLogCommentMutationVariables = Exact<{
  actionLogId: Scalars['ID'];
  comment?: Maybe<Scalars['String']>;
  commentId?: Maybe<Scalars['ID']>;
}>;


export type AddOrEditActionLogCommentMutation = (
  { __typename?: 'Mutation' }
  & { addOrEditComment: (
    { __typename?: 'MutationStatus' }
    & Pick<MutationStatus, 'isSuccess'>
  ) }
);

export type DetailedCommentsForActionLogQueryVariables = Exact<{
  commentsActionLogId: Scalars['ID'];
}>;


export type DetailedCommentsForActionLogQuery = (
  { __typename?: 'Query' }
  & { comments: Array<Maybe<(
    { __typename?: 'Comment' }
    & Pick<Comment, 'id' | 'comment' | 'createdAt' | 'updatedAt'>
    & { user: (
      { __typename?: 'BaseUser' }
      & Pick<BaseUser, 'name' | 'slug' | 'picture'>
    ) }
  )>> }
);

export type SummaryForActionLogQueryVariables = Exact<{
  actionLogId: Scalars['ID'];
}>;


export type SummaryForActionLogQuery = (
  { __typename?: 'Query' }
  & { actionLog?: Maybe<(
    { __typename?: 'CreateEditActionLog' }
    & Pick<CreateEditActionLog, 'id' | 'startDate' | 'description' | 'KPIs' | 'endDate' | 'status' | 'pillar' | 'origin' | 'originText' | 'meeting' | 'note' | 'statusUpdateDate'>
    & { KPI: (
      { __typename?: 'KPI' }
      & Pick<Kpi, 'esTitle' | 'enTitle' | 'slug'>
    ), ManagementTool?: Maybe<(
      { __typename?: 'ManagementTool' }
      & Pick<ManagementTool, 'esTitle' | 'enTitle' | 'slug' | 'id'>
    )>, owner: (
      { __typename?: 'BaseUser' }
      & Pick<BaseUser, 'id' | 'name' | 'slug' | 'picture'>
    ), responsibles: Array<(
      { __typename?: 'BaseUser' }
      & Pick<BaseUser, 'id' | 'name' | 'slug' | 'picture'>
    )>, location: (
      { __typename?: 'BaseLocation' }
      & Pick<BaseLocation, 'slug' | 'name'>
    ), images: Array<Maybe<(
      { __typename?: 'ImageInputItemType' }
      & Pick<ImageInputItemType, 'id' | 'url'>
    )>>, routinesAvailable?: Maybe<(
      { __typename?: 'routinesAvailableNameAndSlug' }
      & Pick<RoutinesAvailableNameAndSlug, 'name' | 'slug'>
    )> }
  )> }
);

export type DeleteActionLogCommentMutationVariables = Exact<{
  commentId: Scalars['ID'];
}>;


export type DeleteActionLogCommentMutation = (
  { __typename?: 'Mutation' }
  & { deleteComment: (
    { __typename?: 'MutationStatus' }
    & Pick<MutationStatus, 'isSuccess'>
  ) }
);

export type ActionLogStatusMutationVariables = Exact<{
  actionLogId: Scalars['Int'];
  status: Action_Log_Status;
  isOwnerCheckSurpassed?: Maybe<Scalars['Boolean']>;
}>;


export type ActionLogStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateActionLogStatus: (
    { __typename?: 'MutationStatus' }
    & Pick<MutationStatus, 'isSuccess'>
  ) }
);

export type CreateEditActionLogMutationVariables = Exact<{
  payload: CreateEditActionLogPayload;
}>;


export type CreateEditActionLogMutation = (
  { __typename?: 'Mutation' }
  & { createEditActionLog: (
    { __typename?: 'CreateEditActionLogResponse' }
    & Pick<CreateEditActionLogResponse, 'isSuccess'>
  ) }
);

export type DeleteActionLogMutationVariables = Exact<{
  actionLogId: Scalars['Int'];
}>;


export type DeleteActionLogMutation = (
  { __typename?: 'Mutation' }
  & { deleteActionLog: (
    { __typename?: 'MutationStatus' }
    & Pick<MutationStatus, 'isSuccess'>
  ) }
);

export type GetDashboardTableDataQueryVariables = Exact<{
  locationIds: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
  groupBy?: Maybe<Scalars['JSON']>;
  date?: Maybe<Scalars['JSON']>;
}>;


export type GetDashboardTableDataQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getDashboardTableData'>
);

export type GetAll5SByLocationIdQueryVariables = Exact<{
  locationId: Scalars['ID'];
  monthNumber?: Maybe<Scalars['Int']>;
  areaId?: Maybe<Scalars['Int']>;
  subAreaId?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  userSearch?: Maybe<Scalars['String']>;
}>;


export type GetAll5SByLocationIdQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getAll5SByLocationId'>
);

export type Delete5SChecklistsMutationVariables = Exact<{
  args: Delete5SChecklistsInput;
}>;


export type Delete5SChecklistsMutation = (
  { __typename?: 'Mutation' }
  & { delete5SChecklists: (
    { __typename?: 'MutationStatus' }
    & Pick<MutationStatus, 'isSuccess'>
  ) }
);

export type Update5SChecklistsMutationVariables = Exact<{
  args: UserChecklistInput;
}>;


export type Update5SChecklistsMutation = (
  { __typename?: 'Mutation' }
  & { update5SChecklists: (
    { __typename?: 'MutationStatus' }
    & Pick<MutationStatus, 'isSuccess'>
  ) }
);

export type AddOrEditCapexCommentMutationVariables = Exact<{
  capexId: Scalars['ID'];
  comment: Scalars['String'];
  commentId?: Maybe<Scalars['ID']>;
}>;


export type AddOrEditCapexCommentMutation = (
  { __typename?: 'Mutation' }
  & { addOrEditCapexComment: (
    { __typename?: 'MutationStatus' }
    & Pick<MutationStatus, 'isSuccess'>
  ) }
);

export type DeleteCapexCommentMutationVariables = Exact<{
  commentId: Scalars['ID'];
}>;


export type DeleteCapexCommentMutation = (
  { __typename?: 'Mutation' }
  & { deleteCapexComment: (
    { __typename?: 'MutationStatus' }
    & Pick<MutationStatus, 'isSuccess'>
  ) }
);

export type CapexCommentsQueryVariables = Exact<{
  capexId: Scalars['ID'];
}>;


export type CapexCommentsQuery = (
  { __typename?: 'Query' }
  & { capexComments: Array<Maybe<(
    { __typename?: 'Comment' }
    & Pick<Comment, 'id' | 'comment' | 'createdAt' | 'updatedAt'>
    & { user: (
      { __typename?: 'BaseUser' }
      & Pick<BaseUser, 'name' | 'slug' | 'picture'>
    ) }
  )>> }
);

export type GetIncidentsByMonthsCategoriesAndTypeQueryVariables = Exact<{
  monthNumber: Scalars['Int'];
  year: Scalars['Int'];
}>;


export type GetIncidentsByMonthsCategoriesAndTypeQuery = (
  { __typename?: 'Query' }
  & { getIncidentsBetweenRange: Array<Maybe<(
    { __typename?: 'IncidentBetweenRange' }
    & Pick<IncidentBetweenRange, 'bu' | 'month'>
    & { weeks: Array<Maybe<(
      { __typename?: 'weekContent' }
      & Pick<WeekContent, 'week' | 'totalIncidents'>
    )>> }
  )>>, getIncidentsByCategories: Array<Maybe<(
    { __typename?: 'IncidentByCategory' }
    & Pick<IncidentByCategory, 'categoryName'>
    & { bus: Array<Maybe<(
      { __typename?: 'buContent' }
      & Pick<BuContent, 'name' | 'totalIncidents'>
    )>> }
  )>>, getIncidentsByMonths: Array<Maybe<(
    { __typename?: 'IncidentByMonth' }
    & Pick<IncidentByMonth, 'bu'>
    & { months: Array<Maybe<(
      { __typename?: 'buContent' }
      & Pick<BuContent, 'name' | 'totalIncidents'>
    )>> }
  )>>, topBest: Array<Maybe<(
    { __typename?: 'IncidentByType' }
    & Pick<IncidentByType, 'bu' | 'name' | 'totalIncidents'>
  )>>, topOffenders: Array<Maybe<(
    { __typename?: 'IncidentByType' }
    & Pick<IncidentByType, 'bu' | 'name' | 'totalIncidents'>
  )>>, topVehicleOffenders: Array<Maybe<(
    { __typename?: 'IncidentByType' }
    & Pick<IncidentByType, 'bu' | 'name' | 'totalIncidents'>
  )>> }
);

export type GetIncidentsByTypePerBuQueryVariables = Exact<{
  monthNumber: Scalars['Int'];
  year: Scalars['Int'];
  bu?: Maybe<Scalars['String']>;
}>;


export type GetIncidentsByTypePerBuQuery = (
  { __typename?: 'Query' }
  & { topBest: Array<Maybe<(
    { __typename?: 'IncidentByType' }
    & Pick<IncidentByType, 'bu' | 'name' | 'totalIncidents'>
  )>>, topOffenders: Array<Maybe<(
    { __typename?: 'IncidentByType' }
    & Pick<IncidentByType, 'bu' | 'name' | 'totalIncidents'>
  )>>, topVehicleOffenders: Array<Maybe<(
    { __typename?: 'IncidentByType' }
    & Pick<IncidentByType, 'bu' | 'name' | 'totalIncidents'>
  )>> }
);

export type GetTrainingsQueryVariables = Exact<{
  data: GetTrainingsPayload;
  by: GetTrainingsBy;
  isFindOne?: Maybe<Scalars['Boolean']>;
}>;


export type GetTrainingsQuery = (
  { __typename?: 'Query' }
  & { getTrainings: Array<Maybe<(
    { __typename?: 'BuData' }
    & Pick<BuData, 'usersToTrain' | 'usersTrained' | 'name' | 'percentage'>
  )>> }
);

export type GetTrainingsByPositionQueryVariables = Exact<{
  data: GetTrainingsPayload;
  by: GetTrainingsBy;
  isFindOne?: Maybe<Scalars['Boolean']>;
}>;


export type GetTrainingsByPositionQuery = (
  { __typename?: 'Query' }
  & { getTrainings: Array<Maybe<(
    { __typename?: 'BuData' }
    & Pick<BuData, 'usersToTrain' | 'usersTrained' | 'position' | 'percentage'>
  )>> }
);

export type GetSifStatsQueryVariables = Exact<{
  filter: FilterParams;
  by: GetSifStatsBy;
  year: Scalars['Int'];
}>;


export type GetSifStatsQuery = (
  { __typename?: 'Query' }
  & { getSifStats: Array<Maybe<(
    { __typename?: 'SifStats' }
    & Pick<SifStats, 'name' | 'totalSif'>
  )>> }
);

export type GetSifStatsByTypeQueryVariables = Exact<{
  filter?: Maybe<FilterParamsByType>;
  year: Scalars['Int'];
}>;


export type GetSifStatsByTypeQuery = (
  { __typename?: 'Query' }
  & { getSifStatsByType: Array<Maybe<(
    { __typename?: 'SifStatsByTypeResponse' }
    & Pick<SifStatsByTypeResponse, 'month'>
    & { sifType: Array<Maybe<(
      { __typename?: 'SifStats' }
      & Pick<SifStats, 'name' | 'totalSif'>
    )>> }
  )>> }
);

export type ListAllSifStatsByMonthQueryVariables = Exact<{
  monthNumber: Scalars['Int'];
  year: Scalars['Int'];
  filter: FilterParams;
}>;


export type ListAllSifStatsByMonthQuery = (
  { __typename?: 'Query' }
  & { listAllSifStatsByMonth: Array<Maybe<(
    { __typename?: 'ListSifStatsResponse' }
    & Pick<ListSifStatsResponse, 'reference' | 'classificationOfInjury' | 'location' | 'incidentCause' | 'nature' | 'department' | 'incidentDate' | 'shiftType' | 'incidentTime'>
  )>> }
);

export type GetSifStatsByDurationQueryVariables = Exact<{
  monthNumber: Scalars['Int'];
  year: Scalars['Int'];
  filter: FilterParams;
}>;


export type GetSifStatsByDurationQuery = (
  { __typename?: 'Query' }
  & { getSifStatsByDuration: Array<Maybe<(
    { __typename?: 'SifStatsDuration' }
    & Pick<SifStatsDuration, 'duration' | 'totalSif'>
  )>> }
);

export type GetNoteQueryVariables = Exact<{
  bu: Scalars['String'];
}>;


export type GetNoteQuery = (
  { __typename?: 'Query' }
  & { getNote?: Maybe<(
    { __typename?: 'Note' }
    & Pick<Note, 'id' | 'bu' | 'content' | 'updatedAt' | 'createdAt'>
    & { user?: Maybe<(
      { __typename?: 'UserNote' }
      & Pick<UserNote, 'id' | 'name' | 'email'>
    )> }
  )> }
);

export type CreateNoteMutationVariables = Exact<{
  input: NoteInput;
}>;


export type CreateNoteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createNote'>
);

export type GetCovidUsersForLocationsQueryVariables = Exact<{
  locationsToAccess: Array<Scalars['ID']> | Scalars['ID'];
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type GetCovidUsersForLocationsQuery = (
  { __typename?: 'Query' }
  & { getCovidUsersForLocations: (
    { __typename?: 'CovidUsersForLocationCount' }
    & Pick<CovidUsersForLocationCount, 'count'>
    & { covidUsersForLocation?: Maybe<Array<Maybe<(
      { __typename?: 'CovidUsersForLocation' }
      & Pick<CovidUsersForLocation, 'name' | 'employeeNumber' | 'quarantineUntil' | 'doctorStatus'>
      & { location?: Maybe<(
        { __typename?: 'LocationName' }
        & Pick<LocationName, 'name'>
      )>, latestCovidTest?: Maybe<(
        { __typename?: 'LatestCovidTest' }
        & Pick<LatestCovidTest, 'type'>
      )> }
    )>>> }
  ) }
);

export type LastFileUploadDateQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type LastFileUploadDateQueryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'lastFileUploadDate'>
);

export type GetDepartmentAreaSubareaFiltersForSupplyListQueryVariables = Exact<{
  userId: Scalars['Int'];
  locationsIds?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
}>;


export type GetDepartmentAreaSubareaFiltersForSupplyListQuery = (
  { __typename?: 'Query' }
  & { getDepartmentAreaSubareaFiltersForSupply: (
    { __typename?: 'userWorkLocationsFiltersForSupply' }
    & { departments?: Maybe<Array<Maybe<(
      { __typename?: 'workLocation' }
      & Pick<WorkLocation, 'id' | 'name' | 'nameEN' | 'slug' | 'locationId'>
    )>>>, areas?: Maybe<Array<Maybe<(
      { __typename?: 'subLocations' }
      & Pick<SubLocations, 'id' | 'name' | 'nameEN' | 'slug' | 'departmentId' | 'departmentSlug'>
    )>>>, subAreas?: Maybe<Array<Maybe<(
      { __typename?: 'specificSubLocations' }
      & Pick<SpecificSubLocations, 'id' | 'name' | 'nameEn' | 'slug' | 'areaId' | 'areaSlug' | 'departmentId' | 'departmentSlug'>
    )>>> }
  ) }
);

export type IncidentsByLastHourQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type IncidentsByLastHourQuery = (
  { __typename?: 'Query' }
  & { getIncidentsByLastHour?: Maybe<(
    { __typename?: 'getIncidentsByLastHourResponse' }
    & Pick<GetIncidentsByLastHourResponse, 'hasReachedLimit' | 'firstIncidentDate'>
  )> }
);

export type UpdateWorkLocationsMutationVariables = Exact<{
  payload: UpdateWorkLocationsPayload;
}>;


export type UpdateWorkLocationsMutation = (
  { __typename?: 'Mutation' }
  & { updateWorkLocations: (
    { __typename?: 'responseUpdateWorkLocations' }
    & { MutationStatus: (
      { __typename?: 'MutationStatus' }
      & Pick<MutationStatus, 'isSuccess'>
    ), Error?: Maybe<(
      { __typename?: 'errorUpdateWorkLocationsDetails' }
      & Pick<ErrorUpdateWorkLocationsDetails, 'areaError' | 'subAreaError' | 'subLocationError'>
    )> }
  ) }
);

export type GetAllLocationsQueryVariables = Exact<{
  type?: Maybe<Location_Type>;
}>;


export type GetAllLocationsQuery = (
  { __typename?: 'Query' }
  & { getLocations: Array<Maybe<(
    { __typename?: 'LocationDetails' }
    & Pick<LocationDetails, 'id' | 'name' | 'slug' | 'type'>
  )>> }
);

export type GetUserInfoQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetUserInfoQuery = (
  { __typename?: 'Query' }
  & { getUserInfo?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'slug' | 'email' | 'type' | 'position' | 'language' | 'isBlocked' | 'shortSlug' | 'picture' | 'pin' | 'blockedReason' | 'lastActiveAt' | 'createdAt' | 'employeeNumber' | 'doctorStatus' | 'startWorkingDay' | 'autoAssignSios'>
    & { info?: Maybe<(
      { __typename?: 'UserInfo' }
      & Pick<UserInfo, 'isAuthorisedForOWD' | 'id' | 'userId' | 'visitorType'>
    )>, distributionCenter?: Maybe<(
      { __typename?: 'LocationDetails' }
      & Pick<LocationDetails, 'id' | 'name' | 'bu' | 'type'>
    )>, userRole?: Maybe<(
      { __typename?: 'userRole' }
      & Pick<UserRole, 'id' | 'name' | 'slug' | 'category'>
    )> }
  )> }
);

export type GetUserWorkLocationsQueryVariables = Exact<{
  userId: Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>;
}>;


export type GetUserWorkLocationsQuery = (
  { __typename?: 'Query' }
  & { getUserWorkLocations: Array<Maybe<(
    { __typename?: 'userWorkLocations' }
    & Pick<UserWorkLocations, 'id' | 'userId'>
    & { workLocation?: Maybe<(
      { __typename?: 'workLocation' }
      & Pick<WorkLocation, 'id' | 'name' | 'nameEN' | 'slug'>
    )>, subLocation?: Maybe<(
      { __typename?: 'area' }
      & Pick<Area, 'id' | 'name' | 'nameEN' | 'slug'>
    )>, subArea?: Maybe<(
      { __typename?: 'subArea' }
      & Pick<SubArea, 'id' | 'name' | 'nameEn' | 'slug'>
    )> }
  )>> }
);

export type GetAccessLocationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAccessLocationsQuery = (
  { __typename?: 'Query' }
  & { getAccessLocations: Array<Maybe<(
    { __typename?: 'LocationDetails' }
    & Pick<LocationDetails, 'id' | 'name'>
  )>> }
);

export type UpdateUserInfoMutationVariables = Exact<{
  id: Scalars['ID'];
  updatedByUserId: Scalars['ID'];
  general?: Maybe<UsersGeneralInfoPayload>;
  permission?: Maybe<PermissionInfoPayload>;
  roleId?: Maybe<Scalars['ID']>;
  userInfo?: Maybe<UserInfoPayload>;
}>;


export type UpdateUserInfoMutation = (
  { __typename?: 'Mutation' }
  & { updateUserInfo: (
    { __typename?: 'MutationStatus' }
    & Pick<MutationStatus, 'isSuccess'>
  ) }
);

export type UpdateUserAccessLocationMutationVariables = Exact<{
  locationIds: Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['String']>;
}>;


export type UpdateUserAccessLocationMutation = (
  { __typename?: 'Mutation' }
  & { updateUserAccessLocation: (
    { __typename?: 'MutationStatus' }
    & Pick<MutationStatus, 'isSuccess'>
  ) }
);

export type GetAllAccessLocationsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetAllAccessLocationsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getAllAccessLocations'>
);

export type GetAllLocationsUserPositionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllLocationsUserPositionsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getAllLocationsUserPositions'>
);

export type GetUserPositionsByBuQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserPositionsByBuQuery = (
  { __typename?: 'Query' }
  & { getUserPositionsByBu: Array<Maybe<(
    { __typename?: 'UserPositionsByBU' }
    & Pick<UserPositionsByBu, 'position'>
    & { bu: (
      { __typename?: 'BusinessUnit' }
      & Pick<BusinessUnit, 'id' | 'name'>
    ) }
  )>> }
);

export type GetDepartmentAreaSubAreaQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetDepartmentAreaSubAreaQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getDepartmentAreaSubArea'>
);

export type GetLocationsDepartmentAreaSubareaQueryVariables = Exact<{
  locationIds: Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>;
}>;


export type GetLocationsDepartmentAreaSubareaQuery = (
  { __typename?: 'Query' }
  & { getLocationsDepartmentAreaSubarea: Array<Maybe<(
    { __typename?: 'locationsDepartmentAreaSubarea' }
    & Pick<LocationsDepartmentAreaSubarea, 'id' | 'name' | 'slug'>
    & { departments?: Maybe<Array<Maybe<(
      { __typename?: 'workLocation' }
      & Pick<WorkLocation, 'id' | 'name' | 'nameEN' | 'slug'>
      & { areas?: Maybe<Array<Maybe<(
        { __typename?: 'area' }
        & Pick<Area, 'id' | 'name' | 'nameEN' | 'slug'>
        & { subAreas?: Maybe<Array<Maybe<(
          { __typename?: 'subArea' }
          & Pick<SubArea, 'id' | 'name' | 'nameEn' | 'slug'>
        )>>> }
      )>>> }
    )>>> }
  )>> }
);

export type GetAllOwdChecklistsQueryVariables = Exact<{
  locationSlug: Scalars['String'];
  source: ClientType;
  pillar?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  userVersion?: Maybe<Scalars['String']>;
  userSearch?: Maybe<Scalars['String']>;
}>;


export type GetAllOwdChecklistsQuery = (
  { __typename?: 'Query' }
  & { getAllOWDChecklists: Array<Maybe<(
    { __typename?: 'GetAllOWDChecklists' }
    & Pick<GetAllOwdChecklists, 'id' | 'name' | 'content' | 'createdAt' | 'updatedAt'>
  )>> }
);

export type GetPostsQueryVariables = Exact<{
  limit: Scalars['Int'];
  search?: Maybe<Scalars['String']>;
  type?: Maybe<Posts_Types>;
  attachmentType?: Maybe<Array<Maybe<Attachment_Types>> | Maybe<Attachment_Types>>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
}>;


export type GetPostsQuery = (
  { __typename?: 'Query' }
  & { getPosts?: Maybe<(
    { __typename?: 'GetPostResponse' }
    & Pick<GetPostResponse, 'hasMore'>
    & { posts: Array<Maybe<(
      { __typename?: 'Post' }
      & Pick<Post, 'title' | 'content' | 'type' | 'bannerImage' | 'slug' | 'postedAt' | 'author'>
      & { attachments?: Maybe<Array<Maybe<(
        { __typename?: 'Attachment' }
        & Pick<Attachment, 'url' | 'type'>
      )>>> }
    )>> }
  )> }
);

export type LocationDetailsQueryVariables = Exact<{
  locationSlug: Scalars['String'];
}>;


export type LocationDetailsQuery = (
  { __typename?: 'Query' }
  & { locationDetails: (
    { __typename?: 'LocationDetails' }
    & Pick<LocationDetails, 'type'>
  ) }
);

export const ActionLogsInDcByStatusFragmentDoc = gql`
    fragment actionLogsInDCByStatus on CreateEditActionLog {
  id
  numberOfComments
  description
  endDate
  status
  pillar
  owner {
    picture
    id
    name
    slug
  }
  originText
  origin
  responsibles {
    id
    picture
    name
    slug
  }
  images {
    id
    url
  }
  routinesAvailable {
    name
    slug
  }
}
    `;
export const CreateSignedS3UrlForGkpiUploadDocument = gql`
    mutation CreateSignedS3UrlForGKPIUpload {
  createSignedS3UrlForGKPIUpload {
    preSignedUrl
    fileName
  }
}
    `;
export type CreateSignedS3UrlForGkpiUploadMutationFn = Apollo.MutationFunction<CreateSignedS3UrlForGkpiUploadMutation, CreateSignedS3UrlForGkpiUploadMutationVariables>;

/**
 * __useCreateSignedS3UrlForGkpiUploadMutation__
 *
 * To run a mutation, you first call `useCreateSignedS3UrlForGkpiUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSignedS3UrlForGkpiUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSignedS3UrlForGkpiUploadMutation, { data, loading, error }] = useCreateSignedS3UrlForGkpiUploadMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateSignedS3UrlForGkpiUploadMutation(baseOptions?: Apollo.MutationHookOptions<CreateSignedS3UrlForGkpiUploadMutation, CreateSignedS3UrlForGkpiUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSignedS3UrlForGkpiUploadMutation, CreateSignedS3UrlForGkpiUploadMutationVariables>(CreateSignedS3UrlForGkpiUploadDocument, options);
      }
export type CreateSignedS3UrlForGkpiUploadMutationHookResult = ReturnType<typeof useCreateSignedS3UrlForGkpiUploadMutation>;
export type CreateSignedS3UrlForGkpiUploadMutationResult = Apollo.MutationResult<CreateSignedS3UrlForGkpiUploadMutation>;
export type CreateSignedS3UrlForGkpiUploadMutationOptions = Apollo.BaseMutationOptions<CreateSignedS3UrlForGkpiUploadMutation, CreateSignedS3UrlForGkpiUploadMutationVariables>;
export const StartProcessingGkpiFileDocument = gql`
    mutation StartProcessingGKPIFile($fileName: String!) {
  startProcessingGKPIFile(fileName: $fileName) {
    status
    message
  }
}
    `;
export type StartProcessingGkpiFileMutationFn = Apollo.MutationFunction<StartProcessingGkpiFileMutation, StartProcessingGkpiFileMutationVariables>;

/**
 * __useStartProcessingGkpiFileMutation__
 *
 * To run a mutation, you first call `useStartProcessingGkpiFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartProcessingGkpiFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startProcessingGkpiFileMutation, { data, loading, error }] = useStartProcessingGkpiFileMutation({
 *   variables: {
 *      fileName: // value for 'fileName'
 *   },
 * });
 */
export function useStartProcessingGkpiFileMutation(baseOptions?: Apollo.MutationHookOptions<StartProcessingGkpiFileMutation, StartProcessingGkpiFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartProcessingGkpiFileMutation, StartProcessingGkpiFileMutationVariables>(StartProcessingGkpiFileDocument, options);
      }
export type StartProcessingGkpiFileMutationHookResult = ReturnType<typeof useStartProcessingGkpiFileMutation>;
export type StartProcessingGkpiFileMutationResult = Apollo.MutationResult<StartProcessingGkpiFileMutation>;
export type StartProcessingGkpiFileMutationOptions = Apollo.BaseMutationOptions<StartProcessingGkpiFileMutation, StartProcessingGkpiFileMutationVariables>;
export const GetgkpiDataDocument = gql`
    query GETGKPIData($country: String, $location: String, $from: Date, $to: Date) {
  getGKPIData(country: $country, location: $location, from: $from, to: $to) {
    GKPI
    data
  }
}
    `;

/**
 * __useGetgkpiDataQuery__
 *
 * To run a query within a React component, call `useGetgkpiDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetgkpiDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetgkpiDataQuery({
 *   variables: {
 *      country: // value for 'country'
 *      location: // value for 'location'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGetgkpiDataQuery(baseOptions?: Apollo.QueryHookOptions<GetgkpiDataQuery, GetgkpiDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetgkpiDataQuery, GetgkpiDataQueryVariables>(GetgkpiDataDocument, options);
      }
export function useGetgkpiDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetgkpiDataQuery, GetgkpiDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetgkpiDataQuery, GetgkpiDataQueryVariables>(GetgkpiDataDocument, options);
        }
export type GetgkpiDataQueryHookResult = ReturnType<typeof useGetgkpiDataQuery>;
export type GetgkpiDataLazyQueryHookResult = ReturnType<typeof useGetgkpiDataLazyQuery>;
export type GetgkpiDataQueryResult = Apollo.QueryResult<GetgkpiDataQuery, GetgkpiDataQueryVariables>;
export const GetCountryAndLocationListDocument = gql`
    query GetCountryAndLocationList {
  getCountryAndLocationList {
    country
    locations
  }
}
    `;

/**
 * __useGetCountryAndLocationListQuery__
 *
 * To run a query within a React component, call `useGetCountryAndLocationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountryAndLocationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountryAndLocationListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCountryAndLocationListQuery(baseOptions?: Apollo.QueryHookOptions<GetCountryAndLocationListQuery, GetCountryAndLocationListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCountryAndLocationListQuery, GetCountryAndLocationListQueryVariables>(GetCountryAndLocationListDocument, options);
      }
export function useGetCountryAndLocationListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCountryAndLocationListQuery, GetCountryAndLocationListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCountryAndLocationListQuery, GetCountryAndLocationListQueryVariables>(GetCountryAndLocationListDocument, options);
        }
export type GetCountryAndLocationListQueryHookResult = ReturnType<typeof useGetCountryAndLocationListQuery>;
export type GetCountryAndLocationListLazyQueryHookResult = ReturnType<typeof useGetCountryAndLocationListLazyQuery>;
export type GetCountryAndLocationListQueryResult = Apollo.QueryResult<GetCountryAndLocationListQuery, GetCountryAndLocationListQueryVariables>;
export const UserHealthStatusUnAuthenticatedDocument = gql`
    query userHealthStatusUnAuthenticated($slug: String!) {
  userHealthStatusUnAuthenticated(slug: $slug) {
    quarantineUntil
    quarantineStart
    healthStatus {
      location
      status
      createdAt
    }
    usersLocationMedicalFacility {
      emergencyContact
    }
  }
}
    `;

/**
 * __useUserHealthStatusUnAuthenticatedQuery__
 *
 * To run a query within a React component, call `useUserHealthStatusUnAuthenticatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserHealthStatusUnAuthenticatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserHealthStatusUnAuthenticatedQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useUserHealthStatusUnAuthenticatedQuery(baseOptions: Apollo.QueryHookOptions<UserHealthStatusUnAuthenticatedQuery, UserHealthStatusUnAuthenticatedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserHealthStatusUnAuthenticatedQuery, UserHealthStatusUnAuthenticatedQueryVariables>(UserHealthStatusUnAuthenticatedDocument, options);
      }
export function useUserHealthStatusUnAuthenticatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserHealthStatusUnAuthenticatedQuery, UserHealthStatusUnAuthenticatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserHealthStatusUnAuthenticatedQuery, UserHealthStatusUnAuthenticatedQueryVariables>(UserHealthStatusUnAuthenticatedDocument, options);
        }
export type UserHealthStatusUnAuthenticatedQueryHookResult = ReturnType<typeof useUserHealthStatusUnAuthenticatedQuery>;
export type UserHealthStatusUnAuthenticatedLazyQueryHookResult = ReturnType<typeof useUserHealthStatusUnAuthenticatedLazyQuery>;
export type UserHealthStatusUnAuthenticatedQueryResult = Apollo.QueryResult<UserHealthStatusUnAuthenticatedQuery, UserHealthStatusUnAuthenticatedQueryVariables>;
export const UpdateUserHealthStatusUnAuthenticatedDocument = gql`
    mutation updateUserHealthStatusUnAuthenticated($status: String!, $location: String!, $slug: String!) {
  addUserHealthStatusUnAuthenticated(
    status: $status
    location: $location
    slug: $slug
  ) {
    isSuccess
  }
}
    `;
export type UpdateUserHealthStatusUnAuthenticatedMutationFn = Apollo.MutationFunction<UpdateUserHealthStatusUnAuthenticatedMutation, UpdateUserHealthStatusUnAuthenticatedMutationVariables>;

/**
 * __useUpdateUserHealthStatusUnAuthenticatedMutation__
 *
 * To run a mutation, you first call `useUpdateUserHealthStatusUnAuthenticatedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserHealthStatusUnAuthenticatedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserHealthStatusUnAuthenticatedMutation, { data, loading, error }] = useUpdateUserHealthStatusUnAuthenticatedMutation({
 *   variables: {
 *      status: // value for 'status'
 *      location: // value for 'location'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useUpdateUserHealthStatusUnAuthenticatedMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserHealthStatusUnAuthenticatedMutation, UpdateUserHealthStatusUnAuthenticatedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserHealthStatusUnAuthenticatedMutation, UpdateUserHealthStatusUnAuthenticatedMutationVariables>(UpdateUserHealthStatusUnAuthenticatedDocument, options);
      }
export type UpdateUserHealthStatusUnAuthenticatedMutationHookResult = ReturnType<typeof useUpdateUserHealthStatusUnAuthenticatedMutation>;
export type UpdateUserHealthStatusUnAuthenticatedMutationResult = Apollo.MutationResult<UpdateUserHealthStatusUnAuthenticatedMutation>;
export type UpdateUserHealthStatusUnAuthenticatedMutationOptions = Apollo.BaseMutationOptions<UpdateUserHealthStatusUnAuthenticatedMutation, UpdateUserHealthStatusUnAuthenticatedMutationVariables>;
export const GetExternalRoutesByLocationSlugDocument = gql`
    query getExternalRoutesByLocationSlug($locationSlug: String!) {
  getExternalRoutesByLocationSlug(locationSlug: $locationSlug) {
    id
    name
    externalRouteId
    status
    content
    location {
      name
    }
    updatedAt
  }
}
    `;

/**
 * __useGetExternalRoutesByLocationSlugQuery__
 *
 * To run a query within a React component, call `useGetExternalRoutesByLocationSlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExternalRoutesByLocationSlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExternalRoutesByLocationSlugQuery({
 *   variables: {
 *      locationSlug: // value for 'locationSlug'
 *   },
 * });
 */
export function useGetExternalRoutesByLocationSlugQuery(baseOptions: Apollo.QueryHookOptions<GetExternalRoutesByLocationSlugQuery, GetExternalRoutesByLocationSlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExternalRoutesByLocationSlugQuery, GetExternalRoutesByLocationSlugQueryVariables>(GetExternalRoutesByLocationSlugDocument, options);
      }
export function useGetExternalRoutesByLocationSlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExternalRoutesByLocationSlugQuery, GetExternalRoutesByLocationSlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExternalRoutesByLocationSlugQuery, GetExternalRoutesByLocationSlugQueryVariables>(GetExternalRoutesByLocationSlugDocument, options);
        }
export type GetExternalRoutesByLocationSlugQueryHookResult = ReturnType<typeof useGetExternalRoutesByLocationSlugQuery>;
export type GetExternalRoutesByLocationSlugLazyQueryHookResult = ReturnType<typeof useGetExternalRoutesByLocationSlugLazyQuery>;
export type GetExternalRoutesByLocationSlugQueryResult = Apollo.QueryResult<GetExternalRoutesByLocationSlugQuery, GetExternalRoutesByLocationSlugQueryVariables>;
export const GetLatestChecklistAnsweredAtByLocationSlugDocument = gql`
    query getLatestChecklistAnsweredAtByLocationSlug($locationSlug: String!) {
  getLatestChecklistAnsweredAtByLocationSlug(locationSlug: $locationSlug) {
    at
  }
}
    `;

/**
 * __useGetLatestChecklistAnsweredAtByLocationSlugQuery__
 *
 * To run a query within a React component, call `useGetLatestChecklistAnsweredAtByLocationSlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestChecklistAnsweredAtByLocationSlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestChecklistAnsweredAtByLocationSlugQuery({
 *   variables: {
 *      locationSlug: // value for 'locationSlug'
 *   },
 * });
 */
export function useGetLatestChecklistAnsweredAtByLocationSlugQuery(baseOptions: Apollo.QueryHookOptions<GetLatestChecklistAnsweredAtByLocationSlugQuery, GetLatestChecklistAnsweredAtByLocationSlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLatestChecklistAnsweredAtByLocationSlugQuery, GetLatestChecklistAnsweredAtByLocationSlugQueryVariables>(GetLatestChecklistAnsweredAtByLocationSlugDocument, options);
      }
export function useGetLatestChecklistAnsweredAtByLocationSlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLatestChecklistAnsweredAtByLocationSlugQuery, GetLatestChecklistAnsweredAtByLocationSlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLatestChecklistAnsweredAtByLocationSlugQuery, GetLatestChecklistAnsweredAtByLocationSlugQueryVariables>(GetLatestChecklistAnsweredAtByLocationSlugDocument, options);
        }
export type GetLatestChecklistAnsweredAtByLocationSlugQueryHookResult = ReturnType<typeof useGetLatestChecklistAnsweredAtByLocationSlugQuery>;
export type GetLatestChecklistAnsweredAtByLocationSlugLazyQueryHookResult = ReturnType<typeof useGetLatestChecklistAnsweredAtByLocationSlugLazyQuery>;
export type GetLatestChecklistAnsweredAtByLocationSlugQueryResult = Apollo.QueryResult<GetLatestChecklistAnsweredAtByLocationSlugQuery, GetLatestChecklistAnsweredAtByLocationSlugQueryVariables>;
export const GetRouteSafetyChecklistAnswerByRouteIdDocument = gql`
    query getRouteSafetyChecklistAnswerByRouteId($routeId: Int!) {
  getRouteSafetyChecklistAnswerByRouteId(routeId: $routeId) {
    id
    name
    content
    answers {
      id
      content
      answeredByUser {
        email
        picture
        name
      }
      createdAt
    }
  }
}
    `;

/**
 * __useGetRouteSafetyChecklistAnswerByRouteIdQuery__
 *
 * To run a query within a React component, call `useGetRouteSafetyChecklistAnswerByRouteIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRouteSafetyChecklistAnswerByRouteIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRouteSafetyChecklistAnswerByRouteIdQuery({
 *   variables: {
 *      routeId: // value for 'routeId'
 *   },
 * });
 */
export function useGetRouteSafetyChecklistAnswerByRouteIdQuery(baseOptions: Apollo.QueryHookOptions<GetRouteSafetyChecklistAnswerByRouteIdQuery, GetRouteSafetyChecklistAnswerByRouteIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRouteSafetyChecklistAnswerByRouteIdQuery, GetRouteSafetyChecklistAnswerByRouteIdQueryVariables>(GetRouteSafetyChecklistAnswerByRouteIdDocument, options);
      }
export function useGetRouteSafetyChecklistAnswerByRouteIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRouteSafetyChecklistAnswerByRouteIdQuery, GetRouteSafetyChecklistAnswerByRouteIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRouteSafetyChecklistAnswerByRouteIdQuery, GetRouteSafetyChecklistAnswerByRouteIdQueryVariables>(GetRouteSafetyChecklistAnswerByRouteIdDocument, options);
        }
export type GetRouteSafetyChecklistAnswerByRouteIdQueryHookResult = ReturnType<typeof useGetRouteSafetyChecklistAnswerByRouteIdQuery>;
export type GetRouteSafetyChecklistAnswerByRouteIdLazyQueryHookResult = ReturnType<typeof useGetRouteSafetyChecklistAnswerByRouteIdLazyQuery>;
export type GetRouteSafetyChecklistAnswerByRouteIdQueryResult = Apollo.QueryResult<GetRouteSafetyChecklistAnswerByRouteIdQuery, GetRouteSafetyChecklistAnswerByRouteIdQueryVariables>;
export const GetRouteSafetyChecklistAnswerByLocationSlugDocument = gql`
    query getRouteSafetyChecklistAnswerByLocationSlug($locationSlug: String!) {
  getRouteSafetyChecklistAnswerByLocationSlug(locationSlug: $locationSlug) {
    id
    name
    content
    answers {
      id
      content
      answeredByUser {
        email
        picture
        name
      }
      createdAt
    }
  }
}
    `;

/**
 * __useGetRouteSafetyChecklistAnswerByLocationSlugQuery__
 *
 * To run a query within a React component, call `useGetRouteSafetyChecklistAnswerByLocationSlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRouteSafetyChecklistAnswerByLocationSlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRouteSafetyChecklistAnswerByLocationSlugQuery({
 *   variables: {
 *      locationSlug: // value for 'locationSlug'
 *   },
 * });
 */
export function useGetRouteSafetyChecklistAnswerByLocationSlugQuery(baseOptions: Apollo.QueryHookOptions<GetRouteSafetyChecklistAnswerByLocationSlugQuery, GetRouteSafetyChecklistAnswerByLocationSlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRouteSafetyChecklistAnswerByLocationSlugQuery, GetRouteSafetyChecklistAnswerByLocationSlugQueryVariables>(GetRouteSafetyChecklistAnswerByLocationSlugDocument, options);
      }
export function useGetRouteSafetyChecklistAnswerByLocationSlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRouteSafetyChecklistAnswerByLocationSlugQuery, GetRouteSafetyChecklistAnswerByLocationSlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRouteSafetyChecklistAnswerByLocationSlugQuery, GetRouteSafetyChecklistAnswerByLocationSlugQueryVariables>(GetRouteSafetyChecklistAnswerByLocationSlugDocument, options);
        }
export type GetRouteSafetyChecklistAnswerByLocationSlugQueryHookResult = ReturnType<typeof useGetRouteSafetyChecklistAnswerByLocationSlugQuery>;
export type GetRouteSafetyChecklistAnswerByLocationSlugLazyQueryHookResult = ReturnType<typeof useGetRouteSafetyChecklistAnswerByLocationSlugLazyQuery>;
export type GetRouteSafetyChecklistAnswerByLocationSlugQueryResult = Apollo.QueryResult<GetRouteSafetyChecklistAnswerByLocationSlugQuery, GetRouteSafetyChecklistAnswerByLocationSlugQueryVariables>;
export const GetRouteSafetyChecklistByLocationSlugDocument = gql`
    query getRouteSafetyChecklistByLocationSlug($locationSlug: String!) {
  getRouteSafetyChecklistByLocationSlug(locationSlug: $locationSlug)
}
    `;

/**
 * __useGetRouteSafetyChecklistByLocationSlugQuery__
 *
 * To run a query within a React component, call `useGetRouteSafetyChecklistByLocationSlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRouteSafetyChecklistByLocationSlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRouteSafetyChecklistByLocationSlugQuery({
 *   variables: {
 *      locationSlug: // value for 'locationSlug'
 *   },
 * });
 */
export function useGetRouteSafetyChecklistByLocationSlugQuery(baseOptions: Apollo.QueryHookOptions<GetRouteSafetyChecklistByLocationSlugQuery, GetRouteSafetyChecklistByLocationSlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRouteSafetyChecklistByLocationSlugQuery, GetRouteSafetyChecklistByLocationSlugQueryVariables>(GetRouteSafetyChecklistByLocationSlugDocument, options);
      }
export function useGetRouteSafetyChecklistByLocationSlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRouteSafetyChecklistByLocationSlugQuery, GetRouteSafetyChecklistByLocationSlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRouteSafetyChecklistByLocationSlugQuery, GetRouteSafetyChecklistByLocationSlugQueryVariables>(GetRouteSafetyChecklistByLocationSlugDocument, options);
        }
export type GetRouteSafetyChecklistByLocationSlugQueryHookResult = ReturnType<typeof useGetRouteSafetyChecklistByLocationSlugQuery>;
export type GetRouteSafetyChecklistByLocationSlugLazyQueryHookResult = ReturnType<typeof useGetRouteSafetyChecklistByLocationSlugLazyQuery>;
export type GetRouteSafetyChecklistByLocationSlugQueryResult = Apollo.QueryResult<GetRouteSafetyChecklistByLocationSlugQuery, GetRouteSafetyChecklistByLocationSlugQueryVariables>;
export const GetLastUpdateAtForTruckDriversUploadDocument = gql`
    query getLastUpdateAtForTruckDriversUpload($locationSlug: String!) {
  getLastUpdateAtForTruckDriversUpload(locationSlug: $locationSlug) {
    updatedAt
  }
}
    `;

/**
 * __useGetLastUpdateAtForTruckDriversUploadQuery__
 *
 * To run a query within a React component, call `useGetLastUpdateAtForTruckDriversUploadQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastUpdateAtForTruckDriversUploadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastUpdateAtForTruckDriversUploadQuery({
 *   variables: {
 *      locationSlug: // value for 'locationSlug'
 *   },
 * });
 */
export function useGetLastUpdateAtForTruckDriversUploadQuery(baseOptions: Apollo.QueryHookOptions<GetLastUpdateAtForTruckDriversUploadQuery, GetLastUpdateAtForTruckDriversUploadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLastUpdateAtForTruckDriversUploadQuery, GetLastUpdateAtForTruckDriversUploadQueryVariables>(GetLastUpdateAtForTruckDriversUploadDocument, options);
      }
export function useGetLastUpdateAtForTruckDriversUploadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLastUpdateAtForTruckDriversUploadQuery, GetLastUpdateAtForTruckDriversUploadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLastUpdateAtForTruckDriversUploadQuery, GetLastUpdateAtForTruckDriversUploadQueryVariables>(GetLastUpdateAtForTruckDriversUploadDocument, options);
        }
export type GetLastUpdateAtForTruckDriversUploadQueryHookResult = ReturnType<typeof useGetLastUpdateAtForTruckDriversUploadQuery>;
export type GetLastUpdateAtForTruckDriversUploadLazyQueryHookResult = ReturnType<typeof useGetLastUpdateAtForTruckDriversUploadLazyQuery>;
export type GetLastUpdateAtForTruckDriversUploadQueryResult = Apollo.QueryResult<GetLastUpdateAtForTruckDriversUploadQuery, GetLastUpdateAtForTruckDriversUploadQueryVariables>;
export const GetRouteSafetyTruckDriversByLocationSlugDocument = gql`
    query getRouteSafetyTruckDriversByLocationSlug($locationSlug: String!) {
  getRouteSafetyTruckDriversByLocationSlug(locationSlug: $locationSlug) {
    id
    name
    info {
      content
    }
  }
}
    `;

/**
 * __useGetRouteSafetyTruckDriversByLocationSlugQuery__
 *
 * To run a query within a React component, call `useGetRouteSafetyTruckDriversByLocationSlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRouteSafetyTruckDriversByLocationSlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRouteSafetyTruckDriversByLocationSlugQuery({
 *   variables: {
 *      locationSlug: // value for 'locationSlug'
 *   },
 * });
 */
export function useGetRouteSafetyTruckDriversByLocationSlugQuery(baseOptions: Apollo.QueryHookOptions<GetRouteSafetyTruckDriversByLocationSlugQuery, GetRouteSafetyTruckDriversByLocationSlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRouteSafetyTruckDriversByLocationSlugQuery, GetRouteSafetyTruckDriversByLocationSlugQueryVariables>(GetRouteSafetyTruckDriversByLocationSlugDocument, options);
      }
export function useGetRouteSafetyTruckDriversByLocationSlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRouteSafetyTruckDriversByLocationSlugQuery, GetRouteSafetyTruckDriversByLocationSlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRouteSafetyTruckDriversByLocationSlugQuery, GetRouteSafetyTruckDriversByLocationSlugQueryVariables>(GetRouteSafetyTruckDriversByLocationSlugDocument, options);
        }
export type GetRouteSafetyTruckDriversByLocationSlugQueryHookResult = ReturnType<typeof useGetRouteSafetyTruckDriversByLocationSlugQuery>;
export type GetRouteSafetyTruckDriversByLocationSlugLazyQueryHookResult = ReturnType<typeof useGetRouteSafetyTruckDriversByLocationSlugLazyQuery>;
export type GetRouteSafetyTruckDriversByLocationSlugQueryResult = Apollo.QueryResult<GetRouteSafetyTruckDriversByLocationSlugQuery, GetRouteSafetyTruckDriversByLocationSlugQueryVariables>;
export const DeleteRouteSafetyTruckDriverDocument = gql`
    mutation deleteRouteSafetyTruckDriver($driverUserId: ID!) {
  deleteRouteSafetyTruckDriver(driverUserId: $driverUserId) {
    isSuccess
  }
}
    `;
export type DeleteRouteSafetyTruckDriverMutationFn = Apollo.MutationFunction<DeleteRouteSafetyTruckDriverMutation, DeleteRouteSafetyTruckDriverMutationVariables>;

/**
 * __useDeleteRouteSafetyTruckDriverMutation__
 *
 * To run a mutation, you first call `useDeleteRouteSafetyTruckDriverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRouteSafetyTruckDriverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRouteSafetyTruckDriverMutation, { data, loading, error }] = useDeleteRouteSafetyTruckDriverMutation({
 *   variables: {
 *      driverUserId: // value for 'driverUserId'
 *   },
 * });
 */
export function useDeleteRouteSafetyTruckDriverMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRouteSafetyTruckDriverMutation, DeleteRouteSafetyTruckDriverMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRouteSafetyTruckDriverMutation, DeleteRouteSafetyTruckDriverMutationVariables>(DeleteRouteSafetyTruckDriverDocument, options);
      }
export type DeleteRouteSafetyTruckDriverMutationHookResult = ReturnType<typeof useDeleteRouteSafetyTruckDriverMutation>;
export type DeleteRouteSafetyTruckDriverMutationResult = Apollo.MutationResult<DeleteRouteSafetyTruckDriverMutation>;
export type DeleteRouteSafetyTruckDriverMutationOptions = Apollo.BaseMutationOptions<DeleteRouteSafetyTruckDriverMutation, DeleteRouteSafetyTruckDriverMutationVariables>;
export const GetPlannedRoutesByLocationSlugDocument = gql`
    query GetPlannedRoutesByLocationSlug($locationSlug: String!) {
  getPlannedRoutesByLocationSlug(locationSlug: $locationSlug) {
    id
    route {
      id
      name
      externalRouteId
      status
      content
      location {
        name
      }
    }
    primaryTruckDriver {
      name
      id
      info {
        content
      }
    }
    secondaryTruckDriver {
      name
      id
      info {
        content
      }
    }
    plannedTruckDriver {
      id
      name
      info {
        content
      }
    }
    truck {
      sapNumber
      plateNumber
      content
    }
    createdAt
    secondDepartureStartedAt
  }
}
    `;

/**
 * __useGetPlannedRoutesByLocationSlugQuery__
 *
 * To run a query within a React component, call `useGetPlannedRoutesByLocationSlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlannedRoutesByLocationSlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlannedRoutesByLocationSlugQuery({
 *   variables: {
 *      locationSlug: // value for 'locationSlug'
 *   },
 * });
 */
export function useGetPlannedRoutesByLocationSlugQuery(baseOptions: Apollo.QueryHookOptions<GetPlannedRoutesByLocationSlugQuery, GetPlannedRoutesByLocationSlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlannedRoutesByLocationSlugQuery, GetPlannedRoutesByLocationSlugQueryVariables>(GetPlannedRoutesByLocationSlugDocument, options);
      }
export function useGetPlannedRoutesByLocationSlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlannedRoutesByLocationSlugQuery, GetPlannedRoutesByLocationSlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlannedRoutesByLocationSlugQuery, GetPlannedRoutesByLocationSlugQueryVariables>(GetPlannedRoutesByLocationSlugDocument, options);
        }
export type GetPlannedRoutesByLocationSlugQueryHookResult = ReturnType<typeof useGetPlannedRoutesByLocationSlugQuery>;
export type GetPlannedRoutesByLocationSlugLazyQueryHookResult = ReturnType<typeof useGetPlannedRoutesByLocationSlugLazyQuery>;
export type GetPlannedRoutesByLocationSlugQueryResult = Apollo.QueryResult<GetPlannedRoutesByLocationSlugQuery, GetPlannedRoutesByLocationSlugQueryVariables>;
export const GetAllTrucksByLocationSlugDocument = gql`
    query GetAllTrucksByLocationSlug($locationSlug: String!) {
  getAllTrucksByLocationSlug(locationSlug: $locationSlug) {
    id
    sapNumber
    plateNumber
    locationId
    content
    updatedAt
    plannedRoute {
      id
      route {
        id
        externalRouteId
      }
    }
  }
}
    `;

/**
 * __useGetAllTrucksByLocationSlugQuery__
 *
 * To run a query within a React component, call `useGetAllTrucksByLocationSlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTrucksByLocationSlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTrucksByLocationSlugQuery({
 *   variables: {
 *      locationSlug: // value for 'locationSlug'
 *   },
 * });
 */
export function useGetAllTrucksByLocationSlugQuery(baseOptions: Apollo.QueryHookOptions<GetAllTrucksByLocationSlugQuery, GetAllTrucksByLocationSlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllTrucksByLocationSlugQuery, GetAllTrucksByLocationSlugQueryVariables>(GetAllTrucksByLocationSlugDocument, options);
      }
export function useGetAllTrucksByLocationSlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllTrucksByLocationSlugQuery, GetAllTrucksByLocationSlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllTrucksByLocationSlugQuery, GetAllTrucksByLocationSlugQueryVariables>(GetAllTrucksByLocationSlugDocument, options);
        }
export type GetAllTrucksByLocationSlugQueryHookResult = ReturnType<typeof useGetAllTrucksByLocationSlugQuery>;
export type GetAllTrucksByLocationSlugLazyQueryHookResult = ReturnType<typeof useGetAllTrucksByLocationSlugLazyQuery>;
export type GetAllTrucksByLocationSlugQueryResult = Apollo.QueryResult<GetAllTrucksByLocationSlugQuery, GetAllTrucksByLocationSlugQueryVariables>;
export const GetTruckDriversDocument = gql`
    query GetTruckDrivers {
  getTruckDrivers {
    user {
      id
      name
      picture
      info {
        content
      }
    }
    status
    routeId
    externalRouteId
  }
}
    `;

/**
 * __useGetTruckDriversQuery__
 *
 * To run a query within a React component, call `useGetTruckDriversQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTruckDriversQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTruckDriversQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTruckDriversQuery(baseOptions?: Apollo.QueryHookOptions<GetTruckDriversQuery, GetTruckDriversQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTruckDriversQuery, GetTruckDriversQueryVariables>(GetTruckDriversDocument, options);
      }
export function useGetTruckDriversLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTruckDriversQuery, GetTruckDriversQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTruckDriversQuery, GetTruckDriversQueryVariables>(GetTruckDriversDocument, options);
        }
export type GetTruckDriversQueryHookResult = ReturnType<typeof useGetTruckDriversQuery>;
export type GetTruckDriversLazyQueryHookResult = ReturnType<typeof useGetTruckDriversLazyQuery>;
export type GetTruckDriversQueryResult = Apollo.QueryResult<GetTruckDriversQuery, GetTruckDriversQueryVariables>;
export const AssignTruckDriverDocument = gql`
    mutation AssignTruckDriver($plannedRouteId: ID!, $primaryDriverId: ID, $secondaryDriverId: ID) {
  assignTruckDriver(
    plannedRouteId: $plannedRouteId
    primaryDriverId: $primaryDriverId
    secondaryDriverId: $secondaryDriverId
  ) {
    isSuccess
  }
}
    `;
export type AssignTruckDriverMutationFn = Apollo.MutationFunction<AssignTruckDriverMutation, AssignTruckDriverMutationVariables>;

/**
 * __useAssignTruckDriverMutation__
 *
 * To run a mutation, you first call `useAssignTruckDriverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignTruckDriverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignTruckDriverMutation, { data, loading, error }] = useAssignTruckDriverMutation({
 *   variables: {
 *      plannedRouteId: // value for 'plannedRouteId'
 *      primaryDriverId: // value for 'primaryDriverId'
 *      secondaryDriverId: // value for 'secondaryDriverId'
 *   },
 * });
 */
export function useAssignTruckDriverMutation(baseOptions?: Apollo.MutationHookOptions<AssignTruckDriverMutation, AssignTruckDriverMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignTruckDriverMutation, AssignTruckDriverMutationVariables>(AssignTruckDriverDocument, options);
      }
export type AssignTruckDriverMutationHookResult = ReturnType<typeof useAssignTruckDriverMutation>;
export type AssignTruckDriverMutationResult = Apollo.MutationResult<AssignTruckDriverMutation>;
export type AssignTruckDriverMutationOptions = Apollo.BaseMutationOptions<AssignTruckDriverMutation, AssignTruckDriverMutationVariables>;
export const AssignTruckDocument = gql`
    mutation AssignTruck($plannedRouteId: ID!, $truckId: ID) {
  assignTruck(plannedRouteId: $plannedRouteId, truckId: $truckId) {
    isSuccess
  }
}
    `;
export type AssignTruckMutationFn = Apollo.MutationFunction<AssignTruckMutation, AssignTruckMutationVariables>;

/**
 * __useAssignTruckMutation__
 *
 * To run a mutation, you first call `useAssignTruckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignTruckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignTruckMutation, { data, loading, error }] = useAssignTruckMutation({
 *   variables: {
 *      plannedRouteId: // value for 'plannedRouteId'
 *      truckId: // value for 'truckId'
 *   },
 * });
 */
export function useAssignTruckMutation(baseOptions?: Apollo.MutationHookOptions<AssignTruckMutation, AssignTruckMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignTruckMutation, AssignTruckMutationVariables>(AssignTruckDocument, options);
      }
export type AssignTruckMutationHookResult = ReturnType<typeof useAssignTruckMutation>;
export type AssignTruckMutationResult = Apollo.MutationResult<AssignTruckMutation>;
export type AssignTruckMutationOptions = Apollo.BaseMutationOptions<AssignTruckMutation, AssignTruckMutationVariables>;
export const ChangeSecondDepartureStartedAtInPlannedRouteByIdDocument = gql`
    mutation ChangeSecondDepartureStartedAtInPlannedRouteById($plannedRouteId: ID!, $secondDepartureStartedAt: Date!) {
  changeSecondDepartureStartedAtInPlannedRouteById(
    plannedRouteId: $plannedRouteId
    secondDepartureStartedAt: $secondDepartureStartedAt
  ) {
    isSuccess
  }
}
    `;
export type ChangeSecondDepartureStartedAtInPlannedRouteByIdMutationFn = Apollo.MutationFunction<ChangeSecondDepartureStartedAtInPlannedRouteByIdMutation, ChangeSecondDepartureStartedAtInPlannedRouteByIdMutationVariables>;

/**
 * __useChangeSecondDepartureStartedAtInPlannedRouteByIdMutation__
 *
 * To run a mutation, you first call `useChangeSecondDepartureStartedAtInPlannedRouteByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeSecondDepartureStartedAtInPlannedRouteByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeSecondDepartureStartedAtInPlannedRouteByIdMutation, { data, loading, error }] = useChangeSecondDepartureStartedAtInPlannedRouteByIdMutation({
 *   variables: {
 *      plannedRouteId: // value for 'plannedRouteId'
 *      secondDepartureStartedAt: // value for 'secondDepartureStartedAt'
 *   },
 * });
 */
export function useChangeSecondDepartureStartedAtInPlannedRouteByIdMutation(baseOptions?: Apollo.MutationHookOptions<ChangeSecondDepartureStartedAtInPlannedRouteByIdMutation, ChangeSecondDepartureStartedAtInPlannedRouteByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeSecondDepartureStartedAtInPlannedRouteByIdMutation, ChangeSecondDepartureStartedAtInPlannedRouteByIdMutationVariables>(ChangeSecondDepartureStartedAtInPlannedRouteByIdDocument, options);
      }
export type ChangeSecondDepartureStartedAtInPlannedRouteByIdMutationHookResult = ReturnType<typeof useChangeSecondDepartureStartedAtInPlannedRouteByIdMutation>;
export type ChangeSecondDepartureStartedAtInPlannedRouteByIdMutationResult = Apollo.MutationResult<ChangeSecondDepartureStartedAtInPlannedRouteByIdMutation>;
export type ChangeSecondDepartureStartedAtInPlannedRouteByIdMutationOptions = Apollo.BaseMutationOptions<ChangeSecondDepartureStartedAtInPlannedRouteByIdMutation, ChangeSecondDepartureStartedAtInPlannedRouteByIdMutationVariables>;
export const GetLastUploadedAtTimestampDocument = gql`
    query getLastUploadedAtTimestamp($locationSlug: String!) {
  getLastUploadedAtTimestamp(locationSlug: $locationSlug) {
    updatedAt
  }
}
    `;

/**
 * __useGetLastUploadedAtTimestampQuery__
 *
 * To run a query within a React component, call `useGetLastUploadedAtTimestampQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastUploadedAtTimestampQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastUploadedAtTimestampQuery({
 *   variables: {
 *      locationSlug: // value for 'locationSlug'
 *   },
 * });
 */
export function useGetLastUploadedAtTimestampQuery(baseOptions: Apollo.QueryHookOptions<GetLastUploadedAtTimestampQuery, GetLastUploadedAtTimestampQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLastUploadedAtTimestampQuery, GetLastUploadedAtTimestampQueryVariables>(GetLastUploadedAtTimestampDocument, options);
      }
export function useGetLastUploadedAtTimestampLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLastUploadedAtTimestampQuery, GetLastUploadedAtTimestampQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLastUploadedAtTimestampQuery, GetLastUploadedAtTimestampQueryVariables>(GetLastUploadedAtTimestampDocument, options);
        }
export type GetLastUploadedAtTimestampQueryHookResult = ReturnType<typeof useGetLastUploadedAtTimestampQuery>;
export type GetLastUploadedAtTimestampLazyQueryHookResult = ReturnType<typeof useGetLastUploadedAtTimestampLazyQuery>;
export type GetLastUploadedAtTimestampQueryResult = Apollo.QueryResult<GetLastUploadedAtTimestampQuery, GetLastUploadedAtTimestampQueryVariables>;
export const GetSkapModulesAndSubmodulesCountDocument = gql`
    query getSKAPModulesAndSubmodulesCount($filter: getModuleSubmoduleCountFilterParams!) {
  getSKAPModulesAndSubmodulesCount(filter: $filter) {
    countByLevels {
      slug
      count
      percentage
      total
    }
    countByAutonomousLevel {
      title
      count
    }
    countByPosition {
      slug
      total
      impactedCount
      data {
        title
        percentage
      }
    }
    countByMonth {
      month
      score
    }
  }
}
    `;

/**
 * __useGetSkapModulesAndSubmodulesCountQuery__
 *
 * To run a query within a React component, call `useGetSkapModulesAndSubmodulesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSkapModulesAndSubmodulesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSkapModulesAndSubmodulesCountQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetSkapModulesAndSubmodulesCountQuery(baseOptions: Apollo.QueryHookOptions<GetSkapModulesAndSubmodulesCountQuery, GetSkapModulesAndSubmodulesCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSkapModulesAndSubmodulesCountQuery, GetSkapModulesAndSubmodulesCountQueryVariables>(GetSkapModulesAndSubmodulesCountDocument, options);
      }
export function useGetSkapModulesAndSubmodulesCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSkapModulesAndSubmodulesCountQuery, GetSkapModulesAndSubmodulesCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSkapModulesAndSubmodulesCountQuery, GetSkapModulesAndSubmodulesCountQueryVariables>(GetSkapModulesAndSubmodulesCountDocument, options);
        }
export type GetSkapModulesAndSubmodulesCountQueryHookResult = ReturnType<typeof useGetSkapModulesAndSubmodulesCountQuery>;
export type GetSkapModulesAndSubmodulesCountLazyQueryHookResult = ReturnType<typeof useGetSkapModulesAndSubmodulesCountLazyQuery>;
export type GetSkapModulesAndSubmodulesCountQueryResult = Apollo.QueryResult<GetSkapModulesAndSubmodulesCountQuery, GetSkapModulesAndSubmodulesCountQueryVariables>;
export const SendSkap_01NotificationDocument = gql`
    mutation SendSKAP_01Notification {
  sendNotificationToLineManager: sendNotification(type: "SKAP_LINE_MANAGERS_01") {
    isSuccess
  }
  sendNotificationToFrontLiners: sendNotification(type: "SKAP_FRONT_LINERS_01") {
    isSuccess
  }
}
    `;
export type SendSkap_01NotificationMutationFn = Apollo.MutationFunction<SendSkap_01NotificationMutation, SendSkap_01NotificationMutationVariables>;

/**
 * __useSendSkap_01NotificationMutation__
 *
 * To run a mutation, you first call `useSendSkap_01NotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendSkap_01NotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSkap_01NotificationMutation, { data, loading, error }] = useSendSkap_01NotificationMutation({
 *   variables: {
 *   },
 * });
 */
export function useSendSkap_01NotificationMutation(baseOptions?: Apollo.MutationHookOptions<SendSkap_01NotificationMutation, SendSkap_01NotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendSkap_01NotificationMutation, SendSkap_01NotificationMutationVariables>(SendSkap_01NotificationDocument, options);
      }
export type SendSkap_01NotificationMutationHookResult = ReturnType<typeof useSendSkap_01NotificationMutation>;
export type SendSkap_01NotificationMutationResult = Apollo.MutationResult<SendSkap_01NotificationMutation>;
export type SendSkap_01NotificationMutationOptions = Apollo.BaseMutationOptions<SendSkap_01NotificationMutation, SendSkap_01NotificationMutationVariables>;
export const KpIsListDocument = gql`
    query KPIsList($isGlobal: Boolean) {
  getAllKPI(isGlobal: $isGlobal) {
    id
    slug
    enTitle
    esTitle
  }
}
    `;

/**
 * __useKpIsListQuery__
 *
 * To run a query within a React component, call `useKpIsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useKpIsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKpIsListQuery({
 *   variables: {
 *      isGlobal: // value for 'isGlobal'
 *   },
 * });
 */
export function useKpIsListQuery(baseOptions?: Apollo.QueryHookOptions<KpIsListQuery, KpIsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KpIsListQuery, KpIsListQueryVariables>(KpIsListDocument, options);
      }
export function useKpIsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KpIsListQuery, KpIsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KpIsListQuery, KpIsListQueryVariables>(KpIsListDocument, options);
        }
export type KpIsListQueryHookResult = ReturnType<typeof useKpIsListQuery>;
export type KpIsListLazyQueryHookResult = ReturnType<typeof useKpIsListLazyQuery>;
export type KpIsListQueryResult = Apollo.QueryResult<KpIsListQuery, KpIsListQueryVariables>;
export const GetRoutineAvailableInfoDocument = gql`
    query GetRoutineAvailableInfo($forFilter: Boolean) {
  getRoutinesAvailable(forFilter: $forFilter) {
    name
    slug
  }
}
    `;

/**
 * __useGetRoutineAvailableInfoQuery__
 *
 * To run a query within a React component, call `useGetRoutineAvailableInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoutineAvailableInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoutineAvailableInfoQuery({
 *   variables: {
 *      forFilter: // value for 'forFilter'
 *   },
 * });
 */
export function useGetRoutineAvailableInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetRoutineAvailableInfoQuery, GetRoutineAvailableInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRoutineAvailableInfoQuery, GetRoutineAvailableInfoQueryVariables>(GetRoutineAvailableInfoDocument, options);
      }
export function useGetRoutineAvailableInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoutineAvailableInfoQuery, GetRoutineAvailableInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRoutineAvailableInfoQuery, GetRoutineAvailableInfoQueryVariables>(GetRoutineAvailableInfoDocument, options);
        }
export type GetRoutineAvailableInfoQueryHookResult = ReturnType<typeof useGetRoutineAvailableInfoQuery>;
export type GetRoutineAvailableInfoLazyQueryHookResult = ReturnType<typeof useGetRoutineAvailableInfoLazyQuery>;
export type GetRoutineAvailableInfoQueryResult = Apollo.QueryResult<GetRoutineAvailableInfoQuery, GetRoutineAvailableInfoQueryVariables>;
export const GetAllManagementToolDocument = gql`
    query getAllManagementTool {
  getAllManagementTool {
    id
    slug
    enTitle
    esTitle
  }
}
    `;

/**
 * __useGetAllManagementToolQuery__
 *
 * To run a query within a React component, call `useGetAllManagementToolQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllManagementToolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllManagementToolQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllManagementToolQuery(baseOptions?: Apollo.QueryHookOptions<GetAllManagementToolQuery, GetAllManagementToolQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllManagementToolQuery, GetAllManagementToolQueryVariables>(GetAllManagementToolDocument, options);
      }
export function useGetAllManagementToolLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllManagementToolQuery, GetAllManagementToolQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllManagementToolQuery, GetAllManagementToolQueryVariables>(GetAllManagementToolDocument, options);
        }
export type GetAllManagementToolQueryHookResult = ReturnType<typeof useGetAllManagementToolQuery>;
export type GetAllManagementToolLazyQueryHookResult = ReturnType<typeof useGetAllManagementToolLazyQuery>;
export type GetAllManagementToolQueryResult = Apollo.QueryResult<GetAllManagementToolQuery, GetAllManagementToolQueryVariables>;
export const SearchedUsersWithAccessToLocationDocument = gql`
    query SearchedUsersWithAccessToLocation($searchString: String!, $locationSlug: String!) {
  searchUsersWithAccessToLocation(
    searchString: $searchString
    locationSlug: $locationSlug
  ) {
    name
    slug
    id
    picture
  }
}
    `;

/**
 * __useSearchedUsersWithAccessToLocationQuery__
 *
 * To run a query within a React component, call `useSearchedUsersWithAccessToLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchedUsersWithAccessToLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchedUsersWithAccessToLocationQuery({
 *   variables: {
 *      searchString: // value for 'searchString'
 *      locationSlug: // value for 'locationSlug'
 *   },
 * });
 */
export function useSearchedUsersWithAccessToLocationQuery(baseOptions: Apollo.QueryHookOptions<SearchedUsersWithAccessToLocationQuery, SearchedUsersWithAccessToLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchedUsersWithAccessToLocationQuery, SearchedUsersWithAccessToLocationQueryVariables>(SearchedUsersWithAccessToLocationDocument, options);
      }
export function useSearchedUsersWithAccessToLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchedUsersWithAccessToLocationQuery, SearchedUsersWithAccessToLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchedUsersWithAccessToLocationQuery, SearchedUsersWithAccessToLocationQueryVariables>(SearchedUsersWithAccessToLocationDocument, options);
        }
export type SearchedUsersWithAccessToLocationQueryHookResult = ReturnType<typeof useSearchedUsersWithAccessToLocationQuery>;
export type SearchedUsersWithAccessToLocationLazyQueryHookResult = ReturnType<typeof useSearchedUsersWithAccessToLocationLazyQuery>;
export type SearchedUsersWithAccessToLocationQueryResult = Apollo.QueryResult<SearchedUsersWithAccessToLocationQuery, SearchedUsersWithAccessToLocationQueryVariables>;
export const ActionLogsCountGroupByLocationQueryDocument = gql`
    query ActionLogsCountGroupByLocationQuery($startTime: Date!, $endTime: Date!) {
  actionLogsCountGroupByLocation(startTime: $startTime, endTime: $endTime) {
    name
    slug
    actionLogsCount
    zones {
      name
      slug
      actionLogsCount
      subzones {
        name
        slug
        actionLogsCount
        locations {
          name
          slug
          cluster {
            name
            slug
          }
          actionLogsCount
        }
      }
    }
  }
}
    `;

/**
 * __useActionLogsCountGroupByLocationQueryQuery__
 *
 * To run a query within a React component, call `useActionLogsCountGroupByLocationQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useActionLogsCountGroupByLocationQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActionLogsCountGroupByLocationQueryQuery({
 *   variables: {
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *   },
 * });
 */
export function useActionLogsCountGroupByLocationQueryQuery(baseOptions: Apollo.QueryHookOptions<ActionLogsCountGroupByLocationQueryQuery, ActionLogsCountGroupByLocationQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActionLogsCountGroupByLocationQueryQuery, ActionLogsCountGroupByLocationQueryQueryVariables>(ActionLogsCountGroupByLocationQueryDocument, options);
      }
export function useActionLogsCountGroupByLocationQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActionLogsCountGroupByLocationQueryQuery, ActionLogsCountGroupByLocationQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActionLogsCountGroupByLocationQueryQuery, ActionLogsCountGroupByLocationQueryQueryVariables>(ActionLogsCountGroupByLocationQueryDocument, options);
        }
export type ActionLogsCountGroupByLocationQueryQueryHookResult = ReturnType<typeof useActionLogsCountGroupByLocationQueryQuery>;
export type ActionLogsCountGroupByLocationQueryLazyQueryHookResult = ReturnType<typeof useActionLogsCountGroupByLocationQueryLazyQuery>;
export type ActionLogsCountGroupByLocationQueryQueryResult = Apollo.QueryResult<ActionLogsCountGroupByLocationQueryQuery, ActionLogsCountGroupByLocationQueryQueryVariables>;
export const AllActionLogForLocationDocument = gql`
    query AllActionLogForLocation($actionLogsLocationSlug: String, $startTime: Date!, $endTime: Date!, $filters: ActionLogFilters) {
  groupActionLogsInDCByStatus(
    locationSlug: $actionLogsLocationSlug
    startTime: $startTime
    endTime: $endTime
    filters: $filters
  ) {
    OPEN {
      ...actionLogsInDCByStatus
    }
    IN_PROGRESS {
      ...actionLogsInDCByStatus
    }
    FOR_REVISION {
      ...actionLogsInDCByStatus
    }
    COMPLETED {
      ...actionLogsInDCByStatus
    }
  }
}
    ${ActionLogsInDcByStatusFragmentDoc}`;

/**
 * __useAllActionLogForLocationQuery__
 *
 * To run a query within a React component, call `useAllActionLogForLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllActionLogForLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllActionLogForLocationQuery({
 *   variables: {
 *      actionLogsLocationSlug: // value for 'actionLogsLocationSlug'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useAllActionLogForLocationQuery(baseOptions: Apollo.QueryHookOptions<AllActionLogForLocationQuery, AllActionLogForLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllActionLogForLocationQuery, AllActionLogForLocationQueryVariables>(AllActionLogForLocationDocument, options);
      }
export function useAllActionLogForLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllActionLogForLocationQuery, AllActionLogForLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllActionLogForLocationQuery, AllActionLogForLocationQueryVariables>(AllActionLogForLocationDocument, options);
        }
export type AllActionLogForLocationQueryHookResult = ReturnType<typeof useAllActionLogForLocationQuery>;
export type AllActionLogForLocationLazyQueryHookResult = ReturnType<typeof useAllActionLogForLocationLazyQuery>;
export type AllActionLogForLocationQueryResult = Apollo.QueryResult<AllActionLogForLocationQuery, AllActionLogForLocationQueryVariables>;
export const AllCommentsForActionLogDocument = gql`
    query AllCommentsForActionLog($commentsActionLogId: ID!) {
  comments(actionLogId: $commentsActionLogId) {
    id
    comment
  }
}
    `;

/**
 * __useAllCommentsForActionLogQuery__
 *
 * To run a query within a React component, call `useAllCommentsForActionLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCommentsForActionLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCommentsForActionLogQuery({
 *   variables: {
 *      commentsActionLogId: // value for 'commentsActionLogId'
 *   },
 * });
 */
export function useAllCommentsForActionLogQuery(baseOptions: Apollo.QueryHookOptions<AllCommentsForActionLogQuery, AllCommentsForActionLogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllCommentsForActionLogQuery, AllCommentsForActionLogQueryVariables>(AllCommentsForActionLogDocument, options);
      }
export function useAllCommentsForActionLogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllCommentsForActionLogQuery, AllCommentsForActionLogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllCommentsForActionLogQuery, AllCommentsForActionLogQueryVariables>(AllCommentsForActionLogDocument, options);
        }
export type AllCommentsForActionLogQueryHookResult = ReturnType<typeof useAllCommentsForActionLogQuery>;
export type AllCommentsForActionLogLazyQueryHookResult = ReturnType<typeof useAllCommentsForActionLogLazyQuery>;
export type AllCommentsForActionLogQueryResult = Apollo.QueryResult<AllCommentsForActionLogQuery, AllCommentsForActionLogQueryVariables>;
export const AddOrEditActionLogCommentDocument = gql`
    mutation AddOrEditActionLogComment($actionLogId: ID!, $comment: String, $commentId: ID) {
  addOrEditComment(
    actionLogId: $actionLogId
    comment: $comment
    commentId: $commentId
  ) {
    isSuccess
  }
}
    `;
export type AddOrEditActionLogCommentMutationFn = Apollo.MutationFunction<AddOrEditActionLogCommentMutation, AddOrEditActionLogCommentMutationVariables>;

/**
 * __useAddOrEditActionLogCommentMutation__
 *
 * To run a mutation, you first call `useAddOrEditActionLogCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrEditActionLogCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrEditActionLogCommentMutation, { data, loading, error }] = useAddOrEditActionLogCommentMutation({
 *   variables: {
 *      actionLogId: // value for 'actionLogId'
 *      comment: // value for 'comment'
 *      commentId: // value for 'commentId'
 *   },
 * });
 */
export function useAddOrEditActionLogCommentMutation(baseOptions?: Apollo.MutationHookOptions<AddOrEditActionLogCommentMutation, AddOrEditActionLogCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddOrEditActionLogCommentMutation, AddOrEditActionLogCommentMutationVariables>(AddOrEditActionLogCommentDocument, options);
      }
export type AddOrEditActionLogCommentMutationHookResult = ReturnType<typeof useAddOrEditActionLogCommentMutation>;
export type AddOrEditActionLogCommentMutationResult = Apollo.MutationResult<AddOrEditActionLogCommentMutation>;
export type AddOrEditActionLogCommentMutationOptions = Apollo.BaseMutationOptions<AddOrEditActionLogCommentMutation, AddOrEditActionLogCommentMutationVariables>;
export const DetailedCommentsForActionLogDocument = gql`
    query DetailedCommentsForActionLog($commentsActionLogId: ID!) {
  comments(actionLogId: $commentsActionLogId, orderByCreatedAt: DESC) {
    id
    comment
    createdAt
    updatedAt
    user {
      name
      slug
      picture
    }
  }
}
    `;

/**
 * __useDetailedCommentsForActionLogQuery__
 *
 * To run a query within a React component, call `useDetailedCommentsForActionLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useDetailedCommentsForActionLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDetailedCommentsForActionLogQuery({
 *   variables: {
 *      commentsActionLogId: // value for 'commentsActionLogId'
 *   },
 * });
 */
export function useDetailedCommentsForActionLogQuery(baseOptions: Apollo.QueryHookOptions<DetailedCommentsForActionLogQuery, DetailedCommentsForActionLogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DetailedCommentsForActionLogQuery, DetailedCommentsForActionLogQueryVariables>(DetailedCommentsForActionLogDocument, options);
      }
export function useDetailedCommentsForActionLogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DetailedCommentsForActionLogQuery, DetailedCommentsForActionLogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DetailedCommentsForActionLogQuery, DetailedCommentsForActionLogQueryVariables>(DetailedCommentsForActionLogDocument, options);
        }
export type DetailedCommentsForActionLogQueryHookResult = ReturnType<typeof useDetailedCommentsForActionLogQuery>;
export type DetailedCommentsForActionLogLazyQueryHookResult = ReturnType<typeof useDetailedCommentsForActionLogLazyQuery>;
export type DetailedCommentsForActionLogQueryResult = Apollo.QueryResult<DetailedCommentsForActionLogQuery, DetailedCommentsForActionLogQueryVariables>;
export const SummaryForActionLogDocument = gql`
    query SummaryForActionLog($actionLogId: ID!) {
  actionLog(id: $actionLogId) {
    id
    startDate
    description
    KPIs
    KPI {
      esTitle
      enTitle
      slug
    }
    ManagementTool {
      esTitle
      enTitle
      slug
      id
    }
    endDate
    status
    pillar
    owner {
      id
      name
      slug
      picture
    }
    responsibles {
      id
      name
      slug
      picture
    }
    location {
      slug
      name
    }
    origin
    originText
    meeting
    note
    images {
      id
      url
    }
    statusUpdateDate
    routinesAvailable {
      name
      slug
    }
  }
}
    `;

/**
 * __useSummaryForActionLogQuery__
 *
 * To run a query within a React component, call `useSummaryForActionLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useSummaryForActionLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSummaryForActionLogQuery({
 *   variables: {
 *      actionLogId: // value for 'actionLogId'
 *   },
 * });
 */
export function useSummaryForActionLogQuery(baseOptions: Apollo.QueryHookOptions<SummaryForActionLogQuery, SummaryForActionLogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SummaryForActionLogQuery, SummaryForActionLogQueryVariables>(SummaryForActionLogDocument, options);
      }
export function useSummaryForActionLogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SummaryForActionLogQuery, SummaryForActionLogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SummaryForActionLogQuery, SummaryForActionLogQueryVariables>(SummaryForActionLogDocument, options);
        }
export type SummaryForActionLogQueryHookResult = ReturnType<typeof useSummaryForActionLogQuery>;
export type SummaryForActionLogLazyQueryHookResult = ReturnType<typeof useSummaryForActionLogLazyQuery>;
export type SummaryForActionLogQueryResult = Apollo.QueryResult<SummaryForActionLogQuery, SummaryForActionLogQueryVariables>;
export const DeleteActionLogCommentDocument = gql`
    mutation deleteActionLogComment($commentId: ID!) {
  deleteComment(commentId: $commentId) {
    isSuccess
  }
}
    `;
export type DeleteActionLogCommentMutationFn = Apollo.MutationFunction<DeleteActionLogCommentMutation, DeleteActionLogCommentMutationVariables>;

/**
 * __useDeleteActionLogCommentMutation__
 *
 * To run a mutation, you first call `useDeleteActionLogCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteActionLogCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteActionLogCommentMutation, { data, loading, error }] = useDeleteActionLogCommentMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *   },
 * });
 */
export function useDeleteActionLogCommentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteActionLogCommentMutation, DeleteActionLogCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteActionLogCommentMutation, DeleteActionLogCommentMutationVariables>(DeleteActionLogCommentDocument, options);
      }
export type DeleteActionLogCommentMutationHookResult = ReturnType<typeof useDeleteActionLogCommentMutation>;
export type DeleteActionLogCommentMutationResult = Apollo.MutationResult<DeleteActionLogCommentMutation>;
export type DeleteActionLogCommentMutationOptions = Apollo.BaseMutationOptions<DeleteActionLogCommentMutation, DeleteActionLogCommentMutationVariables>;
export const ActionLogStatusDocument = gql`
    mutation ActionLogStatus($actionLogId: Int!, $status: ACTION_LOG_STATUS!, $isOwnerCheckSurpassed: Boolean) {
  updateActionLogStatus(
    actionLogId: $actionLogId
    status: $status
    isOwnerCheckSurpassed: $isOwnerCheckSurpassed
  ) {
    isSuccess
  }
}
    `;
export type ActionLogStatusMutationFn = Apollo.MutationFunction<ActionLogStatusMutation, ActionLogStatusMutationVariables>;

/**
 * __useActionLogStatusMutation__
 *
 * To run a mutation, you first call `useActionLogStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionLogStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionLogStatusMutation, { data, loading, error }] = useActionLogStatusMutation({
 *   variables: {
 *      actionLogId: // value for 'actionLogId'
 *      status: // value for 'status'
 *      isOwnerCheckSurpassed: // value for 'isOwnerCheckSurpassed'
 *   },
 * });
 */
export function useActionLogStatusMutation(baseOptions?: Apollo.MutationHookOptions<ActionLogStatusMutation, ActionLogStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActionLogStatusMutation, ActionLogStatusMutationVariables>(ActionLogStatusDocument, options);
      }
export type ActionLogStatusMutationHookResult = ReturnType<typeof useActionLogStatusMutation>;
export type ActionLogStatusMutationResult = Apollo.MutationResult<ActionLogStatusMutation>;
export type ActionLogStatusMutationOptions = Apollo.BaseMutationOptions<ActionLogStatusMutation, ActionLogStatusMutationVariables>;
export const CreateEditActionLogDocument = gql`
    mutation CreateEditActionLog($payload: CreateEditActionLogPayload!) {
  createEditActionLog(payload: $payload) {
    isSuccess
  }
}
    `;
export type CreateEditActionLogMutationFn = Apollo.MutationFunction<CreateEditActionLogMutation, CreateEditActionLogMutationVariables>;

/**
 * __useCreateEditActionLogMutation__
 *
 * To run a mutation, you first call `useCreateEditActionLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEditActionLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEditActionLogMutation, { data, loading, error }] = useCreateEditActionLogMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useCreateEditActionLogMutation(baseOptions?: Apollo.MutationHookOptions<CreateEditActionLogMutation, CreateEditActionLogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEditActionLogMutation, CreateEditActionLogMutationVariables>(CreateEditActionLogDocument, options);
      }
export type CreateEditActionLogMutationHookResult = ReturnType<typeof useCreateEditActionLogMutation>;
export type CreateEditActionLogMutationResult = Apollo.MutationResult<CreateEditActionLogMutation>;
export type CreateEditActionLogMutationOptions = Apollo.BaseMutationOptions<CreateEditActionLogMutation, CreateEditActionLogMutationVariables>;
export const DeleteActionLogDocument = gql`
    mutation DeleteActionLog($actionLogId: Int!) {
  deleteActionLog(actionLogId: $actionLogId) {
    isSuccess
  }
}
    `;
export type DeleteActionLogMutationFn = Apollo.MutationFunction<DeleteActionLogMutation, DeleteActionLogMutationVariables>;

/**
 * __useDeleteActionLogMutation__
 *
 * To run a mutation, you first call `useDeleteActionLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteActionLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteActionLogMutation, { data, loading, error }] = useDeleteActionLogMutation({
 *   variables: {
 *      actionLogId: // value for 'actionLogId'
 *   },
 * });
 */
export function useDeleteActionLogMutation(baseOptions?: Apollo.MutationHookOptions<DeleteActionLogMutation, DeleteActionLogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteActionLogMutation, DeleteActionLogMutationVariables>(DeleteActionLogDocument, options);
      }
export type DeleteActionLogMutationHookResult = ReturnType<typeof useDeleteActionLogMutation>;
export type DeleteActionLogMutationResult = Apollo.MutationResult<DeleteActionLogMutation>;
export type DeleteActionLogMutationOptions = Apollo.BaseMutationOptions<DeleteActionLogMutation, DeleteActionLogMutationVariables>;
export const GetDashboardTableDataDocument = gql`
    query getDashboardTableData($locationIds: [ID]!, $groupBy: JSON, $date: JSON) {
  getDashboardTableData(locationIds: $locationIds, groupBy: $groupBy, date: $date)
}
    `;

/**
 * __useGetDashboardTableDataQuery__
 *
 * To run a query within a React component, call `useGetDashboardTableDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardTableDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardTableDataQuery({
 *   variables: {
 *      locationIds: // value for 'locationIds'
 *      groupBy: // value for 'groupBy'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetDashboardTableDataQuery(baseOptions: Apollo.QueryHookOptions<GetDashboardTableDataQuery, GetDashboardTableDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDashboardTableDataQuery, GetDashboardTableDataQueryVariables>(GetDashboardTableDataDocument, options);
      }
export function useGetDashboardTableDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDashboardTableDataQuery, GetDashboardTableDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDashboardTableDataQuery, GetDashboardTableDataQueryVariables>(GetDashboardTableDataDocument, options);
        }
export type GetDashboardTableDataQueryHookResult = ReturnType<typeof useGetDashboardTableDataQuery>;
export type GetDashboardTableDataLazyQueryHookResult = ReturnType<typeof useGetDashboardTableDataLazyQuery>;
export type GetDashboardTableDataQueryResult = Apollo.QueryResult<GetDashboardTableDataQuery, GetDashboardTableDataQueryVariables>;
export const GetAll5SByLocationIdDocument = gql`
    query getAll5SByLocationId($locationId: ID!, $monthNumber: Int, $areaId: Int, $subAreaId: Int, $status: String, $userSearch: String) {
  getAll5SByLocationId(
    locationId: $locationId
    monthNumber: $monthNumber
    areaId: $areaId
    subAreaId: $subAreaId
    status: $status
    userSearch: $userSearch
  )
}
    `;

/**
 * __useGetAll5SByLocationIdQuery__
 *
 * To run a query within a React component, call `useGetAll5SByLocationIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAll5SByLocationIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAll5SByLocationIdQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      monthNumber: // value for 'monthNumber'
 *      areaId: // value for 'areaId'
 *      subAreaId: // value for 'subAreaId'
 *      status: // value for 'status'
 *      userSearch: // value for 'userSearch'
 *   },
 * });
 */
export function useGetAll5SByLocationIdQuery(baseOptions: Apollo.QueryHookOptions<GetAll5SByLocationIdQuery, GetAll5SByLocationIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAll5SByLocationIdQuery, GetAll5SByLocationIdQueryVariables>(GetAll5SByLocationIdDocument, options);
      }
export function useGetAll5SByLocationIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAll5SByLocationIdQuery, GetAll5SByLocationIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAll5SByLocationIdQuery, GetAll5SByLocationIdQueryVariables>(GetAll5SByLocationIdDocument, options);
        }
export type GetAll5SByLocationIdQueryHookResult = ReturnType<typeof useGetAll5SByLocationIdQuery>;
export type GetAll5SByLocationIdLazyQueryHookResult = ReturnType<typeof useGetAll5SByLocationIdLazyQuery>;
export type GetAll5SByLocationIdQueryResult = Apollo.QueryResult<GetAll5SByLocationIdQuery, GetAll5SByLocationIdQueryVariables>;
export const Delete5SChecklistsDocument = gql`
    mutation Delete5SChecklists($args: delete5SChecklistsInput!) {
  delete5SChecklists(args: $args) {
    isSuccess
  }
}
    `;
export type Delete5SChecklistsMutationFn = Apollo.MutationFunction<Delete5SChecklistsMutation, Delete5SChecklistsMutationVariables>;

/**
 * __useDelete5SChecklistsMutation__
 *
 * To run a mutation, you first call `useDelete5SChecklistsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete5SChecklistsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [delete5SChecklistsMutation, { data, loading, error }] = useDelete5SChecklistsMutation({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useDelete5SChecklistsMutation(baseOptions?: Apollo.MutationHookOptions<Delete5SChecklistsMutation, Delete5SChecklistsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Delete5SChecklistsMutation, Delete5SChecklistsMutationVariables>(Delete5SChecklistsDocument, options);
      }
export type Delete5SChecklistsMutationHookResult = ReturnType<typeof useDelete5SChecklistsMutation>;
export type Delete5SChecklistsMutationResult = Apollo.MutationResult<Delete5SChecklistsMutation>;
export type Delete5SChecklistsMutationOptions = Apollo.BaseMutationOptions<Delete5SChecklistsMutation, Delete5SChecklistsMutationVariables>;
export const Update5SChecklistsDocument = gql`
    mutation Update5SChecklists($args: userChecklistInput!) {
  update5SChecklists(args: $args) {
    isSuccess
  }
}
    `;
export type Update5SChecklistsMutationFn = Apollo.MutationFunction<Update5SChecklistsMutation, Update5SChecklistsMutationVariables>;

/**
 * __useUpdate5SChecklistsMutation__
 *
 * To run a mutation, you first call `useUpdate5SChecklistsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate5SChecklistsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [update5SChecklistsMutation, { data, loading, error }] = useUpdate5SChecklistsMutation({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useUpdate5SChecklistsMutation(baseOptions?: Apollo.MutationHookOptions<Update5SChecklistsMutation, Update5SChecklistsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Update5SChecklistsMutation, Update5SChecklistsMutationVariables>(Update5SChecklistsDocument, options);
      }
export type Update5SChecklistsMutationHookResult = ReturnType<typeof useUpdate5SChecklistsMutation>;
export type Update5SChecklistsMutationResult = Apollo.MutationResult<Update5SChecklistsMutation>;
export type Update5SChecklistsMutationOptions = Apollo.BaseMutationOptions<Update5SChecklistsMutation, Update5SChecklistsMutationVariables>;
export const AddOrEditCapexCommentDocument = gql`
    mutation AddOrEditCapexComment($capexId: ID!, $comment: String!, $commentId: ID) {
  addOrEditCapexComment(
    capexId: $capexId
    comment: $comment
    commentId: $commentId
  ) {
    isSuccess
  }
}
    `;
export type AddOrEditCapexCommentMutationFn = Apollo.MutationFunction<AddOrEditCapexCommentMutation, AddOrEditCapexCommentMutationVariables>;

/**
 * __useAddOrEditCapexCommentMutation__
 *
 * To run a mutation, you first call `useAddOrEditCapexCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrEditCapexCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrEditCapexCommentMutation, { data, loading, error }] = useAddOrEditCapexCommentMutation({
 *   variables: {
 *      capexId: // value for 'capexId'
 *      comment: // value for 'comment'
 *      commentId: // value for 'commentId'
 *   },
 * });
 */
export function useAddOrEditCapexCommentMutation(baseOptions?: Apollo.MutationHookOptions<AddOrEditCapexCommentMutation, AddOrEditCapexCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddOrEditCapexCommentMutation, AddOrEditCapexCommentMutationVariables>(AddOrEditCapexCommentDocument, options);
      }
export type AddOrEditCapexCommentMutationHookResult = ReturnType<typeof useAddOrEditCapexCommentMutation>;
export type AddOrEditCapexCommentMutationResult = Apollo.MutationResult<AddOrEditCapexCommentMutation>;
export type AddOrEditCapexCommentMutationOptions = Apollo.BaseMutationOptions<AddOrEditCapexCommentMutation, AddOrEditCapexCommentMutationVariables>;
export const DeleteCapexCommentDocument = gql`
    mutation DeleteCapexComment($commentId: ID!) {
  deleteCapexComment(commentId: $commentId) {
    isSuccess
  }
}
    `;
export type DeleteCapexCommentMutationFn = Apollo.MutationFunction<DeleteCapexCommentMutation, DeleteCapexCommentMutationVariables>;

/**
 * __useDeleteCapexCommentMutation__
 *
 * To run a mutation, you first call `useDeleteCapexCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCapexCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCapexCommentMutation, { data, loading, error }] = useDeleteCapexCommentMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *   },
 * });
 */
export function useDeleteCapexCommentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCapexCommentMutation, DeleteCapexCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCapexCommentMutation, DeleteCapexCommentMutationVariables>(DeleteCapexCommentDocument, options);
      }
export type DeleteCapexCommentMutationHookResult = ReturnType<typeof useDeleteCapexCommentMutation>;
export type DeleteCapexCommentMutationResult = Apollo.MutationResult<DeleteCapexCommentMutation>;
export type DeleteCapexCommentMutationOptions = Apollo.BaseMutationOptions<DeleteCapexCommentMutation, DeleteCapexCommentMutationVariables>;
export const CapexCommentsDocument = gql`
    query CapexComments($capexId: ID!) {
  capexComments(capexId: $capexId, orderByCreatedAt: DESC) {
    id
    comment
    createdAt
    updatedAt
    user {
      name
      slug
      picture
    }
  }
}
    `;

/**
 * __useCapexCommentsQuery__
 *
 * To run a query within a React component, call `useCapexCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCapexCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCapexCommentsQuery({
 *   variables: {
 *      capexId: // value for 'capexId'
 *   },
 * });
 */
export function useCapexCommentsQuery(baseOptions: Apollo.QueryHookOptions<CapexCommentsQuery, CapexCommentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CapexCommentsQuery, CapexCommentsQueryVariables>(CapexCommentsDocument, options);
      }
export function useCapexCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CapexCommentsQuery, CapexCommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CapexCommentsQuery, CapexCommentsQueryVariables>(CapexCommentsDocument, options);
        }
export type CapexCommentsQueryHookResult = ReturnType<typeof useCapexCommentsQuery>;
export type CapexCommentsLazyQueryHookResult = ReturnType<typeof useCapexCommentsLazyQuery>;
export type CapexCommentsQueryResult = Apollo.QueryResult<CapexCommentsQuery, CapexCommentsQueryVariables>;
export const GetIncidentsByMonthsCategoriesAndTypeDocument = gql`
    query getIncidentsByMonthsCategoriesAndType($monthNumber: Int!, $year: Int!) {
  getIncidentsBetweenRange(monthNumber: $monthNumber, year: $year) {
    bu
    month
    weeks {
      week
      totalIncidents
    }
  }
  getIncidentsByCategories(monthNumber: $monthNumber, year: $year) {
    categoryName
    bus {
      name
      totalIncidents
    }
  }
  getIncidentsByMonths(monthNumber: $monthNumber, year: $year) {
    bu
    months {
      name
      totalIncidents
    }
  }
  topBest: getIncidentsByType(
    by: cd
    type: best
    monthNumber: $monthNumber
    year: $year
  ) {
    bu
    name
    totalIncidents
  }
  topOffenders: getIncidentsByType(
    by: cd
    type: offender
    monthNumber: $monthNumber
    year: $year
  ) {
    bu
    name
    totalIncidents
  }
  topVehicleOffenders: getIncidentsByType(
    by: vehical
    type: offender
    monthNumber: $monthNumber
    year: $year
  ) {
    bu
    name
    totalIncidents
  }
}
    `;

/**
 * __useGetIncidentsByMonthsCategoriesAndTypeQuery__
 *
 * To run a query within a React component, call `useGetIncidentsByMonthsCategoriesAndTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIncidentsByMonthsCategoriesAndTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIncidentsByMonthsCategoriesAndTypeQuery({
 *   variables: {
 *      monthNumber: // value for 'monthNumber'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useGetIncidentsByMonthsCategoriesAndTypeQuery(baseOptions: Apollo.QueryHookOptions<GetIncidentsByMonthsCategoriesAndTypeQuery, GetIncidentsByMonthsCategoriesAndTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIncidentsByMonthsCategoriesAndTypeQuery, GetIncidentsByMonthsCategoriesAndTypeQueryVariables>(GetIncidentsByMonthsCategoriesAndTypeDocument, options);
      }
export function useGetIncidentsByMonthsCategoriesAndTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIncidentsByMonthsCategoriesAndTypeQuery, GetIncidentsByMonthsCategoriesAndTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIncidentsByMonthsCategoriesAndTypeQuery, GetIncidentsByMonthsCategoriesAndTypeQueryVariables>(GetIncidentsByMonthsCategoriesAndTypeDocument, options);
        }
export type GetIncidentsByMonthsCategoriesAndTypeQueryHookResult = ReturnType<typeof useGetIncidentsByMonthsCategoriesAndTypeQuery>;
export type GetIncidentsByMonthsCategoriesAndTypeLazyQueryHookResult = ReturnType<typeof useGetIncidentsByMonthsCategoriesAndTypeLazyQuery>;
export type GetIncidentsByMonthsCategoriesAndTypeQueryResult = Apollo.QueryResult<GetIncidentsByMonthsCategoriesAndTypeQuery, GetIncidentsByMonthsCategoriesAndTypeQueryVariables>;
export const GetIncidentsByTypePerBuDocument = gql`
    query getIncidentsByTypePerBu($monthNumber: Int!, $year: Int!, $bu: String) {
  topBest: getIncidentsByType(
    by: cd
    type: best
    monthNumber: $monthNumber
    year: $year
    filterOnCountry: $bu
  ) {
    bu
    name
    totalIncidents
  }
  topOffenders: getIncidentsByType(
    by: cd
    type: offender
    monthNumber: $monthNumber
    year: $year
    filterOnCountry: $bu
  ) {
    bu
    name
    totalIncidents
  }
  topVehicleOffenders: getIncidentsByType(
    by: vehical
    type: offender
    monthNumber: $monthNumber
    year: $year
    filterOnCountry: $bu
  ) {
    bu
    name
    totalIncidents
  }
}
    `;

/**
 * __useGetIncidentsByTypePerBuQuery__
 *
 * To run a query within a React component, call `useGetIncidentsByTypePerBuQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIncidentsByTypePerBuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIncidentsByTypePerBuQuery({
 *   variables: {
 *      monthNumber: // value for 'monthNumber'
 *      year: // value for 'year'
 *      bu: // value for 'bu'
 *   },
 * });
 */
export function useGetIncidentsByTypePerBuQuery(baseOptions: Apollo.QueryHookOptions<GetIncidentsByTypePerBuQuery, GetIncidentsByTypePerBuQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIncidentsByTypePerBuQuery, GetIncidentsByTypePerBuQueryVariables>(GetIncidentsByTypePerBuDocument, options);
      }
export function useGetIncidentsByTypePerBuLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIncidentsByTypePerBuQuery, GetIncidentsByTypePerBuQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIncidentsByTypePerBuQuery, GetIncidentsByTypePerBuQueryVariables>(GetIncidentsByTypePerBuDocument, options);
        }
export type GetIncidentsByTypePerBuQueryHookResult = ReturnType<typeof useGetIncidentsByTypePerBuQuery>;
export type GetIncidentsByTypePerBuLazyQueryHookResult = ReturnType<typeof useGetIncidentsByTypePerBuLazyQuery>;
export type GetIncidentsByTypePerBuQueryResult = Apollo.QueryResult<GetIncidentsByTypePerBuQuery, GetIncidentsByTypePerBuQueryVariables>;
export const GetTrainingsDocument = gql`
    query getTrainings($data: GetTrainingsPayload!, $by: GetTrainingsBy!, $isFindOne: Boolean) {
  getTrainings(data: $data, by: $by, isFindOne: $isFindOne) {
    usersToTrain
    usersTrained
    name
    percentage
  }
}
    `;

/**
 * __useGetTrainingsQuery__
 *
 * To run a query within a React component, call `useGetTrainingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTrainingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrainingsQuery({
 *   variables: {
 *      data: // value for 'data'
 *      by: // value for 'by'
 *      isFindOne: // value for 'isFindOne'
 *   },
 * });
 */
export function useGetTrainingsQuery(baseOptions: Apollo.QueryHookOptions<GetTrainingsQuery, GetTrainingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTrainingsQuery, GetTrainingsQueryVariables>(GetTrainingsDocument, options);
      }
export function useGetTrainingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTrainingsQuery, GetTrainingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTrainingsQuery, GetTrainingsQueryVariables>(GetTrainingsDocument, options);
        }
export type GetTrainingsQueryHookResult = ReturnType<typeof useGetTrainingsQuery>;
export type GetTrainingsLazyQueryHookResult = ReturnType<typeof useGetTrainingsLazyQuery>;
export type GetTrainingsQueryResult = Apollo.QueryResult<GetTrainingsQuery, GetTrainingsQueryVariables>;
export const GetTrainingsByPositionDocument = gql`
    query getTrainingsByPosition($data: GetTrainingsPayload!, $by: GetTrainingsBy!, $isFindOne: Boolean) {
  getTrainings(data: $data, by: $by, isFindOne: $isFindOne) {
    usersToTrain
    usersTrained
    position
    percentage
  }
}
    `;

/**
 * __useGetTrainingsByPositionQuery__
 *
 * To run a query within a React component, call `useGetTrainingsByPositionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTrainingsByPositionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrainingsByPositionQuery({
 *   variables: {
 *      data: // value for 'data'
 *      by: // value for 'by'
 *      isFindOne: // value for 'isFindOne'
 *   },
 * });
 */
export function useGetTrainingsByPositionQuery(baseOptions: Apollo.QueryHookOptions<GetTrainingsByPositionQuery, GetTrainingsByPositionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTrainingsByPositionQuery, GetTrainingsByPositionQueryVariables>(GetTrainingsByPositionDocument, options);
      }
export function useGetTrainingsByPositionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTrainingsByPositionQuery, GetTrainingsByPositionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTrainingsByPositionQuery, GetTrainingsByPositionQueryVariables>(GetTrainingsByPositionDocument, options);
        }
export type GetTrainingsByPositionQueryHookResult = ReturnType<typeof useGetTrainingsByPositionQuery>;
export type GetTrainingsByPositionLazyQueryHookResult = ReturnType<typeof useGetTrainingsByPositionLazyQuery>;
export type GetTrainingsByPositionQueryResult = Apollo.QueryResult<GetTrainingsByPositionQuery, GetTrainingsByPositionQueryVariables>;
export const GetSifStatsDocument = gql`
    query getSifStats($filter: filterParams!, $by: GetSifStatsBy!, $year: Int!) {
  getSifStats(filter: $filter, by: $by, year: $year) {
    name
    totalSif
  }
}
    `;

/**
 * __useGetSifStatsQuery__
 *
 * To run a query within a React component, call `useGetSifStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSifStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSifStatsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      by: // value for 'by'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useGetSifStatsQuery(baseOptions: Apollo.QueryHookOptions<GetSifStatsQuery, GetSifStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSifStatsQuery, GetSifStatsQueryVariables>(GetSifStatsDocument, options);
      }
export function useGetSifStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSifStatsQuery, GetSifStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSifStatsQuery, GetSifStatsQueryVariables>(GetSifStatsDocument, options);
        }
export type GetSifStatsQueryHookResult = ReturnType<typeof useGetSifStatsQuery>;
export type GetSifStatsLazyQueryHookResult = ReturnType<typeof useGetSifStatsLazyQuery>;
export type GetSifStatsQueryResult = Apollo.QueryResult<GetSifStatsQuery, GetSifStatsQueryVariables>;
export const GetSifStatsByTypeDocument = gql`
    query getSifStatsByType($filter: filterParamsByType, $year: Int!) {
  getSifStatsByType(filter: $filter, year: $year) {
    month
    sifType {
      name
      totalSif
    }
  }
}
    `;

/**
 * __useGetSifStatsByTypeQuery__
 *
 * To run a query within a React component, call `useGetSifStatsByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSifStatsByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSifStatsByTypeQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useGetSifStatsByTypeQuery(baseOptions: Apollo.QueryHookOptions<GetSifStatsByTypeQuery, GetSifStatsByTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSifStatsByTypeQuery, GetSifStatsByTypeQueryVariables>(GetSifStatsByTypeDocument, options);
      }
export function useGetSifStatsByTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSifStatsByTypeQuery, GetSifStatsByTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSifStatsByTypeQuery, GetSifStatsByTypeQueryVariables>(GetSifStatsByTypeDocument, options);
        }
export type GetSifStatsByTypeQueryHookResult = ReturnType<typeof useGetSifStatsByTypeQuery>;
export type GetSifStatsByTypeLazyQueryHookResult = ReturnType<typeof useGetSifStatsByTypeLazyQuery>;
export type GetSifStatsByTypeQueryResult = Apollo.QueryResult<GetSifStatsByTypeQuery, GetSifStatsByTypeQueryVariables>;
export const ListAllSifStatsByMonthDocument = gql`
    query listAllSifStatsByMonth($monthNumber: Int!, $year: Int!, $filter: filterParams!) {
  listAllSifStatsByMonth(monthNumber: $monthNumber, year: $year, filter: $filter) {
    reference
    classificationOfInjury
    location
    incidentCause
    nature
    department
    incidentDate
    shiftType
    incidentTime
  }
}
    `;

/**
 * __useListAllSifStatsByMonthQuery__
 *
 * To run a query within a React component, call `useListAllSifStatsByMonthQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAllSifStatsByMonthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAllSifStatsByMonthQuery({
 *   variables: {
 *      monthNumber: // value for 'monthNumber'
 *      year: // value for 'year'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListAllSifStatsByMonthQuery(baseOptions: Apollo.QueryHookOptions<ListAllSifStatsByMonthQuery, ListAllSifStatsByMonthQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAllSifStatsByMonthQuery, ListAllSifStatsByMonthQueryVariables>(ListAllSifStatsByMonthDocument, options);
      }
export function useListAllSifStatsByMonthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAllSifStatsByMonthQuery, ListAllSifStatsByMonthQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAllSifStatsByMonthQuery, ListAllSifStatsByMonthQueryVariables>(ListAllSifStatsByMonthDocument, options);
        }
export type ListAllSifStatsByMonthQueryHookResult = ReturnType<typeof useListAllSifStatsByMonthQuery>;
export type ListAllSifStatsByMonthLazyQueryHookResult = ReturnType<typeof useListAllSifStatsByMonthLazyQuery>;
export type ListAllSifStatsByMonthQueryResult = Apollo.QueryResult<ListAllSifStatsByMonthQuery, ListAllSifStatsByMonthQueryVariables>;
export const GetSifStatsByDurationDocument = gql`
    query getSifStatsByDuration($monthNumber: Int!, $year: Int!, $filter: filterParams!) {
  getSifStatsByDuration(monthNumber: $monthNumber, year: $year, filter: $filter) {
    duration
    totalSif
  }
}
    `;

/**
 * __useGetSifStatsByDurationQuery__
 *
 * To run a query within a React component, call `useGetSifStatsByDurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSifStatsByDurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSifStatsByDurationQuery({
 *   variables: {
 *      monthNumber: // value for 'monthNumber'
 *      year: // value for 'year'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetSifStatsByDurationQuery(baseOptions: Apollo.QueryHookOptions<GetSifStatsByDurationQuery, GetSifStatsByDurationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSifStatsByDurationQuery, GetSifStatsByDurationQueryVariables>(GetSifStatsByDurationDocument, options);
      }
export function useGetSifStatsByDurationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSifStatsByDurationQuery, GetSifStatsByDurationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSifStatsByDurationQuery, GetSifStatsByDurationQueryVariables>(GetSifStatsByDurationDocument, options);
        }
export type GetSifStatsByDurationQueryHookResult = ReturnType<typeof useGetSifStatsByDurationQuery>;
export type GetSifStatsByDurationLazyQueryHookResult = ReturnType<typeof useGetSifStatsByDurationLazyQuery>;
export type GetSifStatsByDurationQueryResult = Apollo.QueryResult<GetSifStatsByDurationQuery, GetSifStatsByDurationQueryVariables>;
export const GetNoteDocument = gql`
    query GetNote($bu: String!) {
  getNote(bu: $bu) {
    id
    bu
    content
    updatedAt
    createdAt
    user {
      id
      name
      email
    }
  }
}
    `;

/**
 * __useGetNoteQuery__
 *
 * To run a query within a React component, call `useGetNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNoteQuery({
 *   variables: {
 *      bu: // value for 'bu'
 *   },
 * });
 */
export function useGetNoteQuery(baseOptions: Apollo.QueryHookOptions<GetNoteQuery, GetNoteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNoteQuery, GetNoteQueryVariables>(GetNoteDocument, options);
      }
export function useGetNoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNoteQuery, GetNoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNoteQuery, GetNoteQueryVariables>(GetNoteDocument, options);
        }
export type GetNoteQueryHookResult = ReturnType<typeof useGetNoteQuery>;
export type GetNoteLazyQueryHookResult = ReturnType<typeof useGetNoteLazyQuery>;
export type GetNoteQueryResult = Apollo.QueryResult<GetNoteQuery, GetNoteQueryVariables>;
export const CreateNoteDocument = gql`
    mutation createNote($input: NoteInput!) {
  createNote(input: $input)
}
    `;
export type CreateNoteMutationFn = Apollo.MutationFunction<CreateNoteMutation, CreateNoteMutationVariables>;

/**
 * __useCreateNoteMutation__
 *
 * To run a mutation, you first call `useCreateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNoteMutation, { data, loading, error }] = useCreateNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNoteMutation(baseOptions?: Apollo.MutationHookOptions<CreateNoteMutation, CreateNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNoteMutation, CreateNoteMutationVariables>(CreateNoteDocument, options);
      }
export type CreateNoteMutationHookResult = ReturnType<typeof useCreateNoteMutation>;
export type CreateNoteMutationResult = Apollo.MutationResult<CreateNoteMutation>;
export type CreateNoteMutationOptions = Apollo.BaseMutationOptions<CreateNoteMutation, CreateNoteMutationVariables>;
export const GetCovidUsersForLocationsDocument = gql`
    query GetCovidUsersForLocations($locationsToAccess: [ID!]!, $offset: Int!, $limit: Int!) {
  getCovidUsersForLocations(
    locationIds: $locationsToAccess
    offset: $offset
    limit: $limit
  ) {
    count
    covidUsersForLocation {
      name
      employeeNumber
      quarantineUntil
      doctorStatus
      location {
        name
      }
      latestCovidTest {
        type
      }
    }
  }
}
    `;

/**
 * __useGetCovidUsersForLocationsQuery__
 *
 * To run a query within a React component, call `useGetCovidUsersForLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCovidUsersForLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCovidUsersForLocationsQuery({
 *   variables: {
 *      locationsToAccess: // value for 'locationsToAccess'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetCovidUsersForLocationsQuery(baseOptions: Apollo.QueryHookOptions<GetCovidUsersForLocationsQuery, GetCovidUsersForLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCovidUsersForLocationsQuery, GetCovidUsersForLocationsQueryVariables>(GetCovidUsersForLocationsDocument, options);
      }
export function useGetCovidUsersForLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCovidUsersForLocationsQuery, GetCovidUsersForLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCovidUsersForLocationsQuery, GetCovidUsersForLocationsQueryVariables>(GetCovidUsersForLocationsDocument, options);
        }
export type GetCovidUsersForLocationsQueryHookResult = ReturnType<typeof useGetCovidUsersForLocationsQuery>;
export type GetCovidUsersForLocationsLazyQueryHookResult = ReturnType<typeof useGetCovidUsersForLocationsLazyQuery>;
export type GetCovidUsersForLocationsQueryResult = Apollo.QueryResult<GetCovidUsersForLocationsQuery, GetCovidUsersForLocationsQueryVariables>;
export const LastFileUploadDateQueryDocument = gql`
    query LastFileUploadDateQuery {
  lastFileUploadDate
}
    `;

/**
 * __useLastFileUploadDateQueryQuery__
 *
 * To run a query within a React component, call `useLastFileUploadDateQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useLastFileUploadDateQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLastFileUploadDateQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useLastFileUploadDateQueryQuery(baseOptions?: Apollo.QueryHookOptions<LastFileUploadDateQueryQuery, LastFileUploadDateQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LastFileUploadDateQueryQuery, LastFileUploadDateQueryQueryVariables>(LastFileUploadDateQueryDocument, options);
      }
export function useLastFileUploadDateQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LastFileUploadDateQueryQuery, LastFileUploadDateQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LastFileUploadDateQueryQuery, LastFileUploadDateQueryQueryVariables>(LastFileUploadDateQueryDocument, options);
        }
export type LastFileUploadDateQueryQueryHookResult = ReturnType<typeof useLastFileUploadDateQueryQuery>;
export type LastFileUploadDateQueryLazyQueryHookResult = ReturnType<typeof useLastFileUploadDateQueryLazyQuery>;
export type LastFileUploadDateQueryQueryResult = Apollo.QueryResult<LastFileUploadDateQueryQuery, LastFileUploadDateQueryQueryVariables>;
export const GetDepartmentAreaSubareaFiltersForSupplyListDocument = gql`
    query getDepartmentAreaSubareaFiltersForSupplyList($userId: Int!, $locationsIds: [Int]) {
  getDepartmentAreaSubareaFiltersForSupply(
    userId: $userId
    locationsIds: $locationsIds
  ) {
    departments {
      id
      name
      nameEN
      slug
      locationId
    }
    areas {
      id
      name
      nameEN
      slug
      departmentId
      departmentSlug
    }
    subAreas {
      id
      name
      nameEn
      slug
      areaId
      areaSlug
      departmentId
      departmentSlug
    }
  }
}
    `;

/**
 * __useGetDepartmentAreaSubareaFiltersForSupplyListQuery__
 *
 * To run a query within a React component, call `useGetDepartmentAreaSubareaFiltersForSupplyListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDepartmentAreaSubareaFiltersForSupplyListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDepartmentAreaSubareaFiltersForSupplyListQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      locationsIds: // value for 'locationsIds'
 *   },
 * });
 */
export function useGetDepartmentAreaSubareaFiltersForSupplyListQuery(baseOptions: Apollo.QueryHookOptions<GetDepartmentAreaSubareaFiltersForSupplyListQuery, GetDepartmentAreaSubareaFiltersForSupplyListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDepartmentAreaSubareaFiltersForSupplyListQuery, GetDepartmentAreaSubareaFiltersForSupplyListQueryVariables>(GetDepartmentAreaSubareaFiltersForSupplyListDocument, options);
      }
export function useGetDepartmentAreaSubareaFiltersForSupplyListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDepartmentAreaSubareaFiltersForSupplyListQuery, GetDepartmentAreaSubareaFiltersForSupplyListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDepartmentAreaSubareaFiltersForSupplyListQuery, GetDepartmentAreaSubareaFiltersForSupplyListQueryVariables>(GetDepartmentAreaSubareaFiltersForSupplyListDocument, options);
        }
export type GetDepartmentAreaSubareaFiltersForSupplyListQueryHookResult = ReturnType<typeof useGetDepartmentAreaSubareaFiltersForSupplyListQuery>;
export type GetDepartmentAreaSubareaFiltersForSupplyListLazyQueryHookResult = ReturnType<typeof useGetDepartmentAreaSubareaFiltersForSupplyListLazyQuery>;
export type GetDepartmentAreaSubareaFiltersForSupplyListQueryResult = Apollo.QueryResult<GetDepartmentAreaSubareaFiltersForSupplyListQuery, GetDepartmentAreaSubareaFiltersForSupplyListQueryVariables>;
export const IncidentsByLastHourDocument = gql`
    query IncidentsByLastHour($userId: ID!) {
  getIncidentsByLastHour(userId: $userId) {
    hasReachedLimit
    firstIncidentDate
  }
}
    `;

/**
 * __useIncidentsByLastHourQuery__
 *
 * To run a query within a React component, call `useIncidentsByLastHourQuery` and pass it any options that fit your needs.
 * When your component renders, `useIncidentsByLastHourQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIncidentsByLastHourQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useIncidentsByLastHourQuery(baseOptions: Apollo.QueryHookOptions<IncidentsByLastHourQuery, IncidentsByLastHourQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IncidentsByLastHourQuery, IncidentsByLastHourQueryVariables>(IncidentsByLastHourDocument, options);
      }
export function useIncidentsByLastHourLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IncidentsByLastHourQuery, IncidentsByLastHourQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IncidentsByLastHourQuery, IncidentsByLastHourQueryVariables>(IncidentsByLastHourDocument, options);
        }
export type IncidentsByLastHourQueryHookResult = ReturnType<typeof useIncidentsByLastHourQuery>;
export type IncidentsByLastHourLazyQueryHookResult = ReturnType<typeof useIncidentsByLastHourLazyQuery>;
export type IncidentsByLastHourQueryResult = Apollo.QueryResult<IncidentsByLastHourQuery, IncidentsByLastHourQueryVariables>;
export const UpdateWorkLocationsDocument = gql`
    mutation UpdateWorkLocations($payload: UpdateWorkLocationsPayload!) {
  updateWorkLocations(payload: $payload) {
    MutationStatus {
      isSuccess
    }
    Error {
      areaError
      subAreaError
      subLocationError
    }
  }
}
    `;
export type UpdateWorkLocationsMutationFn = Apollo.MutationFunction<UpdateWorkLocationsMutation, UpdateWorkLocationsMutationVariables>;

/**
 * __useUpdateWorkLocationsMutation__
 *
 * To run a mutation, you first call `useUpdateWorkLocationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkLocationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkLocationsMutation, { data, loading, error }] = useUpdateWorkLocationsMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdateWorkLocationsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWorkLocationsMutation, UpdateWorkLocationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWorkLocationsMutation, UpdateWorkLocationsMutationVariables>(UpdateWorkLocationsDocument, options);
      }
export type UpdateWorkLocationsMutationHookResult = ReturnType<typeof useUpdateWorkLocationsMutation>;
export type UpdateWorkLocationsMutationResult = Apollo.MutationResult<UpdateWorkLocationsMutation>;
export type UpdateWorkLocationsMutationOptions = Apollo.BaseMutationOptions<UpdateWorkLocationsMutation, UpdateWorkLocationsMutationVariables>;
export const GetAllLocationsDocument = gql`
    query getAllLocations($type: LOCATION_TYPE) {
  getLocations(type: $type) {
    id
    name
    slug
    type
  }
}
    `;

/**
 * __useGetAllLocationsQuery__
 *
 * To run a query within a React component, call `useGetAllLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllLocationsQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetAllLocationsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllLocationsQuery, GetAllLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllLocationsQuery, GetAllLocationsQueryVariables>(GetAllLocationsDocument, options);
      }
export function useGetAllLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllLocationsQuery, GetAllLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllLocationsQuery, GetAllLocationsQueryVariables>(GetAllLocationsDocument, options);
        }
export type GetAllLocationsQueryHookResult = ReturnType<typeof useGetAllLocationsQuery>;
export type GetAllLocationsLazyQueryHookResult = ReturnType<typeof useGetAllLocationsLazyQuery>;
export type GetAllLocationsQueryResult = Apollo.QueryResult<GetAllLocationsQuery, GetAllLocationsQueryVariables>;
export const GetUserInfoDocument = gql`
    query GetUserInfo($id: ID!) {
  getUserInfo(id: $id) {
    id
    name
    slug
    email
    type
    position
    language
    isBlocked
    shortSlug
    picture
    pin
    blockedReason
    lastActiveAt
    createdAt
    employeeNumber
    doctorStatus
    startWorkingDay
    autoAssignSios
    info {
      isAuthorisedForOWD
      id
      userId
      visitorType
    }
    distributionCenter {
      id
      name
      bu
      type
    }
    userRole {
      id
      name
      slug
      category
    }
  }
}
    `;

/**
 * __useGetUserInfoQuery__
 *
 * To run a query within a React component, call `useGetUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserInfoQuery(baseOptions: Apollo.QueryHookOptions<GetUserInfoQuery, GetUserInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserInfoQuery, GetUserInfoQueryVariables>(GetUserInfoDocument, options);
      }
export function useGetUserInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserInfoQuery, GetUserInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserInfoQuery, GetUserInfoQueryVariables>(GetUserInfoDocument, options);
        }
export type GetUserInfoQueryHookResult = ReturnType<typeof useGetUserInfoQuery>;
export type GetUserInfoLazyQueryHookResult = ReturnType<typeof useGetUserInfoLazyQuery>;
export type GetUserInfoQueryResult = Apollo.QueryResult<GetUserInfoQuery, GetUserInfoQueryVariables>;
export const GetUserWorkLocationsDocument = gql`
    query getUserWorkLocations($userId: [Int]!) {
  getUserWorkLocations(user: $userId) {
    id
    userId
    workLocation {
      id
      name
      nameEN
      slug
    }
    subLocation {
      id
      name
      nameEN
      slug
    }
    subArea {
      id
      name
      nameEn
      slug
    }
  }
}
    `;

/**
 * __useGetUserWorkLocationsQuery__
 *
 * To run a query within a React component, call `useGetUserWorkLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserWorkLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserWorkLocationsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserWorkLocationsQuery(baseOptions: Apollo.QueryHookOptions<GetUserWorkLocationsQuery, GetUserWorkLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserWorkLocationsQuery, GetUserWorkLocationsQueryVariables>(GetUserWorkLocationsDocument, options);
      }
export function useGetUserWorkLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserWorkLocationsQuery, GetUserWorkLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserWorkLocationsQuery, GetUserWorkLocationsQueryVariables>(GetUserWorkLocationsDocument, options);
        }
export type GetUserWorkLocationsQueryHookResult = ReturnType<typeof useGetUserWorkLocationsQuery>;
export type GetUserWorkLocationsLazyQueryHookResult = ReturnType<typeof useGetUserWorkLocationsLazyQuery>;
export type GetUserWorkLocationsQueryResult = Apollo.QueryResult<GetUserWorkLocationsQuery, GetUserWorkLocationsQueryVariables>;
export const GetAccessLocationsDocument = gql`
    query GetAccessLocations {
  getAccessLocations {
    id
    name
  }
}
    `;

/**
 * __useGetAccessLocationsQuery__
 *
 * To run a query within a React component, call `useGetAccessLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccessLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccessLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccessLocationsQuery(baseOptions?: Apollo.QueryHookOptions<GetAccessLocationsQuery, GetAccessLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccessLocationsQuery, GetAccessLocationsQueryVariables>(GetAccessLocationsDocument, options);
      }
export function useGetAccessLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccessLocationsQuery, GetAccessLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccessLocationsQuery, GetAccessLocationsQueryVariables>(GetAccessLocationsDocument, options);
        }
export type GetAccessLocationsQueryHookResult = ReturnType<typeof useGetAccessLocationsQuery>;
export type GetAccessLocationsLazyQueryHookResult = ReturnType<typeof useGetAccessLocationsLazyQuery>;
export type GetAccessLocationsQueryResult = Apollo.QueryResult<GetAccessLocationsQuery, GetAccessLocationsQueryVariables>;
export const UpdateUserInfoDocument = gql`
    mutation UpdateUserInfo($id: ID!, $updatedByUserId: ID!, $general: UsersGeneralInfoPayload, $permission: PermissionInfoPayload, $roleId: ID, $userInfo: UserInfoPayload) {
  updateUserInfo(
    id: $id
    updatedByUserId: $updatedByUserId
    general: $general
    permission: $permission
    roleId: $roleId
    userInfo: $userInfo
  ) {
    isSuccess
  }
}
    `;
export type UpdateUserInfoMutationFn = Apollo.MutationFunction<UpdateUserInfoMutation, UpdateUserInfoMutationVariables>;

/**
 * __useUpdateUserInfoMutation__
 *
 * To run a mutation, you first call `useUpdateUserInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserInfoMutation, { data, loading, error }] = useUpdateUserInfoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      updatedByUserId: // value for 'updatedByUserId'
 *      general: // value for 'general'
 *      permission: // value for 'permission'
 *      roleId: // value for 'roleId'
 *      userInfo: // value for 'userInfo'
 *   },
 * });
 */
export function useUpdateUserInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserInfoMutation, UpdateUserInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserInfoMutation, UpdateUserInfoMutationVariables>(UpdateUserInfoDocument, options);
      }
export type UpdateUserInfoMutationHookResult = ReturnType<typeof useUpdateUserInfoMutation>;
export type UpdateUserInfoMutationResult = Apollo.MutationResult<UpdateUserInfoMutation>;
export type UpdateUserInfoMutationOptions = Apollo.BaseMutationOptions<UpdateUserInfoMutation, UpdateUserInfoMutationVariables>;
export const UpdateUserAccessLocationDocument = gql`
    mutation UpdateUserAccessLocation($locationIds: [Int]!, $userId: String) {
  updateUserAccessLocation(locationIds: $locationIds, userId: $userId) {
    isSuccess
  }
}
    `;
export type UpdateUserAccessLocationMutationFn = Apollo.MutationFunction<UpdateUserAccessLocationMutation, UpdateUserAccessLocationMutationVariables>;

/**
 * __useUpdateUserAccessLocationMutation__
 *
 * To run a mutation, you first call `useUpdateUserAccessLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserAccessLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserAccessLocationMutation, { data, loading, error }] = useUpdateUserAccessLocationMutation({
 *   variables: {
 *      locationIds: // value for 'locationIds'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUpdateUserAccessLocationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserAccessLocationMutation, UpdateUserAccessLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserAccessLocationMutation, UpdateUserAccessLocationMutationVariables>(UpdateUserAccessLocationDocument, options);
      }
export type UpdateUserAccessLocationMutationHookResult = ReturnType<typeof useUpdateUserAccessLocationMutation>;
export type UpdateUserAccessLocationMutationResult = Apollo.MutationResult<UpdateUserAccessLocationMutation>;
export type UpdateUserAccessLocationMutationOptions = Apollo.BaseMutationOptions<UpdateUserAccessLocationMutation, UpdateUserAccessLocationMutationVariables>;
export const GetAllAccessLocationsDocument = gql`
    query GetAllAccessLocations($id: ID!) {
  getAllAccessLocations(id: $id)
}
    `;

/**
 * __useGetAllAccessLocationsQuery__
 *
 * To run a query within a React component, call `useGetAllAccessLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAccessLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAccessLocationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAllAccessLocationsQuery(baseOptions: Apollo.QueryHookOptions<GetAllAccessLocationsQuery, GetAllAccessLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllAccessLocationsQuery, GetAllAccessLocationsQueryVariables>(GetAllAccessLocationsDocument, options);
      }
export function useGetAllAccessLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllAccessLocationsQuery, GetAllAccessLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllAccessLocationsQuery, GetAllAccessLocationsQueryVariables>(GetAllAccessLocationsDocument, options);
        }
export type GetAllAccessLocationsQueryHookResult = ReturnType<typeof useGetAllAccessLocationsQuery>;
export type GetAllAccessLocationsLazyQueryHookResult = ReturnType<typeof useGetAllAccessLocationsLazyQuery>;
export type GetAllAccessLocationsQueryResult = Apollo.QueryResult<GetAllAccessLocationsQuery, GetAllAccessLocationsQueryVariables>;
export const GetAllLocationsUserPositionsDocument = gql`
    query GetAllLocationsUserPositions {
  getAllLocationsUserPositions
}
    `;

/**
 * __useGetAllLocationsUserPositionsQuery__
 *
 * To run a query within a React component, call `useGetAllLocationsUserPositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllLocationsUserPositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllLocationsUserPositionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllLocationsUserPositionsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllLocationsUserPositionsQuery, GetAllLocationsUserPositionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllLocationsUserPositionsQuery, GetAllLocationsUserPositionsQueryVariables>(GetAllLocationsUserPositionsDocument, options);
      }
export function useGetAllLocationsUserPositionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllLocationsUserPositionsQuery, GetAllLocationsUserPositionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllLocationsUserPositionsQuery, GetAllLocationsUserPositionsQueryVariables>(GetAllLocationsUserPositionsDocument, options);
        }
export type GetAllLocationsUserPositionsQueryHookResult = ReturnType<typeof useGetAllLocationsUserPositionsQuery>;
export type GetAllLocationsUserPositionsLazyQueryHookResult = ReturnType<typeof useGetAllLocationsUserPositionsLazyQuery>;
export type GetAllLocationsUserPositionsQueryResult = Apollo.QueryResult<GetAllLocationsUserPositionsQuery, GetAllLocationsUserPositionsQueryVariables>;
export const GetUserPositionsByBuDocument = gql`
    query getUserPositionsByBu {
  getUserPositionsByBu {
    position
    bu {
      id
      name
    }
  }
}
    `;

/**
 * __useGetUserPositionsByBuQuery__
 *
 * To run a query within a React component, call `useGetUserPositionsByBuQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPositionsByBuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPositionsByBuQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserPositionsByBuQuery(baseOptions?: Apollo.QueryHookOptions<GetUserPositionsByBuQuery, GetUserPositionsByBuQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserPositionsByBuQuery, GetUserPositionsByBuQueryVariables>(GetUserPositionsByBuDocument, options);
      }
export function useGetUserPositionsByBuLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserPositionsByBuQuery, GetUserPositionsByBuQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserPositionsByBuQuery, GetUserPositionsByBuQueryVariables>(GetUserPositionsByBuDocument, options);
        }
export type GetUserPositionsByBuQueryHookResult = ReturnType<typeof useGetUserPositionsByBuQuery>;
export type GetUserPositionsByBuLazyQueryHookResult = ReturnType<typeof useGetUserPositionsByBuLazyQuery>;
export type GetUserPositionsByBuQueryResult = Apollo.QueryResult<GetUserPositionsByBuQuery, GetUserPositionsByBuQueryVariables>;
export const GetDepartmentAreaSubAreaDocument = gql`
    query GetDepartmentAreaSubArea($id: ID!) {
  getDepartmentAreaSubArea(id: $id)
}
    `;

/**
 * __useGetDepartmentAreaSubAreaQuery__
 *
 * To run a query within a React component, call `useGetDepartmentAreaSubAreaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDepartmentAreaSubAreaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDepartmentAreaSubAreaQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDepartmentAreaSubAreaQuery(baseOptions: Apollo.QueryHookOptions<GetDepartmentAreaSubAreaQuery, GetDepartmentAreaSubAreaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDepartmentAreaSubAreaQuery, GetDepartmentAreaSubAreaQueryVariables>(GetDepartmentAreaSubAreaDocument, options);
      }
export function useGetDepartmentAreaSubAreaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDepartmentAreaSubAreaQuery, GetDepartmentAreaSubAreaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDepartmentAreaSubAreaQuery, GetDepartmentAreaSubAreaQueryVariables>(GetDepartmentAreaSubAreaDocument, options);
        }
export type GetDepartmentAreaSubAreaQueryHookResult = ReturnType<typeof useGetDepartmentAreaSubAreaQuery>;
export type GetDepartmentAreaSubAreaLazyQueryHookResult = ReturnType<typeof useGetDepartmentAreaSubAreaLazyQuery>;
export type GetDepartmentAreaSubAreaQueryResult = Apollo.QueryResult<GetDepartmentAreaSubAreaQuery, GetDepartmentAreaSubAreaQueryVariables>;
export const GetLocationsDepartmentAreaSubareaDocument = gql`
    query GetLocationsDepartmentAreaSubarea($locationIds: [Int]!) {
  getLocationsDepartmentAreaSubarea(locationIds: $locationIds) {
    id
    name
    slug
    departments {
      id
      name
      nameEN
      slug
      areas {
        id
        name
        nameEN
        slug
        subAreas {
          id
          name
          nameEn
          slug
        }
      }
    }
  }
}
    `;

/**
 * __useGetLocationsDepartmentAreaSubareaQuery__
 *
 * To run a query within a React component, call `useGetLocationsDepartmentAreaSubareaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationsDepartmentAreaSubareaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationsDepartmentAreaSubareaQuery({
 *   variables: {
 *      locationIds: // value for 'locationIds'
 *   },
 * });
 */
export function useGetLocationsDepartmentAreaSubareaQuery(baseOptions: Apollo.QueryHookOptions<GetLocationsDepartmentAreaSubareaQuery, GetLocationsDepartmentAreaSubareaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationsDepartmentAreaSubareaQuery, GetLocationsDepartmentAreaSubareaQueryVariables>(GetLocationsDepartmentAreaSubareaDocument, options);
      }
export function useGetLocationsDepartmentAreaSubareaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationsDepartmentAreaSubareaQuery, GetLocationsDepartmentAreaSubareaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationsDepartmentAreaSubareaQuery, GetLocationsDepartmentAreaSubareaQueryVariables>(GetLocationsDepartmentAreaSubareaDocument, options);
        }
export type GetLocationsDepartmentAreaSubareaQueryHookResult = ReturnType<typeof useGetLocationsDepartmentAreaSubareaQuery>;
export type GetLocationsDepartmentAreaSubareaLazyQueryHookResult = ReturnType<typeof useGetLocationsDepartmentAreaSubareaLazyQuery>;
export type GetLocationsDepartmentAreaSubareaQueryResult = Apollo.QueryResult<GetLocationsDepartmentAreaSubareaQuery, GetLocationsDepartmentAreaSubareaQueryVariables>;
export const GetAllOwdChecklistsDocument = gql`
    query GetAllOWDChecklists($locationSlug: String!, $source: ClientType!, $pillar: String, $startDate: Date, $endDate: Date, $limit: Int, $offset: Int, $userVersion: String, $userSearch: String) {
  getAllOWDChecklists(
    args: {locationSlug: $locationSlug, source: $source, pillar: $pillar, startDate: $startDate, endDate: $endDate, limit: $limit, offset: $offset, userVersion: $userVersion, userSearch: $userSearch}
  ) {
    id
    name
    content
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetAllOwdChecklistsQuery__
 *
 * To run a query within a React component, call `useGetAllOwdChecklistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllOwdChecklistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllOwdChecklistsQuery({
 *   variables: {
 *      locationSlug: // value for 'locationSlug'
 *      source: // value for 'source'
 *      pillar: // value for 'pillar'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      userVersion: // value for 'userVersion'
 *      userSearch: // value for 'userSearch'
 *   },
 * });
 */
export function useGetAllOwdChecklistsQuery(baseOptions: Apollo.QueryHookOptions<GetAllOwdChecklistsQuery, GetAllOwdChecklistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllOwdChecklistsQuery, GetAllOwdChecklistsQueryVariables>(GetAllOwdChecklistsDocument, options);
      }
export function useGetAllOwdChecklistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllOwdChecklistsQuery, GetAllOwdChecklistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllOwdChecklistsQuery, GetAllOwdChecklistsQueryVariables>(GetAllOwdChecklistsDocument, options);
        }
export type GetAllOwdChecklistsQueryHookResult = ReturnType<typeof useGetAllOwdChecklistsQuery>;
export type GetAllOwdChecklistsLazyQueryHookResult = ReturnType<typeof useGetAllOwdChecklistsLazyQuery>;
export type GetAllOwdChecklistsQueryResult = Apollo.QueryResult<GetAllOwdChecklistsQuery, GetAllOwdChecklistsQueryVariables>;
export const GetPostsDocument = gql`
    query getPosts($limit: Int!, $search: String, $type: POSTS_TYPES, $attachmentType: [ATTACHMENT_TYPES], $startDate: Date, $endDate: Date) {
  getPosts(
    limit: $limit
    search: $search
    type: $type
    startDate: $startDate
    endDate: $endDate
    attachmentType: $attachmentType
  ) {
    posts {
      title
      content
      type
      bannerImage
      slug
      postedAt
      author
      attachments {
        url
        type
      }
    }
    hasMore
  }
}
    `;

/**
 * __useGetPostsQuery__
 *
 * To run a query within a React component, call `useGetPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *      type: // value for 'type'
 *      attachmentType: // value for 'attachmentType'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetPostsQuery(baseOptions: Apollo.QueryHookOptions<GetPostsQuery, GetPostsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPostsQuery, GetPostsQueryVariables>(GetPostsDocument, options);
      }
export function useGetPostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPostsQuery, GetPostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPostsQuery, GetPostsQueryVariables>(GetPostsDocument, options);
        }
export type GetPostsQueryHookResult = ReturnType<typeof useGetPostsQuery>;
export type GetPostsLazyQueryHookResult = ReturnType<typeof useGetPostsLazyQuery>;
export type GetPostsQueryResult = Apollo.QueryResult<GetPostsQuery, GetPostsQueryVariables>;
export const LocationDetailsDocument = gql`
    query locationDetails($locationSlug: String!) {
  locationDetails(locationSlug: $locationSlug) {
    type
  }
}
    `;

/**
 * __useLocationDetailsQuery__
 *
 * To run a query within a React component, call `useLocationDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationDetailsQuery({
 *   variables: {
 *      locationSlug: // value for 'locationSlug'
 *   },
 * });
 */
export function useLocationDetailsQuery(baseOptions: Apollo.QueryHookOptions<LocationDetailsQuery, LocationDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocationDetailsQuery, LocationDetailsQueryVariables>(LocationDetailsDocument, options);
      }
export function useLocationDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationDetailsQuery, LocationDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocationDetailsQuery, LocationDetailsQueryVariables>(LocationDetailsDocument, options);
        }
export type LocationDetailsQueryHookResult = ReturnType<typeof useLocationDetailsQuery>;
export type LocationDetailsLazyQueryHookResult = ReturnType<typeof useLocationDetailsLazyQuery>;
export type LocationDetailsQueryResult = Apollo.QueryResult<LocationDetailsQuery, LocationDetailsQueryVariables>;