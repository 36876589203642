import { NameAndSlug } from 'types/interfaces';
import { capitalizeFirst } from './stringHelpers';

// eslint-disable-next-line
export function getUniqueValuesFromArrayForKeys(
  keys: string[], data: object[],
): { [s: string]: any } {
// @ts-ignore
  const unique = [...new Set(data.map(d => keys.map(k => d[k]).join('|r]*a8[v9M4XHQ3X+|')))];
  return unique.map(d => (
    d.split('|r]*a8[v9M4XHQ3X+|').reduce((a: any, c: any, i: any) => ({ ...a, [keys[i]]: c }), {})
  ));
}

export function groupLocationsByCluster(locations: NameAndSlug[]) {
  const clustersMap: any = {};
  let clusterExists = true;
  locations.forEach(({ name, slug, timezoneDomain, cluster, incidents = 0, actionLogsCount = 0 }: any) => {
    const { name: clusterName, slug: clusterSlug } = cluster;
    if (!clusterName) {
      clusterExists = false;
      return;
    }
    if (!clustersMap[clusterName]) {
      clustersMap[clusterName] = {
        name: capitalizeFirst(clusterName),
        slug: clusterSlug,
        incidents: 0,
        actionLogsCount: 0,
        locations: [],
      };
    }
    const existingLocations = clustersMap[clusterName].locations;
    clustersMap[clusterName].locations = [
      { name, slug, incidents, actionLogsCount, timezoneDomain },
      ...existingLocations,
    ];
    clustersMap[clusterName].incidents += incidents;
    clustersMap[clusterName].actionLogsCount += actionLogsCount;
  });

  const clusters: NameAndSlug[] = clusterExists ? Object.values(clustersMap) : locations;
  return { clusterExists, clusters };
}

export function filterLocationsByCluster(locations: any, clusterSlug: string) {
  return locations.filter((location: any) => {
    const { slug } = location.cluster;
    return clusterSlug === slug;
  });
}
