import axios from 'axios';

// eslint-disable-next-line
export function addressFromCoordinates(lat: number, lng: number) {
  return axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
    params: {
      latlng: `${lat},${lng}`,
      key: 'AIzaSyCwe3UdJ7aYDxOQTYw0-dOMBFHYGqZeLSA',
    },
  });
}
