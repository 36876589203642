import React from 'react';

const TruckNumberDisplay = ({ sapNumber }: { sapNumber: string }) => (
  <div className="confirm-truck-details">
    <div className="row left-margins">
      <p className="text-tertiary">Número de camión</p>
    </div>

    {/* sap # */}
    {/** min height to prevent jumping when sap # appears */}
    <div className="row sap-number-container" style={{ minHeight: 50 }}>
      <p className="sap-number">{sapNumber}</p>
    </div>
  </div>
);

export default TruckNumberDisplay;
