import React, { ChangeEvent, Component } from 'react';
import './ConfirmTruckDetails.scss';
import BasicButton from 'components/Button/BasicButton';
import { DISPLAYED_PAGE } from '../../AnonymousTruckComplaint';
import TruckNumberDisplay from '../TruckNumberDisplay/TruckNumberDisplay';

class ConfirmTruckDetails extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.focusInput = this.focusInput.bind(this);
  }

  componentDidMount(): void {
    setTimeout(() => {
      this.focusInput();
    }, 1000);
  }

  focusInput() {
    /** focus the input field to draw the user's attention that it's editable */
    this.props.inputRef?.current?.focus();
  }

  render() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { sapNumber, handleSapNumberChange, navigateTo, inputRef, isSapNumberValid } = this.props;

    return (
      <div className="confirm-truck-details">
        <TruckNumberDisplay
          sapNumber={sapNumber || ''}
        />
        {/* <div className="row left-margins">
          <p className="text-tertiary">Número de camión</p>
        </div> */}

        <div className="row mt-4 left-margins">
          <p className="text-tertiary">
            Verifica que este número sea igual al del cofre o en los costados del camión.
          </p>
        </div>

        {/* proceed */}
        <div className="row justify-content-center align-items-center proceed-button-container">
          <BasicButton
            text="SIGUIENTE"
            disabled={!isSapNumberValid}
            style={{ opacity: !isSapNumberValid ? 0.2 : 1 }}
            onClick={() => navigateTo(DISPLAYED_PAGE.INCIDENT_DETAILS)}
          />
        </div>
      </div>
    );
  }
}

type Props = {
  sapNumber?: string;
  handleSapNumberChange: (e: ChangeEvent<HTMLInputElement>) => void;
  navigateTo: Function;
  inputRef: any;
  isSapNumberValid: boolean;
};
type State = {};

export default ConfirmTruckDetails;
