import React, { useEffect, useMemo, useState } from 'react';
import EmptyState from 'components/EmptyState/EmptyState';
import SnackBarNotification, {
  AlertTypeProps,
} from 'components/Notification/SnackBarNotification/SnackBarNotification';
import '../ComplainceSIF.scss';
import { getMonthsDropDown, getYearDropDownFromBaseYear, months } from 'utils/dateHelpers';
import { getYear, getMonth } from 'date-fns';
import ChartDropdown from 'components/Dropdown/ChartDropdown';
import { SelectInfo } from 'rc-menu/lib/interface';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from 'components/LoadingSpinner';
import { TRANSLATIONS } from 'types/enums';
import {
  DropYearAndMonthDropDown,
  getComplainceChartBuPerFormat,
} from '../compainceSIF.utils';
import { getChartBuList } from '../ComplainceSIFAPICalls';
import ComplainceChart from '../Chart/ComplainceChart';

interface ChartsPerBUPerWeekProps {
  data: object;
}

type Props = {
  type: string;
};

const ChartsPerBUPerWeek = (props: Props) => {
  const { t } = useTranslation();
  const { type } = props;
  // eslint-disable-next-line
  const monthsDropDown = useMemo(() => getMonthsDropDown(t), []);
  const yearsDropDown = useMemo(() => getYearDropDownFromBaseYear(), []);

  function selectedMonthIntiailStateCalculator() {
    const monthIndex = getMonth(new Date());
    return getMonthsDropDown(t, undefined, undefined, [
      months[monthIndex],
    ])[0] as DropYearAndMonthDropDown;
  }
  function selectedYearInitalStateCalculator() {
    const year = getYear(new Date());
    return getYearDropDownFromBaseYear(undefined, undefined, undefined, undefined, [
      year,
    ])[0] as DropYearAndMonthDropDown;
  }
  const [selectedMonth, setSelectedMonth] = useState<DropYearAndMonthDropDown>(
    selectedMonthIntiailStateCalculator,
  );
  const [selectedYear, setSelectedYear] = useState<DropYearAndMonthDropDown>(
    selectedYearInitalStateCalculator,
  );
  const [periodDropDown, setPeriodDropDown] = useState(false);
  const [yearDropDown, setYearDropDown] = useState(false);
  const [toast, setToast] = useState<boolean>(false);
  const [response, setResponse] = useState<any>({ data: {} });
  const [loading, setLoading] = useState<boolean>(false);

  const handleMonthChange = ({ key }: SelectInfo) => {
    setSelectedMonth(
      monthsDropDown.find(month => month.slug === Number(key)) as
      DropYearAndMonthDropDown,
    );
    setPeriodDropDown(false);
  };

  const handleYearChange = ({ key }: SelectInfo) => {
    setSelectedYear(
      yearsDropDown.find(year => year.slug === Number(key)) as DropYearAndMonthDropDown,
    );
    setYearDropDown(false);
  };

  useEffect(() => {
    const getChartData = () => getChartBuList(selectedMonth, selectedYear, type)
      .then((result: ChartsPerBUPerWeekProps) => {
        setResponse(result || { data: {} });
        setLoading(false);
      })
      .catch(() => {
        setToast(true);
        setLoading(false);
      });

    setLoading(true);
    if (type === 'cac') {
      setTimeout(() => {
        getChartData();
      }, 220);
    } else {
      getChartData();
    }
  }, [selectedMonth, selectedYear, type]);

  function renderGraphs() {
    if (loading) {
      return (
        <div className="col-12">
          <div className="card routines-home__card">
            <LoadingSpinner />
          </div>
        </div>
      );
    }
    const apiResponse = Object.keys(response?.data);

    if (!apiResponse.length) {
      return (
        <div className="col-12">
          <div className="card routines-home__card">
            <EmptyState {...{ className: 'no-chart-margin' }} />
          </div>
        </div>
      );
    }
    return apiResponse.map(name => {
      const result = getComplainceChartBuPerFormat(response, name);
      const bus = result.bu.map((el: string) => {
        const translationsTmp: any = TRANSLATIONS;
        return t(translationsTmp[el]);
      });
      return (
        <div key={name} className="col-6 card routines-home__card">
          <span className="routines-home__card__title"> {name} </span>
          <ComplainceChart
            bu={bus}
            closed={result.closed}
            pending={result.pending}
            progress={result.delayed}
            loading={loading}
          />
        </div>
      );
    });
  }

  return (
    <>
      <SnackBarNotification
        isOpen={toast}
        closeNotification={() => {
          setToast(false);
        }}
        alertTitle="Error"
        alertDescription="Something went wrong"
        alertType={AlertTypeProps.ERROR}

      />
      <div className="routines-home__title">{type === 'cac' ? 'Componentes de CAC' : 'Por BU'}</div>
      <div>
        <div className="headerFlex">
          <div className="drop-downs-rowed">
            <ChartDropdown
              onChange={handleMonthChange}
              selectedDropDownItem={selectedMonth}
              dropDownItems={getMonthsDropDown(t) as DropYearAndMonthDropDown[]}
              dropDownOpen={periodDropDown}
              setDropDownOpen={setPeriodDropDown}
              labelClassName="label-default"
            />
            <ChartDropdown
              onChange={handleYearChange}
              selectedDropDownItem={selectedYear}
              dropDownItems={getYearDropDownFromBaseYear() as DropYearAndMonthDropDown[]}
              dropDownOpen={yearDropDown}
              setDropDownOpen={setYearDropDown}
              labelClassName="label-default"
            />
          </div>
        </div>
      </div>
      <div className="row">{renderGraphs()}</div>
    </>
  );
};

export default ChartsPerBUPerWeek;
