import React, { FC, useState } from 'react';
import './TimePicker.scss';
import OutlineButton from 'components/Button/OutlineButton';
import format from 'date-fns/format';
import { es, enUS } from 'date-fns/locale';
import DayPicker from 'react-day-picker';
import MomentLocaleUtils from 'react-day-picker/moment';
import ImageLinks from 'utils/ImageLinks';
import TimeKeeper from 'react-timekeeper';
import i18next from 'i18next';

const TimePicker: FC<Props> = (props: Props) => {
  const {
    incidentDateTime,
    handleTimeChange,
    handleDateChange,
    limitDate,
  } = props;

  const [isShowingCalendar, setIsShowingCalendar] = useState<boolean>(false);
  const [isShowingTimePicker, setIsShowingTimePicker] = useState<boolean>(false);

  const handleToggleCalendar = () => {
    setIsShowingCalendar(cur => !cur);
    setIsShowingTimePicker(false);
  };

  const handleToggleTimePicker = () => {
    setIsShowingCalendar(false);
    setIsShowingTimePicker(cur => !cur);
  };

  return (
    <div className="mt-2 date-time-container" style={{ position: 'relative' }}>
      {/* pill buttons */}
      <OutlineButton
        onClick={handleToggleCalendar}
        text={format(incidentDateTime, 'EEEE d, MMM', { locale: (i18next.language === 'es' ? es : enUS) })}
        style={{ marginRight: '3%', width: '61%' }}
        textStyle={{ fontSize: 13 }}
      />
      <OutlineButton
        onClick={handleToggleTimePicker}
        text={format(incidentDateTime, 'h:mm a', { locale: es })}
        style={{ width: '36%' }}
        textStyle={{ fontSize: 13 }}
      />

      {/* pickers */}
      <div style={{ position: 'relative', zIndex: 10000 }}>
        <div style={{ position: 'absolute', top: 12, right: '50%', left: '1%' }}>
          {/* date */}
          {isShowingCalendar && (
            // styling is done by overwriting class 'DayPicker' in stylesheet
            <DayPicker
              selectedDays={incidentDateTime}
              localeUtils={MomentLocaleUtils}
              locale="es"
              disabledDays={limitDate && ({ after: limitDate })}
              onDayClick={date => {
                if (limitDate && date.getTime() <= limitDate.getTime()) {
                  handleToggleCalendar();
                  return handleDateChange(date);
                }
                if (!limitDate) {
                  handleToggleCalendar();
                  return handleDateChange(date);
                }
              }}
              navbarElement={({ onPreviousClick, onNextClick }) => {
                return (
                  <div className="day-picker-nav-bar">
                    {/* navigation arrows */}
                    <div style={{ position: 'absolute', top: '1.05rem', right: '3.5rem' }}>
                      <img
                        onClick={() => onPreviousClick()}
                        src={ImageLinks.leftMaterial}
                        className="img-button"
                        alt="previous month"
                      />
                      <img
                        onClick={() => onNextClick()}
                        src={ImageLinks.rightMaterial}
                        className="img-button"
                        alt="next month"
                      />
                    </div>
                    {/* close */}
                    <img
                      onClick={handleToggleCalendar}
                      style={{ position: 'absolute', top: '1.15rem', right: '1.2rem' }}
                      src={ImageLinks.closeMaterial}
                      className="img-button"
                      alt="close"
                    />
                  </div>
                );
              }}
            />
          )}
          {/* time */}
          {isShowingTimePicker && (
            <TimeKeeper
              time={{
                hour: incidentDateTime.getHours(),
                minute: incidentDateTime.getMinutes(),
              }}
              onChange={handleTimeChange}
              doneButton={() => (
                <div
                  role="button"
                  style={{
                    textAlign: 'center',
                    padding: '7px 0',
                    cursor: 'pointer',
                    fontSize: 15,
                    fontWeight: 'bold',
                  }}
                  onClick={handleToggleTimePicker}
                >
                  OK
                </div>
              )}
              switchToMinuteOnHourSelect
            />
          )}
        </div>
      </div>
    </div>
  );
};

type Props = {
  incidentDateTime: Date;
  handleTimeChange: any;
  handleDateChange: any;
  limitDate?: any;
};

export default TimePicker;
