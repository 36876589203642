import Header from 'components/Header';
import React from 'react';
import NavigationDrawerWithSideMenu from 'components/NavigationDrawer/NavigationDrawerWithVerticalMenu';
import defaultRoutes from 'utils/defaultRoutes';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUserType, selectUserLocation, selectUser } from 'store/selectors/user.selectors';
import OWDContainer from './AllOWDs';

const OWD = () => {
  const { t } = useTranslation();
  const userType = useSelector(selectUserType);
  const location = useSelector(selectUserLocation);
  const userDetails = useSelector(selectUser);

  return (
    <>
      <Header />
      <NavigationDrawerWithSideMenu
        {...{
          routes: defaultRoutes(t, userType, location, userDetails),
          openDrawer: true,
        }}
      />
      <OWDContainer />
    </>
  );
};

export default OWD;
