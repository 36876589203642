/* eslint-disable @typescript-eslint/naming-convention */
export type DropYearAndMonthDropDown = {
  label: string;
  slug: number
};
export type ComplainceDropDown = {
  label: string;
  slug: string
};

export function getBusPendingProgressAndClosedFromChart(chart: any[]) {
  const data = {
    bu: [] as string[],
    pending: [] as number[],
    closed: [] as number[],
    progress: [] as number[],
  };

  chart.forEach((chartItem: any) => {
    data.bu.push(chartItem.bu as string);
    data.pending.push(chartItem.pendingPercentage as number);
    data.closed.push(chartItem.closedPercentage as number);
    data.progress.push(chartItem.delayedPercentage as number);
  });

  return data;
}

export const getComplainceChartBuPerFormat = (payload: any, key: string) => {
  const item = payload.data[key];
  const buAtKeys = Object.keys(item);
  /*
   * buAtKey = [week1, week2, week3, week4]
  */
  return {
    bu: buAtKeys.map(buAtKey => buAtKey),
    closed: buAtKeys.map(closedCount => item[closedCount].closedPercentage),
    delayed: buAtKeys.map(delayedCount => item[delayedCount].delayedPercentage),
    pending: buAtKeys.map(pendingCount => item[pendingCount].pendingPercentage),
  };
};

export enum COMPLAINCE_GRAPH_COLORS {
  PENDING = '#777DB9',
  CLOSED = '#7EAA56',
  DELAYED = '#B12419',
}

export function determineSIFComplainceStatusColor(status: string) {
  switch (status.toLowerCase()) {
    case 'pendiente': {
      return COMPLAINCE_GRAPH_COLORS.PENDING;
    }
    case 'cerrados': {
      return COMPLAINCE_GRAPH_COLORS.CLOSED;
    }
    case 'con retraso': {
      return COMPLAINCE_GRAPH_COLORS.DELAYED;
    }
    default: {
      return 'black';
    }
  }
}
