import request from 'utils/request';

export async function getChartBuList(selectMonth: any, selectYear: any, type: string) {
  return request.post('/api/v1/analytics/status', {
    month: selectMonth?.slug,
    year: selectYear?.slug,
    by: type,
  });
}

export async function getTicketList(year: number, status: string, classification: string,
  recordTime: string) {
  return request.get('/api/v1/analytics/tickets', { year, status, classification, recordTime });
}

export async function getClassificationList(year: number) {
  return request.get('/api/v1/analytics/tickets/classification', { year });
}
