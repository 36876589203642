import React from 'react';
import { useNavigate } from 'react-router-dom';

const withNavigation = WrappedComponent => props => {
  const navigate = useNavigate();

  return (
    <WrappedComponent
      {...props}
      {...{ navigate }}
    />
  );
};

export default withNavigation;
