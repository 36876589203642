import React, { Component } from 'react';
import './TruckFormSuccess.scss';
import ImageLinks from 'utils/ImageLinks';
import { withTranslation } from 'react-i18next';
import { TRANSLATIONS } from '../../../../types/enums';

class TruckFormSuccess extends Component<Props, State> {
  render() {
    const { t } = this.props;

    return (
      <div className="container-fluid">
        {/* check */}
        <div className="row justify-content-center align-items-center" style={{ marginTop: '20%' }}>
          <div className="col-auto">
            <img src={ImageLinks.checkIcon} alt="success" width="60" />
          </div>
        </div>

        {/* success msg */}
        <div className="row justify-content-center align-items-center">
          <div className="col-auto">
            <h3 className="text-tertiary text-bold">{t(TRANSLATIONS.SENT_SUCCESSFULLY)}</h3>
          </div>
        </div>

        {/* thank you */}
        <div className="row justify-content-center align-items-center mt-5">
          <div className="col-auto">
            <h2 className="text-tertiary text-bold">{`${t(TRANSLATIONS.THANK_YOU)}!`}</h2>
            <p className="text-tertiary">
              {
                t(TRANSLATIONS.THANKS_TO_YOU_WE_CAN_IMPROVE_SECURITY_AMONG_OUR_COLLABORATORS)
              }
            </p>
          </div>
        </div>
      </div>
    );
  }
}

type Props = {
  t: any;
};
type State = {};

// @ts-ignore
export default withTranslation()(TruckFormSuccess);
