import React, { ChangeEvent, Component } from 'react';
import LoadingSpinner from 'components/LoadingSpinner';
import { addressFromCoordinates } from 'utils/geocoding';
import './IncidentDetails.scss';
import BasicButton from 'components/Button/BasicButton';
import SecondaryHeadline from 'containers/SecondaryHeadline';
import FileUpload from 'containers/FileUpload';
import LabelledInputWithTick from 'components/Input/LabelledInputWithTick';
import Toggle from 'components/Button/Toggle';
import { COLOR_CODE, RISK_LEVEL } from 'types/enums';
import { TRUCK_REPORT_TYPES } from '../../AnonymousTruckComplaint';
import TruckNumberDisplay from '../TruckNumberDisplay/TruckNumberDisplay';

function capitalizeFirst(word: string | undefined) {
  if (typeof word !== 'string') return word;

  return `${word.slice(0, 1).toUpperCase()}${word.slice(1)}`;
}

class IncidentDetails extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errorGettingLocation: '',
      isGettingLocation: false,
    };
    this.isFormValid = this.isFormValid.bind(this);
    this.calculateStreet = this.calculateStreet.bind(this);
  }

  componentDidMount(): void {
    window.scrollTo({ top: 0 });
  }

  async calculateStreet() {
    this.setState({ errorGettingLocation: '', isGettingLocation: true });
    const { onStreetChange } = this.props;
    navigator.geolocation.getCurrentPosition(
      // Success
      async (pos: any) => {
        try {
          const { latitude: lat, longitude: lng } = pos.coords;
          const res = await addressFromCoordinates(lat, lng);
          const address = res.data.results[0]?.formatted_address;

          if (address) onStreetChange({ target: { value: address } } as ChangeEvent<any>);
          this.setState({ isGettingLocation: false });
        } catch (e) {
          console.log('e calculateStreet():', e?.message || e);
        }
      },
      // fail
      () => this.setState({
        errorGettingLocation: 'Error obteniendo ubicación',
        isGettingLocation: false,
      }),
    );
  }

  isFormValid() {
    const { description, street, reportType, selectedRiskLevel } = this.props;
    if (
      !!description
      && !!street
      && !!reportType
    ) {
      if (reportType === TRUCK_REPORT_TYPES.ACCIDENT) return !!selectedRiskLevel;
      return true;
    }

    return false;
  }

  render() {
    const {
      cityName,
      description,
      street,
      phoneNumber,
      file,
      reportType,
      selectedRiskLevel,
      onFileChange,
      onStreetChange,
      onPhoneNumberChange,
      onDescriptionChange,
      onReportTypeChange,
      onRiskLevelChange,
      submitForm,
      sapNumber,
    } = this.props;

    const { isGettingLocation, errorGettingLocation } = this.state;

    return (
      <div className="incident-details">
        <TruckNumberDisplay
          sapNumber={sapNumber}
        />

        <div className="ml-2 mt-3 city">
          {/* <SecondaryHeadline title="Datos de ubicación" /> */}
          <p>Ubicación</p>
          <p className="text-bold">{capitalizeFirst(cityName)}</p>
        </div>

        <div className="ml-2 street">
          <LabelledInputWithTick
            value={phoneNumber}
            onChange={onPhoneNumberChange}
            isValid={!!phoneNumber}
            label="Teléfono de contacto"
            description="Lo pedimos para poder darle seguimiento a tu reporte. Agradecemos de antemano."
            iconPosition="right"
            containerClassName="street-container"
            inputProps={{ maxLength: 100, placeholder: '55 0000 0000' }}
          />
        </div>

        {/* street */}
        <div className="ml-2 street">
          <LabelledInputWithTick
            value={street}
            onChange={onStreetChange}
            isValid={!!street}
            label="¿En qué calle sucedió?"
            iconPosition="right"
            containerClassName="street-container"
            inputProps={{ maxLength: 100, placeholder: 'Calle' }}
          />
          {navigator.geolocation && (
            <>
              <p className="or-text">o</p>
              <BasicButton
                text="Calcular ubicación"
                onClick={this.calculateStreet}
                className="calculate-location"
              />
              {isGettingLocation && <LoadingSpinner positionTop={15} text="" />}
              {errorGettingLocation && (
                <p className="error-message">{this.state.errorGettingLocation}</p>)}
            </>
          )}
        </div>

        <SecondaryHeadline title="Datos del reporte" className="ml-2 mt-5" />

        {/* Type of report */}
        <div className="issue-sub-type-dropdown-container ml-2">
          <p style={{ paddingTop: 20 }}>Selecciona tipo de reporte</p>

          {[{
            value: TRUCK_REPORT_TYPES.NEGLIGENCE,
            label: 'Negligencia',
          }, {
            value: TRUCK_REPORT_TYPES.ACCIDENT,
            label: 'Accidente',
          }, {
            value: TRUCK_REPORT_TYPES.SUGGESTION,
            label: 'Sugerencia',
          }].map(option => {
            const isSelected = option.value === reportType;
            return (
              <div
                style={{
                  padding: 10,
                  borderRadius: 10,
                  backgroundColor: isSelected ? COLOR_CODE.PRIMARY : '#F0F0F0',
                  marginTop: 5,
                }}
                onClick={() => {
                  onReportTypeChange(option.value);
                }}
                role="button"
              >
                <p
                  style={{
                    color: isSelected ? 'white' : '#878787',
                  }}
                >{ option.label }
                </p>
              </div>
            );
          }) }
          {/* {[{
            value: TRUCK_REPORT_TYPES.NEGLIGENCE,
            label: 'Negligencia',
          }, {
            value: TRUCK_REPORT_TYPES.ACCIDENT,
            label: 'Accidente',
          }, {
            value: TRUCK_REPORT_TYPES.SUGGESTION,
            label: 'Sugerencia',
          }].map(e => (
            <div>
              <p>{ e.value }</p>
            </div>
          ))} */}
        </div>

        {/* risk level */}
        {reportType === TRUCK_REPORT_TYPES.ACCIDENT && (
          <div className="risk-level-container ml-2">
            <p>Nivel de riesgo</p>

            <Toggle
              options={[
                { key: RISK_LEVEL.HIGH, text: 'Alto' },
                { key: RISK_LEVEL.LOW, text: 'Bajo' },
              ]}
              onOptionSelect={onRiskLevelChange}
              selectedOption={selectedRiskLevel || ''}
              activeTabStyle={{ background: COLOR_CODE.PRIMARY, borderColor: COLOR_CODE.PRIMARY }}
              inActiveTabStyle={{ background: COLOR_CODE.WHITE, borderColor: undefined }}
            />
          </div>
        )}

        {/* description */}
        <div className="description ml-2">
          <LabelledInputWithTick
            value={description}
            onChange={onDescriptionChange}
            isValid={!!description}
            label="Descripción del suceso (140 caracteres)"
            iconPosition="right"
            containerClassName="description-container"
            inputProps={{ maxLength: 140, placeholder: 'Ej: El camión no trae luces traseras' }}
            isTextArea
          />
        </div>

        {/* image */}
        <div className="file mt-5 ml-2">
          <p>Para poder dar un mejor seguimiento y resolver, sube una
            evidencia fotográfica.
            Gracias!
          </p>
          <FileUpload
            file={file}
            handleFileChange={onFileChange}
            label="Subir evidencia"
            accepts="image/*"
            containerClassName="image-upload"
            imgClassName="image"
          />
        </div>

        {/* proceed */}
        <div className="row justify-content-center align-items-center proceed-button-container">
          <BasicButton text="ENVIAR" onClick={submitForm} disabled={!this.isFormValid()} />
        </div>
      </div>
    );
  }
}

type Props = {
  cityName?: string;
  street: string;
  phoneNumber: string;
  description: string;
  file?: any;
  reportType: string;
  selectedRiskLevel: string;
  submitForm: (e?: any) => void;
  // handlers
  onStreetChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onDescriptionChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onFileChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onPhoneNumberChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onReportTypeChange: (e: TRUCK_REPORT_TYPES) => void;
  onRiskLevelChange: (e: RISK_LEVEL) => void;
  sapNumber: string;

};
type State = {
  errorGettingLocation: string;
  isGettingLocation: boolean;
};

export default IncidentDetails;
