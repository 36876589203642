import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import OnClickDropdown from 'components/Dropdown/OnClickDropdown';

import downloadIconBlack from 'assets/icons/download-black.svg';
import checkIcon from 'assets/icons/only-check.svg';
import clipboard from 'assets/icons/clipboard.svg';
import safetyAcademyLogo from 'assets/icons/safety-academy-logo.png';

import './BigTile.scss';

const BigTile = ({
  resultItem: {
    bannerImage,
    title,
    content,
    attachments,
    slug,
  },
} : {
  resultItem: {
    bannerImage: string,
    title: string,
    content: string,
    attachments: Array<{ url: string | undefined }>,
    slug: string,
  }
}) => {
  const navigate = useNavigate();
  const [copied, setCopied] = useState(false);
  const [downloadOpen, setDownloadOpen] = useState(false);

  const handleRedirectToExtended = (e: { stopPropagation: () => void; }) => {
    e.stopPropagation();
    navigate(`/academy/extended-view/${slug}`);
  };

  const hanldeCopyLink = async (e: { stopPropagation: () => void; }) => {
    e.stopPropagation();
    const currentUrl = window.location.href;
    await navigator.clipboard.writeText(currentUrl);
    setCopied(true);
  };

  const handleDownload = (url: string | undefined) => {
    window.open(url, '_blank');
  };

  return (
    <div className="big-tile">
      <img className="big-tile__banner" src={bannerImage || safetyAcademyLogo} alt="banner" onClick={e => handleRedirectToExtended(e)} role="presentation" />
      <div className="big-tile__title" onClick={e => handleRedirectToExtended(e)} role="presentation">{title}</div>
      <p className="big-tile__content" onClick={e => handleRedirectToExtended(e)} role="presentation" dangerouslySetInnerHTML={{ __html: content }} />
      <div className="big-tile__buttons-row">
        {attachments?.length > 0 && (
          <OnClickDropdown
            dropdownContent={
              attachments.map(({ url }) => (
                <button type="button" onClick={() => handleDownload(url)} className="downlaod-link">
                  {url?.split('/').slice(-1)[0]}
                </button>
              ))
            }
            className={`link-dropdown ${downloadOpen ? 'clicked' : ''} big-tile__buttons-row__button`}
            onVisibleChange={setDownloadOpen}
            style={{ marginTop: '-50px' }}
          >
            <img src={downloadIconBlack} alt="download" />
          </OnClickDropdown>
        )}
        <button type="button" onClick={e => hanldeCopyLink(e)} className="big-tile__buttons-row__button">
          <img src={copied ? checkIcon : clipboard} alt="clipboard" />
        </button>
      </div>
    </div>
  );
};

export default BigTile;
