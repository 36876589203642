import React, { useState } from 'react';
import './IncidentsPostponePopup.scss';
import { useTranslation } from 'react-i18next';
import { TICKET_TYPE, TRANSLATIONS } from 'types/enums';
import BasicButton from 'components/Button/BasicButton';
import ImageLinks from 'utils/ImageLinks';
import request from 'utils/request';
import Alert from 'components/alert/Alert';
import LoadingSpinner from 'components/LoadingSpinner';
import DatePicker from 'react-datepicker';
import { Modal } from '@mui/material';
import { forceDateToUTC } from 'utils/dateHelpers';
import { addDays } from 'date-fns';

type Props = {
  showPostponeIncidentModal: boolean;
  setShowPostponeIncidentModal: (show: boolean) => void;
  incidentId?: number;
  incidentTypeOfIssue?: TICKET_TYPE;
  refresh?: () => void;
};

const IncidentsPostponePopup = ({
  showPostponeIncidentModal,
  setShowPostponeIncidentModal,
  incidentId,
  incidentTypeOfIssue,
  refresh,
}: Props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [postponeSIODate, setPostponeSIODate] = useState<Date>();

  const closeModalPostponeIncident = () => {
    setPostponeSIODate(undefined);
    setShowPostponeIncidentModal(false);
  };

  const postponeIncident = async () => {
    setIsLoading(true);
    try {
      const dateForcedToUtc = postponeSIODate && forceDateToUTC(postponeSIODate).getTime();

      const url = `/api/v1/incidents/${incidentId}/postpone`;
      const body = {
        postponeTo: dateForcedToUtc,
      };
      await request.post(url, body);
      setIsLoading(false);
      closeModalPostponeIncident();
      Alert.alert({
        image: { src: ImageLinks.greenFilledCheck },
        title: incidentTypeOfIssue === 'CONDITION'
          ? t(TRANSLATIONS.CONDITION_POSTPONED_SUCCESSFULLY)
          : t(TRANSLATIONS.ACT_POSTPONED_SUCCESSFULLY),
        description: incidentTypeOfIssue === 'CONDITION'
          ? t(TRANSLATIONS.THE_CONDITION_WAS_SUCCESSFULLY_POSTPONED)
          : t(TRANSLATIONS.THE_ACT_WAS_SUCCESSFULLY_POSTPONED),
        buttons: [
          {
            title: t(TRANSLATIONS.OK).toUpperCase(),
            className: 'postponed-ok-button',
            onPress: () => {
              if (refresh) {
                closeModalPostponeIncident();
                refresh();
              }
            },
          },
        ],
      });
    } catch (e) {
      console.log('e postponeIncident(): ', (e as any).message || e);
    }
  };

  return (
    <Modal
      open={showPostponeIncidentModal}
    >
      <div
        className="postpone-sio-modal"
      >
        <div
          className="postpone-sio-section-container postpone-sio-title pt-0"
        >
          {
                incidentTypeOfIssue === 'CONDITION'
                  ? t(TRANSLATIONS.POSTPONE_CONDITION)
                  : t(TRANSLATIONS.POSTPONE_ACT)
            }
        </div>

        <div className="postpone-sio-section-container postpone-sio-description-container">
          {
                incidentTypeOfIssue === 'CONDITION'
                  ? t(TRANSLATIONS.CHOOSE_A_DATE_FOR_WHEN_YOU_WILL_CLOSE_THIS_CONDITION)
                  : t(TRANSLATIONS.CHOOSE_A_DATE_FOR_WHEN_YOU_WILL_CLOSE_THIS_ACT)
            }
        </div>

        <div className="d-flex justify-content-center">
          <div className={`postpone-sio-section-container postpone-sio-date-container ${postponeSIODate ? 'postpone-sio-date-container-selected' : ''}`}>
            <DatePicker
              selected={postponeSIODate}
              placeholderText={t(TRANSLATIONS.SELECT_DATE)}
              className={`postpone-sio-date-input ${postponeSIODate ? 'postpone-sio-date-input-selected' : ''}`}
              onChange={setPostponeSIODate}
              minDate={addDays(new Date(), 1)}
            />
            <img src={ImageLinks.calendar} alt="icon" className="postpone-sio-date-icon" />
          </div>
        </div>

        <div className="d-flex justify-content-end postpone-sio-buttons-container">
          <div className="id-button-outline" role="button" onClick={closeModalPostponeIncident}>
            {t(TRANSLATIONS.CANCEL)}
          </div>
          <div>
            { isLoading ? (
              <LoadingSpinner
                className="loading-spinner-postpone-sio"
              />
            ) : (
              <BasicButton
                text={t(TRANSLATIONS.POSTPONE)}
                onClick={postponeIncident}
                className="id-button-solid"
                disabled={!postponeSIODate}
              />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default IncidentsPostponePopup;
