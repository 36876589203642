import { getMonth } from 'date-fns';
import React from 'react';
import ImageLinks from 'utils/ImageLinks';
import './MonthBarStyles.scss';

type Props = {
  isFirstHalf: boolean;
  auditData: any;
  hasMultipleLocations: any;
  lastAudit: any;
  lastAuditDate: any;
};

export default function MonthBar({
  isFirstHalf,
  auditData,
  hasMultipleLocations,
  lastAuditDate,
  lastAudit,
}: Props) {
  // const monthArr = useMemo(() => {
  //   if (isFirstHalf) {
  //     return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'];
  //   }
  //   return ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  // }, [isFirstHalf]);
  let monthOfAudit = 0;
  if (!hasMultipleLocations) {
    monthOfAudit = getMonth(lastAuditDate);
    if (monthOfAudit > 6) monthOfAudit -= 6;
  }
  const defaultValues = !isFirstHalf ? [0, 0, 0, 0, 0, 0]
    : auditData?.map((s: any) => s.value * 100);
  const difference = [] as string[];

  if (!hasMultipleLocations) {
    defaultValues[monthOfAudit] = lastAudit ? lastAudit * 100 : 0;
  }

  defaultValues.forEach((value: any, index: any) => {
    if (!index) {
      // 0th position
      difference.push('');
    } else if (value === '') {
      difference.push(value);
    } else {
      let valueDiff = value - defaultValues[index - 1];
      if (!hasMultipleLocations) valueDiff = lastAudit ? value - (lastAudit * 100) : 0;
      difference.push(valueDiff.toFixed(0).toString());
    }
  });

  return (
    <div className="MonthBar">
      <div className="container-bar">
        <div className="flex-item mb-1">
          {
            (isFirstHalf ? ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun'] : ['Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']).map(
              (monthValue: any, key: any) => (
                <div
                  {...{ key }}
                  className="row-item"
                  style={{
                    flexDirection: 'column',
                    alignItems: 'baseline',
                    justifyContent: 'flex-start',
                  }}
                >
                  {monthValue}
                </div>
              ),
            )
          }
        </div>
        <div className="flex-item mb-1">
          {
            defaultValues.map(
              (monthValue: any, key: any) => (
                <div
                  {...{ key }}
                  className={`${monthValue > 73 ? 'green' : 'red'} row-item`}
                  style={{
                    flexDirection: 'column',
                    alignItems: 'baseline',
                    height: 29,
                    justifyContent: 'flex-start',
                    ...((!hasMultipleLocations && key === monthOfAudit) ? { color: '#4472C4' } : {}),
                  }}
                >
                  {monthValue ? `${monthValue.toFixed(0)}%` : monthValue}
                  { (!hasMultipleLocations && key === monthOfAudit) && (<img src={ImageLinks.guyWithCheckmark} alt="" style={{ height: 8 }} />)}
                </div>
              ),
            )
          }
        </div>
        <div className="flex-item mb-1" style={{ marginTop: -8 }}>
          {
            difference.map(
              (monthValue, key) => (
                <div {...{ key }} className=" row-item">
                  {monthValue ? `${monthValue}%` : ''}
                </div>
              ),
            )
          }
        </div>
      </div>
    </div>
  );
}
