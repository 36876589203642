export const SET_SEARCH_RESULTS = 'safety-academy/set-search-results';
export const SET_SEARCH_LOADING = 'safety-academy/set-search-loading';
export const SET_SEARCH_VALUE = 'safety-academy/set-search-value';
export const SET_HAS_SEARCHED = 'safety-academy/set-has-searched';

export function setSearchResults(results: any) {
  return { type: SET_SEARCH_RESULTS, results };
}

export function setSearchLoading(loading: any) {
  return { type: SET_SEARCH_LOADING, loading };
}

export function setSearchState(value: any) {
  return { type: SET_SEARCH_VALUE, value };
}

export function setHasSearched(value: any) {
  return { type: SET_HAS_SEARCHED, value };
}
