import React from 'react';
import { useSelector } from 'react-redux';

import EmptyState from 'components/EmptyState/EmptyState';
import BigTile from 'components/BigTile/BigTile';
import LoadingSpinner from 'components/LoadingSpinner';

import './SearchPage.scss';

const SearchPage = () => {
  const loading = useSelector((state: any) => state.toJS().safetyAcademy.loading);
  const results = useSelector((state: any) => state.toJS().safetyAcademy.searchResults);
  const value = useSelector((state: any) => state.toJS().safetyAcademy.searchValue);

  if (loading) {
    return (
      <div className="search-page">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="search-page">
      <div className="search-page__top">
        {value && (<div>Resultados {`"${value}"`}</div>)}<span>{results?.length || 0} Resultados</span>
      </div>
      <div className="search-page__results-list">
        {results?.length > 0 ? results.map((resultItem: {
          bannerImage: string;
          title: string;
          content: string;
          attachments: { url: string | undefined }[];
          slug: string;
        }) => (
          <BigTile resultItem={resultItem} key={resultItem.slug} />
        )) : (
          <EmptyState />
        )}
      </div>
    </div>
  );
};

export default SearchPage;
