import React from 'react';
import ReactDOM from 'react-dom';
import { Modal } from '@mui/material';
import './Loading.scss';
import LoadingSpinner from 'components/LoadingSpinner';

interface LoadingProps {
  open: boolean;
  onClose: () => void;
}

class Loading extends React.Component<LoadingProps> {
  // static defaultProps = {
  //   open: false,
  //   onClose: () => {},
  // };

  static loadingRoot: HTMLElement | null = null;

  static show() {
    if (!Loading.loadingRoot) {
      Loading.loadingRoot = document.createElement('div');
      Loading.loadingRoot.id = 'loading-root';
      document.body.appendChild(Loading.loadingRoot);
      // @ts-ignore
      ReactDOM.render(<Loading open />, Loading.loadingRoot);
    }
  }

  static hide() {
    if (Loading.loadingRoot) {
      ReactDOM.unmountComponentAtNode(Loading.loadingRoot);
      Loading.loadingRoot.parentNode?.removeChild(Loading.loadingRoot);
      Loading.loadingRoot = null;
    }
  }

  render() {
    const { open, onClose } = this.props;

    let modalClasses = 'loading-overlay';
    if (open) {
      modalClasses += ' loading-overlay-visible';
    }

    return (
      <Modal open={open} onClose={onClose} className={modalClasses}>
        <div className="loading-content">
          <LoadingSpinner height={21} />
        </div>
      </Modal>
    );
  }
}

export default Loading;
