import React, { LegacyRef } from 'react';
import './InputWithLabel.scss';

type Props = {
  onChange(e: any): void;
  placeholder?: string;
  value?: string;
  name?: string;
  label?: string;
  error?: string;
  type?: 'text' | 'password' | 'number';
  style?: React.CSSProperties;
  maxLength?: number;
  inputClassName?: string;
  labelClassName?: string;
  containerClassName?: string;
  labelStyles?: React.CSSProperties;
  inputImg?: any,
  disabled?: boolean,
  inputRef?:LegacyRef<HTMLInputElement>
};

const InputWithLabel = ({
  onChange,
  placeholder,
  value,
  name,
  label = '',
  error,
  type = 'text',
  style,
  maxLength = 200,
  inputClassName = '',
  labelClassName = '',
  containerClassName = '',
  labelStyles = {},
  inputImg = '',
  disabled = false,
  inputRef,
}: Props) => (
  <div className={`input-with-label ${containerClassName}`}>
    {(label || error) && (
      <div className={`labels ${labelClassName}`}>
        <p style={labelStyles} className="default-label">{label}</p>
        <p className="error-text">{error}</p>
      </div>
    )}
    <div style={{ position: 'relative' }}>
      <input
        ref={inputRef}
        style={{ ...style, paddingLeft: inputImg ? '45px' : '' }}
        type={type}
        value={value}
        placeholder={placeholder}
        name={name}
        className={`form-input  ${inputClassName} ${error ? 'form-error' : ''}`}
        onChange={onChange}
        maxLength={maxLength}
        disabled={disabled}
      />
      {inputImg && <img className="inputImg" src={inputImg} alt="img" />}
    </div>
  </div>
);

export default InputWithLabel;
