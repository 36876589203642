import React, { useState } from 'react';
import { useCubeQuery } from '@cubejs-client/react';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';
import { format } from 'date-fns';
import { set, subDays } from 'date-fns/esm';
import { lastDayOfMonth } from 'date-fns/esm/fp';
import ChartDropdown from 'components/Dropdown/ChartDropdown';
import executedBeerBottle from 'assets/icons/executed-beer-bottle.svg';
import plannedBeerBottle from 'assets/icons/planned-beer-bottle.svg';
import './GeneralPlanning.scss';
import { useSelector } from 'react-redux';
import { selectLocationsToAccess } from 'store/selectors/globalFilters.selectors';

export default function GeneralPlanning() {
  const { t } = useTranslation();
  const locationsToAccess = useSelector(selectLocationsToAccess);

  const dropDownItems = [{
    slug: 'yesterday',
    label: 'Ayer',
  }, {
    slug: 'today',
    label: 'Hoy',
  }, {
    slug: 'last-7-days',
    label: t(TRANSLATIONS.LAST_7_DAYS),
  }, {
    slug: 'last-30-days',
    label: t(TRANSLATIONS.LAST_30_DAYS),
  }, {
    slug: 'month-to-date',
    label: t(TRANSLATIONS.MONTH_TO_DATE),
  }];

  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [selectedDropDownItem, setSelectedDropDownItem] = useState(dropDownItems[0]);

  const getDateRange = () => {
    const currentDate = new Date();
    let filter = ['', ''] as any[];
    if (selectedDropDownItem.slug === 'today') {
      filter = [subDays(currentDate, 1), currentDate];
    }
    if (selectedDropDownItem.slug === 'yesterday') {
      filter = [subDays(currentDate, 2), subDays(currentDate, 1)];
    }
    if (selectedDropDownItem.slug === 'last-7-days') {
      filter = [subDays(currentDate, 7), currentDate];
    }
    if (selectedDropDownItem.slug === 'last-30-days') {
      filter = [subDays(currentDate, 30), currentDate];
    }
    if (selectedDropDownItem.slug === 'month-to-date') {
      const lastMonthDay = lastDayOfMonth(currentDate);
      const firstDayLastMonth = set(lastMonthDay, { date: 1 });
      filter = [firstDayLastMonth, lastMonthDay];
    }
    return [format(filter[0], 'yyyy-MM-dd hh:mm:ss'), format(filter[1], 'yyyy-MM-dd hh:mm:ss')];
  };

  const isToday = selectedDropDownItem.slug === 'today';

  const dateRange = getDateRange() as unknown as string[];

  const handleDropDownChange = ({ key }: any) => {
    setSelectedDropDownItem(dropDownItems.find(
      item => item.slug === key,
    ) || dropDownItems[0]);
    setDropDownOpen(false);
  };

  const { resultSet: planningResult } = useCubeQuery({
    measures: ['DailyProductionPlannings.totalPlanned'],
    timeDimensions: [{
      dimension: 'DailyProductionPlannings.day',
      dateRange,
    }],
    dimensions: [],
    filters: [{
      dimension: 'DailyProductionPlannings.locationId',
      operator: 'equals',
      values: locationsToAccess,
    }],
    order: {
      'DailyProductionPlannings.totalPlanned': 'desc',
    },
  });

  const { resultSet: realResult } = useCubeQuery({
    measures: [isToday ? 'DailyProductionPlannings.totalPlannedFactory' : 'DailyProductionPlannings.realSum'],
    timeDimensions: [{
      dimension: 'DailyProductionPlannings.day',
      dateRange,
    }],
    dimensions: [],
    filters: [{
      dimension: 'DailyProductionPlannings.locationId',
      operator: 'equals',
      values: locationsToAccess,
    }],
    order: {},
  });

  const plannedProduction = planningResult?.series()[0]
  && planningResult?.series()[0].series[0].value;
  let realProduction = (realResult?.rawData()[0] as any || {})['DailyProductionPlannings.real'];
  if (!realProduction) realProduction = (realResult?.rawData()[0] as any || {})['DailyProductionPlannings.totalPlannedFactory'];
  return (
    <div className="general-planning-analytics">
      <p>Proyección general</p>
      <div className="unit-section">
        <div className="unit-container">
          <div className="icon-name-container">
            <img
              src={executedBeerBottle}
              alt=""
              className="icon"
            />
            <p>{ isToday ? 'Vol. planeado' : 'Vol. real' }</p>
          </div>
          <p className="pull-right">{ (realProduction || 0).toLocaleString() } hl</p>
        </div>
        <div className="spacer-line" />
        <div className="unit-container">
          <div className="icon-name-container">
            <img
              src={plannedBeerBottle}
              alt=""
              className="icon"
            />
            <p>Vol. planeado CSC</p>
          </div>
          <p>{ (plannedProduction || 0).toLocaleString() } hl</p>
        </div>
        <div className="spacer-line" />
        <div className="unit-container">
          <div className="icon-name-container">
            {/* <img
              src={executedBeerBottle}
              alt=""
              className="icon"
            /> */}
            <p>Dif.</p>
          </div>
          <p
            className="pull-right"
            style={(realProduction - plannedProduction || 0) < 0 ? { color: 'red' } : { color: 'green' }}
          >{ (realProduction - plannedProduction || 0).toLocaleString() } hl
          </p>
        </div>
      </div>

      <ChartDropdown
        onChange={handleDropDownChange}
        selectedDropDownItem={selectedDropDownItem}
        dropDownItems={dropDownItems}
        dropDownOpen={dropDownOpen}
        setDropDownOpen={setDropDownOpen}
        labelClassName="label-default"
      />
    </div>
  );
}
