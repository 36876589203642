/* eslint-disable max-len */
import React, { useEffect } from 'react';

const Routines = () => {
  useEffect(() => {
    window.location.href = 'safetyapp://app/routine/truck-checklist-v2';
  }, []);

  return (
    <div style={{ margin: 20, textAlign: 'center' }}>
      <h3>Debes de tener la app de Safety 360 instalada</h3>
      <h5>
        Si tienes la app instalada y actualizada, la rutina se abrira inmediatamente, si no haz click en la siguiente liga para instalar o actualizar:
      </h5>
      <a href="https://play.google.com/store/apps/details?id=com.arvolution.gm.safety" style={{ color: '#2574FB', textDecoration: 'underline' }}>Safety 360</a>
    </div>
  );
};

export default Routines;
