import request from 'utils/request';
import { User } from 'generated/graphql';

type TUserSearchParams = {
  search: string;
  shortSlug?: string;
  locationSlug?: string;
};

export interface TSearchedUsers extends User {
  name: string;
  slug: string;
  shortSlug: string;
  isBlocked?: any;
  blockedReason?: any;
  position?: string;
}

export type Forklifts = {
  name: string;
  slug: string;
  externalId: string;
};

export default async function searchHandlerAPI(
  apiTarget: string,
  params: TUserSearchParams,
) {
  return (await request.get(apiTarget, params)) as {
    users: TSearchedUsers[];
    forklifts: Forklifts;
  };
}
