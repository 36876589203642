import React from 'react';

const ChevronDown = ({ color = '#878787' }) => {
  return (
    <>
      <svg
        width="10px"
        height="7px"
        viewBox="0 0 212 138"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Artboard 2</title>
        <desc>Created with Sketch.</desc>
        <defs />
        <g id="Artboard-2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <polygon
            id="Triangle"
            fill={color}
            transform="translate(106.000000, 69.000000) rotate(-180.000000) translate(-106.000000, -69.000000) "
            points="106 0 212 138 0 138"
          />
        </g>
      </svg>
    </>
  );
};

export default ChevronDown;
