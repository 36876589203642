import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';

import Iframe from 'components/Iframe';
import ChartTabs from 'components/Tabs/ChartTabs';

export default function AnalyticsSiosT2Wrapper() {
  const { t } = useTranslation();

  const tabs = [
    {
      title: t(TRANSLATIONS.OVERVIEW),
      iframe: 'https://app.powerbi.com/reportEmbed?reportId=44bdc84c-1496-4249-9662-07ac7ea8792b&autoAuth=true&ctid=cef04b19-7776-4a94-b89b-375c77a8f936',
      type: 'overview',
      value: '',
      pageName: 'ReportSection?',
      index: 0,
    },
    {
      title: t(TRANSLATIONS.DETAILS),
      iframe: 'https://app.powerbi.com/reportEmbed?reportId=44bdc84c-1496-4249-9662-07ac7ea8792b&autoAuth=true&ctid=cef04b19-7776-4a94-b89b-375c77a8f936',
      type: 'details',
      value: '',
      pageName: 'ReportSection?',
      index: 1,
    },
  ];

  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  return (
    <>
      <div className="container-fluid">
        <div className="row" style={{ marginBottom: -14 }}>
          <>
            <ChartTabs
              tabsItems={tabs}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab as any}
              selectedTabStyle="selected-tab"
              customStyle="tabs-container"
            />
            <div className="col-12 pl-2">
              <Iframe
                iframe={selectedTab.iframe}
                iframeTitle="Sios t2"
                pageName={selectedTab.pageName}
              />
            </div>
          </>
        </div>
      </div>
    </>
  );
}
