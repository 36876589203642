/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ChartDropdown from 'components/Dropdown/ChartDropdown/ChartDropdown';
import { TRANSLATIONS } from 'types/enums';
import ImageLinks from 'utils/ImageLinks';

export enum OWD_DROPDOWN_OPTIONS {
  DOWNLOAD = 'download',
  ARCHIVE = 'archive',
}

const OWDFileDropdown = ({ value, archiveOnChange }: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t } = useTranslation();
  const lineRefs = React.useRef<any>();
  const { download, overwrite } = ImageLinks;
  const fileOptions = [
    {
      label: t(TRANSLATIONS.OVER_WRITE_FILE),
      slug: OWD_DROPDOWN_OPTIONS.ARCHIVE,
      img: overwrite,
    },
    {
      label: t(TRANSLATIONS.DOWNLOAD_FILE),
      slug: OWD_DROPDOWN_OPTIONS.DOWNLOAD,
      img: download,
    },
  ];

  const [dropDownOpen, setDropDownOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedDropDownItem, setSelectedDropDownItem] = useState({
    slug: '',
    label: t(TRANSLATIONS.DOWNLOAD_TEMPLATE),
  });
  return (
    <>
      {' '}
      <input type="file" style={{ display: 'none' }} ref={lineRefs} onChange={archiveOnChange} />
      <ChartDropdown
        onChange={({ key }) => {
          setDropDownOpen(false);
          if (key === OWD_DROPDOWN_OPTIONS.DOWNLOAD) {
            window.open(value?.archive, '_blank');
          }
          if (key === OWD_DROPDOWN_OPTIONS.ARCHIVE) {
            lineRefs.current.click();
          }
        }}
        selectedDropDownItem={selectedDropDownItem}
        dropDownItems={fileOptions}
        dropDownOpen={dropDownOpen}
        setDropDownOpen={setDropDownOpen}
        labelClassName="label-default user-action-dropdown"
        dropdownArrowColor="#4472C4"
      />
    </>
  );
};

export default OWDFileDropdown;
