import React, { Component } from 'react';
import { ReactComponent as UserRegularIcon } from 'assets/icons/user-regular.svg';
import { ReactComponent as WetFloorIcon } from 'assets/icons/wet-floor.svg';
import { ReactComponent as TimeIcon } from 'assets/icons/time.svg';
import { ReactComponent as ProgressIcon } from 'assets/icons/progress-icon.svg';
import { ReactComponent as FileIcon } from 'assets/icons/file-icon.svg';
import 'features/internal-users/SafetyDashboard/AgencyDataOverview/AgencyDataOverview.scss';
// TODO replace above components with img with src and "alt". src is ready in ImageLinks

export default class AgencyDataOverview extends Component {
  render() {
    return (
      <div className="card agency-data-overview">
        <div className="row" style={{ padding: 13 }}>
          <div className="col" style={{ borderRight: '1px solid #D8E1E6' }}>
            <div className="row no-gutters overview-container">
              <UserRegularIcon
                width="20"
                height="20"
                className="card-image"
              />
              <p className="text-light">
                <span className="text-boldest">
                  65
                </span> ayudantes
              </p>
            </div>
            <div className="row no-gutters overview-container">
              <WetFloorIcon
                width="20"
                height="20"
                className="card-image"
              />
              <p className="text-light">
                <span className="text-boldest">
                  360
                </span> veces se reportó piso mojado
              </p>
            </div>
            <div className="row no-gutters overview-container">
              <FileIcon
                width="20"
                height="20"
                className="card-image"
              />
              <p className="text-light">
                <span className="text-boldest">
                  4.5
                </span> ACI&apos;s por persona
              </p>
            </div>
          </div>
          <div className="col" style={{ paddingLeft: 30 }}>
            <div className="row no-gutters overview-container">
              <TimeIcon
                width="20"
                height="20"
                className="card-image"
              />
              <p className="text-light">
                Cada&nbsp;
                <span className="text-boldest">
                  0.3
                </span> días se registra un accidente
              </p>
            </div>
            <div className="row no-gutters overview-container">
              <TimeIcon
                width="20"
                height="20"
                className="card-image"
              />
              <p className="text-light">
                Último LTI sucedió hace&nbsp;
                <span className="text-boldest">
                  3 días
                </span>
              </p>
            </div>
            <div className="row no-gutters overview-container">
              <ProgressIcon
                width="20"
                height="20"
                className="card-image"
              />
              <p className="text-light">
                <span className="text-boldest">
                  2do
                </span> lugar en UEN Metropolitana
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
