import React, { useState } from 'react';
import 'rc-dropdown/assets/index.css';
import './IncidentsPieChart.scss';
import '../../Overview/IncidentsChart/IncidentsChart.scss';
import { set, subDays } from 'date-fns/esm';
import ChartTabs from 'components/Tabs/ChartTabs';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';
import { lastDayOfMonth } from 'date-fns/esm/fp';
import { format } from 'date-fns';
import { useCubeQuery } from '@cubejs-client/react';
import { selectLocationsToAccess } from 'store/selectors/globalFilters.selectors';
import { useSelector } from 'react-redux';
import ChartDropdown from 'components/Dropdown/ChartDropdown';
import ApexCharts from 'apexcharts';
import LoadingSpinner from 'components/LoadingSpinner';
import { routinesMonthSelectordropDownItems } from 'utils/dateHelpers';
import moment from 'moment';
import { selectTimezone } from 'store/selectors/timeFilter.selector';

const dropDownItemsArr = routinesMonthSelectordropDownItems(true);

const IncidentsPieChart = () => {
  const { t } = useTranslation();
  const timezone = useSelector(selectTimezone);

  let INSIDE_DC = 0;
  let OUTSIDE_DC = 0;
  let CONDITION = 0;
  let ACT = 0;

  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [locationTypeLegendIndex, setLocationTypeLegendIndex] = useState(-1);
  const [issueTypeLegendIndex, setIssueTypeLegendIndex] = useState(-1);
  const locationsToAccess = useSelector(selectLocationsToAccess);

  const years = [
    {
      slug: moment().format('YYYY'),
      label: moment().format('YYYY'),
      startTime: moment().clone().startOf('year').format('yyyy-MM-DD'),
      endTime: moment().clone().endOf('year').format('yyyy-MM-DD'),
    },
    {
      slug: moment().subtract(1, 'years').format('YYYY'),
      label: moment().subtract(1, 'years').format('YYYY'),
      startTime: moment().subtract(1, 'years').startOf('year').format('yyyy-MM-DD'),
      endTime: moment().subtract(1, 'years').endOf('year').format('yyyy-MM-DD'),
    },
  ];

  const dropDownItems = [{
    slug: 'last-7-days',
    label: t(TRANSLATIONS.LAST_7_DAYS),
  }, {
    slug: 'last-30-days',
    label: t(TRANSLATIONS.LAST_30_DAYS),
  }, {
    slug: 'month-to-date',
    label: t(TRANSLATIONS.MONTH_TO_DATE),
  },
  ...dropDownItemsArr,
  ...years,
  ];

  const [selectedDropDownItem, setSelectedDropDownItem] = useState<any>(dropDownItems[0]);

  const getDateRange = () => {
    const currentDate = new Date();
    let filter = ['', ''] as any[];
    if (selectedDropDownItem.slug === 'last-7-days') {
      filter = [subDays(currentDate, 7), currentDate];
    }
    if (selectedDropDownItem.slug === 'last-30-days') {
      filter = [subDays(currentDate, 30), currentDate];
    }
    if (selectedDropDownItem.slug === 'month-to-date') {
      const lastMonthDay = lastDayOfMonth(currentDate);
      const firstDayLastMonth = set(lastMonthDay, { date: 1 });
      filter = [firstDayLastMonth, lastMonthDay];
    }
    if (selectedDropDownItem?.slug?.split(' ')?.length === 2) {
      const dateFromSlug = selectedDropDownItem.slug.split(' ');
      const dateMonth = moment().year(dateFromSlug[1]).week(selectedDropDownItem?.content?.weekNumber + 1).format('M');
      const monthFirstDate = new Date(+dateFromSlug[1], +dateMonth - 1, +1);
      const lastMonthDay = lastDayOfMonth(monthFirstDate);
      const firstDayLastMonth = set(lastMonthDay, { date: 1 });
      filter = [firstDayLastMonth, lastMonthDay];
    }
    if (selectedDropDownItem?.slug?.length === 4) {
      filter = [selectedDropDownItem?.startTime, selectedDropDownItem?.endTime];
      return filter;
    }
    return [format(filter[0], 'yyyy-MM-dd'), format(filter[1], 'yyyy-MM-dd')];
  };

  const dateRange = getDateRange() as unknown as string[];

  const computeIncidents = (incidents: any) => {
    INSIDE_DC = 0;
    let odc = 0;
    incidents?.series.map((s: any) => {
      if (s.category === 'INSIDE_DC') {
        INSIDE_DC = s.value;
      } else {
        odc += s.value;
      }
    });
    OUTSIDE_DC = odc;
  };

  const computeIncidentsIssueType = (incidents: any) => {
    ACT = 0;
    CONDITION = 0;
    incidents?.series.map((s: any) => {
      if (s.category === 'ACT') {
        ACT = s.value;
      } else {
        CONDITION = s.value;
      }
    });
  };

  const { resultSet: countResult } = useCubeQuery({
    measures: [
      'Incidents.count',
    ],
    timeDimensions: [{
      dimension: 'Incidents.incidentdatetime',
      dateRange,
    }],
    dimensions: [
      'Incidents.locationtype',
    ],
    order: {
      'Incidents.incidentdatetime': 'asc',
    },
    filters: [{
      member: 'Incidents.locationId',
      operator: 'equals',
      values: locationsToAccess,
    },
    {
      dimension: 'Incidents.deletedat',
      operator: 'notSet',
    },
    {
      dimension: 'Incidents.typeofissue',
      operator: 'equals',
      values: ['CONDITION', 'ACT'],
    }],
    timezone,
  });

  const { resultSet: countResultIssueType }: { resultSet: any } = useCubeQuery({
    measures: [
      'Incidents.count',
    ],
    timeDimensions: [{
      dimension: 'Incidents.incidentdatetime',
      dateRange,
    }],
    dimensions: [
      'Incidents.typeofissue',
    ],
    order: {
      'Incidents.incidentdatetime': 'asc',
    },
    filters: [{
      member: 'Incidents.locationId',
      operator: 'equals',
      values: locationsToAccess,
    },
    {
      dimension: 'Incidents.deletedat',
      operator: 'notSet',
    },
    {
      dimension: 'Incidents.typeofissue',
      operator: 'equals',
      values: ['CONDITION', 'ACT'],
    }],
    timezone,
  });

  const incidentsIssueType: any = countResultIssueType?.series()[0];

  const incidents: any = countResult?.series()[0];

  // const isLoading = !(incidents && locationsToAccess.length);

  let chartDataOptionsAndSeries: { options: any } = {
    options: null,
  };

  // CUBE JS QUERIES FOR FETCHING COUNTS OF INCIDENTS

  const reducer = (accumulator: any, currentValue: any) => accumulator + currentValue.value;

  const { resultSet: openIncidents }: { resultSet: any } = useCubeQuery({
    measures: [
      'Incidents.count',
    ],
    timeDimensions: [{
      dimension: 'Incidents.incidentdatetime',
      dateRange,
    }],
    dimensions: [
      'Incidents.locationtype',
    ],
    order: {
      'Incidents.incidentdatetime': 'asc',
    },
    filters: [{
      member: 'Incidents.isUnsafe',
      operator: 'equals',
      values: ['1'],
    }, {
      dimension: 'Incidents.locationId',
      operator: 'equals',
      values: locationsToAccess,
    },
    {
      dimension: 'Incidents.assignedat',
      operator: 'notSet',
    },
    {
      dimension: 'Incidents.deletedat',
      operator: 'notSet',
    },
    {
      dimension: 'Incidents.closedat',
      operator: 'notSet',
    },
    {
      dimension: 'Incidents.typeofissue',
      operator: 'equals',
      values: ['CONDITION', 'ACT'],
    }],
    timezone,
  });

  const incidentsOpenCountArray: any = openIncidents?.series()[0];

  const { resultSet: openIncidentsIssueType }: { resultSet: any } = useCubeQuery({
    measures: [
      'Incidents.count',
    ],
    timeDimensions: [{
      dimension: 'Incidents.incidentdatetime',
      dateRange,
    }],
    dimensions: [
      'Incidents.typeofissue',
    ],
    order: {
      'Incidents.incidentdatetime': 'asc',
    },
    filters: [{
      member: 'Incidents.isUnsafe',
      operator: 'equals',
      values: ['1'],
    }, {
      dimension: 'Incidents.locationId',
      operator: 'equals',
      values: locationsToAccess,
    },
    {
      dimension: 'Incidents.assignedat',
      operator: 'notSet',
    },
    {
      dimension: 'Incidents.deletedat',
      operator: 'notSet',
    },
    {
      dimension: 'Incidents.typeofissue',
      operator: 'equals',
      values: ['CONDITION', 'ACT'],
    }],
    timezone,
  });

  const incidentsIssueTypeOpenCountArray: any = openIncidentsIssueType?.series()[0];

  const { resultSet: closedAtIncidents }: { resultSet: any } = useCubeQuery({
    measures: [
      'Incidents.count',
    ],
    timeDimensions: [{
      dimension: 'Incidents.incidentdatetime',
      dateRange,
    }],
    dimensions: [
      'Incidents.locationtype',
    ],
    order: {
      'Incidents.incidentdatetime': 'asc',
    },
    filters: [{
      member: 'Incidents.isUnsafe',
      operator: 'equals',
      values: ['1'],
    }, {
      dimension: 'Incidents.locationId',
      operator: 'equals',
      values: locationsToAccess,
    },
    {
      dimension: 'Incidents.closedat',
      operator: 'set',
    },
    {
      dimension: 'Incidents.deletedat',
      operator: 'notSet',
    },
    {
      dimension: 'Incidents.typeofissue',
      operator: 'equals',
      values: ['CONDITION', 'ACT'],
    }],
    timezone,
  });

  const incidentsClosed: any = closedAtIncidents?.series()[0];

  const { resultSet: closedAtIncidentsIssueType }: { resultSet: any } = useCubeQuery({
    measures: [
      'Incidents.count',
    ],
    timeDimensions: [{
      dimension: 'Incidents.incidentdatetime',
      dateRange,
    }],
    dimensions: [
      'Incidents.typeofissue',
    ],
    order: {
      'Incidents.incidentdatetime': 'asc',
    },
    filters: [{
      member: 'Incidents.isUnsafe',
      operator: 'equals',
      values: ['1'],
    }, {
      dimension: 'Incidents.locationId',
      operator: 'equals',
      values: locationsToAccess,
    },
    {
      dimension: 'Incidents.closedat',
      operator: 'set',
    },
    {
      dimension: 'Incidents.deletedat',
      operator: 'notSet',
    },
    {
      dimension: 'Incidents.typeofissue',
      operator: 'equals',
      values: ['CONDITION', 'ACT'],
    }],
    timezone,
  });

  const incidentsIssueTypeClosed: any = closedAtIncidentsIssueType?.series()[0];

  const { resultSet: assignedIncidents }: { resultSet: any } = useCubeQuery({
    measures: [
      'Incidents.count',
    ],
    timeDimensions: [{
      dimension: 'Incidents.incidentdatetime',
      dateRange,
    }],
    dimensions: [
      'Incidents.locationtype',
    ],
    order: {
      'Incidents.incidentdatetime': 'asc',
    },
    filters: [{
      member: 'Incidents.isUnsafe',
      operator: 'equals',
      values: ['1'],
    }, {
      dimension: 'Incidents.locationId',
      operator: 'equals',
      values: locationsToAccess,
    },
    {
      dimension: 'Incidents.closedat',
      operator: 'notSet',
    },
    {
      dimension: 'Incidents.assignedat',
      operator: 'set',
    },
    {
      dimension: 'Incidents.deletedat',
      operator: 'notSet',
    },
    {
      dimension: 'Incidents.typeofissue',
      operator: 'equals',
      values: ['CONDITION', 'ACT'],
    }],
    timezone,
  });

  const incidentsAssigned: any = assignedIncidents?.series()[0];

  const { resultSet: safeIncidents }: { resultSet: any } = useCubeQuery({
    measures: [
      'Incidents.count',
    ],
    timeDimensions: [{
      dimension: 'Incidents.incidentdatetime',
      dateRange,
    }],
    dimensions: [
      'Incidents.locationtype',
    ],
    order: {
      'Incidents.incidentdatetime': 'asc',
    },
    filters: [{
      member: 'Incidents.isUnsafe',
      operator: 'equals',
      values: ['0'],
    }, {
      dimension: 'Incidents.locationId',
      operator: 'equals',
      values: locationsToAccess,
    },
    {
      dimension: 'Incidents.deletedat',
      operator: 'notSet',
    },
    {
      dimension: 'Incidents.typeofissue',
      operator: 'equals',
      values: ['CONDITION', 'ACT'],
    }],
    timezone,
  });

  const incidentsSafe: any = safeIncidents?.series()[0];

  const { resultSet: safeIncidentsIssueType }: { resultSet: any } = useCubeQuery({
    measures: [
      'Incidents.count',
    ],
    timeDimensions: [{
      dimension: 'Incidents.incidentdatetime',
      dateRange,
    }],
    dimensions: [
      'Incidents.typeofissue',
    ],
    order: {
      'Incidents.incidentdatetime': 'asc',
    },
    filters: [{
      member: 'Incidents.isUnsafe',
      operator: 'equals',
      values: ['0'],
    }, {
      dimension: 'Incidents.locationId',
      operator: 'equals',
      values: locationsToAccess,
    },
    {
      dimension: 'Incidents.deletedat',
      operator: 'notSet',
    },
    {
      dimension: 'Incidents.typeofissue',
      operator: 'equals',
      values: ['CONDITION', 'ACT'],
    }],
    timezone,
  });

  const safeAssignedIssueType: any = safeIncidentsIssueType?.series()[0];

  const { resultSet: assignedIncidentsIssueType }: { resultSet: any } = useCubeQuery({
    measures: [
      'Incidents.count',
    ],
    timeDimensions: [{
      dimension: 'Incidents.incidentdatetime',
      dateRange,
    }],
    dimensions: [
      'Incidents.typeofissue',
    ],
    order: {
      'Incidents.incidentdatetime': 'asc',
    },
    filters: [{
      member: 'Incidents.isUnsafe',
      operator: 'equals',
      values: ['1'],
    }, {
      dimension: 'Incidents.locationId',
      operator: 'equals',
      values: locationsToAccess,
    },
    {
      dimension: 'Incidents.closedat',
      operator: 'notSet',
    },
    {
      dimension: 'Incidents.assignedat',
      operator: 'set',
    },
    {
      dimension: 'Incidents.deletedat',
      operator: 'notSet',
    },
    {
      dimension: 'Incidents.typeofissue',
      operator: 'equals',
      values: ['CONDITION', 'ACT'],
    }],
    timezone,
  });

  const incidentsAssignedIssueType: any = assignedIncidentsIssueType?.series()[0];
  const totalIncidents = incidents?.series?.reduce(reducer, 0);
  const totalOpen = incidentsOpenCountArray?.series?.reduce(reducer, 0);
  const totalClosed = incidentsClosed?.series?.reduce(reducer, 0);
  const totalAssigned = incidentsAssigned?.series?.reduce(reducer, 0);
  const safeIncidentsT = incidentsSafe?.series?.reduce(reducer, 0) || 0;
  const incidentsWithoutSafe = totalIncidents - safeIncidentsT;

  const tabsItems = [{
    title: t(TRANSLATIONS.TOTAL),
    value: totalIncidents?.toLocaleString() || '-',
  }, {
    title: t(TRANSLATIONS.OPENED),
    value: (totalOpen && incidentsWithoutSafe) ? `${totalOpen.toLocaleString()} (${((totalOpen / incidentsWithoutSafe) * 100).toFixed(1)}%)` : '-',
  }, {
    title: t(TRANSLATIONS.CLOSED),
    value: (totalClosed && incidentsWithoutSafe) ? `${totalClosed.toLocaleString()} (${((totalClosed / incidentsWithoutSafe) * 100).toFixed(1)}%)` : '-',
  }, {
    title: t(TRANSLATIONS.ASSIGNED),
    value: (totalAssigned && incidentsWithoutSafe) ? `${totalAssigned.toLocaleString()} (${((totalAssigned / incidentsWithoutSafe) * 100).toFixed(1)}%)` : '-',
  }, {
    title: t(TRANSLATIONS.SAFE),
    value: safeIncidentsT ? `${safeIncidentsT.toLocaleString()} (${((safeIncidentsT / incidentsWithoutSafe) * 100).toFixed(1)}%)` : '-',
  }];

  const [selectedTab, setSelectedTab] = useState(tabsItems[0]);

  if (selectedTab.title === t(TRANSLATIONS.OPENED)) {
    computeIncidents(incidentsOpenCountArray);
    computeIncidentsIssueType(incidentsIssueTypeOpenCountArray);
  }
  if (selectedTab.title === t(TRANSLATIONS.TOTAL)) {
    computeIncidents(incidents);
    computeIncidentsIssueType(incidentsIssueType);
  }
  if (selectedTab.title === t(TRANSLATIONS.CLOSED)) {
    computeIncidents(incidentsClosed);
    computeIncidentsIssueType(incidentsIssueTypeClosed);
  }
  if (selectedTab.title === t(TRANSLATIONS.ASSIGNED)) {
    computeIncidents(incidentsAssigned);
    computeIncidentsIssueType(incidentsAssignedIssueType);
  }
  if (selectedTab.title === tabsItems[4].title) {
    computeIncidents(incidentsSafe);
    computeIncidentsIssueType(safeAssignedIssueType);
  }

  chartDataOptionsAndSeries = {
    options: {
      states: {
        hover: {
          filter: {
            type: 'none',
          },
        },
      },
      chart: {
        width: 380,
        id: 'location-type',
        type: 'pie',
      },
      tooltip: {
        enabled: false,
      },
      dataLabels: {
        enabled: true,
        textAnchor: 'start',
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: '11px',
          colors: ['#E4F2DA', '#70AD47', 'red', 'blue'],
        },
        dropShadow: {
          enabled: false,
        },
      },
      fill: {
        colors: ['#70AD47', '#E4F2DA', 'red', 'blue'],
      },
      labels: [t(TRANSLATIONS.INSIDE_DC), t(TRANSLATIONS.OUTSIDE_DC)],
      // responsive: [{
      //   breakpoint: 480,
      //   options: {
      //     chart: {
      //       width: 200,
      //     },
      //     fill: {
      //       colors: ['#70AD47', '#E4F2DA', 'red', 'blue'],
      //     },
      //     legend: {
      //       position: 'bottom',
      //     },
      //   },
      // }],
      legend: {
        show: false,
      },
    },
  };

  const handleDropDownChange = ({ key }: any) => {
    setSelectedDropDownItem(dropDownItems.find(
      item => item.slug === key,
    ) || dropDownItems[0]);
    setDropDownOpen(false);
    setLocationTypeLegendIndex(-1);
    setIssueTypeLegendIndex(-1);
  };

  const f = false;

  return (
    <div className="incidents-pie-chart">

      <div
        className="tabs-container"
      >
        <ChartTabs
          tabsItems={tabsItems as any}
          setSelectedTab={setSelectedTab as any}
          selectedTab={selectedTab as any}
        />
      </div>
      {
        f ? (
          <LoadingSpinner height={233} />
        ) : (
          <div className="row pb-2">
            <div className="rowNew col-sm-6 px-4 pt-4 pb-0">
              <div className="">
                <div
                  role="button"
                  className="legendCard"
                  style={{
                    backgroundColor: locationTypeLegendIndex === 0 ? '#F7F7F7' : 'white',
                  }}
                  onClick={() => {
                    if (INSIDE_DC === 0) return;
                    ApexCharts.exec('location-type', 'toggleDataPointSelection', 0);
                    setLocationTypeLegendIndex(0);
                  }}
                >
                  <p style={{ fontWeight: 'bold' }}>{INSIDE_DC.toLocaleString()}</p>
                  <div className="row pl-3 pr-3">
                    <div
                      className="circle1"
                    />
                    <p className="legend-name">{t(TRANSLATIONS.INSIDE_DC)}</p>
                  </div>
                </div>
                <div
                  role="button"
                  className="legendCard"
                  style={{
                    backgroundColor: locationTypeLegendIndex === 1 ? '#F7F7F7' : 'white',
                  }}
                  onClick={() => {
                    if (OUTSIDE_DC === 0) return;
                    ApexCharts.exec('location-type', 'toggleDataPointSelection', 1);
                    setLocationTypeLegendIndex(1);
                  }}
                >
                  <p style={{ fontWeight: 'bold' }}>{OUTSIDE_DC.toLocaleString()}</p>
                  <div className="row pl-3 pr-3">
                    <div
                      className="circle2"
                    />
                    <p className="legend-name">{t(TRANSLATIONS.OUTSIDE_DC)}</p>
                  </div>
                </div>
              </div>
              <div>
                <Chart
                  type="pie"
                  height={200}
                  options={chartDataOptionsAndSeries.options}
                  series={[INSIDE_DC, OUTSIDE_DC]}
                />
              </div>
            </div>

            <div className="vertical" />
            <div className="rowNew col-sm-6 px-4 pt-4 pb-0">
              <div className="">
                <div
                  role="button"
                  className="legendCard"
                  style={{
                    backgroundColor: issueTypeLegendIndex === 0 ? '#F7F7F7' : 'white',
                  }}
                  onClick={() => {
                    if (ACT === 0) return;
                    ApexCharts.exec('issue-type', 'toggleDataPointSelection', 0);
                    setIssueTypeLegendIndex(0);
                  }}
                >
                  <p style={{ fontWeight: 'bold' }}>{ACT.toLocaleString()}</p>
                  <div className="row pl-3 pr-3">
                    <div
                      className="circle1"
                    />
                    <p className="legend-name">{t(TRANSLATIONS.ACT)}</p>
                  </div>
                </div>
                <div
                  role="button"
                  className="card legendCard"
                  style={{
                    backgroundColor: issueTypeLegendIndex === 1 ? '#F7F7F7' : 'white',
                  }}
                  onClick={() => {
                    if (CONDITION === 0) return;
                    ApexCharts.exec('issue-type', 'toggleDataPointSelection', 1);
                    setIssueTypeLegendIndex(1);
                  }}
                >
                  <p style={{ fontWeight: 'bold' }}>{CONDITION.toLocaleString()}</p>
                  <div className="row pl-3 pr-3">
                    <div
                      className="circle2"
                    />
                    <p className="legend-name">{t(TRANSLATIONS.CONDITION)}</p>
                  </div>
                </div>
              </div>
              <div>
                <Chart
                  type="pie"
                  height={200}
                  options={{
                    ...chartDataOptionsAndSeries.options,
                    ...{
                      labels: [
                        t(TRANSLATIONS.ACT),
                        t(TRANSLATIONS.CONDITION),
                      ],
                    },
                    ...{ chart: { id: 'issue-type' } },
                  }}
                  series={[ACT, CONDITION]}
                />
              </div>
            </div>
          </div>
        )
      }
      <div className="pb-1">
        <ChartDropdown
          onChange={handleDropDownChange}
          selectedDropDownItem={selectedDropDownItem}
          dropDownItems={dropDownItems}
          dropDownOpen={dropDownOpen}
          setDropDownOpen={setDropDownOpen}
          labelClassName="label-default"
        />
      </div>
    </div>
  );
};

export default IncidentsPieChart;
