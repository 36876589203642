/* eslint-disable @typescript-eslint/default-param-last */
import { fromJS } from 'immutable';
import { SET_AUTHENTICATED_USER } from '../actions/user.actions';

const INITIAL_STATE = fromJS({ isAuthenticating: true });

const userReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SET_AUTHENTICATED_USER:
      return fromJS(action.user).set('isAuthenticating', false);
    // case SET_USER_HEADER_INFO:
    //   return state.update('userHeaderInfo', () => fromJS(action.info));
    // case SET_USER_ACTIVITY_DETAIL:
    //   return state.update('userActivityDetail', () => fromJS(action.detail));
    // case SET_USER_BOXES_BY_PRESENTATION:
    //   return state.update('userBoxesByPresentation', () => fromJS(action.presentation));
    // case SET_USER_WEEKLY_PAYMENT:
    //   return state.update('userWeeklyPayment', () => fromJS(action.payment));
    default:
      return state;
  }
};

export default userReducer;
