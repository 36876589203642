/* eslint-disable @typescript-eslint/default-param-last */
import esLocale from 'date-fns/locale/es';
import {
  addMinutes,
  addMonths,
  addYears,
  differenceInDays,
  endOfMonth,
  endOfYear,
  format,
  getDay,
  getMonth,
  getWeek,
  getYear,
  lastDayOfMonth,
  nextMonday,
  previousMonday,
  setHours,
  setMinutes,
  setMonth,
  setSeconds,
  startOfMonth,
  startOfYear,
  subDays,
  isEqual,
  addHours,
  isBefore,
  startOfDay,
  previousSaturday,
  nextSaturday,
} from 'date-fns';
import { es, enUS } from 'date-fns/locale';
import i18next from 'i18next';
import { TRANSLATIONS } from 'types/enums';
import moment from 'moment-timezone';

import { capitalizeFirst } from './stringHelpers';

/**
 * Base year for few analytic feature like complaince
 */
export const BASE_YEAR = 2021;
type DetailedYearDropDownOptionContent = {
  startWeek: Date;
  weeks: number;
  weekNumber: number;
  lastDayOfLastWeek: Date;
};

export type DetailedYearDropDownOption = {
  slug: string;
  label: string;
  content: DetailedYearDropDownOptionContent;
};

export function getCurrentDate() {
  const startDate = startOfDay(new Date());
  const endDate = addHours(startDate, 24);
  return { startDate, endDate };
}

export function formatTimeLabel(
  times: any,
  timeUnit: string,
  distributionCenterUTCOffset: number,
  isTable?: boolean,
): any[] {
  /** timeUnit: 'hour' | 'day' | 'week' | 'month'
   * a daily datum that represents a single day,
   * e.g. the whole day of the 27th of march, is represented with start date
   * a monthly datum that represents a whole month,
   * e.g. the whole of January, is represented with start date
   * everything else is represented with a range (e.g. 27th of march-27th of April, or 7PM-8PM)
   * */

  let formatFunction: Function;
  const hourFormat = 'H';
  const dayFormat = 'd/MM';
  const dayTableFormat = 'dddd d';
  const weekFormat = 'MMM d';
  const monthFormat = 'dd/M';

  // build the format function depending on period of time labels
  switch (timeUnit) {
    case 'hour':
      formatFunction = (startDate: Date, endDate: Date) => {
        // hour begins at 00?
        const hourlyFormat = startDate.getMinutes() || endDate.getMinutes() ? 'h[:]mmA' : hourFormat;
        return `${format(startDate, hourlyFormat, { locale: esLocale })} - ${format(
          endDate,
          hourlyFormat,
          { locale: esLocale },
        )}`;
      };
      break;

    case 'day':
      formatFunction = (startDate: Date) => (isTable
        ? format(startDate, dayTableFormat, { locale: esLocale }).split(' ')
        : format(startDate, dayFormat, { locale: esLocale }));
      break;

    case 'week':
      formatFunction = (startDate: Date, endDate: Date) => {
        let endDayEarlier;
        // for end date: take previous day when it begins at 00:00
        if (endDate.getHours() === 0 && endDate.getMinutes() === 0) {
          endDayEarlier = subDays(endDate, 1);
        }

        return `${format(startDate, weekFormat, { locale: esLocale })} - ${format(
          endDayEarlier || endDate,
          weekFormat,
          { locale: esLocale },
        )}`;
      };
      break;

    case 'month':
      formatFunction = (startDate: Date, endDate: Date) => {
        // partial months
        if (differenceInDays(endDate, startDate) < 28) {
          return `${format(startDate, monthFormat, { locale: esLocale })} - ${format(
            endDate,
            monthFormat,
            { locale: esLocale },
          )}`;
        }

        return format(startDate, 'MMM', { locale: esLocale });
      };
      break;

    default:
      formatFunction = (startDate: Date, endDate: Date) => `${startDate.toLocaleString()} - ${endDate.toLocaleString()}`;
  }

  return times.map((o: any) => {
    const startLocalDate = new Date(o.start);
    const endLocalDate = new Date(o.end);
    const startDateDistributionCenter = addMinutes(
      startLocalDate,
      startLocalDate.getTimezoneOffset() - +distributionCenterUTCOffset,
    );
    const endDateDistributionCenter = addMinutes(
      endLocalDate,
      endLocalDate.getTimezoneOffset() - +distributionCenterUTCOffset,
    );

    return formatFunction(startDateDistributionCenter, endDateDistributionCenter);
  });
}

export function generateQueryDates(startTime?: Date, endTime?: Date) {
  const today = getCurrentDate();

  const jsStart = new Date(startTime || today.startDate);
  const jsEnd = new Date(endTime || today.endDate);

  return {
    startTime: startTime || today.startDate,
    endTime: endTime || today.endDate,
    timezoneOffsetStart: jsStart?.getTimezoneOffset(),
    timezoneOffsetEnd: jsEnd.getTimezoneOffset(),
  };
}

export function getTimeFiltersFromState(state: any) {
  return generateQueryDates(
    state.getIn(['timeFilter', 'startTime']),
    state.getIn(['timeFilter', 'endTime']),
  );
}
export function getTranslatedDateTimeValue(
  date = new Date(),
  formatPattern: string,
  lang?: typeof enUS | typeof es,
) {
  let locale;
  if (lang?.code) {
    locale = lang;
  } else {
    locale = i18next.language?.indexOf('en') === 0 ? enUS : es;
  }
  return format(new Date(date), formatPattern, { locale });
}

export const TIME_ZONE_OFFSET = new Date().getTimezoneOffset();

export const switchMonthToSpanish = (month: string) => {
  switch (month) {
    case 'January':
      return 'ENERO';
    case 'February':
      return 'FEBRERO';
    case 'March':
      return 'MARZO';
    case 'April':
      return 'ABRIL';
    case 'May':
      return 'MAYO';
    case 'June':
      return 'JUNIO';
    case 'July':
      return 'JULIO';
    case 'August':
      return 'AGOSTO';
    case 'September':
      return 'SEPTIEMBRE';
    case 'October':
      return 'OCTUBRE';
    case 'November':
      return 'NOVIEMBRE';
    case 'December':
      return 'DICIEMBRE';
    default:
      return '';
  }
};

export function semestralPeriodOptionsCalculator(startPeriod = new Date(2020, 0, 1)) {
  const periodOptions = [];
  while (differenceInDays(startOfMonth(new Date()), startPeriod) >= 0) {
    periodOptions.push({
      slug: `${getTranslatedDateTimeValue(startPeriod, 'MMM')} - ${getTranslatedDateTimeValue(
        setMonth(startPeriod, 5),
        'MMM',
      )} ${getTranslatedDateTimeValue(startPeriod, 'yyyy')}`,
      label: `${capitalizeFirst(
        getTranslatedDateTimeValue(startPeriod, 'MMM'),
      )} - ${capitalizeFirst(getTranslatedDateTimeValue(
        setMonth(startPeriod, 5),
        'MMM',
      ))} ${getTranslatedDateTimeValue(startPeriod, 'yyyy')}`,
      startTime: startPeriod,
      endTime: endOfMonth(setMonth(startPeriod, 5)),
    });
    if (setMonth(startPeriod, 6) < new Date()) {
      periodOptions.push({
        slug: `${getTranslatedDateTimeValue(
          setMonth(startPeriod, 6),
          'MMM',
        )} - ${getTranslatedDateTimeValue(
          setMonth(startPeriod, 11),
          'MMM',
        )} ${getTranslatedDateTimeValue(startPeriod, 'yyyy')}`,
        label: `${capitalizeFirst(
          getTranslatedDateTimeValue(setMonth(startPeriod, 6), 'MMM'),
        )} - ${capitalizeFirst(getTranslatedDateTimeValue(
          setMonth(startPeriod, 11),
          'MMM',
        ))} ${getTranslatedDateTimeValue(startPeriod, 'yyyy')}`,
        startTime: setMonth(startPeriod, 6),
        endTime: endOfMonth(setMonth(startPeriod, 11)),
      });
    }

    startPeriod = addYears(startPeriod, 1);
  }
  return periodOptions.reverse();
}
export const semestralPeriodOptions = semestralPeriodOptionsCalculator();

export function getYearList(startYear: Date, endYear: Date) {
  const yearList = [];
  for (let year: number = startYear.getUTCFullYear(); year <= endYear.getUTCFullYear(); year++) {
    const label = year.toString();
    const value = new Date(label);
    yearList.push({ label, slug: value.toString() });
  }
  return yearList.reverse();
}
export function monthData(date: Date) {
  const receivedDate = new Date(date);
  return receivedDate.toLocaleString('en-US', { month: 'short', year: 'numeric' });
}

export function isCurrentMonth(date: Date) {
  const presentDate = new Date();
  const receivedDate = new Date(date);
  return presentDate.getMonth() > receivedDate.getMonth();
}

export function getMonthRange(startDate: Date, endDate: Date) {
  const startYear = getYear(startDate);
  const endYear = getYear(endDate);
  const dates = [];

  for (let i = startYear; i <= endYear; i++) {
    const endMonth = i !== endYear ? 11 : getMonth(endDate) - 1;
    const startMon = i === startYear ? getMonth(startDate) - 1 : 0;
    for (let j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
      const month = j + 1;
      const displayMonth = month < 10 ? month : month;
      dates.push(new Date(i, displayMonth, 1));
    }
  }
  return dates;
}

export default {
  getCurrentDate,
};

export const months = [
  TRANSLATIONS.JANUARY,
  TRANSLATIONS.FEBRUARY,
  TRANSLATIONS.MARCH,
  TRANSLATIONS.APRIL,
  TRANSLATIONS.MAY,
  TRANSLATIONS.JUNE,
  TRANSLATIONS.JULY,
  TRANSLATIONS.AUGUST,
  TRANSLATIONS.SEPTEMBER,
  TRANSLATIONS.OCTOBER,
  TRANSLATIONS.NOVEMBER,
  TRANSLATIONS.DECEMBER,
];

export function getMonthsDropDown(t: Function, labelKey = 'label', slugKey = 'slug', monthsArray = months) {
  return monthsArray.map((month, index) => ({
    [labelKey]: t(month),
    [slugKey]: index + 1,
  }));
}

export function getYearsFromBaseYear(baseYear = BASE_YEAR, isReversed = true): number[] {
  const currentYear = getYear(new Date());
  const years: number[] = [];
  if (isReversed) {
    for (let loopingYear = currentYear; loopingYear >= baseYear; loopingYear--) {
      years.push(loopingYear);
    }
  } else {
    for (let loopingYear = baseYear; loopingYear <= currentYear; loopingYear++) {
      years.push(loopingYear);
    }
  }
  return years;
}

export function getYearDropDownFromBaseYear(baseYear = BASE_YEAR, isReversed = true, labelKey = 'label', slugKey = 'slug', yearsArray?: number[]) {
  const years = yearsArray || getYearsFromBaseYear(baseYear, isReversed);
  return years.map(year => ({
    [labelKey]: String(year),
    [slugKey]: year,
  }));
}

export function getBusinessDatesCount(startDate: Date, endDate: Date): number {
  let count = 0;
  const formattedStartDate = new Date(startDate.getTime());

  while (
    isBefore(formattedStartDate, endDate)
    || isEqual(formattedStartDate, endDate)
  ) {
    const dayOfWeek = formattedStartDate.getDay();

    if (dayOfWeek !== 0 && dayOfWeek !== 6) {
      count += 1;
    }
    formattedStartDate.setDate(formattedStartDate.getDate() + 1);
  }
  return count;
}

export function getLastWorkingDay(date: Date) {
  date.setDate(date.getDate() + 7);
  if (date.getDay() === 0) date.setDate(date.getDate() + 1);
  else if (date.getDay() === 6) date.setDate(date.getDate() + 2);
  return date;
}

export function getWeeksInfoForBusinessCalender(month: number, year: number) {
  const firstDateOfMonth = new Date(year, month, 1);
  const lastDateOfMonth = lastDayOfMonth(firstDateOfMonth);
  let lastDayOfLastWeek = lastDayOfMonth(firstDateOfMonth);

  const weekDayOfFirstDate = getDay(firstDateOfMonth);
  const weekDayOfLastDate = getDay(lastDateOfMonth);

  let firstDateOfFirstWeek = firstDateOfMonth;

  if (weekDayOfFirstDate > 3 || weekDayOfFirstDate === 0) {
    firstDateOfFirstWeek = nextMonday(firstDateOfMonth);
  } else if (weekDayOfFirstDate < 4 && weekDayOfFirstDate !== 1) {
    firstDateOfFirstWeek = previousMonday(firstDateOfMonth);
  }
  if (weekDayOfLastDate < 3) {
    lastDayOfLastWeek = previousSaturday(lastDateOfMonth);
  } else if (weekDayOfLastDate >= 3 && weekDayOfLastDate !== 6) {
    lastDayOfLastWeek = nextSaturday(lastDateOfMonth);
  }

  return {
    startWeek: firstDateOfFirstWeek,
    weeks: Math.ceil(differenceInDays(lastDayOfLastWeek, firstDateOfFirstWeek) / 7),
    weekNumber: getWeek(firstDateOfFirstWeek),
    lastDayOfLastWeek: setSeconds(setMinutes(setHours(lastDayOfLastWeek, 23), 59), 59),
  };
}

export function getWeeksInfoForBusinessCalenderForTrainings(month: number, year: number) {
  const firstDateOfMonth = new Date(year, month, 1);
  const lastDateOfMonth = lastDayOfMonth(firstDateOfMonth);

  return {
    startWeek: firstDateOfMonth,
    weeks: Math.ceil(differenceInDays(lastDateOfMonth, firstDateOfMonth) / 7),
    weekNumber: getWeek(firstDateOfMonth),
    lastDayOfLastWeek: setSeconds(setMinutes(setHours(lastDateOfMonth, 23), 59), 59),
  };
}

// Showing Routines Data after the following date. so it is hard coded
export const ROUTINES_REPORT_START_DATE = new Date(2021, 0, 1);

export const routinesMonthSelectordropDownItems = (
  isReversed = false,
): DetailedYearDropDownOption[] => {
  const dropDownOptions: DetailedYearDropDownOption[] = [];
  let routinesReportMonthDate = ROUTINES_REPORT_START_DATE;
  while (differenceInDays(startOfMonth(new Date()), routinesReportMonthDate) >= 0) {
    const weekInfo = getWeeksInfoForBusinessCalender(
      getMonth(routinesReportMonthDate),
      getYear(routinesReportMonthDate),
    );

    dropDownOptions.push({
      slug: capitalizeFirst(getTranslatedDateTimeValue(routinesReportMonthDate, 'MMM yyyy')),
      label: capitalizeFirst(getTranslatedDateTimeValue(routinesReportMonthDate, 'MMM yyyy')),
      content: weekInfo,
    });

    routinesReportMonthDate = addMonths(routinesReportMonthDate, 1);
  }
  return isReversed ? dropDownOptions.reverse() : dropDownOptions;
};

export const trainignsMonthSelectordropDownItems = (
  isReversed = false,
): DetailedYearDropDownOption[] => {
  const dropDownOptions: DetailedYearDropDownOption[] = [];
  let routinesReportMonthDate = ROUTINES_REPORT_START_DATE;
  while (differenceInDays(startOfMonth(new Date()), routinesReportMonthDate) >= 0) {
    const weekInfo = getWeeksInfoForBusinessCalenderForTrainings(
      getMonth(routinesReportMonthDate),
      getYear(routinesReportMonthDate),
    );

    dropDownOptions.push({
      slug: capitalizeFirst(getTranslatedDateTimeValue(routinesReportMonthDate, 'MMM yyyy')),
      label: capitalizeFirst(getTranslatedDateTimeValue(routinesReportMonthDate, 'MMM yyyy')),
      content: weekInfo,
    });

    routinesReportMonthDate = addMonths(routinesReportMonthDate, 1);
  }
  return isReversed ? dropDownOptions.reverse() : dropDownOptions;
};

export function generateYearOptionsAsPerABInDevCalender(startDate: Date) {
  const yearOptions: any[] = [];
  let reportYearDate = startOfYear(startDate);
  while (differenceInDays(startOfMonth(new Date()), reportYearDate) >= 0) {
    yearOptions.push({
      slug: format(reportYearDate, 'yyyy'),
      label: format(reportYearDate, 'yyyy'),
      dateRange: [
        format(startOfYear(reportYearDate), 'yyyy-MM-dd'),
        format(endOfYear(reportYearDate), 'yyyy-MM-dd'),
      ],
    });
    reportYearDate = addYears(reportYearDate, 1);
  }
  return yearOptions;
}

export function getBusinessCalenderInfo(date: Date) {
  const month = getMonth(date);
  const year = getYear(date);

  const calenderInfo = getWeeksInfoForBusinessCalender(month, year);

  if (date > calenderInfo.startWeek && date < calenderInfo.lastDayOfLastWeek) {
    return {
      month,
      year,
    };
  }
  if (date < calenderInfo.startWeek) {
    if (month === 0) {
      return {
        month: 12,
        year: year - 1,
      };
    }
    return {
      year,
      month: month - 1,
    };
  }
  if (date > calenderInfo.lastDayOfLastWeek) {
    if (month === 11) {
      return {
        month: 1,
        year: year + 1,
      };
    }
    return {
      month: month + 1,
      year,
    };
  }
  return {
    month,
    year,
  };
}

export function getMonthNameAndIndex(language: string, year: number) {
  const monthsDrop = [];
  for (let monthIndex = 0; monthIndex < 12; monthIndex++) {
    const label = language === 'en'
      ? (new Date(year, monthIndex, 1)).toLocaleString('en-US', { month: 'short' })
      : (new Date(year, monthIndex, 1)).toLocaleString('en-US', { month: 'short' });
    monthsDrop.push({
      label,
      slug: String(monthIndex),
    });
  }
  return monthsDrop;
}

export function generateYearlyRange(year: string) {
  return [
    format((new Date(year)), 'yyyy-MM-dd'),
    format((new Date((Number(year) + 1).toString())), 'yyyy-MM-dd'),
  ];
}

export function forceDateToUTC(date: Date) {
  /** e.g. converts 2020, March, 25, 01:34 +- GMT => 2020, March, 25, 01:34 UTC
   * i.e. it ignores the time zone and replaces it with UTC without any conversion to the numbers */

  return new Date(Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    0,
    0,
    0,
  ));
}

export const getDateOffset = (dateString: string, timezone: string) => {
  const date = moment(new Date(dateString));
  const offsetInMinutes = date?.tz(timezone)?.utcOffset();

  return offsetInMinutes;
};
