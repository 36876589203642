import React, { FC, useCallback, useState } from 'react';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import './DailyProductionPlanningUpload.scss';
import HeaderWithNavigation from 'components/HeaderWithNavigation';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';
import gauzeIcon from 'assets/icons/gauge-gray.svg';
import gauzeGreenIcon from 'assets/icons/gauge-green.svg';
import { ReactComponent as UploadIcon } from '../../../assets/icons/icon-upload.svg';
import { ReactComponent as CheckIcon } from '../../../assets/icons/check.svg';
import request from '../../../utils/request';

const FileUpload: FC = () => {
  const [file, setFile] = useState<any>();
  const [status, setStatus] = useState({
    rejected: false,
    uploaded: false,
    uploadingPercent: 1,
    cancelFileUpload: () => {},
    errorMessage: '',
  });

  const onDrop = useCallback(async acceptedFiles => {
    if (!acceptedFiles.length) {
      return setStatus({
        ...status,
        rejected: true,
      });
    }
    setStatus({
      ...status,
      rejected: false,
    });
    setFile(acceptedFiles[0]);

    // eslint-disable-next-line no-undef
    const data = new FormData();
    data.append('file', acceptedFiles[0]);

    try {
      const { CancelToken } = axios;
      await request.upload(
        '/api/v1/production-plannings-process',
        data,
        {},
        {
          onUploadProgress: (progressEvent: { loaded: number; total: number }) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setStatus({
              ...status,
              uploadingPercent: status.uploadingPercent + percentCompleted,
            });
          },
          cancelToken: new CancelToken((c => {
            setStatus({
              ...status,
              cancelFileUpload: c,
            });
          })),
        },
      );

      setStatus({
        ...status,
        uploaded: true,
      });
    } catch (error) {
      const errors:any = error;
      console.log('the error: ', errors, errors?.response?.data);
      setStatus({
        ...status,
        uploaded: false,
        rejected: true,
        errorMessage: errors.response.data?.message || 'Error de servidor',
      });
      if (errors.message === 'cancel file upload') {
        console.log('Request canceled', errors.message);
      }
    }
  // eslint-disable-next-line
  }, []);

  const cancelUpload = () => {
    // @ts-ignore
    status.cancelFileUpload('cancel file upload');
    setStatus({
      rejected: false,
      uploaded: false,
      uploadingPercent: 1,
      cancelFileUpload: () => {},
      errorMessage: '',
    });

    setFile(undefined);
  };

  const {
    getRootProps, getInputProps, isDragActive, isFileDialogActive,
  } = useDropzone({
    onDrop,
    accept: ['.xlsx'],
    multiple: false,
    disabled: !!file,
  });

  const { t } = useTranslation();

  const { rejected, uploaded, uploadingPercent } = status;

  const routes = [{
    listItemText: t(TRANSLATIONS.OVERVIEW),
    routeName: '/analytics/production-planning',
    listItemIcon: gauzeIcon,
    listItemIconActive: gauzeGreenIcon,
  }];

  return (
    <HeaderWithNavigation
      routes={routes}
    >
      <div className="card dragContainer p-4 m-5 daily-production-plan-upload">
        <div {...getRootProps({
          className: `${
            isDragActive || isFileDialogActive ? 'dragActive' : rejected ? 'dragRejected' : file ? 'uploading' : ''}`,
        })}
        >
          <input {...getInputProps()} />
          {!file
            ? (
              <div className="uploadBoard">
                <div>
                  <UploadIcon />
                  <p>Cargar archivo</p>
                </div>
                <p>Arrastra tu archivo a esta pantalla</p>
              </div>
            )
            : !uploaded ? (
              <div className="uploading">
                <div className="p-3">
                  <CheckIcon arial-label="excel icon" className="mr-2" />
                  <p>{file.name}</p>
                </div>
                <div>
                  <span><span><span style={{ width: `${uploadingPercent}%` }} /></span></span>
                  <button type="button" onClick={cancelUpload}>Cancelar</button>
                </div>
                {status.errorMessage && (
                  <>
                    <p>Error cargando archivo:</p>
                    <p style={{ fontWeight: 'bolder' }}>
                      { status.errorMessage }
                    </p>
                  </>
                )}
              </div>
            ) : (
              <div className="uploaded">
                <div>
                  <CheckIcon style={{ width: '4.5rem', height: '4.5rem' }} />
                  <p>Cargado éxitosamente</p>
                </div>
                {/* <button type="button" onClick={cancelUpload}>Guardar</button> */}
              </div>
            )}
        </div>
      </div>
    </HeaderWithNavigation>
  );
};

export default FileUpload;
