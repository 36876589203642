import React, { useState, useEffect, useMemo, useContext, useRef, useCallback } from 'react';
import MainNavigationHeader from 'components/MainNavigationHeader';
import req from 'utils/request';
// @ts-ignore
import ReactTable from 'react-table-legacy';
import { selectUser } from 'store/selectors/user.selectors';
import LoadingSpinner from 'components/LoadingSpinner';
import InputWithLabel from 'components/Input/InputWithLabel';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Button, Switch, Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';
import BasicButton from 'components/Button/BasicButton';
import { TRANSLATIONS } from 'types/enums';
import EmptyState from 'components/EmptyState/EmptyState';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import Modal from 'components/Modal';
import ProgressBar from 'components/ProgressBar';
import Header from 'components/Header';
import { selectLocationsToAccess } from 'store/selectors/globalFilters.selectors';
import axios from 'axios';
import Radio, { RadioProps } from '@mui/material/Radio';

import { User_Features } from 'generated/graphql';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { canUserDownloadHC, userCanAddNewUserPin, canUserDownloadAccessHC, canUserDownloadSKAPHC, userCanAddNewUserByEmployeeNumber } from '../../../../utils/shared/canUserAccessFeature';
import UserManagementContextProvider, { UserManagementContext } from './context';
import UserManagement from './UserManagement';
import './userList.scss';
import userImg from '../../../../assets/icons/user-flat.svg';
import copy from '../../../../assets/icons/copy-icon.svg';
import UserFilters from './filters';
import userSearch from '../../../../assets/icons/input-search.svg';
import UserStatusDropdown from './UserStatusDropdown';
import CreateUserModal from './CreateUserModal';
import UserRegisterSuccess from './UserRegisterSuccess';

const { CancelToken } = axios;

export const getQueryParamString = (params: any) => {
  const queryKeys = Object.keys(params);
  const string = queryKeys
    .filter(key => params[key])
    .map(key => `${key}=${params[key]}`)
    .join('&');

  return string ? `?${string}` : '';
};

const useDebounce = (value: any, timeout: any) => {
  const [state, setState] = useState(value);

  useEffect(() => {
    const collection = document.getElementsByClassName('rt-tr-group');
    for (let i = 0; i < collection.length; i++) {
      collection[i].setAttribute('tabIndex', '1');
    }
  });

  useEffect(() => {
    const handler = setTimeout(() => setState(value), timeout);

    return () => clearTimeout(handler);
  }, [value, timeout]);

  return state;
};

type UserInfoType = {
  position: string;
  location: string;
  name: string;
  pin: string;
  shortSlug: string;
  employeeNumber: string;
};

enum HCDownloadOptions {
  GeneralHCReport = 'general-hc-report',
  AccessHCReport = 'access-hc-report',
  SKAPHCReport = 'skap-hc-report',
}

const UsersIndex = () => {
  let location = useLocation();
  const navigate = useNavigate();
  const userSlug = location.search.split('=')[1];
  const routeParams: any = useParams();
  const { t, i18n } = useTranslation();
  const resetFilters = { userType: '', locationSlug: '', departmentSlug: '', areaSlug: '', subAreaSlug: '', roleType: '', roleSlug: '', limit: 20, page: 1 };
  const resetDepAreaSubareaFilters = { departmentSlug: '', areaSlug: '', subAreaSlug: '', limit: 20, page: 1 };
  const [totalPages, setTotalPages] = useState(1);
  const [filters, setFilters] = useState(resetFilters);
  const [users, setUsers] = useState([]);
  const [deletedUsers, setDeletedUsers] = useState([]);
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [emptyData, setEmpty] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState<boolean>(false);
  const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false);
  const [downloadError, setDownloadError] = useState<boolean>(false);
  const [showProgressBar, setShowProgressBar] = useState<boolean>(false);
  const [progressPercentage, setProgressPercentage] = useState<number>(0);
  const [sentViaEmail, setSentViaEmail] = useState<boolean>(false);
  const locations = useSelector(selectLocationsToAccess);
  const [showPinPopup, setShowPinPopup] = useState<boolean>(false);
  const [createdUserInfo, setCreatedUserInfo] = useState<UserInfoType>({ position: '', location: '', name: '', pin: '', shortSlug: '', employeeNumber: '' });
  const fileRef = useRef<HTMLInputElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const loggedInUser = useSelector(selectUser);
  const QrCopyText = t(TRANSLATIONS.COPY_QR_CODE);
  const [selectedDownloadOption, setSelectedDownloadOption] = useState<string>('');
  const [showDeletedUsers, setShowDeletedUsers] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState({ value: '', type: '', label: '' });
  const selectDownloadOption = (downloadOptionSlug: any) => {
    setSelectedDownloadOption(downloadOptionSlug);
  };

  const cancelSource = useRef<any>(null);
  const isRequestCancelledManually = useRef<any>(false);

  const lngIsEn = i18n.language === 'en-US' || i18n.language === 'en';
  const hcGeneralTextLabel = lngIsEn ? `${t(TRANSLATIONS.GENERAL)} HC` : `HC ${t(TRANSLATIONS.GENERAL)}`;
  const hcAccessTextLabel = `HC ${t(TRANSLATIONS.BY)} ${t(TRANSLATIONS.ACCESS)}`;
  const hcSkapTextLabel = t(TRANSLATIONS.DOWNLOAD_SKAP_HC_TITLE);
  const BlueRadio = withStyles({
    root: {
      color: '#878787',
      '&$checked': {
        color: '#6C63FF',
      },
    },
    checked: {},
  })((MUIRadioProps: RadioProps) => <Radio color="default" {...MUIRadioProps} />);

  const { email: loggedInUserEmail, hasUserManagementAccess } = loggedInUser.toJS();

  const userState = useContext(UserManagementContext);
  const updateSelectedUserDetails = (user: any) => {
    userState.setUserDetails(user);
  };

  const copyToClipboard = async (text: string) => {
    navigator.clipboard.writeText(text);
  };

  const getUserInfo = async () => {
    setIsLoading(true);
    const url = `/api/v1/users-management${getQueryParamString({
      shortSlug: search || userSlug || '',
      isDeletedUsersList: !!showDeletedUsers,
      ...filters,
    })}`;
    try {
      const res = await req.get(url);
      setIsLoading(false);
      setTotalPages(res?.userManagement?.paginationInfo?.totalPages);
      if (showDeletedUsers) {
        setDeletedUsers(res.userManagement.users);
        setUsers([]);
      } else {
        setUsers(res.userManagement.users);
      }
    } catch (error) {
      if (error?.name !== 'DEBOUNCED_REQUEST_ERROR') {
        toast.error('Error occurred while fetching users list');
      }
    } finally {
      inputRef.current?.focus();
    }
  };

  const toggleShowDeletedUsers = () => {
    setShowDeletedUsers(!showDeletedUsers);
  };

  const searchBy = `${t(TRANSLATIONS.SEARCH)} ${t(TRANSLATIONS.BY)} ${t(TRANSLATIONS.NAME)}, ${t(
    TRANSLATIONS.QR_CODE,
  )}, ${t(TRANSLATIONS.EMAIL)}, ${t(TRANSLATIONS.USER_TYPE)}, ${t(TRANSLATIONS.POSITION)}, ${t(
    TRANSLATIONS.SHARP,
  )}.`;
  const debouncedUsersReq = useDebounce(search, 1500);

  const toCamelCase = (str: string) => (str
    ? str
      .toLowerCase()
      .replace(/(^\w|\s\w)(\S*)/g, (_ignore, m1, m2) => m1.toUpperCase() + m2.toLowerCase())
    : '');

  useEffect(() => {
    getUserInfo();
    // eslint-disable-next-line
  }, [showDeletedUsers]);

  useEffect(() => {
    if (showDeletedUsers && search.length < 6) {
      return;
    }
    getUserInfo();
    setIsLoading(true);
    // eslint-disable-next-line
  }, [debouncedUsersReq, filters.userType, filters.roleType, filters.locationSlug, filters.departmentSlug, filters.areaSlug, filters.subAreaSlug, filters.page, filters.roleSlug]);

  useEffect(() => {
    if (users?.length === 0) {
      return setEmpty(true);
    }
    setEmpty(false);
  }, [users]);

  const handleSearch = async (event: any) => {
    setSearch(event.target.value);
    setUsers([]);
    setDeletedUsers([]);
  };

  useEffect(() => {
    if (showDeletedUsers) {
      if (search.length >= 6) setUsers(deletedUsers);
      else setUsers([]);
    }
    // eslint-disable-next-line
  }, [showDeletedUsers, deletedUsers, search]);

  const columns = useMemo(() => {
    return [
      {
        Header: () => {
          return <img alt="user" width={30} height={30} src={userImg} />;
        },
        accessor: 'name',
      },
      {
        Header: t(TRANSLATIONS.JOB_TITLE),
        accessor: 'position',
        Cell: (v: any) => {
          return v.value ? toCamelCase(String(v.value)) : 'N/A';
        },
      },
      {
        Header: t(TRANSLATIONS.EMAIL),
        accessor: 'email',
        Cell: (v: any) => {
          return String(v.value ?? 'N/A');
        },
      },
      {
        Header: t(TRANSLATIONS.SAP_SHARP),
        accessor: 'employeeNumber',
        Cell: (v: any) => {
          if (v?.original?.distributionCenter?.bu === 'Mexico') {
            return v?.value ? `${v.value}` : 'N/A';
          }
          return String(v.value ?? 'N/A');
        },
      },
      {
        Header: t(TRANSLATIONS.QR_CODE),
        accessor: 'shortSlug',
        Cell: (v: any) => {
          return <div style={{ display: 'flex' }}><b>{String(v.value ?? 'N/A')}</b><Tooltip title={QrCopyText} placement="right-start"><img alt="copy" style={{ cursor: 'pointer', marginLeft: 5 }} width={25} height={25} src={copy} onClick={() => copyToClipboard(v.value || 'N/A')} /></Tooltip></div>;
        },
      },
      {
        Header: `${t(TRANSLATIONS.USER_TYPE)}`,
        accessor: 'type',
        Cell: (v: any) => {
          return String((v?.original?.roleId ? v?.original?.userRole?.name : v.value) ?? 'N/A');
        },
      },
      {
        id: 'configuration',
        Header: t(TRANSLATIONS.STATUS),
        accessor: 'isBlocked',
        width: 130,
        Cell: (v: any) => (
          <UserStatusDropdown
            user={v.original}
            users={users}
            setUsers={setUsers}
          />
        ),
      },
    ] as any;
    // eslint-disable-next-line
  }, [t, users]);
  const onRowClick = (state: any, rowInfo: any, column: any) => {
    return {
      onClick: () => {
        if (column?.id !== 'configuration' && column?.id !== 'shortSlug') {
          updateSelectedUserDetails({
            ...(rowInfo?.original as any),
          });
          if (rowInfo?.original?.id) {
            userState.setUMDrawerVisibility(!userState.isUMDrawerVisible);
          }
        }
      },
    };
  };
  const handleUploadButton = () => {
    if (fileRef.current !== null) {
      fileRef.current.click();
    }
  };
  const handleAttachmentsUpload = async (e: any) => {
    const { files } = e.target;
    if (files?.length) {
      const config = {
        headers: { 'content-type': 'multipart/form-data' },
      };
      const data = new FormData();

      data.set('file', files[0]);

      try {
        await req.post('/api/v1/upload-user-positions', data, config);
        toast.success('Uploaded Successfully!');
      } catch (err) {
        toast.error('Error while uploading');
      } finally {
        if (fileRef.current !== null) {
          fileRef.current.value = '';
        }
      }
    }
  };

  const handleDownloadHC = () => {
    setShowDownloadModal(true);
  };

  const handleCloseModal = () => {
    setSentViaEmail(false);
    setDownloadError(false);
    setShowDownloadModal(false);
    setShowProgressBar(false);
    setProgressPercentage(0);
    setSelectedDownloadOption('');
    if (cancelSource?.current) {
      isRequestCancelledManually.current = true;
      cancelSource.current.cancel();
    }
  };

  const handleAddUser = () => {
    setShowAddUserModal(true);
  };

  const handleCloseAddUserModal = () => {
    setSelectedLocation({ value: '', type: '', label: '' });
    setShowAddUserModal(false);
  };

  const handleShowPinPopup = (userInfo: UserInfoType) => {
    setCreatedUserInfo(userInfo);
    setShowAddUserModal(false);
    setShowPinPopup(true);
    setSelectedLocation({ value: '', type: '', label: '' });
  };

  const handleCloseShowPinPopup = () => {
    getUserInfo();
    setShowPinPopup(false);
  };

  const downloadHCExcel = useCallback(
    async () => {
      cancelSource.current = CancelToken.source();
      try {
        setSentViaEmail(false);
        setDownloadError(false);
        setShowDownloadModal(true);
        setShowProgressBar(true);
        const url = `/api/v1/users/download-hc-excel?locationIds=${JSON.stringify(locations)}&type=${selectedDownloadOption}`;
        const res = await axios({
          url,
          method: 'GET',
          onDownloadProgress: (progressEvent: any) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setProgressPercentage(percentCompleted);
          },
          cancelToken: cancelSource.current.token,
        });
        if (res.data?.sentViaEmail) {
          return setSentViaEmail(true);
        }
        const downloadUrl = res.data;
        const a = document.createElement('a');
        a.href = downloadUrl;
        setSelectedDownloadOption('');
        setShowDownloadModal(false);
        setShowProgressBar(false);
        setProgressPercentage(0);
        a.click();
      } catch (error) {
        if (!isRequestCancelledManually.current) {
          setDownloadError(true);
          setProgressPercentage(0);
          isRequestCancelledManually.current = false;
        }
      }
    }, [locations, selectedDownloadOption],
  );

  const hcDownloadOptions = [{
    slug: HCDownloadOptions.GeneralHCReport,
    label: hcGeneralTextLabel,
    permission: User_Features.DownloadGeneralHc,
    description: t(TRANSLATIONS.LIST_OF_THE_USERS_THAT_ARE_ASSIGNED_TO_THE_SELECTED_LOCATION),
  },
  {
    slug: HCDownloadOptions.AccessHCReport,
    label: hcAccessTextLabel,
    permission: User_Features.DownloadHc,
    description: t(
      // eslint-disable-next-line max-len
      TRANSLATIONS.LIST_OF_THE_USERS_THAT_HAVE_ACCESS_TO_THE_SELECTED_LOCATION_USERS_MAY_APPEAR_MORE_THAN_ONCE_IN_DIFFERENT_LOCATIONS,
    ),
  },
  {
    slug: HCDownloadOptions.SKAPHCReport,
    label: hcSkapTextLabel,
    permission: User_Features.DownloadSkapHc,
    description: t(
      TRANSLATIONS.DOWNLOAD_SKAP_HC_DESCRIPTION,
    ),
  },
  ];

  const availableDownloadOptionsByUserData = (
    hasUserManagementAccess
      ? hcDownloadOptions
      : hcDownloadOptions.filter(
        downloadOption => loggedInUser?.get('features')?.some(
          (feature: any) => feature.get('slug') === downloadOption.permission,
        ),
      )
  );

  const isReactiveUsers = () => {
    const reactiveFeature = loggedInUser?.toJS()?.features.filter((feature:any) => feature?.slug === 'reactive_users');
    return reactiveFeature.length;
  };

  useEffect(() => {
    if (availableDownloadOptionsByUserData.length === 1) {
      selectDownloadOption(availableDownloadOptionsByUserData[0].slug);
    }
  }, [availableDownloadOptionsByUserData]);

  const showEmptyState = useCallback(() => {
    if (showDeletedUsers) {
      return (
        <EmptyState
          label={t(TRANSLATIONS.SEARCH_USERS)}
          description={t(TRANSLATIONS.FIND_USERS_DESCRIPTION)}
        />
      );
    }
    return (
      <EmptyState />
    );
  }, [t, showDeletedUsers]);

  return (
    <>
      <MainNavigationHeader
        userName=""
        // @ts-ignore
        goBack={routeParams?.distCenter ? navigate(-1) : null}
        nutIconOnly
      />
      <div className=" user-list-container">
        <>
          <div className="user-search-container">
            <InputWithLabel
              inputRef={inputRef}
              inputImg={userSearch}
              onChange={handleSearch}
              placeholder={showDeletedUsers ? `${t(TRANSLATIONS.SEARCH_FOR_DELETED_USERS_BY_SHARP_EMAIL_QR_OR_PIN)}` : searchBy}
              value={search}
              label={t(TRANSLATIONS.SEARCH_USERS)}
              labelClassName="search-header"
              inputClassName="search-input"
            // disabled={isLoading}
            />
            {(hasUserManagementAccess || canUserDownloadHC(loggedInUser?.get('features')) || canUserDownloadAccessHC(loggedInUser?.get('features')) || canUserDownloadSKAPHC(loggedInUser?.get('features'))) && (
              <div className="download_hc_container">
                <BasicButton
                  text={t(TRANSLATIONS.DOWNLOAD_HC)}
                  className="download_hc_button_filled"
                  onClick={handleDownloadHC}
                />
              </div>
            )}
            {(hasUserManagementAccess || userCanAddNewUserPin(loggedInUser?.get('features')) || userCanAddNewUserByEmployeeNumber(loggedInUser?.get('features'))) && (
              <div className="add_user_container">
                <BasicButton
                  text={t(TRANSLATIONS.ADD_NEW_USER)}
                  onClick={handleAddUser}
                  className="add_user_button_filled"
                />
              </div>
            )}
            <div className={loggedInUserEmail.includes('arvolution') ? 'upload_button_container' : 'hidden'}>
              <BasicButton
                text={t(TRANSLATIONS.UPLOAD_TEMPLATE)}
                className="upload_button_filled"
                onClick={handleUploadButton}
              />
              <input
                ref={fileRef}
                className="hidden"
                type="file"
                onChange={handleAttachmentsUpload}
                name="file"
                multiple
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              />
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div style={{ visibility: showDeletedUsers ? 'hidden' : 'visible' }}>
              <UserFilters {...{
                setFilters,
                filters,
                resetFilters,
                setSearch,
                isLoading,
                resetDepAreaSubareaFilters,
                showDeletedUsers,
              }}
              />
            </div>
            {(hasUserManagementAccess || !!isReactiveUsers()) ? (
              <div style={{ marginTop: 'auto' }}>
                {t(TRANSLATIONS.SHOW_DELETED_USERS)}
                <Switch
                  checked={showDeletedUsers}
                  onChange={toggleShowDeletedUsers}
                  style={{ color: '#F0F0F0' }}
                  color={showDeletedUsers ? 'primary' : 'default'}
                  disabled={isLoading}
                />
              </div>
            ) : null}
          </div>
          <div
            style={{
              padding: '0px 0',
              margin: '15px 0',
              position: 'relative',
              display: isLoading && users?.length === 0 && deletedUsers.length === 0 ? 'flex' : '',
            }}
            className="user-table"
          >
            {isLoading ? (
              <LoadingSpinner />
            ) : users?.length > 0 || deletedUsers.length > 0 ? (
              <>
                <ReactTable
                  minRows={0}
                  data={users}
                  columns={columns}
                  showPagination={false}
                  className="-striped max-height"
                  NoDataComponent={showEmptyState}
                  getTdProps={onRowClick}
                />
                {((showDeletedUsers && search.length > 5) || !showDeletedUsers) && (
                <div className="table-footer">
                  <Button
                    variant="contained"
                    onClick={() => {
                      setFilters(f => ({ ...f, page: f.page - 1 }));
                    }}
                    disabled={filters.page < 2}
                  >{t(TRANSLATIONS.PREVIOUS)}
                  </Button>
                  <div className="page">
                    <input
                      type="number"
                      defaultValue={filters.page}
                      onChange={e => {
                        const debounce = _.debounce(() => {
                          if (e.target.value
                            && Number(e.target.value) > 0
                            && !(Number(e.target.value) > totalPages)) {
                            setFilters(f => ({ ...f, page: Number(e.target.value) }));
                          }
                        }, 1500);
                        debounce();
                      }}
                    />
                    <div>/ {t(TRANSLATIONS.TOTAL_PAGES)}: <span>{totalPages}</span></div>
                  </div>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setFilters(f => ({ ...f, page: f.page + 1 }));
                    }}
                    disabled={filters.page === totalPages}
                  >{t(TRANSLATIONS.NEXT)}
                  </Button>
                </div>
                )}
              </>
            ) : showDeletedUsers ? (
              <EmptyState
                label={t(TRANSLATIONS.SEARCH_USERS)}
                description={t(TRANSLATIONS.FIND_USERS_DESCRIPTION)}
              />
            ) : (
              <EmptyState />
            )}
          </div>
        </>
      </div>
      {userState?.isUMDrawerVisible
        && (
        <UserManagement
          getUserInfo={getUserInfo}
          showDeletedUsers={showDeletedUsers}
          isReactiveUsers={isReactiveUsers}
        />
        )}
      {/* {showPinModal && (
      <AddUserModal
        closeModal={() => {
          setShowPinModal(false);
          setSelectedLocation({ value: '', type: '', label: '' });
        }}
        onAdd={(userInfo: any) => handleShowPinPopup(userInfo)}
        selectedLocation={selectedLocation}
      />
      )} */}
      {showAddUserModal && (
        <CreateUserModal
          closeModal={handleCloseAddUserModal}
          onAdd={(userInfo: any) => handleShowPinPopup(userInfo)}
          selectedLocation={selectedLocation}
          setSelectedLocation={setSelectedLocation}
        />
      )}
      {showPinPopup && (
        <UserRegisterSuccess
          userInfo={createdUserInfo}
          close={handleCloseShowPinPopup}
          selectedLocation={selectedLocation}
        />
      )}
      {showDownloadModal && (
        <Modal
          modalContainerStyles={{ width: '70vw',
            left: '20%',
            maxHeight: '90vh',
            minHeight: '20vh',
            height: 'fit-content',
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)' }}
        >
          <div className="downloadMoal">
            <p className="downloadMoal__title">{downloadError ? `${t(TRANSLATIONS.SOMETHING_WENT_WRONG)}...` : t(TRANSLATIONS.DOWNLOAD_DATA)}</p>
            {showProgressBar ? (
              <>
                <p className="downloadMoal__downloading_title">{downloadError ? t(TRANSLATIONS.DOWNLOAD_FAIL_RETRY_THE_DOWNLOAD_PLEASE) : sentViaEmail ? t(TRANSLATIONS.THE_DATA_IS_TAKING_A_LONG_TIME_TO_DOWNLOAD_A_DOWNLOAD_LINK_WILL_BE_SENT_TO_YOUR_EMAIL_TO_CONTINUE) : t(TRANSLATIONS.DOWNLOADING_DATA)}</p>
                {!downloadError && !sentViaEmail && (
                  <div className="downloadMoal__progress_bar">
                    <p className="downloadMoal__progress_bar__title">{progressPercentage}%</p>
                    <ProgressBar bgcolor="#4472C4" height={16} progress={progressPercentage} />
                  </div>
                )}
              </>
            ) : (
              <>
                <p className="downloadMoal__description">{t(TRANSLATIONS.THE_DATA_FOR_THE_LOCATIONS_THAT_ARE_FILTERED_IN_THE_TOP_FILTER_BAR_WILL_BE_DOWNLOADED)}</p>
                <div className="downloadMoal__header">
                  <Header showLogo={false} showSettings={false} />
                  <div className="hc__downloadMoal__select_container">
                    <p className="hc__downloadMoal__select_container__title">{t(TRANSLATIONS.SELECT_DOCUMENT)}</p>
                    <p className="hc__downloadMoal__select_container__description">{t(TRANSLATIONS.SELECT_THE_DOCUMENT_YOU_WANT_TO_DOWNLOAD)}</p>
                    {availableDownloadOptionsByUserData.map(downloadOption => {
                      return (
                        <>
                          <div key={downloadOption.slug} onChange={() => selectDownloadOption(downloadOption.slug)} className="hc__downloadMoal__select_container__radio_container">
                            <BlueRadio
                              checked={
                                selectedDownloadOption === downloadOption.slug
                              }
                              value="c"
                              name="radio-button-demo"
                              inputProps={{ 'aria-label': 'C' }}
                            />
                            <div className="hc__downloadMoal__select_container__radio_container__text_container">
                              <p className="hc__downloadMoal__select_container__radio_container__text_container__radio_title">{downloadOption.label}</p>
                              <p className="hc__downloadMoal__select_container__radio_container__text_container__radio_description">{downloadOption.description}</p>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </>
            )}
            <div className="downloadMoal__button_container">
              {!sentViaEmail && (
                downloadError ? (
                  <BasicButton text={t(TRANSLATIONS.RETRY_AGAIN)} className="downloadMoal__button_container__download_button" onClick={downloadHCExcel} />
                ) : (
                  <BasicButton disabled={(selectedDownloadOption === '' && availableDownloadOptionsByUserData.length > 1) || showProgressBar} text={t(TRANSLATIONS.DOWNLOAD)} className="downloadMoal__button_container__download_button" onClick={downloadHCExcel} />
                )
              )}
              <BasicButton text={t(TRANSLATIONS.CANCEL)} className="downloadMoal__button_container__cancel_button" onClick={handleCloseModal} />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

const UsersIndexN = (props: any) => {
  return (
    <UserManagementContextProvider>
      <UsersIndex {...{ ...props }} />
    </UserManagementContextProvider>
  );
};

export default UsersIndexN;
