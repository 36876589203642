import React, { Component } from 'react';
import SelectDropdown from 'components/Dropdown/SelectDropdown';
import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/en-gb';
import i18next from 'i18next';

type Props = {
  onChange: any;
  currentValue: string;
  t?: any;
  bgColor?: string;
};

const dynamicMonths = [
  {
    value: 'currentMonth',
    label: moment().locale('es').format('MMMM YYYY'),
    englishLabel: moment().locale('en-b').format('MMMM YYYY'),
    startTime: moment().clone().startOf('month').toLocaleString(),
    endTime: moment().clone().endOf('month').toLocaleString(),
  },
];

// show last 12 months as options to select
for (let index = 1; index < 12; index++) {
  const month = moment().subtract(index, 'months');
  dynamicMonths.push({
    value: `currentMonthMinus${index}`,
    label: month.locale('es').format('MMMM YYYY'),
    englishLabel: month.locale('en-gb').format('MMMM YYYY'),
    startTime: month.startOf('month').toLocaleString(),
    endTime: month.endOf('month').toLocaleString(),
  });
}

// show last 2 years as options to select
const years = [
  {
    value: moment().format('YYYY'),
    label: moment().locale('es').format('YYYY'),
    englishLabel: moment().locale('es').format('YYYY'),
    startTime: moment().clone().startOf('year').toLocaleString(),
    endTime: moment().clone().endOf('year').toLocaleString(),
  },
  {
    value: moment().subtract(1, 'years').format('YYYY'),
    label: moment().locale('es').subtract(1, 'years').format('YYYY'),
    englishLabel: moment().locale('en-gb').subtract(1, 'years').format('YYYY'),
    startTime: moment().subtract(1, 'years').startOf('year').toLocaleString(),
    endTime: moment().subtract(1, 'years').endOf('year').toLocaleString(),
  },
];

export const MonthlyOptions = [...dynamicMonths, ...years];
export const OnlyMonthlyOptions = [...dynamicMonths];

export default class MonthlyTimeFilter extends Component<Props> {
  render() {
    const { onChange, currentValue, bgColor } = this.props;
    const lang = i18next.language;

    const dropdownContent = MonthlyOptions.map(({ value, label, englishLabel }) => {
      const localeLabel = lang.includes('es') ? label : englishLabel;
      return (
        { value, label: localeLabel }
      );
    });

    return (
      <SelectDropdown
        name="Month"
        bgColor={bgColor}
        value={currentValue}
        onChange={(e: any) => onChange(e.target.value)}
        dropdownContent={dropdownContent}
      />
    );
  }
}
