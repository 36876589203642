import React, { FC } from 'react';
import ReportBoard from './ReportBoard';

const Consequences: FC<any> = () => {
  return (
    <>
      <ReportBoard />
    </>
  );
};

export default Consequences;
