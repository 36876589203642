import { NameAndSlug, BusinessUnitJSON } from '../../types/interfaces';
import request from '../../utils/request';

export const SET_BUSINESS_UNIT = 'globalFilters/set-business-unit';
export const SET_ZONE = 'globalFilters/set-zone';
export const SET_ALL_ZONES = 'globalFilters/set-all-zones';
export const SET_SUBZONE = 'globalFilters/set-subzone';
export const SET_ALL_SUBZONES = 'globalFilters/set-all-subzones';
export const SET_LOCATION = 'globalFilters/set-location';
export const SET_ALL_LOCATIONS = 'globalFilters/set-all-locations';
export const SET_ANALYTICS_FILTERS = 'globalFilters/set-analytics-filters';
export const SET_LOCATIONS = 'globalFilters/set-locations';
export const SET_CLUSTER = 'globalFilters/set-cluster';
export const SET_ALL_CLUSTERS = 'globalFilters/set-all-clusters';

export function setBusinessUnit(businessUnit: NameAndSlug) {
  return { type: SET_BUSINESS_UNIT, businessUnit };
}

export function setZone(zone: NameAndSlug) {
  return { type: SET_ZONE, zone };
}

export function setAllZones(zones: NameAndSlug[]) {
  return { type: SET_ALL_ZONES, zones };
}

export function setSubzone(subzone: NameAndSlug) {
  return { type: SET_SUBZONE, subzone };
}

export function setAllSubzones(subzones: NameAndSlug[]) {
  return { type: SET_ALL_SUBZONES, subzones };
}

export function setLocation(location: NameAndSlug) {
  return { type: SET_LOCATION, location };
}

export function setAllLocations(locations: NameAndSlug[]) {
  return { type: SET_ALL_LOCATIONS, locations };
}

export function setAnalyticsFilters(analyticsFilters: BusinessUnitJSON[]) {
  return { type: SET_ANALYTICS_FILTERS, analyticsFilters };
}

export function setLocations(locations: number[]) {
  return { type: SET_LOCATIONS, locations };
}

export function setCluster(cluster: NameAndSlug) {
  return { type: SET_CLUSTER, cluster };
}

export function setAllClusters(clusters: NameAndSlug[]) {
  return { type: SET_ALL_CLUSTERS, clusters };
}

export function getAnalyticsFilters() {
  return async (dispatch: Function, getState: Function) => {
    try {
      const userSlug = getState().getIn(['user', 'slug']);
      if (userSlug) {
        const analyticsFilters = await request.get(`/api/v1/users/${encodeURIComponent(userSlug)}/analytics-filters`);
        dispatch(setAnalyticsFilters(analyticsFilters));
        return;
      }
      throw new Error('user log not found');
    } catch (e) {
      console.log(e);
    }
  };
}

export function updateAnalyticsLocations() {
  return async (dispatch: Function, getState: Function) => {
    try {
      const filters = getState().getIn(['globalFilters']);
      const filter = {
        locationSlug: filters.getIn(['location', 'slug']) || '',
        clusterSlug: filters.getIn(['cluster', 'slug']) || '',
        subzoneSlug: filters.getIn(['subzone', 'slug']) || '',
        zoneSlug: filters.getIn(['zone', 'slug']) || '',
        businessSlug: filters.getIn(['businessUnit', 'slug']) || '',
      };
      const locations = await request.post('/api/v1/users/location-access', filter);
      dispatch(setLocations(locations));
    } catch (e) {
      console.log(e);
    }
  };
}
