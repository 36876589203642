import React, { FC, useState } from 'react';
import 'react-day-picker/lib/style.css';
import './SupplyRoutineMobile.scss';
import AddRoutine from '../AddSupplyRoutine/AddRoutine';
import VerifyRoutine from '../AddSupplyRoutine/VerifyRoutine';

type Props = {

};

const AddSupplyRoutine: FC<Props> = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <div className="supply-routine-mobile">
      <div className="tab-container">
        <div
          className={`tab ${selectedTab === 0 ? 'selected-tab' : ''}`}
          role="button"
          onClick={() => setSelectedTab(0)}
        >
          <p>Nueva rutina</p>
        </div>
        <div
          className={`tab ${selectedTab === 1 ? 'selected-tab' : ''}`}
          role="button"
          onClick={() => setSelectedTab(1)}
        >
          <p>Rutinas pendientes</p>
        </div>
      </div>
      { selectedTab === 0 ? (
        <AddRoutine />
      ) : (
        <VerifyRoutine />
      )}
    </div>
  );
};

export default AddSupplyRoutine;
