import React, { ChangeEvent, Component } from 'react';
import './FormDetails.scss';
import Toggle from 'components/Button/Toggle';
import { COLOR_CODE,
  COLOR_CODE_NEW,
  LOCATION_TYPES,
  RISK_LEVEL,
  SECURITY_STATUS,
  SIF_TYPES,
  TICKET_TYPE,
  TRANSLATIONS,
  YES_NO_OPTIONS,
  PRIORITY } from 'types/enums';
import { fromJS } from 'immutable';
import {
  DistributionCenterDetails,
  DistributionCenterDetailsMap,
  RiskGroup,
  UserMap,
} from 'types/interfaces';
import { NameAndSlugRecord, WorkLocationRecord } from 'types/types';
import Autosuggest from 'react-autosuggest';
import { userNameExtractor } from 'utils/stringHelpers';
import ImageLinks from 'utils/ImageLinks';
import request from 'utils/request';
import LoadingSpinner from 'components/LoadingSpinner';
import 'react-day-picker/lib/style.css';
import 'moment/locale/es';
import FileUpload from 'containers/FileUpload';
import LabelledInputWithTick from 'components/Input/LabelledInputWithTick';
import TimePicker
  from 'features/external-users/AnonymousComplaint/FormDetails/containers/TimePicker';
import searchHandlerAPI from 'features/action-logs/SummaryModal/SummaryModalComponent/ResponsiblesAvatarWithTooltip/searchHandlerAPI';
import { connect } from 'react-redux';

import { Supply_User_Roles } from 'generated/graphql';
import i18next from 'i18next';
import AnonymousFormDropdownWithLabel from '../../containers/AnonymousFormDropdownWithLabel';
import AnonymousDropDown from '../../containers/AnonymousDropDown';

export const otherRiskType = {
  name: 'Otro',
  slug: 'other-risk-types',
};

export const initializedRecords = fromJS({
  name: '',
  nameEN: '',
  slug: '',
  specificSubLocations: [],
});

const activeTabStyle = {
  background: COLOR_CODE_NEW.COMPLEMENTARY,
  borderColor: COLOR_CODE_NEW.COMPLEMENTARY_LIGHT,
};
const inActiveTabStyle = { background: COLOR_CODE_NEW.WHITE, borderColor: undefined };
class FormDetails extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      userNameSuggestions: [],
      userNames: [],
      isUserKnown: true,
      isUserKnownReportedBy: true,
      riskGroups: [],
      riskTypes: [],
      riskLevel: null,
    };
    this.fetchUsers = this.fetchUsers.bind(this);
    this.getUserSuggestions = this.getUserSuggestions.bind(this);
    this.handleSuggestionsFetchRequested = this.handleSuggestionsFetchRequested.bind(this);
    this.clearSuggestions = this.clearSuggestions.bind(this);
    this.handleToggleUserKnown = this.handleToggleUserKnown.bind(this);
    this.handleToggleUserKnownReportedBy = this.handleToggleUserKnownReportedBy.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.handleRiskGroupChange = this.handleRiskGroupChange.bind(this);
    this.handleRiskTypeChange = this.handleRiskTypeChange.bind(this);
    this.handleRiskLevelChange = this.handleRiskLevelChange.bind(this);
    this.validateFields = this.validateFields.bind(this);
    this.handleSecurityStatusChange = this.handleSecurityStatusChange.bind(this);
    this.handleComplaintTypeChange = this.handleComplaintTypeChange.bind(this);
    this.handlepriorityStatusEnvRiskChange = this.handlepriorityStatusEnvRiskChange.bind(this);
    this.handleCategory = this.handleCategory.bind(this);
  }

  componentDidMount(): void {
    this.validateFields();
    this.fetchUsers();
    this.fetchDistCenterInfo();

    window.scrollTo({ top: 0 });
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
    if (this.props.distCenterSlug !== prevProps.distCenterSlug) {
      this.fetchUsers();
      this.fetchDistCenterInfo();
    }

    if (
      this.props.selectedComplaintType !== prevProps.selectedComplaintType
      || this.props.selectedSecurityStatus !== prevProps.selectedSecurityStatus
      || this.props.selectedUserName !== prevProps.selectedUserName
      || this.props.selectedReportedByUserName !== prevProps.selectedReportedByUserName
      || this.props.selectedUserSlug !== prevProps.selectedUserSlug
      || this.props.selectedReportedByUserSlug !== prevProps.selectedReportedByUserSlug
      || this.props.issueDescription !== prevProps.issueDescription
      || this.props.selectedLocation !== prevProps.selectedLocation
      || this.props.locationDescription !== prevProps.locationDescription
      || this.props.selectedSubLocation !== prevProps.selectedSubLocation
      || this.props.selectedSubArea !== prevProps.selectedSubArea
      || this.props.selectedRiskGroup !== prevProps.selectedRiskGroup
      || this.props.selectedRiskType !== prevProps.selectedRiskType
      || this.props.selectedRiskLevel !== prevProps.selectedRiskLevel
      || this.props.correctiveAction !== prevProps.correctiveAction
      || this.props.file !== prevProps.file
      || this.state.isUserKnown !== prevState.isUserKnown
      || this.state.isUserKnownReportedBy !== prevState.isUserKnownReportedBy
      || this.props.isUserNotListed !== prevProps.isUserNotListed
      || this.props.isReportedByUserNotListed !== prevProps.isReportedByUserNotListed
      || this.props.isSIF !== prevProps.isSIF
      || this.props.SIFType !== prevProps.SIFType
      || this.props.selectedImmediateClosure !== prevProps.selectedImmediateClosure
      || this.props.selectedPriority !== prevProps.selectedPriority
      || this.props.sapNumber !== prevProps.sapNumber
      || this.props.category !== prevProps.category
    ) {
      this.validateFields();
    }
  }

  async getUserSuggestions(value: string) {
    const { distCenterSlug } = this.props;
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    const apiTarget = '/api/v1/users-list';
    const structuredResult: any = [];
    if (inputLength !== 0 && (!/[-!@#$%^&*()_+|~=`{}[\]:";<>?,./·¿¢∞¬÷“”≠‚¢ºª]/.test(inputValue))) {
      try {
        const result: any = await searchHandlerAPI(
          apiTarget, {
            search: inputValue,
            locationSlug: (distCenterSlug as string),
          },
        );
        result.users.forEach((user: UserMap) => structuredResult.push(fromJS(user)));
        this.setState({
          userNameSuggestions: structuredResult,
          userNames: structuredResult,
        });
        return structuredResult;
      } catch (e) {
        console.log('the errror: ', e);
      }
    } else {
      this.setState({ userNameSuggestions: [] });
      return [];
    }
  }

  // validateSAPInputField = (sapNumber: string):boolean => /^\d{8}$/.test(sapNumber.trim());

  // successComponentSAP = (t: any) => (
  //   <div className="ml-2 form-details row">
  //     <p className="form-details success-text">
  //       {t(TRANSLATIONS.SAP_SUCCESSFULLY_VALIDATED)}
  //     </p>
  //     <img src={checkIcon} alt="check-icon" className="form-details check-icon-image" />
  //   </div>
  // );

  async fetchRiskTypes() {
    const { selectedComplaintType, selectedSecurityStatus, t, isFactory } = this.props;
    const lng = ['en-US', 'en'].includes(i18next.language) ? 'en' : 'es';
    const response: RiskGroup[] = await request.get('/api/v2/risk-types/list', {
      typeOfIssue: selectedComplaintType,
      isUnsafe: selectedSecurityStatus === t(SECURITY_STATUS.INSECURE),
      lng,
    });
    const filteredResponse = response?.filter((res: RiskGroup) => {
      return res.locationType === (isFactory
        ? LOCATION_TYPES.FACTORY
        : LOCATION_TYPES.DISTRIBUTION_CENTER);
    });

    this.setState({
      riskGroups: fromJS(filteredResponse),
    });
  }

  async fetchUsers() {
    const { distCenterSlug, isFactory } = this.props;
    let distCenterFactory;
    if (!distCenterSlug) return;
    const response = await request.get(`/api/v3/${distCenterSlug}/dist-center-details`) as DistributionCenterDetails;
    // const response = mockDistributionCenterDetails(10000);

    // add "other" risk type
    const distCenter = {
      ...response.distCenterDetails,
      riskTypes: [
        ...(response.risks || []),
        otherRiskType,
      ],
    };
    if (isFactory) {
      distCenterFactory = {
        name: response.distCenterDetails!.name,
        riskTypes: [
          response.risks,
          otherRiskType,
        ],
        slug: response.distCenterDetails!.slug,
        workLocations: response.distCenterDetails!.workLocations,
      };
    }

    this.setState({
      distCenterDetails: fromJS(isFactory ? distCenterFactory : distCenter),
      // riskGroups: fromJS(response.risks),
    });
  }

  async fetchDistCenterInfo() {
    const { distCenterSlug } = this.props;
    if (!distCenterSlug) return;
    const response = await request.get(`/api/v1/${distCenterSlug}/dist-center`) as DistributionCenterDetails;

    const enabledAnonymous = !!response?.distCenterDetails?.hasAnonymousEnabled;
    this.props.handleHasAnonymousReport(enabledAnonymous);

    this.setState({
      distCenterinfo: fromJS(response),
    });
  }

  handleSuggestionsFetchRequested({ value }: any) {
    this.setState({
      userNameSuggestions: this.getUserSuggestions(value),
    });
  }

  clearSuggestions() {
    this.setState(prevState => ({ ...prevState, userNameSuggestions: prevState.userNames }));
  }

  handleToggleUserKnown() {
    this.setState(state => ({ isUserKnown: !state.isUserKnown }));
    this.props.handleSuggestionSelected(null, { suggestion: null, suggestionValue: null });
  }

  handleToggleUserKnownReportedBy() {
    this.setState(state => ({ isUserKnownReportedBy: !state.isUserKnownReportedBy }));
    this.props.handleSuggestionSelectedReportedBy(null, {
      suggestion: null,
      suggestionValue: null,
    });
  }

  handleLocationChange(e: ChangeEvent<any>) {
    const { distCenterDetails } = this.state;

    const locationSlug = e.target.value;

    const chosenLocation = distCenterDetails?.get('workLocations')
      ?.find((location: WorkLocationRecord) => location.get('slug') === locationSlug);

    this.props.handleLocationChange(chosenLocation);
    this.props.handleSubLocationChange(null);
  }

  handleCategory(e: ChangeEvent<any>) {
    this.props.handleCategory(e.target.value);
  }

  handleSubLocationChange(e: ChangeEvent<any>) {
    const subLocationSlug = e.target.value;

    const chosenSubLocation = this.props.selectedLocation?.get('subLocations')
      .find((subLocation: any) => subLocation.get('slug') === subLocationSlug);
    this.props.handleSubLocationChange(chosenSubLocation);
    this.props.handleSubAreaChange(null);
  }

  handleSubAreaChange(e: ChangeEvent<any>) {
    const subAreaSlug = e.target.value;

    const chosenSubArea = this.props.selectedSubLocation?.get('specificSubLocations')
      .find((subArea: any) => subArea.get('slug') === subAreaSlug);
    this.props.handleSubAreaChange(chosenSubArea);
  }

  handleRiskTypeChange(e: ChangeEvent<any>) {
    const { riskGroups } = this.state;
    const { selectedRiskGroup } = this.props;

    const chosenRiskTypeSlug = e.target.value;

    const chosenRiskType = riskGroups
      ?.find((riskGroup: NameAndSlugRecord) => riskGroup.get('slug') === selectedRiskGroup.get('slug'))
      ?.get('riskTypes')
      ?.find((riskType: NameAndSlugRecord) => riskType.get('slug') === chosenRiskTypeSlug);

    this.props.handleRiskTypeChange(chosenRiskType);
    this.props.handleIsSIFChange(chosenRiskType?.get('isSIF') ? YES_NO_OPTIONS.YES : YES_NO_OPTIONS.NO);
  }

  handleRiskGroupChange(e: ChangeEvent<any>) {
    const { riskGroups } = this.state;

    const chosenRiskGroupSlug = e.target.value;

    const chosenRiskGroup = riskGroups?.find((riskGroup: NameAndSlugRecord) => riskGroup.get('slug') === chosenRiskGroupSlug);

    this.props.handleRiskGroupChange(chosenRiskGroup);
  }

  handleSecurityStatusChange(e: ChangeEvent<any>) {
    this.props.handleSecurityStatusChange(e);
    setTimeout(() => {
      this.fetchRiskTypes();
    }, 100);
  }

  handleRiskLevelChange(e: ChangeEvent<any>) {
    this.props.handleRiskLevelChange(e);
  }

  handleComplaintTypeChange(e: TICKET_TYPE) {
    this.props.handleComplaintTypeChange(e);

    if (e === TICKET_TYPE.CONDITION) {
      this.setState({ isUserKnown: false });
    }
    setTimeout(() => {
      this.fetchRiskTypes();
    }, 100);
  }

  handlepriorityStatusEnvRiskChange(e: string) {
    const { t } = this.props;
    switch (e) {
      case t(PRIORITY.LOW):
        this.props.handlepriorityStatusEnvRiskChange(0);
        break;
      case t(PRIORITY.MEDIUM):
        this.props.handlepriorityStatusEnvRiskChange(1);
        break;
      case t(PRIORITY.HIGH):
        this.props.handlepriorityStatusEnvRiskChange(2);
        break;
      default:
        break;
    }
  }

  validateFields() {
    const {
      selectedComplaintType,
      selectedSecurityStatus,
      selectedUserName,
      selectedUserSlug,
      selectedReportedByUserName,
      selectedReportedByUserSlug,
      isUserNotListed,
      isReportedByUserNotListed,
      issueDescription,
      selectedLocation,
      locationDescription,
      selectedRiskGroup,
      selectedRiskType,
      riskTypeDescription,
      selectedRiskLevel,
      correctiveAction,
      file,
      isSIF,
      SIFType,
      isFactory,
      t,
      category,
      selectedPriority,
      sapNumber,
      userData,
      selectedSubLocation,
      selectedSubArea,
    } = this.props;
    const { isUserKnown, isUserKnownReportedBy } = this.state;

    const ticketType = selectedComplaintType;
    const securityStatus = selectedSecurityStatus;

    // if (!ticketType || !securityStatus) return this.props.onPageValidation(false);
    const departmentHasAreas = !!this.props.selectedLocation?.get('subLocations').size;
    const areaHasSubareas = !!this.props.selectedSubLocation?.get('specificSubLocations').size;
    let isValidated = true;
    // let fieldsValidation: any;
    const fieldsValidation: any = {
      userName: { required: false, valid: !!selectedUserName && selectedUserName.length >= 8 && (!/[-!@#$%^&*()_+|~=`{}[\]:";<>?,./·¿¢∞¬÷“”≠‚¢ºª]/.test(selectedUserName) || !isUserNotListed) },
      userSlug: { required: false, valid: !!selectedUserSlug },
      submittedByUserName: { required: false, valid: !!selectedReportedByUserName },
      submittedByUserSlug: { required: false, valid: !!selectedReportedByUserSlug },
      incidentLocation: { required: false, valid: !!selectedLocation },
      areaSelected: { required: false, valid: !!selectedSubLocation },
      subAreaSelected: { required: false, valid: !!selectedSubArea },
      areaDescription: { required: false, valid: !!locationDescription },
      riskGroup: { required: false, valid: !!selectedRiskGroup },
      riskType: { required: false, valid: !!selectedRiskType },
      riskTypeDescription: { required: false, valid: !!riskTypeDescription },
      actDescription: { required: false, valid: !!issueDescription },
      riskLevel: { required: false, valid: !!selectedRiskLevel },
      actCorrectiveAction: { required: false, valid: !!correctiveAction },
      file: { required: false, valid: !!file },
      isSIF: { required: false, valid: !!isSIF },
      SIFType: { required: false, valid: !!SIFType },
      priority: { required: false, valid: !!selectedPriority },
      sapNumber: { required: false, valid: !!sapNumber },
      category: { required: false, valid: !!category },
    };
    // eslint-disable-next-line default-case
    switch (ticketType) {
      case TICKET_TYPE.ACT:
        // eslint-disable-next-line default-case
        switch (securityStatus) {
          case t(SECURITY_STATUS.INSECURE):
            fieldsValidation.userName.required = isUserKnown;
            fieldsValidation.userSlug.required = (isUserKnown
              && !isUserNotListed
              && selectedUserName
              && selectedUserName.length >= 8)
            ?? false;
            if (isFactory && selectedUserName && selectedUserName.length >= 3) {
              fieldsValidation.submittedByUserName.required = false;
            } fieldsValidation.submittedByUserName.required = (
              (isUserKnownReportedBy && userData?.roleDetails?.slug === Supply_User_Roles.KioskUser)
            );
            fieldsValidation.submittedByUserSlug.required = (
              isUserKnownReportedBy
              && !isReportedByUserNotListed
              && userData?.roleDetails?.slug === Supply_User_Roles.KioskUser
            ) ?? false;
            fieldsValidation.incidentLocation.required = true;
            fieldsValidation.areaSelected.required = departmentHasAreas;
            fieldsValidation.subAreaSelected.required = areaHasSubareas;
            fieldsValidation.riskType.required = true;
            fieldsValidation.riskLevel.required = !isFactory;
            fieldsValidation.actDescription.required = true;
            fieldsValidation.isSIF.required = isFactory ?? false;
            fieldsValidation.SIFType.required = (
              isFactory && isSIF === YES_NO_OPTIONS.YES
            ) ?? false;
            fieldsValidation.riskGroup.required = isFactory ?? false;
            fieldsValidation.actCorrectiveAction.required = true;
            break;
          case t(SECURITY_STATUS.SECURE):
            fieldsValidation.userName.required = isUserKnown;
            fieldsValidation.userSlug.required = (isUserKnown && !isUserNotListed) ?? false;
            fieldsValidation.submittedByUserName.required = (
              isUserKnownReportedBy && userData?.roleDetails?.slug === Supply_User_Roles.KioskUser
            );
            fieldsValidation.submittedByUserSlug.required = (
              isUserKnownReportedBy
              && !isReportedByUserNotListed
              && userData?.roleDetails?.slug === Supply_User_Roles.KioskUser
            ) ?? false;
            fieldsValidation.incidentLocation.required = true;
            fieldsValidation.areaSelected.required = departmentHasAreas;
            fieldsValidation.subAreaSelected.required = areaHasSubareas;
            fieldsValidation.riskType.required = true;
            fieldsValidation.actDescription.required = true;
            fieldsValidation.riskGroup.required = isFactory ?? false;
            break;
        }
        break;
      case TICKET_TYPE.CONDITION:
        // eslint-disable-next-line default-case
        switch (securityStatus) {
          case t(SECURITY_STATUS.INSECURE):
            fieldsValidation.submittedByUserName.required = (
              isUserKnownReportedBy && userData?.roleDetails?.slug === Supply_User_Roles.KioskUser
            );
            fieldsValidation.submittedByUserSlug.required = (
              isUserKnownReportedBy
              && !isReportedByUserNotListed
              && userData?.roleDetails?.slug === Supply_User_Roles.KioskUser
            ) ?? false;
            fieldsValidation.incidentLocation.required = true;
            fieldsValidation.areaDescription.required = true;
            fieldsValidation.areaSelected.required = departmentHasAreas;
            fieldsValidation.subAreaSelected.required = areaHasSubareas;
            fieldsValidation.riskType.required = true;
            fieldsValidation.riskLevel.required = !isFactory;
            fieldsValidation.actDescription.required = true;
            fieldsValidation.isSIF.required = isFactory ?? false;
            fieldsValidation.SIFType.required = (
              isFactory && isSIF === YES_NO_OPTIONS.YES
            ) ?? false;
            fieldsValidation.riskGroup.required = isFactory ?? false;
            fieldsValidation.actCorrectiveAction.required = true;
            break;
          case t(SECURITY_STATUS.SECURE):
            fieldsValidation.submittedByUserName.required = (
              isUserKnownReportedBy && userData?.roleDetails?.slug === Supply_User_Roles.KioskUser
            );
            fieldsValidation.submittedByUserSlug.required = (
              isUserKnownReportedBy
              && !isReportedByUserNotListed
              && userData?.roleDetails?.slug === Supply_User_Roles.KioskUser
            ) ?? false;
            fieldsValidation.incidentLocation.required = true;
            fieldsValidation.areaSelected.required = departmentHasAreas;
            fieldsValidation.subAreaSelected.required = areaHasSubareas;
            fieldsValidation.areaDescription.required = true;
            fieldsValidation.riskType.required = true;
            fieldsValidation.actDescription.required = true;
            fieldsValidation.riskGroup.required = isFactory ?? false;
            break;
        }
        break;
      case TICKET_TYPE.ENVIRONMENT:
        fieldsValidation.submittedByUserName.required = (
          isUserKnownReportedBy && userData?.roleDetails?.slug === Supply_User_Roles.KioskUser
        );
        fieldsValidation.submittedByUserSlug.required = (
          isUserKnownReportedBy
          && !isReportedByUserNotListed
          && userData?.roleDetails?.slug === Supply_User_Roles.KioskUser
        ) ?? false;
        fieldsValidation.incidentLocation.required = true;
        fieldsValidation.areaDescription.required = true;
        fieldsValidation.areaSelected.required = departmentHasAreas;
        fieldsValidation.riskGroup.required = isFactory ?? false;
        fieldsValidation.riskType.required = true;
        fieldsValidation.actDescription.required = true;
        fieldsValidation.sapNumber.required = category === 'ZBB' ?? true;
        fieldsValidation.category.required = true;
        break;
    }

    Object.keys(fieldsValidation).forEach(key => {
      if (fieldsValidation[key].required && !fieldsValidation[key].valid) isValidated = false;
    });
    if (!securityStatus) {
      isValidated = false;
    }
    this.props.handleIsValidated(isValidated);
  }

  render() {
    const {
      incidentDateTime,
      selectedComplaintType,
      selectedSecurityStatus,
      selectedUserName,
      selectedReportedByUserName,
      issueDescription,
      sapNumber,
      category,
      selectedLocation,
      selectedSubLocation,
      selectedSubArea,
      locationDescription,
      selectedRiskGroup,
      selectedRiskType,
      selectedRiskLevel,
      isRiskTypeDescriptionVisible,
      riskTypeDescription,
      correctiveAction,
      file,
      t,
      isFactory,
      selectedPriority,
      userData,
      hasAnonymousReport,
      isUserNotListed,
    } = this.props;
    const {
      distCenterDetails,
      userNameSuggestions,
      isUserKnown,
      isUserKnownReportedBy,
      riskGroups,
      distCenterinfo,
    } = this.state;

    const isAct = selectedComplaintType === TICKET_TYPE.ACT;
    const isCondition = selectedComplaintType === TICKET_TYPE.CONDITION;
    const isSecure = selectedSecurityStatus === t(SECURITY_STATUS.SECURE);
    const locationHasSafeReportsEnabled = !!distCenterinfo?.getIn(['distCenterDetails', 'hasSafeReportsEnabled']);
    const incidentsPilotAccess = userData?.canAccessSIOSWebChangeRequest;

    let userNameInputError = '';
    if (isAct && isUserKnown && isUserNotListed) {
      if (selectedUserName && /[-!@#$%^&*()_+|~=`{}[\]:";<>?,./·¿¢∞¬÷“”≠‚¢ºª]/.test(selectedUserName)) {
        userNameInputError = t(TRANSLATIONS.SPECIAL_CHARACTERS_NOT_ALLOWED);
      } else if (selectedUserName && selectedUserName.length < 8) {
        userNameInputError = t(TRANSLATIONS.PLEASE_ENTER_8_CHARACTERS);
      }
    }

    const lng = ['en-US', 'en'].includes(i18next.language) ? 'en' : 'es';
    const areaHasSubareas = !!this.props.selectedSubLocation?.get('specificSubLocations').size;
    const departmentHasAreas = !!this.props.selectedLocation?.get('subLocations').size;

    const ticketTypeOptionsDPO = [
      { key: TICKET_TYPE.ACT, text: t(TRANSLATIONS.BEHAVIOR) },
      { key: TICKET_TYPE.CONDITION, text: t(TRANSLATIONS.CONDITION) },
    ];

    const ticketTypeOptionsSupply = [
      ...ticketTypeOptionsDPO,
      { key: TICKET_TYPE.ENVIRONMENT, text: t(TRANSLATIONS.ENVIRONMENTAL_RISK) },
    ];
    let securityStatusText = t(SECURITY_STATUS.INSECURE);
    if (selectedComplaintType === TICKET_TYPE.ACT && lng === 'es') {
      securityStatusText = `${securityStatusText.slice(0, -1)}o`;
    }

    const securityStatuses = ((isFactory && !isAct) || !locationHasSafeReportsEnabled)
      ? [
        { key: (t(SECURITY_STATUS.INSECURE)), text: securityStatusText },
      ]
      : [
        { key: (t(SECURITY_STATUS.SECURE)), text: (t(SECURITY_STATUS.SECURE)) },
        { key: (t(SECURITY_STATUS.INSECURE)), text: securityStatusText },
      ];

    const riskLevel = [
      {
        key: RISK_LEVEL.HIGH, text: t(RISK_LEVEL.HIGH),
      },
      {
        key: RISK_LEVEL.LOW, text: t(RISK_LEVEL.LOW),
      },
    ];

    const priorityStatusEnvRisk = [
      { key: (t(PRIORITY.HIGH)), text: (t(PRIORITY.HIGH)) },
      { key: (t(PRIORITY.MEDIUM)), text: (t(PRIORITY.MEDIUM)) },
      { key: (t(PRIORITY.LOW)), text: (t(PRIORITY.LOW)) },
    ];

    const categoryOptions = [t(TRANSLATIONS.PROCEDURE), 'ZBB', 'CAPEX'].map(c => ({ value: c, label: c }));

    let filteredRiskTypes = riskGroups?.find(
      (riskGroup: NameAndSlugRecord) => riskGroup.get('slug') === selectedRiskGroup?.get('slug'),
    )
      ?.get('riskTypes');

    if (this.props.selectedSecurityStatus && this.props.selectedComplaintType && !isFactory) {
      filteredRiskTypes = filteredRiskTypes
        ?.filter((riskType: any) => {
          if (riskType.get('slug') === otherRiskType?.slug) return riskType;

          return riskType.get('locationType') === LOCATION_TYPES.INSIDE_DC
            && riskType.get('isUnsafe') === (this.props.selectedSecurityStatus === t(SECURITY_STATUS.INSECURE))
            && riskType.get('typeOfIssue') === this.props.selectedComplaintType;
        });
    }

    let subLocations = [] as any;
    let subAreas = [] as any;
    if (this.props.selectedLocation) {
      subLocations = this.props.selectedLocation.get('subLocations');
    }
    if (this.props.selectedSubLocation) {
      subAreas = this.props.selectedSubLocation.get('specificSubLocations');
    }

    return (
      <div className="form-details">

        {/* datetime */}
        <div className="mt-4 mb-4">
          <TimePicker
            incidentDateTime={incidentDateTime}
            handleDateChange={this.props.handleDateChange}
            handleTimeChange={this.props.handleTimeChange}
            limitDate={isFactory && (new Date())}
          />
        </div>

        {/*  disclaimer information submitter */}
        {/* <div>
          <p>
            {t(TRANSLATIONS.TO_KEEP_A_TRACK_RECORD_OF_YOUR_REPORTS_TYPE_IN_YOUR_SAP_NUMBER)}
            {t(TRANSLATIONS.PLEASE_MAKE_SURE_ITS_AN_8_DIGIT_NUMBER)}
          </p>
          <p className="mt-4">
            {t(TRANSLATIONS.IF_YOU_LEAVE_IT_EMPTY_OR_INCORRECT_THIS_WILL_COUNT_AS_ANONYMOUS_REPORT)}
          </p>
        </div> */}
        {/* submitter */}
        {/* <InputWithFeedback
          value={submittedByString}
          onChange={this.props.handleSubmitterChange}
          placeholder={t(TRANSLATIONS.WRITE_YOUR_8_DIGIT_SAP_NUMBER)}
          containerClassName="mt-4 submitter-container"
          inputClassName="submitter-input"
          validateValue={this.validateSAPInputField}
          error={t(TRANSLATIONS.WRITE_AN_8_DIGIT_SAP_NUMBER)}
          successComponent={() => this.successComponentSAP(t)}
        /> */}

        {/* ticket type */}
        <Toggle
          options={isFactory ? ticketTypeOptionsSupply : ticketTypeOptionsDPO}
          onOptionSelect={this.handleComplaintTypeChange}
          selectedOption={selectedComplaintType || ''}
          // tabStyle={tabStyle}
          activeTabStyle={activeTabStyle}
          inActiveTabStyle={inActiveTabStyle}
          containerClassName="mt-2"
        />

        {/* security status */}
        {selectedComplaintType && selectedComplaintType !== TICKET_TYPE.ENVIRONMENT
        && (
          <Toggle
            options={securityStatuses}
            onOptionSelect={this.handleSecurityStatusChange}
            selectedOption={selectedSecurityStatus || ''}
            // tabStyle={tabStyle}
            activeTabStyle={activeTabStyle}
            inActiveTabStyle={inActiveTabStyle}
            containerClassName="mt-2"
          />
        )}

        {/* rest of fields */}
        {((selectedComplaintType
        && selectedSecurityStatus)
        || (selectedComplaintType
        === TICKET_TYPE.ENVIRONMENT)) && (
          <>
            <div className="mt-4 d-flex flex-column fade-up">
              {distCenterDetails?.size ? (
                <>
                  {/* committer */}
                  {selectedComplaintType === TICKET_TYPE.ACT ? (
                    <div className="mt-4">
                      {/* label */}
                      <p className="text-light">{t(TRANSLATIONS.WHO_HAD_THIS_BEHAVIOR)}</p>

                      {/* autocomplete */}
                      {isUserKnown || isFactory ? (
                        <div className="user-search-input-container">
                          {/* @ts-ignore */}
                          <Autosuggest
                            multiSection={false}
                            suggestions={userNameSuggestions as UserMap[]}
                            onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
                            onSuggestionsClearRequested={this.clearSuggestions}
                            onSuggestionSelected={this.props.handleSuggestionSelected}
                            getSuggestionValue={(user: UserMap) => userNameExtractor(user)}
                            renderSuggestion={(user: UserMap) => (
                              <h5 className="cursor-point">{userNameExtractor(user)}</h5>
                            )}
                            inputProps={{
                              placeholder: t(TRANSLATIONS.SEARCH_USERS),
                              value: selectedUserName || '',
                              onChange: this.props.handleUserChange,
                            }}
                          />
                          {selectedUserName && <img src={ImageLinks.checkIcon} alt="valid field" />}
                        </div>
                      ) : (
                      // couldn't identify msg
                        <div className="d-flex align-items-center" style={{ height: 50 }}>
                          <h3 className="mb-0 mt-1">
                            {t(TRANSLATIONS.IT_WAS_NOT_POSSIBLE_TO_IDENTIFY)}
                          </h3>
                        </div>
                      )}
                      {userNameInputError && (
                        <div className="text-primary">{userNameInputError}</div>
                      )}
                      {
                        !isFactory ? (
                          <div className="align-items-center mt-2">
                            <button
                              type="button"
                              className="all-unset text-muted ml-1"
                              style={{ color: COLOR_CODE.PLACEHOLDER }}
                              onClick={this.handleToggleUserKnown}
                            >
                              {isUserKnown
                                ? t(TRANSLATIONS.I_COULDNT_IDENTIFY_THE_PERSON)
                                : t(TRANSLATIONS.BACK_TO_SEARCH_BAR)}
                            </button>
                            <img
                              src={ImageLinks.chevronRightBlueIcon}
                              alt="couldn't identify who did it"
                              height="11"
                              className="ml-2"
                            />
                          </div>
                        ) : null
                      }
                    </div>
                  ) : null}

                  {(userData?.roleDetails?.slug === Supply_User_Roles.KioskUser) ? (
                    <div className="mt-4">
                      {/* label */}
                      <p className="text-light">
                        {isAct
                          ? t(TRANSLATIONS.WHO_SUBMITTED_THIS_BEHAVIOR)
                          : isCondition
                            ? t(TRANSLATIONS.WHO_SUBMITTED_THIS_CONDITION)
                            : t(TRANSLATIONS.WHO_SUBMITTED_THIS_ENVIRONMENTAL_RISK)}
                      </p>
                      {/* autocomplete */}
                      {isUserKnownReportedBy ? (
                        <div className="user-search-input-container">
                          {/* @ts-ignore */}
                          <Autosuggest
                            multiSection={false}
                            suggestions={userNameSuggestions as UserMap[]}
                            onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
                            onSuggestionsClearRequested={this.clearSuggestions}
                            onSuggestionSelected={this.props.handleSuggestionSelectedReportedBy}
                            getSuggestionValue={(user: UserMap) => userNameExtractor(user)}
                            renderSuggestion={(user: UserMap) => (
                              <h5 className="cursor-point">{userNameExtractor(user)}</h5>
                            )}
                            inputProps={{
                              placeholder: t(TRANSLATIONS.SEARCH_USERS),
                              value: selectedReportedByUserName || '',
                              onChange: this.props.handleReportedByUserChange,
                            }}
                          />
                          {selectedReportedByUserName && <img src={ImageLinks.checkIcon} alt="valid field" />}
                        </div>
                      ) : (
                        // couldn't identify msg
                        <div className="d-flex align-items-center" style={{ height: 50 }}>
                          <h3 className="mb-0 mt-1">
                            {t(TRANSLATIONS.IT_IS_GOING_TO_BE_AN_ANONYMOUS_REPORT)}
                          </h3>
                        </div>
                      )}

                      {/* committer known toggle */}
                      { hasAnonymousReport
                        ? (
                          <div className="align-items-center mt-2">
                            <button
                              type="button"
                              className="all-unset text-muted ml-1"
                              style={{ color: COLOR_CODE.PLACEHOLDER }}
                              onClick={this.handleToggleUserKnownReportedBy}
                            >
                              {isUserKnownReportedBy
                                ? t(TRANSLATIONS.IT_IS_AN_ANONYMOUS_REPORT)
                                : t(TRANSLATIONS.BACK_TO_SEARCH_BAR)}
                            </button>
                            <img
                              src={ImageLinks.chevronRightBlueIcon}
                              alt="couldn't identify who did it"
                              height="11"
                              className="ml-2"
                            />
                          </div>
                        ) : null }
                    </div>
                  ) : null}

                  <div>

                    {/* location + location description text box */}
                    <div className="location-container">
                      {/* location dropdown */}
                      <AnonymousFormDropdownWithLabel
                        name="Location"
                        value={selectedLocation?.get('slug') || ''}
                        onChange={(locationSlug: ChangeEvent<any>) => this.handleLocationChange(
                          locationSlug,
                        )}
                        dropdownContent={distCenterDetails.get('workLocations')
                          ?.map((location: WorkLocationRecord) => ({
                            value: location.get('slug'),
                            label: lng === 'en' ? location.get('nameEN') || location.get('name') : location.get('name') || location.get('nameEN'),
                          }))}
                        placeholder={`${isFactory ? t(TRANSLATIONS.DEPARTMENT) : t(TRANSLATIONS.WHERE_DID_YOU_SEE_IN_DC_AREA)} *?`}
                        label={isFactory ? t(TRANSLATIONS.DEPARTMENT)
                          : isAct
                            ? `${t(TRANSLATIONS.WHERE_DID_YOU_SEE_IN_DC_AREA)} *?`
                            : t(TRANSLATIONS.IN_WHICH_AREA_OF_DISTRIBUTION_CENTER)}
                      />

                      { (!!selectedLocation?.get('slug').trim().length && departmentHasAreas) && (
                      <AnonymousDropDown
                        name="SubLocation"
                        value={selectedSubLocation?.get('slug') || ''}
                        onChange={
                          (subLocationChange: ChangeEvent<any>) => this.handleSubLocationChange(
                            subLocationChange,
                          )
                        }
                        dropdownContent={subLocations
                          ?.map((location: NameAndSlugRecord) => ({
                            value: location.get('slug'),
                            label: lng === 'en' ? location.get('nameEN') || location.get('name') : location.get('name') || location.get('nameEN'),
                          }))}
                        placeholder={`${t(TRANSLATIONS.SELECT)} ${selectedLocation?.get('name') || ''}*`}
                        mainContainerClassName="mt-2 form-details"
                      />
                      ) }

                      { (!!selectedSubLocation?.get('slug').trim().length && isFactory && areaHasSubareas) && (
                      <AnonymousDropDown
                        name="SubArea"
                        value={selectedSubArea?.get('slug') || ''}
                        onChange={
                          (subAreaChange: ChangeEvent<any>) => this.handleSubAreaChange(
                            subAreaChange,
                          )
                        }
                        dropdownContent={subAreas
                          ?.map((location: NameAndSlugRecord) => ({
                            value: location.get('slug'),
                            label: lng === 'en' ? location.get('nameEN') || location.get('name') : location.get('name') || location.get('nameEN'),
                          }))}
                        placeholder={`${t(TRANSLATIONS.SELECT)} ${selectedSubArea?.get('name') || ''}*`}
                        mainContainerClassName="mt-2 form-details"
                      />
                      ) }

                      {/* location description */}
                      <LabelledInputWithTick
                        value={locationDescription || ''}
                        onChange={this.props.handleLocationDescriptionChange}
                        label=""
                        isTextArea
                        isValid={!!locationDescription}
                        inputProps={{ placeholder: `${t(TRANSLATIONS.SPECIFY_THE_PLACE_IN_THE_SUBAREA)} ${isAct ? '' : '*'}` }}
                        containerClassName="mt-1 location-description fade-up"
                        className="mt-1"
                        iconStyle={{ marginRight: 5 }}
                      />
                    </div>

                    <div>
                      {/* risk group + risk type + risk description */}
                      <div className="risk-type-container">
                        {/* risk-group dropdown */}
                        <AnonymousFormDropdownWithLabel
                          name="RiskGroup"
                          value={selectedRiskGroup?.get('slug') || ''}
                          onChange={this.handleRiskGroupChange}
                          dropdownContent={riskGroups
                            ?.map((riskGroup: any) => ({
                              value: riskGroup.get('slug'),
                              label: lng === 'en' ? riskGroup.get('nameEN') || riskGroup.get('name') : riskGroup.get('name') || riskGroup.get('nameEN'),
                            }))}
                          placeholder={t(TRANSLATIONS.SELECT_RISK_GROUP)}
                          label={!isSecure ? `${t(TRANSLATIONS.RISK_GROUP)}*`
                            : isAct ? t(TRANSLATIONS.WHAT_RISK_TYPE_WAS_PREVENTED_WITH_ACT)
                              : t(TRANSLATIONS.WHAT_RISK_TYPE_WAS_PREVENTED_WITH_CONDITION)}
                        />

                        {/* risk-type dropdown */}
                        <AnonymousFormDropdownWithLabel
                          name="RiskType"
                          value={selectedRiskType?.get('slug') || ''}
                          onChange={this.handleRiskTypeChange}
                          dropdownContent={filteredRiskTypes
                            ?.map((riskType: any) => ({
                              value: riskType.get('slug'),
                              label: lng === 'en' ? riskType.get('nameEN') || riskType.get('name') : riskType.get('name') || riskType.get('nameEN'),
                            }))}
                          placeholder={t(TRANSLATIONS.SELECT_RISK_TYPE)}
                          label={!isSecure ? `${t(TRANSLATIONS.TYPE_OF_RISK)}*`
                            : isAct ? t(TRANSLATIONS.WHAT_RISK_TYPE_WAS_PREVENTED_WITH_ACT)
                              : t(TRANSLATIONS.WHAT_RISK_TYPE_WAS_PREVENTED_WITH_CONDITION)}
                        />

                        {/* risk type golden rule  */}
                        {
                          selectedRiskType
                          && isFactory
                          && selectedComplaintType !== TICKET_TYPE.ENVIRONMENT
                          && incidentsPilotAccess
                          && (

                            <div className={selectedRiskType?.get('isGoldenRule') ? 'fd-sif-golden-rule-banner-2' : 'fd-sif-golden-rule-banner'}>
                              <section className="fd-sif-golden-rule-banner-item-container">
                                <img
                                  src={selectedRiskType?.get('isSIF') ? ImageLinks.acis3 : ImageLinks.shield}
                                  className="fd-sif-golden-rule-banner-item-image"
                                  alt="SIF"
                                />
                                <div className="fd-sif-golden-rule-banner-item-title">{t(TRANSLATIONS.ACI_CONSIDERED)} {t(selectedRiskType?.get('isSIF') ? TRANSLATIONS.SIF : TRANSLATIONS.NO_SIF)} ({t(TRANSLATIONS.SERIOUS_INJURIES_AND_FATALITIES)})</div>
                              </section>

                              {/* <section className="fd-sif-golden-rule-banner-item-container">
                                <img
                                  src={selectedRiskType?.get('isGoldenRule')
                                  ? ImageLinks.thunder2 : ImageLinks.thunder}
                                  className="fd-sif-golden-rule-banner-item-image"
                                  alt="golden rule"
                                />
                                <div className="fd-sif-golden-rule-banner-item-title">
                                {selectedRiskType?.get('isGoldenRule')
                                ? t(TRANSLATIONS.BREAKS_THE_GOLDEN_RULE)
                                : t(TRANSLATIONS.FOLLOW_THE_GOLDEN_RULE)
                              }</div>
                              </section>
                              <div className="fd-sif-golden-rule-banner-item-info"></div> */}
                            </div>
                          )
                        }

                        {/* risk type description */}
                        {isRiskTypeDescriptionVisible && (
                          <LabelledInputWithTick
                            value={riskTypeDescription}
                            onChange={this.props.handleRiskTypeDescriptionChange}
                            isTextArea
                            isValid={!!riskTypeDescription}
                            containerClassName="mt-1 other-risk-container fade-up"
                            iconStyle={{ marginRight: 5 }}
                            inputClassName="other-risk"
                            label=""
                            inputProps={
                              {
                                placeholder: `Describe the ${t(TRANSLATIONS.TYPE_OF_RISK).toLowerCase()}`,
                              }
                            }
                            labelClassName="text-light"
                          />
                        )}
                      </div>

                      {/* incident description */}
                      <LabelledInputWithTick
                        value={issueDescription}
                        onChange={this.props.handleDescriptionChange}
                        label={`${isAct
                          ? `${t(TRANSLATIONS.DESCRIPTION)}`
                          : t(TRANSLATIONS.DESCRIPTION)} *`}
                        isTextArea
                        isValid={!!issueDescription}
                        inputProps={{ placeholder: isAct ? `${t(TRANSLATIONS.DESCRIBE_THE_BEHAVIOR)}...` : `${t(TRANSLATIONS.DESCRIPTION_OF_THE_CONDITION)}...` }}
                        containerClassName="mt-1 incident-description-container fade-up"
                        inputClassName="incident-description"
                        labelClassName="text-light"
                        className="incident-description-main"
                        iconStyle={{ marginRight: 5 }}
                      />

                      {(!isSecure && !isFactory) ? (
                        <>
                          <p className="text-light mt-4">{`${t(TRANSLATIONS.RISK_LEVEL_TRANSLATION)} *`}</p>
                          <Toggle
                            options={riskLevel}
                            onOptionSelect={this.handleRiskLevelChange}
                            selectedOption={selectedRiskLevel || ''}
                          // tabStyle={tabStyle}
                            activeTabStyle={activeTabStyle}
                            inActiveTabStyle={inActiveTabStyle}
                            containerClassName="mt-2"
                          />
                        </>
                      ) : null}

                      {/* corrective action */}
                      {(selectedSecurityStatus
                      === t(SECURITY_STATUS.INSECURE)
                      && selectedComplaintType
                      !== TICKET_TYPE.ENVIRONMENT) && (
                        <LabelledInputWithTick
                          value={correctiveAction || ''}
                          onChange={this.props.handleCorrectiveActionChange}
                          label={`${t(TRANSLATIONS.CORRECTIVE_ACTION)} *`}
                          isTextArea
                          isValid={!!correctiveAction}
                          inputProps={{ placeholder: `Describe ${t(TRANSLATIONS.CORRECTIVE_ACTION).toLowerCase()}` }}
                          containerClassName="mt-1 corrective-action-container"
                          labelClassName="text-light"
                          className="corrective-action-main"
                          iconStyle={{ marginRight: 5 }}
                        />
                      )}
                      {selectedComplaintType === TICKET_TYPE.ENVIRONMENT
                      && (
                        <AnonymousFormDropdownWithLabel
                          name="Category"
                          value={category || ''}
                          onChange={e => this.handleCategory(e)}
                          dropdownContent={categoryOptions}
                          placeholder={t(TRANSLATIONS.CATEGORY)}
                          label={t(TRANSLATIONS.CATEGORY)}
                        />
                      )}
                      {category === 'ZBB' && (
                        <LabelledInputWithTick
                          value={sapNumber || ''}
                          onChange={this.props.handleSapNumber}
                          label="#SAP"
                          isTextArea
                          isValid={!!sapNumber}
                          inputProps={{ placeholder: '#SAP' }}
                          containerClassName="mt-1 corrective-action-container"
                          labelClassName="text-light"
                          className="corrective-action-main"
                          iconStyle={{ marginRight: 5 }}
                        />
                      )}
                      {selectedComplaintType === TICKET_TYPE.ENVIRONMENT
                      && (
                        <>
                          <p className="text-light mt-5"> {t(TRANSLATIONS.PRIORITY_T)} </p>
                          <Toggle
                            options={priorityStatusEnvRisk}
                            onOptionSelect={(e: any) => this.handlepriorityStatusEnvRiskChange(e)}
                            selectedOption={
                              selectedPriority === 0
                                ? t(PRIORITY.LOW)
                                : selectedPriority === 1
                                  ? t(PRIORITY.MEDIUM)
                                  : selectedPriority === 2
                                    ? t(PRIORITY.HIGH)
                                    : null
                            }
                            // tabStyle={tabStyle}
                            activeTabStyle={activeTabStyle}
                            inActiveTabStyle={inActiveTabStyle}
                            containerClassName="mt-2"
                          />
                        </>
                      )}
                    </div>
                    <div className="upload-file mt-5">
                      <FileUpload
                        handleFileChange={this.props.handleFileChange}
                        label={t(TRANSLATIONS.UPLOAD_EVIDENCE_OF_ACI_CREATION)}
                        file={file}
                        containerClassName="file"
                      />
                    </div>
                    <div className="upload-file">
                      <p className="text-light">{`${t(TRANSLATIONS.MAXIMUM_ALLOWED_FILE_SIZE, {
                        size: '10 MB',
                      })}`}
                      </p>
                    </div>
                  </div>
                </>
              ) : (
                <LoadingSpinner />
              )}
            </div>
          </>
        )}
      </div>
    );
  }
}

type Props = {
  // stepper
  incidentDateTime: Date;
  submittedByString?: string;
  onPageValidation?: any;
  distCenterSlug: string;
  selectedComplaintType?: TICKET_TYPE;
  selectedSecurityStatus?: SECURITY_STATUS;
  selectedUserName?: string; // when typed (not found in dropdown)
  selectedUserSlug?: string; // when chosen from dropdown
  selectedReportedByUserName?: string; // for user name that reported the incident
  selectedReportedByUserSlug?: string; // for user slug that reported the incident
  isUserNotListed?: boolean;
  isReportedByUserNotListed?: boolean;
  issueDescription: string;
  sapNumber: string;
  category: string;
  selectedPriority?: number;
  selectedLocation?: WorkLocationRecord;
  locationDescription?: string;
  isShowingLocationDescription?: boolean;
  selectedRiskGroup?: NameAndSlugRecord;
  selectedRiskType?: NameAndSlugRecord;
  selectedRiskLevel?: RISK_LEVEL;
  selectedImmediateClosure?: YES_NO_OPTIONS;
  isSIF?: YES_NO_OPTIONS;
  SIFType?: SIF_TYPES;
  selectedSubLocation?:NameAndSlugRecord;
  selectedSubArea?:NameAndSlugRecord;
  correctiveAction?: string;
  isRiskTypeDescriptionVisible?: boolean;
  riskTypeDescription: string;
  file?: any;
  // handlers
  handleDateChange: any;
  handleTimeChange: any;
  handleSubmitterChange: any;
  handleComplaintTypeChange: any;
  handlepriorityStatusEnvRiskChange: any;
  handleSecurityStatusChange: any;
  handleUserChange: any;
  handleReportedByUserChange: any;
  handleSuggestionSelected: any;
  handleSuggestionSelectedReportedBy: any;
  handleDescriptionChange: any;
  handleLocationDescriptionChange: any;
  handleRiskLevelChange: any;
  handleImmediateClosure: any;
  handleIsSIFChange: any;
  handleIsSIFTypeChange: any;
  handleCorrectiveActionChange: any;
  handleFileChange: any;
  handleLocationChange: any;
  handleCategory: any;
  handleSapNumber: any;
  handleRiskGroupChange: any;
  handleRiskTypeChange: any;
  handleRiskTypeDescriptionChange: any;
  handleSubLocationChange: any;
  handleSubAreaChange: any;
  handleHasAnonymousReport: Function;
  hasAnonymousReport?: any;
  t: any;
  isFactory: boolean;
  handleIsValidated?: any;
  userData?: any;
  selectedCountry: any;
};

type State = {
  distCenterDetails?: DistributionCenterDetailsMap;
  userNameSuggestions: any;
  userNames: DistributionCenterDetails['users'];
  isUserKnown: boolean;
  isUserKnownReportedBy: boolean;
  riskGroups: any[];
  riskTypes: any[];
  riskLevel: RISK_LEVEL | null;
  distCenterinfo?: any;
};

const mapStateToProps = (state: any) => {
  return {
    userData: state.get('user')?.toJS(),
  };
};

// @ts-ignore
export default connect(mapStateToProps)(FormDetails) as any;
