import React, { CSSProperties, FC, HTMLAttributes } from 'react';
import SelectDropdown, { SelectDropdownProps } from 'components/Dropdown/SelectDropdown/SelectDropdown';
import { COLOR_CODE } from 'types/enums';
import ImageLinks from 'utils/ImageLinks';
import '../AnonymousFormDropdownWithLabel/AnonymousFormDropdownWithLabel.scss';

const AnonymousDropDown: FC<Props> = (props: Props) => {
  const { value, mainContainerClassName, mainContainerStyle, labelComponent, ...rest } = props;

  return (
    <div className={mainContainerClassName} style={mainContainerStyle}>
      {labelComponent && labelComponent}
      <div className="anonymous-form-dropdown">
        <SelectDropdown
          value={value}
          dropdownElementsWidth={200}
          dropDownClassName="dropdown-constraints"
          className={`mt-1 ${value ? 'valid-location' : 'invalid-location'}`}
          placeHolderStyle={{
            color: COLOR_CODE.PLACEHOLDER,
          }}
          valueStyle={{
            color: COLOR_CODE.DARK,
          }}
          {...rest}
        />
        {value && <img src={ImageLinks.checkIcon} alt="this field is valid" />}
      </div>
    </div>
  );
};

interface Props extends SelectDropdownProps {
  mainContainerClassName?: HTMLAttributes<HTMLDivElement>['className'];
  mainContainerStyle?: CSSProperties;
  labelComponent?: any;
}

export default AnonymousDropDown;
