import React, { FC } from 'react';
import './SecondaryHeadline.scss';

const SecondaryHeadline: FC<Props> = (props: Props) => {
  const { title, className } = props;
  return (
    <h2 className={`text-tertiary-light ${className}`}>{title}</h2>
  );
};

type Props = {
  title: string;
  className?: string;
};

export default SecondaryHeadline;
