import CheckBoxWithIcon from 'components/CheckBoxWithIcon';
import { fromJS } from 'immutable';
import { cloneDeep } from 'lodash';
import React from 'react';
import imageLinks from 'utils/ImageLinks';
import { capitalizeFirst } from 'utils/stringHelpers';
import { withStyles } from '@mui/styles';
import { Checkbox, CheckboxProps } from '@mui/material';
import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import './UserInfo.scss';

interface RoutinesByPillars {
  selected: boolean;
  routines: {
    slug: string;
    name: string;
    granted: boolean;
    editable: boolean;
  }[];
}

interface RoutinesSelectorProps {
  setFormData: Function;
  routinesByPillars: {
    [key: string]: RoutinesByPillars;
  };
}

function getCountOfGrantedRoutines(routinesByPillars: RoutinesByPillars) {
  return routinesByPillars.routines.filter(routine => !!routine.granted).length;
}

function getCountOfTotalRoutines(routinesByPillars: RoutinesByPillars) {
  return routinesByPillars.routines.length;
}

const RoutinesSelector: React.FC<any> = (props: RoutinesSelectorProps) => {
  const OrangeCheckbox = withStyles({
    root: {
      '&$checked': {
        color: '#EFBE3F',
      },
    },
    checked: {},
  })((MUIRadioProps: CheckboxProps) => <Checkbox color="default" {...MUIRadioProps} />);
  const { routinesByPillars: routinesGroupedByPillar } = props;
  const { emptyRadioUnchecked, yellowRadioChecked } = imageLinks;

  const onTogglePillarSelection = (pillar: string) => {
    props.setFormData((formData: any) => formData.setIn(
      ['permissions', 'routines', pillar, 'selected'],
      !formData.getIn(['permissions', 'routines', pillar, 'selected']),
    ));
  };

  const shouldShowAllPillars = () => {
    const routinesGroupedByPillarClone = cloneDeep(routinesGroupedByPillar);
    let shouldShow = true;
    Object.values(routinesGroupedByPillarClone).forEach(routinesByPillar => {
      if (!routinesByPillar.selected) {
        shouldShow = false;
      }
    });
    return shouldShow;
  };

  const toggleShowAllPillars = () => {
    const isShowingAllPillars = shouldShowAllPillars();
    const routinesGroupedByPillarClone = cloneDeep(routinesGroupedByPillar);
    Object.values(routinesGroupedByPillarClone).forEach(routinesByPillar => {
      routinesByPillar.selected = !isShowingAllPillars;
    });
    props.setFormData((formData: any) => formData.setIn(['permissions', 'routines'], fromJS(routinesGroupedByPillarClone)));
  };

  const onToggle = (path: (string | number)[]) => {
    props.setFormData((formData: any) => formData.setIn(
      ['permissions', 'routines', ...path],
      !formData.getIn(['permissions', 'routines', ...path]),
    ));
  };

  const revokePermissionOfAllRoutines = () => {
    const routinesGroupedByPillarClone = cloneDeep(routinesGroupedByPillar);
    Object.values(routinesGroupedByPillarClone).forEach(routinesByPillar => {
      routinesByPillar.routines.forEach(routine => {
        routine.granted = false;
      });
    });
    props.setFormData((formData: any) => formData.setIn(['permissions', 'routines'], fromJS(routinesGroupedByPillarClone)));
  };

  return (
    <>
      <div className="row select_all_container">
        <div className="d-flex justify-content-top flex-column align-items-stretch select_all">
          <button
            className={shouldShowAllPillars() ? 'pillar-active' : 'pillar-inactive'}
            onClick={toggleShowAllPillars}
            type="button"
          >
            Todos los pilares
          </button>
          {Object.keys(routinesGroupedByPillar).map((pillar: string) => (
            <button
              className={` filter_button ${
                shouldShowAllPillars()
                  ? 'single-filter-button-active'
                  : routinesGroupedByPillar[pillar].selected
                    ? 'filter-button-active'
                    : 'filter-button-inactive'
              }`}
              onClick={() => onTogglePillarSelection(pillar)}
              type="button"
              key={pillar}
              // style={{ background: '#FFF4D9' }}
            >
              {capitalizeFirst(pillar)} (
              {getCountOfGrantedRoutines(routinesGroupedByPillar[pillar])})
            </button>
          ))}
        </div>
        <div className="routineList">
          {Object.keys(routinesGroupedByPillar).map((pillar: string) => {
            const { routines } = routinesGroupedByPillar[pillar];
            if (routinesGroupedByPillar[pillar].selected) {
              return (
                <div className="routineContainer py-1" key={pillar}>
                  <h4>
                    {capitalizeFirst(pillar)} (
                    {getCountOfGrantedRoutines(routinesGroupedByPillar[pillar])} /{' '}
                    {getCountOfTotalRoutines(routinesGroupedByPillar[pillar])})
                  </h4>
                  {routines.map((routine, routineIndex) => (
                    <CheckBoxWithIcon
                      onClick={() => {
                        if (routine.editable === false) {
                          return;
                        }
                        const permissionPath = [pillar, 'routines', routineIndex, 'granted'];
                        onToggle(permissionPath);
                      }}

                      deSelectedIcon={emptyRadioUnchecked}
                      selectedIcon={yellowRadioChecked}
                      selected={routine.granted}
                      label={routine.name}
                      key={routine.slug}
                      icon={<OrangeCheckbox
                        icon={<CircleUnchecked />}
                        checkedIcon={<CircleCheckedFilled />}
                        checked={routine.granted}
                      />}
                    />
                  ))}
                </div>
              );
            }
            return null;
          })}
        </div>
        <div className="remove-all">
          <button onClick={revokePermissionOfAllRoutines} type="button" style={{ color: '#CAA038' }}>
            Quitar todas
          </button>
        </div>
      </div>
    </>
  );
};

export default RoutinesSelector;
