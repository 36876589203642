import { combineReducers } from 'redux-immutable';

import safetyDashboardReducer from './safetyDashboard.reducer';
import userReducer from './users.reducer';
import distributionCenterReducer from './distributionCenter.reducer';
import timeFilterReducer from './timeFilter.reducer';
import corporateDashboardReducer from './corporateDashboard.reducer';
import statusReducer from './status.reducer';
import globalFiltersReducer from './globalFilters.reducer';
import safetyAcademmyReducer from './safetyAcademy.reducer';
import driverManagementSystemReducer from './driverManagementSystem.reducer';

const rootReducer = combineReducers({
  safetyDashboard: safetyDashboardReducer,
  user: userReducer,
  distCenter: distributionCenterReducer,
  timeFilter: timeFilterReducer,
  corporateDashboard: corporateDashboardReducer,
  status: statusReducer,
  globalFilters: globalFiltersReducer,
  safetyAcademy: safetyAcademmyReducer,
  driverManagementSystem: driverManagementSystemReducer,
});

export default rootReducer;
