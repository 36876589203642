import React, { useContext } from 'react';

const CapexContext = React.createContext<any>({});

export const CapexProvider:any = ({ capex, children }) => {
  return (
    <CapexContext.Provider value={capex}>
      {children}
    </CapexContext.Provider>
  );
};

export const useCapexContext = () => {
  return useContext(CapexContext);
};
