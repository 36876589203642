import React, { FC, useMemo } from 'react';
import { useExpanded, useTable } from 'react-table';
import ClosedIcon from '../../assets/icons/chevron-closed-down.svg';
import OpenIcon from '../../assets/icons/chevron-closed-right.svg';
import './ExpandableTable.scss';

type Props = {
  columns: any;
  data: any;
  isExpandable?: boolean;
  showExpandedRowSubHeader?: boolean;
};

const ExpandableTable: FC<Props> = ({
  columns,
  data,
  isExpandable,
  showExpandedRowSubHeader = false,
}: Props) => {
  const expandRowAction = (row: any, rows: any, toggleRowExpanded: any) => {
    const expandedRow = rows.find((currentRow: any) => currentRow.isExpanded);

    if (expandedRow) {
      const isSubItemOfRow = Boolean(expandedRow && row.id.split('.')[0] === expandedRow.id);

      if (isSubItemOfRow) {
        const expandedSubItem = expandedRow.subRows.find((subRow: any) => subRow.isExpanded);

        if (expandedSubItem) {
          const isSubSubItemOfRow = Boolean(
            row.id.split('.')[1] === expandedSubItem.id.split('.')[1],
          );
          const expandedSubSubItem = expandedSubItem.subRows.find(
            (subRow: any) => subRow.isExpanded,
          );
          if (isSubSubItemOfRow) {
            if (expandedSubSubItem) {
              const isClickedOnExpandedSubSubItem = expandedSubSubItem.id === row.id;
              if (!isClickedOnExpandedSubSubItem) {
                toggleRowExpanded(expandedSubSubItem.id, false);
              }
            }
          } else {
            const isClickedOnExpandedSubItem = expandedSubItem.id === row.id;
            if (!isClickedOnExpandedSubItem) {
              toggleRowExpanded(expandedSubItem.id, false);
            }
          }
        }
      } else {
        toggleRowExpanded(expandedRow.id, false);
      }
    }
    row.toggleRowExpanded();
  };

  const expandableColumns = useMemo(
    () => [
      {
        id: 'expander',
        Cell: ({ row }: { row: any }) => (row.canExpand ? (
          <span
            {...row.getToggleRowExpandedProps({
              style: { marginLeft: `${row.depth * 10}px` },
            })}
          >
            <span
              role="button"
              // eslint-disable-next-line @typescript-eslint/no-use-before-define
              onClick={() => expandRowAction(row, rows, toggleRowExpanded)}
            >
              {row.isExpanded ? (
                <img
                  src={OpenIcon}
                  alt="back"
                  style={{
                    width: 12,
                    height: 12,
                  }}
                />
              ) : (
                <img
                  src={ClosedIcon}
                  alt="back"
                  style={{
                    width: 12,
                    height: 12,
                  }}
                />
              )}
            </span>
          </span>
        ) : null),
      },
      ...columns,
    ],
    // eslint-disable-next-line
    [columns],
  );

  const conditionalProps = (row: any, rows: any, toggleRowExpanded: any) => {
    if (row.canExpand) {
      return {
        ...row.getToggleRowExpandedProps({
          style: {},
          onClick: () => expandRowAction(row, rows, toggleRowExpanded),
        }),
      };
    }
    return {};
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    toggleRowExpanded,
  } = useTable({ columns: isExpandable ? expandableColumns : columns, data }, useExpanded);
  return (
    <div className="expandable-table">
      <table {...getTableProps()} className="table">
        <thead className="some">
          {headerGroups.map(
            (headerGroup: {
              getHeaderGroupProps: () => JSX.IntrinsicAttributes &
              React.ClassAttributes<HTMLTableRowElement> &
              React.HTMLAttributes<HTMLTableRowElement>;
              headers: any[];
            }) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any, i: number) => (
                  <th {...column.getHeaderProps()} style={{ height: 45 }} key={i}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ),
          )}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(
            (row: {
              getRowProps: () => JSX.IntrinsicAttributes &
              React.ClassAttributes<HTMLTableRowElement> &
              React.HTMLAttributes<HTMLTableRowElement>;
              cells: any[];
              isExpanded?: boolean;
              canExpand?: boolean;
              depth?: any;
              id: any;
            }) => {
              prepareRow(row as any);
              const rowID = row.id ?? 0;
              if (row.depth === 1 && !!showExpandedRowSubHeader) {
                if (String(rowID).split('.')[1] === '0') {
                  return (
                    <>
                      <tr
                        className="row-subHeader"
                        {...row.getRowProps()}
                        {...conditionalProps(row, rows, toggleRowExpanded)}
                      >
                        {row.cells.map((cell: any, i: number) => {
                          return (
                            <td
                              className="row-subHeader-title"
                              key={i}
                              {...cell.getCellProps()}
                              {...(cell.column?.getProps ? cell.column?.getProps(cell) : {})}
                              style={{
                                height: 40,
                              }}
                            >
                              {(() => {
                                if (i === 0) return '';
                                if (row.cells[i].column.Header === '') return 'RUTINAS';
                                if (row.cells[i].column.Header === 'DC') return 'PERIODICIDAD';
                                if (row.cells[i].column.Header === 'Prog. actual') return 'PROGRESO';
                                if (row.cells[i].column.Header === 'Última') return 'ÚLTIMA';
                                return '';
                              })()}
                            </td>
                          );
                        })}
                      </tr>
                      <tr
                        {...row.getRowProps()}
                        {...conditionalProps(row, rows, toggleRowExpanded)}
                      >
                        {row.cells.map((cell: any, i: number) => {
                          return (
                            <td
                              key={i}
                              {...cell.getCellProps()}
                              {...(cell.column?.getProps ? cell.column?.getProps(cell) : {})}
                              style={{
                                height: 45,
                              }}
                            >
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                    </>
                  );
                }
                return (
                  <tr
                    {...row.getRowProps()}
                    className={`
                  row-subItems
                `}
                    {...conditionalProps(row, rows, toggleRowExpanded)}
                  >
                    {row.cells.map((cell: any, i: number) => {
                      console.log({ rowDepth: row.depth });
                      return (
                        <td
                          key={i}
                          {...cell.getCellProps()}
                          {...(cell.column?.getProps ? cell.column?.getProps(cell) : {})}
                          style={{
                            height: 45,
                          }}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              }
              return (
                <tr
                  {...row.getRowProps()}
                  className={`
                row
                row_depth${row.depth} 
                ${row.isExpanded ? 'expanded-row' : ''}
                ${row.canExpand ? 'expandable-row' : ''}
                `}
                  {...conditionalProps(row, rows, toggleRowExpanded)}
                >
                  {row.cells.map((cell: any, i: number) => {
                    return (
                      <td
                        key={i}
                        {...cell.getCellProps()}
                        {...(cell.column?.getProps ? cell.column?.getProps(cell) : {})}
                        className={`
                        cell_depth${row.depth}
                        ${isExpandable ? '' : 'cell'}
                        ${row.isExpanded ? 'expanded-row-items' : ''}
                      `}
                        style={{
                          height: row.depth === 0 ? 45 : 35,
                        }}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            },
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ExpandableTable;
