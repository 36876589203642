import React, { useState } from 'react';
import Modal from 'components/Modal';
import Close from 'assets/icons/close-24px.svg';
import OutlineButton from 'components/Button/OutlineButton';
import Calendar from 'components/Calendar';
import User from 'assets/icons/icon-placeholder.png';
import './PCRDateAssignStyles.scss';
import { COLOR_CODE_NEW } from 'types/enums';

type Props = {
  onOverlayClick(): void;
  row: any
};

const PCRDateAssign = (props: Props) => {
  const { SUCCESS, BORDERS } = COLOR_CODE_NEW;
  const [toggleCovidButton, setToggleCovidButton] = useState<boolean>(false);
  const toggleButton = (btn: string) => {
    if (btn === '1') {
      setToggleCovidButton(false);
      return;
    }
    setToggleCovidButton(true);
  };
  return (
    <Modal modalContainerStyles={{
      width: '25%',
      height: '70%',
      top: '60px',
      left: '68%',
      padding: 0,
    }}
    >
      <div className="header_area">
        <div className="intro_section">
          <div style={{ display: 'flex' }}>
            <img src={User} alt="user" height="60px" width="60px" style={{ marginRight: 10 }} />
            <div className="name_section">
              <h5>{props.row.values.name}</h5>
              <h5 style={{ fontWeight: 300 }}>{props.row.original.location.name}</h5>
            </div>
          </div>
          <img src={Close} alt="close" className="close-btn" onClick={props.onOverlayClick} />
        </div>
        <div className="info_section">
          <div className="id_section">
            <h5>ID</h5>
            <h6 style={{ fontWeight: 400 }}>{props.row.values.employeeNumber || 'null'}</h6>
          </div>
          <div>
            <h5>Sharp</h5>
            <h6 style={{ fontWeight: 400 }}>1772391</h6>
          </div>
        </div>
      </div>
      <div className="covid_text">
        <h5>Covid test for patients</h5>
      </div>
      <div className="tests">
        <div className="test_btns_container">
          <OutlineButton
            onClick={() => toggleButton('1')}
            text="Pruebas"
            style={{
              borderRadius: 0,
              borderColor: 'transparent',
              borderBottomColor: toggleCovidButton ? 'transparent' : SUCCESS,
              marginRight: 10,
            }}
            textStyle={{
              fontSize: 13,
              color: toggleCovidButton ? BORDERS : SUCCESS,
            }}
          />
          <OutlineButton
            onClick={() => toggleButton('2')}
            text="Vacunas"
            style={{
              borderRadius: 0,
              borderColor: 'transparent',
              borderBottomColor: !toggleCovidButton ? 'transparent' : SUCCESS,
            }}
            textStyle={{
              fontSize: 13,
              color: !toggleCovidButton ? BORDERS : SUCCESS,
            }}
          />
        </div>
        {[0, 1, 2].map(test => (
          <div className="test">
            <h5 style={{ margin: 0 }}>Test {test}</h5>
            <Calendar onClose={() => {}} excludeLastHour initialState="LAST_DAY" />
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default PCRDateAssign;
