import React, { useMemo, useState, useEffect } from 'react';
import { TextField } from '@mui/material';
import { ACTION_LOG_SUMMARY_MODAL_STATUS, TRANSLATIONS } from 'types/enums';
import './ManagementToolForSummaryModalStyle.scss';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

import { useGetAllManagementToolQuery } from 'generated/graphql';
import { ManagementTool } from 'features/action-logs/createEditActionLog/createEditActionLogModal.state';
import Autocomplete from '@mui/lab/Autocomplete';

type ManagementToolForSummaryModalProps = {
  modalStatus: ACTION_LOG_SUMMARY_MODAL_STATUS;
  managementTool: ManagementTool;
  handleManagementToolChange: (managementTool: ManagementTool) => void;
};
function ManagementToolForSummaryModal(props: ManagementToolForSummaryModalProps) {
  const [managementToolList, setManagementToolList] = useState<ManagementTool[]>();
  const { data } = useGetAllManagementToolQuery();
  const { t } = useTranslation();

  useEffect(() => {
    const structuredManagementToolData: ManagementTool[] = [];
    (data?.getAllManagementTool || []).forEach(managementTool => {
      const label = (i18next.language === 'en' ? managementTool?.enTitle : managementTool?.esTitle) || '';
      const value = managementTool?.id!;
      structuredManagementToolData.push({ label, value });
    });
    setManagementToolList(structuredManagementToolData!);
  }, [data]);

  const managementToolListHash = useMemo(() => {
    const hash: {
      [label: string ]: string
    } = {};

    managementToolList?.forEach(managementToolListItem => {
      if (!hash[managementToolListItem.value]) {
        hash[managementToolListItem.value] = managementToolListItem.label;
      }
    });
    return hash;
  }, [managementToolList]);

  const { modalStatus, managementTool, handleManagementToolChange } = props;
  function onChange(e: ManagementTool) {
    handleManagementToolChange({
      label: managementToolListHash[e?.value] || '' as string,
      value: e?.value || '' as string,
    });
  }

  return modalStatus === ACTION_LOG_SUMMARY_MODAL_STATUS.SUMMARY ? (
    <div className="check">
      <p className="extra_title">{ t(TRANSLATIONS.MANAGEMENT_TOOLS) }</p>
      <p className="extra_sub_title">{managementTool?.label || '-'}</p>
    </div>
  ) : (
    <div className="management-tool-container">
      <div className="check selection_dropdown">
        <p className="extra_title">{ t(TRANSLATIONS.MANAGEMENT_TOOLS)}</p>
        <Autocomplete
          key="managementTool"
          options={managementToolList || []}
          isOptionEqualToValue={(option: any, value: any) => option?.id === value?.id}
          value={managementToolList?.length ? managementToolList
            ?.find(e => String(e?.value) === String(managementTool?.value as any))
            : () => null}
          getOptionLabel={(option: any) => option?.label}
          style={{ minWidth: 164 }}
          renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
              inputProps={{
                ...params.inputProps,
                style: {
                  padding: 0,
                },
              }}
            />
          )}
          disableClearable
          onChange={(event, value: any) => {
            onChange(value);
          }}
        />
      </div>
    </div>
  );
}
export default ManagementToolForSummaryModal;
