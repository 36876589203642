import { Maybe } from 'generated/graphql';

type GetAvatarAlternateUI = {
  name: Maybe<string> | number | undefined;
  color?: string;
  isRandomBackground?: boolean;
};

export default function getAvatarAlternateUI({
  name,
  color = 'fff',
  isRandomBackground,
}: GetAvatarAlternateUI) {
  return encodeURI(`https://ui-avatars.com/api/?name=${name || ''}&color=${color}${isRandomBackground ? '&background=random' : ''}`);
}
