import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import NavigationDrawerWithSideMenu from 'components/NavigationDrawer/NavigationDrawerWithVerticalMenu';
import Header from 'components/Header';
import { selectCurrentAccesLevel } from 'store/selectors/distributionCenters.selectors';
import { setMonthlyDropdownChange } from 'store/actions/timeFilter.actions';
import {
  selectSelectedLocation,
} from 'store/selectors/globalFilters.selectors';
import { selectUserType, selectUserLocation, selectUser } from 'store/selectors/user.selectors';
import { FILTER_ACCESS_LEVELS } from 'types/enums';
import defaultRoutes from 'utils/defaultRoutes';
import { format } from 'date-fns';
import './style.scss';
import TrelloBoard from '../TrelloBoard';
import ExcelDownloadBoard from './ExcelDownloadBoard';

const ActionLogsBoard = () => {
  const accessLevel = useSelector(selectCurrentAccesLevel);
  const { t } = useTranslation();
  const userType = useSelector(selectUserType);
  const location = useSelector(selectUserLocation);
  const dispatch = useDispatch();
  const userDetails = useSelector(selectUser);
  const [currentMonth, setCurrentMonth] = useState(format(new Date(), 'yyyy'));

  const handleMonthChange = (value: string) => {
    setCurrentMonth(value);
    dispatch(setMonthlyDropdownChange(value));
  };
  useEffect(() => {
    dispatch(setMonthlyDropdownChange(format(new Date(), 'yyyy')));
  }, [dispatch]);
  const selectedDC = useSelector(selectSelectedLocation);

  return (
    <>
      <Header />
      <NavigationDrawerWithSideMenu
        {...{
          routes: defaultRoutes(t, userType, location, userDetails),
          openDrawer: true,
        }}
      />
      {accessLevel === FILTER_ACCESS_LEVELS.DISTRIBUTION_CENTER && selectedDC.locationSlug ? (
        <TrelloBoard
          locationSlug={selectedDC.locationSlug}
          locationName={selectedDC.locationName}
          handleMonthChange={handleMonthChange}
          currentMonth={currentMonth}
        />
      ) : (
        <ExcelDownloadBoard handleMonthChange={handleMonthChange} currentMonth={currentMonth} />
      )}
    </>
  );
};

export default ActionLogsBoard;
