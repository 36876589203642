import React from 'react';
import './ChartTabs.scss';

type TabItem = {
  title: string,
  value?: string,
};
type Props = {
  tabsItems: TabItem[],
  setSelectedTab: (item: TabItem)=>void;
  selectedTab: TabItem,
  selectedTabStyle?: any,
  customStyle?: any,
};

const ChartTabs = (props: Props) => {
  const { tabsItems, setSelectedTab, selectedTab, selectedTabStyle, customStyle } = props;
  return (
    <div className={`chart-tabs ${customStyle}`}>
      {tabsItems.map(
        (item => (
          <div
            key={`${item.title}-${item.value}`}
            role="button"
            onClick={() => {
              setSelectedTab(item);
            }}
            className={`tab-item ${item.title === selectedTab.title ? selectedTabStyle ?? 'tab-item-selected' : ''}`}
          >
            <p className="tab-title">
              {item.title}
            </p>
            <p className="tab-value">
              {item.value}
            </p>
          </div>
        )),
      )}
    </div>
  );
};

export default ChartTabs;
