/* eslint-disable no-undef */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TRANSLATIONS } from 'types/enums';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUserType } from 'store/selectors/user.selectors';

import { userOnlyHasAccessToSearchUserScreen } from 'utils/shared/canUserAccessFeature';
import ImageLinks from '../../utils/ImageLinks';
import LinkDropdown from '../Dropdown/LinkDropdown';

import { ReactComponent as LogoutIcon } from '../../assets/icons/log-out.svg';
import { ReactComponent as NutIcon } from '../../assets/icons/nut-icon.svg';

import './MainNavigationHeader.scss';

const SafetyLogo = ImageLinks.iconShield;

type Props = {
  configRoutes?: any;
  userName?: string;
  goBack?: () => void;
  nutIconOnly?: boolean;
  distCenter?: string;
};

const MainNavigationHeader = (props: Props) => {
  const userType = useSelector(selectUserType);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { configRoutes, goBack } = props;
  const backToDistCenters = () => {
    if (goBack) {
      goBack();
      return;
    }
    navigate('/distribution-centers');
  };

  return (
    <div className="main-navigation-header">
      <div className="container-fluid">
        <div className="row">
          <div className="content-container left-content">
            {(!userOnlyHasAccessToSearchUserScreen(userType)) ? (
              <div onClick={backToDistCenters} role="button" className="back-container">
                <img src={ImageLinks.chevronLeft} alt="" className="back-icon" />
                <p>{t(TRANSLATIONS.BACK_TO)} {t(TRANSLATIONS.DIST_CENTERS)}</p>
              </div>
            )
              : (<div className="back-container-empty"> </div>
              )}
          </div>
          <div className="col-md-4 col-12 content-container logo-section">
            <img src={SafetyLogo} alt="safety-logo" />
            <p className="title">SAFETY 360</p>
          </div>
          <div className={`dropdown-container ${props.nutIconOnly ? 'reposition-popup' : ''}`}>
            <LinkDropdown
              dropdownContent={
                configRoutes || [
                  {
                    url: '/logout',
                    label: (
                      /* use span since it's going to be inside of a p tag. */
                      <span className="logout-text">
                        {t(TRANSLATIONS.EXIT)}
                        <LogoutIcon className="logout-icon" />
                      </span>
                    ),
                  },
                ]
              }
              className="logout-dropdown"
              dropdownContentPositioning={{ right: -10 }}
            >
              <div className="setting-section">
                <NutIcon />
                {props.nutIconOnly ? '' : <h3 className="d-md-block d-none">{t(TRANSLATIONS.CD_SETTINGS, { location: props.distCenter })}</h3>}
              </div>
            </LinkDropdown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNavigationHeader;
