import EmptyState from 'components/EmptyState/EmptyState';
import LoadingSpinner from 'components/LoadingSpinner';
import { useGetSifStatsByTypeQuery } from 'generated/graphql';
import React from 'react';
import Chart from 'react-apexcharts';
import { capitalizeFirst } from 'utils/stringHelpers';

type Props = {
  xCategories?: any[];
  colors?: string[];
  xLabel?: string;
  yLabel?: string;
  filter: any,
  year: number,

};

enum SIFTypes {
  SIF_POTENTIAL = 1,
  FLAGGED = 3,
  SIF_PRECURSOR = 0,
  SIF_ACTUAL = 2,
}

function IncidentHistogram(props: Props) {
  const {
    colors = ['#2B323B', '#2574FB', '#E92B36', '#56CD9D'],
    xLabel = '',
    yLabel = '',
    filter,
    year,
  } = props;
  const series: { name: string, data: number[] }[] = [
    {
      name: 'Precursores',
      data: [],
    },
    {
      name: 'Potenciales',
      data: [],
    },
    {
      name: 'Actuales',
      data: [],
    },
    {
      name: 'Marcados',
      data: [],
    },
  ];

  const months: string[] = [];

  const { data, error, loading } = useGetSifStatsByTypeQuery({
    variables: {
      filter,
      year,
    },
  });

  data?.getSifStatsByType.forEach((item, index) => {
    months.push(item?.month || '');
    series[0].data.push(0);
    series[1].data.push(0);
    series[2].data.push(0);
    series[3].data.push(0);
    item?.sifType.forEach(sifItem => {
      // As Flagged to bypass typscript error since sifItem.name is of type string
      // sifItem.name may contain more keys than the 4 defiened in enum
      if (sifItem?.name && SIFTypes[sifItem.name as 'FLAGGED'] !== undefined) {
        series[SIFTypes[sifItem.name as 'FLAGGED']].data[index] = (sifItem?.totalSif || 0);
      }
    });
  });

  const options = {
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    colors,
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
          position: 'bottom',
          offsetX: -10,
          offsetY: 0,
        },
      },
    }],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10,
      },
    },
    xaxis: {
      categories: months,
      title: {
        text: xLabel,
      },
      labels: {
        show: true,
        formatter: (val: string) => {
          return capitalizeFirst(val);
        },
      },
    },
    yaxis: {
      title: {
        text: yLabel,
      },

    },
    legend: {
      position: 'top',
      offsetX: 40,
    },

  };
  if (error) return <h5> Something Went Wrong. Please Try Again </h5>;
  if (loading) return <LoadingSpinner />;
  if (data?.getSifStatsByType.length === 0) return <div className="mt-4"> <EmptyState /> </div>;
  // @ts-ignore
  return (<Chart height={350} series={series} options={options} type="bar" />);
}

export default IncidentHistogram;
