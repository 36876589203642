import React from 'react';
import DayPicker from 'react-day-picker';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';
import useComponentVisible from 'utils/hooks/useComponentVisible';
import ImageLinks from 'utils/ImageLinks';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/en-gb';
import 'moment/locale/es';
import './DueDatePicker.scss';
import i18next from 'i18n';

const DateLocale = i18next.language?.indexOf('en') === 0 ? 'en-gb' : 'es';

type Props = {
  selectedDueDate: Date | undefined;
  handleSelectDueDate: Function;
};

type CallBackFunction = (callback?: (() => void) | undefined) => void;

function DueDatePicker(props: Props) {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const { selectedDueDate, handleSelectDueDate } = props;
  const { t } = useTranslation();
  const toggleDropdown = () => {
    setIsComponentVisible(!isComponentVisible);
  };
  function onEndDateFilterChange(date: any) {
    if (date === selectedDueDate) {
      handleSelectDueDate(undefined);
    } else {
      handleSelectDueDate(date);
    }
  }
  function NavBarElement(
    {
      onNextClick, onPreviousClick,
    } : { onPreviousClick: CallBackFunction, onNextClick: CallBackFunction },
  ) {
    return (
      <div className="day-picker-nav-bar">
        <div className="navbar-element-container">
          <img
            onClick={() => onPreviousClick()}
            src={ImageLinks.leftMaterial}
            className="img-button"
            alt="previous month"
          />
          <img
            onClick={() => onNextClick()}
            src={ImageLinks.rightMaterial}
            className="img-button"
            alt="next month"
          />
        </div>
      </div>
    );
  }
  return (
    <div className="filter-on-click-dropdown">
      <div className="filter-dropdown-container" ref={ref}>
        <div className="filter-header" onClick={toggleDropdown} role="button">
          {selectedDueDate
            ? (
              <div className="due-date-selected">
                <img src={ImageLinks.calendar} alt="calendar" />
                <p>
                  {`${selectedDueDate.getMonth() + 1}/${selectedDueDate.getDate()}/${selectedDueDate.getFullYear()}`}
                </p>
              </div>
            )
            : (
              <p>{t(TRANSLATIONS.NO_DUE_DATE_SELECTED)}</p>
            )}
        </div>
        {isComponentVisible && (
          <div
            className="dropdown-content"
            style={{
              display: `${!isComponentVisible ? 'none' : 'block'}`,
            }}
          >
            <DayPicker
              selectedDays={selectedDueDate}
              onDayClick={onEndDateFilterChange}
              navbarElement={NavBarElement}
              localeUtils={MomentLocaleUtils}
              locale={DateLocale}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default DueDatePicker;
