import { KPI } from '../KPIsListing/KPIsListing';
import { Routine } from '../RoutinesListing/RoutinesListing';
import { SearchedUser } from '../UsersListing/UserListing';

export enum ActionLogFilterStates {
  ROUTINE = 'ROUTINE',
  KPIS = 'KPIS',
  SEARCHED_USERS = 'SEARCHED_USERS',
  DUE_DATE = 'DUE_DATE',
  DELAY_TASK = 'DELAY_TASK',
}

export type ActionLogFilterStatesType = {
  [ActionLogFilterStates.ROUTINE]: Routine,
  [ActionLogFilterStates.KPIS]: KPI[],
  [ActionLogFilterStates.SEARCHED_USERS]: SearchedUser[],
  [ActionLogFilterStates.DUE_DATE]: Date | undefined,
  [ActionLogFilterStates.DELAY_TASK]: boolean,
};

export const initialActionLogFiltersState: ActionLogFilterStatesType = {
  [ActionLogFilterStates.ROUTINE]: { label: '', value: '' },
  [ActionLogFilterStates.KPIS]: [],
  [ActionLogFilterStates.SEARCHED_USERS]: [],
  [ActionLogFilterStates.DUE_DATE]: undefined,
  [ActionLogFilterStates.DELAY_TASK]: false,
};

export const reducerActionLogFilters = (
  state: ActionLogFilterStatesType,
  action: { type: ActionLogFilterStates | 'RESET'; payload: any },
) => {
  if (action.type === 'RESET') {
    return action.payload || initialActionLogFiltersState;
  }
  return { ...state, [action.type]: action.payload };
};
