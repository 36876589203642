import React, { useEffect, useState } from 'react';
import LoadingSpinner from 'components/LoadingSpinner';
import EmptyState from 'components/EmptyState/EmptyState';
import { sum } from 'lodash';
import CapexBars from './CapexChart';
import CapexDonut from './CapexDonut';
import getCapexApi from './CapexApi/GetCapexApi';
import './Capex.scss';

type BudgetData = {
  totalBudget: number;
  usedBudget: number;
  usedBudgetPercent: number;
  avgImplementation: number;
};
const CapexPorBu = (props: { type: string; }) => {
  const [countries, setCountries] = useState<string[]>(['']);
  const [usedBudget, setUsedBudget] = useState<number[]>([]);
  const [totalBudget, setTotalBudget] = useState<number[]>([]);
  const [differenceBudget, setDifferenceBudget] = useState<number[]>([]);
  const [pourcentageBudget, setPourcentageBudget] = useState<number>(0);
  const [isLoading, setIsloading] = useState<boolean>(false);

  useEffect(() => {
    const budget: number[] = [];
    const usedAmount: number[] = [];
    const difference: number[] = [];
    const getCapexPorBu = async () => {
      try {
        setIsloading(true);
        const url: string = '/api/v1/capex-by-bu';
        const params: string = props.type;
        const response: BudgetData[] = await getCapexApi(url, params);
        Object.entries(response).forEach(Obj => {
          const ObjData: BudgetData = Obj[1] as BudgetData;
          budget.push(props.type === 'byBudget' ? ObjData.totalBudget : 100);
          usedAmount.push(props.type === 'byBudget' ? ObjData.usedBudget : ObjData.avgImplementation);
          difference.push(props.type === 'byBudget' ? ObjData.totalBudget - ObjData.usedBudget : 100 - ObjData.avgImplementation);
        });
        setUsedBudget(usedAmount);
        setTotalBudget(budget);
        setDifferenceBudget(difference);
        setCountries(Object.keys(response));
        setPourcentageBudget((sum(usedAmount) / sum(budget)) * 100);
      } catch (error) {
        console.log('getCapexPorBu e()', error);
      } finally {
        setIsloading(false);
      }
    };
    getCapexPorBu();
  }, [props.type]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="row container-fluid">
      <div className="col-12 routines-home__card">
        <span className="routines-home__card__title">MAZ</span>
        {usedBudget.length
          ? (
            <div className="row container-fluid">
              <div className="col-8">
                <CapexBars
                  countries={countries}
                  usedBudget={usedBudget}
                  differenceBudget={differenceBudget}
                  type={props.type}
                  max={props.type === 'byProject' ? 100 : undefined}
                />
              </div>
              <div className="display-row">
                <div className="capex-donut">
                  <CapexDonut used={Number(pourcentageBudget.toFixed(2))} />
                </div>
                {props.type === 'byBudget'
                  && (
                    <div className="capex-subinfo-card">
                      <div className="subinfo-container">
                        <div className="font-weight700 font-size16"> Ejercido </div>
                        <div className="font-weight400 font-size16 capex-donut-subinfo">  $ {sum(usedBudget).toLocaleString()} </div>
                      </div>
                      <div className="subinfo-container">
                        <div className="font-weight700 font-size16"> Presupuesto </div>
                        <div className="font-weight400 font-size16 capex-donut-subinfo">   $ {sum(totalBudget).toLocaleString()}  </div>
                      </div>
                      <div className="subinfo-container">
                        <div className="font-weight700 font-size16"> % Ejercido </div>
                        <div className="font-weight400 font-size16 capex-donut-subinfo">  {Math.ceil(pourcentageBudget)}% </div>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          )
          : (
            <EmptyState />
          )}
      </div>
    </div>
  );
};

export default CapexPorBu;
