import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TRANSLATIONS, COMMENT_CREATE_TYPE } from 'types/enums';
import getAvatarAlternateUI from 'utils/avatarAlternateUi';
import CommentInput from './CommentInput';

type CommentCreateProps = {
  type: COMMENT_CREATE_TYPE;
  customContext: any;
  createEditMuation: Function;
  refetchQueries: Function;
  dynamicKeyName: string;
};

export default function CommentCreate(props: CommentCreateProps) {
  const loggedInUser = useSelector((state: any) => state.get('user'))?.toJS();
  const commonContext = props.customContext();
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef<HTMLSpanElement>(null);
  const { t } = useTranslation();

  const [createComment, createCommentState] = props.createEditMuation();
  function onCancel() {
    if (inputRef.current) {
      inputRef.current.innerText = '';
      inputRef.current.blur();
    }
    setIsEditing(prevState => !prevState);
  }
  async function handleSendComment() {
    const newComment = inputRef.current?.innerText;
    if (!newComment) return;

    try {
      await createComment({
        variables: {
          [props.dynamicKeyName]: commonContext.id,
          comment: newComment,
        },
        refetchQueries: props.refetchQueries(commonContext.id),
        awaitRefetchQueries: true,
      });
      onCancel();
    } catch (e) {
      if (inputRef.current) {
        inputRef.current.innerText = newComment;
      }
    }
  }

  function onFocus() {
    setIsEditing(prevState => !prevState);
  }

  return (
    <div className="comment-container">
      <div className="user_profile">
        <img
          className="user_profile__image"
          src={loggedInUser?.picture || getAvatarAlternateUI({
            name: loggedInUser?.name,
            isRandomBackground: true,
          })}
          alt={loggedInUser?.name || ''}
        />
      </div>
      <div className="comment-input-container">
        {isEditing ? (
          <CommentInput
            comment=""
            inputRef={inputRef}
            isLoading={createCommentState.loading}
            onSave={handleSendComment}
            onCancel={onCancel}
          />
        ) : (
          <input placeholder={`${t(TRANSLATIONS.ADD_COMMENT)}...`} className="actionlog-comment-textarea" onFocus={onFocus} />
        )}
      </div>
    </div>
  );
}
