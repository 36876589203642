import { useAsync } from 'react-async-hook';
import { useSelector } from 'react-redux';
import { selectCurrentAccesLevel } from 'store/selectors/distributionCenters.selectors';
import { selectLocationsToAccess } from 'store/selectors/globalFilters.selectors';
import { FILTER_ACCESS_LEVELS } from 'types/enums';
import request from 'utils/request';

export enum UsersPositionsError {
  SELECT_A_SUBZONE = 'SELECT_A_SUBZONE',
}

export const getUsersPositions = async (
  locationsToAccess: number[],
  accessLevel: FILTER_ACCESS_LEVELS,
) => {
  if (
    !(
      accessLevel === FILTER_ACCESS_LEVELS.SUBZONE
             || accessLevel === FILTER_ACCESS_LEVELS.DISTRIBUTION_CENTER
    )
  ) {
    throw new Error(UsersPositionsError.SELECT_A_SUBZONE);
  }

  const uniquePositions: string[] = await request.post(
    '/api/v1/users/all-unique-positions',
    {
      locationsToAccess,
    },
  );
  // sort in alphabetical order
  const UsersPositions = uniquePositions
    .sort((a, b) => (a === b ? 0 : a < b ? -1 : 1))
    .map((positions: string) => ({
      label: positions,
      slug: positions,
    }));

  return UsersPositions;
};

export const useGetUsersPositions = () => {
  const locationsToAccess = useSelector(selectLocationsToAccess);
  const accessLevel = useSelector(selectCurrentAccesLevel);
  return useAsync(getUsersPositions, [locationsToAccess, accessLevel]);
};
