import request from 'utils/request';

const postCapexApi = async (url: string, popupName: string, popupCode: string) => {
  try {
    const params = {
      projectName: popupName,
      projectCode: popupCode,
    };
    const res = await request.post(url, params);
    return (res);
  } catch (error) {
    console.log('getCapexAPIPopup e()', error);
  }
};

export default postCapexApi;
