import React from 'react';

import './LoadingSpinner.scss';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';

type Props = {
  height?: number;
  positionTop?: number;
  text?: string;
  style?: React.CSSProperties;
  textStyle?: React.CSSProperties;
  className?: string;
};

const LoadingSpinner = (props: Props) => {
  const {
    height,
    positionTop,
    text,
    style = {},
    textStyle = {},
    className = '',
  } = props;

  const { t } = useTranslation();
  return (
    <div
      className={`loading-spinner ${className}`}
      style={{
        height: height || 0,
        paddingTop: (height ? ((height / 2) - 12) : 0),
        top: (positionTop || 0),
        position: (positionTop ? 'relative' : 'static'),
        ...style,
      }}
    >
      <div className="spinner" />
      <p style={textStyle}>{ text || t(TRANSLATIONS.LOADING) }</p>
    </div>
  );
};

export default LoadingSpinner;
