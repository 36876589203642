import React, { useEffect, useMemo, useState } from 'react';
// @ts-ignore
import ReactTable from 'react-table-legacy';
import EmptyState from 'components/EmptyState/EmptyState';
import Modal from 'components/Modal';
import ImageLinks from 'utils/ImageLinks';
import { useSelector } from 'react-redux';
import { selectSelectedLocationsSlug } from 'store/selectors/globalFilters.selectors';
import LoadingSpinner from 'components/LoadingSpinner';
import './SafetyTraining.scss';
import { GetTrainingsBy, useGetTrainingsByPositionLazyQuery, useGetTrainingsByPositionQuery } from 'generated/graphql';

type Props = {
  searchField?: string;
};

type PositionData = {
  Position: string;
  Total: number;
  Capacity: number;
  Avance: string;
};
const SafetyTrainingTable = (props: Props) => {
  const { searchField } = props;
  const [popup, setPopup] = useState<boolean>(false);
  const [findTrainingPosition, setFindTrainingPosition] = useState<string>('');
  const [headerData, setHeaderData] = useState<PositionData | undefined>();
  const selectedLocations = useSelector(selectSelectedLocationsSlug);
  const [getTrainingByPosition, trainingByPositionData] = useGetTrainingsByPositionLazyQuery();
  const trainingDataByAllPosition = useGetTrainingsByPositionQuery({
    variables: {
      data: {
        bu: selectedLocations?.businessUnit || '',
        region: selectedLocations?.zone || '',
        zone: selectedLocations?.subzone || '',
        location: selectedLocations?.location || '',
      },
      by: GetTrainingsBy.Position,
    },
  });

  const regionalData: PositionData[] = [];
  const { data } = trainingDataByAllPosition;
  let filteredRegionalData;
  if (data) {
    data.getTrainings.forEach((row: any) => {
      regionalData.push({
        Position: row.position,
        Total: row.usersToTrain,
        Capacity: row.usersTrained,
        Avance: row.percentage,
      });
    });
  }

  if (!!regionalData && searchField) {
    filteredRegionalData = regionalData.filter(
      (rowData: PositionData) => rowData.Position.toLowerCase().includes(searchField.toLowerCase()),
    );
  }
  const OpenModal = (row: PositionData | any) => {
    setPopup(true);
    if (row.Position) {
      setFindTrainingPosition(row.Position);
      setHeaderData(row);
    }
    const ClosePopup = () => {
      setPopup(false);
    };
    return (
      <Modal>
        {trainingByPositionData.error ? <div> Something Went Wrong </div>
          : trainingByPositionData.loading ? <LoadingSpinner />
            : (
              <div>
                <div className="capex-close" onClick={ClosePopup} role="button">&times;</div>
                <div className="capex-popup-header">
                  {headerData && (
                    <div className="display-flex popup-header">
                      <div>
                        <p className=""> Puesto </p>
                        <p className=""> {headerData.Position} </p>
                      </div>
                      <div>
                        <p className=""> Capacitaciones Totales </p>
                        <p className=""> {headerData.Total} </p>
                      </div>
                      <div>
                        <p className=""> Capacitaciones Actuales </p>
                        <p className=""> {headerData.Capacity} </p>
                      </div>
                      <div>
                        <p className=" "> Avance </p>
                        <p className=""> {headerData.Avance} </p>
                      </div>
                    </div>
                  )}
                </div>
                {trainingByPositionData.data
                  && trainingByPositionData.data.getTrainings.map((e: any) => (
                    <div>
                      <div className="capex-popup-details">
                        <p className=""> {e?.name} </p>
                        <p className="font-size14 capex-popup-second-color"> % de avance actual: <b>{e?.percentage}</b>  </p>
                      </div>
                      <div className="training-popup-bar">
                        <div className="training-popup-progress-bar" style={{ width: e?.percentage }}>
                          {e?.usersTrained} capacitados
                        </div>
                      </div>
                      <div className="training-flex-end"> Total:  {e?.usersToTrain}</div>
                    </div>
                  ))}
              </div>
            )}
      </Modal>
    );
  };
  const columns = useMemo(() => [
    {
      Header: 'Puesto',
      headerStyle: { fontWeight: 'bold' },
      minWidth: 110,
      id: 'Position',
      accessor: 'Position',
      Cell: (row: any) => (
        <p className="text-left margin-left-25 font-normal-normal">
          {row.value}
        </p>
      ),
    },
    {
      Header: 'Capacitaciones Totales',
      headerStyle: { fontWeight: 'bold' },
      minWidth: 110,
      id: 'Total',
      accessor: 'Total',
      Cell: (row: any) => (
        <p className="text-left margin-left-25 font-normal-normal">
          {row.value.toLocaleString()}
        </p>
      ),
    },
    {
      Header: 'Capacitaciones Actuales',
      headerStyle: { fontWeight: 'bold' },
      id: 'Capacity',
      accessor: 'Capacity',
      minWidth: 95,
      Cell: (row: any) => (
        <p className="text-left fontWeight200 margin-left-25">
          {row.value.toLocaleString()}
        </p>
      ),
    },
    {
      Header: 'Avance',
      headerStyle: { fontWeight: 'bold' },
      id: 'Avance',
      accessor: 'Avance',
      Cell: (row: any) => (
        <p className="text-center cell-two-items">
          <div className="text-center"> {`${row.value}`} </div>
          {selectedLocations.subzone && <img className="cell-chevron" src={ImageLinks.chevron_right} alt="upload file" onClick={() => OpenModal(row.original)} />}
        </p>
      ),
    },
    // eslint-disable-next-line
  ], [selectedLocations]);

  useEffect(() => {
    trainingDataByAllPosition.refetch();
    // eslint-disable-next-line
  }, [
    selectedLocations.businessUnit,
    selectedLocations.zone,
    selectedLocations.subzone,
    selectedLocations.location]);

  useEffect(() => {
    getTrainingByPosition({
      variables: {
        data: {
          position: findTrainingPosition,
          bu: selectedLocations.businessUnit,
          region: selectedLocations.zone,
          zone: selectedLocations.subzone,
          location: selectedLocations.location,
        },
        by: GetTrainingsBy.Position,
        isFindOne: true,
      },
    });
    // eslint-disable-next-line
  }, [findTrainingPosition]);

  return (
    <div className="table-by-regional-manager">
      {popup && <OpenModal />}
      {trainingDataByAllPosition.error ? <div> Something Went Wrong </div>
        : trainingDataByAllPosition.loading ? <LoadingSpinner /> : regionalData.length
          ? (
            <ReactTable
              data={filteredRegionalData || regionalData}
              columns={columns}
              showPagination={false}
              defaultPageSize={regionalData?.length}
              className="-striped max-height"
            />
          )
          : <EmptyState />}
    </div>
  );
};
export default SafetyTrainingTable;
