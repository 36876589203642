import request from '../../utils/request';
import { getTimeFiltersFromState } from '../../utils/dateHelpers';

export const SET_ZONES_WITH_INCIDENTS = 'corporate-dashboard/set-zones-with-incidents';
export const DOWNLOADING_INCIDENTS_XLS = 'safety-dashboard/downloading_incidents_xls';

export function setZonesWithIncidents(zones: any) {
  return { type: SET_ZONES_WITH_INCIDENTS, zones };
}

export function getZonesWithIncidents() {
  return async (dispatch: any, getState: any) => {
    try {
      const url = '/api/v1/corporate/zones-with-incidents';
      const timeFilters = getTimeFiltersFromState(getState());
      const response = await request.get(url, timeFilters);
      dispatch(setZonesWithIncidents(response));
    } catch (e) {
      console.log('e getZonesWithIncidents(): ', e);
      throw e;
    }
  };
}
