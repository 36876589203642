import FilterDropdown from 'components/Dropdown/FilterDropdown';
import { useSearchedUsersWithAccessToLocationLazyQuery } from 'generated/graphql';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';
import getAvatarAlternateUI from 'utils/avatarAlternateUi';
import ImageLinks from 'utils/ImageLinks';
import './UserListing.scss';

export type SearchedUser = {
  label: string;
  value: string;
};

type Props = {
  locationSlug: string;
  selectedUsers: SearchedUser[];
  setSelectedUsers: Function;
};

const UsersListing = (props: Props) => {
  const { t } = useTranslation();
  const [userList, setUserList] = useState<SearchedUser[]>([]);
  const [searchedText, setSearchedText] = useState<string>();
  const [showSelectedUsers, setShowSelectedUsers] = useState(true);
  const { selectedUsers, setSelectedUsers } = props;
  const [getSearchedUsers, searchedUsersResponse] = useSearchedUsersWithAccessToLocationLazyQuery();

  function selectOrDeselectUser(selectedUser: SearchedUser) {
    let selected = false;
    selectedUsers.forEach(user => {
      if (selectedUser.label === user.label) selected = true;
    });
    if (selected) {
      const temporarySelectedUser = selectedUsers?.filter(
        userFromSelectedUsers => userFromSelectedUsers.label !== selectedUser.label,
      );
      setSelectedUsers(temporarySelectedUser);
    } else {
      setSelectedUsers([...selectedUsers, selectedUser]);
    }
    setShowSelectedUsers(true);
  }

  function handleSearch(text: string) {
    setShowSelectedUsers(false);
    const trimmedText = text.trim();
    if (trimmedText.length) {
      setSearchedText(trimmedText);
    } else {
      setShowSelectedUsers(true);
    }
  }

  useEffect(() => {
    const structuredUsersFromData: SearchedUser[] = [];
    (searchedUsersResponse?.data?.searchUsersWithAccessToLocation || []).forEach(searchedUser => {
      const label = searchedUser?.name || '';
      const value = searchedUser?.slug!;
      structuredUsersFromData.push({ label, value });
    });
    setUserList(structuredUsersFromData);
    // eslint-disable-next-line
  }, [searchedUsersResponse?.data]);

  useEffect(() => {
    if (searchedText && searchedText !== '') {
      getSearchedUsers({
        variables: {
          searchString: searchedText,
          locationSlug: props.locationSlug,
        },
      });
    }
  }, [getSearchedUsers, props.locationSlug, searchedText]);

  function ListItem(listItem: SearchedUser) {
    let selected = false;
    selectedUsers.forEach(selectedUser => {
      if (selectedUser.label === listItem.label) selected = true;
    });
    return (
      <div className="searched-users" role="button" onClick={() => selectOrDeselectUser(listItem)}>
        <div className="user_profile">
          <img
            className="user_profile__image"
            src={getAvatarAlternateUI({ name: listItem.label, isRandomBackground: true })}
            alt={listItem.label || ''}
          />
          <p>{listItem.label}</p>
        </div>
        <div className="user-action">
          <img
            src={
              !showSelectedUsers
                ? selected
                  ? ImageLinks.checked
                  : ImageLinks.add
                : ImageLinks.remove
            }
            alt="add remove check"
          />
        </div>
      </div>
    );
  }

  function ListHeader() {
    return (
      <div className="filter-headers">
        <h2>Usuarios</h2>
        <p>{t(TRANSLATIONS.SELECT_THE_USERS_YOU_WANT_TO_SEE)}</p>
      </div>
    );
  }

  function ListEmptyComponent() {
    if (!showSelectedUsers) {
      return (<p>{t(TRANSLATIONS.NO_RESULTS_FOUND)}</p>);
    }
    return <></>;
  }

  return (
    <div>
      <FilterDropdown
        filterName={selectedUsers.length ? `${selectedUsers[0].label}${(selectedUsers.length - 1) ? ` + ${selectedUsers.length - 1}` : ''}` : t(TRANSLATIONS.ALL_USERS)}
        ListHeaderComponent={ListHeader}
        ListItem={ListItem}
        listToRender={showSelectedUsers ? selectedUsers : userList}
        selectedItems={selectedUsers}
        searchBarPlaceholder={t(TRANSLATIONS.SEARCH_USERS)}
        handleSearch={handleSearch}
        EmptyList={ListEmptyComponent}
      />
    </div>
  );
};

export default UsersListing;
