import { useAsync } from 'react-async-hook';
import { generateQueryDates } from 'utils/dateHelpers';
import request from '../../../../utils/request';

const getConsequenceCountsGroupByLocation = async (startTime: Date, endTime: Date) => {
  const url = '/api/v1/consequence/count';
  const timeFilters = generateQueryDates(
    startTime,
    endTime,
  );
  const response = await request.get(url, timeFilters);
  return response;
};

const useGetConsequenceCountsGroupByLocation = ({
  startTime,
  endTime,
}:{
  startTime: Date;
  endTime: Date;
}) => {
  return useAsync(getConsequenceCountsGroupByLocation, [startTime, endTime]);
};

export default useGetConsequenceCountsGroupByLocation;
