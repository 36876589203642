import ChartDropdown from 'components/Dropdown/ChartDropdown';
import { GetSifStatsBy } from 'generated/graphql';
import React, { useState } from 'react';
import { TypeLabelSlugArray } from 'types/types';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';
import HorizontalBarByMonth from './HorizontalBarByMonth/HorizontalBarByMonth';
import IncidentHistogram from './IncidentHistogram';
import LineAndColumnChart from './LineAndColumnChart';
import SimpleBarGraph from './SimpleBarGraph';
import StatisticsSIFTable from './StatisticsSIFTable';
import { ReactComponent as RefreshIcon } from '../../../assets/icons/refresh.svg';

import './StatisticsSIFStyle.scss';

enum Filters {
  BU = 'BU',
  CAUSE = 'CAUSE',
  NATURE = 'NATURE',
  REGION = 'REGION',
  YEAR = 'YEAR',
}

const chartsVertical = [
  { title: 'Análisis Pareto de Clasificiación de lesión', by: GetSifStatsBy.Classification, label: 'Clasificación' },
  { title: 'Análisis Pareto de Causas (lo que pasó)', by: GetSifStatsBy.Cause, label: 'Causas' },
  { title: 'Análisis Pareto de naturaleza de la lesión o enfermedad', by: GetSifStatsBy.Nature, label: 'Causas con consecuencia' },
];

const sortAlphabeticallyByLabel = (word1:any, word2:any) => {
  const nameA = word1.label.toUpperCase(); // ignore upper and lowercase
  const nameB = word2.label.toUpperCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  // names must be equal
  return 0;
};

const getLatestYearsAndCurrentYear = (baseYear:number) => {
  const localDate: Date = new Date();
  const currentYear: number = localDate.getUTCFullYear();
  const years: any[] = [];

  for (let i = currentYear; i >= baseYear; i--) {
    const currentYearToString: string = i.toString();

    years.push({
      label: currentYearToString,
      slug: currentYearToString,
    });
  }

  return years;
};

function StatisticsSIF() {
  const [causesAndNatureFilter, setCausesAndNatureFilter] = useState<
  { cause: TypeLabelSlugArray, nature: TypeLabelSlugArray }>({ cause: [], nature: [] });
  const [dropdown, setDropDown] = useState('');

  const dropDownItems = {
    BU: [
      { label: 'All BU', slug: '' },
      { label: 'Mexico', slug: 'mexico' },
      { label: 'Perú', slug: 'peru' },
      { label: 'Ecuador', slug: 'ecuador' },
      { label: 'Colombia', slug: 'colombia' },
      { label: 'CAC', slug: 'cac' },
    ],
    MONTHS: [
      { label: 'Enero', slug: '1' },
      { label: 'Febrero', slug: '2' },
      { label: 'Marzo', slug: '3' },
      { label: 'Abril', slug: '4' },
      { label: 'Mayo', slug: '5' },
      { label: 'Junio', slug: '6' },
      { label: 'Julio', slug: '7' },
      { label: 'Agosto', slug: '8' },
      { label: 'Septiembre', slug: '9' },
      { label: 'Octubre', slug: '10' },
      { label: 'Noviembre', slug: '11' },
      { label: 'Diciembre', slug: '12' },
    ],
    REGION: [
      { label: 'Region', slug: '' },
      { label: 'T1', slug: 'T1' },
      { label: 'T2', slug: 'T2' },
      { label: 'Warehouse', slug: 'Warehouse' },
    ],
    YEAR: getLatestYearsAndCurrentYear(2021),
    CAUSE: [{ label: 'Causa', slug: '' }, ...causesAndNatureFilter.cause.sort(sortAlphabeticallyByLabel)],
    NATURE: [{ label: 'Naturaleza', slug: '' }, ...causesAndNatureFilter.nature.sort(sortAlphabeticallyByLabel)],
  };

  const initialStateFilter = {
    BU: dropDownItems.BU[0],
    CAUSE: dropDownItems.CAUSE[0],
    NATURE: dropDownItems.NATURE[0],
    REGION: dropDownItems.REGION[0],
    YEAR: dropDownItems.YEAR[0],
  };

  const [selectedMonth, setSelectedMonth] = useState(dropDownItems.MONTHS[0]);
  const [filters, setFilters] = useState(initialStateFilter);
  const [clearFilters, setClearFilters] = useState(false);
  const { t } = useTranslation();

  const handleDropDown = (val: string) => {
    if (val === dropdown) {
      setDropDown('');
    } else {
      setDropDown(val);
    }
  };

  const handleClickClearFilters = () => {
    setCausesAndNatureFilter({ cause: [], nature: [] });
    setFilters(initialStateFilter);
    setClearFilters(false);
  };

  const findArrayItemOnSlug = (slug: string, array: TypeLabelSlugArray) => {
    return array.find(item => item.slug === slug) || array[0];
  };

  const reducerFilter = (action: { type: any; value: any; }) => {
    if (action.type === Filters.YEAR) { setCausesAndNatureFilter({ cause: [], nature: [] }); }
    if (action.type === Filters.REGION) { setCausesAndNatureFilter({ cause: [], nature: [] }); }
    if (action.type === Filters.BU) { setCausesAndNatureFilter({ cause: [], nature: [] }); }

    Object.values(Filters).forEach(filter => {
      const filterItem = { ...filters };
      filterItem[filter] = findArrayItemOnSlug(action.value, dropDownItems[filter]);
      if (action.type === filter) {
        setFilters(filterItem);
        setClearFilters(true);
      }
    });
  };

  const chartsHorizontal = [
    { title: `SIFs en ${filters[Filters.BU].slug || 'All BU'} por mes`, by: GetSifStatsBy.Month },
    { title: `SIFs en ${filters[Filters.BU].slug || 'All BU'} por día de la semana`, by: GetSifStatsBy.Weekday },
    { title: `SIFs en ${filters[Filters.BU].slug || 'All BU'} por CD`, by: GetSifStatsBy.Location },
  ];
  function dispatch(args: any) {
    handleDropDown('');
    reducerFilter(args);
  }

  const filterObject = {
    bu: filters[Filters.BU].slug,
    cause: filters[Filters.CAUSE].slug,
    nature: filters[Filters.NATURE].slug,
    region: filters[Filters.REGION].slug,
  };

  function renderSifStatFilters() {
    return (
      <div className="drop_down_section stick-top">
        {Object.values(Filters).map(filter => {
          return (
            <ChartDropdown
              key={filter}
              onChange={event => {
                dispatch({ type: filter, value: event.key });
              }}
              selectedDropDownItem={filters[filter]}
              dropDownItems={dropDownItems[filter]}
              dropDownOpen={dropdown === filter}
              setDropDownOpen={() => {
                handleDropDown(filter);
              }}
              labelClassName="label-default"
            />
          );
        })}
        <div
          className={`reset-button refresh-button ${clearFilters ? 'active' : 'disabled'}`}
          onClick={handleClickClearFilters}
          role="presentation"
        >
          <RefreshIcon />
          <p>{t(TRANSLATIONS.RESET)}</p>
        </div>
      </div>
    );
  }

  function renderSifStatsDataPerBU() {
    return (
      <div id="sif-stat-per-bu-section">
        <h4 className="row heading1">Por BU</h4>
        <div className="row">
          {chartsHorizontal.slice(0, 2).map(chart => {
            return (
              <div key={chart.title} className="col-6 card">
                <HorizontalBarByMonth
                  title={chart.title}
                  by={chart.by}
                  filter={filterObject}
                  year={Number(filters[Filters.YEAR].slug)}
                />
              </div>
            );
          })}
        </div>
        <div className="row">
          {filters[Filters.BU].slug === '' ? (
            <div className="card col">
              <div className="label-info"> Por favor, selecciona una BU para ver información aquí </div>
            </div>
          ) : (
            <div className="card col">
              <HorizontalBarByMonth
                title={chartsHorizontal[2].title}
                by={chartsHorizontal[2].by}
                filter={filterObject}
                year={Number(filters[Filters.YEAR].slug)}
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  function renderSifStatsAnalyticalData() {
    return (
      <div id="sif-stat-Análisis-Pareto-section">
        <h4 className="row heading1">Análisis Pareto</h4>
        <div className="row">
          {chartsVertical.slice(0, 2).map(chartVertical => {
            return (
              <div key={chartVertical.title} className="col-6 card">
                <LineAndColumnChart
                  title={chartVertical.title}
                  titlePosition="left"
                  barGraphTitle="Clasificación"
                  lineGraphTitle="Acumulado"
                  yLabel={chartVertical.label}
                  yOppositeLabel="Acumulado"
                  by={chartVertical.by}
                  filter={filterObject}
                  year={Number(filters[Filters.YEAR].slug)}
                  setCausesAndNatureFilter={setCausesAndNatureFilter}
                  causesAndNatureFilter={causesAndNatureFilter}
                />
              </div>
            );
          })}
        </div>
        <div className="row mt-1">
          <div className="card col-12">
            <LineAndColumnChart
              title={chartsVertical[2].title}
              titlePosition="left"
              barGraphTitle="Clasificación"
              lineGraphTitle="Acumulado"
              yLabel={chartsVertical[2].label}
              yOppositeLabel="Acumulado"
              by={chartsVertical[2].by}
              filter={filterObject}
              year={Number(filters[Filters.YEAR].slug)}
              causesAndNatureFilter={causesAndNatureFilter}
              setCausesAndNatureFilter={setCausesAndNatureFilter}
            />
          </div>
        </div>
      </div>
    );
  }

  function renderSifStatsHistorgram() {
    return (
      <div id="sif-stat-histogram-section">
        <h4 className="row heading2 mt-4">Histograma de incidentes</h4>
        <p className="row sub-label"> * Esta gráfica sólo se verá afectada por los filtros de BU, Región y Año </p>
        <div className="row">
          <div className="col card mt-2">
            <IncidentHistogram
              filter={
                  {
                    bu: filters[Filters.BU].slug,
                    region: filters[Filters.REGION].slug,
                  }
                }
              year={Number(filters[Filters.YEAR].slug)}
              xLabel="Mes"
              yLabel="Casos totales"
            />
          </div>
        </div>
      </div>
    );
  }
  function renderSifStatsIncidentDetailsSection() {
    return (
      <div id="sif-stat-incident-details-section">
        <div className="row">
          <div className="incident_histogram_container">
            <div className="row m-0 mb-2">
              <div className="incident-detail-heading-text">
                <p className="heading2">Detalle de incidentes para el mes de</p>
              </div>
              <ChartDropdown
                onChange={e => {
                  setSelectedMonth(findArrayItemOnSlug(e.key.toString(), dropDownItems.MONTHS)
                    || dropDownItems.MONTHS[0]);
                  handleDropDown('MONTHS');
                }}
                selectedDropDownItem={selectedMonth}
                dropDownItems={dropDownItems.MONTHS}
                dropDownOpen={dropdown === 'MONTHS'}
                setDropDownOpen={() => handleDropDown('MONTHS')}
                labelClassName="dropdown-label-month label-default"
              />
            </div>
            <div className="table_container mb-4">
              <StatisticsSIFTable
                filter={
                  {
                    bu: filters[Filters.BU].slug,
                    region: filters[Filters.REGION].slug,
                  }
                }
                monthNumber={Number(selectedMonth.slug)}
                year={Number(filters[Filters.YEAR].slug)}
              />
            </div>
            <h4 className="heading2 mt-4">Turno con mayor incidencia en el mes de:  <u> {selectedMonth.label} </u> </h4>
            <SimpleBarGraph
              filter={
                  {
                    bu: filters[Filters.BU].slug,
                    region: filters[Filters.REGION].slug,
                  }
                }
              monthNumber={Number(selectedMonth.slug)}
              year={Number(filters[Filters.YEAR].slug)}
              graphColor="#E92B36"
              xLabel="Rangos horarios"
              yLabel="Casos totales"
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <section className="sif-stats container-fluid">
      {renderSifStatFilters()}
      <div className="container-fluid">
        {renderSifStatsDataPerBU()}
        {renderSifStatsAnalyticalData()}
        {renderSifStatsHistorgram()}
        {renderSifStatsIncidentDetailsSection()}
      </div>
    </section>
  );
}

export default StatisticsSIF;
