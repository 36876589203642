import React, { useState, useRef, useCallback } from 'react';
import './PDT.scss';
import { useTranslation } from 'react-i18next';
import req from 'utils/request';
import { TRANSLATIONS } from 'types/enums';
// import get from 'lodash/get';
import { toast } from 'react-toastify';
import BasicButton from 'components/Button/BasicButton';
import Modal from 'components/Modal';
import axios from 'axios';
import ProgressBar from 'components/ProgressBar';
import Header from 'components/Header';
import { selectLocationsToAccess } from 'store/selectors/globalFilters.selectors';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/selectors/user.selectors';
// import DatePicker from 'react-datepicker';

const validFileExtensions = ['.csv', '.xlsx'];
const Validate = (files: any) => {
  for (let i = 0; i < files.length; i++) {
    const oInput = files[i];

    const sFileName = oInput.name;
    if (sFileName.length > 0) {
      let blnValid = false;
      for (let j = 0; j < validFileExtensions.length; j++) {
        const sCurExtension = validFileExtensions[j];
        console.log({ sCurExtension, sFileName });
        if (sFileName.substr(sFileName.length - sCurExtension.length, sCurExtension.length)
          .toLowerCase() === sCurExtension.toLowerCase()) {
          blnValid = true;
          break;
        }
      }

      if (!blnValid) {
        alert(`Sorry, ${sFileName} is invalid, allowed extensions are: ${validFileExtensions.join(', ')}`);
        return false;
      }
    }
  }

  return true;
};

const { CancelToken } = axios;

const PDTContainer = () => {
  const { t } = useTranslation();
  const cancelSource = useRef<any>(null);
  const isRequestCancelledManually = useRef<any>(false);
  const fileRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false);
  const [showProgressBar, setShowProgressBar] = useState<boolean>(false);
  const [progressPercentage, setProgressPercentage] = useState<number>(0);
  const [downloadError, setDownloadError] = useState<boolean>(false);
  const [sentViaEmail, setSentViaEmail] = useState<boolean>(false);
  const userDetails = useSelector(selectUser);

  const locations = useSelector(selectLocationsToAccess);

  const handleUploadButton = () => {
    if (fileRef.current !== null) {
      fileRef.current.click();
    }
  };

  const handleAttachmentsUpload = async (e: any) => {
    const { files } = e.target;
    if (files?.length && Validate(files)) {
      const config = {
        headers: { 'content-type': 'multipart/form-data' },
      };
      const data = new FormData();

      data.set('file', files[0]);

      try {
        setLoading(true);
        await req.post('/api/v1/pdt/upload-external-company-excel', data, config);
        setLoading(false);
        toast.success('Uploaded Successfully!');
      } catch (err) {
        setLoading(false);
        toast.error('Error while uploading');
      } finally {
        if (fileRef.current !== null) {
          setLoading(false);
          fileRef.current.value = '';
        }
      }
    }
  };

  const closeModal = () => {
    setSentViaEmail(false);
    setDownloadError(false);
    setShowDownloadModal(false);
    setShowProgressBar(false);
    setProgressPercentage(0);
  };

  const onPressCancelRequest = () => {
    setSentViaEmail(false);
    setDownloadError(false);
    setShowDownloadModal(false);
    setShowProgressBar(false);
    setProgressPercentage(0);
    if (cancelSource?.current) {
      isRequestCancelledManually.current = true;
      cancelSource.current.cancel();
    }
  };

  const onClickDownload = () => {
    setShowDownloadModal(true);
  };

  const downloadOwdExcel = useCallback(
    async () => {
      cancelSource.current = CancelToken.source();
      try {
        setSentViaEmail(false);
        setDownloadError(false);
        setShowDownloadModal(true);
        setShowProgressBar(true);
        const url = `/api/v1/pdt/excel?locationIds=${JSON.stringify(locations)}`;
        const res = await axios({
          url,
          method: 'GET',
          onDownloadProgress: (progressEvent: any) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setProgressPercentage(percentCompleted);
          },
          cancelToken: cancelSource.current.token,
        });
        if (res.data?.sentViaEmail) {
          return setSentViaEmail(true);
        }
        const downloadUrl = res.data;
        const a = document.createElement('a');
        a.href = downloadUrl;
        setShowDownloadModal(false);
        setShowProgressBar(false);
        setProgressPercentage(0);
        a.click();
      } catch (error) {
        if (!isRequestCancelledManually.current) {
          setDownloadError(true);
          setProgressPercentage(0);
          isRequestCancelledManually.current = false;
        }
      }
    }, [locations],
  );

  return (
    <section className="PDT_container">
      <div className="pdt_header">
        <p className="title">{t(TRANSLATIONS.PDT)}</p>
        <div className="upload_container">
          <BasicButton text={t(TRANSLATIONS.DOWNLOAD_DATA)} className="download_button" onClick={onClickDownload} />
          {userDetails?.get('email').includes('arvolution') && (
          <div className="upload_button">
            <BasicButton
              text="Upload"
              className="upload_button_filled"
              onClick={handleUploadButton}
              disabled={loading}
            />
            <input
              ref={fileRef}
              className="hidden"
              type="file"
              onChange={handleAttachmentsUpload}
              name="file"
              multiple
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            />
          </div>
          )}
        </div>
      </div>
      {showDownloadModal && (
      <Modal
        modalContainerStyles={{ width: '70vw',
          left: '20%',
          maxHeight: '90vh',
          minHeight: '20vh',
          height: 'fit-content',
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)' }}
      >
        <div className="downloadMoal">
          <p className="downloadMoal__title">{downloadError ? `${t(TRANSLATIONS.SOMETHING_WENT_WRONG)}...` : t(TRANSLATIONS.DOWNLOAD_DATA)}</p>
          {showProgressBar ? (
            <>
              <p className="downloadMoal__downloading_title">{downloadError ? t(TRANSLATIONS.DOWNLOAD_FAIL_RETRY_THE_DOWNLOAD_PLEASE) : sentViaEmail ? t(TRANSLATIONS.THE_DATA_IS_TAKING_A_LONG_TIME_TO_DOWNLOAD_A_DOWNLOAD_LINK_WILL_BE_SENT_TO_YOUR_EMAIL_TO_CONTINUE) : t(TRANSLATIONS.DOWNLOADING_DATA)}</p>
              {!downloadError && !sentViaEmail && (
              <div className="downloadMoal__progress_bar">
                <p className="downloadMoal__progress_bar__title">{progressPercentage}%</p>
                <ProgressBar bgcolor="#4472C4" height={16} progress={progressPercentage} />
              </div>
              )}
            </>
          ) : (
            <>
              <p className="downloadMoal__description">{t(TRANSLATIONS.THE_DATA_FOR_THE_LOCATIONS_THAT_ARE_FILTERED_IN_THE_TOP_FILTER_BAR_WILL_BE_DOWNLOADED)}</p>
              <div className="downloadMoal__header">
                <Header showLogo={false} showSettings={false} />
              </div>
            </>
          )}
          <div className="downloadMoal__button_container">
            {!sentViaEmail && (
              downloadError ? (
                <BasicButton text={t(TRANSLATIONS.RETRY_AGAIN)} className="downloadMoal__button_container__download_button" onClick={downloadOwdExcel} />
              ) : (
                <BasicButton disabled={showProgressBar} text={t(TRANSLATIONS.DOWNLOAD)} className="downloadMoal__button_container__download_button" onClick={downloadOwdExcel} />
              )
            )}
            <BasicButton text={t(TRANSLATIONS.CANCEL)} className="downloadMoal__button_container__cancel_button" onClick={showProgressBar ? onPressCancelRequest : closeModal} />
          </div>
        </div>
      </Modal>
      )}
    </section>
  );
};

export default PDTContainer;
