import { useCubeQuery } from '@cubejs-client/react';
import ChartDropdown from 'components/Dropdown/ChartDropdown';
import LoadingSpinner from 'components/LoadingSpinner';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectLocationsToAccess } from 'store/selectors/globalFilters.selectors';
import { TRANSLATIONS } from 'types/enums';
import { format } from 'date-fns';
import { set, subDays } from 'date-fns/esm';
import { lastDayOfMonth } from 'date-fns/esm/fp';
import './ParticipationTable.scss';
import twoPersons from 'assets/icons/two-persons.svg';
import EmptyState from 'components/EmptyState/EmptyState';

type Props = {};

// eslint-disable-next-line no-empty-pattern
const ParticipationTable = ({} : Props) => {
  const { t } = useTranslation();

  const dropDownItems = [{
    slug: 'last-7-days',
    label: t(TRANSLATIONS.LAST_7_DAYS),
  }, {
    slug: 'last-30-days',
    label: t(TRANSLATIONS.LAST_30_DAYS),
  }, {
    slug: 'month-to-date',
    label: t(TRANSLATIONS.MONTH_TO_DATE),
  }];

  const locationsToAccess = useSelector(selectLocationsToAccess);
  const skip = !locationsToAccess.length;
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [selectedDropDownItem, setSelectedDropDownItem] = useState(dropDownItems[0]);

  const getDateRange = () => {
    const currentDate = new Date();
    let filter = ['', ''] as any[];
    if (selectedDropDownItem.slug === 'last-7-days') {
      filter = [subDays(currentDate, 7), currentDate];
    }
    if (selectedDropDownItem.slug === 'last-30-days') {
      filter = [subDays(currentDate, 30), currentDate];
    }
    if (selectedDropDownItem.slug === 'month-to-date') {
      const lastMonthDay = lastDayOfMonth(currentDate);
      const firstDayLastMonth = set(lastMonthDay, { date: 1 });
      filter = [firstDayLastMonth, lastMonthDay];
    }
    return [format(filter[0], 'yyyy-MM-dd'), format(filter[1], 'yyyy-MM-dd')];
  };

  const dateRange = getDateRange() as unknown as string[];
  const { resultSet, isLoading } = useCubeQuery({
    measures: ['Incidents.count'],
    timeDimensions: [
      {
        dimension: 'Incidents.incidentdatetime',
        dateRange,
      },
    ],
    dimensions: ['Users.position'],
    order: { 'Incidents.count': 'desc' },
    filters: [{
      dimension: 'Incidents.locationId',
      operator: 'equals',
      values: locationsToAccess,
    }, {
      dimension: 'Users.position',
      operator: 'set',
    }],
    limit: 20,
  }, { skip });

  const { resultSet: countResult } = useCubeQuery({
    measures: ['Incidents.count'],
    timeDimensions: [{
      dimension: 'Incidents.incidentdatetime',
      dateRange,
    }],
    order: {
      'Incidents.incidentdatetime': 'asc',
    },
    filters: [{
      dimension: 'Incidents.locationId',
      operator: 'equals',
      values: locationsToAccess,
    }],
  }, { skip });
  const incidents = countResult?.series()[0].series[0].value;

  // @ts-ignore
  const handleDropDownChange = ({ key }) => {
    setSelectedDropDownItem(dropDownItems.find(
      item => item.slug === key,
    ) || dropDownItems[0]);
    setDropDownOpen(false);
  };

  const isEmpty = (!isLoading && (!countResult?.series()[0] || !incidents));

  if (isLoading || skip) return <LoadingSpinner height={231} />;

  return (
    <div className="overview-participation-table">
      <div className="header-container">
        <div className="header-title">
          <img
            src={twoPersons}
            alt=""
            className="hc-icon"
          />
          <p className="text-bold overview-header">{ t(TRANSLATIONS.INCIDENTS_PER_POSITION) }</p>
        </div>
        <p className="table-description">{t(TRANSLATIONS.INCIDENTS)}</p>
      </div>
      <div className="table" style={isLoading ? { fontSize: 16 } : {}}>
        { isEmpty ? (
          <EmptyState />
        ) : (
          resultSet?.series()[0]?.series?.map(position => (
            <div className="table-row" key={position.category}>
              <p style={{ marginRight: 3 }}>{ position.x }</p>
              <div className="value-container">
                <p className="percent">{ Math.round((position.value / incidents) * 100) }%</p>
                <p>{ position.value.toLocaleString() }</p>
              </div>
            </div>
          ))
        )}
      </div>
      <ChartDropdown
        onChange={handleDropDownChange}
        selectedDropDownItem={selectedDropDownItem}
        dropDownItems={dropDownItems}
        dropDownOpen={dropDownOpen}
        setDropDownOpen={setDropDownOpen}
        labelClassName="label-default"
      />
    </div>
  );
};

export default ParticipationTable;
