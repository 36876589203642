import React, { FC, useEffect } from 'react';
import './FileUpload.scss';
import ImageLinks from 'utils/ImageLinks';
import { useLocation } from 'react-router-dom';

const FileUpload: FC<Props> = (props: Props) => {
  const { label, handleFileChange, accepts = 'video/*, image/*', containerClassName, imgClassName = '', uploadKey = 'file-upload' } = props;
  let { file } = props;
  const location = useLocation();
  const acceptsImgOnly = 'image/*';
  const fileTooBig = file?.size > 10000000;

  if (fileTooBig) {
    file = null;
  }

  // mounting and un-mounting
  useEffect(() => {
    // returned function will be called on component unmount
    return () => {
    };
  }, []);

  return (
    <div className={`file-upload-container ${containerClassName}`}>
      {/* upload */}
      <label
        htmlFor={uploadKey}
        className="text-secondary text-bold text-larger text-center input-file-label"
      >
        <img src={ImageLinks.uploadIcon} alt="upload file" width="25" />
        <span className="ml-3">{label}</span>
        <input
          id={uploadKey}
          name={uploadKey}
          type="file"
          className="hidden-input"
          accept={location.pathname === '/ac' ? acceptsImgOnly : accepts}
          onChange={handleFileChange}
        />
      </label>

      {/* img or video */}
      {file && !fileTooBig
      && (
        <div className="row justify-content-center align-items-center mt-2">
          {/* {file.type.includes('video') ? (
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <video controls className={`uploaded-file mt-1 ${imgClassName}`}>
              <source src={URL.createObjectURL(file)} />
              Your Browser Does Not Seem To Support Videos.
            </video>
          ) : ( */}
          <div className="col-auto">
            <img
              src={URL.createObjectURL(file)}
              alt="uploaded-file"
              className={`uploaded-file mt-1 ${imgClassName}`}
            />
          </div>
          {/* )} */}
        </div>
      )}
      {fileTooBig && <p className="imageError">File is too big to be uploaded!</p> }
    </div>
  );
};

type Props = {
  uploadKey?: string;
  handleFileChange: any;
  accepts?: string;
  label: string;
  file?: any;
  containerClassName?: string;
  imgClassName?: string;
};

export default FileUpload;
