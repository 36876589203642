import React, { useState } from 'react';
import './DeleteUser.scss';
import { useTranslation } from 'react-i18next';
import Modal from '../../../components/Modal';
import BasicButton from '../../../components/Button/BasicButton';
import { COLOR_CODE_NEW, TRANSLATIONS } from '../../../types/enums';
import request from '../../../utils/request';
import LoadingSpinner from '../../../components/LoadingSpinner';

type Props = {
  user: any;
  close?: ()=>void;
};

const DeleteUser = (props: Props) => {
  const { user, close } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const deleteHandler = () => {
    if (user && user.slug) {
      setLoading(true);
      request.delete(`/api/v1/users/${user.slug}`).then(
        () => {
          if (close) {
            close();
          }
        },
      ).catch(() => {
        setLoading(false);
        alert('Delete user failed');
      });
    }
  };
  if (loading) {
    return (
      <Modal
        onOverlayClick={() => {
          if (close) {
            close();
          }
        }}
        modalContainerStyles={{
          width: '30vw',
          left: '40vw',
          height: '30vh',
          top: '40vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <LoadingSpinner />
      </Modal>
    );
  }
  return (
    <Modal
      onOverlayClick={() => {
        if (close) {
          close();
        }
      }}
      modalContainerStyles={{
        width: '30vw',
        left: '40vw',
        height: '30vh',
        top: '40vh',
      }}
    >
      <div className="mainContainer">
        <h2>{t(TRANSLATIONS.DELETE_USER)}</h2>
        <h4>{t(TRANSLATIONS.CONFIRM_USER_DELETION)} {user.name}</h4>
        <div className="verifyButtons">
          <BasicButton
            text={t(TRANSLATIONS.YES)}
            onClick={deleteHandler}
            style={{
              backgroundColor: COLOR_CODE_NEW.PRIMARY,
              padding: '10px 22px',
              marginRight: '20px',
              flex: 1,
            }}
          />
          <BasicButton
            text="No"
            onClick={() => {
              if (close) close();
            }}
            style={{
              backgroundColor: COLOR_CODE_NEW.PRIMARY_LIGHT,
              color: COLOR_CODE_NEW.PRIMARY,
              padding: '10px 22px',
              flex: 1,
            }}
          />
        </div>
      </div>
    </Modal>

  );
};

export default DeleteUser;
