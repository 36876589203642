import React from 'react';
import { useNavigate } from 'react-router-dom';

import './TileWithImage.scss';

const TileWithImage = (
  { img, title, subtitle, slug, link } : {
    img: string,
    title: string,
    subtitle: string,
    slug?: string,
    link?: string
  },
) => {
  const navigate = useNavigate();

  const handleRedirectToExtended = () => {
    if (slug) {
      navigate(`/academy/extended-view/${slug}`);
    } else if (link) {
      navigate(`/file-upload/${link}`);
    }
  };

  return (
    <li className="tile-with-image" onClick={handleRedirectToExtended}>
      <div className="tile-with-image__left">
        <img alt={title} src={img} />
      </div>
      <div className="tile-with-image__right">
        <p>
          {title}
        </p>
        <span>
          {subtitle}
        </span>
      </div>
    </li>
  );
};

TileWithImage.defaultProps = {
  link: '',
  slug: '',
};

export default TileWithImage;
