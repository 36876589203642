import LoadingSpinner from 'components/LoadingSpinner';
import { fromJS } from 'immutable';
import React, { CSSProperties } from 'react';
import { ReactComponent as DropIcon } from '../../../assets/icons/drop.svg';
import OnClickDropdown from '../OnClickDropdown';
import './SelectDropdown.scss';

export type SelectDropdownProps = {
  name: string;
  value: string | Object;
  children?: React.ClassicElement<any>;
  dropdownContent: Array<
  {
    value?: string | number | Object; label: string | React.ClassicElement<any>; className?: string,
  }
  >;
  onChange(e: any): void;
  placeholder?: string;
  className?: string;
  inputStyle?: CSSProperties;
  dropDownStyle?: CSSProperties;
  dropDownClassName?: string;
  dropdownWrapperClassName?: string;
  dropdownElementsWidth?: any;
  selectWidth?: number;
  highlightSelected?: boolean;
  valueStyle?: any;
  placeHolderStyle?: any;
  disabled?: boolean;
  bgColor?: string;
  icon?: string;
};

const selectDropdown = ({
  name, // Name of the select
  value,
  dropdownContent,
  onChange, // Function that executes when one of the select options is clicked
  placeholder, // Text that will appear on the Select when there is no selected value
  // className, // Classname for the OnClickDropdown className prop
  inputStyle, // Style for the OnClickDropdown style prop
  dropdownElementsWidth,
  selectWidth, // Width of the OnClickDropdown children prop
  highlightSelected = false, // When set to true, displays a blue dot
  dropDownStyle,
  dropDownClassName,
  // clasName of the dropdown wrapper
  dropdownWrapperClassName,
  valueStyle,
  placeHolderStyle,
  disabled = false,
  bgColor,
  icon,
  // on the Select option that is currently the Select value
}: SelectDropdownProps) => {
  const createEvent = (
    optionName: any,
    optionValue: any,
    e: any,
    toggleDropDownVisibility: Function,
  ) => {
    // Because we are not using default select element, the event lacks a name and a value.
    // We add them here.
    e.target.name = optionName;
    e.target.value = optionValue;
    onChange(e);
    toggleDropDownVisibility();
  };

  const renderDropDownContent = (toggleDropDownVisibility: Function) => {
    const dropdownContentValidation = !!(fromJS(dropdownContent)?.toJS());
    return (
      <div className={dropDownClassName}>
        {dropdownContentValidation ? (
          dropdownContent?.map((element: any) => (
            <div
              className={`option ${element.className || ''}`}
              key={element.label}
              role="button"
              onClick={(e: any) => createEvent(name, element.value, e, toggleDropDownVisibility)}
            >
              <p className="label">{element.label}</p>
              {highlightSelected && (element.value === value)
                ? (
                  <div
                    style={{
                      width: 10,
                      height: 10,
                      backgroundColor: '#2574FB',
                      borderRadius: 50,
                    }}
                  />
                )
                : ''}
            </div>
          ))
        ) : <LoadingSpinner height={30} /> }
      </div>
    );
  };

  const currentValue = dropdownContent?.find((el: any) => el.value === value);

  return (
    <OnClickDropdown
      disabled={disabled}
      className={dropdownWrapperClassName}
      dropdownContent={renderDropDownContent}
      inputStyle={inputStyle}
      dropDownStyle={dropDownStyle}
      dropdownElementsWidth={dropdownElementsWidth}
    >
      <div
        className={`select ${disabled ? 'disabled' : ''}`}
        style={{
          width: selectWidth,
          color: value === '' ? '#A6A6A6' : '#000',
          backgroundColor: bgColor,
        }}
      >
        {icon && <img src={icon} alt="left icon" />}
        <p
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            color: '#808080',
            maxWidth: selectWidth ? selectWidth - 15 : '95%',
            ...(currentValue ? valueStyle : placeHolderStyle),
            marginRight: 8,
          }}
        >
          {currentValue ? currentValue.label : placeholder}
        </p>
        <DropIcon />
      </div>
    </OnClickDropdown>
  );
};

export default selectDropdown;
