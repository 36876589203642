import { Box, Tab, Tabs } from '@mui/material';
import CheckBoxWithIcon from 'components/CheckBoxWithIcon';
import LoadingSpinner from 'components/LoadingSpinner';
import { TextField } from '@mui/material';
import { General_User_Roles, Location_Type, Logistics_User_Roles, Supply_User_Roles } from 'generated/graphql';
import { fromJS } from 'immutable';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { ROLES_CATEGORY, TRANSLATIONS, UserTypes } from 'types/enums';
import ImageLinks from 'utils/ImageLinks';
import request from 'utils/request';
import CheckBoxWithDescription from 'components/CheckBoxWithDescription';
import { toast } from 'react-toastify';
import homeIcon from 'assets/icons/home-black.svg';
import Autocomplete from '@mui/lab/Autocomplete';
import { useGetDepartmentAreaSubAreaLazyQuery } from 'generated/graphql';
import i18next from 'i18next';
import { UserManagementContext, UserPermissionType } from '../../context';
import RoutinesSelector from './routinesSelector';
import RoutinesViewer from './routinesViewer';
import { userIsManagerDPO } from 'utils/shared/canUserAccessFeature';
import { withStyles } from '@mui/styles';
import { Checkbox, CheckboxProps } from '@mui/material';
import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import './UserInfo.scss';

const useStyles = makeStyles(() => ({
  indicator: {
    backgroundColor: '#1876F6',
    border: 'none',
  },
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  tabRoot: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: 'normal',
    marginRight: '32px',
    '&:hover': {
      color: '#1876F6',
      opacity: 1,
    },
    '&$selected': {
      color: '#1876F6',
      fontWeight: 'normal',
    },
    '&:focus': {
      color: '#1876F6',
    },
  },
}));

const { emptyRadioUnchecked, yellowRadioChecked } = ImageLinks;

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} id={index} {...other}>
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

const PermissionsTab = (props: any) => {
  const { t } = useTranslation();
  const { isAuthorisedForOWD, setIsAuthorisedForOWD, autoAssignSios, setAutoAssignSios, isDeletedUser } = props;
  const state = useContext(UserManagementContext);
  const [tabIndex, setTabIndex] = useState(0);
  const classes = useStyles();
  const userData = state.user;
  const {
    isEditing,
    originalUser,
    setOriginalUser,
    editingUser,
    setEditingUser,
    setIsLoading,
    isLoading,
    setUserAvailableRoles,
    user,
  } = state;

  const OrangeCheckbox = withStyles({
    root: {
      '&$checked': {
        color: '#EFBE3F',
      },
    },
    checked: {},
  })((MUIRadioProps: CheckboxProps) => <Checkbox color="default" {...MUIRadioProps} />);

  const {
    userAvailableRoles,
  } = state as unknown as { userAvailableRoles: UserPermissionType[] };
  const [supplyRoles, setSupplyRoles] = useState();
  const [logisticsRoles, setLogisticsRoles] = useState();
  const [generalRoles, setGeneralRoles] = useState();
  const [area, setArea] = useState<any>(null);
  const [disabedFieldsState, setDisabedFieldsState] = useState<any>(
    { area: false, subArea: false },
  );

  const distributionCenterType = originalUser?.get('general')?.get('distributionCenter')?.get('type');

  const originalUserInfo = originalUser?.get('general')?.toJS();
  const editingUserInfo = editingUser?.get('general')?.toJS();

  const originalOrEditingInfo = !isEditing ? originalUserInfo : editingUserInfo;

  const isDistributionCenter = distributionCenterType
    === Location_Type.DistributionCenter;

  const [callDepartmentAreaSubArea, { data: departmentAreaSubArea, loading }] = useGetDepartmentAreaSubAreaLazyQuery({ fetchPolicy: 'no-cache' });

  const accessLocationIdTemp = useMemo(() => {
    return (!isEditing ? originalUserInfo : editingUserInfo)?.distributionCenter?.id;
  }, [originalUserInfo, editingUserInfo, isEditing]);

  useEffect(() => {
    if (accessLocationIdTemp) {
      callDepartmentAreaSubArea({
        variables: {
          id: accessLocationIdTemp,
        },
      });
    }
  }, [accessLocationIdTemp, callDepartmentAreaSubArea]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading, setIsLoading]);

  const rolesTabs = [
    {
      id: '1',
      label: t(TRANSLATIONS.LOGISTICS),
    },
    {
      id: '2',
      label: 'Supply',
    },
    {
      id: '3',
      label: t(TRANSLATIONS.GENERAL),
    },
  ];

  useEffect(() => {
    setSupplyRoles(
      (userAvailableRoles as any).filter((value: any) => value.category === ROLES_CATEGORY.SUPPLY),
    );
    setLogisticsRoles(
      (userAvailableRoles as any)
        .filter((value: any) => value.category === ROLES_CATEGORY.LOGISTICS),
    );
    setGeneralRoles(
      (userAvailableRoles as any).filter((value: any) => value.category === ROLES_CATEGORY.MIXED),
    );
  }, [userAvailableRoles]);

  useEffect(() => {
    const editingUserCDType = editingUser?.get('general')?.get('distributionCenter')?.get('type');
    const defaultTab = editingUserCDType === 'DISTRIBUTION_CENTER'
      ? 0
      : editingUserCDType === 'FACTORY'
        ? 1
        : 0;
    setTabIndex(defaultTab);
    // eslint-disable-next-line
  }, []);

  const permissionsOptions = useMemo(() => {
    if (isDistributionCenter) {
      return [
        {
          id: 1,
          label: `${t(TRANSLATIONS.CERTIFICATES)}`,
          type: UserTypes.CertificatesOnly,
          roleSlug: General_User_Roles.CertificatesOnly as string,
        },
        {
          id: 2,
          label: `${t(TRANSLATIONS.CERTIFICATES)}, ${t(TRANSLATIONS.REPORT)} ${t(
            TRANSLATIONS.INCIDENTS,
          )}, ${t(TRANSLATIONS.ACTION_LOGS)}`,
          type: UserTypes.ReporterOnlyDPO,
          roleSlug: Logistics_User_Roles.ReporterOnlyDpo as string,
        },
        {
          id: 3,
          label: `${t(TRANSLATIONS.CERTIFICATES)}, ${t(TRANSLATIONS.REPORT)} ${t(TRANSLATIONS.INCIDENTS)}, ${t(TRANSLATIONS.ACTION_LOGS)}, ${t(TRANSLATIONS.WEB_ACCESS)}`,
          type: UserTypes.CorporateDPO,
          roleSlug: Logistics_User_Roles.CorporateDpo as string,
        },
        {
          id: 4,
          label: `${t(TRANSLATIONS.CERTIFICATES)}, ${t(TRANSLATIONS.REPORT)}, ${t(
            TRANSLATIONS.CLOSE,
          )} ${t(TRANSLATIONS.INCIDENTS)}, ${t(TRANSLATIONS.ACTION_LOGS)}, ${t(TRANSLATIONS.WEB_ACCESS)}`,
          type: UserTypes.DistributionCenterManagerDPO,
          roleSlug: Logistics_User_Roles.DistributionCenterManagerDpo as string,
        },
        {
          id: 5,
          label: `${t(TRANSLATIONS.CERTIFICATES)}, ${t(TRANSLATIONS.REPORT)}, ${t(
            TRANSLATIONS.CLOSE,
          )}, ${t(TRANSLATIONS.ASSIGN)} ${t(TRANSLATIONS.INCIDENTS)}, ${t(
            TRANSLATIONS.ACTION_LOGS,
          )}, ${t(TRANSLATIONS.WEB_ACCESS)}`,
          type: UserTypes.SubzoneGeneralManagerDPO,
          roleSlug: Logistics_User_Roles.SubzoneGeneralManagerDpo as string,
        },
      ];
    }
    return [
      {
        id: 1,
        label: `${t(TRANSLATIONS.CERTIFICATES)}`,
        type: UserTypes.CertificatesOnly,
        roleSlug: General_User_Roles.CertificatesOnly as string,
      },
      {
        id: 2,
        label: `${t(TRANSLATIONS.CERTIFICATES)}, ${t(TRANSLATIONS.REPORT)} ${t(
          TRANSLATIONS.INCIDENTS,
        )}, ${t(TRANSLATIONS.AMBIENTAL_RISKS)}`,
        type: UserTypes.ReporterOnlySupply,
        roleSlug: Supply_User_Roles.ReporterOnly as string,
      },
      {
        id: 3,
        label: `${t(TRANSLATIONS.CERTIFICATES)}, ${t(TRANSLATIONS.REPORT)} ${t(TRANSLATIONS.INCIDENTS)}, ${t(TRANSLATIONS.WEB_ACCESS)}`,
        type: UserTypes.CorporateSupply,
        roleSlug: Supply_User_Roles.CorporateSupply as string,
      },
      {
        id: 4,
        label: `${t(TRANSLATIONS.CERTIFICATES)}, ${t(TRANSLATIONS.REPORT)}, ${t(
          TRANSLATIONS.CLOSE,
        )} ${t(TRANSLATIONS.INCIDENTS)}, ${t(TRANSLATIONS.AMBIENTAL_RISKS)}, ${t(TRANSLATIONS.WEB_ACCESS)}`,
        type: UserTypes.DistributionCenterManagerSupply,
        roleSlug: Supply_User_Roles.EjecutorSafetyEnvironment as string,
      },
      {
        id: 5,
        label: `${t(TRANSLATIONS.CERTIFICATES)}, ${t(TRANSLATIONS.REPORT)}, ${t(
          TRANSLATIONS.CLOSE,
        )}, ${t(TRANSLATIONS.ASSIGN)} ${t(TRANSLATIONS.INCIDENTS)}, ${t(
          TRANSLATIONS.AMBIENTAL_RISKS,
        )}, ${t(TRANSLATIONS.WEB_ACCESS)}`,
        type: UserTypes.SubzoneGeneralManagerSupply,
        roleSlug: Supply_User_Roles.AssignerSafetyEnvironment as string,
      },
      {
        id: 6,
        label: t(TRANSLATIONS.PRODUCTION_PERFORMANCE_TRACKING),
        type: UserTypes.BreweryViewer,
        roleSlug: Supply_User_Roles.PlantManager as string,
      },
    ];
  }, [isDistributionCenter, t]);

  const loadPermissions = async () => {
    let tempObj = {} as any;
    let availablePermissons;
    try {
      setIsLoading(true);
      const userRequest = request.get(`/api/v1/users/${encodeURIComponent(userData.slug)}/info?isUserDeleted=${isDeletedUser}`).then(res => {
        const typeFromApi = permissionsOptions.find(
          ({ type }) => res.info?.type === type,
        );

        const roleFromApi = res?.info?.userRole || {};

        const obj = {
          permissions: {
            type: (
              !roleFromApi
              && typeFromApi
            ) ? typeFromApi
              : (
                roleFromApi
                || permissionsOptions[1]
              ),
            roleId: (res?.info?.roleId),
            roleSlug: (res?.info?.userRole?.slug),
            role: roleFromApi,
            ...tempObj.permissions,
          },
        };
        tempObj = { ...tempObj, ...obj };
      });
      const permissionRequest = request.get(`/api/v1/users/${encodeURIComponent(userData.slug)}/permissions?isUserDeleted=${isDeletedUser}`).then(
        res => {
          const routinesGroup = {} as any;
          res.permissions.forEach((routine: any) => {
            if (routinesGroup[routine.pillar]) {
              routinesGroup[routine.pillar].routines = (
                routinesGroup[routine.pillar].routines.concat(routine)
              );
            } else {
              routinesGroup[routine.pillar] = {
                routines: [].concat(routine),
                selected: true,
              };
            }
          });
          tempObj = {
            ...tempObj,
            permissions: {
              routines: {
                ...routinesGroup,
              },
              ...tempObj.permissions,
            },
          };
        },
      );
      const availablePermissionesRequest = request.get('/api/v1/users-available-roles').then(
        res => {
          availablePermissons = res.roles;
        },
      );
      await Promise.all([userRequest, permissionRequest, availablePermissionesRequest]);
      setOriginalUser(originalUser.merge(fromJS(tempObj)));
      if (!editingUser?.getIn(['permissions', 'routines'])?.size) {
        setEditingUser(editingUser.merge(fromJS(tempObj)));
      }
      setUserAvailableRoles(availablePermissons);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    loadPermissions();
    // eslint-disable-next-line
  }, []);

  const routinesPermissions = originalUser
    ?.get('permissions')
    ?.get('routines')
    ?.toJS();

  const editableRoutinesPermissions = editingUser
    ?.get('permissions')
    ?.get('routines')
    ?.toJS();
  // const originalUserType = originalUser
  //   ?.get('permissions')
  //   ?.get('type')
  //   ?.toJS();

  // const originalUserRole = originalUser
  //   ?.getIn(['permissions', 'role'])
  //   ?.toJS();

  const originalUserRoleId = +originalUser
    ?.getIn(['permissions', 'roleId']);

  const editedUserRoleId = +editingUser
    ?.getIn(['permissions', 'roleId']) || 31;

  // const locationType = originalUser
  //   ?.getIn(['general', 'distributionCenter', 'type']);

  // const EditableUserType = editingUser
  //   ?.get('permissions')
  //   ?.get('type')
  //   ?.toJS();

  // const EditableUserRole = editingUser
  //   ?.getIn(['permissions', 'role'])
  //   ?.toJS();

  // function changeUserTypeAndRole(id: number) {
  //   setEditingUser((editingUserData: any) => editingUserData
  //     .setIn(['permissions', 'type'], fromJS(permissionsOptions[id - 1])));
  //   setEditingUser((editingUserData: any) => editingUserData
  //     .setIn(['permissions', 'role'], fromJS(permissionsOptions[id - 1])));
  // }

  function changeUserRoleId(userRole: any) {
    setEditingUser((editingUserData: any) => editingUserData.mergeIn(['permissions'], {
      roleId: userRole.id,
      roleSlug: userRole.slug,
      roleCategory: userRole.category,
    }));
    if (userRole?.category !== user?.userRole?.category && isEditing) {
      toast.info(`${t(TRANSLATIONS.CHANGED_ROLE_TO, { role1: user?.userRole?.name, role2: userRole?.name, from: user?.userRole?.category, to: userRole?.category })}`);
    }
  }

  const options = useMemo(() => {
    if (departmentAreaSubArea?.getDepartmentAreaSubArea) {
      const { departments } = departmentAreaSubArea?.getDepartmentAreaSubArea;
      const selectedDepartment = departments?.find((localDepartment: any) => {
        return localDepartment.id === (
          area?.id || originalOrEditingInfo
            ?.userWorkLocation?.workLocation?.id
        );
      });

      const areas = selectedDepartment?.areas?.filter((localArea: any) => {
        return localArea?.workLocationId === (
          area?.id || originalOrEditingInfo
            ?.userWorkLocation?.workLocation?.id
        );
      });

      return {
        areas: departments || [],
        subAreas: areas || [],
      };
    }
    return {
      areas: [],
      subAreas: [],
    };
    // eslint-disable-next-line
  }, [departmentAreaSubArea, area]);

  function InfoRow(item: any) {
    return (
      <div className="info-row-container">
        <div className="d-flex">
          <div className="info-row-icon">
            <img src={item.icon} width="100%" height="100%" alt="delete file" />
          </div>
          <span className="info-row-label">{item.label}</span>
        </div>
        <span className="info-row-value">{item.value}</span>
      </div>
    );
  }

  const handleAreaChange = (value: any) => {
    const copyOfEditingUserInfo = JSON.parse(JSON.stringify(editingUserInfo));
    const tempObj = {
      general: {
        ...copyOfEditingUserInfo,
        userWorkLocation: {
          id: originalOrEditingInfo?.userWorkLocation?.id,
          workLocation: value,
          subLocation: undefined,
          subArea: undefined,
        },
      },
    };
    setEditingUser(editingUser.merge(fromJS(tempObj)));
    setDisabedFieldsState({ ...disabedFieldsState, subArea: false });
    setArea(value);
  };

  const handleSubAreaChange = (value: any) => {
    const copyOfEditingUserInfo = JSON.parse(JSON.stringify(editingUserInfo));
    const tempObj = {
      general: {
        ...copyOfEditingUserInfo,
        userWorkLocation: {
          id: originalOrEditingInfo?.userWorkLocation?.id,
          workLocation: area,
          subLocation: value,
          subArea: undefined,
        },
      },
    };
    setEditingUser(editingUser.merge(fromJS(tempObj)));
    setDisabedFieldsState({ ...disabedFieldsState, subArea: false });
  };

  const disabedFields = useMemo(() => {
    const obj = {
      area: false,
      subArea: false,
    };

    if (!editingUserInfo?.distributionCenter?.id) {
      obj.area = true;
      obj.subArea = true;
    }

    if (!editingUserInfo?.userWorkLocation?.workLocation) {
      obj.subArea = true;
    }
    return obj;
  }, [editingUserInfo]);

  const showAutoAssignSios = useMemo(() => {
    let flag = false;
    if (userIsManagerDPO(editingUser?.getIn(['permissions', 'roleSlug']))) {
      flag = true;
    }
    return flag;
  }, [editingUser]);

  useEffect(() => {
    if (!autoAssignSios || !showAutoAssignSios) {
      const copyOfEditingUserInfo = JSON.parse(JSON.stringify(editingUserInfo));
      const tempObj = {
        general: {
          ...copyOfEditingUserInfo,
          userWorkLocation: {
            id: originalOrEditingInfo?.userWorkLocation?.id,
            workLocation: undefined,
            subLocation: undefined,
            subArea: undefined,
          },
        },
      };
      setAutoAssignSios(false);
      setEditingUser(editingUser.merge(fromJS(tempObj)));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoAssignSios, showAutoAssignSios]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      {routinesPermissions && Object.keys(routinesPermissions).length > 0 && (
        <>
          <div className="permissionsWrapper">
            <div>
              <div className="pt-2">
                <Tabs
                  classes={{ indicator: classes.indicator, root: classes.root }}
                  value={tabIndex}
                  onChange={(event: any, newValue: number) => setTabIndex(newValue)}
                >
                  {rolesTabs?.map(tab => {
                    return (
                      <Tab
                        disableRipple
                        key={tab.id}
                        classes={{ root: classes.tabRoot }}
                        id={tab.id}
                        label={tab.label}
                      />
                    );
                  })}
                </Tabs>
                <TabPanel value={tabIndex} index={0}>
                  <CheckBoxWithDescription
                    onClick={changeUserRoleId}
                    selected={(isEditing ? editedUserRoleId : originalUserRoleId)}
                    disabled={!isEditing}
                    data={logisticsRoles}
                    isEditing={isEditing}
                  />
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                  <CheckBoxWithDescription
                    onClick={changeUserRoleId}
                    selected={(isEditing ? editedUserRoleId : originalUserRoleId)}
                    disabled={!isEditing}
                    data={supplyRoles}
                    isEditing={isEditing}
                  />
                </TabPanel>
                <TabPanel value={tabIndex} index={2}>
                  <CheckBoxWithDescription
                    onClick={changeUserRoleId}
                    selected={(isEditing ? editedUserRoleId : originalUserRoleId)}
                    disabled={!isEditing}
                    data={generalRoles}
                    isEditing={isEditing}
                  />
                </TabPanel>
              </div>
            </div>

            {/* { locationType === LOCATION_TYPES.FACTORY ? (
              !originalUserRoleId && !isEditing ? (
                <p>El usuario no tiene ningun rol asignado aun</p>
              ) : (
                userAvailableRoles.map((
                  userRole: any,
                ) => {
                  const { id, name } = userRole;
                  return (
                    (
                      isEditing || id === (isEditing ? editedUserRoleId : originalUserRoleId)
                    ) && (
                    <CheckBoxWithIcon
                      key={id}
                      onClick={() => {
                        if (isEditing) changeUserRoleId(userRole);
                      }}
                      deSelectedIcon={emptyRadioUnchecked}
                      selectedIcon={yellowRadioChecked}
                      selected={(isEditing ? editedUserRoleId : originalUserRoleId) === id}
                      label={name}
                      disabled={!isEditing}
                    />
                    )
                  );
                })
              )
            ) : (
              permissionsOptions.map(
                ({ id, label }) => (
                  (isEditing
                    || (
                      id === (isEditing ? EditableUserRole : originalUserRole)?.id
                      || id === (isEditing ? EditableUserType : originalUserType)?.id
                    )
                  )) && (
                  <CheckBoxWithIcon
                    key={id}
                    onClick={() => {
                      if (isEditing) {
                        changeUserTypeAndRole(id);
                      }
                    }}
                    deSelectedIcon={emptyRadioUnchecked}
                    selectedIcon={yellowRadioChecked}
                    selected={
                      (
                        id === (isEditing ? EditableUserRole : originalUserRole)?.id
                        || id === (isEditing ? EditableUserType : originalUserType)?.id
                      )
                    }
                    label={label}
                    disabled={!isEditing}
                  />
                ),
              )
            )} */}
          </div>
          {tabIndex === 0 && (
            <div>
              <div className='owdsDivider' />
              <h4>OWDs</h4>
              <CheckBoxWithIcon
                onClick={() => {
                  if (isEditing) setIsAuthorisedForOWD(!isAuthorisedForOWD);
                }}
                deSelectedIcon={emptyRadioUnchecked}
                selectedIcon={yellowRadioChecked}
                selected={isAuthorisedForOWD}
                label={t(TRANSLATIONS.APPLY_OWDS)}
                disabled={!isEditing}
                icon={<OrangeCheckbox
                  icon={<CircleUnchecked />}
                  checkedIcon={<CircleCheckedFilled />}
                  checked={isAuthorisedForOWD}
                />}
              />
            </div>
          )}
          {(tabIndex === 0 && showAutoAssignSios) && (
            <div className='assignSios'>
              <div className='assignSios__divider' />
              <h4 className='assignSios__title'>{t(TRANSLATIONS.AUTOMATIC_ASSIGNMENT_OF_ACIS)}</h4>
              <CheckBoxWithIcon
                onClick={() => {
                  if (isEditing) setAutoAssignSios(!autoAssignSios);
                }}
                deSelectedIcon={emptyRadioUnchecked}
                selectedIcon={yellowRadioChecked}
                selected={autoAssignSios}
                label={t(TRANSLATIONS.ALLOW_AUTOMATIC_ASSIGNMENT_OF_ACIS)}
                disabled={!isEditing}
                icon={<OrangeCheckbox
                  icon={<CircleUnchecked />}
                  checkedIcon={<CircleCheckedFilled />}
                  checked={autoAssignSios}
                />}
              />

              {autoAssignSios && (
                <>
                  <InfoRow
                    icon={homeIcon}
                    label={t(TRANSLATIONS.AREA)}
                    value={
                      isEditing ? (
                        <Autocomplete
                          key="area"
                          options={options?.areas}
                          isOptionEqualToValue={(option, value) => {
                            if (value?.id) {
                              return String(option.id) === String(value.id);
                            }
                            return false;
                          }}
                          value={
                            editingUserInfo?.userWorkLocation?.workLocation !== null
                              ? options?.areas?.find((e: any) => {
                                const tmp = editingUserInfo?.userWorkLocation?.workLocation;
                                return String(e.id) === String(tmp?.id);
                              }) || (() => null)
                              : () => null
                          }
                          disabled={disabedFields.area || disabedFieldsState.area}
                          getOptionLabel={option => option.name}
                          style={{ minWidth: 164 }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              variant="outlined"
                              inputProps={{
                                ...params.inputProps,
                                style: {
                                  padding: 0,
                                },
                              }}
                            />
                          )}
                          disableClearable
                          onChange={(event, value) => {
                            handleAreaChange(value);
                          }}
                        />
                      ) : (
                        (i18next.language === 'en'
                          ? originalUserInfo?.userWorkLocation?.workLocation?.nameEN
                          : originalUserInfo?.userWorkLocation?.workLocation?.name) || 'N/A'
                      )
                    }
                  />
                  <InfoRow
                    icon={homeIcon}
                    label={t(TRANSLATIONS.SUBAREA)}
                    value={
                      isEditing ? (
                        <Autocomplete
                          key="subArea"
                          options={options?.subAreas}
                          isOptionEqualToValue={(option, value) => {
                            if (value?.id) {
                              return String(option.id) === String(value.id);
                            }
                            return false;
                          }}
                          value={
                            editingUserInfo?.userWorkLocation?.subLocation !== null
                              ? options?.subAreas?.find((e: any) => {
                                const tmp = editingUserInfo?.userWorkLocation?.subLocation;
                                return String(e.id) === String(tmp?.id);
                              }) || (() => null)
                              : () => null
                          }
                          disabled={disabedFields.subArea || disabedFieldsState.subArea}
                          getOptionLabel={option => option.name}
                          style={{ minWidth: 164 }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              variant="outlined"
                              inputProps={{
                                ...params.inputProps,
                                style: {
                                  padding: 0,
                                },
                              }}
                            />
                          )}
                          disableClearable
                          onChange={(event, value) => {
                            handleSubAreaChange(value);
                          }}
                        />
                      ) : (
                        (i18next.language === 'en'
                          ? originalUserInfo?.userWorkLocation?.subLocation?.nameEn
                          : originalUserInfo?.userWorkLocation?.subLocation?.name) || 'N/A'
                      )
                    }
                  />
                </>
              )}
              <div className='assignSios__divider' />
            </div>
          )}
          {tabIndex === 0
            && (
              <div className="pt-5">
                <span>{t(TRANSLATIONS.ROUTINES)}</span>
                {isEditing ? (
                  <div className="user-info__bottom">
                    <RoutinesSelector
                      routinesByPillars={editableRoutinesPermissions || {}}
                      setFormData={setEditingUser}
                    />
                  </div>
                ) : (
                  <div className="user-info__bottom">
                    <RoutinesViewer routinesByPillars={routinesPermissions} />
                  </div>
                )}
              </div>
            )}
        </>
      )}
    </div>
  );
};

export default PermissionsTab;
