import React, { useEffect, useRef, useState } from 'react';
import './ThreeDottedMenu.scss';

type Props = {
  options: { image: string; text: string; onClick: (e: any)=>void; }[];
};

const ThreeDottedMenu = (props: Props) => {
  const { options } = props;
  const [showDropDown, setShowDropdown] = useState(false);

  const ref = useRef(null);

  useEffect(() => {
    if (!ref || !ref.current) {
      return;
    }
    const listener = (evt: any) => {
      const flyoutElement = ref.current;
      let targetElement = evt.target; // clicked element
      let insideClick = false;

      do {
        if (targetElement === flyoutElement) {
          insideClick = true;
          break;
        }
        // Go up the DOM
        targetElement = targetElement.parentNode;
      } while (targetElement);

      // This is a click outside.
      if (!insideClick) {
        if (showDropDown) {
          setShowDropdown(false);
        }
      }
    };
    document.addEventListener('click', listener);
    return () => {
      document.removeEventListener('click', listener);
    };
  }, [showDropDown]);

  return (
    <div
      role="button"
      ref={ref}
      className="config-dots d-flex flex-column"
      style={{
        alignItems: 'flex-end',
        cursor: 'pointer',
      }}
      onClick={e => {
        e.stopPropagation();
        setShowDropdown(!showDropDown);
      }}
    >
      <svg width="4" height="4">
        <ellipse cx="2" cy="2" rx="2" ry="2" fill="#D8D8D8" />
      </svg>
      <svg width="4" height="4">
        <ellipse cx="2" cy="2" rx="2" ry="2" fill="#D8D8D8" />
      </svg>
      <svg width="4" height="4">
        <ellipse cx="2" cy="2" rx="2" ry="2" fill="#D8D8D8" />
      </svg>
      {showDropDown
      && (
      <div className="three-dot-dropdown">
        {options.map(
          (option: any) => (
            <div
              role="button"
              key={option.text.toLowerCase()}
              className="item"
              onClick={e => {
                option.onClick(e);
              }}
            >
              <img src={option.image} alt={option.text} />
              <span>{option.text}</span>
            </div>
          ),
        )}
      </div>
      )}
    </div>
  );
};

export default ThreeDottedMenu;
