import React, { useState } from 'react';
import Chart from 'react-apexcharts';
import 'rc-dropdown/assets/index.css';
import { format, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';
import { useCubeQuery } from '@cubejs-client/react';

import './TotalIncidentsChart.scss';
import LoadingSpinner from 'components/LoadingSpinner';
import { set, subDays } from 'date-fns/esm';
import ChartDropdown from 'components/Dropdown/ChartDropdown';
import { lastDayOfMonth, startOfMonth, lastDayOfYear, startOfYear } from 'date-fns/esm/fp';
import mean from 'lodash.mean';
import sum from 'lodash.sum';
import { useSelector } from 'react-redux';
import { selectLocationsToAccess } from 'store/selectors/globalFilters.selectors';
import { routinesMonthSelectordropDownItems } from 'utils/dateHelpers';
import moment from 'moment';
import { selectTimezone } from 'store/selectors/timeFilter.selector';

const dropDownItemsArr = routinesMonthSelectordropDownItems(true);

const IncidentsChart = () => {
  const { t } = useTranslation();
  const timezone = useSelector(selectTimezone);

  const years = [
    {
      slug: moment().format('YYYY'),
      label: moment().format('YYYY'),
      startTime: moment().clone().startOf('year').format('yyyy-MM-DD'),
      endTime: moment().clone().endOf('year').format('yyyy-MM-DD'),
    },
    {
      slug: moment().subtract(1, 'years').format('YYYY'),
      label: moment().subtract(1, 'years').format('YYYY'),
      startTime: moment().subtract(1, 'years').startOf('year').format('yyyy-MM-DD'),
      endTime: moment().subtract(1, 'years').endOf('year').format('yyyy-MM-DD'),
    },
  ];

  const dropDownItems = [{
    slug: 'last-7-days',
    label: t(TRANSLATIONS.LAST_7_DAYS),
  }, {
    slug: 'last-30-days',
    label: t(TRANSLATIONS.LAST_30_DAYS),
  }, {
    slug: 'month-to-date',
    label: t(TRANSLATIONS.MONTH_TO_DATE),
  }, {
    slug: 'last-90-days',
    label: t(TRANSLATIONS.LAST_90_DAYS),
  }, {
    slug: 'year-to-date',
    label: t(TRANSLATIONS.YEAR_TO_DATE),
  },
  ...dropDownItemsArr,
  ...years,
  ];

  const [selectedDropDownItem, setSelectedDropDownItem] = useState<any>(dropDownItems[2]);
  const [dropDownOpen, setDropDownOpen] = useState(false);

  const locationsToAccess = useSelector(selectLocationsToAccess);
  let timeLabel: string = t(TRANSLATIONS.MONTH_TO_DATE);

  let chartApiResponse = null as any;

  const getDateRange = () => {
    const currentDate = new Date();
    let filter = ['', ''] as any[];
    if (selectedDropDownItem.slug === 'last-7-days') {
      filter = [subDays(currentDate, 14), currentDate];
      timeLabel = t(TRANSLATIONS.LAST_7_DAYS);
    } else if (selectedDropDownItem.slug === 'last-30-days') {
      filter = [subDays(currentDate, 60), currentDate];
      timeLabel = t(TRANSLATIONS.LAST_30_DAYS);
    } else if (selectedDropDownItem.slug === 'last-90-days') {
      filter = [subDays(currentDate, 180), currentDate];
      timeLabel = t(TRANSLATIONS.LAST_90_DAYS);
    } else if (selectedDropDownItem.slug === 'month-to-date') {
      const lastMonthDay = lastDayOfMonth(currentDate);
      const firstDayLastMonth = startOfMonth(subDays(lastMonthDay, 32));
      filter = [firstDayLastMonth, lastMonthDay];
    } else if (selectedDropDownItem.slug === 'year-to-date') {
      const lastYearDay = lastDayOfYear(currentDate);
      const firstDayLastYear = startOfYear(subDays(lastYearDay, 367));
      filter = [firstDayLastYear, lastYearDay];
      timeLabel = t(TRANSLATIONS.YEAR_TO_DATE);
    } else if (selectedDropDownItem?.slug?.split(' ')?.length === 2) {
      const dateFromSlug = selectedDropDownItem.slug.split(' ');
      const dateMonth = moment().year(dateFromSlug[1]).week(selectedDropDownItem?.content?.weekNumber + 1).format('M');

      const monthFirstDate = new Date(+dateFromSlug[1], +dateMonth - 1, +1);
      const lastMonthDay = lastDayOfMonth(monthFirstDate);
      const firstDayLastMonth = set(lastMonthDay, { date: 1 });
      filter = [firstDayLastMonth, lastMonthDay];
    }
    if (selectedDropDownItem?.slug?.length === 4) {
      filter = [
        selectedDropDownItem?.startTime,
        selectedDropDownItem?.endTime,
      ];
      return filter;
    }

    return [format(filter[0], 'yyyy-MM-dd'), format(filter[1], 'yyyy-MM-dd')];
  };

  const getGranularity = (): 'day' => {
    return 'day';
  };

  const dateRange = getDateRange() as unknown as string[];
  const granularity = getGranularity();

  const { resultSet: countResult } = useCubeQuery({
    measures: ['Incidents.count'],
    timeDimensions: [{
      dimension: 'Incidents.incidentdatetime',
      granularity,
      dateRange,
    }],
    order: {
      'Incidents.incidentdatetime': 'asc',
    },
    filters: [{
      dimension: 'Incidents.locationId',
      operator: 'equals',
      values: locationsToAccess,
    },
    {
      dimension: 'Incidents.deletedat',
      operator: 'notSet',
    },
    {
      dimension: 'Incidents.typeofissue',
      operator: 'equals',
      values: ['CONDITION', 'ACT'],
    }],
    timezone,
  });
  const { resultSet: uniqueCount } = useCubeQuery({
    measures: ['Incidents.uniqueReporters'],
    timeDimensions: [{
      dimension: 'Incidents.incidentdatetime',
      granularity,
      dateRange,
    }],
    order: {
      'Incidents.incidentdatetime': 'asc',
    },
    filters: [{
      dimension: 'Incidents.locationId',
      operator: 'equals',
      values: locationsToAccess,
    },
    {
      dimension: 'Incidents.deletedat',
      operator: 'notSet',
    },
    {
      dimension: 'Incidents.typeofissue',
      operator: 'equals',
      values: ['CONDITION', 'ACT'],
    }],
    timezone,
  });
  // const { resultSet: uniqueReportersQ }: { resultSet: any } = useCubeQuery({
  //   measures: ['Incidents.uniqueReporters'],
  //   timeDimensions: [{
  //     dimension: 'Incidents.incidentdatetime',
  //     dateRange,
  //   }],
  //   filters: [{
  //     dimension: 'Incidents.locationId',
  //     operator: 'equals',
  //     values: locationsToAccess,
  //   }],
  // });
  const { resultSet: headCount }: { resultSet: any } = useCubeQuery({
    measures: ['Users.belongsToLocation'],
    filters: [{
      dimension: 'Users.locationId',
      operator: 'equals',
      values: locationsToAccess,
    }],
  });

  if (countResult?.series()[0] && uniqueCount) {
    const incidents = countResult.series()[0];
    const uniqueU = uniqueCount.series()[0];
    // const uniqueReporters = uniqueReportersQ?.rawData()[0]['Incidents.uniqueReporters'];

    const midOfSeries = Math.ceil(incidents.series.length / 2);
    const incidentsData = incidents.series.map((v: any) => v.value);
    const uniqueReportersData = uniqueU.series.map((v: any) => v.value);
    const avgPerUserData = incidents.series.map(
      (v: any, i: number) => ((v.value / uniqueU.series[i].value) || 0),
    );

    chartApiResponse = {
      totalReports: sum(incidentsData.slice(midOfSeries)),
      uniqueReporters: sum(uniqueReportersData.slice(midOfSeries)),
      averageReportsByUser: mean(avgPerUserData.slice(midOfSeries)).toFixed(1),
      totalUsers: headCount?.rawData()[0]['Users.belongsToLocation'], // Total users on the DB. Explained more below.
      incidentsByTime: {
        data: incidentsData,
        labels: incidents.series.map((v: any) => v.x),
      },
      uniqueReportersByTime: {
        data: uniqueReportersData,
        labels: uniqueU.series.map((v: any) => v.x),
      },
      avgPerUser: {
        data: avgPerUserData,
        labels: uniqueU.series.map((v: any) => v.x),
      },
    };
  }

  // @ts-ignore
  const handleDropDownChange = ({ key }) => {
    setSelectedDropDownItem(dropDownItems.find(
      item => item.slug === key,
    ) || dropDownItems[0]);
    setDropDownOpen(false);
  };

  const getChartVars = () => {
    if (chartApiResponse === null) {
      return ({
        solidLineData: [],
        dottedLineData: [],
        averageLineData: [],
        labels: [],
      });
    }
    let seriesToConsider = null;
    let labelsToConsider: string[] = (null as unknown) as string[];

    seriesToConsider = chartApiResponse.incidentsByTime.data;
    labelsToConsider = chartApiResponse.incidentsByTime.labels;

    if (seriesToConsider === null) {
      seriesToConsider = chartApiResponse.incidentsByTime.data.map(
        (d: number, i: number) => parseFloat((chartApiResponse.incidentsByTime.data[i]
          / chartApiResponse.uniqueReportersByTime.data[i]
        ).toFixed(2)),
      );
      labelsToConsider = chartApiResponse.incidentsByTime.labels;
    }

    const midOfSeries = Math.floor(seriesToConsider.length / 2);
    const solidLineData = seriesToConsider.slice(midOfSeries);
    const dottedLineData = seriesToConsider.slice(0, midOfSeries);
    const theLabels: string[] = labelsToConsider.slice(midOfSeries);
    const averageLinePos = (solidLineData.reduce(
      (acc: number, node: number) => acc + node,
      0,
    )) / solidLineData.length;
    const averageLinePoints = new Array(solidLineData.length).fill(averageLinePos.toFixed(2));
    let n: number = 0;
    return ({
      solidLineData,
      dottedLineData,
      averageLineData: averageLinePoints,
      labels: theLabels.map((l: string) => {
        n += 1;
        // Showing only the first, the middle and the last labels
        if (n === 1 || n === Math.floor(theLabels.length / 2) || n === theLabels.length - 1) return format(parseISO(l), 'd MMM');
        return '';
      }),
    });
  };

  const chartVars = getChartVars();

  const chartData = {
    series: [{
      name: timeLabel,
      data: chartVars.solidLineData,
      type: 'line',
    },
    {
      name: t(TRANSLATIONS.PRECIDING_PERIOD),
      data: chartVars.dottedLineData,
      type: 'line',
    },
    {
      name: t(TRANSLATIONS.AVERAGE),
      data: chartVars.averageLineData,
      type: 'line',
    }],
    options: {
      colors: ['#70AD47', '#4472C4', '#F39C1F'],
      zoom: {
        enabled: true,
        autoScaleYaxis: true,
      },
      chart: {
        toolbar: {
          show: false,
        },
        height: 350,
        type: 'line',
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [1.5, 1.5, 1.5],
        curve: 'straight',
        dashArray: [0, 3, 0],
        colors: ['#70AD47', '#4472C4', '#F39C1F'],
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6,
        },
      },
      xaxis: {
        categories: chartVars.labels,
        labels: {
          rotate: 0,
          style: {
            // fontSize: '10px',
            color: ['#878787', '#878787', '#878787'],
            cssClass: 'apexcharts-xaxis-label',
          },
        },
      },
      yaxis: {
        opposite: true,
        tickAmount: 4,
        decimalsInFloat: 0,
        forceNiceScale: true,
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'right',
        fontSize: 10,
        offsetY: 3,
        markers: {
          width: 12,
          height: 2,
          radius: 0,
        },
      },
      tooltip: {
        y: [
          {
            title: {
              formatter(val: any) {
                return `${val}`;
              },
            },
          },
          {
            title: {
              formatter(val: any) {
                return `${val}`;
              },
            },
          },
        ],
      },
      grid: {
        borderColor: '#f1f1f1',
        padding: {
          top: 0,
          right: 25,
          bottom: 20,
          left: 25,
        },
      },
    },
  };

  const isLoading = !(chartApiResponse && locationsToAccess.length);

  return (
    <div className="total-incidents-chart">
      <div
        className="tabs-container"
      >
        {t(TRANSLATIONS.REPORT_HISTORY)}
      </div>
      <div>
        {isLoading ? (
          <LoadingSpinner height={250} />
        ) : (
          // @ts-ignore
          <Chart options={chartData.options} series={chartData.series} type="line" height={280} />
        )}
        <ChartDropdown
          onChange={handleDropDownChange}
          selectedDropDownItem={selectedDropDownItem}
          dropDownItems={dropDownItems}
          dropDownOpen={dropDownOpen}
          setDropDownOpen={setDropDownOpen}
          labelClassName={`label-default ${isLoading ? 'dropdown-loading-labels' : 'dropdown-label-o'}`}
        />
      </div>
    </div>
  );
};

export default IncidentsChart;
