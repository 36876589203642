import React, { useState, useEffect, useMemo } from 'react';
import { SelectInfo } from 'rc-menu/lib/interface';
import Chart from 'react-apexcharts';
import request from 'utils/request';
import { getMonthsDropDown, getYearDropDownFromBaseYear, months } from 'utils/dateHelpers';
import { useTranslation } from 'react-i18next';
import { getYear, getMonth } from 'date-fns';
import EmptyState from 'components/EmptyState/EmptyState';
import ChartDropdown from '../../../components/Dropdown/ChartDropdown';
import ChartByBU from './ChartPerBU';
import ChartsPerBUPerWeek from './ChartPerBUPerWeek';
import TicketsTable from './Table';
import {
  DropYearAndMonthDropDown,
  getBusPendingProgressAndClosedFromChart,
} from './compainceSIF.utils';
import './ComplainceSIF.scss';

const ComplainceSIF = () => {
  const { t } = useTranslation();
  // eslint-disable-next-line
  const monthsDropDown = useMemo(() => getMonthsDropDown(t), []);
  const yearsDropDown = useMemo(() => getYearDropDownFromBaseYear(), []);

  function selectedMonthIntiailStateCalculator() {
    const monthIndex = getMonth(new Date());
    return getMonthsDropDown(t, undefined, undefined, [
      months[monthIndex],
    ])[0] as DropYearAndMonthDropDown;
  }
  function selectedYearInitalStateCalculator() {
    const year = getYear(new Date());
    return getYearDropDownFromBaseYear(undefined, undefined, undefined, undefined, [
      year,
    ])[0] as DropYearAndMonthDropDown;
  }

  const [periodDropDown, setPeriodDropDown] = useState(false);
  const [yearDropDown, setYearDropDown] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState<DropYearAndMonthDropDown>(
    selectedMonthIntiailStateCalculator,
  );
  const [selectedYear, setSelectedYear] = useState<DropYearAndMonthDropDown>(
    selectedYearInitalStateCalculator,
  );
  const [response, setResponse] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const { bu, pending, closed, progress } = getBusPendingProgressAndClosedFromChart(response);

  const handleMonthChange = ({ key }: SelectInfo) => {
    setSelectedMonth(
      monthsDropDown.find(month => month.slug === Number(key)) as
       DropYearAndMonthDropDown,
    );
    setPeriodDropDown(false);
  };

  const handleYearChange = ({ key }: SelectInfo) => {
    setSelectedYear(
      yearsDropDown.find(year => year.slug === Number(key)) as DropYearAndMonthDropDown,
    );
    setYearDropDown(false);
  };

  useEffect(() => {
    const getChartBuList = async () => {
      setLoading(true);
      try {
        const url: string = '/api/v1/analytics/dashboard';
        const result = await request.get(url, {
          month: selectedMonth.slug,
          year: selectedYear.slug,
        });
        setResponse(result || []);
        setLoading(false);
      } catch (error) {
        console.log('getChartBuList e()', error);
        setLoading(false);
      }
    };
    getChartBuList();
  }, [selectedMonth, selectedYear]);

  const renderRadialChart = () => {
    let totalPending = 0;
    let totalClosed = 0;
    response.forEach((responseItem: any) => {
      totalPending += responseItem.pending;
      totalClosed += responseItem.closed;
    });

    const getRadialChartData: string = (
      (totalClosed / (totalPending + totalClosed) || 0) * 100
    ).toFixed(2);
    return (
      <>
        <Chart
          type="radialBar"
          height={310}
          options={{
            legend: { show: false },
            colors: ['#5572B9'],
            labels: ['MAZ Cerrados'],
            plotOptions: {
              pie: {
                donut: {
                  labels: {
                    show: true,
                  },
                },
              },
            },
          }}
          // @ts-ignore
          series={[getRadialChartData]}
        />
        <div
          className="card routines-home__card complaince_radialBar_card"
          style={{ margin: 'auto', top: -10 }}
        >
          <div className="data">
            <b>Cerrados</b>
            <span>{totalClosed}</span>
          </div>
          <div className="data">
            <b>Pendientes</b>
            <span>{totalPending}</span>
          </div>

          <div className="data">
            <b>Totales</b>
            <span>{totalClosed + totalPending}</span>
          </div>
        </div>
      </>
    );
  };

  const renderMemorizedComponents = useMemo(
    () => (
      <>
        <ChartsPerBUPerWeek type="all" />
        <ChartsPerBUPerWeek type="cac" />
        <TicketsTable />
      </>
    ),
    [],
  );

  return (
    <div className="sif-complaince container-fluid overview-wrapper routines-home">
      <div className="row">
        <div className="col-8 pr-0">
          <div className="routines-home__title">Cumplimiento de acciones SIF</div>
          <h2 className="routines-home__title">MAZ</h2>
          <div className="headerFlex">
            <div className="drop-downs-rowed">
              <ChartDropdown
                onChange={handleMonthChange}
                selectedDropDownItem={selectedMonth}
                dropDownItems={getMonthsDropDown(t) as DropYearAndMonthDropDown[]}
                dropDownOpen={periodDropDown}
                setDropDownOpen={setPeriodDropDown}
                labelClassName="label-default"
              />
              <ChartDropdown
                onChange={handleYearChange}
                selectedDropDownItem={selectedYear}
                dropDownItems={
                  getYearDropDownFromBaseYear() as DropYearAndMonthDropDown[]
                }
                dropDownOpen={yearDropDown}
                setDropDownOpen={setYearDropDown}
                labelClassName="label-default"
              />
            </div>
          </div>
          <div className="card routines-home__card">
            <ChartByBU {...{ progress, pending, closed, bu, loading, response }} />
          </div>
        </div>
        <div className="col-4 pl-0 radial-chart-container">
          {!!(!loading && response.length) && renderRadialChart()}
          {!loading && !response.length && <EmptyState className="no-chart-margin" />}
        </div>
      </div>
      {renderMemorizedComponents}
    </div>
  );
};

export default ComplainceSIF;
