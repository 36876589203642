import React, { useState } from 'react';
import ChartDropdown from 'components/Dropdown/ChartDropdown';
import { getYearList } from 'utils/dateHelpers';
import IncidentsOverTime from './IncidentsOverTime/IncidentsOverTime';

import LtiVsSif from './LTIvsSIF/LTIvsSIF';
import ParetoSIF from './SIFCauses/ParetoSIF';
import Pyramid from './Pyramid/Pyramid';

const Accidentability = () => {
  // When the filters are in scope then change the next
  // variable i.e showYearAndMonthDropDown to true
  const showYearAndMonthDropDown = false;
  // const startYear = new Date('2021');
  // TODO
  // Comment out these two lines and uncomment next two to have this feature out of scope
  // Replace 2021 with 2022 before production or staging deployment
  // 2021 is for development purpose and to show client the working of the feature
  // const endYear = new Date('2021');
  // const yearOptions = getYearList(startYear, endYear);
  const currentYear = new Date();
  const yearOptions = getYearList(currentYear, currentYear);
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [businessYear, setBusinessYear] = useState(yearOptions[0]);

  const handleDropDownChange = ({ key }: any) => {
    setBusinessYear(yearOptions.find(item => item.slug === key) || yearOptions[0]);
    setDropDownOpen(false);
  };

  return (
    <div className="container-fluid overview-wrapper">
      {showYearAndMonthDropDown && (
      <>
        <p className="screen-header">Accidentability</p>
        <ChartDropdown
          onChange={handleDropDownChange}
          selectedDropDownItem={businessYear}
          dropDownItems={yearOptions}
          dropDownOpen={dropDownOpen}
          setDropDownOpen={setDropDownOpen}
          labelClassName="label-default"
        />
      </>
      )}
      <div className="row">
        <div className="col-7 card overview-card">
          <div className="mb-3 p-0">
            <Pyramid year={businessYear} />
          </div>
        </div>
        <div className="col-5 card overview-card">
          <div className="mb-3 p-0">
            <LtiVsSif year={businessYear} showMonthDropDown={showYearAndMonthDropDown} />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 card overview-card">
          <div className="p-0 no-margin">
            <IncidentsOverTime year={businessYear} />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 card overview-card">
          <div className="p-0 no-margin">
            <ParetoSIF year={businessYear} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accidentability;
