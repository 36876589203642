/* eslint-disable @typescript-eslint/default-param-last */
import { fromJS } from 'immutable';
import {
  SET_INCIDENTS,
  SET_INCIDENTS_BY_TIME,
  SET_INCIDENTS_PAGINATION_INFO,
} from '../actions/distributionCenter.actions';

const INITIAL_STATE = fromJS({});

const distributionCenterReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SET_INCIDENTS:
      return state.set('incidents', fromJS(action.incidents));
    case SET_INCIDENTS_BY_TIME:
      return state.set('incidentsByTime', fromJS(action.incidentsByTime));
    case SET_INCIDENTS_PAGINATION_INFO:
      return state.set('incidentsPaginationInfo', fromJS(action.incidentsPaginationInfo));
    default:
      return state;
  }
};

export default distributionCenterReducer;
