/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react';
// @ts-ignore
import { generateQueryDates, getMonthsDropDown, getYearDropDownFromBaseYear } from 'utils/dateHelpers';
import './WebPortal5S.scss';
import BasicButton from 'components/Button/BasicButton';
import ButtonWithTooltip from 'components/Button/ButtonWithTooltip';
import { useSelector } from 'react-redux';
import { selectLocationsToAccess, selectSelectedLocation, selectUserAccesses } from 'store/selectors/globalFilters.selectors';
import LoadingSpinner from 'components/LoadingSpinner';
import { useTranslation } from 'react-i18next';
import request from 'utils/request';
import { toast } from 'react-toastify';
import Iframe from 'components/Iframe';
import ChartTabs from 'components/Tabs/ChartTabs';
import Modal from 'components/Modal';
import ProgressBar from 'components/ProgressBar';
import { TRANSLATIONS } from 'types/enums';
import axios from 'axios';
import Header from 'components/Header';
import { CircularProgress } from '@mui/material';
import { selectUser } from 'store/selectors/user.selectors';
import ChartDropdown from 'components/Dropdown/ChartDropdown';
import ImageLinks from 'utils/ImageLinks';
import WebPortal5SMain from './5SMain/5SMain';

export enum SECTION_TYPES {
  Seleccionar = 'Clasificar',
  Ordenar = 'Ordenar',
  Limpiar = 'Limpiar',
  Estandarizar = 'Estandarizar',
  Disciplina = 'Disciplina',

}

export enum UploadErrorMessage {
  CANCEL_FILE_UPLOAD = 'CANCEL_FILE_UPLOAD',
}

export enum FiveSDropDowns {
  startMonth = 'startMonth',
  endMonth = 'endMonth',
  year = 'year',
}

const { CancelToken } = axios;

const WebPortal5S = () => {
  const { t } = useTranslation();

  const cancelSource = useRef<any>(null);
  const isRequestCancelledManually = useRef<any>(false);
  const loggedInUser = useSelector(selectUser);
  const { email: loggedInUserEmail } = loggedInUser.toJS();
  const userHasAccesses = useSelector(selectUserAccesses)?.length > 0;
  const tooltipMessage = t(TRANSLATIONS.YOU_DONT_HAVE_ACCESS_TO_LOCATION_PLEASE_CONTACT_SUPERUSER);
  const { SelectDCImg } = ImageLinks;

  const tabs = [
    {
      title: 'Ejecucion',
      type: 'Ejecucion',
      value: '',
      pageName: 'ReportSectiona37e8496a828878363dc',
      index: 0,
    },
    {
      title: 'Resultados',
      type: 'Resultados',
      value: '',
      pageName: 'ReportSection?',
      index: 1,
    },
    {
      title: 'Vehículos',
      type: 'Vehículos',
      value: '',
      pageName: 'ReportSectiona879bc829cee6937ae0e',
      index: 2,
    },
    {
      title: 'Adherencia por usuario',
      type: 'Adherencia por usuario',
      value: '',
      pageName: 'ReportSectione151b4cd50b33b3437f6',
      index: 3,
    },
  ];

  const monthArr = [
    t(TRANSLATIONS.JANUARY),
    t(TRANSLATIONS.FEBRUARY),
    t(TRANSLATIONS.MARCH),
    t(TRANSLATIONS.APRIL),
    t(TRANSLATIONS.MAY),
    t(TRANSLATIONS.JUNE),
    t(TRANSLATIONS.JULY),
    t(TRANSLATIONS.AUGUST),
    t(TRANSLATIONS.SEPTEMBER),
    t(TRANSLATIONS.OCTOBER),
    t(TRANSLATIONS.NOVEMBER),
    t(TRANSLATIONS.DECEMBER),
  ];

  const getLatestYearsAndCurrentYear = () => {
    const localDate: Date = new Date();
    const baseYear: number = 2021;
    const currentYear: number = localDate.getUTCFullYear();
    const years: any[] = [];

    for (let i = baseYear; i <= currentYear; i++) {
      const currentYearToString: string = i.toString();

      years.push({
        label: currentYearToString,
        slug: currentYearToString,
        year: i,
      });
    }

    return years.reverse();
  };

  const monthOptions: any = monthArr.map((monthSlug, monthIndex) => ({
    slug: monthSlug,
    label: monthSlug.substring(0, 1).toUpperCase() + monthSlug.substring(1),
    month: monthSlug,
    monthNumber: monthIndex + 1,
  }));

  const yearOptions = getLatestYearsAndCurrentYear();

  const getCurrentMonth = () => {
    const localDate: Date = new Date();
    const monthIndexToGet: number = localDate.getUTCMonth();

    return monthOptions[monthIndexToGet];
  };

  const getCurrentYear = () => {
    const localDate: Date = new Date();
    const currentYear: number = localDate.getUTCFullYear();
    const yearDataToReturn = yearOptions.find(year => Number(year.label) === currentYear);
    return yearDataToReturn ?? yearOptions[0];
  };

  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [uploading, setLoading] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false);
  const [showProgressBar, setShowProgressBar] = useState<boolean>(false);
  const [progressPercentage, setProgressPercentage] = useState<number>(0);
  const monthsDropDown = useMemo(() => getMonthsDropDown(t), [t]);
  const yearsDropDown = useMemo(() => getYearDropDownFromBaseYear(), []);

  const fileRef = useRef<HTMLInputElement>(null);
  const [periodDropDown, setPeriodDropDown] = useState(false);
  const [yearDropDown, setYearDropDown] = useState(false);
  const [downloadError, setDownloadError] = useState<boolean>(false);
  const [sentViaEmail, setSentViaEmail] = useState<boolean>(false);
  const [fileDownloadLink, setFileDownloadLink] = useState<string>('');
  const [isDropDownOpen, setIsDropDownOpen] = useState<FiveSDropDowns | undefined>(undefined);
  const [selectedStartMonth, setSelectedStartMonth] = useState(monthOptions[0]);
  const [selectedEndMonth, setSelectedEndMonth] = useState(getCurrentMonth());
  const [selectedYear, setSelectedYear] = useState(getCurrentYear());

  const isDCSelected = useSelector(selectSelectedLocation);

  const dropDownOpenCloseHandler = (opened: boolean, dropDownType: FiveSDropDowns) => {
    return opened ? setIsDropDownOpen(dropDownType) : setIsDropDownOpen(undefined);
  };

  const validMonthOptions: any = useMemo(() => {
    const localDate: Date = new Date();
    const currentYear: number = localDate.getUTCFullYear();
    if (selectedYear.year === currentYear) {
      const currentMonthIndex = localDate.getUTCMonth();
      const possibleMonths = monthOptions.slice(0, currentMonthIndex + 1);
      if (isDCSelected?.locationSlug) {
        return [
          { slug: t(TRANSLATIONS.ALL), label: t(TRANSLATIONS.ALL), allMonths: true },
          ...possibleMonths,
        ];
      }
      return possibleMonths;
    }
    if (isDCSelected?.locationSlug) {
      return [
        { slug: t(TRANSLATIONS.ALL), label: t(TRANSLATIONS.ALL), allMonths: true },
        ...monthOptions,
      ];
    }
    return monthOptions;
    // eslint-disable-next-line
  }, [selectedYear, isDCSelected]);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const locations = useSelector(selectLocationsToAccess);

  useEffect(() => {
    const init = async () => {
      try {
        setLoading(true);
        const response = await request.get('/api/v1/5s/5S-annual-template-link');
        setFileDownloadLink(response.annualPlanUploadURL);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    init();
  // eslint-disable-next-line
  }, []);

  const handleCloseInstructions = () => setIsOpen(false);
  const handleUploadButton = () => {
    if (fileRef.current !== null) {
      fileRef.current.click();
    }
  };

  const handleOpenInstructions = () => setIsOpen(true);

  const OWDEmptyPage = () => {
    return (
      <div className="no_location" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: 20, height: '100%', width: '100%', alignItems: 'center' }}>
        <img src={SelectDCImg} style={{ height: '300px', marginTop: 40 }} alt="Select Location" />
        <h3 style={{ marginTop: 20 }}>{t(TRANSLATIONS.SELECT_DISTRIBUTION_CENTER)}</h3>
        <p style={{ width: '550px', textAlign: 'center' }}>{t(TRANSLATIONS.SELECT_DISTRIBUTION_5S_PARAGRAPH)}</p>
      </div>
    );
  };

  const closeModal = () => {
    setSentViaEmail(false);
    setDownloadError(false);
    setShowDownloadModal(false);
    setShowProgressBar(false);
    setProgressPercentage(0);
  };

  const onPressCancelRequest = () => {
    setSentViaEmail(false);
    setDownloadError(false);
    setShowDownloadModal(false);
    setShowProgressBar(false);
    setProgressPercentage(0);
    if (cancelSource?.current) {
      isRequestCancelledManually.current = true;
      cancelSource.current.cancel();
    }
  };

  const onClickDownload = () => {
    setShowDownloadModal(true);
  };

  const downloadFiveSExcel = useCallback(
    async () => {
      cancelSource.current = CancelToken.source();
      try {
        setSentViaEmail(false);
        setDownloadError(false);
        setShowDownloadModal(true);
        setShowProgressBar(true);
        const { timezoneOffsetStart } = generateQueryDates();
        const url = `/api/v1/5s/excel?locationIds=${JSON.stringify(locations)}&timezoneOffset=${timezoneOffsetStart}`;
        const res = await axios({
          url,
          method: 'GET',
          onDownloadProgress: (progressEvent: any) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setProgressPercentage(percentCompleted);
          },
          params: {
            month: selectedStartMonth?.monthNumber,
            startMonth: selectedStartMonth?.monthNumber,
            endMonth: selectedEndMonth?.monthNumber,
            year: selectedYear?.slug,
          },
          cancelToken: cancelSource.current.token,
        });
        if (res.data?.sentViaEmail) {
          return setSentViaEmail(true);
        }
        const downloadUrl = res.data;
        const a = document.createElement('a');
        a.href = downloadUrl;
        setShowDownloadModal(false);
        setShowProgressBar(false);
        setProgressPercentage(0);
        a.click();
      } catch (error) {
        if (!isRequestCancelledManually.current) {
          setDownloadError(true);
          setProgressPercentage(0);
          isRequestCancelledManually.current = false;
        }
      }
    }, [locations, selectedStartMonth, selectedEndMonth, selectedYear],
  );

  const handleTemplateAttachmentsUpload = async (e: any) => {
    setLoading(true);
    const { files } = e.target;
    if (files?.length) {
      const config = {
        headers: { 'content-type': 'multipart/form-data' },
      };
      const formData = new FormData();

      formData.set('file', files[0]);

      try {
        const response = await request.post('/api/v1/5s/upload-excel', formData, config);
        setFileDownloadLink(response?.s3Url);
        setLoading(false);
        toast.success('Uploaded Successfully!');
        if (fileRef.current !== null) {
          fileRef.current.value = '';
        }
      } catch (err) {
        toast.error('Error while uploading');
      } finally {
        setLoading(false);
      }
    }
    setLoading(false);
  };

  const handleDropStartMonthChange = ({ key }: { key: any }) => {
    const selectedDropDownMonth = validMonthOptions.find(
      (item: { slug: any; }) => item.slug === key,
    );
    setSelectedStartMonth(selectedDropDownMonth || validMonthOptions[0]);
    setIsDropDownOpen(undefined);
  };

  const handleDropEndMonthChange = ({ key }: { key: any }) => {
    const selectedDropDownMonth = validMonthOptions.find(
      (item: { slug: any; }) => item.slug === key,
    );
    setSelectedEndMonth(selectedDropDownMonth || validMonthOptions[0]);
    setIsDropDownOpen(undefined);
  };

  const handleDropYearChange = ({ key }: { key: any }) => {
    const selectedDropDownYear = yearOptions.find(item => item.slug === key);
    setSelectedYear(selectedDropDownYear || yearOptions[0]);
    setIsDropDownOpen(undefined);
  };

  useEffect(() => {
    const currentYear: number = (new Date()).getUTCFullYear();
    if (selectedYear.year === currentYear) {
      const currentMonthIndex = (new Date()).getMonth();
      if (selectedStartMonth.monthNumber > currentMonthIndex + 1) {
        handleDropStartMonthChange({ key: monthArr[currentMonthIndex] });
      }
    }

  // eslint-disable-next-line
  }, [validMonthOptions]);

  const endMonthOptions = useMemo(() => {
    const startMonthIndex = validMonthOptions.findIndex(
      (month: any) => month.slug === selectedStartMonth.slug,
    );
    return validMonthOptions.slice(startMonthIndex);
  }, [selectedStartMonth, validMonthOptions]);

  useEffect(() => {
    if (endMonthOptions.length) {
      setSelectedEndMonth(endMonthOptions[endMonthOptions.length - 1]);
    }
  }, [endMonthOptions]);

  useEffect(() => {
    if (!isDCSelected?.locationSlug) {
      setSelectedStartMonth(validMonthOptions[0]);
    }
  // eslint-disable-next-line
  }, [isDCSelected]);

  return (
    <div className="container-fluid overview-wrapper">
      {showDownloadModal && (
      <Modal
        modalContainerStyles={{ width: '70vw',
          left: '20%',
          maxHeight: '90vh',
          minHeight: '20vh',
          height: 'fit-content',
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)' }}
      >
        <div className="downloadMoal">
          <p className="downloadMoal__title">{downloadError ? `${t(TRANSLATIONS.SOMETHING_WENT_WRONG)}...` : t(TRANSLATIONS.DOWNLOAD_DATA)}</p>
          {showProgressBar ? (
            <>
              <p className="downloadMoal__downloading_title">{downloadError ? t(TRANSLATIONS.DOWNLOAD_FAIL_RETRY_THE_DOWNLOAD_PLEASE) : sentViaEmail ? t(TRANSLATIONS.THE_DATA_IS_TAKING_A_LONG_TIME_TO_DOWNLOAD_A_DOWNLOAD_LINK_WILL_BE_SENT_TO_YOUR_EMAIL_TO_CONTINUE) : t(TRANSLATIONS.DOWNLOADING_DATA)}</p>
              {!downloadError && !sentViaEmail && (
                <div className="downloadMoal__progress_bar">
                  <p className="downloadMoal__progress_bar__title">{progressPercentage}%</p>
                  <ProgressBar bgcolor="#4472C4" height={16} progress={progressPercentage} />
                </div>
              )}
            </>
          ) : (
            <>
              <p className="downloadMoal__description">{t(TRANSLATIONS.THE_DATA_FOR_THE_LOCATIONS_THAT_ARE_FILTERED_IN_THE_TOP_FILTER_BAR_WILL_BE_DOWNLOADED)}</p>
              <div className="downloadMoal__header">
                <Header showLogo={false} showSettings={false} />
              </div>
              <div className="downloadMoal__yearFilter">
                <div>
                  <p className="downloadMoal__yearFilter__yearTitle">{t(TRANSLATIONS.YEAR)}</p>
                  <ChartDropdown
                    onChange={handleDropYearChange}
                    selectedDropDownItem={selectedYear}
                    dropDownItems={yearOptions}
                    dropDownOpen={isDropDownOpen === FiveSDropDowns.year}
                    setDropDownOpen={
                      (opened: boolean) => dropDownOpenCloseHandler(opened, FiveSDropDowns.year)
                    }
                    labelClassName="label-default"
                    dropdownArrowColor="#2B323B"
                  />
                </div>
                <div>
                  <p className="downloadMoal__yearFilter__monthTitle">{t(TRANSLATIONS.START_MONTH)}</p>
                  <ChartDropdown
                    onChange={handleDropStartMonthChange}
                    selectedDropDownItem={selectedStartMonth}
                    dropDownItems={validMonthOptions}
                    dropDownOpen={isDropDownOpen === FiveSDropDowns.startMonth}
                    setDropDownOpen={
                      (opened: boolean) => dropDownOpenCloseHandler(
                        opened, FiveSDropDowns.startMonth,
                      )
                    }
                    labelClassName="label-default"
                    dropdownArrowColor="#2B323B"
                  />
                </div>
                <div>
                  <p className="downloadMoal__yearFilter__monthTitle">{t(TRANSLATIONS.END_MONTH)}</p>
                  <ChartDropdown
                    onChange={handleDropEndMonthChange}
                    selectedDropDownItem={selectedEndMonth}
                    dropDownItems={endMonthOptions}
                    dropDownOpen={isDropDownOpen === FiveSDropDowns.endMonth}
                    setDropDownOpen={
                      (opened: boolean) => dropDownOpenCloseHandler(opened, FiveSDropDowns.endMonth)
                    }
                    labelClassName="label-default"
                    dropdownArrowColor="#2B323B"
                  />
                </div>
              </div>
            </>
          )}
          {!sentViaEmail ? (
            <div className="downloadMoal__button_container">
              {downloadError ? (
                <BasicButton text={t(TRANSLATIONS.RETRY_AGAIN)} className="downloadMoal__button_container__download_button" onClick={downloadFiveSExcel} />
              ) : (
                <BasicButton disabled={showProgressBar} text={t(TRANSLATIONS.DOWNLOAD)} className="downloadMoal__button_container__download_button" onClick={downloadFiveSExcel} />
              )}
              <BasicButton text={t(TRANSLATIONS.CANCEL)} className="downloadMoal__button_container__cancel_button" onClick={showProgressBar ? onPressCancelRequest : closeModal} />
            </div>
          ) : (
            <div className="downloadMoal__continue_button_container">
              <BasicButton text={t(TRANSLATIONS.CONTINUE)} className="downloadMoal__continue_button_container__continue_button" onClick={closeModal} />
            </div>
          )}
        </div>
      </Modal>
      )}
      <div className="checklist_section__body">
        {isOpen && (
          <>
            {!isDCSelected?.locationSlug ? (
              <div>
                <OWDEmptyPage />
              </div>
            ) : (
              <WebPortal5SMain />
            )}
          </>
        )}
        {!isOpen && (
          <>
            {uploading ? (
              <div className="loader">
                <CircularProgress />
              </div>
            ) : (
              <>
                <div className="chart_header">
                  <h2>Resultados 5s</h2>
                  <div className="chart_filters">
                    <ButtonWithTooltip text={t(TRANSLATIONS.DOWNLOAD_DATA)} className="checklist_section_download_button_filled" onClick={onClickDownload} disabled={!userHasAccesses} tooltipMessage={tooltipMessage} />
                    <BasicButton text="Calendarización" className="checklist_section_button_filled" onClick={handleOpenInstructions} />
                    {loggedInUserEmail.includes('arvolution') && (
                      <BasicButton text={t(TRANSLATIONS.UPDATE_TEMPLATE)} className="checklist_section_update_template_button_filled" onClick={handleUploadButton} />
                    )}
                    <input
                      ref={fileRef}
                      className="hidden"
                      type="file"
                      onChange={handleTemplateAttachmentsUpload}
                      name="file"
                      multiple
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    />
                  </div>
                </div>
                <ChartTabs
                  tabsItems={tabs}
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab as any}
                  selectedTabStyle="selected-tab"
                  customStyle="tabs-container"
                />
                <Iframe
                  iframe="https://app.powerbi.com/view?r=eyJrIjoiOTNmYTNjOTEtZmY2NS00NTdkLWJjZGMtOTA1MmVjNjMwM2E2IiwidCI6ImJlMTliNmNlLTdkMTktNGVkOS05NDgzLWIzM2I5NDI3ZTc4YyJ9"
                  iframeTitle="5S"
                  pageName={selectedTab.pageName}
                />

              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};
export default WebPortal5S;
