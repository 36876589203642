import React, { FC } from 'react';
import './FormLocationSelection.scss';
import { useTranslation } from 'react-i18next';
import IconCheckBox from 'components/IconCheckBox';
import ImageLinks from 'utils/ImageLinks';
import { TRANSLATIONS } from '../../types/enums';

type Props = {
  setSelected: any;
  selectedLocationType?: string;
  setUsualWorkLocation?: any;
  isUserFromFactory?: boolean;
};

const FormLocationSelection: FC<Props> = ({
  setSelected,
  selectedLocationType,
  setUsualWorkLocation,
  isUserFromFactory,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className="containerLayout">
      <IconCheckBox
        image={ImageLinks.warehouse}
        value="DISTRIBUTION_CENTER"
        title={t(TRANSLATIONS.IN_WAREHOUSE)}
        setSelected={setSelected}
        selectedLocationType={selectedLocationType}
        setUsualWorkLocation={setUsualWorkLocation}
      />
      {isUserFromFactory
        ? (
          <IconCheckBox
            image={ImageLinks.factory}
            value="FACTORY"
            title={t(TRANSLATIONS.IN_FACTORY)}
            setSelected={setSelected}
            selectedLocationType={selectedLocationType}
            setUsualWorkLocation={setUsualWorkLocation}
          />
        )
        : null}
    </div>
  );
};

export default FormLocationSelection;
