import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';
import { ReactComponent as RefreshIcon } from '../../../assets/icons/refresh.svg';
import './RefreshButton.scss';

interface Props {
  setBusinessUnit: Function
  enableResetFiltersButton: boolean;
}

const RefreshButton: FC<Props> = ({ setBusinessUnit, enableResetFiltersButton }: Props) => {
  const { t } = useTranslation();
  function handleClick() {
    if (enableResetFiltersButton) {
      setBusinessUnit({ name: '', slug: '' });
    }
  }
  return (
    <div
      className={`refresh-button ${enableResetFiltersButton ? 'active' : 'disabled'}`}
      onClick={handleClick}
      role="presentation"
    >
      <RefreshIcon />
      <p>{t(TRANSLATIONS.RESET)}</p>
    </div>
  );
};

export default RefreshButton;
