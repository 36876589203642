/* eslint-disable @typescript-eslint/naming-convention */
import React, { ChangeEvent, Component } from 'react';
import './AnonymousTruckComplaint.scss';
import FormHeader from 'containers/FormHeader';
import Separator from 'components/Separator';
import queryStrings from 'query-string';
import request from 'utils/request';
import FetchStatus from 'components/FetchStatus';
import { fromJS } from 'immutable';
import delay from 'utils/delay';
import { FETCH_STATUS, RISK_LEVEL } from 'types/enums';
import withLocation from 'utils/hoc/withLocation';
import IncidentDetails from './containers/IncidentDetails';
import ConfirmTruckDetails from './containers/ConfirmTruckDetails';

const IMAGE_UPLOAD_PERMISSION_ERROR = new Error();
IMAGE_UPLOAD_PERMISSION_ERROR.name = 'IMAGE_UPLOAD_PERMISSION_ERROR';
IMAGE_UPLOAD_PERMISSION_ERROR.message = 'was not able to get permission to upload image to S3';

interface TruckValidation {
  truckNumber: string;
  isValid: boolean;
}

interface TruckDetails {
  city: string;
  id: number;
  distributionCenter: {
    slug: string
  }
}

export enum DISPLAYED_PAGE {
  CONFIRM_TRUCK_DETAILS,
  INCIDENT_DETAILS,
}

export enum TRUCK_REPORT_TYPES {
  NEGLIGENCE = 'ACT',
  ACCIDENT = 'ACCIDENT',
  SUGGESTION = 'SUGGESTION',
}

class AnonymousTruckComplaint extends Component<Props, State> {
  static async requestImageUploadPermission(imageExtension: string) {
    try {
      const url = '/api/v1/image-upload';

      const response = await request.post(url, { fileExtension: imageExtension }) as {
        preSignedUrl: string;
        fileName: string;
      };

      return ({
        imageUploadData: {
          preSignedUrl: response.preSignedUrl,
          fileName: response.fileName,
        },
      });
    } catch (e) {
      console.log('e requestImageUploadPermission(): ', e.message || e);

      // set an error value which we can check for in uploadImage above to sniff for a failure
      return (
        {
          imageUploadData: IMAGE_UPLOAD_PERMISSION_ERROR.name,
        });
    }
  }

  inputRef = React.createRef<HTMLInputElement>();

  constructor(props: any) {
    super(props);
    this.state = {
      displayedPage: 0,
      validateTruckFetchStatus: FETCH_STATUS.NO_ACTIVE_REQUEST,
      sapNumber: '',
      street: '',
      description: '',
      formSubmitFetchStatus: FETCH_STATUS.NO_ACTIVE_REQUEST,
      reportType: '',
      selectedRiskLevel: '',
      locationSlug: '',
      phoneNumber: '',
    };
    this.fetchTruckDetails = this.fetchTruckDetails.bind(this);
    this.handleSapNumberChange = this.handleSapNumberChange.bind(this);
    this.handleNavigation = this.handleNavigation.bind(this);
    this.navigateTo = this.navigateTo.bind(this);
    // form details
    this.handleStreetChange = this.handleStreetChange.bind(this);
    this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleReportTypeChange = this.handleReportTypeChange.bind(this);
    this.handleRiskLevelChange = this.handleRiskLevelChange.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount(): void {
    const sapNumber = this.getSapNumberFromRoute();
    // Temp fix for incorrect QR code printed on demo
    if (sapNumber === '020179') window.location.href = '/tc?sn=70021308';
    if (sapNumber) this.fetchTruckDetails(sapNumber);
  }

  getSapNumberFromRoute() {
    const qs = queryStrings.parse(this.props.location.search);

    if (qs.sn) {
      return Array.isArray(qs.sn) ? qs.sn[0] : qs.sn;
    }
  }

  async fetchTruckDetails(sapNumber: string) {
    try {
      this.setState({ validateTruckFetchStatus: FETCH_STATUS.PENDING });
      const response: TruckDetails = await request.post('/api/v1/trucks/get-details', {
        sapNumber,
      });

      this.setState({
        cityName: response.city,
        // cityId: response.id,
        sapNumber,
        validateTruckFetchStatus: FETCH_STATUS.SUCCESS,
        truckValidation: { truckNumber: sapNumber, isValid: true },
        locationSlug: response.distributionCenter.slug,
      });
      setTimeout(() => {
        this.setState({ validateTruckFetchStatus: FETCH_STATUS.NO_ACTIVE_REQUEST });
      }, 750);
    } catch (e) {
      console.log('e fetchTruckDetails():', e);
      this.setState({
        validateTruckFetchStatus: FETCH_STATUS.FAIL,
        sapNumber,
        truckValidation: { truckNumber: sapNumber, isValid: false },
      });
      setTimeout(() => {
        this.setState({ validateTruckFetchStatus: FETCH_STATUS.NO_ACTIVE_REQUEST });
      }, 1500);
      throw e;
    }
  }

  handleSapNumberChange(e: ChangeEvent<HTMLInputElement>) {
    this.setState({ sapNumber: e.target.value });
  }

  handleNavigation(pageNumber: DISPLAYED_PAGE) {
    const { sapNumber, truckValidation } = this.state;

    if (!sapNumber) return;

    if (pageNumber === DISPLAYED_PAGE.INCIDENT_DETAILS) {
      /** check current [possibly changed] sap # against last validated
       * sap # (on mount or last user-entered sap) */
      if (sapNumber !== truckValidation?.truckNumber) {
        this.fetchTruckDetails(sapNumber)
          .then(() => {
            /** give time for the success indicator to show */
            setTimeout(() => {
              this.navigateTo(pageNumber);
            }, 700);
          });
      } else if (truckValidation?.isValid) this.navigateTo(pageNumber);
      else if (!truckValidation?.isValid) {
        this.inputRef?.current?.focus();
        this.inputRef?.current?.classList.add('error-input');
      }
    }
  }

  handleStreetChange(e: ChangeEvent<HTMLInputElement>) {
    this.setState({ street: e.target.value });
  }

  handlePhoneNumberChange(e: ChangeEvent<HTMLInputElement>) {
    this.setState({ phoneNumber: e.target.value });
  }

  handleDescriptionChange(e: ChangeEvent<HTMLTextAreaElement>) {
    this.setState({ description: e.target.value });
  }

  handleFileChange(e: ChangeEvent<HTMLInputElement>) {
    const file = e.target?.files?.[0];
    if (file) {
      this.setState({ file });
      const extension = file.name
        && `${file.name.match(/\.\w+$/)?.[0].substring(1)}`;
      // nullify any existing imageUploadData
      this.setState({
        imageUploadData: null,
      });

      AnonymousTruckComplaint.requestImageUploadPermission(extension || file.type.split('/')[1])
        .then(res => this.setState({ imageUploadData: fromJS(res.imageUploadData) }))
        .catch(err => this.setState({ imageUploadData: err.imageUploadData }));
    }
  }

  async uploadFile(): Promise<any> {
    try {
      const { imageUploadData, file } = this.state;

      // user hasn't picked a file
      if (!file || !file.size) return true;

      // in case there's an error in getting permission to upload the image to S3
      // in requestImageUploadPermission() below
      if (imageUploadData === IMAGE_UPLOAD_PERMISSION_ERROR.name) {
        throw IMAGE_UPLOAD_PERMISSION_ERROR;
      }

      // haven't received permission yet to upload image in requestImageUploadPermission() below
      if (!imageUploadData || !imageUploadData.size) {
        // check again in a sec if the request ImageUploadPermission() returned
        await delay(1000);
        return await this.uploadFile();
      }

      const url = imageUploadData.get('preSignedUrl');

      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/octet-stream',
        },
        body: file,
      });

      if (response && (response.status >= 200 && response.status <= 299)) {
        return console.log('uploadImage() uploaded image to S3 successfully');
      }

      throw Error(`error uploading image. Status ${response.status}`);
    } catch (e) {
      console.log('e uploadImage(): ', e.message || e);
      throw e;
    }
  }

  async handleSubmit() {
    try {
      this.setState({ formSubmitFetchStatus: FETCH_STATUS.PENDING });

      await this.uploadFile();

      const url = '/api/v1.1/submit-incident';

      const {
        sapNumber,
        description,
        street,
        imageUploadData,
        reportType,
        selectedRiskLevel,
        phoneNumber,
      } = this.state;

      const body = {
        typeOfIssue: 'TRUCK_REPORT',
        locationType: 'TRUCK',
        subTypeOfIssue: reportType,
        distCenterSlug: this.state.locationSlug,
        riskLevel: selectedRiskLevel || undefined,
        sapNumber,
        description,
        street,
        imageUrl: imageUploadData?.get('fileName'),
        source: 'website',
        phoneNumber,
      };

      await request.post(url, body);

      this.setState({ formSubmitFetchStatus: FETCH_STATUS.SUCCESS });
      setTimeout(() => {
        this.setState({ formSubmitFetchStatus: FETCH_STATUS.NO_ACTIVE_REQUEST });
      }, 800);
      setTimeout(() => {
        this.props.history?.push('/tc/thank-you');
      }, 600);
    } catch (e) {
      console.log('e submitForm(): ', e.message || e);
      this.setState({ formSubmitFetchStatus: FETCH_STATUS.FAIL });
      setTimeout(() => {
        this.setState({ formSubmitFetchStatus: FETCH_STATUS.NO_ACTIVE_REQUEST });
      }, 800);
      throw e;
    }
  }

  handleReportTypeChange(type: TRUCK_REPORT_TYPES) {
    if (type === this.state.reportType) return;
    this.setState({
      reportType: type,
      // reset risk level in case it was set for an accident
      selectedRiskLevel: '',
    });
  }

  handleRiskLevelChange(value: RISK_LEVEL) {
    this.setState({ selectedRiskLevel: value });
  }

  navigateTo(pageNumber: DISPLAYED_PAGE) {
    this.setState({ displayedPage: pageNumber });
  }

  render() {
    const {
      displayedPage,
      sapNumber,
      validateTruckFetchStatus,
      cityName,
      street,
      description,
      file,
      formSubmitFetchStatus,
      truckValidation,
      reportType,
      selectedRiskLevel,
      phoneNumber,
    } = this.state;

    const spinnerStyle = { marginTop: '2rem', height: '100vh' };

    return (
      <div className="anonymous-truck-complaint">
        <div className="container step-page">
          <FormHeader containerClassName="justify-start indent-1" hideSettingsLabel />
          <Separator />

          {displayedPage === DISPLAYED_PAGE.CONFIRM_TRUCK_DETAILS
          && (validateTruckFetchStatus === FETCH_STATUS.NO_ACTIVE_REQUEST
            ? (
              <ConfirmTruckDetails
                sapNumber={sapNumber}
                handleSapNumberChange={this.handleSapNumberChange}
                navigateTo={this.handleNavigation}
                inputRef={this.inputRef}
                isSapNumberValid={(truckValidation?.isValid
                    && truckValidation?.truckNumber === sapNumber) || false}
              />
            )
            : (
              <FetchStatus
                containerStyle={spinnerStyle}
                fetchStatus={validateTruckFetchStatus}
              />
            )
          )}

          {displayedPage === DISPLAYED_PAGE.INCIDENT_DETAILS
          && (formSubmitFetchStatus === FETCH_STATUS.NO_ACTIVE_REQUEST
            ? (
              <IncidentDetails
                cityName={cityName}
                sapNumber={sapNumber || ''}
                phoneNumber={phoneNumber}
                street={street}
                description={description}
                file={file}
                reportType={reportType}
                submitForm={this.handleSubmit}
                onStreetChange={this.handleStreetChange}
                onPhoneNumberChange={this.handlePhoneNumberChange}
                onDescriptionChange={this.handleDescriptionChange}
                onFileChange={this.handleFileChange}
                onReportTypeChange={this.handleReportTypeChange}
                selectedRiskLevel={selectedRiskLevel}
                onRiskLevelChange={this.handleRiskLevelChange}
              />
            )
            : (
              <FetchStatus
                containerStyle={spinnerStyle}
                fetchStatus={formSubmitFetchStatus}
              />
            )
          )}
        </div>
      </div>
    );
  }
}

type Props = {
  // router
  location: any;
  history: any;
  match: any;
};
type State = {
  displayedPage: DISPLAYED_PAGE;
  sapNumber?: string;
  cityName?: string;
  // cityId?: number;
  validateTruckFetchStatus: FETCH_STATUS;
  truckValidation?: TruckValidation;
  // form details
  street: string;
  description: string;
  file?: any;
  imageUploadData?: any;
  formSubmitFetchStatus: FETCH_STATUS;
  reportType: TRUCK_REPORT_TYPES | '';
  selectedRiskLevel: RISK_LEVEL | '';
  locationSlug: string,
  phoneNumber: string,
};

export default withLocation(AnonymousTruckComplaint);
