import React, { useState } from 'react';
import Chart from 'react-apexcharts';

import { DefaultOptions, LineOptions } from 'utils/ChartOptions';

import ChartTabs from 'components/Tabs/ChartTabs';
import CheckBoxWithIcon from 'components/CheckBoxWithIcon';

import checkedBox from 'assets/icons/checked-box.svg';
import uncheckedBox from 'assets/icons/unchecked-box.svg';
import { Query } from '@cubejs-client/core';
import getAccidentabilityQuery from 'utils/CubeObject';
import { useCubeQuery } from '@cubejs-client/react';
import { generateYearlyRange } from 'utils/dateHelpers';
import LoadingSpinner from 'components/LoadingSpinner';

import '../Accidentability.scss';
import { Item } from 'components/Dropdown/ChartDropdown/ChartDropdown';

type Props = {
  year: Item,
};

const ParetoSIF = (props: Props) => {
  const dateRange = generateYearlyRange(props.year.label);
  const checkboxOptions = ['T1', 'T2', 'Warehouse'];
  const [selectedCheckboxes, setSelectedCheckbox] = useState([checkboxOptions[0]]);

  const queryOptions = {
    dimensions: ['AnalyticsIncidents.causeOfInjury'],
    dateOptions: {
      dateRange,
      dimension: 'AnalyticsIncidents.dateOfIncidentOccurred',
    },
  };

  const { resultSet: thisYearLTIResult } = useCubeQuery(
    getAccidentabilityQuery({
      ...queryOptions,
      measure: ['AnalyticsIncidents.count'],
      filterOptions: [{
        dimension: 'AnalyticsIncidents.clasificationOfIncidents',
        operator: 'contains',
        values: ['LTI'],
      }, {
        dimension: 'AnalyticsIncidents.region',
        operator: 'contains',
        values: selectedCheckboxes,
      }],
    }) as Query,
  );

  const labelsThisYearLTI = thisYearLTIResult?.tablePivot().map(i => (i['AnalyticsIncidents.causeOfInjury']));

  const valuesThisYearLTI: any[] = [];

  const thisYearLTI = thisYearLTIResult?.tablePivot()
    .map(i => (i['AnalyticsIncidents.count']))
    .map(i => {
      if (typeof i === 'undefined') {
        return 0;
      }
      valuesThisYearLTI.push(i);
      return i;
    });

  const valuesForParetLineLTI: number[] = [];

  let prevLTI = 0;
  for (let i = 0; i < valuesThisYearLTI.length; i++) {
    const current = valuesThisYearLTI[i];
    prevLTI += current;
    valuesForParetLineLTI.push(prevLTI);
  }

  const { resultSet: thisYearSIFResult } = useCubeQuery(
    getAccidentabilityQuery({
      ...queryOptions,
      measure: ['AnalyticsIncidents.sifCount'],
      filterOptions: [{
        dimension: 'AnalyticsIncidents.region',
        operator: 'contains',
        values: selectedCheckboxes,
      }],
    }) as Query,
  );

  const labelsThisYearSIF = thisYearSIFResult?.tablePivot().map(i => (i['AnalyticsIncidents.causeOfInjury']));

  const valuesThisYearSIF: any[] = [];

  const thisYearSIF = thisYearSIFResult?.tablePivot()
    .map(i => (i['AnalyticsIncidents.sifCount']))
    .map(i => {
      if (typeof i === 'undefined') {
        return 0;
      }
      valuesThisYearSIF.push(i);
      return i;
    });

  const valuesForParetLineSIF: number[] = [];

  let prevSIF = 0;
  for (let i = 0; i < valuesThisYearSIF.length; i++) {
    const current = valuesThisYearSIF[i];
    prevSIF += current;
    valuesForParetLineSIF.push(prevSIF);
  }

  const { resultSet: thisYearTRIResult, isLoading } = useCubeQuery(
    getAccidentabilityQuery({
      ...queryOptions,
      measure: ['AnalyticsIncidents.countTRI'],
      filterOptions: [{
        dimension: 'AnalyticsIncidents.region',
        operator: 'contains',
        values: selectedCheckboxes,
      }],
    }) as Query,
  );

  const labelsThisYearTRI = thisYearTRIResult?.tablePivot().map(i => (i['AnalyticsIncidents.causeOfInjury']));

  const valuesThisYearTRI: any[] = [];

  const thisYearTRI = thisYearTRIResult?.tablePivot()
    .map(i => (i['AnalyticsIncidents.countTRI']))
    .map(i => {
      if (typeof i === 'undefined') {
        return 0;
      }
      valuesThisYearTRI.push(i);
      return i;
    });

  const valuesForParetLineTRI: number[] = [];

  let prevTRI = 0;
  for (let i = 0; i < valuesThisYearTRI.length; i++) {
    const current = valuesThisYearTRI[i];
    prevTRI += current;
    valuesForParetLineTRI.push(prevTRI);
  }

  const sif = [{
    name: 'sif_column',
    type: 'column',
    data: thisYearSIF,
  }, {
    name: 'sif_line',
    type: 'line',
    data: valuesForParetLineSIF,
  }];

  const lti = [{
    name: 'lti_column',
    type: 'column',
    data: thisYearLTI,
  }, {
    name: 'lti_line',
    type: 'line',
    data: valuesForParetLineLTI,
  }];

  const tri = [{
    name: 'tri_column',
    type: 'column',
    data: thisYearTRI,
  }, {
    name: 'tri_line',
    type: 'line',
    data: valuesForParetLineTRI,
  }];

  const tabsItems = [{
    title: 'SIF',
    series: sif,
  }, {
    title: 'LTI',
    series: lti,
  },
  {
    title: 'TRI',
    series: tri,
  }];

  const [selectedTab, setSelectedTab] = useState({ title: 'SIF', series: sif });

  const customOptions = {
    ...LineOptions,
    ...DefaultOptions,
    colors: ['#777DB9', 'black'],
    xaxis: {
      categories: selectedTab?.title === 'SIF' ? labelsThisYearSIF : selectedTab.title === 'LTI' ? labelsThisYearLTI : labelsThisYearTRI,
      labels: {
        show: true,
        rotate: -45,
        rotateAlways: true,
        hideOverlappingLabels: false,
        showDuplicates: true,
        trim: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    markers: {
      size: 4,
      strokeColors: 'black',
      strokeWidth: 2,
      strokeOpacity: 1,
      fillOpacity: 1,
      discrete: [],
      shape: 'circle',
      radius: 2,
    },
  };

  const changeCheckboxes = (label: string) => {
    if (selectedCheckboxes.includes(label)) {
      setSelectedCheckbox(selectedCheckboxes.filter(checkbox => checkbox !== label));
    } else {
      setSelectedCheckbox([...selectedCheckboxes, label]);
    }
  };

  if (isLoading) return <div className="lti-vs-sif"><LoadingSpinner /></div>;

  return (
    <div className="pareto-sif">
      <div className="pareto-sif__top-row">
        <ChartTabs
          tabsItems={tabsItems}
          setSelectedTab={setSelectedTab as any}
          selectedTab={selectedTab}
        />
        {checkboxOptions.map(label => (
          <span>
            <CheckBoxWithIcon
              label={label}
              selected={selectedCheckboxes.includes(label)}
              selectedIcon={checkedBox}
              deSelectedIcon={uncheckedBox}
              onClick={() => changeCheckboxes(label)}
            />
          </span>
        ))}
      </div>
      {/* @ts-ignore  */}
      <Chart
        type="line"
        options={customOptions}
        series={selectedTab.title === 'SIF' ? sif : selectedTab.title === 'LTI' ? lti : tri}
        height="420px"
      />
    </div>
  );
};

export default ParetoSIF;
