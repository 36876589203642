import React, { useState, useEffect } from 'react';
import './IncidentsClose.scss';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';
import Pagination from '@mui/lab/Pagination';
import request from 'utils/request';
import { generateQueryDates } from 'utils/dateHelpers';
import { Incident, SpecificSubLocations, SubLocations, WorkLocation, useGetDepartmentAreaSubareaFiltersForSupplyListLazyQuery } from 'generated/graphql';
import LoadingSpinner from 'components/LoadingSpinner';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/selectors/user.selectors';
import ImageLinks from 'utils/ImageLinks';
import DatePicker from 'react-datepicker';

import { selectLocationsToAccess, selectSelectedLocationsSlug } from 'store/selectors/globalFilters.selectors';
import ChartTabs from 'components/Tabs/ChartTabs';
import i18next from 'i18next';
import { userIsSafetyManager } from 'utils/shared/canUserAccessFeature';
import BasicButton from 'components/Button/BasicButton';
import IncidentsListCard from '../components/incidents-list-card/IncidentsListCard';
import IncidentsDetails from '../components/incidents-details/IncidentsDetails';
import IncidentsFilterDropdown from '../components/incidents-filter-dropdown/IncidentsFilterDropdown';

const IncidentsCloseContainer = () => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const locationsToAccess = useSelector(selectLocationsToAccess);
  const { location } = useSelector(selectSelectedLocationsSlug);

  const [
    getDepartmentAreaSubareaFilters,
    getDepartmentAreaSubareaFiltersData,
  ] = useGetDepartmentAreaSubareaFiltersForSupplyListLazyQuery({
    variables: {
      userId: user.get('id'),
      locationsIds: locationsToAccess.map(
        (locationIdToAccess: string) => Number(locationIdToAccess),
      ),
    },
    fetchPolicy: 'cache-and-network',
  });

  const departmentsList = (
    getDepartmentAreaSubareaFiltersData?.data?.getDepartmentAreaSubareaFiltersForSupply?.departments
    || []
  ) as WorkLocation[];
  const areasList = (
    getDepartmentAreaSubareaFiltersData?.data?.getDepartmentAreaSubareaFiltersForSupply?.areas
    || []
  ) as SubLocations[];
  const subAreasList = (
    getDepartmentAreaSubareaFiltersData?.data?.getDepartmentAreaSubareaFiltersForSupply?.subAreas
    || []
  ) as SpecificSubLocations[];

  const [loading, setLoading] = useState<boolean>(false);
  const [assignedIncidentsList, setAssignedIncidentsList] = useState<string[]>([]);
  const [selectedIncident, setSelectedIncident] = useState<any>();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [incidentsList, setIncidentsList] = useState<{
    distributionCenters: any[],
    incidents: any[],
    pagination: {
      cursor?: string,
      hasMore: boolean,
      totalCount: number
    }
  }>({
    pagination: {
      cursor: undefined,
      hasMore: true,
      totalCount: 0,
    },
    incidents: [],
    distributionCenters: [],
  });
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [areasFiltered, setAreasFiltered] = useState<SubLocations[]>([]);
  const [subAreasFiltered, setSubAreasFiltered] = useState<SpecificSubLocations[]>([]);

  const initialFilters: {
    folio?: string;
    reportType?: any[];
    department?: WorkLocation[];
    area?: SubLocations[];
    subarea?: SpecificSubLocations[];
    date?: Date;
    sif?: any[];
  } = {
    folio: '',
    reportType: [],
    department: [],
    area: [],
    subarea: [],
    date: undefined,
    sif: [
      {
        name: t(TRANSLATIONS.SIF),
        value: 'IS_SIF',
      },
    ],
  };
  const [filters, setFilters] = useState(initialFilters);
  const [dropdown, setDropDown] = useState('');

  const reportTypeList = [
    {
      name: t(TRANSLATIONS.ACT),
      value: 'ACT',
    },
    {
      name: t(TRANSLATIONS.CONDITION),
      value: 'CONDITION',
    },
  ];

  const sifTypeList = [
    {
      name: t(TRANSLATIONS.SIF),
      value: 'IS_SIF',
    },
    {
      name: t(TRANSLATIONS.NO_SIF),
      value: 'NO_SIF',
    },
  ];

  const tabs = [
    {
      title: 'Para Cerrar',
      type: 'Para Cerrar',
      value: '',
      pageName: 'open',
      index: 0,
    },
    {
      title: 'Cerrados',
      type: 'Cerrados',
      value: '',
      pageName: 'closed',
      index: 1,
    },
    {
      title: 'Planeados',
      type: 'Planeados',
      value: '',
      pageName: 'planned',
      index: 2,
    },
  ];

  if (
    userIsSafetyManager(user?.get('roleDetails')?.get('slug'))
  ) {
    tabs.push({
      title: 'Asignados',
      type: 'Asignados',
      value: '',
      pageName: 'assigned',
      index: 3,
    });
  }

  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const handlePageChange = (event: any, newPage: number) => {
    setCurrentPage(newPage);
  };

  const getIncidentsList = async () => {
    setLoading(true);
    setSelectedIncident(undefined);
    try {
      const res = await request.get('/api/v1/users-assigned-incidents', {
        ...generateQueryDates(),
        filters: JSON.stringify({
          TYPE: filters.reportType,
          AREA: filters.department,
          SUBAREA: filters.area,
          SPECIFIC_SUBLOCATION: filters.subarea,
          CREATED_AT: filters.date,
          FOLIO_NUMBER: filters.folio,
          LOCATIONS: locationsToAccess,
          SIF: filters.sif,
        }),
        openOnly: selectedTab.pageName === 'open' ? true : null,
        closedOnly: selectedTab.pageName === 'closed' ? true : null,
        postponedOnly: selectedTab.pageName === 'planned' ? true : null,
        assignedOnly: selectedTab.pageName === 'assigned' ? true : null,
        includeDistCenters: true,
        pageSize,
        page: currentPage,
      });
      setLoading(false);
      setTotalPages(Math.ceil(res?.pagination?.totalCount / pageSize));
      setIncidentsList(res);
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    if (location) {
      getDepartmentAreaSubareaFilters();
      getIncidentsList();
    }
    // setLoading(true);
    // eslint-disable-next-line
  }, [filters, currentPage, locationsToAccess]);

  useEffect(() => {
    const filterAreas = areasList?.filter(
      area => filters?.department?.map(e => e.id).includes(area.departmentId),
    );
    setAreasFiltered(filterAreas);
  // eslint-disable-next-line
  }, [filters.department]);

  useEffect(() => {
    const filterSubAreas = subAreasList?.filter(
      subArea => filters?.area?.map(e => e.id).includes(subArea.areaId),
    );
    setSubAreasFiltered(filterSubAreas);
  // eslint-disable-next-line
  }, [filters.area]);

  useEffect(() => {
    if (selectedTab) {
      getIncidentsList();
    }
    // setLoading(true);
    // eslint-disable-next-line
  }, [selectedTab]);

  const handleDropDown = (val: string) => {
    if (val === dropdown) {
      setDropDown('');
    } else {
      setDropDown(val);
    }
  };

  const handleDownloadTutorial = async () => {
    window.open(
      'https://arvolution-safety-development.s3.us-east-2.amazonaws.com/SIOs/Material/ACIs+Web+-+Tutorial.pptx',
      'noreferrer',
    );
  };

  const renderBody = () => {
    if (!location) {
      return (
        <div className="no_location">
          {' '}
          <img className="w-100" src={ImageLinks.selectGlobalLocation} alt="Select Location" />
        </div>
      );
    }
    return (
      <>
        <section className="iac-filters-container">
          <div className="global_search">
            <div className="input_with_icon">
              <img src={ImageLinks.searchIcon} alt="icon" className="icon_search" />
              <input
                disabled={false}
                placeholder={t(TRANSLATIONS.SEARCH_BY_FOLIO)}
                className="in-active global-search-input"
                onChange={e => setFilters({
                  ...filters,
                  folio: e.target.value,
                })}
                value={filters.folio}
              />
              <img
                src={ImageLinks.closeMaterial}
                alt="icon"
                className={`${filters.folio ? 'active' : 'in-active'} icon_close`}
                onClick={() => setFilters({
                  ...filters, folio: '',
                })}
              />
            </div>
          </div>
          <IncidentsFilterDropdown
            dropDownItems={reportTypeList}
            selectedDropDownItem={filters.reportType}
            placeholder={t(TRANSLATIONS.TYPE_OF_REPORT)}
            itemLabelKey="name"
            itemValueKey="value"
            multiple
            onChange={event => {
              const { key } = event;
              const reportTypeExists = filters?.reportType?.some((e: any) => e.value === key);

              if (reportTypeExists) {
                setFilters({
                  ...filters,
                  reportType: filters?.reportType?.filter((e:any) => e.value !== key),
                });
              } else {
                setFilters({
                  ...filters,
                  reportType: [...(filters?.reportType || []),
                    reportTypeList.find(e => e.value === key) as any],
                });
              }
            }}
            dropDownOpen={dropdown === TRANSLATIONS.TYPE_OF_REPORT}
            setDropDownOpen={() => {
              handleDropDown(TRANSLATIONS.TYPE_OF_REPORT);
            }}
          />
          <IncidentsFilterDropdown
            dropDownItems={sifTypeList}
            selectedDropDownItem={filters.sif}
            placeholder={
              filters.sif?.length
                ? filters.sif[0].name
                : t(TRANSLATIONS.SIF)
            }
            itemLabelKey="name"
            itemValueKey="value"
            multiple
            onChange={event => {
              const { key } = event;
              const sifTypeExists = filters?.sif?.some((e: any) => e.value === key);

              if (sifTypeExists) {
                setFilters({
                  ...filters,
                  sif: filters?.sif?.filter((e: any) => e.value !== key),
                });
              } else {
                setFilters({
                  ...filters,
                  sif: [...(filters?.sif || []),
                    sifTypeList.find(e => e.value === key) as any],
                });
              }
            }}
            dropDownOpen={dropdown === TRANSLATIONS.SIF}
            setDropDownOpen={() => {
              handleDropDown(TRANSLATIONS.SIF);
            }}
          />
          <IncidentsFilterDropdown
            dropDownItems={departmentsList}
            disabled={departmentsList.length === 0}
            selectedDropDownItem={filters.department}
            placeholder={
              filters.department?.length
                ? filters.department[0].name?.split(' - ')[1] as string
                : t(TRANSLATIONS.DEPARTMENT)
            }
            itemLabelKey={i18next.language === 'en' ? 'nameEN' : 'name'}
            itemValueKey="slug"
            multiple
            onChange={event => {
              const { key } = event;
              const departmentExists = filters?.department?.some(e => e.slug === key);

              if (departmentExists) {
                setFilters({
                  ...filters,
                  department: filters?.department?.filter(e => e.slug !== key),
                  area: filters?.area?.filter(e => e.departmentSlug !== key),
                  subarea: filters?.subarea?.filter(e => e.departmentSlug !== key),
                });
              } else {
                setFilters({
                  ...filters,
                  department: [...(filters?.department || []),
                    departmentsList.find(e => e.slug === key) as any],
                  area: filters?.area?.filter(e => e.departmentSlug !== key),
                  subarea: filters?.subarea?.filter(e => e.departmentSlug !== key),
                });
              }
            }}
            dropDownOpen={dropdown === TRANSLATIONS.DEPARTMENT}
            setDropDownOpen={() => {
              handleDropDown(TRANSLATIONS.DEPARTMENT);
            }}
          />
          <IncidentsFilterDropdown
            dropDownItems={areasFiltered}
            disabled={filters?.department?.length === 0}
            selectedDropDownItem={filters.area}
            placeholder={
              filters.area?.length
                ? filters.area[0].name?.split(' - ')[1] as string
                : t(TRANSLATIONS.AREA)
            }
            itemLabelKey={i18next.language === 'en' ? 'nameEN' : 'name'}
            itemValueKey="slug"
            multiple
            onChange={event => {
              const { key } = event;
              const areaExists = filters?.area?.some(e => e.slug === key);

              if (areaExists) {
                setFilters({
                  ...filters,
                  area: filters?.area?.filter(e => e.slug !== key),
                  subarea: filters?.subarea?.filter(e => e.areaSlug !== key),
                });
              } else {
                setFilters({
                  ...filters,
                  area: [...(filters?.area || []),
                    areasList.find(e => e.slug === key) as any],
                  subarea: filters?.subarea?.filter(e => e.areaSlug !== key),
                });
              }
            }}
            dropDownOpen={dropdown === TRANSLATIONS.AREA}
            setDropDownOpen={() => {
              handleDropDown(TRANSLATIONS.AREA);
            }}
          />
          <IncidentsFilterDropdown
            dropDownItems={subAreasFiltered}
            disabled={filters?.area?.length === 0}
            selectedDropDownItem={filters.subarea}
            placeholder={
              filters.subarea?.length
                ? filters.subarea[0].name?.split(' - ')[1] as string
                : t(TRANSLATIONS.SUBAREA)
            }
            itemLabelKey={i18next.language === 'en' ? 'nameEn' : 'name'}
            itemValueKey="slug"
            multiple
            onChange={event => {
              const { key } = event;
              const subareaExists = filters?.subarea?.some(e => e.slug === key);

              if (subareaExists) {
                setFilters({
                  ...filters,
                  subarea: filters?.subarea?.filter(e => e.slug !== key),
                });
              } else {
                setFilters({
                  ...filters,
                  subarea: [...(filters?.subarea || []),
                    subAreasList.find(e => e.slug === key) as any],
                });
              }
            }}
            dropDownOpen={dropdown === TRANSLATIONS.SUBAREA}
            setDropDownOpen={() => {
              handleDropDown(TRANSLATIONS.SUBAREA);
            }}
          />
          <div className={`iac-filters-date-container ${filters.date ? 'iac-filters-date-container-selected' : ''}`}>
            <img src={ImageLinks.calendar} alt="icon" className="iac-filters-date-icon" />
            <DatePicker
              selected={filters.date}
              placeholderText={t(TRANSLATIONS.SELECT_DATE)}
              className={`iac-filters-date-input ${filters.date ? 'iac-filters-date-selected' : ''}`}
              onChange={(date: any) => {
                setFilters({
                  ...filters,
                  date,
                });
              }}
            />
          </div>
          <div
            className="iac-reset-filter"
            role="button"
            onClick={() => setFilters(initialFilters)}
          >
            {t(TRANSLATIONS.REMOVE_FILTERS)}
          </div>

        </section>
        <ChartTabs
          tabsItems={tabs}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab as any}
          selectedTabStyle="selected-tab"
          customStyle="tabs-container"
        />
        <div className="section-container">
          <div className="section-left-container">
            <div className="section-left-scroll-container">
              {loading ? <LoadingSpinner />
                : incidentsList?.incidents?.length
                  ? incidentsList.incidents?.map((incident: Incident) => {
                    return (
                      <IncidentsListCard
                        incident={incident}
                        isActive={selectedIncident?.id === incident.id}
                        onClick={() => {
                          if (!isEditing) {
                            setSelectedIncident(incident);
                          }
                        }}
                      />
                    );
                  }) : (
                    <div className="id-empty-container">
                      <div className="id-description¯">{t(TRANSLATIONS.NO_RECORDS_FOUND)}</div>
                    </div>
                  )}
            </div>
            <Pagination
              disabled={loading && isEditing}
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
            />
          </div>
          <div className="section-right-container">
            <IncidentsDetails
              detailsType="close"
              incident={selectedIncident}
              isAssigned={assignedIncidentsList?.includes(selectedIncident?.id)}
              handleIncidentAssign={() => setAssignedIncidentsList([
                ...assignedIncidentsList,
                selectedIncident?.id,
              ])}
              isEditing={isEditing}
              userToAssignList={
                incidentsList?.distributionCenters
                  ?.find(e => e?.slug === selectedIncident?.distributionCenterSlug)
                  ?.managers?.filter(
                    (manager: any) => manager?.userWorkLocation?.subAreasIds.find(
                      (
                        subAreaId: number,
                      ) => subAreaId === selectedIncident?.incidentLocation?.specificSubLocation?.id,
                    ),
                  ) || []
              }
              departmentsList={departmentsList}
              areasList={areasList}
              subAreasList={subAreasList}
              setIsEditing={setIsEditing}
              refresh={getIncidentsList}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <section className="main-container">
      <div className="main-header">
        <p className="title">{`${t(TRANSLATIONS.CLOSE)} ${t(TRANSLATIONS.ACIS)}`}</p>
        <BasicButton
          text="Tutorial"
          className="tutorial_button"
          onClick={handleDownloadTutorial}
        />
      </div>

      {renderBody()}

    </section>
  );
};

export default IncidentsCloseContainer;
