import React from 'react';
import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';
import { BusinessUnitJSON, ZoneJSON, SubzoneJSON, LocationJSON } from '../../../types/interfaces';
import SelectDropdown from '../../Dropdown/SelectDropdown';
import './Dropdown.scss';

interface Props {
  name: string;
  value: string;
  content: Array<BusinessUnitJSON | ZoneJSON | SubzoneJSON | LocationJSON>;
  onChange: (e: Event) => void;
  lastSelected?: string;
}

/* I'm also setting placeholder to the newValue, because of the way SelectDropdown sets the current
   value: on line 56 of SelectDropdown.tsx, it loops throught the dropdown content, and compares
   every value in there to the value prop, and since our values are objects,
   they are always different, so it shows the placeholder instead.
 */
function Dropdown({ name, value, content, onChange, lastSelected }: Props) {
  const { t } = useTranslation();
  // All businessUnits/zones/subzones/locations
  const all = (name === 'businessUnit' ? t(TRANSLATIONS.ALL_THE_BUS) : `${t(TRANSLATIONS.ALL)} ${lastSelected}`);
  const newValue = value || all;
  const dropdownContent = content
    .map(b => ({ value: { name: b.name, slug: b.slug }, label: b.name }));
  return dropdownContent.length > 1 ? (
    <SelectDropdown
      name={`${name}-dropdown`}
      dropdownWrapperClassName={`header-dropdown ${name}-dropdown`}
      dropDownClassName={`${name}-dropdown-element`}
      value={newValue}
      placeholder={newValue}
      dropdownContent={[{ value: { name: '', slug: '' }, label: all, className: 'all' }, ...dropdownContent]}
      onChange={onChange}
      bgColor={value ? 'white' : '#F2F2F2'}
    />
  ) : (
    <p className="header-dropdown-no-dropdown">{value}</p>
  );
}

/*
   This will allow us to use arrow functions in the onchange handler
   without the performance penalty, as every arrow function is different,
   we aren't comparing onChange values of previous and next props.
   See for me in depth explanation https://cdb.reacttraining.com/react-inline-functions-and-performance-bdff784f5578
*/
function compareProps(prevProps: Props, nextProps: Props) {
  return prevProps.name === nextProps.name
    && prevProps.value === nextProps.value
    && isEqual(prevProps.content, nextProps.content)
    && prevProps.lastSelected === nextProps.lastSelected;
}

export default React.memo(Dropdown, compareProps);
