import React, { FC, useEffect } from 'react';
import './FetchStatus.scss';
import { FETCH_STATUS } from 'types/enums';
import LoadingSpinner from 'components/LoadingSpinner';
import ImageLinks from 'utils/ImageLinks';

const FetchStatus: FC<Props> = (props: Props) => {
  const { containerStyle, fetchStatus } = props;

  // mounting and un-mounting
  useEffect(() => {
    // returned function will be called on component unmount
    return () => {
    };
  }, []);

  return (
    <div style={containerStyle} className="fetch-status-component">
      <>
        {/* this is your fetch component */}
        <>{fetchStatus === FETCH_STATUS.PENDING && (
          <LoadingSpinner />
        )}
        </>
        <>{fetchStatus === FETCH_STATUS.SUCCESS && (
          <img src={ImageLinks.checkIcon} alt="request succeeded" className="fetch-img" />
        )}
        </>
        <>{fetchStatus === FETCH_STATUS.FAIL && (
          <img src={ImageLinks.crossIcon} alt="request failed" className="fetch-img" />
        )}
        </>
      </>
    </div>
  );
};

type Props = {
  containerStyle?: any;
  fetchStatus: FETCH_STATUS;
};

// const mapStateToProps = (state: any) => {
//   return {
//
//   };
// };

// export default connect(mapStateToProps)(FetchStatus);
export default FetchStatus;
