import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';

import Iframe from 'components/Iframe';
import ChartTabs from 'components/Tabs/ChartTabs';

export default function AnalyticsCertificates() {
  const { t } = useTranslation();

  const tabs = [
    { title: t(TRANSLATIONS.SAFETY_CERTIFICATES_T2), value: '', type: 'CERTS', index: 0 },
    { title: t(TRANSLATIONS.DETAILS), value: '', type: 'DETAILS', index: 1 },
  ];

  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  return (
    <>
      <div className="container-fluid">
        <div className="row" style={{ marginBottom: -14 }}>
          <>
            <ChartTabs
              tabsItems={tabs}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab as any}
              selectedTabStyle="selected-tab"
              customStyle="tabs-container"
            />
            {selectedTab.index === 0 && (
            <div className="col-12 pl-2">
              <Iframe
                iframe="https://app.powerbi.com/reportEmbed?reportId=67e4e8fe-7bac-411b-80ef-f478e5e00b56&autoAuth=true&ctid=cef04b19-7776-4a94-b89b-375c77a8f936"
                iframeTitle="Certificaciones Safety App"
                pageName="ReportSection6746cd2b2bc5104f544f"
              />
            </div>
            )}
            {selectedTab.index === 1 && (
            <div className="col-12 pl-2">
              <Iframe
                iframe="https://app.powerbi.com/reportEmbed?reportId=67e4e8fe-7bac-411b-80ef-f478e5e00b56&autoAuth=true&ctid=cef04b19-7776-4a94-b89b-375c77a8f936"
                iframeTitle="Certificaciones Safety App"
                pageName="ReportSection6746cd2b2bc5104f544f"
              />
            </div>
            )}
          </>
        </div>
      </div>
    </>
  );
}
