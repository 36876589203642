import { useState, useEffect } from 'react';

export enum OS {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
  WINDOW_PHONE = 'WINDOW_PHONE',
}

const useOS = () => {
  const [os, setOs] = useState<string | undefined>();

  useEffect(() => {
    // @ts-ignore
    const userAgent = navigator.userAgent || navigator.vendor || window?.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return setOs(OS.WINDOW_PHONE);
    }

    if (/android/i.test(userAgent)) {
      return setOs(OS.ANDROID);
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    // @ts-ignore
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return setOs(OS.IOS);
    }
    setOs('unknown');
  }, []);

  const isAndroid = os === OS.ANDROID;
  const isIOS = os === OS.IOS;
  const isWindowPhone = os === OS.WINDOW_PHONE;

  return { isAndroid, isIOS, isWindowPhone };
};

export default useOS;
