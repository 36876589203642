import React, { FC } from 'react';
import { NameAndSlug } from 'types/interfaces';

import './Card.scss';

type Props = {
  name: string;
  counter: number;
  slug: string;
  handleClick: ({ name, slug }: NameAndSlug) => void;
  downloadComponent: any;
  isClickable?: boolean;
};

const Card: FC<Props> = ({
  name,
  counter,
  slug,
  handleClick,
  downloadComponent,
  isClickable,
}) => {
  function handleCardClicked(e:any) {
    if (e.target.id === 'location-card' && isClickable) {
      handleClick({ name, slug });
    }
  }
  return (
    <div
      className={`custom-card ${isClickable ? '' : 'no-click'}`}
      onClick={handleCardClicked}
      role="presentation"
      id="location-card"
    >
      <span id="location-card">
        <p className="" id="location-card">
          {name}
        </p>
      </span>
      <p className="custom-card__incidents" id="location-card">
        {counter?.toLocaleString() || 0}
      </p>
      <div className="custom-card__dropdown" id="dropdown">
        {downloadComponent}
      </div>
    </div>
  );
};

export default Card;
