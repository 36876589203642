/* eslint-disable no-use-before-define */
/* eslint-disable no-undef */
import React from 'react';
import { connect } from 'react-redux';
import { useNavigate, useLocation, useParams, Navigate } from 'react-router-dom';
import i18next from 'i18next';
import { UserTypes } from 'types/enums';
import {
  userOnlyHasAccessToSearchUserScreen,
  userIsBreweryPlantManager,
  userHasWebAccess,
  userCanReportSIOs,
  userOnlyHasAccessToReportSIOs,
  hasAccessToAL,
  canUserEditOperations,
} from 'utils/shared/canUserAccessFeature';

const AuthWrapper = (props: any) => {
  const lang = i18next.language; // es or en
  const {
    user,
    isAuthenticating,
    userType,
    userDetails,
  } = props;

  const location = useLocation();
  const navigate = useNavigate();
  const { locationSlug } = useParams();

  const currentPath = location.pathname;
  const hasAccessToReportSIOs = userOnlyHasAccessToReportSIOs(userDetails.get('features'), userDetails?.toJS());
  const canReportSios = userCanReportSIOs(userDetails.get('features'), userDetails?.toJS());
  const hasWebAccess = userHasWebAccess(userDetails.getIn(['roleDetails', 'slug']), userDetails.get('features'), userType, user);
  const canEditOperations = canUserEditOperations(userDetails?.toJS());
  const hasUserManagementAccess = userDetails && userDetails.get('hasUserManagementAccess');

  if (currentPath === '/logout' && userOnlyHasAccessToSearchUserScreen(userType)) {
    // eslint-disable-next-line no-undef
    window.location.replace('/logout');
  }

  // still authenticating
  if (isAuthenticating) {
    return <p style={{ textAlign: 'center', marginTop: '20px' }}>{lang === 'es' ? 'Cargando' : 'Loading'}...</p>;
  }
  if (userType === 'BackendEdit') {
    if (!currentPath.includes('backend-edit')) {
      navigate('/backend-edit', { replace: true });
      return null;
    }
  }
  if (userIsBreweryPlantManager(userDetails?.getIn(['roleDetails', 'slug']), userType)) {
    if (window.innerWidth < 500 && !currentPath.includes('mobile/daily-routine')) {
      navigate('/mobile/daily-routine', { replace: true });
      return null;
    }
    if (window.innerWidth >= 500
      && !currentPath.includes('analytics/production-planning')
      && !currentPath.includes('upload/daily-production-plannings')
      && !currentPath.includes('upload/daily-routine')
    ) {
      navigate('/analytics/production-planning', { replace: true });
      return null;
    }
  }

  if (
    userType === UserTypes.LocationSecurityDoctor
    || userType === UserTypes.LocationSecurity
    || userType === UserTypes.LocationManager
  ) {
    if (!currentPath.includes('users-blocked')) {
      navigate('/users-blocked', { replace: true });
      return null;
    }
  }
  if (currentPath.includes('/analytics') && hasWebAccess === false && canReportSios) {
    navigate('/ac', { replace: true });
    return null;
  }

  if (hasAccessToReportSIOs && !(userIsBreweryPlantManager(userDetails?.getIn(['roleDetails', 'slug']), userType))) {
    if (!currentPath.includes('/action-log') && !currentPath.includes('ac')) {
      navigate('/ac', { replace: true });
      return null;
    }
  }

  if ((!currentPath.includes('/action-log') && currentPath.includes('/ac')) || currentPath.includes('/login')) {
    if (
      (
        !hasWebAccess
        && !hasAccessToReportSIOs
        && !canReportSios
      )
      || (
        hasWebAccess
        && (
          !hasAccessToReportSIOs
          && !canReportSios
        )
      )
    ) {
      navigate('/', { replace: true });
      return null;
    }
  }

  if (currentPath.includes('/action-log')) {
    if (!hasAccessToAL(userDetails?.getIn(['roleDetails', 'slug']), userDetails?.get('features'), userDetails?.get('type'))) {
      navigate('/', { replace: true });
      return null;
    }
  }

  if ((currentPath.includes(`/locations/${locationSlug}/areas-subareas`) && (!canEditOperations && hasUserManagementAccess))
    || (currentPath.includes(`/locations/${locationSlug}/areas-subareas`) && !hasUserManagementAccess)) {
    navigate('/', { replace: true });
    return null;
  }

  // After redirecting to suitable AuthWrapper, TAKE THE USER TO THE ROUTE:
  if (user) return <props.component />;

  // in case of no user
  return <Navigate to="/login" replace />;
};

const mapStateToProps = (state: any) => {
  return {
    isAuthenticating: state.getIn(['user', 'isAuthenticating']),
    user: !!state.getIn(['user', 'email']),
    userType: state.getIn(['user', 'type']),
    userDetails: state.get('user'),
  };
};

export default connect(mapStateToProps)(AuthWrapper) as any;
