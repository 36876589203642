import React from 'react';
import { Query } from '@cubejs-client/core';
import { useCubeQuery } from '@cubejs-client/react';

import LoadingSpinner from 'components/LoadingSpinner';
import getAccidentabilityQuery from 'utils/CubeObject';

import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';

import './Pyramid.scss';
import { generateYearlyRange } from 'utils/dateHelpers';
import { Item } from 'components/Dropdown/ChartDropdown/ChartDropdown';

type Props = {
  year: Item,
};

const Pyramid = (props: Props) => {
  const { t } = useTranslation();
  const dateRange = generateYearlyRange(props.year.label);
  const pyramidCubeObject = {
    measure: ['AnalyticsIncidents.count'],
    dateOptions: {
      dateRange,
      dimension: 'AnalyticsIncidents.dateOfIncidentOccurred',
    },
    filterOptions: {
      dimension: 'AnalyticsIncidents.clasificationOfIncidents',
      operator: 'contains',
    },
  };

  const { resultSet: ltiResult, isLoading } = useCubeQuery(
    getAccidentabilityQuery({
      ...pyramidCubeObject,
      filterOptions: [{
        ...pyramidCubeObject.filterOptions,
        values: ['LTI'],
      }],
    }) as Query,
  );

  const { resultSet: fatResult } = useCubeQuery(
    getAccidentabilityQuery({
      ...pyramidCubeObject,
      filterOptions: [{
        ...pyramidCubeObject.filterOptions,
        values: ['FAT'],
      }],
    }) as Query,
  );

  const { resultSet: faiResult } = useCubeQuery(
    getAccidentabilityQuery({
      ...pyramidCubeObject,
      filterOptions: [{
        ...pyramidCubeObject.filterOptions,
        values: ['FAI'],
      }],
    }) as Query,
  );

  const { resultSet: mdiResult } = useCubeQuery(
    getAccidentabilityQuery({
      ...pyramidCubeObject,
      filterOptions: [{
        ...pyramidCubeObject.filterOptions,
        values: ['MDI'],
      }],
    }) as Query,
  );

  const { resultSet: mtiResult } = useCubeQuery(
    getAccidentabilityQuery({
      ...pyramidCubeObject,
      filterOptions: [{
        ...pyramidCubeObject.filterOptions,
        values: ['MTI'],
      }],
    }) as Query,
  );

  const { resultSet: sioResult } = useCubeQuery(
    getAccidentabilityQuery({
      ...pyramidCubeObject,
      filterOptions: [{
        ...pyramidCubeObject.filterOptions,
        values: ['Incidente - Sin lesiones'],
      }],
    }) as Query,
  );
  const { resultSet: shoResult } = useCubeQuery({
    measures: [
      'Incidents.count',
    ],
    timeDimensions: [
      {
        dimension: 'Incidents.createdat',
        dateRange,
      },
    ],
    filters: [],
    dimensions: [],
    order: {},
  });

  const { resultSet: sifResult } = useCubeQuery({
    timeDimensions: [
      { dimension: 'AnalyticsIncidents.dateOfIncidentOccurred', dateRange },
    ],
    measures: [
      'AnalyticsIncidents.count',
    ],
    filters: [{
      operator: 'contains',
      dimension: 'AnalyticsIncidents.clasificationOfIncidents',
      values: ['SIF'],
    }],
  } as Query);

  const { resultSet: incidentsBySif } = useCubeQuery({
    dimensions: ['AnalyticsIncidents.sif'],
    timeDimensions: [
      { dimension: 'AnalyticsIncidents.dateOfIncidentOccurred', dateRange },
    ],
    measures: [
      'AnalyticsIncidents.count',
    ],
  } as Query);

  const totalRecordsForTheYear: number = incidentsBySif?.tablePivot().reduce((partialSum: any, firstResult) => partialSum + firstResult['AnalyticsIncidents.count'], 0);

  const sifPotentialValue = incidentsBySif?.tablePivot()[0]?.['AnalyticsIncidents.count'] || 0;
  const sifPrecursorValue = incidentsBySif?.tablePivot()[1]?.['AnalyticsIncidents.count'] || 0;
  const actualSifValue = sifResult?.tablePivot()[0]?.['AnalyticsIncidents.count'] || 0;
  const ltiValue = ltiResult?.tablePivot()[0]?.['AnalyticsIncidents.count'] || 0;
  const sioValue = sioResult?.tablePivot()[0]?.['AnalyticsIncidents.count'] || 0;
  const faiValue = faiResult?.tablePivot()[0]?.['AnalyticsIncidents.count'] || 0;
  const mtiValue = mtiResult?.tablePivot()[0]?.['AnalyticsIncidents.count'] || 0;
  const mdiValue = mdiResult?.tablePivot()[0]?.['AnalyticsIncidents.count'] || 0;
  const fatValue = fatResult?.tablePivot()[0]?.['AnalyticsIncidents.count'] || 0;
  const shoValue = shoResult?.tablePivot()[0]?.['Incidents.count'] || 0;

  // @ts-ignore
  const sifPotentialPercentage = (
    (sifPotentialValue as number * 100) / totalRecordsForTheYear
  );
  // @ts-ignore
  const sifPrecursorPercentage = (
    (sifPrecursorValue as number * 100) / totalRecordsForTheYear
  );
  // @ts-ignore
  const sifActualPercentage = ((ltiValue + fatValue) * 100) / actualSifValue;

  if (isLoading) return <div className="lti-vs-sif"><LoadingSpinner /></div>;

  return (
    <div className="pyramid">
      <div className="pyramid__top-row">{t(TRANSLATIONS.PYRAMID)}</div>
      <svg width="100%" viewBox="0 0 608 224" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Pyramid">
          <g id="Group 5">
            <g id="Group 4">
              <path id="Rectangle 30" d="M324 0H600C604.418 0 608 3.58172 608 8V216C608 220.418 604.418 224 600 224H324V0Z" fill="#F7F7F7" />
              <text id="696,764" fill="#2B323B" fontFamily="Roboto" fontSize="14" letterSpacing="0.03em"><tspan x="555.857" y="212.785">{shoValue}</tspan></text>
              <text id="2,912" fill="#2B323B" fontFamily="Roboto" fontSize="14" letterSpacing="0.03em"><tspan x="555.857" y="180.785">{sioValue}</tspan></text>
              <text id="388" fill="#2B323B" fontFamily="Roboto" fontSize="14" letterSpacing="0.03em"><tspan x="555.857" y="148.785">{faiValue}</tspan></text>
              <text id="32" fill="#2B323B" fontFamily="Roboto" fontSize="14" letterSpacing="0.03em"><tspan x="555.857" y="116.785">{mtiValue}</tspan></text>
              <text id="84" fill="#2B323B" fontFamily="Roboto" fontSize="14" letterSpacing="0.03em"><tspan x="555.857" y="84.7852">{mdiValue}</tspan></text>
              <text id="39" fill="#2B323B" fontFamily="Roboto" fontSize="14" letterSpacing="0.03em"><tspan x="555.857" y="52.7852">{ltiValue}</tspan></text>
              <text id="3" fill="#2B323B" fontFamily="Roboto" fontSize="14" letterSpacing="0.03em"><tspan x="555.857" y="20.7852">{fatValue}</tspan></text>
              <text id="LTIs" fill="#2B323B" fontFamily="Roboto" fontSize="12" fontWeight="500" letterSpacing="0.03em"><tspan x="457.158" y="20.1016">FAT</tspan></text>
              <text id="LTIs" fill="#2B323B" fontFamily="Roboto" fontSize="12" fontWeight="500" letterSpacing="0.03em"><tspan x="457.158" y="52.1016">LTI&#x2019;s</tspan></text>
              <text id="MDI" fill="#2B323B" fontFamily="Roboto" fontSize="12" fontWeight="500" letterSpacing="0.03em"><tspan x="459.542" y="84.1016">MDI</tspan></text>
              <text id="MTI" fill="#2B323B" fontFamily="Roboto" fontSize="12" fontWeight="500" letterSpacing="0.03em"><tspan x="460.268" y="116.102">MTI</tspan></text>
              <text id="FAI" fill="#2B323B" fontFamily="Roboto" fontSize="14" letterSpacing="0.03em"><tspan x="461.637" y="148.785">FAI</tspan></text>
              <text id="SIO" fill="#2B323B" fontFamily="Roboto" fontSize="14" letterSpacing="0.03em"><tspan x="459.408" y="180.785">SIO</tspan></text>
              <text id="SHO" fill="#2B323B" fontFamily="Roboto" fontSize="14" letterSpacing="0.03em"><tspan x="453.242" y="212.785">SHO</tspan></text>
              <path id="Line 17" d="M324 32L608 32" stroke="#D6D6D6" />
              <path id="Line 18" d="M324 64L608 64" stroke="#D6D6D6" />
              <path id="Line 19" d="M324 96.0001L608 96.0001" stroke="#D6D6D6" />
              <path id="Line 20" d="M324 128L608 128" stroke="#D6D6D6" />
              <path id="Line 21" d="M324 160L608 160" stroke="#D6D6D6" />
              <path id="Line 22" d="M324 192L608 192" stroke="#D6D6D6" />
            </g>
            <g id="Mask Group">
              <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="200" y="0" width="248" height="224">
                <path id="Polygon 1" d="M324 0L448 224H200L324 0Z" fill="#DEDEDE" />
              </mask>
              <g mask="url(#mask0)">
                <rect id="Rectangle 37" x="200" width="248" height="32" fill="#D75252" stroke="black" />
                <rect id="Rectangle 38" x="200" y="32" width="248" height="32" fill="#DA6E3E" stroke="black" />
                <rect id="Rectangle 39" x="200" y="64" width="248" height="32" fill="#E59F47" stroke="black" />
                <rect id="Rectangle 40" x="200" y="96" width="248" height="32" fill="#F4C44C" stroke="black" />
                <rect id="Rectangle 41" x="200" y="128" width="248" height="32" fill="#F6E959" stroke="black" />
                <rect id="Rectangle 42" x="200" y="160" width="248" height="32" fill="#545A90" stroke="black" />
                <rect id="Rectangle 43" x="200" y="192" width="248" height="32" fill="#334552" stroke="black" />
                <rect id="Rectangle 60" x="312" y="152" width="24" height="72" fill="#E29545" />
                <rect id="Rectangle 61" x="312" y="52" width="24" height="100" fill="#A63C31" />
                <rect id="Rectangle 62" x="312" y="32" width="24" height="20" fill="#F4C44C" />
              </g>
            </g>
            <line id="Line 24" x1="324" y1="52.5" x2="1" y2="52.5" stroke="#B5B5B5" strokeDasharray="3 3" />
            <line id="Line 25" x1="324" y1="152.5" x2="1" y2="152.5" stroke="#B5B5B5" strokeDasharray="3 3" />
            <text id="SIF 18/43" fill="#2B323B" fontFamily="Roboto" fontSize="12" fontWeight="500" letterSpacing="0.03em"><tspan x="0" y="16.1016">SIF</tspan></text>
            <text id="SIF Potential" fill="#2B323B" fontFamily="Roboto" fontSize="12" fontWeight="500" letterSpacing="0.03em"><tspan x="0" y="72.1016">SIF Potential</tspan></text>
            <text id="SIF Precursor" fill="#2B323B" fontFamily="Roboto" fontSize="12" fontWeight="500" letterSpacing="0.03em"><tspan x="0" y="172.102">SIF Precursor</tspan></text>
            <text id="2% of these events caused or had the potential to cause death" fill="#878787" fontFamily="Roboto" fontSize="12" letterSpacing="0.02em"><tspan x="0" y="30.1016">{sifActualPercentage ? sifActualPercentage?.toFixed(2) : '0.00'}% of these events caused or had the </tspan><tspan x="0" y="42.1016">potential to cause death</tspan></text>
            <text id="6% of these events had the potential to cause death" fill="#878787" fontFamily="Roboto" fontSize="12" letterSpacing="0.02em"><tspan x="0" y="86.1016">{sifPotentialPercentage ? sifPotentialPercentage?.toFixed(2) : '0.00'}% of these events had the </tspan><tspan x="0" y="98.1016">potential to cause death</tspan></text>
            <text id="0.01% of these events had the potential to cause death" fill="#878787" fontFamily="Roboto" fontSize="12" letterSpacing="0.02em"><tspan x="0" y="186.102">{sifPrecursorPercentage ? sifPrecursorPercentage?.toFixed(2) : '0.00'}% of these events </tspan><tspan x="0" y="198.102">had the potential to </tspan><tspan x="0" y="210.102">cause death</tspan></text>
            <line id="Line 24_2" x1="323" y1="0.5" y2="0.5" stroke="#B5B5B5" strokeDasharray="3 3" />
          </g>
        </g>
      </svg>
    </div>
  );
};

export default Pyramid;
