import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedLocationsSlug } from 'store/selectors/globalFilters.selectors';
import { toast, ToastOptions } from 'react-toastify';
import LoadingSpinner from 'components/LoadingSpinner';
import ChartDropdown from 'components/Dropdown/ChartDropdown';
import { getTranslatedDateTimeValue } from 'utils/dateHelpers';
import { es } from 'date-fns/locale';
import BU from './BarGraphWithLine/BU';
import Level from './BarGraphWithLine/Level';
import './VoilencePrevention.scss';
import { useCreateNoteMutation, useGetNoteLazyQuery } from '../../../generated/graphql';

const getLatestYearsAndCurrentYear = () => {
  const localDate: Date = new Date();
  /** baseYear as we started collecting data for this feature from 2020  */
  const baseYear = 2020;
  const currentYear: number = localDate.getUTCFullYear();
  const years: any[] = [];

  for (let i = currentYear; i >= baseYear; i--) {
    const currentYearToString: string = i.toString();

    years.push({
      label: currentYearToString,
      slug: currentYearToString,
      year: [currentYearToString],
    });
  }

  return years;
};

const buList = [
  { label: 'México', slug: 'mexico', bu: ['mexico'] },
  { label: 'Perú', slug: 'peru', bu: ['peru'] },
  { label: 'Ecuador', slug: 'ecuador', bu: ['ecuador'] },
  { label: 'Colombia', slug: 'colombia', bu: ['colombia'] },
  { label: 'CAC', slug: 'cac', bu: ['cac'] },
];

const levelList = [
  { label: 'Nivel 1', slug: '1', nivel: ['1'] },
  { label: 'Nivel 2', slug: '2', nivel: ['2'] },
  { label: 'Nivel 3', slug: '3', nivel: ['3'] },
];

const monthArr = [
  'enero',
  'febrero',
  'marzo',
  'abril',
  'mayo',
  'junio',
  'julio',
  'agosto',
  'septiembre',
  'octubre',
  'noviembre',
  'diciembre',
];

const monthOptions: any = monthArr.map((monthSlug, monthIndex) => ({
  slug: monthSlug,
  label: monthSlug.substring(0, 1).toUpperCase() + monthSlug.substring(1),
  months: [monthSlug],
  lastMonth: [monthSlug === 'enero' ? 'diciembre' : monthArr[monthIndex - 1]],
}));

const yearOptions = getLatestYearsAndCurrentYear();

const allowedEmails = ['juan.leon.N@ab-inbev.com', 'francisco.juarezg@ab-inbev.com', 'info@arvolution.com'];

const getCurrentMonth = () => {
  const localDate: Date = new Date();
  const monthIndexToGet: number = localDate.getUTCMonth();

  return monthOptions[monthIndexToGet];
};

const getCurrentYear = () => {
  const localDate: Date = new Date();
  const currentYear: number = localDate.getUTCFullYear();
  const yearDataToReturn = yearOptions.find(year => Number(year.label) === currentYear);
  return yearDataToReturn ?? yearOptions[0];
};

const VoliencePrevention = () => {
  const [dropDownOpenMonth, setDropDownOpenMonth] = useState(false);
  const [dropDownOpenYear, setDropDownOpenYear] = useState(false);
  const [dropDownSecondOpenMonth, setDropDownSecondOpenMonth] = useState(false);
  const [dropDownOpenLevel, setDropDownOpenLevel] = useState(false);
  const [dropDownOpenBu, setDropDownOpenBu] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(getCurrentMonth());
  const [selectedYear, setSelectedYear] = useState(getCurrentYear());
  const [selectedSecondMonth, setSelectedSecondMonth] = useState(getCurrentMonth());
  const [selectedLevel, setSelectedLevel] = useState(levelList[2]);
  const [selectedBu, setSelectedBu] = useState(buList[0]);

  const handleDropMonthChange = ({ key }: { key: any }) => {
    setSelectedMonth(monthOptions.find(
      (item: { slug: any }) => item.slug === key,
    ) || monthOptions[0]);
    setDropDownOpenMonth(false);
  };

  const handleDropYearChange = ({ key }: { key: any }) => {
    setSelectedYear(yearOptions.find(item => item.slug === key) || yearOptions[0]);
    setDropDownOpenYear(false);
  };

  const handleDropSecondMonthChange = ({ key }: { key: any }) => {
    setSelectedSecondMonth(
      monthOptions.find((item: { slug: any }) => item.slug === key) || monthOptions[0],
    );
    setDropDownSecondOpenMonth(false);
  };

  const handleDropLevelChange = ({ key }: { key: any }) => {
    setSelectedLevel(levelList.find(item => item.slug === key) || levelList[0]);
    setDropDownOpenLevel(false);
  };

  const handleDropBuChange = ({ key }: { key: any }) => {
    setSelectedBu(buList.find(item => item.slug === key) || buList[0]);
    setDropDownOpenBu(false);
  };

  const user = useSelector((state: any) => state.get('user'))?.toJS();

  const location = useSelector(selectSelectedLocationsSlug);
  const [fetchNotes, { data, loading, refetch }] = useGetNoteLazyQuery();
  const [content, setContent] = useState<string>(data?.getNote?.content ?? '');
  const locationBusinessUnitLabel = buList.find(
    ({ slug }) => slug === location.businessUnit,
  )?.label;

  const isCommentsVisible = allowedEmails.includes(user.email);

  const [createNote] = useCreateNoteMutation({
    variables: {
      input: {
        bu: location.businessUnit,
        content,
      },
    },
  });

  const toastConfig = {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  } as ToastOptions;

  const updateNote = () => {
    createNote().then(() => {
      toast.success('Note Updated', toastConfig);
      if (refetch) {
        refetch({ bu: location.businessUnit });
      }
    }).catch(() => {
      toast.error('Something Went wrong please try again.', toastConfig);
    });
  };

  useEffect(() => {
    if (isCommentsVisible) {
      fetchNotes({ variables: { bu: location.businessUnit } });
      setContent('');
    }
    // eslint-disable-next-line
  }, [location.businessUnit, isCommentsVisible]);

  return (
    <div className="container-fluid overview-wrapper routines-home">
      <h2 className="routines-home__title">Prevención de violencia</h2>
      <div className="headerFlex">
        <div className="commonFlex">
          <ChartDropdown
            onChange={handleDropLevelChange}
            selectedDropDownItem={selectedLevel}
            dropDownItems={levelList}
            dropDownOpen={dropDownOpenLevel}
            setDropDownOpen={setDropDownOpenLevel}
            labelClassName="label-default"
          />
        </div>
        <div className="commonFlex">
          <ChartDropdown
            onChange={handleDropMonthChange}
            selectedDropDownItem={selectedMonth}
            dropDownItems={monthOptions}
            dropDownOpen={dropDownOpenMonth}
            setDropDownOpen={setDropDownOpenMonth}
            labelClassName="label-default"
          />
        </div>
        <div className="commonFlex">
          <ChartDropdown
            onChange={handleDropYearChange}
            selectedDropDownItem={selectedYear}
            dropDownItems={yearOptions}
            dropDownOpen={dropDownOpenYear}
            setDropDownOpen={setDropDownOpenYear}
            labelClassName="label-default"
          />
        </div>
        <div className="commonFlex" />
        <div className="commonFlex" />
      </div>
      <div className="col-12 card routines-home__card" style={{ justifyContent: 'flex-start' }}>
        <span className="routines-home__card__title">Nivel {selectedLevel.slug}</span>
        <Level
          currentMonth={selectedMonth.months}
          lastMonth={selectedMonth.lastMonth}
          year={selectedYear.year}
          level={selectedLevel.nivel}
        />
      </div>
      <div className="headerFlex">
        <div className="commonFlex">
          <ChartDropdown
            onChange={handleDropBuChange}
            selectedDropDownItem={selectedBu}
            dropDownItems={buList}
            dropDownOpen={dropDownOpenBu}
            setDropDownOpen={setDropDownOpenBu}
            labelClassName="label-default"
          />
        </div>
        <div className="commonFlex">
          <ChartDropdown
            onChange={handleDropSecondMonthChange}
            selectedDropDownItem={selectedSecondMonth}
            dropDownItems={monthOptions}
            dropDownOpen={dropDownSecondOpenMonth}
            setDropDownOpen={setDropDownSecondOpenMonth}
            labelClassName="label-default"
          />
        </div>
        <div className="commonFlex" />
        <div className="commonFlex" />
      </div>
      <div className="row ml-0">
        <div className="col card routines-home__card">
          <BU
            currentMonth={selectedSecondMonth.months}
            lastMonth={selectedSecondMonth.lastMonth}
            bu={selectedBu.bu}
            year={selectedYear.year}
          />
        </div>
        {isCommentsVisible && (
          <div className="col-4">
            <div>
              <h3> {location.businessUnit
                ? `Notas para ${locationBusinessUnitLabel === undefined
                  ? location.businessUnit
                  : locationBusinessUnitLabel}`
                : 'Por favor escoge una BU para dejar comentarios'}
              </h3>
              {data?.getNote?.user?.name
                && <h5> Comentario escrito por {data?.getNote?.user?.name} </h5>}
              {loading ? <LoadingSpinner />
                : (
                  <textarea
                    disabled={!location.businessUnit}
                    onChange={e => setContent(e.target.value)}
                    value={content || (data?.getNote?.content ?? '')}
                    className="form-control form-group"
                    style={{ width: '100%' }}
                  />
                )}
              {data?.getNote?.updatedAt
                && <h5> Última edición: <span style={{ fontWeight: 'normal' }}>{getTranslatedDateTimeValue(data?.getNote?.updatedAt, 'd MMMM YYY', es)} </span> </h5>}
              {!loading
                && (
                <button
                  disabled={!location.businessUnit}
                  onClick={updateNote}
                  style={{
                    backgroundColor: !location.businessUnit ? 'grey' : '#2574FB',
                    color: 'white',
                    borderRadius: '8px',
                    width: '277px',
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '8px',
                    cursor: location.businessUnit ? 'pointer' : 'not-allowed',
                  }}
                  type="button"
                > Guardar nota
                </button>
                )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default VoliencePrevention;
