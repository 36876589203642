import React, { FC } from 'react';
import './FormHeader.scss';
import { useTranslation } from 'react-i18next';
import Settings from 'components/Header/Settings';
import { TRANSLATIONS } from '../../types/enums';

const FormHeader: FC<Props> = (props: Props) => {
  const {
    containerClassName,
    alternativeLabel,
    fromSubmitSuccessful,
    hideSettingsLabel = false,
  } = props;

  const { t } = useTranslation();

  return (
    <div className={`row justify-content-center align-items-center mt-3 ${containerClassName}`}>
      <div className="col-auto mr-auto">
        <h1 className="text-tertiary">
          {
            alternativeLabel || t(TRANSLATIONS.NEW_REPORT)
          }
        </h1>
      </div>
      { !hideSettingsLabel && (
        <div className="col-auto">
          <Settings fromSubmitSuccessful={fromSubmitSuccessful} fromFormSubmission />
        </div>
      )}
    </div>
  );
};

type Props = {
  containerClassName?: any;
  alternativeLabel?: string;
  fromSubmitSuccessful?: boolean;
  hideSettingsLabel?: boolean;
};

export default FormHeader;
