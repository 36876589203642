import React, { Component } from 'react';
import 'features/external-users/AnonymousComplaint/SubmitSuccessful/SubmitSuccessful.scss';
import ImageLinks from 'utils/ImageLinks';
import BasicButton from 'components/Button/BasicButton';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import FormHeader from 'containers/FormHeader';
import { TRANSLATIONS } from '../../../../types/enums';

class SubmitSuccessful extends Component<Props, State> {
  render() {
    const { t } = this.props;
    return (
      <div className="container">
        <FormHeader
          containerClassName="justify-start"
          alternativeLabel={`${t(TRANSLATIONS.SIO_SUCCESSFULLY_SENT)}`}
          fromSubmitSuccessful
        />

        {/* check */}
        <div className="row justify-content-center align-items-center" style={{ marginTop: '20%' }}>
          <div className="col-auto">
            <img src={ImageLinks.checkIcon} alt="success" width="60" />
          </div>
        </div>

        {/* success msg */}
        <div className="row justify-content-center align-items-center">
          <div className="col-auto">
            <p className="text-tertiary-light text-larger text-bold">
              {t(TRANSLATIONS.SUCCESSFUL_REGISTRATION)}
            </p>
          </div>
        </div>
        {/* subtext */}
        <div className="row justify-content-center align-items-center">
          <div className="col-auto">
            <p>
              {t(TRANSLATIONS.THE_REPORT_HAS_BEEN_SUCCESSFULLY_SENT)}
            </p>
          </div>
        </div>

        {/* new form */}
        <div className="row justify-content-center align-items-center fixed-bottom">
          <div className="col-auto">
            <Link to="/ac">
              <BasicButton
                text={t(TRANSLATIONS.NEW_REPORT)}
                onClick={() => false}
                style={{ borderRadius: 50, minWidth: 280 }}
              />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

type Props = {
  t: any;
};
type State = {};
// @ts-ignore
export default withTranslation()(SubmitSuccessful);
