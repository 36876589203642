import { Tooltip } from '@mui/material';
import React, { CSSProperties } from 'react';

import './ButtonWithTooltip.scss';

export type ButtonWithTooltipProps = {
  text: string;
  onClick?(e?: any): void;
  className?: string;
  background?: string;
  disabled?: boolean;
  style?: CSSProperties;
  tooltipMessage?: string;
};

const ButtonWithTooltip = ({
  text,
  onClick,
  className = '',
  background,
  disabled,
  style,
  tooltipMessage,
}: ButtonWithTooltipProps) => (
  <Tooltip title={disabled ? (tooltipMessage || '') : ''} placement="top">
    <button
      type="button"
      className={`basic-button-with-tooltip ${className}`}
      onClick={onClick}
      tabIndex={0}
      style={{
        backgroundColor: background,
        ...(disabled ? { opacity: 0.3 } : {}),
        outline: 'none',
        ...style,
      }}
      disabled={disabled}
    >
      {text}
    </button>
  </Tooltip>
);

export default ButtonWithTooltip;
