import React from 'react';
import { useTranslation } from 'react-i18next';
import { ACTION_LOG_SUMMARY_MODAL_STATUS, TRANSLATIONS } from 'types/enums';
import ImageLinks from 'utils/ImageLinks';
import './ButtonsForSummaryModalStyle.scss';

type ButtonsForSummaryModalProps = {
  modalStatus: ACTION_LOG_SUMMARY_MODAL_STATUS;
  onSetActionLogModalStatusToEdit: VoidFunction;
  onCancelClick: VoidFunction;
  onDeleteClick: VoidFunction;
  canEdit?: boolean;
};

function ButtonsForSummaryModal(props: ButtonsForSummaryModalProps) {
  const { t } = useTranslation();
  const {
    modalStatus,
    onCancelClick,
    onDeleteClick,
    onSetActionLogModalStatusToEdit,
    canEdit = false,
  } = props;

  if (modalStatus === ACTION_LOG_SUMMARY_MODAL_STATUS.SUMMARY) {
    return (
      <>
        <div className="edit-and-delete-button-container">
          <button
            className={`delete_btn ${canEdit ? 'active_btn' : 'disable_btn'}`}
            type="button"
            onClick={onDeleteClick}
            disabled={!canEdit}
          >
            {canEdit && <img src={ImageLinks.deleteIcon} alt="delete" />}
            {t(TRANSLATIONS.DELETE)}
          </button>
          <button
            className="edit-btn"
            type="button"
            onClick={onSetActionLogModalStatusToEdit}
            disabled={!canEdit}
          >
            {canEdit && <img src={ImageLinks.editIcon} alt="close" />}
            Editar
          </button>
        </div>
        <button
          className="close_btn d-flex justify-content-around"
          type="button"
          onClick={onCancelClick}
        >
          {t(TRANSLATIONS.CLOSE)} <img src={ImageLinks.grayCross} alt="close" />
        </button>
      </>
    );
  }
  return (
    <button
      className="close_btn d-flex justify-content-around"
      type="button"
      onClick={onCancelClick}
    >
      {t(TRANSLATIONS.CLOSE)} <img src={ImageLinks.grayCross} alt="close" />
    </button>
  );
}

export default ButtonsForSummaryModal;
