import ReactDOM from 'react-dom';
import React, { Component } from 'react';
import Modal from '@mui/material/Modal';
import './Alert.scss';
import BasicButton from 'components/Button/BasicButton';

export type AlertProps = {
  image?: {
    src: string;
    className?: string;
  };
  title: string;
  description: string;
  buttons: {
    title: string;
    className: string;
    onPress: () => void;
  }[];
  onClose?: () => void
};

class Alert extends Component<AlertProps> {
  static alert(props: AlertProps) {
    const alertContainer = document.createElement('div');
    document.body.appendChild(alertContainer);

    const closeAlert = () => {
      ReactDOM.unmountComponentAtNode(alertContainer);
      alertContainer.remove();
    };

    const alertElement = (
      <Alert {...props} onClose={closeAlert} />
    );

    ReactDOM.render(alertElement, alertContainer);
  }

  render() {
    const {
      image,
      title = '',
      description = '',
      buttons = [],
      onClose,
    } = this.props;

    return (
      <Modal
        open
        // onClose={onClose}
      >
        <div className="alert-main-container">
          <div className="alert-container">
            <img src={image?.src} className={image?.className ?? 'alert-image'} alt="alert" />

            <p className="alert-title">{title}</p>
            <p className="alert-description">{description}</p>

            <div className="alert-buttons-container">
              {buttons?.map((button, index: number) => (
                <BasicButton
                  key={String(index)}
                  text={button.title}
                  onClick={() => {
                    button.onPress();
                    onClose?.();
                  }}
                  className={button.className}
                />
              ))}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default Alert;
