import LoadingSpinner from 'components/LoadingSpinner'; // eslint-disable-line
import { useCubeQuery } from '@cubejs-client/react';
import { groupBy } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectLocationsToAccess } from 'store/selectors/globalFilters.selectors';
import EmptyState from 'components/EmptyState/EmptyState';
// @ts-ignore
import ReactTable from 'react-table-legacy';
import './TopCds.scss';
import { TRANSLATIONS } from 'types/enums';
import { useTranslation } from 'react-i18next';
import { getMonth, getYear, addDays } from 'date-fns';
import { getWeeksInfoForBusinessCalender } from 'utils/dateHelpers';

const TopCds = ({
  isTopOffenders,
  dateRange,
  selectedPillar,
  allPillarIds,
}: {
  isTopOffenders: boolean, dateRange: Array<string> | string, selectedPillar: string,
  allPillarIds: string[],
}) => {
  const { t } = useTranslation();
  const title = isTopOffenders ? `Top 10 offender ${t(TRANSLATIONS.ROUTINE_COMPLIANCE_CD)}s` : `Top 10 ${t(TRANSLATIONS.ROUTINE_COMPLIANCE_CD)}s`;
  const color = isTopOffenders ? '#E92B36' : '#5572B9';

  const columns = [
    {
      Header: '',
      id: 'number',
      accessor: 'number',
      minWidth: 45,
      Cell: (row: any) => (
        <p className="text-left font-bold margin-left-25">
          {row.index + 1}
        </p>
      ),
    },
    {
      Header: 'BU',
      id: 'bu',
      accessor: 'bu',
      minWidth: 95,
      Cell: (row: any) => (
        <p className="text-left font-bold margin-left-25">
          {row.value}
        </p>
      ),
    },
    {
      Header: `${t(TRANSLATIONS.ROUTINE_COMPLIANCE_CD)}`,
      id: 'cd',
      accessor: 'cd',
      Cell: (row: any) => (
        <p className="text-left font-bold margin-left-25">
          {row.value}
        </p>
      ),
    },
    {
      Header: `${t(TRANSLATIONS.ROUTINE_COMPLIANCE_COMPLIANCE)}`,
      id: 'percentage',
      accessor: 'percentage',
      Cell: (row: any) => {
        return (
          <p style={{ color }} className="text-left font-bold margin-left-25">
            {`${row.value}%`}
          </p>
        );
      },
    },
  ];

  const locationsToAccess = useSelector(selectLocationsToAccess);

  const {
    resultSet: routinesToDoQuery,
    isLoading: loadingToDo,
  } = useCubeQuery({
    measures: ['TheoricRoutinesCompliance.count'],
    timeDimensions: [],
    dimensions: [
      'TheoricRoutinesCompliance.frequency',
      'TheoricRoutinesCompliance.locationName',
      'TheoricRoutinesCompliance.buName',
    ],
    filters: [{
      dimension: 'TheoricRoutinesCompliance.locations',
      operator: 'equals',
      values: locationsToAccess,
    },
    {
      dimension: 'TheoricRoutinesCompliance.pillarId',
      operator: 'equals',
      values: selectedPillar === '' ? allPillarIds : [selectedPillar],
    }],
  });
  const {
    resultSet: routinesDoneQuery,
    isLoading: loadingDone,
  } = useCubeQuery({
    measures: ['RealRoutinesCompliance.count'],
    timeDimensions: [{
      dimension: 'RealRoutinesCompliance.date',
      granularity: 'month',
      dateRange,
    }],
    dimensions: [
      'RealRoutinesCompliance.frequency',
      'RealRoutinesCompliance.buName',
      'RealRoutinesCompliance.month',
      'RealRoutinesCompliance.locationName',
    ],
    filters: [{
      dimension: 'RealRoutinesCompliance.locations',
      operator: 'equals',
      values: locationsToAccess,
    },
    {
      dimension: 'RealRoutinesCompliance.pillarId',
      operator: 'equals',
      values: selectedPillar === '' ? allPillarIds : [selectedPillar],
    }],
  });

  let routinesLocations: any[] = [];

  if (routinesToDoQuery?.tablePivot() && routinesDoneQuery?.tablePivot()) {
    const toDo = routinesToDoQuery.tablePivot();
    const done = routinesDoneQuery.tablePivot();

    const toDoG = groupBy(toDo, 'TheoricRoutinesCompliance.locationName');
    const doneG = groupBy(done, 'RealRoutinesCompliance.locationName');

    Object.entries(toDoG).forEach(([key, value]) => {
      let totalRoutinesToDo = 0;
      let totalRoutinesDone = 0;
      let buName;

      value.forEach(r => {
        buName = r['TheoricRoutinesCompliance.buName'] as string;
        const frequency = r['TheoricRoutinesCompliance.frequency'] as string;
        const userRoutinesTheoric = r['TheoricRoutinesCompliance.count'] as number;
        const totalDone = doneG[key]
          ?.filter(k => k['RealRoutinesCompliance.frequency'] === frequency)
          .reduce((acum, current) => acum + (current['RealRoutinesCompliance.count'] as number), 0);

        const totalWeeks = getWeeksInfoForBusinessCalender(
          getMonth(addDays(new Date(dateRange[0]), 15)), getYear(new Date(dateRange[1])),
        ).weeks;

        if (totalWeeks) {
          if (frequency === '0 0 * * *') totalRoutinesToDo += userRoutinesTheoric * 6 * totalWeeks;
          if (frequency === '0 0 * * 0') totalRoutinesToDo += userRoutinesTheoric * totalWeeks;
          if (frequency === '0 0 1 * *') totalRoutinesToDo += userRoutinesTheoric;
        }
        totalRoutinesDone += (totalDone ?? 0);
      });

      const percentage = ((totalRoutinesDone / totalRoutinesToDo) * 100);
      if (key !== 'null') routinesLocations.push({ cd: key, bu: buName, percentage: (percentage).toFixed(2) });
    });

    routinesLocations = routinesLocations
      .sort((a: any, b: any) => (Number(a.percentage) < Number(b.percentage) ? 1 : -1));
  }

  if (isTopOffenders) routinesLocations = routinesLocations.slice(-10);
  routinesLocations = routinesLocations.slice(0, 10);

  if (!routinesLocations.length) {
    return (
      <div className="overview-locations-top-bottom">
        <EmptyState />
      </div>
    );
  }

  if (loadingToDo || loadingDone) {
    return (
      <div className="overview-locations-top-bottom">
        <LoadingSpinner height={271} />
      </div>
    );
  }

  return (
    <div className="top-cds">
      <span className="top-cds__title" style={{ color }}>{title}</span>
      <ReactTable
        data={routinesLocations}
        columns={columns}
        showPagination={false}
        defaultPageSize={routinesLocations?.length}
        className="-striped"
        NoDataComponent={() => <EmptyState />}
      />
    </div>
  );
};

export default TopCds;
