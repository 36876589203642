import React, { Fragment, useCallback, useEffect, useState } from 'react';
import MainNavigationHeader from 'components/MainNavigationHeader';
import ContentDivisionHeader from 'components/bit/ContentDivisionHeader';
import './LocationAreasConfig.scss';
import { COLOR_CODE, COLOR_CODE_NEW, TRANSLATIONS } from 'types/enums';
import { useTranslation } from 'react-i18next';
import Input from 'components/Input/Input';
import { createAreaForLocation, getAreasForLocation, removeSectionForLocation } from 'store/actions/distributionCenter.actions';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import BasicButton from 'components/Button/BasicButton/BasicButton';
import OutlineButton from 'components/Button/OutlineButton/OutlineButton';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Location_Type, UpdateWorkLocationsPayload, useLocationDetailsQuery, useUpdateWorkLocationsMutation } from 'generated/graphql';

type OptionProps = {
  isSelected: boolean;
  selectOption: () => void;
  name: string;
  secondaryText?: string;
  secondaryTextLength?: number;
  onDelete?: () => void;
  onEdit?: () => void;
};

type NewSectionButtonProps = {
  isSectionFilled: boolean;
  onTextChange: (text: string) => void;
  onTextChangeEn: (text: string) => void;
  inputValue: string;
  inputValueEn: string;
  buttonText: string;
  onSubmit: () => void;
  onCancel?: () => void;
  errorMessage: string;
  isLoading: boolean;
  cancelButtonText?: string;
};

type SubLocation = {
  id: number
  name: string;
  nameEN: string
};

type SubArea = {
  id: number
  name: string
  nameEN: string
  subLocations: SubLocation[]
};

type Areas = {
  id: number
  name: string
  nameEN: string
  subAreas: SubArea[]
};

type Props = {
  userCountry: string,
};

enum LocationAreasSections {
  AREA = 'AREA',
  SUB_AREA = 'SUB_AREA',
  SUB_LOCATION = 'SUB_LOCATION',
}

const LocationAreasConfig = (props: Props) => {
  const { userCountry } = props;
  const routeParams: any = useParams();
  const { t } = useTranslation();
  const [selectedArea, setSelectedArea] = useState<number>();
  const [selectedSubarea, setSelectedSubarea] = useState<number>();
  const [selectedSubLocation, setSelectedSubLocation] = useState<number>();
  const [areas, setAreas] = useState<Areas[]>();
  const [errorMessage, setErrorMessage] = useState('');
  const [isFactory, setIsFactory] = useState<boolean>();

  const { data, loading } = useLocationDetailsQuery({
    variables: { locationSlug: routeParams?.locationSlug },
  });

  const [triggerEditName] = useUpdateWorkLocationsMutation();

  // Rename Department to Sector for PY Users
  const sectionName = userCountry === 'PY' ? t(TRANSLATIONS.SECTOR) : t(TRANSLATIONS.DEPARTMENT);

  // Remove
  const [sectionToDelete, setSectionToDelete] = useState<
  [LocationAreasSections | '', string, number | null] // [level, name of section, id of section]
  // >([LocationAreasSections.AREA, 'Calidad', 23]);
  >(['', '', null]);
  const [sectionToEdit, setSectionToEdit] = useState<
  [LocationAreasSections | '', string, number | null] // [level, name of section, id of section]
  // >([LocationAreasSections.AREA, 'Calidad', 23]);
  >(['', '', null]);
  const [isRemovingSection, setIsRemovingSection] = useState(false);
  const [isReassigningSectionToDelete, setIsReassigningSectionToDelete] = useState(false);
  // Used to save the original selection of the user. This is used for UX so after the user
  // selected the area to reasign the incidents we can go back to the original selection
  const [originalSelectedSections, setOriginalSelectedSections] = useState<
  [number | undefined, number | undefined, number | undefined]>([undefined, undefined, undefined]);

  // Department
  const [newDepartmentName, setNewDepartmentName] = useState('');
  const [newDepartmentNameEn, setNewDepartmentNameEn] = useState('');
  const [newDepartmentError, setNewDepartmentError] = useState('');
  const [newDepartmentLoading, setNewDepartmentLoading] = useState(false);
  // SubArea
  const [newSubAreaName, setNewSubAreaName] = useState('');
  const [newSubAreaNameEn, setNewSubAreaNameEn] = useState('');
  const [newSubAreaError, setNewSubAreaError] = useState('');
  const [newSubAreaLoading, setNewSubAreaLoading] = useState(false);
  // SubLocation
  const [newSubLocationName, setNewSubLocationName] = useState('');
  const [newSubLocationNameEn, setNewSubLocationNameEn] = useState('');
  const [newSubLocationError, setNewSubLocationError] = useState('');
  const [newSubLocationLoading, setNewSubLocationLoading] = useState(false);

  // edit
  const [editName, setEditName] = useState('');
  const [editNamEn, setEditNamEn] = useState('');
  const [editNameError, setEditNameError] = useState('');
  const [editNameLoading, setEditNameLoading] = useState(false);

  const fetchAreas = useCallback(async () => {
    try {
      setErrorMessage('');
      const { areas: locationAreas } = await getAreasForLocation(routeParams?.locationSlug);
      setAreas(locationAreas);
    } catch (e) {
      if ((e as Error).message === 'User does not have access to the location') {
        setErrorMessage(t(TRANSLATIONS.SUPER_USER_DOESNT_HAVE_ACCESS_TO_LOCATION));
      } else {
        setErrorMessage((e as Error).message);
      }
    }
  }, [routeParams?.locationSlug, t]);

  const cleanSelectedAreas = useCallback(() => {
    setSelectedSubLocation(undefined);
    setSelectedSubarea(undefined);
    setSelectedArea(undefined);
  }, []);

  const deleteSection = useCallback(async (reassignToOtherSection: boolean) => {
    if (!areas) return;
    const levelOfItemToDelete = sectionToDelete[0];
    // const originalArea = selectedArea;
    // const originalSubArea = selectedSubarea;
    // const originalSubLocation = selectedSubLocation;
    setIsRemovingSection(true);
    setIsReassigningSectionToDelete(false);
    if (reassignToOtherSection) {
      // Save the current selection state to revert it once
      let areaId; let subAreaId; let subLocationId;
      if (selectedArea !== undefined) areaId = areas[selectedArea as number].id;
      if (selectedSubarea !== undefined) {
        const area = areas[selectedArea as number];
        subAreaId = area.subAreas[selectedSubarea].id;
      }
      if (selectedSubLocation !== undefined) {
        const area = areas[selectedArea as number];
        const subArea = area.subAreas[selectedSubarea as number];
        subLocationId = subArea.subLocations[selectedSubLocation as number].id;
      }
      try {
        await removeSectionForLocation(
          routeParams?.locationSlug,
          levelOfItemToDelete,
          sectionToDelete[2] as number,
          areaId,
          subAreaId,
          subLocationId,
        );
      } catch (e) {
        // eslint-disable-next-line no-alert
        alert(`Error eliminando: ${(e as Error).message}`);
      }
    } else {
      try {
        await removeSectionForLocation(
          routeParams?.locationSlug, levelOfItemToDelete, sectionToDelete[2] as number,
        );
      } catch (e) {
        // eslint-disable-next-line no-alert
        alert(`Error eliminando: ${(e as Error).message}`);
      }
    }
    cleanSelectedAreas();
    await fetchAreas();
    if (levelOfItemToDelete === LocationAreasSections.SUB_AREA) {
      setSelectedArea(originalSelectedSections[0]);
    }
    if (levelOfItemToDelete === LocationAreasSections.SUB_LOCATION) {
      setSelectedArea(originalSelectedSections[0]);
      setSelectedSubarea(originalSelectedSections[1]);
    }
    setSectionToDelete(['', '', null]);
    setIsRemovingSection(false);
  }, [
    areas,
    fetchAreas,
    routeParams?.locationSlug,
    sectionToDelete,
    selectedArea,
    selectedSubLocation,
    selectedSubarea,
    cleanSelectedAreas,
    originalSelectedSections,
  ]);

  const saveSelectedValues = useCallback(() => {
    setOriginalSelectedSections([
      selectedArea, selectedSubarea, selectedSubLocation,
    ]);
  }, [selectedArea, selectedSubLocation, selectedSubarea]);

  const addNewLocationSection = useCallback(async (optionToAdd: string) => {
    if (!areas) return;
    if (optionToAdd === LocationAreasSections.AREA) {
      try {
        setNewDepartmentLoading(true);
        setNewDepartmentError('');
        await createAreaForLocation(routeParams?.locationSlug, newDepartmentName, newDepartmentNameEn);
        await fetchAreas();
      } catch (e) {
        setNewDepartmentError((e as Error).message);
      }
      setNewDepartmentLoading(false);
      setNewDepartmentName('');
      setNewDepartmentNameEn('');
    }
    if (optionToAdd === LocationAreasSections.SUB_AREA) {
      try {
        setNewSubAreaLoading(true);
        setNewSubAreaError('');
        const areaId = areas[selectedArea as number].id;
        await createAreaForLocation(routeParams?.locationSlug, newSubAreaName, newSubAreaNameEn, areaId);
        await fetchAreas();
      } catch (e) {
        setNewSubAreaError((e as Error).message);
      }
      setNewSubAreaLoading(false);
      setNewSubAreaName('');
      setNewSubAreaNameEn('');
    }
    if (optionToAdd === LocationAreasSections.SUB_LOCATION) {
      try {
        setNewSubLocationLoading(true);
        setNewSubLocationError('');
        const area = areas[selectedArea as number];
        const subArea = area.subAreas[selectedSubarea as number];
        await createAreaForLocation(
          routeParams?.locationSlug, newSubLocationName, newSubLocationNameEn, area.id, subArea.id,
        );
        await fetchAreas();
      } catch (e) {
        setNewSubLocationError((e as Error).message);
      }
      setNewSubLocationLoading(false);
      setNewSubLocationName('');
      setNewSubLocationNameEn('');
    }
  }, [
    areas,
    fetchAreas,
    routeParams?.locationSlug,
    newDepartmentName,
    newSubAreaName,
    newSubLocationName,
    newDepartmentNameEn,
    newSubAreaNameEn,
    newSubLocationNameEn,
    selectedArea,
    selectedSubarea,
  ]);

  useEffect(() => {
    const locationType = data?.locationDetails.type === Location_Type.Factory;
    if (!loading) {
      setIsFactory(locationType);
    }
  }, [data, loading]);

  useEffect(() => {
    if (isFactory === undefined) return;

    if (isFactory) {
      fetchAreas();
    } else {
      setErrorMessage(t(TRANSLATIONS.EDIT_OPERATION_LOCATION_NOT_SUPPORTED));
    }
  }, [isFactory, fetchAreas, t]);

  const setArea = (index: number) => {
    setSelectedSubarea(undefined);
    setSelectedSubLocation(undefined);
    setSelectedArea(index);
  };

  const setSubarea = (index: number) => {
    setSelectedSubLocation(undefined);
    setSelectedSubarea(index);
  };

  const handleEditName = async () => {
    try {
      setEditNameLoading(true);
      setEditNameError('');
      let payload: UpdateWorkLocationsPayload = {};
      if (sectionToEdit[0] === LocationAreasSections.AREA) {
        payload = {
          areaId: sectionToEdit[2],
          areaName: editName,
          areaNameEn: editNamEn,
        };
      }
      if (sectionToEdit[0] === LocationAreasSections.SUB_AREA) {
        payload = {
          subAreaId: sectionToEdit[2],
          subAreaName: editName,
          subAreaNameEn: editNamEn,
        };
      }
      if (sectionToEdit[0] === LocationAreasSections.SUB_LOCATION) {
        payload = {
          subLocationId: sectionToEdit[2],
          subLocationName: editName,
          subLocationNameEn: editNamEn,
        };
      }
      triggerEditName({ variables: {
        payload,
      } });
      await fetchAreas();
    } catch (e) {
      setEditNameError((e as Error).message);
    } finally {
      setEditNameLoading(false);
      setEditName('');
      setEditNamEn('');
      setSectionToEdit(['', '', null]);
    }
  };

  const AreaOption = ({
    isSelected,
    selectOption,
    name,
    secondaryText,
    secondaryTextLength,
    onDelete,
    onEdit,
  }: OptionProps) => (
    <div
      className={`row option-row btn rounded ${isSelected ? 'selected-btn' : ''}`}
      role="button"
      onClick={selectOption}
    >
      <div className="column">
        <p>{name}</p>
        { !!secondaryText && (
          <p className="section-amount text-secondary">{secondaryText}: {secondaryTextLength}</p>
        ) }
      </div>
      { !!(onDelete || onEdit) && (
        <div
          className="column"
        >
          { isSelected && (
            <>

              <div
                className="remove-option"
                style={{
                  textDecorationColor: COLOR_CODE.PRIMARY,
                }}
                role="button"
                onClick={onEdit}
              >
                <p
                  style={{
                    color: COLOR_CODE.PRIMARY,
                  }}
                >
                  {t(TRANSLATIONS.EDIT)}
                </p>
              </div>
              <div
                className="remove-option"
                style={{
                  textDecorationColor: COLOR_CODE.DANGER,
                }}
                role="button"
                onClick={onDelete}
              >
                <p
                  style={{
                    color: COLOR_CODE.DANGER,
                  }}
                >
                  {t(TRANSLATIONS.DELETE)}
                </p>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );

  const NewOptionInput = useCallback(({
    isSectionFilled,
    onTextChange,
    onTextChangeEn,
    inputValue,
    inputValueEn,
    buttonText,
    onSubmit,
    errorMessage: sectionError,
    isLoading,
    cancelButtonText,
    onCancel = () => {},
  }: NewSectionButtonProps) => (
    <div className="row option-row mt-3 input-row no-gutters">
      <div className="col-12">
        <Input
          onChange={e => onTextChange(e.target.value)}
          value={inputValue}
          error={sectionError}
          placeholder="Nombre en español"
          submitOnEnter={() => {
            // if (!isSectionFilled || isLoading) return;
            // onSubmit();
          }}
        />
        <Input
          onChange={e => onTextChangeEn(e.target.value)}
          value={inputValueEn}
          placeholder="Nombre en ingles (opcional)"
          submitOnEnter={() => {
            // if (!isSectionFilled || isLoading) return;
            // onSubmit();
          }}
        />
      </div>
      <div
        className={`col-${(cancelButtonText) ? 6 : 12} flex ${sectionError ? 'mt-4' : ''}`}
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <div
          role="button"
          onClick={() => {
            if (!isSectionFilled || isLoading) return;
            onSubmit();
          }}
        >
          <p
            className={`${isSectionFilled ? '' : 'text-muted'} pv-2`}
            style={{
              color: isSectionFilled ? COLOR_CODE.PRIMARY : undefined,
            }}
          >
            {buttonText}
          </p>
        </div>
        { isLoading && (
          <LoadingSpinner height={15} positionTop={-5} style={{ marginRight: -12 }} text=" " />
        )}
        {!!cancelButtonText && (
          <div
            role="button"
            onClick={() => {
              if (!isSectionFilled || isLoading) return;
              onCancel?.();
            }}
          >
            <p
              className={`${isSectionFilled ? '' : 'text-muted'} pv-2`}
              style={{
                color: isSectionFilled ? COLOR_CODE.SECONDARY : undefined,
              }}
            >
              {cancelButtonText}
            </p>
          </div>
        )}
      </div>
    </div>
  ), []);

  return (
    <>
      <MainNavigationHeader />
      <div className="container main-container mt-3 location-areas-config">
        <ContentDivisionHeader title={t(TRANSLATIONS.STRUCTURE_TREE)} />
        { areas ? (
          <div className="card row mt-2">
            { (sectionToDelete[0] || isRemovingSection) && !isReassigningSectionToDelete ? (
              <>
                <div className="col-12 text-center">
                  <h3>{t(TRANSLATIONS.DELETE)} {sectionToDelete[1]}</h3>
                  <p>Los responsables serán desasignados de su departamento,
                    recuérdales volver a llenar la información de su perfil.
                  </p>
                </div>
                { isRemovingSection ? (
                  <LoadingSpinner text={t(TRANSLATIONS.DELETING)} height={200} />
                ) : (
                  <div className="col-6 offset-3 delete-action-container text-center">
                    <BasicButton
                      text="Eliminar y reasignar ACIS reportados"
                      style={{ backgroundColor: COLOR_CODE_NEW.PRIMARY }}
                      onClick={() => {
                        setIsReassigningSectionToDelete(true);
                        cleanSelectedAreas();
                      }}
                    />
                    <BasicButton
                      text="Eliminar junto con los ACIS reportados"
                      style={{ backgroundColor: COLOR_CODE_NEW.PRIMARY }}
                      onClick={() => {
                        deleteSection(false);
                      }}
                    />
                    <OutlineButton
                      text={t(TRANSLATIONS.CANCEL)}
                      onClick={() => {
                        setSectionToDelete(['', '', null]);
                      }}
                    />
                  </div>
                )}

              </>
            ) : (
              <>
                { isReassigningSectionToDelete && (
                  <>
                    <div className="col-12">
                      <h3>{t(TRANSLATIONS.DELETE)} {sectionToDelete[1]}</h3>
                      <p>Selecciona a donde se deberian de reasignar los ACIs reportados a {
                      sectionToDelete[1]
                      }
                      </p>
                    </div>
                    <div className="col-6 mt-3" style={{ marginBottom: 30 }}>
                      <p> {sectionName} : {
                      selectedArea !== undefined && areas[selectedArea].name
                      }
                      </p>
                      <p>{t(TRANSLATIONS.AREA)}: {
                      selectedSubarea !== undefined && areas[selectedArea as number]
                        .subAreas[selectedSubarea].name
                      }
                      </p>
                      <p>{t(TRANSLATIONS.SUBAREA)}: {
                      selectedSubLocation !== undefined && areas[selectedArea as number]
                        .subAreas[selectedSubarea as number]
                        .subLocations[selectedSubLocation].name
                      }
                      </p>
                    </div>
                    <div className="col-6 text-right">
                      <div>
                        <BasicButton
                          text="Eliminar y reasignar ACIS reportados"
                          disabled={selectedArea === undefined}
                          style={{ backgroundColor: COLOR_CODE_NEW.PRIMARY }}
                          onClick={() => {
                            deleteSection(true);
                          }}
                        />
                      </div>
                      <div className="mt-3">
                        <OutlineButton
                          text={t(TRANSLATIONS.CANCEL)}
                          onClick={() => {
                            setSelectedArea(originalSelectedSections[0]);
                            setSelectedSubarea(originalSelectedSections[1]);
                            setSelectedSubLocation(originalSelectedSections[2]);
                            setSectionToDelete(['', '', null]);
                            setIsReassigningSectionToDelete(false);
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}
                <div className="col">
                  <div className="row">
                    <h3>{sectionName}</h3>
                  </div>
                  { areas.map((a, index) => {
                    const isSelected = index === selectedArea;
                    return (
                      <Fragment key={a.id}>
                        <AreaOption
                          isSelected={isSelected}
                          selectOption={() => setArea(index)}
                          name={a.name}
                          secondaryText="Areas"
                          secondaryTextLength={a.subAreas.length}
                          onDelete={isReassigningSectionToDelete ? undefined : () => {
                            saveSelectedValues();
                            setSectionToEdit(['', '', null]);
                            setSectionToDelete([LocationAreasSections.AREA, a.name, a.id]);
                          }}
                          onEdit={isReassigningSectionToDelete ? undefined : () => {
                            saveSelectedValues();
                            setSectionToDelete(['', '', null]);
                            setEditNamEn(a.nameEN);
                            setEditName(a.name);
                            setSectionToEdit([LocationAreasSections.AREA, a.name, a.id]);
                          }}
                        />
                        { ((sectionToEdit[0] === LocationAreasSections.AREA)
                          && sectionToEdit[2] === a.id
                          && !isReassigningSectionToDelete)
                          ? (
                            <div className="col-12 text-center">
                              <NewOptionInput
                                isSectionFilled={editName !== ''}
                                onTextChange={text => setEditName(text)}
                                onTextChangeEn={text => setEditNamEn(text)}
                                inputValue={editName}
                                inputValueEn={editNamEn}
                                buttonText={`${t(TRANSLATIONS.EDIT)}`}
                                cancelButtonText={`${t(TRANSLATIONS.CANCEL)}`}
                                onSubmit={handleEditName}
                                onCancel={() => {
                                  saveSelectedValues();
                                  setSectionToEdit(['', '', null]);
                                }}
                                errorMessage={editNameError}
                                isLoading={editNameLoading}
                              />
                            </div>
                          ) : null }
                      </Fragment>

                    );
                  })}
                  {!isReassigningSectionToDelete && (
                    <NewOptionInput
                      isSectionFilled={newDepartmentName !== ''}
                      onTextChange={text => setNewDepartmentName(text)}
                      onTextChangeEn={text => setNewDepartmentNameEn(text)}
                      inputValue={newDepartmentName}
                      inputValueEn={newDepartmentNameEn}
                      buttonText={`${t(TRANSLATIONS.ADD)} ${sectionName}`}
                      onSubmit={() => addNewLocationSection(LocationAreasSections.AREA)}
                      errorMessage={newDepartmentError}
                      isLoading={newDepartmentLoading}
                    />
                  )}
                </div>
                <div className="col">
                  <div className="row">
                    <h3>{t(TRANSLATIONS.AREA)}</h3>
                  </div>
                  { typeof selectedArea === 'number' ? (
                    <>
                      {areas[selectedArea].subAreas.map((s, index) => {
                        const isSelected = index === selectedSubarea;
                        return (
                          <Fragment key={s.id}>
                            <AreaOption
                              key={s.id}
                              isSelected={isSelected}
                              selectOption={() => setSubarea(index)}
                              name={s.name}
                              secondaryText="Subareas"
                              secondaryTextLength={s.subLocations.length}
                              onDelete={isReassigningSectionToDelete ? undefined : () => {
                                saveSelectedValues();
                                setSectionToDelete([LocationAreasSections.SUB_AREA, s.name, s.id]);
                                setSectionToEdit(['', '', null]);
                              }}
                              onEdit={isReassigningSectionToDelete ? undefined : () => {
                                saveSelectedValues();
                                setSectionToDelete(['', '', null]);
                                setEditNamEn(s.nameEN);
                                setEditName(s.name);
                                setSectionToEdit([LocationAreasSections.SUB_AREA, s.name, s.id]);
                              }}
                            />
                            { ((sectionToEdit[0] === LocationAreasSections.SUB_AREA)
                            && sectionToEdit[2] === s.id
                            && !isReassigningSectionToDelete)
                              ? (
                                <div className="col-12 text-center">
                                  <NewOptionInput
                                    isSectionFilled={editName !== ''}
                                    onTextChange={text => setEditName(text)}
                                    onTextChangeEn={text => setEditNamEn(text)}
                                    inputValue={editName}
                                    inputValueEn={editNamEn}
                                    buttonText={`${t(TRANSLATIONS.EDIT)}`}
                                    cancelButtonText={`${t(TRANSLATIONS.CANCEL)}`}
                                    onSubmit={handleEditName}
                                    onCancel={() => {
                                      saveSelectedValues();
                                      setSectionToEdit(['', '', null]);
                                    }}
                                    errorMessage={editNameError}
                                    isLoading={editNameLoading}
                                  />
                                </div>
                              ) : null }
                          </Fragment>
                        );
                      })}

                      {!isReassigningSectionToDelete && (
                        <NewOptionInput
                          isSectionFilled={newSubAreaName !== ''}
                          onTextChange={text => setNewSubAreaName(text)}
                          onTextChangeEn={text => setNewSubAreaNameEn(text)}
                          inputValue={newSubAreaName}
                          inputValueEn={newSubAreaNameEn}
                          buttonText={`${t(TRANSLATIONS.ADD)} ${t(TRANSLATIONS.AREA)}`}
                          onSubmit={() => addNewLocationSection(LocationAreasSections.SUB_AREA)}
                          errorMessage={newSubAreaError}
                          isLoading={newSubAreaLoading}
                        />
                      )}
                    </>
                  ) : (
                    <div className="row">
                      <p className="text-secondary">{t(TRANSLATIONS.SELECT)} {sectionName}</p>
                    </div>
                  )}
                </div>
                <div className="col">
                  <div className="row">
                    <h3>{t(TRANSLATIONS.SUBAREA)}</h3>
                  </div>
                  { (typeof selectedSubarea === 'number' && typeof selectedArea === 'number') ? (
                    <>
                      {areas[selectedArea].subAreas[selectedSubarea].subLocations
                        .map((s, index) => {
                          const isSelected = index === selectedSubLocation;
                          return (
                            <Fragment key={s.id}>
                              <AreaOption
                                key={s.id}
                                isSelected={isSelected}
                                selectOption={() => setSelectedSubLocation(index)}
                                name={s.name}
                                onDelete={isReassigningSectionToDelete ? undefined : () => {
                                  saveSelectedValues();
                                  setSectionToDelete([
                                    LocationAreasSections.SUB_LOCATION, s.name, s.id,
                                  ]);
                                  setSectionToEdit(['', '', null]);
                                }}
                                onEdit={isReassigningSectionToDelete ? undefined : () => {
                                  saveSelectedValues();
                                  setSectionToDelete(['', '', null]);
                                  setEditNamEn(s.nameEN);
                                  setEditName(s.name);
                                  setSectionToEdit([
                                    LocationAreasSections.SUB_LOCATION, s.name, s.id,
                                  ]);
                                }}
                              />
                              { ((sectionToEdit[0] === LocationAreasSections.SUB_LOCATION)
                                && sectionToEdit[2] === s.id
                                && !isReassigningSectionToDelete)
                                ? (
                                  <div className="col-12 text-center">
                                    <NewOptionInput
                                      isSectionFilled={editName !== ''}
                                      onTextChange={text => setEditName(text)}
                                      onTextChangeEn={text => setEditNamEn(text)}
                                      inputValue={editName}
                                      inputValueEn={editNamEn}
                                      buttonText={`${t(TRANSLATIONS.EDIT)}`}
                                      cancelButtonText={`${t(TRANSLATIONS.CANCEL)}`}
                                      onSubmit={handleEditName}
                                      onCancel={() => {
                                        saveSelectedValues();
                                        setSectionToEdit(['', '', null]);
                                      }}
                                      errorMessage={editNameError}
                                      isLoading={editNameLoading}
                                    />
                                  </div>
                                ) : null }
                            </Fragment>
                          );
                        })}

                      {!isReassigningSectionToDelete && (
                        <NewOptionInput
                          isSectionFilled={newSubLocationName !== ''}
                          onTextChange={text => setNewSubLocationName(text)}
                          onTextChangeEn={text => setNewSubLocationNameEn(text)}
                          inputValue={newSubLocationName}
                          inputValueEn={newSubLocationNameEn}
                          buttonText={`${t(TRANSLATIONS.ADD)} ${t(TRANSLATIONS.SUBAREA)}`}
                          onSubmit={() => addNewLocationSection(LocationAreasSections.SUB_LOCATION)}
                          errorMessage={newSubLocationError}
                          isLoading={newSubLocationLoading}
                        />
                      )}

                    </>
                  ) : (
                    <div className="row">
                      <p className="text-secondary">{t(TRANSLATIONS.SELECT)} {t(TRANSLATIONS.AREA)}</p>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        ) : (
          <div className="card row mt-2 justify-content-center">
            { errorMessage ? (
              <div className="column justify-content-center">
                <h3>{t(TRANSLATIONS.ERROR_LOADING_INFORMATION)}</h3>
                <p className="mb-3">{ errorMessage }</p>
                <BasicButton
                  background={COLOR_CODE.DANGER}
                  text={t(TRANSLATIONS.RELOAD_DATA)}
                  onClick={fetchAreas}
                />
              </div>
            ) : (
              <LoadingSpinner height={240} />
            )}
          </div>
        )}
      </div>
    </>
  );
};

function mapStateToProps(state: any) {
  return {
    userCountry: state.getIn(['user', 'distributionCenter', 'country']),
  };
}
export default connect(mapStateToProps)(LocationAreasConfig);
