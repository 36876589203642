export const DefaultOptions = (
  {
    colors: ['#F39C1F', '#DA5926', '#C33827'],
    zoom: {
      enabled: true,
      autoScaleYaxis: true,
    },
    chart: {
      toolbar: {
        show: false,
      },
      height: 350,
      type: 'line',
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [4, 4, 4],
      curve: 'straight',
    },
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6,
      },
    },
  }
);

export const LineOptions = (
  {
    stroke: {
      width: [0, 4],
    },
    grid: {
      borderColor: '#f1f1f1',
      padding: {
        top: 0,
        right: 25,
        bottom: 20,
        left: 25,
      },
    },
  }
);
