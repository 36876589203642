import { gql } from '@apollo/client';

const GET_POSTS = gql`
query getDepartmentAreaSubareaFiltersForSupplyList ($userId: Int!, $locationsIds: [Int]) {
  getDepartmentAreaSubareaFiltersForSupply (userId: $userId, locationsIds: $locationsIds) {
    departments {
      id
      name
      nameEN
      slug
      locationId
    }
    areas {
      id
      name
      nameEN
      slug
      departmentId
      departmentSlug
    }
    subAreas {
      id
      name
      nameEn
      slug
      areaId
      areaSlug
      departmentId
      departmentSlug
    }
  }
}
`;

export const GET_INCIDENTS_BY_LAST_HOUR = gql`
query IncidentsByLastHour ($userId: ID!){
  getIncidentsByLastHour(userId: $userId){
    hasReachedLimit
    firstIncidentDate
  }
}
`;
