import request from '../../utils/request';
import { getTimeFiltersFromState } from '../../utils/dateHelpers';

export const SET_INCIDENTS = 'distribution-center/set-incidents';
export const SET_INCIDENTS_PAGINATION_INFO = 'distribution-center/set-incidents-pagination-info';
export const SET_INCIDENTS_BY_TIME = 'distribution-center/set-incidents-by-time';

export function setIncidents(incidents: any) {
  return { type: SET_INCIDENTS, incidents };
}

export function setIncidentsByTime(incidentsByTime: any) {
  return { type: SET_INCIDENTS_BY_TIME, incidentsByTime };
}

export function setIncidentsPaginationInfo(incidentsPaginationInfo: any) {
  return { type: SET_INCIDENTS_PAGINATION_INFO, incidentsPaginationInfo };
}

export function getIncidents(
  distCenter: string, searchString: string, page: number, limit: number,
) {
  return async (dispatch: any, getState: any) => {
    dispatch(setIncidents(undefined));
    const url = '/api/v1/list-incidents';
    const timeFilters = getTimeFiltersFromState(getState());
    let queryParams = {};

    if (page && limit) {
      queryParams = {
        distributionCenter: distCenter,
        search: searchString ?? '',
        page,
        limit,
      };
    } else {
      queryParams = {
        distributionCenter: distCenter,
        search: searchString ?? '',
      };
    }
    const response = await request.get(url, {
      ...timeFilters,
      ...queryParams,
    });
    dispatch(setIncidents(response.incidents));
    dispatch(setIncidentsPaginationInfo(response.paginationInfo));
  };
}

export function getTotalIncidentsByTime(
  distCenter: string, searchString: string, page: number, limit: number,
) {
  return async (dispatch: any, getState: any) => {
    dispatch(setIncidentsByTime(undefined));
    const timeFilters = getTimeFiltersFromState(getState());
    let queryParams = {};

    if (page && limit) {
      queryParams = {
        timezoneOffset: timeFilters.timezoneOffsetEnd,
        search: searchString ?? '',
        page,
        limit,
      };
    } else {
      queryParams = {
        timezoneOffset: timeFilters.timezoneOffsetEnd,
        search: searchString ?? '',
      };
    }
    const url = `/api/v1/distribution-centers/${distCenter}/incidents-by-time`;
    const response = await request.get(url, {
      ...timeFilters,
      ...queryParams,
    });
    dispatch(setIncidentsByTime(response));
  };
}

export function downloadIncidents(location: string, factory?: boolean, incidentsIds?: string) {
  return async (_: any, getState: any) => {
    const timeFilters = getTimeFiltersFromState(getState());
    window.open(
      `/api/v1/incidents-report?startTime=${timeFilters.startTime}&endTime=${timeFilters.endTime}&timezoneOffsetStart=${timeFilters.timezoneOffsetStart}&timezoneOffsetEnd=${timeFilters.timezoneOffsetEnd}&${location}&factory=${factory}&incidentsIds=${incidentsIds ?? ''}`,
      '_blank',
    );
  };
}

export function downloadAmbientalRisks(location: string) {
  return async (_: any, getState: any) => {
    const timeFilters = getTimeFiltersFromState(getState());
    window.open(
      `/api/v1/incidents-report?startTime=${timeFilters.startTime}&endTime=${timeFilters.endTime}&timezoneOffsetStart=${timeFilters.timezoneOffsetStart}&timezoneOffsetEnd=${timeFilters.timezoneOffsetEnd}&${location}&ambientalRisk=1`,
      '_blank',
    );
  };
}

export function downloadCertificates(timezone: string, location: string) {
  return async (_: any, getState: any) => {
    const timeFilters = getTimeFiltersFromState(getState());
    window.open(
      `/api/v3/user-access-certificates-report?startTime=${new Date(timeFilters.startTime).toISOString()}&endTime=${new Date(timeFilters.endTime).toISOString()}&${location}&timezone=${timezone}`,
      '_blank',
    );
  };
}

export function downloadTruckRoutinesExcel(
  location: string,
  type = 'TRUCK',
) {
  return async (_: any, getState: any) => {
    const timeFilters = getTimeFiltersFromState(getState());
    window.open(
      `/api/v1/get-routines-excel?startTime=${timeFilters.startTime}&endTime=${timeFilters.endTime}&type=${type}&timezoneOffsetStart=${timeFilters.timezoneOffsetStart}&timezoneOffsetEnd=${timeFilters.timezoneOffsetEnd}&${location}`,
      '_blank',
    );
  };
}

export function downloadRoutinesExcel(
  location: string,
  timeFilters: {
    startTime: Date;
    endTime: Date;
    timezoneOffsetStart: number;
    timezoneOffsetEnd: number;
  },
  type = 'all',
) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (_: any, getState: any) => {
    window.open(
      `/api/v1/get-routines-excel?startTime=${timeFilters.startTime}&type=${type}&endTime=${timeFilters.endTime}&timezoneOffsetStart=${timeFilters.timezoneOffsetStart}&timezoneOffsetEnd=${timeFilters.timezoneOffsetEnd}&${location}`,
      '_blank',
    );
  };
}

export async function getAreasForLocation(locationSlug: string) {
  const url = `/api/v1/location/${locationSlug}/areas-tree`;
  const areas = await request.get(url);
  return areas;
}

export async function getAreasForLocationById(locationId: number) {
  const url = `/api/v1/locationById/${locationId}/areas-tree`;
  const areas = await request.get(url);
  return areas;
}

export async function createAreaForLocation(
  locationSlug: string, name: string, nameEn: string, areaId?: number, subAreaId?: number,
) {
  const url = `/api/v1/location/${locationSlug}/areas-tree`;
  await request.post(url, {
    name, areaId, subAreaId, nameEn,
  });
}

export async function removeSectionForLocation(
  locationSlug: string,
  level: string,
  idToRemove: number,
  areaIdToReasign?: number,
  subAreaIdToReasign?: number,
  subLocationIdToReasign?: number,
) {
  const url = `/api/v1/location/${locationSlug}/areas-tree`;
  await request.delete(url, {
    level, idToRemove, areaIdToReasign, subAreaIdToReasign, subLocationIdToReasign,
  });
}
