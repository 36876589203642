import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { ATTACHMENT_TYPES, POSTS_TYPES } from 'types/enums';
import { setMonthlyDropdownChange } from 'store/actions/timeFilter.actions';
import { setHasSearched, setSearchLoading, setSearchResults, setSearchState } from 'store/actions/safetyAcademy.actions';

import MonthlyTimeFilter from 'components/MonthlyTimeFilter';
import SelectDropdown from 'components/Dropdown/SelectDropdown';
import { useNavigate } from 'react-router-dom';

import { Maybe, Posts_Types, useGetPostsLazyQuery } from 'generated/graphql';
import { ReactComponent as file } from 'assets/icons/file.svg';
import { ReactComponent as gallery } from 'assets/icons/gallery.svg';
import { ReactComponent as video } from 'assets/icons/video.svg';
import { ReactComponent as Search } from 'assets/icons/search.svg';

import './SearchBar.scss';

const dropdowmFilterOptions = [
  { value: 'ALL', label: 'Todo' },
  { value: POSTS_TYPES.PREVENTION_CAMPAIGNS, label: 'Campaña preventiva' },
  { value: POSTS_TYPES.BEST_PRACTICES, label: 'Buenas prácticas' },
  { value: POSTS_TYPES.NEWS, label: 'Noticias Generales' },
];

const fileTypeOptions = [
  { value: ATTACHMENT_TYPES.IMAGE, label: 'Póster', Icon: gallery, id: 0 },
  { value: ATTACHMENT_TYPES.VIDEO, label: 'Video', Icon: video, id: 1 },
  { value: ATTACHMENT_TYPES.PDF, label: 'PDF', Icon: file, id: 2 },
];

const SearchBar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState('');
  const [selectedPostType, setSelectedPostType] = useState(dropdowmFilterOptions[0].value);
  const [selectedFileTypes, setSelectedFileTypes] = useState<any>([]);
  const [timeFilterOption, setTimeFilterOption] = useState<string>(format(new Date(), 'yyyy'));

  const startDate = useSelector((state: any) => state.toJS().timeFilter?.startTime);
  const endDate = useSelector((state: any) => state.toJS().timeFilter?.endTime);

  const [getResults, { loading, data }] = useGetPostsLazyQuery();

  const handleSelectFileTypes = (value: ATTACHMENT_TYPES) => {
    if (selectedFileTypes.includes(value)) {
      setSelectedFileTypes(selectedFileTypes.filter((type: ATTACHMENT_TYPES) => type !== value));
      console.log(selectedFileTypes.filter((type: ATTACHMENT_TYPES) => type !== value));
    } else {
      setSelectedFileTypes([...selectedFileTypes, value]);
    }
  };

  const handleSearch = () => {
    getResults({ variables: {
      limit: 12,
      search: searchValue,

      type: selectedPostType !== 'ALL' ? (selectedPostType as Maybe<Posts_Types>) : undefined,
      startDate,
      endDate,
      attachmentType: selectedFileTypes,
    } });
    dispatch(setSearchState(searchValue));
    dispatch(setHasSearched(true));
    navigate('/safety-academy');
  };

  const handleMonthChange = (value: 'typeOf MonthlyOptions') => {
    setTimeFilterOption(value);
    dispatch(setMonthlyDropdownChange(value));
  };

  useEffect(() => {
    dispatch(setSearchLoading(loading));
  }, [loading, dispatch]);

  useEffect(() => {
    dispatch(setMonthlyDropdownChange(format(new Date(), 'yyyy')));
  }, [dispatch]);

  useEffect(() => {
    const result = data?.getPosts;
    dispatch(setSearchResults(result?.posts));
  }, [data, dispatch]);

  return (
    <div className="search-bar">
      <div className="search-bar__main-pill">
        <input
          placeholder="Busca por título o contenido..."
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
        />
        <SelectDropdown
          name="Type"
          bgColor="transparent"
          value={selectedPostType}
          onChange={(e: any) => setSelectedPostType(e.target.value)}
          dropdownContent={dropdowmFilterOptions}
          className="search-bar__main-pill__dropdown"
        />
        <MonthlyTimeFilter
          bgColor="transparent"
          onChange={handleMonthChange}
          currentValue={timeFilterOption}
          t={t}
        />
      </div>
      <div className="search-bar__file-types-picker">
        {fileTypeOptions.map(({ label, Icon, value }) => (
          <div
            className={`
              search-bar__file-types-picker__option 
              ${selectedFileTypes.includes(value) && 'search-bar__file-types-picker__option--selected'}
            `}
            onClick={() => handleSelectFileTypes(value)}
            role="presentation"
            key={value}
          >
            <Icon />
            {label}
          </div>
        ))}
      </div>
      <button
        type="button"
        className="search-bar__search-button"
        onClick={handleSearch}
      >
        <Search />
      </button>
    </div>
  );
};

export default SearchBar;
