import React, { useMemo } from 'react';
import Chart from 'react-apexcharts';
import { BU } from 'types/enums';
import { BULISTMap } from 'utils/userHelper';
import { DefaultOptions, LineOptions } from 'utils/ChartOptions';
import ErrorMessage from 'components/ErrorMessage';
import { useSelector } from 'react-redux';
import EmptyState from 'components/EmptyState/EmptyState';
import selectDriverManagementSystem from 'store/selectors/driverManagementSystem.selectors';
import {
  customDMSDataSelectorByYearMonthTypeBuAndSubType,
  DMSSelectorTypes,
} from '../dmsHelper';
import './IncidentsLineChartDMSStyles.scss';

type Props = {
  month: string;
  year: number;
  loading: boolean;
  error: boolean;
};

const IncidentsLineChart = ({ month, year, loading, error }: Props) => {
  const driverManagementSystemData = useSelector(selectDriverManagementSystem);
  const selectedMonthData = customDMSDataSelectorByYearMonthTypeBuAndSubType(
    driverManagementSystemData,
    year,
    month,
    DMSSelectorTypes.perWeekGraph,
  );

  const chartDataValues = useMemo(() => {
    if (!loading) {
      if (selectedMonthData) {
        return {
          xAxisCategories: selectedMonthData.weeks,
          mexioChartData: selectedMonthData[BU.Mexico],
          colombiaChartData: selectedMonthData[BU.Colombia],
          ecuadorChartData: selectedMonthData[BU.Ecuador],
          peruChartData: selectedMonthData[BU.Perú],
          cacChartData: selectedMonthData[BU.CAC],
        };
      }
    }
    return {
      xAxisCategories: [],
      mexioChartData: [],
      colombiaChartData: [],
      ecuadorChartData: [],
      peruChartData: [],
      cacChartData: [],
    };
  }, [loading, selectedMonthData]);
  const xaxisCategories = chartDataValues.xAxisCategories;

  const customOptions = {
    ...LineOptions,
    ...DefaultOptions,
    stroke: {
      ...DefaultOptions.stroke,
    },
    xaxis: {
      categories: xaxisCategories,
    },
    yaxis: {
      title: {
        text: 'Registro',
        style: {
          color: '#002033',
          fontSize: '12px',
          fontFamily: 'Roboto',
          fontWeight: 400,
        },
      },
      labels: {
        formatter: (value: any) => { return value.toLocaleString(); },
      },
    },
    legend: {
      show: false,
      markers: {
        fillColors: ['#DEA153', '#86A85F', '#BE6742', '#A33223', '#5572B9'],
      },
    },
    colors: ['#DEA153', '#86A85F', '#BE6742', '#A33223', '#5572B9'],
  };

  const chartData = [
    {
      name: `BU ${BULISTMap[BU.Colombia].label}`,
      data: chartDataValues.colombiaChartData,
    },
    {
      name: `BU ${BULISTMap[BU.Mexico].label}`,
      data: chartDataValues.mexioChartData,
    },
    {
      name: `BU ${BULISTMap[BU.Ecuador].label}`,
      data: chartDataValues.ecuadorChartData,
    },
    {
      name: `BU ${BULISTMap[BU.Perú].label}`,
      data: chartDataValues.peruChartData,
    },
    {
      name: `BU ${BULISTMap[BU.CAC].label}`,
      data: chartDataValues.cacChartData,
    },
  ];

  if (error) return <ErrorMessage />;
  if (loading) return <></>;
  if (
    chartDataValues.colombiaChartData.length === 0
    && chartDataValues.mexioChartData.length === 0
    && chartDataValues.ecuadorChartData.length === 0
    && chartDataValues.peruChartData.length === 0
    && chartDataValues.cacChartData.length === 0
  ) return <EmptyState />;

  return (
    <div>
      <Chart
        type="line"
        // @ts-ignore
        options={customOptions}
        series={chartData}
        height="320px"
      />
      <div className="dms">
        <div className="labelsWrap">
          <span className="labelsWrap__label">{month}</span>
        </div>
      </div>
    </div>
  );
};

export default IncidentsLineChart;
