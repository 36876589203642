import React, { useState } from 'react';
import Input from 'components/Input';
import { useTranslation } from 'react-i18next';
import Modal from '../../../components/Modal';
import './VerifyUser.scss';
import BasicButton from '../../../components/Button/BasicButton';
import { COLOR_CODE_NEW, TRANSLATIONS } from '../../../types/enums';
import request from '../../../utils/request';
import LoadingSpinner from '../../../components/LoadingSpinner';

type Props = {
  close: () => void;
  setUserInfoPopupConfig: (config: any) => void;
  distributionCenterSlug: string;
};

const VerifyUser = (props: Props) => {
  const { t } = useTranslation();
  const { distributionCenterSlug } = props;
  const [subtitle, setSubTitle] = useState(t(TRANSLATIONS.DOES_USER_ACCOUNT));
  const [email, setEmail] = useState('');
  const [accountExists, setAccountExists] = useState(false);
  const [errorText, setErrorText] = useState('');
  const { close, setUserInfoPopupConfig } = props;
  const [loading, setLoading] = useState(false);

  const handleCreateUser = () => {
    setUserInfoPopupConfig({
      show: true,
      action: 'add-user',
      source: 'verify-user',
      distributionCenterSlug,
      formTitle: t(TRANSLATIONS.ADD_USER_TO_DC),
    });
  };

  const handleSubmit = async () => {
    // eslint-disable-next-line no-useless-escape
    if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))) {
      setErrorText(t(TRANSLATIONS.USER_NOT_EXIST_CREATE_NEW));
      setSubTitle(t(TRANSLATIONS.ASK_USER_WHICH_EMAIL));
      return;
    }
    setLoading(true);
    const user = await request.get(`/api/v1/users-info/${email}`).catch(() => {
      setLoading(false);
      setErrorText(t(TRANSLATIONS.SOMETHING_WENT_WRONG_TRY_LATER));
    });
    setLoading(false);
    if (user && user.slug) {
      setUserInfoPopupConfig({
        show: true,
        action: 'edit-user',
        userData: user,
        source: 'verify-user',
        formTitle: t(TRANSLATIONS.EDIT_INFORMATION),
        distributionCenterSlug,
        successBtnText: t(TRANSLATIONS.CONFIRM),
      });
    } else {
      setErrorText(t(TRANSLATIONS.USER_NOT_EXIST_CREATE_NEW));
    }
  };

  return (
    <Modal
      onOverlayClick={() => {
        if (close) {
          close();
        }
      }}
      modalContainerStyles={{
        width: '40vw',
        left: '30vw',
        height: '50vh',
        top: '25vh',
      }}
    >
      <div className="verify-user-header">
        <h2>{t(TRANSLATIONS.USER_ALREADY_EXISTS)}</h2>
        {/* @ts-ignore */}
        <h4>{subtitle}</h4>
      </div>
      {!accountExists
        && (
          <div className="verifyButtons">
            <BasicButton
              text={t(TRANSLATIONS.YES_HE_HAS)}
              onClick={() => {
                setAccountExists(true);
              }}
              style={{
                backgroundColor: COLOR_CODE_NEW.PRIMARY,
                padding: '10px 22px',
                marginRight: '20px',
                flex: 1,
              }}
            />
            <BasicButton
              text={t(TRANSLATIONS.NO_CREATE_SCRATCH)}
              onClick={handleCreateUser}
              style={{
                backgroundColor: COLOR_CODE_NEW.PRIMARY_LIGHT,
                color: COLOR_CODE_NEW.PRIMARY,
                padding: '10px 22px',
                flex: 1,
              }}
            />
          </div>
        )}

      {accountExists && (
        <div className="verify-user-form">

          <form onSubmit={e => {
            e.preventDefault();
            e.stopPropagation();
            handleSubmit();
            return false;
          }}
          >
            <div>
              <div className="emailInput">
                <span>{t(TRANSLATIONS.EMAIL)}</span>
                <Input
                  value={email}
                  placeholder={t(TRANSLATIONS.ENTER_EMAIL)}
                  activeBoxShadow="none"
                  activeBorderColor={COLOR_CODE_NEW.PRIMARY}
                  onChange={e => {
                    setErrorText('');
                    setEmail(e.target.value);
                  }}
                  style={{
                    border: `2px solid ${COLOR_CODE_NEW.BORDERS}`,
                    maxWidth: 'unset',
                    paddingTop: '5px',
                    paddingBottom: '5px',
                  }}
                />
              </div>

              <div className="error-and-button">
                <span className="errorText">{errorText}</span>
                {loading ? <LoadingSpinner text="" />
                  : (
                    <BasicButton
                      text={t(TRANSLATIONS.SEARCH)}
                      onClick={handleSubmit}
                      style={{
                        backgroundColor: COLOR_CODE_NEW.PRIMARY,
                        color: COLOR_CODE_NEW.WHITE,
                        padding: '10px 22px',
                        // flex: 1,
                      }}
                    />
                  )}
              </div>
              {errorText && errorText.length
                && (
                  <BasicButton
                    text={t(TRANSLATIONS.ADD_USER_TO_DC)}
                    onClick={handleCreateUser}
                    style={{
                      backgroundColor: COLOR_CODE_NEW.PRIMARY_LIGHT,
                      color: COLOR_CODE_NEW.PRIMARY,
                      padding: '10px 22px',
                      // flex: 1,
                    }}
                  />
                )}
            </div>
          </form>
        </div>
      )}

    </Modal>
  );
};

export default VerifyUser;
