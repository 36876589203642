import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import Iframe from 'components/Iframe';
import { TRANSLATIONS } from 'types/enums';
import { useTranslation } from 'react-i18next';
import ChartTabs from 'components/Tabs/ChartTabs';
import axios from 'axios';
import Header from 'components/Header';
import { useSelector } from 'react-redux';
import { selectLocationsToAccess, selectSelectedLocation } from 'store/selectors/globalFilters.selectors';
import Modal from 'components/Modal';
import BasicButton from 'components/Button/BasicButton';
import ProgressBar from 'components/ProgressBar';
import ChartDropdown from 'components/Dropdown/ChartDropdown';
import './OWD.scss';
import { generateQueryDates } from 'utils/dateHelpers';

export enum OWDDropDowns {
  month = 'month',
  year = 'year',
}

const { CancelToken } = axios;

const OWDPowerBi = () => {
  const { t } = useTranslation();

  const cancelSource = useRef<any>(null);
  const isRequestCancelledManually = useRef<any>(false);

  const monthArr = [
    t(TRANSLATIONS.JANUARY),
    t(TRANSLATIONS.FEBRUARY),
    t(TRANSLATIONS.MARCH),
    t(TRANSLATIONS.APRIL),
    t(TRANSLATIONS.MAY),
    t(TRANSLATIONS.JUNE),
    t(TRANSLATIONS.JULY),
    t(TRANSLATIONS.AUGUST),
    t(TRANSLATIONS.SEPTEMBER),
    t(TRANSLATIONS.OCTOBER),
    t(TRANSLATIONS.NOVEMBER),
    t(TRANSLATIONS.DECEMBER),
  ];

  const getLatestYearsAndCurrentYear = () => {
    const localDate: Date = new Date();
    const baseYear: number = 2021;
    const currentYear: number = localDate.getUTCFullYear();
    const years: any[] = [];

    for (let i = baseYear; i <= currentYear; i++) {
      const currentYearToString: string = i.toString();

      years.push({
        label: currentYearToString,
        slug: currentYearToString,
        year: i,
      });
    }

    return years.reverse();
  };

  const monthOptions: any = monthArr.map((monthSlug, monthIndex) => ({
    slug: monthSlug,
    label: monthSlug.substring(0, 1).toUpperCase() + monthSlug.substring(1),
    month: monthSlug,
    monthNumber: monthIndex + 1,
  }));

  const yearOptions = getLatestYearsAndCurrentYear();

  const getCurrentMonth = () => {
    const localDate: Date = new Date();
    const monthIndexToGet: number = localDate.getUTCMonth();

    return monthOptions[monthIndexToGet];
  };

  const getCurrentYear = () => {
    const localDate: Date = new Date();
    const currentYear: number = localDate.getUTCFullYear();
    const yearDataToReturn = yearOptions.find(year => Number(year.label) === currentYear);
    return yearDataToReturn ?? yearOptions[0];
  };

  const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false);
  const [showProgressBar, setShowProgressBar] = useState<boolean>(false);
  const [progressPercentage, setProgressPercentage] = useState<number>(0);
  const [downloadError, setDownloadError] = useState<boolean>(false);
  const [sentViaEmail, setSentViaEmail] = useState<boolean>(false);
  const [isDropDownOpen, setIsDropDownOpen] = useState<OWDDropDowns | undefined>(undefined);
  const [selectedMonth, setSelectedMonth] = useState(getCurrentMonth());
  const [selectedYear, setSelectedYear] = useState(getCurrentYear());

  const locations = useSelector(selectLocationsToAccess);
  const isDCSelected = useSelector(selectSelectedLocation);

  const dropDownOpenCloseHandler = (opened: boolean, dropDownType: OWDDropDowns) => {
    return opened ? setIsDropDownOpen(dropDownType) : setIsDropDownOpen(undefined);
  };

  const validMonthOptions: any = useMemo(() => {
    const localDate: Date = new Date();
    const currentYear: number = localDate.getUTCFullYear();
    if (selectedYear.year === currentYear) {
      const currentMonthIndex = localDate.getUTCMonth();
      const possibleMonths = monthOptions.slice(0, currentMonthIndex + 1);
      if (isDCSelected?.locationSlug) {
        return [
          { slug: t(TRANSLATIONS.ALL), label: t(TRANSLATIONS.ALL), allMonths: true },
          ...possibleMonths,
        ];
      }
      return possibleMonths;
    }
    if (isDCSelected?.locationSlug) {
      return [
        { slug: t(TRANSLATIONS.ALL), label: t(TRANSLATIONS.ALL), allMonths: true },
        ...monthOptions,
      ];
    }
    return monthOptions;
    // eslint-disable-next-line
  }, [selectedYear, isDCSelected]);

  useEffect(() => {
    if (!isDCSelected?.locationSlug) {
      setSelectedMonth(validMonthOptions[0]);
    }
  // eslint-disable-next-line
  }, [isDCSelected]);

  const tabs = [
    { title: t(TRANSLATIONS.SUMMARY), value: '', type: TRANSLATIONS.SUMMARY, index: 0 },
    { title: t(TRANSLATIONS.DEVIATIONS), value: '', type: TRANSLATIONS.DEVIATIONS, index: 1 },
  ];
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const closeModal = () => {
    setSentViaEmail(false);
    setDownloadError(false);
    setShowDownloadModal(false);
    setShowProgressBar(false);
    setProgressPercentage(0);
  };

  const onPressCancelRequest = () => {
    setSentViaEmail(false);
    setDownloadError(false);
    setShowDownloadModal(false);
    setShowProgressBar(false);
    setProgressPercentage(0);
    if (cancelSource?.current) {
      isRequestCancelledManually.current = true;
      cancelSource.current.cancel();
    }
  };

  const onClickDownload = () => {
    setShowDownloadModal(true);
  };

  const downloadOwdExcel = useCallback(
    async () => {
      try {
        cancelSource.current = CancelToken.source();
        setSentViaEmail(false);
        setDownloadError(false);
        setShowDownloadModal(true);
        setShowProgressBar(true);
        const { timezoneOffsetStart } = generateQueryDates();
        const url = `/api/v1/owd/excel?locationIds=${JSON.stringify(locations)}&timezoneOffset=${timezoneOffsetStart}`;
        const res = await axios({
          url,
          method: 'GET',
          onDownloadProgress: (progressEvent: any) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setProgressPercentage(percentCompleted);
          },
          params: {
            month: selectedMonth?.monthNumber,
            year: selectedYear?.slug,
            allMonths: selectedMonth?.allMonths,
          },
          cancelToken: cancelSource.current.token,
        });
        if (res.data?.sentViaEmail) {
          return setSentViaEmail(true);
        }
        const downloadUrl = res.data;
        const a = document.createElement('a');
        a.href = downloadUrl;
        setShowDownloadModal(false);
        setShowProgressBar(false);
        setProgressPercentage(0);
        a.click();
      } catch (error) {
        if (!isRequestCancelledManually.current) {
          setDownloadError(true);
          setProgressPercentage(0);
          isRequestCancelledManually.current = false;
        }
      }
    }, [locations, selectedMonth, selectedYear],
  );

  const handleDropMonthChange = ({ key }: { key: any }) => {
    const selectedDropDownMonth = validMonthOptions.find(
      (item: { slug: any; }) => item.slug === key,
    );
    setSelectedMonth(selectedDropDownMonth || validMonthOptions[0]);
    setIsDropDownOpen(undefined);
  };

  const handleDropYearChange = ({ key }: { key: any }) => {
    const selectedDropDownYear = yearOptions.find(item => item.slug === key);
    setSelectedYear(selectedDropDownYear || yearOptions[0]);
    setIsDropDownOpen(undefined);
  };

  useEffect(() => {
    const currentYear: number = (new Date()).getUTCFullYear();
    if (selectedYear.year === currentYear) {
      const currentMonthIndex = (new Date()).getMonth();
      if (selectedMonth.monthNumber > currentMonthIndex + 1) {
        handleDropMonthChange({ key: monthArr[currentMonthIndex] });
      }
    }

  // eslint-disable-next-line
  }, [validMonthOptions]);

  return (
    <div>
      {showDownloadModal && (
      <Modal
        modalContainerStyles={{ width: '70vw',
          left: '20%',
          maxHeight: '90vh',
          minHeight: '20vh',
          height: 'fit-content',
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)' }}
      >
        <div className="downloadMoal">
          <p className="downloadMoal__title">{downloadError ? `${t(TRANSLATIONS.SOMETHING_WENT_WRONG)}...` : t(TRANSLATIONS.DOWNLOAD_DATA)}</p>
          {showProgressBar ? (
            <>
              <p className="downloadMoal__downloading_title">{downloadError ? t(TRANSLATIONS.DOWNLOAD_FAIL_RETRY_THE_DOWNLOAD_PLEASE) : sentViaEmail ? t(TRANSLATIONS.THE_DATA_IS_TAKING_A_LONG_TIME_TO_DOWNLOAD_A_DOWNLOAD_LINK_WILL_BE_SENT_TO_YOUR_EMAIL_TO_CONTINUE) : t(TRANSLATIONS.DOWNLOADING_DATA)}</p>
              {!downloadError && !sentViaEmail && (
              <div className="downloadMoal__progress_bar">
                <p className="downloadMoal__progress_bar__title">{progressPercentage}%</p>
                <ProgressBar bgcolor="#4472C4" height={16} progress={progressPercentage} />
              </div>
              )}
            </>
          ) : (
            <>
              <p className="downloadMoal__description">{t(TRANSLATIONS.THE_DATA_FOR_THE_LOCATIONS_THAT_ARE_FILTERED_IN_THE_TOP_FILTER_BAR_WILL_BE_DOWNLOADED)}</p>
              <div className="downloadMoal__header">
                <Header showLogo={false} showSettings={false} />
              </div>
              <div className="downloadMoal__yearFilter">
                <div>
                  <p className="downloadMoal__yearFilter__yearTitle">{t(TRANSLATIONS.YEAR)}</p>
                  <ChartDropdown
                    onChange={handleDropYearChange}
                    selectedDropDownItem={selectedYear}
                    dropDownItems={yearOptions}
                    dropDownOpen={isDropDownOpen === OWDDropDowns.year}
                    setDropDownOpen={
                      (opened: boolean) => dropDownOpenCloseHandler(opened, OWDDropDowns.year)
                    }
                    labelClassName="label-default"
                    dropdownArrowColor="#2B323B"
                  />
                </div>
                <div>
                  <p className="downloadMoal__yearFilter__monthTitle">{t(TRANSLATIONS.MONTH)}</p>
                  <ChartDropdown
                    onChange={handleDropMonthChange}
                    selectedDropDownItem={selectedMonth}
                    dropDownItems={validMonthOptions}
                    dropDownOpen={isDropDownOpen === OWDDropDowns.month}
                    setDropDownOpen={
                      (opened: boolean) => dropDownOpenCloseHandler(opened, OWDDropDowns.month)
                    }
                    labelClassName="label-default"
                    dropdownArrowColor="#2B323B"
                  />
                </div>
              </div>
            </>
          )}
          {!sentViaEmail ? (
            <div className="downloadMoal__button_container">
              {downloadError ? (
                <BasicButton text={t(TRANSLATIONS.RETRY_AGAIN)} className="downloadMoal__button_container__download_button" onClick={downloadOwdExcel} />
              ) : (
                <BasicButton disabled={showProgressBar} text={t(TRANSLATIONS.DOWNLOAD)} className="downloadMoal__button_container__download_button" onClick={downloadOwdExcel} />
              )}
              <BasicButton text={t(TRANSLATIONS.CANCEL)} className="downloadMoal__button_container__cancel_button" onClick={showProgressBar ? onPressCancelRequest : closeModal} />
            </div>
          ) : (
            <div className="downloadMoal__continue_button_container">
              <BasicButton text={t(TRANSLATIONS.CONTINUE)} className="downloadMoal__continue_button_container__continue_button" onClick={closeModal} />
            </div>
          )}
        </div>
      </Modal>
      )}
      <div className="chartHeader">
        <h4 className="chartHeader__title">OWDs</h4>
        <BasicButton text={t(TRANSLATIONS.DOWNLOAD_DATA)} className="chartHeader__download_button" onClick={onClickDownload} />
      </div>
      <ChartTabs
        tabsItems={tabs}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab as any}
        selectedTabStyle="selected-tab"
        customStyle="tabs-container"
      />
      {selectedTab.index === 0 && (
        <Iframe
          iframe="https://app.powerbi.com/view?r=eyJrIjoiMTc2ODc3MDgtNWIwZC00ZGVkLTkwNDctNTVmYzdlOGQxNzE4IiwidCI6ImJlMTliNmNlLTdkMTktNGVkOS05NDgzLWIzM2I5NDI3ZTc4YyJ9"
          iframeTitle="Resumen"
          pageName="ReportSection"
        />
      )}
      {selectedTab.index === 1 && (
        <Iframe
          iframe="https://app.powerbi.com/view?r=eyJrIjoiMTc2ODc3MDgtNWIwZC00ZGVkLTkwNDctNTVmYzdlOGQxNzE4IiwidCI6ImJlMTliNmNlLTdkMTktNGVkOS05NDgzLWIzM2I5NDI3ZTc4YyJ9"
          iframeTitle="Desviaciones"
          pageName="ReportSection1a9d8028afcaed3f1bd1"
        />
      )}
    </div>
  );
};

export default OWDPowerBi;
