/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-shadow */
import ChartDropdown from 'components/Dropdown/ChartDropdown';
import ExpandableTable from 'components/ExpandableTable';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import './RoutinesStyles.scss';
import LoadingSpinner from 'components/LoadingSpinner';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns/esm';
import { getWeek } from 'date-fns';
import { FILTER_ACCESS_LEVELS, TRANSLATIONS } from 'types/enums';
import { useSelector } from 'react-redux';
import { selectLocationsToAccess } from 'store/selectors/globalFilters.selectors';

import { selectCurrentAccesLevel } from 'store/selectors/distributionCenters.selectors';
import { routinesMonthSelectordropDownItems } from 'utils/dateHelpers';
import { TypeLabelSlug } from 'types/types';
import RoutineLocationTopBottom from './LocationsTopBottom/LocationTopBottom';
import RoutinePositionsTopBottom from './PositionsTopBottom/PositionsTopBottom';
import ProgressByRoutine from './ProgressByRoutine/ProgressByRoutine';
import useGetUsersRoutine, { UserRoutineError } from './hooks/useGetUsersRoutine';
import { useGetUsersPositions, UsersPositionsError } from './hooks/useGetUsersPosition';

type Props = {
  selectedPillar: string,
  allPillarIds: string[]
};

export default function Routines({ selectedPillar, allPillarIds } : Props) {
  const dropDownItems = useMemo(() => routinesMonthSelectordropDownItems(true), []);
  const [tabs, setTabs] = useState<any[]>([]);
  const [selectedTab, setSelectedTab] = useState<TypeLabelSlug>({ label: '', slug: '' });
  const [routines, setRoutines] = useState([]);
  const [positionCompletion, setPositionCompletion] = useState(0);
  const accessLevel = useSelector(selectCurrentAccesLevel);
  const showAll = [FILTER_ACCESS_LEVELS.DISTRIBUTION_CENTER].includes(accessLevel);
  const locationsToAccess = useSelector(selectLocationsToAccess);
  const { t } = useTranslation();
  const getT = useCallback((t: any) => {
    if (t === '0 0 * * *') return 'Diaria';
    if (t === '0 0 * * 0') return 'Semanal';
    if (t === '0 0 1 * *') return 'Mensual';
  }, []);

  const getMonthlyRoutines = useCallback((t: any, weeks: any) => {
    if (t === '0 0 * * *') return 6 * weeks;
    if (t === '0 0 * * 0') return weeks;
    if (t === '0 0 1 * *') return 1;
    return 0;
  }, []);

  const [selectedDropDownItem, setSelectedDropDownItem] = useState(dropDownItems[0]);
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [positionDropdownOpen, setPostionDropdownOpen] = useState(false);
  const [weekInfo, setWeekInfo] = useState(dropDownItems[2].content);

  const userRoutine = useGetUsersRoutine({
    selectedPosition: selectedTab,
    selectedDropDownItem,
  });

  const usersPositions = useGetUsersPositions();

  useEffect(() => {
    if (userRoutine.result && !userRoutine.loading && !userRoutine.error) {
      let accumulativeTotalRoutines = 0;
      let accumulativeRoutinesDone = 0;
      // setCursor(userRoutine.result.cursor);
      const rr = userRoutine.result.map((r: any) => {
        let totalRoutines = 0;
        let routinesDone = 0;
        r.userRoutines.forEach((c: any) => {
          const monthlyRoutinesCount = getMonthlyRoutines(
            c.routineAvailable.frequency,
            selectedDropDownItem.content.weeks,
          );
          totalRoutines += monthlyRoutinesCount;
          routinesDone += (monthlyRoutinesCount > 0 ? c.routines.length : 0);
        }, 0);
        accumulativeTotalRoutines += totalRoutines;
        accumulativeRoutinesDone += routinesDone;

        return {
          last: '',
          month: (routinesDone / totalRoutines) * 100 || 0,
          ...r,
          subRows: r.userRoutines.map((ro: any) => ({
            ...ro,
            name: ro.routineAvailable.name,
            distributionCenter: {
              name: getT(ro.routineAvailable.frequency),
            },
            last:
              ro.routines[0]
              && format(new Date(ro.routines[ro.routines.length - 1].completedAt), 'MM/dd'),
          })),
        };
      });
      setPositionCompletion((accumulativeRoutinesDone / accumulativeTotalRoutines) * 100);
      setRoutines(rr);
    }
  }, [
    getMonthlyRoutines,
    getT,
    selectedDropDownItem.content.weeks,
    userRoutine.result,
    userRoutine.loading,
    userRoutine.error,
  ]);

  const columns = [
    {
      Header: '',
      accessor: 'name',
      getProps: (state: any) => {
        if (state.row.depth) {
          return {
            style: {
              fontWeight: 'bold',
            },
          };
        }
        return {};
      },
    },
    {
      Header: t(TRANSLATIONS.DIST_CENTERS),
      accessor: 'distributionCenter.name',
    },
    {
      Header: t(TRANSLATIONS.CURRENT_PROGRESS),
      style: {
        borderWidth: 2,
      },
      id: 'prog',
      // accessor: 'month',
      Cell: (data: any) => {
        if (data.row.depth === 0) {
          return (
            <p style={{ color: '#C00000' }}>{data.row.original.month.toFixed(0)}%</p>
          );
        }
        const frequency = data.row.original?.routineAvailable?.frequency;
        const toDo = getMonthlyRoutines(frequency, selectedDropDownItem.content.weeks);
        const totalDone = data.row.original?.routines?.length;
        return `${totalDone}/${toDo}`;
      },
    },
    {
      Header: t(TRANSLATIONS.LAST),
      id: 'last',
      accessor: 'last',
    },
  ];
  const currentWeek = useMemo(() => getWeek(new Date()), []);

  for (let int = 0; int < weekInfo.weeks; int++) {
    columns.push({
      Header: `${t(TRANSLATIONS.WEEK)} ${int + 1}`,
      id: `${int}`,
      // @ts-ignore
      Cell: (data: any) => {
        if (data.row.depth === 0) return '';

        const frequency = data.row.original?.routineAvailable?.frequency;
        let totalEntries = 0;
        if (frequency === '0 0 * * *') {
          totalEntries = 6;
        }
        if (frequency === '0 0 * * 0') {
          totalEntries = 1;
        }
        const routinesDone = data.row.original?.routines.reduce((a: any, c: any) => {
          const week = getWeek(new Date(c.completedAt)) - weekInfo.weekNumber;
          if (week === int) return a + 1;
          return a;
        }, 0);
        if ((weekInfo.weekNumber + int) > currentWeek) return null;
        let missingEntries = [];
        if (routinesDone < totalEntries) {
          missingEntries = new Array(totalEntries - routinesDone).fill(1);
        }
        return (
          <div className="dots-container">
            {data.row.original?.routines.map(({ completedAt }: any) => {
              const week = getWeek(new Date(completedAt)) - weekInfo.weekNumber;
              if (week === int) return <div className="dot" />;
              return null;
            })}
            {missingEntries.map(() => <div className="dot" style={{ backgroundColor: '#C00000' }} />)}
          </div>
        );
      },
    } as any);
  }

  const handlePositionDropDownChange = useCallback(
    ({ key }: any) => {
      const selectedPosition = tabs.find(
        (item: any) => item.slug === key,
      ) || tabs[0];
      setSelectedTab(selectedPosition);
      setPostionDropdownOpen(false);
    },
    [tabs],
  );
  const handleDropDownChange = useCallback(
    ({ key }: any) => {
      const selectedItem = dropDownItems.find(
        item => item.slug === key,
      ) || dropDownItems[0];
      setWeekInfo(selectedItem.content);
      setSelectedDropDownItem(selectedItem);
      setDropDownOpen(false);
    },
    // eslint-disable-next-line
    [],
  );

  useEffect(() => {
    setSelectedTab({
      slug: '',
      label: '',
    });
  }, [locationsToAccess]);

  useEffect(() => {
    if (usersPositions.result) {
      const usersPositionsArr = [...usersPositions.result];
      if (showAll) {
        usersPositionsArr.push({ label: t(TRANSLATIONS.ALL), slug: 'all' });
      }
      setSelectedTab(usersPositionsArr[0]);
      setTabs(usersPositionsArr);
    }
  }, [usersPositions.result, showAll, t]);

  const renderWithError = (error: any) => {
    if (
      error === UsersPositionsError.SELECT_A_SUBZONE
      || accessLevel === FILTER_ACCESS_LEVELS.ZONE
      || accessLevel === FILTER_ACCESS_LEVELS.BUSINESS_UNIT
    ) {
      return (
        <div className="card p-0">
          <div
            className="heading p-2 pl-4 pr-4 d-flex flex-column justify-content-center"
            style={{ minHeight: 100 }}
          >
            <p
              style={{
                color: 'grey',
              }}
            >
              {`* ${t(TRANSLATIONS.SELECT_SUBZONE_OR_DISTRIBUTION_CENTER)}`}
            </p>
          </div>
        </div>
      );
    }
    if (error === UserRoutineError.POSITION_NOT_SELECTED) {
      return (
        <div className="card">
          <LoadingSpinner height={100} />
        </div>
      );
    }
    return (
      <div className="card">
        <LoadingSpinner height={100} />
      </div>
    );
  };

  return (
    <div className="row routine-card-group">
      <div className="col-4">
        <div className="sub-heading mb-2"> {t(TRANSLATIONS.BY_BU)}</div>
        <div className="card p-0">
          <RoutineLocationTopBottom
            allPillarIds={allPillarIds}
            selectedPillar={selectedPillar}
          />
        </div>
      </div>
      <div className="col-4">
        <div className="sub-heading mb-2">{t(TRANSLATIONS.BY_POSITION)}</div>
        <div className="card p-0">
          <RoutinePositionsTopBottom
            allPillarIds={allPillarIds}
            selectedPillar={selectedPillar}
          />
        </div>
      </div>
      <div className="col-4">
        <div className="sub-heading mb-2">{t(TRANSLATIONS.BY_ROUTINES)}</div>
        <div className="card p-0">
          <ProgressByRoutine
            allPillarIds={allPillarIds}
            selectedPillar={selectedPillar}
          />
        </div>
      </div>
      <div className="col-12">
        {usersPositions.loading || usersPositions.error ? (
          renderWithError(usersPositions.error?.message)
        ) : (
          <>
            <div className="card p-0">
              <div className="heading p-2 pl-4 pr-4 d-flex justify-content-between">
                <ChartDropdown
                  onChange={handlePositionDropDownChange}
                  selectedDropDownItem={
                    selectedTab.slug ? selectedTab : { slug: 'loading', label: 'loading' }
                  }
                  dropDownItems={tabs}
                  dropDownOpen={positionDropdownOpen}
                  setDropDownOpen={setPostionDropdownOpen}
                  labelClassName="area-dropdown"
                />
                <div className="d-flex">
                  <div className="vertical-line-separator-routine" />
                  <p className="total-progress-of-position">
                    {t(TRANSLATIONS.TOTAL_PROGRESS)} {selectedTab.label}:{' '}
                    {positionCompletion.toFixed(1)}%
                  </p>
                </div>
              </div>
              <div style={{ marginTop: 10, marginBottom: 30, marginLeft: 10, marginRight: 10 }}>
                {userRoutine.loading || userRoutine.error ? (
                  renderWithError(userRoutine.error?.message)
                ) : (
                  <ExpandableTable
                    columns={columns}
                    data={routines}
                    showExpandedRowSubHeader
                    isExpandable
                  />
                )}
              </div>
              <div className="p-2 pl-4 pr-4">
                <ChartDropdown
                  onChange={handleDropDownChange}
                  selectedDropDownItem={selectedDropDownItem}
                  dropDownItems={dropDownItems}
                  dropDownOpen={dropDownOpen}
                  setDropDownOpen={setDropDownOpen}
                  labelClassName="label-default"
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
