import React, { ChangeEvent, FC, useState } from 'react';
import './LabelledInputWithTick.scss';
import { COLOR_CODE } from 'types/enums';
import ImageLinks from 'utils/ImageLinks';

const LabelledInputWithTick: FC<Props> = (props: Props) => {
  const {
    label,
    onChange,
    value,
    iconPosition = 'right',
    inputProps,
    isValid,
    className,
    iconClassName,
    iconStyle,
    inputClassName,
    inputStyle,
    labelClassName,
    labelStyle,
    containerClassName,
    containerStyle,
    isTextArea,
    description,
  } = props;

  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);

  const handleInputFocus = () => setIsInputFocused(true);
  const handleInputBlur = () => setIsInputFocused(false);

  const textInputProps = {
    value,
    onChange,
    className: `${inputClassName}`,
    onFocus: handleInputFocus,
    onBlur: handleInputBlur,
    style: inputStyle,
    ...inputProps,
  };

  return (
    <div className={`input-with-label-and-icon ${className}`}>
      {label && <p className={`${labelClassName} label`} style={labelStyle}>{label}</p>}
      {!!description && <p style={{ color: '#878787' }}>{ description }</p>}
      {/* input + icon */}
      <div
        className={`input-with-label-and-icon ${containerClassName}`}
        style={{
          borderColor: isInputFocused ? COLOR_CODE.PRIMARY : (
            isValid ? COLOR_CODE.VALID_INPUT_BORDER : COLOR_CODE.INVALID_INPUT_BORDER
          ),
          borderWidth: 2,
          borderRadius: 4,
          borderStyle: 'solid',
          backgroundColor: isInputFocused || isValid ? COLOR_CODE.WHITE
            : COLOR_CODE.UNTOUCHED_INPUT_BG,
          ...containerStyle,
        }}
      >
        <img
          src={ImageLinks.checkIcon}
          className={`${iconPosition === 'right' && 'order-1'} ${iconClassName}`}
          alt="valid input"
          style={{ opacity: isValid ? 1 : 0, ...iconStyle }}
        />

        {isTextArea ? <textarea {...textInputProps} /> : <input {...textInputProps} />}
      </div>
    </div>
  );
};

type Props = {
  value: string | number;
  onChange: ((e: ChangeEvent<any>) => void);
  label: string;
  iconPosition?: 'left' | 'right';
  inputProps?: object;
  isValid: boolean;
  isTextArea?: boolean;
  // styling
  iconStyle?: any;
  iconClassName?: string;
  labelStyle?: any;
  labelClassName?: string;
  inputStyle?: any;
  inputClassName?: string;
  containerStyle?: any;
  containerClassName?: string;
  className?: string;
  description?: string;
};

export default LabelledInputWithTick;
