import React, { useState, useMemo } from 'react';
import LoadingSpinner from 'components/LoadingSpinner';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS, DOCTOR_STATUS } from 'types/enums';
import ChartDropdown from 'components/Dropdown/ChartDropdown/ChartDropdown';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/selectors/user.selectors';
import req from 'utils/request';
import { canUserBlockUnblock } from 'utils/shared/canUserAccessFeature';

const UserStatusDropdown = (props: any) => {
  const loggedInuser = useSelector(selectUser);
  const { user, users, setUsers } = props;
  const { t } = useTranslation();

  const setUserReqStatus = (slug: string, status: boolean) => {
    const i = users.findIndex((u: any) => u.slug === slug);
    const a = JSON.parse(JSON.stringify(users));
    a[i].sendingRequest = status;
    setUsers(a);
  };

  const updateUserBlockStatus = async (slug: string, isBlocked: boolean) => {
    const i = users.findIndex((u: any) => u.slug === slug);
    const a = JSON.parse(JSON.stringify(users));
    a[i].isBlocked = isBlocked;
    a[i].sendingRequest = false;
    setUsers(a);
  };

  const updateUserDoctorStatus = async (slug: string, doctorStatus: DOCTOR_STATUS) => {
    const i = users.findIndex((u: any) => u.slug === slug);
    const a = JSON.parse(JSON.stringify(users));
    a[i].doctorStatus = doctorStatus;
    a[i].sendingRequest = false;
    setUsers(a);
  };

  const blockUser = async (slug: string) => {
    setUserReqStatus(slug, true);
    const url = `/api/v1/users/${slug}/block`;
    try {
      await req.patch(url);
      updateUserBlockStatus(slug, true);
    } catch (e) {
      setUserReqStatus(slug, false);
      throw e;
    }
  };

  const unblockUser = async (slug: string) => {
    setUserReqStatus(slug, true);
    const url = `/api/v1/users/${slug}/unblock`;
    try {
      await req.patch(url);
      updateUserBlockStatus(slug, false);
    } catch (e) {
      setUserReqStatus(slug, false);
      throw e;
    }
  };

  const sendToDoctorProfile = async (slug: string) => {
    setUserReqStatus(slug, true);
    const url = `/api/v1/users/${slug}/send-to-doctor`;
    try {
      await req.post(url, {});
      updateUserDoctorStatus(slug, DOCTOR_STATUS.IN_TEST);
    } catch (e) {
      setUserReqStatus(slug, false);
      throw e;
    }
  };

  const dropDownItems: { slug: string; label: string }[] = useMemo(
    () => [
      {
        slug: '0',
        label: t(TRANSLATIONS.UNBLOCKED),
      },
      {
        slug: '1',
        label: t(TRANSLATIONS.BLOCKED),
      },
      {
        slug: '2',
        label: t(TRANSLATIONS.MEDIACAL_EXAMINATION),
      },
    ],
    [t],
  );

  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [selectedDropDownItem, setSelectedDropDownItem] = useState(
    user?.isBlocked
      ? { slug: 'default', label: t(TRANSLATIONS.BLOCKED) }
      : { slug: 'default', label: t(TRANSLATIONS.UNBLOCKED) },
  );
  if (user.sendingRequest) {
    return <LoadingSpinner height={35} text="" />;
  }
  if (user.doctorStatus === DOCTOR_STATUS.IN_TEST) {
    return <p className="greyColored">En pruebas...</p>;
  }
  if (user.doctorStatus === DOCTOR_STATUS.QUARANTINE) {
    return <p className="greyColored">En cuarentena</p>;
  }

  if (canUserBlockUnblock(loggedInuser?.get('features')?.toJS())) {
    return (
      <ChartDropdown
        onChange={({ key }: any) => {
          console.log({ key });
          if (key === '1') {
            blockUser(user.slug);
          }
          if (key === '0') {
            unblockUser(user.slug);
          }
          if (key === '2') {
            sendToDoctorProfile(user.slug);
          }
          setSelectedDropDownItem(dropDownItems[key]);
          setDropDownOpen(false);
        }}
        selectedDropDownItem={selectedDropDownItem}
        dropDownItems={
                  user?.isBlocked
                    ? [dropDownItems[0], dropDownItems[2]]
                    : [dropDownItems[1]]
                }
        dropDownOpen={dropDownOpen}
        setDropDownOpen={setDropDownOpen}
        labelClassName="label-default user-action-dropdown"
      />
    );
  }
  return <p className="greyColored">---</p>;
};

export default UserStatusDropdown;
