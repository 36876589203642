import { gql, useQuery } from '@apollo/client';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/selectors/user.selectors';

const getUserUsualWorkLocationList = gql`
query getUserUsualWorkLocationList($userId: Int) {
    getUserUsualWorkLocation(userId: $userId) {
      country
      businessUnit
      location
    }
  }
`;

type Props = {
  image: string;
  value: string;
  title: string;
  setSelected: Function;
  setUsualWorkLocation?: any;
  selectedLocationType: any;
};

const IconCheckBox: FC<Props> = ({
  image,
  value,
  setSelected,
  selectedLocationType,
  setUsualWorkLocation,
  title,
}: Props) => {
  const userData = useSelector(selectUser);
  const userId = userData?.get('id');

  const { data } = useQuery(getUserUsualWorkLocationList, {
    variables: { userId },
  });
  const usualWorkLocationMatchesLocation = data?.getUserUsualWorkLocation?.location === userData?.getIn(['distributionCenter', 'slug']);
  const userFromFactory = userData?.getIn(['distributionCenter', 'type']);

  const handleOnPress = (e: string) => {
    const isFactory = e === 'FACTORY' ? 'FACTORY' : 'DISTRIBUTION_CENTER';
    setSelected(isFactory);
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    (isFactory === 'FACTORY' && userFromFactory && usualWorkLocationMatchesLocation) && setUsualWorkLocation(data?.getUserUsualWorkLocation);
  };

  const isSelected = value === selectedLocationType;

  return (
    <div onClick={() => handleOnPress(value)} className="container" style={isSelected ? { backgroundColor: '#F2F2F2' } : {}} role="button">
      <img src={image} className="image" alt="locationType" />
      <p className="text">{title}</p>
    </div>
  );
};

export default IconCheckBox;
