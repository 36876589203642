import React, { ChangeEvent, useState } from 'react';
import './IncidentsClosePopup.scss';
import Modal from '@mui/material/Modal';
import { useTranslation } from 'react-i18next';
import { TICKET_TYPE, TRANSLATIONS } from 'types/enums';
import FileUpload from 'containers/FileUpload';
import BasicButton from 'components/Button/BasicButton';
import ImageLinks from 'utils/ImageLinks';
import request from 'utils/request';
import Alert from 'components/alert/Alert';
import delay from 'utils/delay';
import LoadingSpinner from 'components/LoadingSpinner';

type Props = {
  handlePopupClose:()=>void;
  isImageRequired?:boolean;
  incidentId?:number;
  requestImageUploadPermission: any,
  uploadFile?: any,
  incidentTypeOfIssue?: TICKET_TYPE;
  fromIncidentsList?: boolean,
  refresh?: () => void,
  handleOpenIncidentPostponePopup?: () => void,
  incidentIsGoldenRule?: boolean,
};

const IncidentsClosePopup = ({
  incidentId,
  incidentTypeOfIssue,
  handlePopupClose,
  isImageRequired,
  requestImageUploadPermission,
  uploadFile,
  fromIncidentsList,
  refresh,
  handleOpenIncidentPostponePopup,
  incidentIsGoldenRule,
}: Props) => {
  const { t } = useTranslation();
  const [file, setFile] = useState<any>();
  const [actionText, setActionText] = useState('');
  const [consequencesAnswer, setConsequencesAnswer] = useState<boolean>();
  const [imageUploadData, setImageUploadData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const IMAGE_UPLOAD_PERMISSION_ERROR = new Error();
  IMAGE_UPLOAD_PERMISSION_ERROR.name = 'IMAGE_UPLOAD_PERMISSION_ERROR';
  IMAGE_UPLOAD_PERMISSION_ERROR.message = 'was not able to get permission to upload image to S3';

  const isFormValid = () => {
    let valid = false;

    const isActionTextValid = actionText?.trim()?.length > 0;

    const isFileValid = !!incidentIsGoldenRule || (!isImageRequired || !!file);

    const isConsequencesAnswerValid = !incidentIsGoldenRule || consequencesAnswer !== undefined;

    valid = isActionTextValid && isFileValid && isConsequencesAnswerValid;
    return valid;
  };

  const uploadImageFile = async (): Promise<any> => {
    try {
      // const { imageUploadData, file } = this.state;

      // user hasn't picked a file
      if (!file || !file.size) return true;

      // in case there's an error in getting permission to upload the image to S3
      // in requestImageUploadPermission() below
      if (imageUploadData === IMAGE_UPLOAD_PERMISSION_ERROR.name) {
        throw IMAGE_UPLOAD_PERMISSION_ERROR;
      }

      // haven't received permission yet to upload image in requestImageUploadPermission() below
      if (!imageUploadData) {
        // check again in a sec if the request ImageUploadPermission() returned
        await delay(1000);
        return await uploadImageFile();
      }

      const url = imageUploadData?.preSignedUrl;

      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/octet-stream',
        },
        body: file,
      });

      if (response && (response.status >= 200 && response.status <= 299)) {
        return console.log('uploadImage() uploaded image to S3 successfully');
      }

      throw Error(`error uploading image. Status ${response.status}`);
    } catch (e) {
      console.log('e uploadImage(): ', (e as any).message || e);
      throw e;
    }
  };

  const handleFile = (e: ChangeEvent<HTMLInputElement>) => {
    const fileData = e.target?.files?.[0];
    if (fileData) {
      setFile(fileData);
      const { size } = fileData;
      const extension = fileData?.name
        && `${fileData?.name.match(/\.\w+$/)?.[0].substring(1)}`;
      setImageUploadData(null);
      if (size < 10000000) {
        requestImageUploadPermission(extension
          || file.type.split('/')[1], size)
          .then((res:any) => setImageUploadData(res.imageUploadData))
          .catch((err:any) => setImageUploadData(err.imageUploadData));
      }
    }
  };

  const closeIncident = async () => {
    setIsLoading(true);
    let buttonsAlertsToShow:any = [];
    let descriptionAlertToShow = '';
    if (fromIncidentsList) {
      buttonsAlertsToShow = [
        {
          title: t(TRANSLATIONS.CLOSE_MESSAGE),
          className: 'icp-close-now',
          onPress: () => {
            if (refresh) {
              handlePopupClose();
              refresh();
            }
          },
        },
      ];
      descriptionAlertToShow = incidentTypeOfIssue === TICKET_TYPE.CONDITION
        ? t(TRANSLATIONS.THE_CONDITION_WAS_SUCCESSFULLY_CLOSED)
        : t(TRANSLATIONS.THE_ACT_WAS_SUCCESSFULLY_CLOSED);
    } else {
      buttonsAlertsToShow = [
        {
          title: t(TRANSLATIONS.GO_TO_SAFETY),
          className: 'icp-close-later',
          onPress: () => {
            window.location.assign('/');
          },
        },
        {
          title: t(TRANSLATIONS.START_ANOTHER_REPORT),
          className: 'icp-close-now',
          onPress: () => {
            window.location.reload();
          },
        },
      ];
      descriptionAlertToShow = incidentTypeOfIssue === TICKET_TYPE.CONDITION
        ? t(TRANSLATIONS.THE_CONDITION_WAS_SUCCESSFULLY_RECORDED_AND_CLOSED)
        : t(TRANSLATIONS.THE_ACT_WAS_SUCCESSFULLY_RECORDED_AND_CLOSED);
    }
    if (uploadFile) await uploadFile();
    if (!uploadFile) await uploadImageFile();
    try {
      const url = `/api/v1.1/incidents/${incidentId}/close`;
      await request.post(url, {
        imageUrl: imageUploadData?.fileName,
        evidenceClosureText: actionText,
        consequencesFlow: consequencesAnswer,
      });
      setIsLoading(false);
      handlePopupClose();
      Alert.alert({
        image: { src: ImageLinks.greenFilledCheck },
        title: incidentTypeOfIssue === TICKET_TYPE.CONDITION
          ? t(TRANSLATIONS.CONDITION_CLOSED_SUCCESSFULLY)
          : t(TRANSLATIONS.ACT_CLOSED_SUCCESSFULLY),
        description: descriptionAlertToShow,
        buttons: buttonsAlertsToShow,
      });
    } catch (e) {
      console.log('e closeIncident():', (e as any).message || e);
      throw e;
    }
  };

  const handleCloseLater = () => {
    handlePopupClose();
    Alert.alert({
      image: { src: ImageLinks.checked },
      title: t(incidentTypeOfIssue === TICKET_TYPE.CONDITION
        ? TRANSLATIONS.CONDITION_REGISTERED_SUCCESSFULLY
        : TRANSLATIONS.ACT_REGISTERED_SUCCESSFULLY),
      description: t(incidentTypeOfIssue === TICKET_TYPE.CONDITION
        ? TRANSLATIONS
          .THE_CONDITION_WAS_RECORDED_AND_WILL_BE_SENT_TO_THE_APPROPRIATE_DEPARTMENT
        : TRANSLATIONS.THE_ACT_WAS_RECORDED_AND_WILL_BE_SENT_TO_THE_APPROPRIATE_DEPARTMENT),
      buttons: [
        {
          title: t(TRANSLATIONS.GO_TO_SAFETY),
          className: 'icp-close-later',
          onPress: () => {
            window.location.assign('/');
          },
        },
        {
          title: t(TRANSLATIONS.START_ANOTHER_REPORT),
          className: 'icp-close-now',
          onPress: () => {
            window.location.reload();
          },
        },
      ],
    });
  };

  const handleCloseNow = () => {
    closeIncident();
  };

  const handleConsequencesFlow = (answer: boolean) => {
    setConsequencesAnswer(answer);
  };

  return (
    <Modal
      open={Boolean(incidentId)}
    >
      <div className="icp-main-container">
        <div className="downloadMoal">
          <p className={`downloadMoal__title ${fromIncidentsList ? 'pb-5 pt-2' : ''}`}>
            {t(TRANSLATIONS.CLOSE)}{' '}
            {incidentTypeOfIssue === TICKET_TYPE.CONDITION
              ? t(TRANSLATIONS.CONDITION) : t(TRANSLATIONS.ACT)}
          </p>
          <>
            { !fromIncidentsList && (
              <p className="downloadMoal__description">
                {
                  incidentTypeOfIssue === TICKET_TYPE.CONDITION
                    ? t(TRANSLATIONS.DO_YOU_WANT_TO_CLOSE_THE_CONDITION_AT_THIS_TIME)
                    : t(TRANSLATIONS.DO_YOU_WANT_TO_CLOSE_THE_ACT_AT_THIS_TIME)
                }
              </p>
            )}
            <input
              className="icp-input"
              placeholder={t(incidentIsGoldenRule
                ? TRANSLATIONS.WRITE_HERE_THE_DESCRIPTION_OF_THE_CONSEQUENCE_APPLIED
                : incidentTypeOfIssue === TICKET_TYPE.CONDITION
                  ? TRANSLATIONS.WRITE_HERE_THE_ACTION_TAKEN_TO_CLOSE_THE_CONDITION
                  : TRANSLATIONS.WRITE_HERE_THE_ACTION_TAKEN_TO_CLOSE_THE_ACT)}
              onChange={e => setActionText(e.target.value)}
              value={actionText}
            />
            {
              incidentIsGoldenRule ? (
                <>
                  <div className="isgr-text-container">
                    <p className="isgr-title-text">
                      {t(TRANSLATIONS.YOU_ARE_ABOUT_TO_CLOSE_AN_INSECURE_ACT_THAT_IS_A_GOLDEN_RULE)}
                    </p>
                    <p>{t(TRANSLATIONS.HAS_THE_CORRESPONDING_CONSEQUENCE_FLOW_BEEN_APPLIED)}</p>
                  </div>
                  <div className="isgr-buttons-container">
                    <BasicButton
                      text={t(TRANSLATIONS.YES)}
                      onClick={() => handleConsequencesFlow(true)}
                      className={`isgr-buttons ${consequencesAnswer === true ? 'isgr-button-selected' : 'isgr-yes-button'}`}
                    />
                    <BasicButton
                      text={t(TRANSLATIONS.NO)}
                      onClick={() => handleConsequencesFlow(false)}
                      className={`isgr-buttons ${consequencesAnswer === false ? 'isgr-button-selected' : 'isgr-no-button'}`}
                    />
                  </div>
                </>
              ) : (
                <div className="icp-upload-container">
                  <FileUpload
                    uploadKey="icp"
                    handleFileChange={handleFile}
                    label={t(TRANSLATIONS.UPLOAD_EVIDENCE_TO_CLOSE_ACI)}
                    file={file}
                    containerClassName="file"
                  />
                  <div className="upload-file">
                    <p className="text-light">{`${t(TRANSLATIONS.MAXIMUM_ALLOWED_FILE_SIZE, {
                      size: '10 MB',
                    })}`}
                    </p>
                  </div>
                </div>
              )
            }

            { !fromIncidentsList && (
              <div className="icp-buttons-container">
                <BasicButton
                  text={
                    incidentTypeOfIssue === 'CONDITION'
                      ? t(TRANSLATIONS.POSTPONE_CONDITION)
                      : t(TRANSLATIONS.POSTPONE_ACT)
                  }
                  onClick={handleOpenIncidentPostponePopup}
                  className="icp-postpone-button"
                />
                <BasicButton
                  text={t(TRANSLATIONS.CLOSE_LATER)}
                  onClick={handleCloseLater}
                  className="icp-close-later"
                />
                <BasicButton
                  text={t(TRANSLATIONS.CLOSE_RIGHT_NOW)}
                  onClick={handleCloseNow}
                  className="icp-close-now"
                  disabled={!isFormValid()}
                />
              </div>
            )}

            { fromIncidentsList && (
              <div className="icp-buttons-container">
                <BasicButton
                  text={t(TRANSLATIONS.CANCEL)}
                  onClick={handlePopupClose}
                  className="icp-cancel-button"
                />
                { isLoading ? (
                  <LoadingSpinner
                    className="loading-spinner-close-sio"
                  />
                ) : (
                  <BasicButton
                    text={t(TRANSLATIONS.CLOSE)}
                    onClick={handleCloseNow}
                    className="icp-close-now"
                    disabled={!isFormValid()}
                  />
                )}
              </div>
            )}
          </>
        </div>
      </div>
    </Modal>
  );
};

export default IncidentsClosePopup;
