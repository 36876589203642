/* eslint-disable max-len */
import React from 'react';

import './PrivacyPolicy.scss';
import 'features/privacy-policy/PrivacyPolicy.scss';
import i18next from 'i18next';

export default class PrivacyPolicy extends React.Component {
  render() {
    const isLanguageEs = i18next.language === 'es';
    return (
      !isLanguageEs ? (
        <div className="container privacy-policy">
          <h2>Penrose Technologies Privacy Policy</h2>

          <p>This Privacy Policy describes how your personal information is collected, used, and
            shared when you visit or make a purchase from https://safety.arvolution.com (the
            “Site”).
          </p>

          <p>PERSONAL INFORMATION WE COLLECT</p>

          <p>When you visit the Site, we automatically collect certain information about your device,
            including information about your web browser, IP address, time zone, and some of the
            cookies that are installed on your device. Additionally, as you browse the Site, we
            collect information about the individual web pages or products that you view, what
            websites or search terms referred you to the Site, and information about how you interact
            with the Site. We refer to this automatically-collected information as “Device
            Information.”
          </p>

          <p>We collect Device Information using the following technologies:</p>

          <p>- “Cookies” are data files that are placed on your device or computer and often include
            an anonymous unique identifier. For more information about cookies, and how to disable
            cookies, visit http://www.allaboutcookies.org.
          </p>
          <p>- “Log files” track actions occurring on the Site, and collect data including your IP
            address, browser type, Internet service provider, referring/exit pages, and date/time
            stamps.
          </p>

          <p>When we talk about “Personal Information” in this Privacy Policy, we are talking both
            about Device Information and Order Information.
          </p>

          <p>HOW DO WE USE YOUR PERSONAL INFORMATION?</p>

          <p>We use the Information that we collect generally to understand the safety issues that are
            reported.
          </p>

          <p>When in line with the preferences you have shared with us, provide you with information
            or advertising relating to our products or services.
          </p>

          <p>We use the Device Information that we collect to help us screen for potential risk and
            fraud (in particular, your IP address), and more generally to improve and optimize our
            Site (for example, by generating analytics about how our customers browse and interact
            with the Site, and to assess the success of our marketing and advertising campaigns).
          </p>

          <p>SHARING YOUR PERSONAL INFORMATION</p>

          <p>We share your Personal Information with third parties to help us use your Personal
            Information, as described above. For example, we use Google Analytics to help us
            understand how our customers use the Site--you can read more about how Google uses your
            Personal Information here: https://www.google.com/intl/en/policies/privacy/. You can also
            opt-out of Google Analytics here: https://tools.google.com/dlpage/gaoptout.
          </p>

          <p>Finally, we may also share your Personal Information to comply with applicable laws and
            regulations, to respond to a subpoena, search warrant or other lawful request for
            information we receive, or to otherwise protect our rights.
          </p>

          <p>BEHAVIOURAL ADVERTISING</p>
          <p>As described above, we use your Personal Information to provide you with targeted
            advertisements or marketing communications we believe may be of interest to you. For more
            information about how targeted advertising works, you can visit the Network Advertising
            Initiative’s (“NAI”) educational page at
            http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.
          </p>

          <p>You can opt out of targeted advertising by:</p>
          <p>- https://www.google.com/settings/ads/anonymous</p>

          <p>Additionally, you can opt out of some of these services by visiting the Digital
            Advertising Alliance’s opt-out portal at: http://optout.aboutads.info/.
          </p>

          <p>DO NOT TRACK</p>
          <p>Please note that we do not alter our Site’s data collection and use practices when we see
            a Do Not Track signal from your browser.
          </p>

          <p>YOUR RIGHTS</p>
          <p>If you are a European resident, you have the right to access personal information we hold
            about you and to ask that your personal information be corrected, updated, or deleted. If
            you would like to exercise this right, please contact us through the contact information
            below.
          </p>

          <p>Additionally, if you are a European resident we note that we are processing your
            information in order to fulfill contracts we might have with you (for example if you make
            an order through the Site), or otherwise to pursue our legitimate business interests
            listed above. Additionally, please note that your information will be transferred outside
            of Europe, including to Canada and the United States.
          </p>

          <p>DATA RETENTION</p>
          <p>When you place an order through the Site, we will maintain your Order Information for our
            records unless and until you ask us to delete this information.
          </p>

          <p>MINORS</p>
          <p>The Site is not intended for individuals under the age of 16.</p>

          <p>CHANGES</p>
          <p>We may update this privacy policy from time to time in order to reflect, for example,
            changes to our practices or for other operational, legal or regulatory reasons.
          </p>

          <p>CONTACT US</p>
          <p>For more information about our privacy practices,
            if you have questions, or if you would
            like to make a complaint, please contact us by e-mail at info@arvolution.com or by mail
            using the details provided below:
          </p>

          <p>Retorno 13 #15, Colonia Avante, CDMX, DIF, 04460, Mexico</p>

          <p>
            Comprehensive privacy notice for the application “SAFETY 360” ECUADOR
          </p>

          <p>
            1. Objective:
          </p>
          <p>
            This Personal Data Privacy Notice (hereinafter, the &quot;Privacy Notice&quot;), is intended to inform how Anheuser-Busch InBev SA/NV (hereinafter, &quot;ABI&quot;), represented in this document by the local subsidiary in Ecuador, its affiliates, subsidiaries, parent company and related parties, process the personal data it collects through navigation and virtual forms of the &quot;Safety 360&quot; mobile application.
          </p>

          <p>
            For these purposes, we will refer to the &quot;Owner&quot;, as the person whose data will be collected and processed in accordance with the terms established in this Comprehensive Privacy Notice. Only personal data for which due consent has been obtained are processed, unless the exceptions established in the Organic Law on Personal Data Protection (the “Law”) are applicable.
          </p>

          <p>
            The processing of personal data derived from this Privacy Notice is legitimate and lawful by virtue of obtaining the Owner&apos;s consent and its origin comes directly from the Owner.
          </p>

          <p>
            2. Legal Basis:
          </p>
          <p>
            This Privacy Notice is regulated by Ecuadorian legislation and, in particular, by the Organic Law on Data Protection published in the Official Gazette Supplement 459 of May 26, 2021, its Regulations and other applicable secondary regulations.
          </p>

          <p>
            3. Conservation period:
          </p>
          <p>
            The personal data provided by the Owner will be kept as long as they are necessary to fulfill the purpose for which they were collected and/or as long as the Owner does not revoke their consent, in accordance with the provisions of the applicable Law and regulations.
          </p>

          <p>
            4. Personal databases:
          </p>
          <p>
            The personal data provided will be stored in the personal database called &quot;Safety 360&quot;, duly registered in the National Registry for the Protection of Personal Data.
          </p>

          <p>
            5. Purpose of the treatment:
          </p>
          <p>
            &quot;Safety 360&quot; is a digital platform managed by ABI, in which users, who are staff in general, can register to enter its facilities.
          </p>

          <p>
            To access and interact with the application, you will be asked to freely and voluntarily provide the following personal data, which will be used solely and exclusively for the following purposes:
          </p>

          <table>
            <tr>
              <th>DATA</th>
              <th>PURPOSE</th>
            </tr>
            <tr>
              <td>Full Name</td>
              <td>For user identification in the general personnel database of ABI, as the service is intended for all visitors to the facilities.</td>
            </tr>
            <tr>
              <td>Age and Date of Birth</td>
              <td>For user identification in the general personnel database of ABI, as the service is intended for all visitors to the facilities.</td>
            </tr>
            <tr>
              <td>Gender</td>
              <td>For user identification in the general personnel database of ABI, as the service is intended for all visitors to the facilities.</td>
            </tr>
            <tr>
              <td>Country of Origin</td>
              <td>For user identification in the general personnel database of ABI, as the service is intended for all visitors to the facilities.</td>
            </tr>
            <tr>
              <td>Email Address</td>
              <td>For the purpose of receiving notifications related to this Privacy Notice.</td>
            </tr>
            <tr>
              <td>Cell Phone Number</td>
              <td>For user identification in the general personnel database of ABI, as the service is intended for all visitors to the facilities.</td>
            </tr>
            <tr>
              <td>Visitor Type</td>
              <td>For user identification in the case of being an employee, supplier, or visitor.</td>
            </tr>
            <tr>
              <td>Identification Number</td>
              <td>For user identification in the general personnel database of ABI, as the service is exclusive to clients.</td>
            </tr>
            <tr>
              <td>Company</td>
              <td>For identification of the visitor in case they are not an employee of ABI.</td>
            </tr>
            <tr>
              <td>Location</td>
              <td>For identification of the company&apos;s address.</td>
            </tr>
          </table>

          <p>
            The general purposes of the processing of your personal data and the purposes for which ABI in this act collects your data are the following:
          </p>
          <p>
            i.Give you access and/or equipment necessary to enter our facilities;
          </p>
          <p>
            ii. Maintain internal record of visitors to our facilities;
          </p>
          <p>
            iii.Create a profile or account on the Safety 360 mobile application;
          </p>
          <p>
            iv.Receive, track and file inquiries, safety reports, complaints and/or suggestions made by you through the Safety 360 mobile application;
          </p>
          <p>
            v. Respond and respond to questions, requests, complaints and suggestions made by you;
          </p>
          <p>
            vi. Comply with the obligations contracted with you;
          </p>
          <p>
            vii. Communicate and inform you about any change in the relationship contracted with you;
          </p>
          <p>
            viii. Contact you in the case of follow-up on queries, safety reports, complaints and/or suggestions made by you through the Safety 360 mobile application on safety and similar matters;
          </p>
          <p>
            ix. Maintain contact with you in case of any eventual emergency and/or unforeseen events;
          </p>
          <p>
            x. Contact you and indicate the facilities closest to you in case of any eventual emergency and/or unforeseen events;
          </p>
          <p>
            xi. Follow up on any contingency, emergency case or event related to the safety of our facilities and personnel;
          </p>
          <p>
            xii. Follow up on your present or future health status for security reasons for staff and visitors to ABI facilities;
          </p>
          <p>
            xiii.Prevent the spread of dangerous diseases inside ABI facilities.
          </p>

          <p>
            Likewise, ABI will collect personal data through the video recording system that operates in its facilities. The images and/or sounds captured by the video surveillance cameras will be used for your safety, the safety of our staff and that of the people who visit us.
          </p>

          <p>
            Additionally, ABI will use your personal information for the following secondary or accessory purposes that are not necessary to maintain a relationship with you, but that allow us to provide you with better service:
          </p>

          <p>
            i. Evaluate the quality of our professional services and carry out customer satisfaction surveys;
          </p>
          <p>
            ii.Send informative emails on health and good practices in the work area;
          </p>
          <p>
            iii.For statistical purposes that allow administrative and/or operational optimization;
          </p>

          <p>
            6. Security measures:
          </p>
          <p>
            ABI adopts the necessary security measures to guarantee the protection of the Owner&apos;s information in order to avoid its alteration, loss, treatment and/or unauthorized access, taking into account the nature of the information and the risks to which they are exposed. . To protect the Owner&apos;s personal data, at ABI we strictly comply with the provisions of the Law and its Regulations.
          </p>

          <p>
            7. Communications and subsequent processing:
          </p>
          <p>
            ABI undertakes not to disclose or share the personal data provided by the Owner without the latter having given valid consent to do so, with the exception of the following cases:
          </p>

          <p>
            -Requests for information from administrative or judicial authorities by virtue of requests and orders in the exercise of their functions and the scope of their powers covered by the Law.
          </p>
          <p>
            -Request from administrative authorities whose purpose is the subsequent processing of data for historical, statistical or scientific purposes, as long as said data is duly dissociated or at least anonymized.
          </p>
          <p>
            -In general, requests for information under relevant legal provisions.
          </p>

          <p>
            The Holder may revoke his consent to this type of communication at any time, without the need for any justification.
          </p>

          <p>
            8. Exercise of rights:
          </p>
          <p>
            The Holder can exercise the rights of access, deletion, rectification and updating, opposition, cancellation, limitation of treatment and not being subject to a decision based solely on automated evaluations and other rights enshrined in the Law, by means of a written request that can be sent to: gabriela.paredes@ab-inbev.com or protecciondatosecuador@ab-inbev.com and including, at least, the following information:
          </p>

          <p>
            -The name and email address of the Owner or any other means to receive responses.
          </p>
          <p>
            -A document that proves the identity of the applicant and, if necessary, that of his representative with the respective authorization.
          </p>
          <p>
            -The clear and precise description of the personal data with respect to which the Holder seeks to exercise any of the rights and the specific request.
          </p>

          <p>
            Notwithstanding the foregoing, ABI may keep certain information of the Holder who requests the cancellation, in order to serve as evidence in the event of a possible claim against ABI.
          </p>

          <p>
            The duration of said conservation will not exceed the legal prescription period of said responsibilities. In the event that the information on the user&apos;s logical interactions with the Website cannot be eliminated due to technical limitations, these data will be anonymized so that they cannot be used to identify or make the Owner identifiable.
          </p>

          <p>
            If they consider that they have not been attended to in the exercise of their rights, the Holder may file a claim with the Personal Data Protection Authority through the channels that this institution has enabled.
          </p>
          <p>
            9. Portability of personal data:
          </p>
          <p>
            At the express request of the Holder, ABI may return the personal data collected from him, in a compatible, updated, structured, common, interoperable and machine-readable format, preserving its characteristics, or deliver them to another responsible party defined by the Holder.
          </p>
          <p>
            ABI will make this transfer or communication in an economical and financially efficient, expeditious and unencumbered manner.
          </p>
          <p>
            The portability of the Owner&apos;s data will not take place in the case of the information inferred, derived, created, generated or obtained from the analysis or treatments carried out by ABI.
          </p>
          <p>
            10. Revocation of consent:
          </p>
          <p>
            The Owner may revoke the authorization and consent granted by ABI for the processing of their personal data, by submitting a written request sent to the previously mentioned emails.
          </p>
          <p>
            11. Modifications to the Privacy Notice:
          </p>
          <p>
            ABI expressly reserves the right to modify, update or supplement this Privacy Notice at any time. Any modification, update or extension produced in this notice will be immediately communicated through the mobile application.
          </p>
          <p>
            12. Personal Data Protection Officer:
          </p>
          <p>
            ABI in Ecuador has designated a person responsible for compliance with the adopted personal data protection policies:
          </p>
          <p>
            Name: Gabriela Paredes Goottman
          </p>
          <p>
            Email: gabriela.paredes@ab-inbev.com
          </p>
          <p>
            Telephone number: (593) 987614298
          </p>
          <p>
            Address: kilometer 16 ½ via Daule, Avenida Rosavin and Calle Cobre
          </p>

          <p>
            13. Applicable law and conflict resolution:
          </p>
          <p>
            The terms of the Privacy Notice are governed and interpreted in accordance with the laws in force in the Republic of Ecuador, subject to the competence and jurisdiction of the Judges of the city of Guayaquil, Ecuador and the National Authority for the Protection of Personal Data.
          </p>

          <p>
            Date of last update: May 08, 2023.
          </p>
        </div>
      ) : (
        <div className="container privacy-policy">
          <h2>Política de privacidad de Penrose Technologies</h2>
          <p>Esta Política de privacidad describe cómo se recopila, utiliza y comparte su información personal cuando visita o realiza una compra en https://safety.arvolution.com (el &quot;Sitio&quot;).
          </p>

          <p>INFORMACIÓN PERSONAL QUE RECOPILAMOS</p>

          <p>Cuando visita el Sitio, recopilamos automáticamente cierta información sobre su dispositivo, incluida información sobre su navegador web, dirección IP, zona horaria y algunas de las cookies que se instalan en su dispositivo. Además, mientras navega por el Sitio, recopilamos información sobre las páginas web individuales o los productos que ve, los sitios web o los términos de búsqueda que lo remitieron al Sitio y la información sobre cómo interactúa con el Sitio. Nos referimos a esta información recopilada automáticamente como &quot;Información del dispositivo&quot;.
          </p>

          <p>Recopilamos Información del dispositivo utilizando las siguientes tecnologías:</p>

          <p>- Las &quot;cookies&quot; son archivos de datos que se colocan en su dispositivo o computadora y a menudo incluyen un identificador único anónimo. Para obtener más información sobre las cookies y cómo deshabilitarlas, visite http://www.allaboutcookies.org.
          </p>
          <p>- Los &quot;archivos de registro&quot; registran las acciones que ocurren en el Sitio y recopilan datos, incluida su dirección IP, tipo de navegador, proveedor de servicios de Internet, páginas de referencia o salida y marcas de fecha y hora.
          </p>

          <p>Cuando hablamos de &quot;Información personal&quot; en esta Política de privacidad, nos referimos tanto a la Información del dispositivo como a la Información del pedido.
          </p>

          <p>¿CÓMO UTILIZAMOS SU INFORMACIÓN PERSONAL?</p>

          <p>Utilizamos la información que recopilamos en general para comprender los problemas de seguridad que se informan.
          </p>

          <p>Cuando esté de acuerdo con las preferencias que ha compartido con nosotros, le proporcionaremos información o publicidad relacionada con nuestros productos o servicios.
          </p>

          <p>Utilizamos la Información del dispositivo que recopilamos para ayudarnos a detectar posibles riesgos y fraudes (en particular, su dirección IP) y, en general, para mejorar y optimizar nuestro Sitio (por ejemplo, generando análisis sobre cómo nuestros clientes navegan e interactúan con el Sitio, y para evaluar el éxito de nuestras campañas de marketing y publicidad).
          </p>

          <p>COMPARTIR SU INFORMACIÓN PERSONAL</p>

          <p>Compartimos su Información personal con terceros para ayudarnos a utilizar su Información personal, como se describe anteriormente. Por ejemplo, utilizamos Google Analytics para ayudarnos a comprender cómo nuestros clientes utilizan el Sitio; puede obtener más información sobre cómo Google utiliza su Información personal aquí: https://www.google.com/intl/es/policies/privacy/. También puede optar por no participar en Google Analytics aquí: https://tools.google.com/dlpage/gaoptout.
          </p>

          <p>Por último, también podemos compartir su Información personal para cumplir con las leyes y regulaciones aplicables, para responder a una citación, orden de registro u otra solicitud legal de información que recibamos, o para proteger nuestros derechos de otra manera.
          </p>

          <p>PUBLICIDAD COMPORTAMENTAL</p>
          <p>Como se describe anteriormente, utilizamos su Información personal para proporcionarle anuncios o comunicaciones de marketing dirigidos que creemos pueden ser de su interés. Para obtener más información sobre cómo funciona la publicidad dirigida, puede visitar la página educativa de la Iniciativa de Publicidad en Red (&quot;NAI&quot;) en http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.
          </p>

          <p>Puede optar por no participar en la publicidad dirigida a través de:</p>
          <p>- https://www.google.com/settings/ads/anonymous</p>

          <p>Además, puede optar por no participar en algunos de estos servicios visitando el portal de exclusión voluntaria de la Alianza de Publicidad Digital en: http://optout.aboutads.info/.
          </p>

          <p>NO SEGUIMIENTO</p>
          <p>Tenga en cuenta que no modificamos las prácticas de recopilación y uso de datos de nuestro Sitio cuando detectamos una señal de No realizar seguimiento desde su navegador.
          </p>

          <p>SUS DERECHOS</p>
          <p>Si usted es residente de Europa, tiene derecho a acceder a la información personal que tenemos sobre usted y a solicitar que corrijamos, actualicemos o eliminemos su información personal. Si desea ejercer este derecho, comuníquese con nosotros a través de la información de contacto que se proporciona a continuación.
          </p>

          <p>Además, si usted es residente de Europa, tenga en cuenta que estamos procesando su información para cumplir con los contratos que podríamos tener con usted (por ejemplo, si realiza un pedido a través del Sitio) o de lo contrario para perseguir nuestros intereses comerciales legítimos mencionados anteriormente. Además, tenga en cuenta que su información se transferirá fuera de Europa, incluso a Canadá y Estados Unidos.
          </p>

          <p>CONSERVACIÓN DE DATOS</p>
          <p>Cuando realiza un pedido a través del Sitio, conservaremos su Información del pedido para nuestros registros a menos que nos solicite que eliminemos esta información.
          </p>

          <p>MENORES</p>
          <p>El Sitio no está destinado a personas menores de 16 años.</p>

          <p>CAMBIOS</p>
          <p>Podemos actualizar esta política de privacidad periódicamente para reflejar, por ejemplo, cambios en nuestras prácticas o por otras razones operativas, legales o reglamentarias.
          </p>

          <p>CONTÁCTENOS</p>
          <p>Para obtener más información sobre nuestras prácticas de privacidad, si tiene preguntas o si desea presentar una queja, comuníquese con nosotros por correo electrónico a info@arvolution.com o por correo utilizando los datos proporcionados a continuación:
          </p>

          <p>Retorno 13 #15, Colonia Avante, CDMX, DIF, 04460, México</p>

          <p>
            Aviso integral de privacidad para la aplicación “SAFETY 360” ECUADOR
          </p>

          <p>
            1. Objetivo:
          </p>
          <p>
            El presente Aviso de privacidad de datos personales (en adelante, el “Aviso de Privacidad”), tiene por finalidad informar como Anheuser-Busch InBev SA/NV (en adelante, &quot;ABI&quot;), representada en este documento por la subsidiaria local en Ecuador, sus afiliadas, subsidiarias, empresa matriz y partes relacionadas, realiza el tratamiento de los datos personales que recopila a través de la navegación y formularios virtuales de la aplicación móvil “Safety 360”.
          </p>

          <p>
            Para estos efectos, haremos referencia al “Titular”, como aquella persona cuyos datos serán recopilados y tratados de acuerdo con los términos establecidos en este Aviso Integral de Privacidad. Únicamente son tratados los datos personales para los cuales se ha obtenido el debido consentimiento, salvo sean aplicables las excepciones establecidas en la Ley Orgánica de Protección de Datos Personales (la “Ley”).
          </p>

          <p>
            El tratamiento de datos personales derivada del presente Aviso de Privacidad es legítimo y lícito en virtud de la obtención del consentimiento del Titular y su origen proviene directamente de éste.
          </p>

          <p>
            2.  Base Legal:
          </p>
          <p>
            Este Aviso de Privacidad se encuentra regulada por la legislación ecuatoriana y, en particular, por Ley Orgánica de Protección de Datos publicada en el Registro Oficial Suplemento 459 del 26 de mayo de 2021, su Reglamento y demás normativa secundaria aplicable.
          </p>

          <p>
            3. Plazo de conservación:
          </p>
          <p>
            Los datos personales proporcionados por el Titular se conservarán en tanto sean necesarios para cumplir con la finalidad para la cual fueron recopilados y/o mientras el Titular no revoque su consentimiento, conforme a lo regulado por la Ley y normativa aplicable.
          </p>

          <p>
            4. Bases de datos personales:
          </p>
          <p>
            Los datos personales proporcionados se almacenarán en la base de datos personales denominado “Safety 360”, debidamente inscrita en el Registro Nacional de Protección de Datos Personales.
          </p>

          <p>
            5.  Finalidad del tratamiento:
          </p>
          <p>
            “Safety 360” es una plataforma digital administrada por ABI, en la que usuarios, que son personal en general, pueden registrarse para el ingreso a las instalaciones de esta.
          </p>

          <p>
            Para el acceso e interacción con la aplicación se solicitará que entregue de manera libre y voluntaria los siguientes datos personales, que serán utilizados única y exclusivamente para los siguientes fines:
          </p>

          <table>
            <tr>
              <th>DATOS</th>
              <th>FINES</th>
            </tr>
            <tr>
              <td>Nombre completo</td>
              <td>Para identificación del usuario en la base de datos de personal en general de ABI, dado que el servicio es dirigido para todos los visitantes de las instalaciones.</td>
            </tr>
            <tr>
              <td>Edad y fecha de nacimiento</td>
              <td>Para identificación del usuario en la base de datos de personal en general de ABI, dado que el servicio es dirigido para todos los visitantes de las instalaciones.</td>
            </tr>
            <tr>
              <td>Género</td>
              <td>Para identificación del usuario en la base de datos de personal en general de ABI, dado que el servicio es dirigido para todos los visitantes de las instalaciones.</td>
            </tr>
            <tr>
              <td>País de origen</td>
              <td>Para identificación del usuario en la base de datos de personal en general de ABI, dado que el servicio es dirigido para todos los visitantes de las instalaciones.</td>
            </tr>
            <tr>
              <td>Correo electrónico</td>
              <td>Para efecto de recibir notificaciones relacionadas al presente Aviso de Privacidad</td>
            </tr>
            <tr>
              <td>Número de celular</td>
              <td>Para identificación del usuario en la base de datos de personal en general de ABI, dado que el servicio es dirigido para todos los visitantes de las instalaciones.</td>
            </tr>
            <tr>
              <td>Tipo de visitante</td>
              <td>Para identificación del usuario en caso de que sea trabajador, proveedor o visitante.</td>
            </tr>
            <tr>
              <td>Número de cédula</td>
              <td>Para identificación del usuario en la base de datos de personal general de ABI, dado que el servicio es exclusivo para clientes.</td>
            </tr>
            <tr>
              <td>Compañía</td>
              <td>Para identificación del visitante en caso de que no sea trabajador de ABI.</td>
            </tr>
            <tr>
              <td>Ubicación</td>
              <td>Para identificación de la dirección de la compañía.</td>
            </tr>
          </table>

          <p>
            Las finalidades en general del tratamiento de sus datos personales y las finalidades para las que ABI en este acto recoge sus datos son las siguientes:
          </p>
          <p>
            i.Darle acceso y/o equipos necesarios para entrar a nuestras instalaciones;
          </p>
          <p>
            ii.Mantener constancia interna de los visitantes a nuestras instalaciones;
          </p>
          <p>
            iii.Crear un perfil o cuenta en la aplicación móvil Safety 360;
          </p>
          <p>
            iv.Recibir, dar seguimiento y archivar consultas, informes de seguridad, quejas y/o sugerencias realizadas por usted a través la aplicación móvil Safety 360;
          </p>
          <p>
            v.Responder y atender a dudas, solicitudes, quejas y sugerencias realizadas por usted;
          </p>
          <p>
            vi.Dar cumplimiento a las obligaciones contraídas con usted;
          </p>
          <p>
            vii.Comunicar e informarle sobre cualquier cambio en la relación contraída con usted;
          </p>
          <p>
            viii.Contactarlo para el caso de seguimiento a las consultas, informes de seguridad, quejas y/o sugerencias realizadas por usted a través la aplicación móvil Safety 360 en materia de seguridad y análogas;
          </p>
          <p>
            ix.Mantener contacto con usted en caso de cualquier eventual emergencia y/o imprevistos;
          </p>
          <p>
            x.Contactarle e indicarle las instalaciones más cercano a usted en caso de cualquier eventual emergencia y/o imprevistos;
          </p>
          <p>
            xi.Dar seguimiento a cualquier contingencia, caso de emergencia o eventualidad relacionada con la seguridad de nuestras instalaciones y personal;
          </p>
          <p>
            xii.Dar seguimiento a su estado de salud presente o futuro por cuestiones de seguridad del personal y visitantes a las instalaciones de ABI;
          </p>
          <p>
            xiii.Prevenir un contagio de enfermedades peligrosas al interior de las instalaciones de ABI.
          </p>

          <p>
            Asimismo, ABI recabará datos personales a través del sistema de videograbación que opera en sus instalaciones. Las imágenes y/o sonidos captados por las cámaras de videovigilancia serán utilizados para su seguridad, la seguridad de nuestro personal y la de las personas que nos visitan.
          </p>

          <p>
            Adicionalmente, ABI utilizará su información personal para las siguientes finalidades secundarias o accesorias que no son necesarias para mantener una relación con usted, pero que nos permiten brindarle una mejor atención:
          </p>

          <p>
            i.Evaluar la calidad de nuestros servicios profesionales y llevar a cabo encuestas de satisfacción de clientes;
          </p>
          <p>
            ii.Enviarle correos informativos en materia de salud y buenas prácticas en el área de trabajo;
          </p>
          <p>
            iii.Para fines estadísticos que permitan optimizar administrativos y/u operativos;
          </p>

          <p>
            6.  Medidas de seguridad:
          </p>
          <p>
            ABI adopta las medidas de seguridad necesarias para garantizar la protección de la información del Titular a fin de evitar su alteración, pérdida, tratamiento y/o acceso no autorizado, tomando en consideración la naturaleza de la información y los riesgos a los que se encuentran expuestos. Para proteger los datos personales del Titular, en ABI cumplimos estrictamente con lo establecido en la Ley y su Reglamento.
          </p>

          <p>
            7.  Comunicaciones y tratamientos ulteriores:
          </p>
          <p>
            ABI se compromete a no divulgar o compartir los datos personales proporcionados por el Titular sin que éste haya prestado el consentimiento válido para ello, con excepción de los siguientes casos:
          </p>

          <p>
            Solicitudes de información de autoridades administrativas o judiciales en virtud de solicitudes y órdenes en ejercicio de sus funciones y el ámbito de sus competencias amparadas en la Ley.
          </p>
          <p>
            Solicitud de autoridades administrativas que tengan por objeto el tratamiento posterior de datos con fines históricos, estadísticos o científicos, siempre y cuando dichos datos se encuentren debidamente disociados o a lo menos anonimizados.
          </p>
          <p>
            En general solicitudes de información en virtud de disposiciones legales relevantes.
          </p>

          <p>
            El Titular puede revocar su consentimiento de este tipo de comunicaciones en cualquier momento, sin necesidad de que medie justificación alguna.
          </p>

          <p>
            8. Ejercicio de derechos:
          </p>
          <p>
            El Titular puede ejercer los derechos de acceso, eliminación, rectificación y actualización, oposición, anulación, limitación del tratamiento y a no ser objeto de una decisión basada únicamente en valoraciones automatizadas y demás derechos consagrados en la Ley, mediante una solicitud escrita que puede ser remitida a: gabriela.paredes@ab-inbev.com o protecciondatosecuador@ab-inbev.com e incluyendo, como mínimo, la siguiente información:
          </p>

          <p>
            -El nombre y dirección de correo del Titular o cualquier otro medio para recibir respuestas.
          </p>
          <p>
            -Un documento que acredite la identidad del solicitante y en caso dado, la de su representante con la respectiva autorización.
          </p>
          <p>
            -La descripción clara y precisa de los datos personales respecto a los cuales el Titular busca ejercer alguno de los derechos y la solicitud concreta.
          </p>

          <p>
            Sin perjuicio de lo anterior, ABI podrá conservar determinada información del Titular que solicita la baja, a fin de que sirva de prueba ante una eventual reclamación contra ABI.
          </p>

          <p>
            La duración de dicha conservación no será superior al plazo de prescripción legal de dichas responsabilidades. En caso de que la información sobre interacciones lógicas del usuario con la Página Web no pueda ser eliminadas por limitaciones técnicas, estos datos serán anonimizados para que no puedan servir para identificar o hacer identificable al Titular.
          </p>

          <p>
            De considerar que no ha sido atendido en ejercicio de sus derechos, el Titular puede presentar una reclamación ante la Autoridad de Protección de Datos Personales por medio de los canales que esta institución tenga habilitados.
          </p>

          <p>
            9. Portabilidad de datos personales:
          </p>
          <p>
            A solicitud expresa del Titular, ABI podrá devolver los datos personales recabados de este, en un formato compatible, actualizado, estructurado, común, interoperable y de lectura mecánica, preservando sus características, o entregarlos a otro responsable definido por el Titular.
          </p>

          <p>
            ABI realizará esta transferencia o comunicación de manera económica y financieramente eficiente, expedita y sin trabas.
          </p>

          <p>
            No tendrá lugar la portabilidad de los datos del Titular para el caso de la información inferida, derivada, creada, generada u obtenida a partir del análisis o tratamientos efectuados por ABI.
          </p>

          <p>
            10. Revocatoria del consentimiento:
          </p>
          <p>
            El Titular podrá revocar la autorización y el consentimiento otorgados por ABI para el tratamiento de sus datos personales, mediante la presentación de una solicitud escrita remitida a los correos mencionados con anterioridad.
          </p>

          <p>
            11. Modificaciones del Aviso de Privacidad:
          </p>
          <p>
            ABI se reserva expresamente el derecho a modificar, actualizar o completar en cualquier momento este Aviso de Privacidad. Cualquier modificación, actualización o ampliación producida en el presente aviso será inmediatamente comunicada a través de la aplicación móvil.
          </p>

          <p>
            12. Oficial de protección de datos personales:
          </p>

          <p>
            ABI en Ecuador ha designado un responsable para el cumplimiento de las políticas de protección de datos personales adoptadas:
          </p>

          <p>
            Nombre: Gabriela Paredes Goottman
          </p>
          <p>
            Correo electrónico: gabriela.paredes@ab-inbev.com
          </p>
          <p>
            Número de teléfono: (593) 987614298
          </p>
          <p>
            Dirección: kilómetro 16 ½ vía Daule, Avenida Rosavin y calle Cobre
          </p>

          <p>
            13. Legislación aplicable y resolución de conflictos:
          </p>
          <p>
            Los términos del Aviso de Privacidad se rigen y se interpretan de acuerdo con las leyes vigentes en la Republica del Ecuador, sujetándose a la competencia y jurisdicción de los Jueces de la ciudad de Guayaquil, Ecuador y de la Autoridad Nacional de Protección de Datos Personales.
          </p>

          <p>
            Fecha de última actualización:  08 de mayo de 2023.
          </p>
        </div>
      )
    );
  }
}
