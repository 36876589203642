import {
  ACTION_LOG_PILLAR_IMAGE,
  pillarImages,
} from 'features/action-logs/createEditActionLog/pillarImagesHelper';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ACTION_LOG_SUMMARY_MODAL_STATUS, TRANSLATIONS } from 'types/enums';
import { withStyles } from '@mui/styles';
import { green } from '@mui/material/colors';
import Radio, { RadioProps } from '@mui/material/Radio';
import './PillarForSummaryModalStyle.scss';

type PillarForSummaryModalProps = {
  selectedPillar?: ACTION_LOG_PILLAR_IMAGE;
  changePillar: (pillar: ACTION_LOG_PILLAR_IMAGE) => void;
  modalStatus: ACTION_LOG_SUMMARY_MODAL_STATUS;
};

function PillarForSummaryModal(props: PillarForSummaryModalProps) {
  const { selectedPillar = 'SAFETY', changePillar, modalStatus } = props;
  const { t } = useTranslation();
  const GreenRadio = withStyles({
    root: {
      color: 'black',
      '&$checked': {
        color: green[600],
      },
    },
    checked: {},
  })((MUIRadioProps: RadioProps) => <Radio color="default" {...MUIRadioProps} />);

  if (modalStatus === ACTION_LOG_SUMMARY_MODAL_STATUS.SUMMARY) {
    return <div className={`pillar safety ${selectedPillar.toLocaleLowerCase()}`}>{selectedPillar}</div>;
  }

  return (
    <div>
      <p className="choose_pillar_title">{t(TRANSLATIONS.PILLAR)}</p>
      <div className="create_edit_pillar_image">
        {' '}
        {Object.keys(pillarImages).map(pillarImagesKey => (
          <div
            key={pillarImagesKey}
            role="presentation"
            onClick={() => changePillar(pillarImagesKey as ACTION_LOG_PILLAR_IMAGE)}
            className="single_pillar_container"
          >
            <img
              className="pillar_image"
              src={pillarImages[pillarImagesKey as ACTION_LOG_PILLAR_IMAGE]}
              alt="pillar"
            />
            <p className="pillar_text">{t(pillarImagesKey)}</p>
            <GreenRadio
              checked={selectedPillar === pillarImagesKey}
              value="c"
              name="radio-button-demo"
              inputProps={{ 'aria-label': 'C' }}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default PillarForSummaryModal;
