import React from 'react';
import './TextWithIcon.scss';

type Props = {
  icon: string;
  text: string;
  style?: React.CSSProperties;
  imageStyles?: React.CSSProperties;
};

const TextWithIcon = (props: Props) => {
  const {
    icon,
    text,
    imageStyles,
  } = props;

  return (
    <div className="icon-and-text">
      <img src={icon} alt="icon" className="icon-container" style={{ ...(imageStyles || { width: 20 }) }} />
      <div className="text-container">{text}</div>
    </div>
  );
};

export default TextWithIcon;
