import {
  CapexCommentsDocument,
} from 'generated/graphql';

export const capexCommentRefetchQueries = (capexId: string) => {
  return [
    {
      query: CapexCommentsDocument,
      variables: {
        capexId,
      },
    },
  ];
};
