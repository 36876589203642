import React from 'react';
import Chart from 'react-apexcharts';

const CapexDonut = (props: { used: number; }) => {
  const used = Math.ceil((props.used || 0));
  const series = [used, (100 - used)];
  const options = {
    labels: ['Ejercido', 'Pendiente'],
    colors: ['#5572B9', '#A33223'],
    responsive: [{
      options: {
        legend: {
          position: 'middle',
        },
      },
    }],
  };
  return (
    <>
      <Chart options={options} series={series} type="donut" width="380" />
      <div className="capex-donut-info">
        <div className="font-weight700 font-size18 capex-donut-center">MAZ </div>
        <div className="font-weight400 font-size18"> {used}% ejercido </div>
      </div>
    </>
  );
};

export default CapexDonut;
