import { FILTER_ACCESS_LEVELS } from 'types/enums';
import request from 'utils/request';
import { selectCurrentAccesLevel } from 'store/selectors/distributionCenters.selectors';
import { selectLocationsToAccess } from 'store/selectors/globalFilters.selectors';
import { useSelector } from 'react-redux';
import { useAsync } from 'react-async-hook';
import { fromJS } from 'immutable';
import { lastDayOfMonth, set, subDays } from 'date-fns';

const getDateRange = (selectedTimeRange: any) => {
  const currentDate = new Date();
  let filter = ['', ''] as any[];
  if (selectedTimeRange.slug === 'last-7-days') {
    filter = [subDays(currentDate, 14), currentDate];
  }
  if (selectedTimeRange.slug === 'last-30-days') {
    filter = [subDays(currentDate, 60), currentDate];
  }
  if (selectedTimeRange.slug === 'month-to-date') {
    const lastMonthDay = lastDayOfMonth(currentDate);
    const firstDayLastMonth = set(subDays(lastMonthDay, 50), { date: 1 });
    filter = [firstDayLastMonth, lastMonthDay];
  }
  return [filter[0] as Date, filter[1] as Date];
};

export enum FetchError {
  WAITING = 'WAITING',
}

export const getAnalyticsTraining = async (
  locationsToAccess: number[],
  accessLevel: FILTER_ACCESS_LEVELS,
  selectedTimeRange: any,
) => {
  if (!(accessLevel && locationsToAccess && locationsToAccess.length)) {
    throw new Error(FetchError.WAITING);
  }
  const [startDate, endDate] = getDateRange(selectedTimeRange);
  const params = {
    startDate,
    endDate,
    locationsToAccessString: locationsToAccess,
    accessLevel,
  } as any;
  const qs = Object.keys(params)
    .map((key:string) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&');
  const response = await request.get(`/api/v1/trainings-analytics?${qs}`);

  return fromJS(response.trainings);
};

const useGetAnalyticsTraining = ({
  selectedTimeRange,
}:{
  selectedTimeRange: any;
}) => {
  const locationsToAccess = useSelector(selectLocationsToAccess);
  const accessLevel = useSelector(selectCurrentAccesLevel);
  return useAsync(getAnalyticsTraining, [locationsToAccess, accessLevel, selectedTimeRange]);
};
export default useGetAnalyticsTraining;
