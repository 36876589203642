import request from '../../utils/request';

export const SET_AUTHENTICATED_USER = 'user/set-authenticated-user';
export const SET_USER_HEADER_INFO = 'user/user-header-info';
export const SET_USER_ACTIVITY_DETAIL = 'user/user-activity-detail';
export const SET_USER_BOXES_BY_PRESENTATION = 'user/user-boxes-by-presentation';
export const SET_USER_WEEKLY_PAYMENT = 'user/user-weekly-payment';

export function setAuthenticatedUser(user: any) {
  return { type: SET_AUTHENTICATED_USER, user };
}

export function setUserHeaderInfo(info: any) {
  return { type: SET_USER_HEADER_INFO, info };
}

export function setUserActivityDetail(detail: any) {
  return { type: SET_USER_ACTIVITY_DETAIL, detail };
}

export function setUserBoxesByPresentation(presentation: any) {
  return { type: SET_USER_BOXES_BY_PRESENTATION, presentation };
}

export function setUserWeeklyPayment(payment: any) {
  return { type: SET_USER_WEEKLY_PAYMENT, payment };
}

export function isUserAuthenticated(): any {
  return async (dispatch: any) => {
    const url = '/api/v1/users';

    try {
      const response = await request.get(url);

      // if (process.env.NODE_ENV === 'production') {
      //   // @ts-ignore
      //   $crisp.push(['set', 'session:data', [
      //     ['name', response.name], ['email', response.email], ['shortSlug', response.shortSlug],
      //   ]]);
      //   // @ts-ignore
      //   $crisp.push(['set', 'user:email', [response.email]]);
      //   // @ts-ignore
      //   $crisp.push(['set', 'user:nickname', [response.name]]);
      // }

      dispatch(setAuthenticatedUser(response));
    } catch (err) {
      console.error(err);
      dispatch(setAuthenticatedUser({}));
    }
  };
}

// export function getUserHeaderInfo(sorter: string,
//                                   period?: { startTime: Date; endTime: Date }): any {
//   return async (dispatch: any) => {
//     try {
//       const url = `${getCurrentDistCenter()}/sorters/${sorter}`;
//
//       const response = await request.get(url, period);
//       dispatch(setUserHeaderInfo(response));
//     } catch (e) {
//       console.log('e getUserHeaderInfo()', e.message || e);
//     }
//   };
// }
//
// export function getUserActivityDetail(sorter: string, currentFilterTime?: any): any {
//   return async (dispatch: any) => {
//     try {
//       const url = `${getCurrentDistCenter()}/sorters/${sorter}/pallets-to-sort`;
//
//       const timeFilter = currentFilterTime
//         ? {
//           startTime: currentFilterTime.get('startDate'),
//           endTime: currentFilterTime.get('endDate'),
//         }
//         : undefined;
//       const response = await request.get(url, timeFilter);
//       dispatch(setUserActivityDetail(response.pallets));
//     } catch (e) {
//       console.log('e getUserActivityDetail()', e.message || e);
//     }
//   };
// }
//
// export function getUserBoxesByPresentation(sorterSlug: string,
//                                            period?: { startTime: Date; endTime: Date }): any {
//   return async (dispatch: any) => {
//     try {
//       const url = `${getCurrentDistCenter()}/sorters/${sorterSlug}/hourly-boxes`;
//
//       const requestObject = {
//         startTime: (period && period.startTime) || null,
//         endTime: (period && period.endTime) || null,
//         timezoneOffset: TIME_ZONE_OFFSET,
//       };
//
//       const response = await request.get(url, requestObject);
//
//       dispatch(setUserBoxesByPresentation(response));
//     } catch (e) {
//       console.log('e getUserBoxesByPresentation()', e.message || e);
//     }
//   };
// }
//
// export function getUserWeeklyCompensation(sorterSlug: string,
//                                           period?: { startTime: Date; endTime: Date }): any {
//   return async (dispatch: any) => {
//     try {
//       const url = `${getCurrentDistCenter()}/sorters/${sorterSlug}/payment-weekly`;
//
//       const requestObject = {
//         startTime: (period && period.startTime) || null,
//         endTime: (period && period.endTime) || null,
//         timezoneOffset: TIME_ZONE_OFFSET,
//       };
//
//       const response = await request.get(url, requestObject);
//
//       dispatch(setUserWeeklyPayment(response));
//     } catch (e) {
//       console.log('e getUserBoxesByPresentation()', e.message || e);
//     }
//   };
// }
