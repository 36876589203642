import React, { Component } from 'react';
import { connect } from 'react-redux';

import MainNavigationHeader from 'components/MainNavigationHeader';
import ACIsIndex from 'features/internal-users/SafetyDashboard/ACIsIndex';
import withParams from 'utils/hoc/withParams';
import AgencyDataOverview from './AgencyDataOverview';

class SafetyDashboard extends Component<any, any> {
  render() {
    console.log(this.props);
    return (
      <div className="safety-dashboard">
        <MainNavigationHeader userName={this.props.userName} />
        <div className="container main-content-margin">
          {/* <DashboardFilters /> */}
          {/* <ContentDivisionHeader
            title="Datos de la Agencia"
            containerStyle={{ marginBottom: 10 }}
          /> */}
          <div className="row">
            <div className="col-12 col-lg-8">
              <AgencyDataOverview />
            </div>
            <div className="col-12 col-lg-4">
              {/* <ACIProgress /> */}
            </div>
          </div>
          {/* @ts-ignore */}
          <ACIsIndex distCenter={this.props.params.distCenter} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    userName: state.getIn(['user', 'name']),
  };
}

export default connect(mapStateToProps)(withParams(SafetyDashboard));
