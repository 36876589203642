import HeaderWithNavigation from 'components/HeaderWithNavigation';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import gauzeIcon from 'assets/icons/gauge-gray.svg';
import executedBeer from 'assets/icons/beer.png';
import gauzeGreenIcon from 'assets/icons/gauge-green.svg';
import { TRANSLATIONS } from 'types/enums';
import 'react-day-picker/lib/style.css';
import AddRoutine from './AddRoutine';
import VerifyRoutine from './VerifyRoutine';

type Props = {

};

const AddSupplyRoutine: FC<Props> = () => {
  const { t } = useTranslation();
  const routes = [
    {
      listItemText: t(TRANSLATIONS.OVERVIEW),
      routeName: '/analytics/production-planning',
      listItemIcon: gauzeIcon,
      listItemIconActive: gauzeGreenIcon,
    },
    {
      listItemText: 'Agregar rutina',
      routeName: '/upload/daily-routine',
      listItemIcon: executedBeer,
      listItemIconActive: executedBeer,
    },
  ];

  return (
    <HeaderWithNavigation
      routes={routes}
    >
      <AddRoutine />
      <VerifyRoutine />
    </HeaderWithNavigation>
  );
};

export default AddSupplyRoutine;
