import React, { useRef, useState, useEffect } from 'react';
import './Drivers.scss';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';
import { toast } from 'react-toastify';
import Modal from 'components/Modal';
import axios from 'axios';
import ProgressBar from 'components/ProgressBar';
import BasicButton from 'components/Button/BasicButton';
import CustomTable from 'components/Table';
import { Button, Menu, MenuItem } from '@mui/material';
import ImageLinks from 'utils/ImageLinks';
import LoadingSpinner from 'components/LoadingSpinner';
import { canUserAccessRoadSafetyDriverResourceActions } from 'utils/shared/canUserAccessFeature';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/selectors/user.selectors';
import { format } from 'date-fns';
import i18next from 'i18next';
import { es, enUS } from 'date-fns/locale';
import {
  selectSelectedLocationsSlug,
  selectSelectedLocation,
  selectGlobalFilters,
} from 'store/selectors/globalFilters.selectors';
import { useGetLastUpdateAtForTruckDriversUploadLazyQuery, useGetRouteSafetyTruckDriversByLocationSlugLazyQuery, useDeleteRouteSafetyTruckDriverMutation } from 'generated/graphql';

const { CancelToken } = axios;

const Drivers = () => {
  const { t } = useTranslation();

  const fileRef = useRef<HTMLInputElement>(null);

  const cancelSource = useRef<any>(null);
  const isRequestCancelledManually = useRef<any>(false);

  const userDetails = useSelector(selectUser);
  const { location } = useSelector(selectSelectedLocationsSlug);
  const selectedDC = useSelector(selectSelectedLocation);
  const globalFilters = useSelector(selectGlobalFilters);

  const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
  const [progressPercentage, setProgressPercentage] = useState<number>(0);
  const [uploadError, setUploadError] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<any>('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedDriverId, setSelectedDriverId] = useState<number>(-1);
  const [uploadErrorData, setUploadErrorData] = useState<any>([]);

  const canUserAccessRoadSafetyResourcesSideBarPage = canUserAccessRoadSafetyDriverResourceActions(
    userDetails?.get('features'),
    userDetails?.get('position')?.toUpperCase(),
  );

  const open = Boolean(anchorEl);

  const [callGetLastUpdateAtForTruckDriversUploadLazyQuery,
    { data: lastUploadedData }] = useGetLastUpdateAtForTruckDriversUploadLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const [callGetRouteSafetyTruckDriversByLocationSlugLazyQuery,
    { data: driversData, loading }] = useGetRouteSafetyTruckDriversByLocationSlugLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const callGetRouteSafetyTruckDriversByLocationSlugApi = () => {
    callGetRouteSafetyTruckDriversByLocationSlugLazyQuery({
      variables: {
        locationSlug: selectedDC?.locationSlug,
      },
    });
  };

  const [
    deleteRouteSafetyTruckDriver,
    { loading: deleteTruckDriverLoading },
  ] = useDeleteRouteSafetyTruckDriverMutation({
    onError: () => {
      setSelectedDriverId(-1);
      setAnchorEl(null);
      toast.error(TRANSLATIONS.SOMETHING_WENT_WRONG_TRY_LATER);
    },
    onCompleted: () => {
      toast.success(t(TRANSLATIONS.DRIVER_SUCCESSFULLY_REMOVED));
      setSelectedDriverId(-1);
      setAnchorEl(null);
      callGetRouteSafetyTruckDriversByLocationSlugApi();
    },
  });

  const callGetLastUpdateAtForTruckDriversUploadApi = () => {
    callGetLastUpdateAtForTruckDriversUploadLazyQuery({
      variables: {
        locationSlug: selectedDC?.locationSlug,
      },
    });
  };

  const driverList = driversData?.getRouteSafetyTruckDriversByLocationSlug ?? [];

  useEffect(() => {
    if (selectedDC?.locationSlug) {
      callGetLastUpdateAtForTruckDriversUploadApi();
      callGetRouteSafetyTruckDriversByLocationSlugApi();
    }
  // eslint-disable-next-line
  }, [selectedDC?.locationSlug]);

  const handleTruckFileUpload = () => {
    if (fileRef.current !== null) {
      fileRef.current.click();
    }
  };

  const onPressCancelRequest = () => {
    setShowUploadModal(false);
    setUploadError(false);
    setProgressPercentage(0);
    if (cancelSource?.current) {
      isRequestCancelledManually.current = true;
      cancelSource.current.cancel();
    }
  };

  const closeModal = () => {
    setProgressPercentage(0);
    setShowUploadModal(false);
    setUploadError(false);
  };

  const handleAttachmentsUpload = async (e: any) => {
    cancelSource.current = CancelToken.source();
    setShowUploadModal(true);
    setUploadError(false);
    setProgressPercentage(0);
    const { files } = e.target;
    if (files?.length) {
      setSelectedFile(files);
      const config = {
        headers: { 'content-type': 'multipart/form-data' },
      };
      const data = new FormData();

      data.set('file', files[0]);
      data.set('uploadedByUserEmail', userDetails?.get('email'));
      data.set('uploadedByUserName', userDetails?.get('name'));
      data.set('locationSlug', selectedDC?.locationSlug);

      try {
        const res = await axios({
          url: '/api/v1/roadsafety/upload-truck-drivers',
          method: 'POST',
          onDownloadProgress: (progressEvent: any) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setProgressPercentage(percentCompleted);
          },
          cancelToken: cancelSource.current.token,
          data,
          params: config,
        });

        if (res?.data?.isSuccess) {
          toast.success('Uploaded Successfully!');
          callGetLastUpdateAtForTruckDriversUploadApi();
          callGetRouteSafetyTruckDriversByLocationSlugApi();
          closeModal();
          if (fileRef.current !== null) {
            fileRef.current.value = '';
          }
        } else {
          if (res?.data?.data) {
            setUploadErrorData(res?.data?.data);
          }
          setUploadError(true);
        }
      } catch (error) {
        setUploadError(true);
      }
      // e.target.value = '';
    }
  };

  const handleAttachmentsRetryUpload = async () => {
    cancelSource.current = CancelToken.source();
    setShowUploadModal(true);
    setUploadError(false);
    setProgressPercentage(0);
    if (selectedFile?.length) {
      const config = {
        headers: { 'content-type': 'multipart/form-data' },
      };
      const data = new FormData();

      data.set('file', selectedFile[0]);
      data.set('uploadedByUserEmail', userDetails?.get('email'));
      data.set('uploadedByUserName', userDetails?.get('name'));
      data.set('locationSlug', selectedDC?.locationSlug);

      try {
        const res = await axios({
          url: '/api/v1/roadsafety/upload-truck-drivers',
          method: 'POST',
          onDownloadProgress: (progressEvent: any) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setProgressPercentage(percentCompleted);
          },
          cancelToken: cancelSource.current.token,
          data,
          params: config,
        });

        if (res?.data?.isSuccess) {
          toast.success('Uploaded Successfully!');
          callGetLastUpdateAtForTruckDriversUploadApi();
          callGetRouteSafetyTruckDriversByLocationSlugApi();
          closeModal();
          if (fileRef.current !== null) {
            fileRef.current.value = '';
          }
        } else {
          if (res?.data?.data) {
            setUploadErrorData(res?.data?.data);
          }
          setUploadError(true);
        }
      } catch (error) {
        setUploadError(true);
      }
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const threeDot = (driverId: number) => (
    <div style={{ margin: 0 }}>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        style={{ borderRadius: 20, boxShadow: 'none', margin: 0 }}
        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          handleClick(e);
          setSelectedDriverId(driverId);
        }}
      >
        <img
          alt="dots"
          style={{ cursor: 'pointer' }}
          width={15}
          height={15}
          src={ImageLinks.verticalDots}
        />
      </Button>
    </div>
  );

  const tableValues = (driverData: any) => {
    let className = 'textDesign';
    if (driverData?.isHeaderCritical && driverData?.isValueCritical) {
      className = 'driverNumberDesignError';
    } else if (driverData?.isHeaderCritical && !driverData?.isValueCritical) {
      className = 'driverNumberDesignSuccess';
    }
    return (
      <div className={className}>
        <p>{driverData?.value === null ? '' : String(driverData?.value)?.toUpperCase()}</p>
      </div>
    );
  };

  const tableTextValue = (value: string) => {
    return (
      <div className="textDesign">
        <p>{value}</p>
      </div>
    );
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelteDriver = () => {
    deleteRouteSafetyTruckDriver({
      variables: {
        driverUserId: String(selectedDriverId),
      },
    });
  };

  const ResourcesEmptyPage = () => {
    return (
      globalFilters?.size ? (
        <div className="roadSafetyResources__selectDc">
          <img src={ImageLinks.selectDc} alt="Select Location" />
          <div className="roadSafetyResources__selectDc__info">
            <p className="roadSafetyResources__selectDc__info__title">{t(TRANSLATIONS.SELECT_A_DISTRIBUTION_CENTER)}</p>
            <p className="roadSafetyResources__selectDc__info__subTitle">{t(TRANSLATIONS.IN_ORDER_TO_ACCESS_THE_ROAD_SAFETY_SETTINGS_IT_IS_NECESSARY_TO_SELECT_A_LOCATION_USING_THE_TOP_BAR)}</p>
          </div>
        </div>
      ) : (
        <LoadingSpinner height={30} style={{ position: 'fixed', top: '50%', left: '50%' }} />
      )
    );
  };

  const downloadTemplate = () => {
    window.open('https://arvolution-safety-development.s3.us-east-2.amazonaws.com/roadSafety/templates/excel-files/rs2/803f391b-ce51-4df0-b4c1-1dc78a2031c2-RS2%20Plantilla%20Faro%CC%81l%20de%20Chofer%20%285%29.xlsx', '_blank');
  };

  const uploadFile = () => {
    if (fileRef.current !== null) {
      fileRef.current.value = '';
    }
    handleTruckFileUpload();
  };

  return (
    <div className="roadSafetyDrivers">
      {showUploadModal && (
        <Modal
          modalContainerStyles={{
            width: '60vw',
            height: 'auto',
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'auto',
            padding: '24px',
            maxHeight: '80%',
          }}
        >
          <div className="roadSafetyDrivers__uploadModal">
            <div className="roadSafetyDrivers__uploadModal__tileWrap">
              <p className="roadSafetyDrivers__uploadModal__tileWrap__title">{uploadError ? `${t(TRANSLATIONS.SOMETHING_WENT_WRONG)}...` : `${t(TRANSLATIONS.LOADING_FILE)}...`}</p>
              {uploadError ? (
                <div role="button" onClick={closeModal}>
                  <img src={ImageLinks.deleteCircle} alt="closeModal" />
                </div>
              ) : null}
            </div>
            <>
              {uploadError ? (
                <>
                  <p className="roadSafetyDrivers__uploadModal__description">{t(TRANSLATIONS.LOADING_ERROR_PLEASE_RETRY)}</p>
                  {uploadErrorData?.length ? (
                    <ul className="roadSafetyDrivers__uploadModal__customBullet">
                      {uploadErrorData?.map?.((userData: any) => (
                        <li><span className="roadSafetyDrivers__uploadModal__customBullet__title">{userData?.qrCode ? userData?.qrCode : t(TRANSLATIONS.WITHOUT_CODE)}</span><span> - </span><span className="roadSafetyDrivers__uploadModal__customBullet__title">{userData?.name}</span></li>
                      ))}
                    </ul>
                  ) : null}
                </>
              ) : (
                <div className="roadSafetyDrivers__uploadModal__progress_bar">
                  <p className="roadSafetyDrivers__uploadModal__progress_bar__title">{progressPercentage}%</p>
                  <ProgressBar bgcolor="#4472C4" height={16} progress={progressPercentage} />
                </div>
              )}
            </>
            {uploadError ? (
              <div className="roadSafetyDrivers__uploadModal__button_container">
                <BasicButton text={t(TRANSLATIONS.RETRY_AGAIN)} className="roadSafetyDrivers__uploadModal__button_container__download_button" onClick={handleAttachmentsRetryUpload} />
                <BasicButton text={t(TRANSLATIONS.UPLOAD_ANOTHER_FILE)} className="roadSafetyDrivers__uploadModal__button_container__cancel_button" onClick={uploadFile} />
              </div>
            ) : (
              <div className="roadSafetyDrivers__uploadModal__button_retry_container">
                <BasicButton text={t(TRANSLATIONS.CANCEL)} className="roadSafetyDrivers__uploadModal__button_retry_container__cancel_button" onClick={!uploadError ? onPressCancelRequest : closeModal} />
              </div>
            )}
          </div>
        </Modal>
      )}
      {location ? (
        <>
          <p className="roadSafetyDrivers__title">{t(TRANSLATIONS.DRIVERS)}</p>
          {canUserAccessRoadSafetyResourcesSideBarPage ? (
            <div className="roadSafetyDrivers__driverSection">
              <div className="roadSafetyDrivers__driverSection__uploadContainer">
                <div role="button" onClick={uploadFile}>
                  <p className="roadSafetyDrivers__driverSection__uploadContainer__uploadNewTruckFile-title">{t(TRANSLATIONS.UPLOAD_NEW_DRIVER_FILE)}</p>
                </div>
                {lastUploadedData?.getLastUpdateAtForTruckDriversUpload?.updatedAt ? (
                  <p className="roadSafetyDrivers__driverSection__uploadContainer__lastUpdated-title">{format(new Date(lastUploadedData?.getLastUpdateAtForTruckDriversUpload?.updatedAt), `'${t(TRANSLATIONS.LAST_UPDATED_ON_RS)}' d ${i18next.language === 'es' ? "'de'" : ''} MMMM, yyyy`, { locale: (i18next.language === 'es' ? es : enUS) })}</p>
                ) : null}
              </div>
              <div className="roadSafetyDrivers__driverSection__downloadContainer" role="button" onClick={downloadTemplate}>
                <p className="roadSafetyDrivers__driverSection__downloadContainer__downloadTemplate-title">{t(TRANSLATIONS.DOWNLOAD_TEMPLATE)}</p>
              </div>
            </div>
          ) : null}

          {(!loading && !deleteTruckDriverLoading) ? (
            <div style={driverList?.length === 0 ? { display: 'none', marginTop: 0 } : {}}>
              <div className="roadSafetyDrivers__cards-row roadSafetyDrivers__scroll-view">
                <CustomTable
                  onClickRow={() => {}}
                  header={
                    [
                      '',
                      'NOMBRE',
                      'EDAD >30<55',
                      '>5 AÑOS CONDUC.',
                      'Realiza esta única act.',
                      'Histórico de Accidente',
                      'Uso bebidas alc. <1 x sem.',
                      'Uso de medicamentos controlados',
                      'Problemas de salud',
                    ]
                  }
                  rows={
                    driverList?.map((row: any) => {
                      const rowDataArr: any = [];
                      row?.info?.content?.map?.((d:any) => {
                        rowDataArr?.push(tableValues(d));
                      });
                      return {
                        rowData: [
                          threeDot(Number(row!.id)),
                          tableTextValue(row!.name),
                          ...rowDataArr,
                        ],
                        index: row!.id,
                      };
                    })
                  }
                />
              </div>
              {
                open && selectedDriverId !== -1 && (
                  <Menu
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    className="roadSafetyDrivers__dashboard-option-menu shadow-none"
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                  >
                    <MenuItem
                      className="roadSafetyDrivers__dashboard-menu-item shadow-none"
                      onClick={(e: React.MouseEvent<HTMLElement>) => {
                        e.stopPropagation();
                        handleDelteDriver();
                      }}
                    >
                      <div className="roadSafetyDrivers__dashboard-menu-item-div shadow-none">
                        <img src={ImageLinks.deleteGrey} style={{ height: 24, width: 24 }} alt="delete" />
                        <text className="roadSafetyDrivers__menu_deleteTitle">Delete user</text>
                      </div>
                    </MenuItem>
                  </Menu>
                )
              }
            </div>
          ) : (
            <div style={{ height: 400 }}>
              <LoadingSpinner positionTop={200} />
            </div>
          )}
          { (driverList?.length === 0 && !loading)
            && (
            <div className="roadSafetyDrivers__no-checklist-container">
              <img src={ImageLinks.noData} style={{ margin: '2rem 0' }} alt="noChecklists" />
              <p className="roadSafetyDrivers__no-checklist-p">{t(TRANSLATIONS.NO_DATA_LOADED_YET_PRESS_UPLOAD_NEW_DRIVER_FILE)}</p>
            </div>
            )}
          <input
            ref={fileRef}
            className="hidden"
            type="file"
            onChange={handleAttachmentsUpload}
            name="file"
            multiple
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          />
        </>
      ) : (
        <ResourcesEmptyPage />
      )}
    </div>
  );
};

export default Drivers;
