import React, { useState } from 'react';
import ChartTabs from 'components/Tabs/ChartTabs';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';
import { useCubeQuery } from '@cubejs-client/react';
import { Filter } from '@cubejs-client/core';
import Chart from 'react-apexcharts';
import ChartDropdown from 'components/Dropdown/ChartDropdown';
import { subDays } from 'date-fns';
import { format, lastDayOfMonth, set } from 'date-fns/esm';
// import './IncidentsBarChart.scss';
import { useSelector } from 'react-redux';
import { selectLocationsToAccess } from 'store/selectors/globalFilters.selectors';
import { routinesMonthSelectordropDownItems } from 'utils/dateHelpers';
import moment from 'moment';
import { selectTimezone } from 'store/selectors/timeFilter.selector';

const dropDownItemsArr = routinesMonthSelectordropDownItems(true);

type IncidentsByLocation = {
  'Locations.name': string;
  'Locations.slug': string;
  'Incidents.count': number;
};

type LocationDataItem = {
  name: string,
  incidents: number,
  openIncidents: number,
  closedIncidents: number,
  assignedIncidents: number,
  unsafeIncidents: number,
  safeIncidents: number,
};

const IncidentsByRiskTypeBarChart = () => {
  const { t } = useTranslation();
  const timezone = useSelector(selectTimezone);

  const years = [
    {
      slug: moment().format('YYYY'),
      label: moment().format('YYYY'),
      startTime: moment().clone().startOf('year').format('yyyy-MM-DD'),
      endTime: moment().clone().endOf('year').format('yyyy-MM-DD'),
    },
    {
      slug: moment().subtract(1, 'years').format('YYYY'),
      label: moment().subtract(1, 'years').format('YYYY'),
      startTime: moment().subtract(1, 'years').startOf('year').format('yyyy-MM-DD'),
      endTime: moment().subtract(1, 'years').endOf('year').format('yyyy-MM-DD'),
    },
  ];

  const dropDownItems = [{
    slug: 'last-7-days',
    label: t(TRANSLATIONS.LAST_7_DAYS),
  }, {
    slug: 'last-30-days',
    label: t(TRANSLATIONS.LAST_30_DAYS),
  }, {
    slug: 'month-to-date',
    label: t(TRANSLATIONS.MONTH_TO_DATE),
  },
  ...dropDownItemsArr,
  ...years,
  ];

  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [selectedDropDownItem, setSelectedDropDownItem] = useState<any>(dropDownItems[0]);
  const locationsToAccess = useSelector(selectLocationsToAccess);

  const handleDropDownChange = ({ key }: any) => {
    setSelectedDropDownItem(dropDownItems.find(
      item => item.slug === key,
    ) || dropDownItems[0]);
    setDropDownOpen(false);
  };

  const getDateRange = () => {
    const currentDate = new Date();
    let filter = ['', ''] as any[];
    if (selectedDropDownItem.slug === 'last-7-days') {
      filter = [subDays(currentDate, 7), currentDate];
    }
    if (selectedDropDownItem.slug === 'last-30-days') {
      filter = [subDays(currentDate, 30), currentDate];
    }
    if (selectedDropDownItem.slug === 'month-to-date') {
      const lastMonthDay = lastDayOfMonth(currentDate);
      const firstDayLastMonth = set(lastMonthDay, { date: 1 });
      filter = [firstDayLastMonth, lastMonthDay];
    }
    if (selectedDropDownItem?.slug?.split(' ')?.length === 2) {
      const dateFromSlug = selectedDropDownItem.slug.split(' ');
      const dateMonth = moment().year(dateFromSlug[1]).week(selectedDropDownItem?.content?.weekNumber + 1).format('M');

      const monthFirstDate = new Date(+dateFromSlug[1], +dateMonth - 1, +1);
      const lastMonthDay = lastDayOfMonth(monthFirstDate);
      const firstDayLastMonth = set(lastMonthDay, { date: 1 });
      filter = [firstDayLastMonth, lastMonthDay];
    }
    if (selectedDropDownItem?.slug?.length === 4) {
      filter = [selectedDropDownItem?.startTime, selectedDropDownItem?.endTime];
      return filter;
    }
    return [format(filter[0], 'yyyy-MM-dd'), format(filter[1], 'yyyy-MM-dd')];
  };

  const getDimensions = () => {
    return ['RiskTypes.name'];
    // if (selectedLocations.location) return ['Locations.name', 'Locations.slug'];
    // if (selectedLocations.subzone) return ['Locations.name', 'Locations.slug'];
    // if (selectedLocations.zone) return ['Subzones.name', 'Subzones.slug'];
    // if (selectedLocations.businessUnit) return ['Zones.name', 'Zones.slug'];

    // return ['Zones.name', 'Zones.slug'];
  };

  const dimensions = getDimensions();

  const dateRange = getDateRange();
  const assignedIncidentsQueryFilters = [{
    member: 'Incidents.isUnsafe',
    operator: 'equals',
    values: ['1'],
  }, {
    member: 'Incidents.closedat',
    operator: 'notSet',
  }, {
    member: 'Incidents.assignedat',
    operator: 'set',
  }, {
    member: 'Incidents.locationId',
    operator: 'equals',
    values: locationsToAccess,
  },
  {
    dimension: 'Incidents.deletedat',
    operator: 'notSet',
  },
  {
    dimension: 'Incidents.typeofissue',
    operator: 'equals',
    values: ['CONDITION', 'ACT'],
  }] as Filter[];

  const closedIncidentsQueryFilters = [{
    dimension: 'Incidents.isUnsafe',
    operator: 'equals',
    values: ['1'],
  }, {
    dimension: 'Incidents.closedat',
    operator: 'set',
  }, {
    member: 'Incidents.locationId',
    operator: 'equals',
    values: locationsToAccess,
  },
  {
    dimension: 'Incidents.deletedat',
    operator: 'notSet',
  },
  {
    dimension: 'Incidents.typeofissue',
    operator: 'equals',
    values: ['CONDITION', 'ACT'],
  }] as Filter[];

  const limit = 999;

  const openedIncidentsQueryFilters = [{
    dimension: 'Incidents.isUnsafe',
    operator: 'equals',
    values: ['1'],
  }, {
    dimension: 'Incidents.assignedat',
    operator: 'notSet',
  }, {
    dimension: 'Incidents.closedat',
    operator: 'notSet',
  }, {
    member: 'Incidents.locationId',
    operator: 'equals',
    values: locationsToAccess,
  },
  {
    dimension: 'Incidents.deletedat',
    operator: 'notSet',
  },
  {
    dimension: 'Incidents.typeofissue',
    operator: 'equals',
    values: ['CONDITION', 'ACT'],
  }] as Filter[];

  const safeIncidentsQueryFilters = [{
    dimension: 'Incidents.isUnsafe',
    operator: 'equals',
    values: ['0'],
  }, {
    member: 'Incidents.locationId',
    operator: 'equals',
    values: locationsToAccess,
  },
  {
    dimension: 'Incidents.deletedat',
    operator: 'notSet',
  },
  {
    dimension: 'Incidents.typeofissue',
    operator: 'equals',
    values: ['CONDITION', 'ACT'],
  }] as Filter[];

  const { resultSet: totalIncidentsByLocation } = useCubeQuery({
    measures: ['Incidents.count'],
    timeDimensions: [{
      dimension: 'Incidents.incidentdatetime',
      dateRange,
    }],
    dimensions,
    order: {
      [dimensions[0]]: 'desc',
    },
    filters: [{
      member: 'Incidents.locationId',
      operator: 'equals',
      values: locationsToAccess,
    },
    {
      dimension: 'Incidents.deletedat',
      operator: 'notSet',
    },
    {
      dimension: 'Incidents.typeofissue',
      operator: 'equals',
      values: ['CONDITION', 'ACT'],
    }],
    limit,
    timezone,
  });
  const { resultSet: totalIncidents } = useCubeQuery({
    measures: ['Incidents.count'],
    timeDimensions: [{
      dimension: 'Incidents.incidentdatetime',
      dateRange,
    }],
    filters: [{
      member: 'Incidents.locationId',
      operator: 'equals',
      values: locationsToAccess,
    },
    {
      dimension: 'Incidents.deletedat',
      operator: 'notSet',
    },
    {
      dimension: 'Incidents.typeofissue',
      operator: 'equals',
      values: ['CONDITION', 'ACT'],
    }],
    timezone,
  });
  const { resultSet: totalOpenedIncidentsByLocation } = useCubeQuery({
    measures: ['Incidents.count'],
    timeDimensions: [{
      dimension: 'Incidents.incidentdatetime',
      dateRange,
    }],
    dimensions,
    order: {
      [dimensions[0]]: 'desc',
    },
    filters: openedIncidentsQueryFilters,
    limit,
    timezone,
  });
  // Opened incidents total:
  const { resultSet: openedIncidentsTotal } = useCubeQuery({
    measures: ['Incidents.count'],
    timeDimensions: [{
      dimension: 'Incidents.incidentdatetime',
      dateRange,
    }],
    filters: openedIncidentsQueryFilters,
    timezone,
  });
  const { resultSet: closedIncidentsTotal } = useCubeQuery({
    measures: ['Incidents.count'],
    timeDimensions: [{
      dimension: 'Incidents.incidentdatetime',
      dateRange,
    }],
    filters: closedIncidentsQueryFilters,
    timezone,
  });
  const { resultSet: totalClosedIncidentsByLocation } = useCubeQuery({
    measures: ['Incidents.count'],
    timeDimensions: [{
      dimension: 'Incidents.incidentdatetime',
      dateRange,
    }],
    dimensions,
    order: {
      [dimensions[0]]: 'desc',
    },
    filters: closedIncidentsQueryFilters,
    limit,
    timezone,
  });
  const { resultSet: assignedIncidentsTotal } = useCubeQuery({
    measures: ['Incidents.count'],
    timeDimensions: [{
      dimension: 'Incidents.incidentdatetime',
      dateRange,
    }],
    filters: assignedIncidentsQueryFilters,
    timezone,
  });
  const { resultSet: totalAssignedIncidentsByLocation } = useCubeQuery({
    measures: ['Incidents.count'],
    timeDimensions: [{
      dimension: 'Incidents.incidentdatetime',
      dateRange,
    }],
    dimensions,
    order: {
      [dimensions[0]]: 'desc',
    },
    filters: closedIncidentsQueryFilters,
    limit,
    timezone,
  });
  const { resultSet: safeIncidentsTotal } = useCubeQuery({
    measures: ['Incidents.count'],
    timeDimensions: [{
      dimension: 'Incidents.incidentdatetime',
      dateRange,
    }],
    filters: safeIncidentsQueryFilters,
    timezone,
  });
  const { resultSet: totalSafeIncidentsByLocation } = useCubeQuery({
    measures: ['Incidents.count'],
    timeDimensions: [{
      dimension: 'Incidents.incidentdatetime',
      dateRange,
    }],
    dimensions,
    order: {
      [dimensions[0]]: 'desc',
    },
    filters: safeIncidentsQueryFilters,
    limit,
    timezone,
  });

  const chartData = totalIncidentsByLocation?.rawData() as
    unknown as IncidentsByLocation[] | undefined;
  const openedByLocationData = totalOpenedIncidentsByLocation?.rawData() as
    unknown as IncidentsByLocation[];
  const closedByLocationData = totalClosedIncidentsByLocation?.rawData() as
    unknown as IncidentsByLocation[];
  const assignedByLocationData = totalAssignedIncidentsByLocation?.rawData() as
    unknown as IncidentsByLocation[];
  const safeByLocationData = totalSafeIncidentsByLocation?.rawData() as
    unknown as IncidentsByLocation[];

  let allChartData = [] as LocationDataItem[];
  const chartDataLoaded = chartData && openedByLocationData;
  if (chartDataLoaded && chartData) {
    allChartData = chartData.map((v: any) => {
      const name = v[dimensions[0]] || 'Otro';
      const incidents = v['Incidents.count'] || '';

      const finder = ((e: any) => e[dimensions[1]] === v[dimensions[1]]);
      const openIncidents = openedByLocationData?.find(finder)?.['Incidents.count'] || 0;
      const closedIncidents = closedByLocationData?.find(finder)?.['Incidents.count'] || 0;
      const assignedIncidents = assignedByLocationData?.find(finder)?.['Incidents.count'] || 0;
      const safeIncidents = safeByLocationData?.find(finder)?.['Incidents.count'] || 0;
      const unsafeIncidents = openIncidents + closedIncidents + assignedIncidents + safeIncidents;

      return {
        name,
        incidents,
        openIncidents,
        closedIncidents,
        assignedIncidents,
        unsafeIncidents,
        safeIncidents,
      };
    });
  }

  const tabsItems = [{
    title: t(TRANSLATIONS.TOTAL_REPORTS),
    value: totalIncidents?.tablePivot()[0]['Incidents.count']?.toLocaleString() || '-',
  }, {
    title: t(TRANSLATIONS.OPENED),
    value: openedIncidentsTotal?.tablePivot()[0]['Incidents.count']?.toLocaleString() || '-',
  }, {
    title: t(TRANSLATIONS.CLOSED),
    value: closedIncidentsTotal?.tablePivot()[0]['Incidents.count']?.toLocaleString() || '-',
  }, {
    title: t(TRANSLATIONS.ASSIGNED),
    value: assignedIncidentsTotal?.tablePivot()[0]['Incidents.count']?.toLocaleString() || '-',
  }, {
    title: t(TRANSLATIONS.SAFE),
    value: safeIncidentsTotal?.tablePivot()[0]['Incidents.count']?.toLocaleString() || '-',
  }];
  const [selectedTab, setSelectedTab] = useState(tabsItems[0]);

  const calculateSeriesData = () => {
    let compare;
    const values = ['incidents', 'unsafeIncidents', 'unsafeIncidents', 'unsafeIncidents', 'safeIncidents'];

    values.forEach((v, i) => {
      if (selectedTab.title === tabsItems[i].title) {
        compare = (a: any, b: any) => a[v] - b[v];
      }
    });

    const sortedValues = allChartData.sort(compare);
    const labels = sortedValues.map(v => v.name);

    if (selectedTab.title === tabsItems[0].title) {
      return {
        labels,
        series: [{
          name: t(TRANSLATIONS.INCIDENTS),
          data: sortedValues.map(v => v.incidents),
        }],
      };
    }
    if (selectedTab.title === tabsItems[4].title) {
      return {
        labels,
        series: [{
          name: 'Seguros',
          data: sortedValues.map(v => v.safeIncidents),
        }],
      };
    }

    return {
      labels,
      series: [{
        name: t(TRANSLATIONS.OPENED),
        data: sortedValues.map(v => v.openIncidents),
      }, {
        name: t(TRANSLATIONS.CLOSED),
        data: sortedValues.map(v => v.closedIncidents),
      }, {
        name: t(TRANSLATIONS.ASSIGNED),
        data: sortedValues.map(v => v.assignedIncidents),
      }],
    };
  };

  const chartValues = calculateSeriesData();

  const data = {
    series: chartValues.series || [],
    options: {
      xaxis: {
        categories: chartValues.labels || [],
        labels: {
          rotate: 0,
          style: {
            fontSize: '8px',
          },
        },
      },
      chart: {
        stacked: true,
        toolbar: {
          show: false,
        },
        height: 350,
      },
      colors: [tabsItems[0].title, tabsItems[4].title].includes(selectedTab.title) ? ['#70AD47'] : ['#BEC7B9', '#E4F2DA', '#70AD47'],
      plotOptions: {
        bar: {
          columnWidth: '90%',
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        offsetY: 7,
        show: true,
        showForSingleSeries: true,
      },
    },
  };

  return (
    <div className="incidents-bar-chart">
      <ChartTabs
        tabsItems={tabsItems as any}
        setSelectedTab={setSelectedTab as any}
        selectedTab={selectedTab as any}
      />
      <Chart series={data.series} type="bar" options={data.options} height={200} />
      <ChartDropdown
        onChange={handleDropDownChange}
        selectedDropDownItem={selectedDropDownItem}
        dropDownItems={dropDownItems}
        dropDownOpen={dropDownOpen}
        setDropDownOpen={setDropDownOpen}
        labelClassName="time-dropdown"
      />
    </div>
  );
};

export default IncidentsByRiskTypeBarChart;
