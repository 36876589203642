import React from 'react';
import Menu, { Item as MenuItem } from 'rc-menu';
import { SelectInfo } from 'rc-menu/es/interface';
import Dropdown from 'rc-dropdown';
import 'rc-dropdown/assets/index.css';
import './ChartDropdownStyles.scss';
import ChevronDown from '../../../assets/icons/ChevronDown';

export type Item = {
  label: string;
  slug: any;
  img?: any;
};
type Props = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange: (info: SelectInfo) => void;
  selectedDropDownItem: any;
  dropDownItems: Item[];
  dropDownOpen: boolean;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setDropDownOpen: (opened: boolean) => void;
  labelClassName?: string;
  isLoading?: boolean;
  dropdownArrowColor?: string;
  isDisabled?: boolean;
};

const ChartDropdown = (props: Props) => {
  const {
    onChange,
    selectedDropDownItem,
    dropDownItems,
    dropDownOpen,
    setDropDownOpen,
    labelClassName = '',
    isLoading = false,
    dropdownArrowColor = '#878787',
    isDisabled = false,
  } = props;
  const menu = (
    <Menu
      onSelect={onChange}
      selectedKeys={[selectedDropDownItem?.slug]}
      style={{ cursor: 'pointer' }}
    >
      {dropDownItems.map(item => (
        <MenuItem key={item.slug}>
          {item?.img && <img alt="icon" src={item.img} style={{ marginRight: 10 }} />} {item.label}
        </MenuItem>
      ))}
    </Menu>
  );

  return (
    <div
      className="chart-dropdown"
      style={{ pointerEvents: isLoading ? 'none' : 'auto', opacity: isLoading ? '0.6' : '1', maxWidth: 'fit-content' }}
    >
      <Dropdown
        trigger={isDisabled ? [] : ['click']}
        overlay={menu}
        animation="slide-up"
        visible={dropDownOpen}
        onVisibleChange={setDropDownOpen}
      >
        <div className={`${isDisabled ? 'dropdown-label-disabled' : 'dropdown-label'} ${labelClassName}`}>
          <span>{selectedDropDownItem?.label}</span>
          <div className="chev-down-icon">
            <ChevronDown color={`${isDisabled ? '#00000061' : dropdownArrowColor}`} />
          </div>
        </div>
      </Dropdown>
    </div>
  );
};

export default ChartDropdown;
