import React, { Component } from 'react';

import './Toggle.scss';

export default class Toggle extends Component<Props, any> {
  render() {
    const {
      options,
      onOptionSelect,
      selectedOption,
      containerClassName,
      tabStyle,
      activeTabStyle,
      inActiveTabStyle,
    } = this.props;

    let toggleStyle = tabStyle;

    return (
      <div className={`toggle ${containerClassName}`}>
        {options.map((option: any) => {
          const isOptionSelected = selectedOption === option.key;

          if (isOptionSelected) toggleStyle = { ...toggleStyle, ...activeTabStyle };
          else toggleStyle = { ...toggleStyle, ...inActiveTabStyle };

          return (
            <button
              type="button"
              key={option.key}
              className={`toggle-button ${
                isOptionSelected ? 'button-on' : 'button-off'}`}
              onClick={() => onOptionSelect(option.key)}
              style={toggleStyle}
            >
              {option.text}
            </button>
          );
        })}
      </div>
    );
  }
}

type Props = {
  options: Array<{ text: string; key: string }>;
  onOptionSelect: any;
  selectedOption: string;
  containerClassName?: string;
  tabStyle?: any;
  activeTabStyle?: object;
  inActiveTabStyle?: object;
};
