import React, { useState } from 'react';
import SeparatorWithTitle from 'components/Separator/SeparatorWithTitle';
import ImageLinks from 'utils/ImageLinks';
import './SifRoute.scss';
import SifRouteBU from './rutaSifBU';
import SifRouteMAZ from './rutaSifMAZ';
import SifRoutesTable from './SifRoutesTable/SifRoutesTable';

const SifRoute = () => {
  const [searchField, setSearchField] = useState<string>('');

  return (
    <div className="container-fluid overview-wrapper sif-route">
      <h2 className="sif-route__title">Rutas SIF</h2>
      <h3 className="sif-route__subtitle">Rutas SIF en MAZ</h3>
      <SifRouteMAZ />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <SeparatorWithTitle title="Ranking de Rutas SIF por GRO" />
        <div>
          <input
            className="sif-route__search-box"
            type="search"
            placeholder="Buscar por GRO o País/Región"
            onChange={event => setSearchField(event.target.value)}
          />
          <img src={ImageLinks.search_black} alt="search" style={{ margin: 8 }} />
        </div>
      </div>
      <div className="row">
        <div className="col-12 card routines-home__card">
          <SifRoutesTable searchField={searchField} />
        </div>
      </div>
      <SeparatorWithTitle title="SIF Routes BU" />
      <SifRouteBU />
    </div>
  );
};

export default SifRoute;
