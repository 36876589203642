import React, { useEffect, useMemo, useState } from 'react';
import FilterDropdown from 'components/Dropdown/FilterDropdown';
import { TRANSLATIONS } from 'types/enums';
import './RoutinesListing.scss';
import { useTranslation } from 'react-i18next';
import { useGetRoutineAvailableInfoQuery } from 'generated/graphql';
import { generateMeetingList } from './routinesListHelper';

export type Routine = {
  label: string;
  value: string;
};

type Props = {
  selectedRoutine?: Routine;
  setSelectedRoutine: Function;
};

const RoutineListing = (props: Props) => {
  const [routineList, setRoutineList] = useState<Routine[]>([]);
  const { selectedRoutine, setSelectedRoutine } = props;
  const { data } = useGetRoutineAvailableInfoQuery(
    {
      variables: { forFilter: false },
    },
  );
  const { t } = useTranslation();
  const routinesAvailable = useMemo(() => {
    return generateMeetingList(data?.getRoutinesAvailable || []);
  }, [data]);

  useEffect(() => {
    setRoutineList(routinesAvailable);
  }, [routinesAvailable]);

  function selectOrDeselectRoutine(routine: Routine) {
    if (selectedRoutine?.value === routine.value) {
      setSelectedRoutine({ label: '', value: '' });
    } else {
      setSelectedRoutine(routine);
    }
  }

  function handleSearch(text: string) {
    const trimedText = text.trim();
    if (trimedText.length) {
      const filteredRoutines = routinesAvailable.filter(
        (routine: Routine) => routine.label.toLowerCase().includes(trimedText.toLowerCase()),
      );
      setRoutineList(filteredRoutines);
    } else {
      setRoutineList(routinesAvailable);
    }
  }

  function ListHeader() {
    return (
      <div className="filter-headers header-border">
        <h2>{t(TRANSLATIONS.ROUTINES)}</h2>
        <p>{t(TRANSLATIONS.SELECT_THE_ROUTINE_YOU_WANT_TO_SEE)}</p>
      </div>
    );
  }

  function ListItem(listItem: Routine, selectedItem: Routine) {
    const selected = listItem.value === selectedItem?.value;
    return (
      <div
        key={listItem.value}
        className={`list-item ${selected ? 'selected' : ''}`}
        role="button"
        onClick={() => selectOrDeselectRoutine(listItem)}
      >
        <p>{t(listItem.label)}</p>
      </div>
    );
  }

  function EmptyList() {
    return (<p>{t(TRANSLATIONS.NO_RESULTS_FOUND)}</p>);
  }

  return (
    <div>
      <FilterDropdown
        listToRender={routineList}
        filterName={selectedRoutine?.label
          ? t(selectedRoutine.label)
          : t(TRANSLATIONS.ALL_ROUTINES)}
        ListHeaderComponent={ListHeader}
        ListItem={ListItem}
        selectedItems={selectedRoutine || { label: '', value: '' }}
        searchBarPlaceholder={t(TRANSLATIONS.SEARCH_ROUTINE)}
        handleSearch={handleSearch}
        resetList={() => setRoutineList(routinesAvailable)}
        EmptyList={EmptyList}
      />
    </div>
  );
};

export default RoutineListing;
