import { createSelector } from 'reselect';
import { FILTER_ACCESS_LEVELS } from 'types/enums';
import { filterLocationsByCluster, groupLocationsByCluster } from 'utils/arrayHelpers';
import { selectAllClusters, selectSelectedLocationsSlug } from './globalFilters.selectors';

export const selectCurrentAccesLevel = createSelector(
  selectSelectedLocationsSlug,
  selectAllClusters,
  (locations, allClusters) => {
    if (locations.cluster) return FILTER_ACCESS_LEVELS.DISTRIBUTION_CENTER;
    if (locations.subzone && allClusters?.length > 0) {
      return FILTER_ACCESS_LEVELS.CLUSTER;
    }
    if (locations.subzone) return FILTER_ACCESS_LEVELS.DISTRIBUTION_CENTER;
    if (locations.zone) return FILTER_ACCESS_LEVELS.SUBZONE;
    if (locations.businessUnit) return FILTER_ACCESS_LEVELS.ZONE;
    return FILTER_ACCESS_LEVELS.BUSINESS_UNIT;
  },
);

export const selectLocationsWidthIncidents = (state: any) => state.getIn(['corporateDashboard', 'zones', 'businessUnits']);

export const selectLocationsToShow = createSelector(
  selectSelectedLocationsSlug,
  selectLocationsWidthIncidents,
  (locations, locationsWithIncidents) => {
    if (!locationsWithIncidents) return null;

    const businessUnitIndex = locationsWithIncidents.findIndex((bu: any) => bu.get('slug') === locations.businessUnit);
    const zoneIndex = businessUnitIndex !== -1 && locationsWithIncidents.getIn([businessUnitIndex, 'zones']).findIndex((zone: any) => zone.get('slug') === locations.zone);
    const subzoneIndex = (zoneIndex !== -1 && zoneIndex !== false) && locationsWithIncidents.getIn([businessUnitIndex, 'zones', zoneIndex, 'subzones']).findIndex((subzone: any) => subzone.get('slug') === locations.subzone);

    if (locations.cluster) {
      const subzoneChilds = locationsWithIncidents.getIn([businessUnitIndex, 'zones', zoneIndex, 'subzones', subzoneIndex, 'locations']);
      const allLocations = subzoneChilds?.toJS();
      const list = allLocations ? filterLocationsByCluster(allLocations, locations.cluster) : [];
      return { list, level: 'CDs' };
    }

    if (locations.subzone) {
      const subzoneChilds = locationsWithIncidents.getIn([businessUnitIndex, 'zones', zoneIndex, 'subzones', subzoneIndex, 'locations']);
      const allLocations = subzoneChilds?.toJS();
      const { clusterExists, clusters }: any = allLocations
        ? groupLocationsByCluster(allLocations) : {};

      return clusterExists ? { list: clusters, level: 'Clusters' } : { list: allLocations, level: 'CDs' };
    }

    if (locations.zone) {
      const zoneChilds = locationsWithIncidents.getIn([businessUnitIndex, 'zones', zoneIndex, 'subzones']);
      return { list: zoneChilds?.toJS(), level: 'Subzones' };
    }

    if (locations.businessUnit) {
      const buChilds = locationsWithIncidents.getIn([businessUnitIndex, 'zones']);
      return { list: buChilds?.toJS(), level: 'Zones' };
    }
    return { list: locationsWithIncidents?.toJS(), level: 'BusinessUnits' };
  },
);
