import EmptyState from 'components/EmptyState/EmptyState';
import LoadingSpinner from 'components/LoadingSpinner';
import { useGetSifStatsByDurationQuery } from 'generated/graphql';
import React from 'react';
import Chart from 'react-apexcharts';

type Props = {
  xLabel?: string;
  yLabel?: string;
  graphColor?: string;
  monthNumber: number,
  year: number,
  filter: any,
};

function SimpleBarGraph(props: Props) {
  const { data: chartData, loading, error } = useGetSifStatsByDurationQuery({
    variables: {
      monthNumber: props.monthNumber,
      year: props.year,
      filter: props.filter,
    },
  });

  const data: any = [];
  const labels: any = [];

  if (chartData?.getSifStatsByDuration) {
    chartData?.getSifStatsByDuration.forEach(chartDataItem => {
      data.push(chartDataItem?.totalSif);
      labels.push(chartDataItem?.duration);
    });
  }

  const {
    xLabel,
    yLabel,
    graphColor = '#5572B9',
  } = props;

  const series = [{
    name: 'Actuales',
    data,
  }];

  const options = {
    chart: {
      height: 350,
      type: 'bar',
      stacked: true,
    },
    colors: [graphColor],
    plotOptions: {
      bar: {
        borderRadius: 10,
        columnWidth: '50%',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 2,
    },
    xaxis: {
      labels: {
        rotate: -45,
      },
      title: {
        text: xLabel,
      },
      categories: labels,
    },
    yaxis: {
      title: {
        text: yLabel,
      },
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      position: 'top',
      offsetX: 40,
    },
  };
  if (error) return <h5> Something Went Wrong. Please Try Again  </h5>;
  if (loading) return <LoadingSpinner />;
  if (chartData?.getSifStatsByDuration.length === 0) return <EmptyState />;
  // @ts-ignore
  return (<Chart options={options} series={series} type="bar" height={350} />);
}

export default SimpleBarGraph;
