import { KPI } from 'features/action-logs/KPIsListing/KPIsListing';
import { Routine } from 'features/action-logs/RoutinesListing/RoutinesListing';
import { SearchedUser } from 'features/action-logs/UsersListing/UserListing';
import React, { useRef } from 'react';
import useComponentVisible from 'utils/hooks/useComponentVisible';
import ImageLinks from 'utils/ImageLinks';
import './FilterDropdown.scss';

interface Props {
  filterName: string;
  listToRender: Routine[] | KPI[] | SearchedUser[];
  selectedItems: Routine | KPI[] | SearchedUser[];
  handleSearch?: Function;
  searchBarPlaceholder?: string;
  ListHeaderComponent: React.FunctionComponent<any>;
  ListItem: React.FunctionComponent<any>;
  EmptyList?: React.FunctionComponent<any>;
  resetList?: Function;
}

function FilterDropdown(props: Props) {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { ListHeaderComponent } = props;

  function eventPersistAndHandleRequest(e: any) {
    e.persist();
    if (typeof props.handleSearch === 'function') props.handleSearch(e.target.value);
  }

  function clearField() {
    if (inputRef.current) {
      inputRef.current.value = '';
      if (typeof props.handleSearch === 'function') props.handleSearch('');
      inputRef.current.focus();
    }
  }

  const toggleDropdown = () => {
    if (!isComponentVisible && props.resetList) {
      props.resetList();
    }
    setIsComponentVisible(!isComponentVisible);
  };

  return (
    <div className="on-click-dropdown">
      <div className="filter-dropdown-container" ref={ref}>
        <div className="filter-header" onClick={toggleDropdown} role="button">
          <p>{props.filterName}</p>
        </div>
        {isComponentVisible && (
          <div
            className="dropdown-content"
            style={{
              display: `${!isComponentVisible ? 'none' : 'block'}`,
            }}
          >
            <ListHeaderComponent />
            {props.searchBarPlaceholder && (
              <div className="search-bar">
                <div>
                  <img src={ImageLinks.searchIcon} alt="icon" className="icon_search" />
                  <input
                    ref={inputRef}
                    disabled={false}
                    placeholder={props.searchBarPlaceholder}
                    className={`${
                      props.searchBarPlaceholder ? 'active' : 'in-active'
                    }`}
                    onChange={eventPersistAndHandleRequest}
                  />
                </div>
                <img
                  src={ImageLinks.closeMaterial}
                  alt="icon"
                  className={`${!inputRef.current?.value && 'stash'}`}
                  onClick={clearField}
                />
              </div>
            )}
            <div>
              {props.listToRender.length ? (
                props.listToRender.map((listItem: any) => props
                  .ListItem(listItem, props.selectedItems))
              ) : (
                <div className="no-result">
                  {(props.EmptyList) && props.EmptyList({})}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default FilterDropdown;
