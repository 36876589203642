/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SpanishLocale from 'date-fns/locale/es';
import format from 'date-fns/format';

import { getAcisIndex } from 'store/actions/safetyDashboard.action';

import './ACIsIndex.scss';

type State = {
  activeFilter: string;
};

type Props = {
  getAcisIndex(arg0: string): any;
  incidents: any;
  distCenter: string;
};

class ACIsIndex extends Component<Props, State> {
  static createTableColumns() {
    const columns = [] as any;

    columns.push({
      Header: 'Quién cometió',
      id: 'doneByString',
      accessor: (dataRow: any) => (dataRow.doneByString),
      Cell: (row: any) => (
        <p className="text-left" style={{ paddingLeft: 25 }}>
          {row.value === undefined ? '---' : row.value}
        </p>
      ),
      sortMethod: (a: any, b: any) => (a.name < b.name ? 1 : -1),
      style: { textAlign: 'left', fontWeight: 'lighter' },
      headerStyle: { fontWeight: 'bold' },
      width: 250,
    });

    columns.push({
      Header: <div className="text-center">Descripción <br />acto/condición</div>,
      id: 'description',
      accessor: (dataRow: any) => ({
        description: dataRow.description,
        type: dataRow.typesOfIssue,
      }),
      Cell: (row: any) => {
        let description = '';
        if (row.value.description) {
          description = row.value.description.length > 51
            ? `${row.value.description.substring(0, 50)}...`
            : row.value.description;
        }
        return (
          <div style={{ display: 'flex' }}>
            <div
              className="description-square"
              style={{
                backgroundColor: row.value.type === 'ACT'
                  ? '#2574FB' : '#70A5FF',
                width: '20px !important',
                height: '20px !important',
              }}
            >
              {row.value.type === 'ACT' ? 'A' : 'C'}
            </div>
            <p className="text-area" style={{ paddingLeft: 10 }}>{description}</p>
          </div>
        );
      },
      style: { textAlign: 'left', fontWeight: 'lighter' },
      headerStyle: { fontWeight: 'bold' },
      width: 400,
    });

    columns.push({
      Header: 'Lugar',
      id: 'place',
      accessor: (dataRow: any) => dataRow.workLocation && dataRow.workLocation.name,
      Cell: (row: any) => (
        <p className="text-left" style={{ paddingLeft: 25 }}>
          {row.value}
        </p>
      ),
      style: { textAlign: 'left', fontWeight: 'lighter' },
      headerStyle: { fontWeight: 'bold' },
      width: 165,
    });

    columns.push({
      Header: 'Inicio',
      id: 'createdAt',
      accessor: (dataRow: any) => dataRow.createdAt,
      Cell: (row: any) => (
        <p className="text-left" style={{ paddingLeft: 25 }}>
          {format(new Date(row.value), 'dd LLL yy', { locale: SpanishLocale })}
        </p>
      ),
      style: { textAlign: 'left', fontWeight: 'lighter' },
      headerStyle: { fontWeight: 'bold' },
      width: 120,
    });

    // columns.push({
    //   Header: 'Cierre',
    //   id: 'closedAt',
    //   accessor: (dataRow: any) => dataRow.closedAt,
    //   Cell: (row: any) => {
    //     const isClosed = !!row.value;
    //     return (
    //       <p className="text-left" style={{ paddingLeft: 25 }}>
    //         {isClosed ? format(new Date(row.value),
    //           'dd LLL yy',
    //           { locale: SpanishLocale }) : 'Sin cerrar'}
    //       </p>
    //     );
    //   },
    //   style: { textAlign: 'left', fontWeight: 'lighter' },
    //   headerStyle: { fontWeight: 'bold' },
    // });

    // columns.push({
    //   Header: '',
    //   id: 'closeAction',
    //   accessor: (dataRow: any) => dataRow,
    //   Cell: (row: any) => {
    //     const isClosed = !!row.value.closedAt;
    //     return (
    //       <CloseIncident id={row.value.id} isClosed={isClosed} />
    //     );
    //   },
    //   style: {
    //     textAlign: 'left',
    //     fontWeight: 'lighter',
    //     display: 'flex',
    //     alignItems: 'center',
    //     justifyContent: 'center',
    //   },
    //   headerStyle: { fontWeight: 'bold' },
    // });

    // DELETE WHEN THE PREVIOUS CODE IS UNCOMMENTED
    // columns.push({
    //   Header: 'Cierre',
    //   id: 'endDate',
    //   accessor: (dataRow: any) => dataRow.endDate,
    //   Cell: (row: any) => (
    //     <p className="text-left" style={{ paddingLeft: 25 }}>
    //       {row.value}
    //     </p>
    //   ),
    //   style: { textAlign: 'left', fontWeight: 'lighter' },
    //   headerStyle: { fontWeight: 'bold' },
    // });
    //
    // columns.push({
    //   Header: 'Responsable',
    //   id: 'responsibleUser',
    //   accessor: (dataRow: any) => dataRow.responsibleUser,
    //   Cell: (row: any) => (
    //     <p className="text-left" style={{ paddingLeft: 25 }}>
    //       {row.value}
    //     </p>
    //   ),
    //   style: { textAlign: 'left', fontWeight: 'lighter' },
    //   headerStyle: { fontWeight: 'bold' },
    //   width: 215,
    // });
    return columns;
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      activeFilter: 'Todos',
    };
  }

  componentDidMount() {
    this.props.getAcisIndex(this.props.distCenter);
  }

  // updateFilter(filter: string) {
  //   this.setState({ activeFilter: filter });
  // }

  render() {
    const { distCenter } = this.props;
    // TODO instead of the loading spinner in react table when doing cerrar, add a loading that
    // replaces cerrar button. Why this happens: we reset the safetyDashboard/acisIndex in reducer
    // before sending the server request. We need a different approach.
    const { activeFilter } = this.state;
    const { incidents } = this.props;
    const columns = ACIsIndex.createTableColumns();

    // TODO expensive. Use immutable .toArray then use immutable getters in createTableColumns()
    const allData: any = (incidents?.get('incidents')?.toJS()) || [];
    const actData = allData?.filter((entry: any) => entry.typesOfIssue === 'ACT');
    const conditionData = allData?.filter((entry: any) => entry.typesOfIssue === 'CONDITION');
    // TODO u can ready the data from the request group in the console.

    let data = [];
    switch (activeFilter) {
      case 'Todos':
        data = allData;
        break;
      case 'Actos':
        data = actData;
        break;
      default:
        data = conditionData;
    }

    return (
      <div />
    );
  }
}

function mapStateToProps(state: any) {
  return {
    incidents: state.getIn(['safetyDashboard', 'acisIndex']),
  };
}

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators({
    getAcisIndex,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ACIsIndex);
