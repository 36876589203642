import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fromJS } from 'immutable';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { useAsync } from 'react-async-hook';
import useConstant from 'use-constant';
import { useTranslation } from 'react-i18next';
import Disclaimer from 'components/Disclaimer/Disclaimer';
import MainNavigationHeader from 'components/MainNavigationHeader';
import ThreeDottedMenu from 'components/ThreeDottedMenu';
import InputWithIcon from 'components/Input/InputWithIcon';

import {
  downloadIncidents,
  getIncidents,
  getTotalIncidentsByTime,
} from 'store/actions/distributionCenter.actions';
import { setMonthlyDropdownChange } from 'store/actions/timeFilter.actions';
import { COLOR_CODE_NEW, TRANSLATIONS, USER_TYPES } from 'types/enums';
import searchIcon from 'assets/icons/search.svg';
import userIcon from 'assets/icons/user-icon.svg';
import eyeIcon from 'assets/icons/eye-icon.svg';
import editProfileIcon from 'assets/icons/edit-profile.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/log-out.svg';
import { useParams } from 'react-router-dom';
import './UserManagementDashboard.scss';
import request from 'utils/request';
import VerifyUser from '../VerifyUser';
import UserInfo from '../UserInfo';
import UserRegisterSuccess from '../UserRegisterSuccess';
import DeleteUser from '../DeleteUser/DeleteUser';

// eslint-disable-next-line @typescript-eslint/naming-convention
type props = {
  userName: string;
  hasManagmentAccess: boolean;
};

const UserManagementDashboard = (props: props) => {
  const { t } = useTranslation();
  const routeParams: any = useParams();
  const [selectedUserType, setSelectedUserType] = useState(USER_TYPES.EXTERNAL);

  const [initialUsersInternal, setInitialUsersInternal] = useState([]);
  const [initialUsersExternal, setInitialUsersExternal] = useState([]);

  const [searchedUsersInternal, setSearchedUsersInternal] = useState([]);
  const [searchedUsersExternal, setSearchedUsersExternal] = useState([]);

  const [toShowUsers, setToShowUsers] = useState(fromJS([]));

  const [showVerifyUser, setShowVerifyUser] = useState(false);

  const [tableLoading, setTableLoading] = useState(true);

  const [userInfoPopupConfig, setUserInfoPopupConfig] = useState({
    show: false,
    action: 'add-user',
    userData: {},
    source: 'verify-user',
    formTitle: t(TRANSLATIONS.EDIT_INFORMATION),
    distributionCenterSlug: routeParams?.distCenter,
  } as any);

  const [showPinPopup, setShowPinPopup] = useState({
    show: false,
    pin: 0,
    name: '',
    position: '',
    permissionsInString: '',
  } as any);

  const [showDeleteUser, setShowDeleteUser] = useState({
    show: false,
    user: {},
  } as any);

  const useDebouncedSearch = (searchFunction: (input: string, selectedUserType: string) => []) => {
    // Handle the input text state
    const [inputText, setInputText] = useState('');

    // Debounce the original search async function
    const debouncedSearchFunction = useConstant(() => AwesomeDebouncePromise(searchFunction, 300));

    // The async callback is run each time the text changes,
    // but as the search function is debounced, it does not
    // fire a new request on each keystroke
    const searchResults = useAsync(async () => {
      if (inputText.length === 0) {
        return [];
      }
      return debouncedSearchFunction(inputText, selectedUserType);
    }, [debouncedSearchFunction, inputText]);

    // Return everything needed for the hook consumer
    return {
      inputText,
      setInputText,
      searchResults,
    };
  };

  // @ts-ignore
  const useUserSearchHook = () => useDebouncedSearch(async (searchText, userType) => {
    if (!searchText.length) {
      return;
    }
    if (userType === USER_TYPES.EXTERNAL) {
      setSearchedUsersExternal([]);
    }
    if (userType === USER_TYPES.INTERNAL) {
      setSearchedUsersInternal([]);
    }
    setTableLoading(true);
    const url = `/api/v1/distribution-centers/${routeParams?.distCenter}/users?userType=${userType === USER_TYPES.EXTERNAL ? 'contractor' : 'internal'
    }&search=${searchText}`;
    request
      .get(url)
      .then(res => {
        if (userType === USER_TYPES.EXTERNAL) {
          setSearchedUsersExternal(fromJS(res.users));
        }
        if (userType === USER_TYPES.INTERNAL) {
          setSearchedUsersInternal(fromJS(res.users));
        }
        setTableLoading(false);
      })
      .catch(console.error);
  });
  const { inputText: searchText, setInputText: setSearchText } = useUserSearchHook();

  useEffect(() => {
    setSearchText('');
    setSearchedUsersInternal([]);
    setSearchedUsersExternal([]);
  }, [selectedUserType, setSearchText]);

  const getUsers = () => {
    request
      .get(`/api/v1/distribution-centers/${routeParams?.distCenter}/users?userType=contractor`)
      .then(res => {
        setInitialUsersExternal(fromJS(res.users));
        setTableLoading(false);
      })
      .catch(console.error);
  };

  const reloadUsersAfterEditting = () => {
    request
      .get(`/api/v1/distribution-centers/${routeParams?.distCenter}/users?userType=internal`)
      .then(res => {
        setInitialUsersInternal(fromJS(res.users));
        setTableLoading(false);
      })
      .catch(console.error);
  };

  // const [searchText, setSearchText] = useState('');

  useEffect(() => {
    request
      .get(`/api/v1/distribution-centers/${routeParams?.distCenter}/users?userType=internal`)
      .then(res => {
        setInitialUsersInternal(fromJS(res.users));
        setTableLoading(false);
      })
      .catch(console.error);
    getUsers();
  // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //
  // }, [searchText, selectedUserType]);

  // manager users to show based on external/internal or search
  useEffect(() => {
    const toShowInternalUsers = searchText.length ? searchedUsersInternal : initialUsersInternal;
    const toShowExternalUsers = searchText.length ? searchedUsersExternal : initialUsersExternal;

    setToShowUsers(
      selectedUserType === USER_TYPES.EXTERNAL ? toShowExternalUsers : toShowInternalUsers,
    );
    // eslint-disable-next-line max-len
  }, [
    searchText,
    searchedUsersExternal,
    setSearchedUsersInternal,
    initialUsersInternal,
    initialUsersExternal,
    searchedUsersInternal,
    selectedUserType,
  ]);

  const getSecurityPin = (user: any) => {
    if (selectedUserType === USER_TYPES.EXTERNAL) {
      if (user.get('pin')) return user.get('pin');
      if (user.get('email') && user.get('email').length > 3) {
        return t(TRANSLATIONS.EMAIL).toLowerCase();
      }
      return user.get('pin');
    }
    return user.get('employeeNumber');
  };

  return (
    <>
      <MainNavigationHeader
        configRoutes={[
          {
            url: `/distribution-centers/${routeParams?.distCenter}`,
            label: (
              /* use span since it's going to be inside of a p tag. */
              <span
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {t(TRANSLATIONS.ACIS)}
              </span>
            ),
          },
          {
            url: '/logout',
            label: (
              /* use span since it's going to be inside of a p tag. */
              <span
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {t(TRANSLATIONS.LOGOUT)}
                <LogoutIcon style={{ width: 15 }} />
              </span>
            ),
          },
        ]}
        userName={`${props.userName}`}
      />

      <div className="container main-content-margin dist">
        <div
          style={{
            marginTop: '30px',
          }}
        >
          <div className="card">
            <div className="userTypeContainer">
              <span
                role="button"
                onClick={() => {
                  setSelectedUserType(USER_TYPES.EXTERNAL);
                }}
                className={`userType ${selectedUserType === USER_TYPES.EXTERNAL ? 'selectedUserType' : ''
                }`}
              >
                {t(TRANSLATIONS.EXTERNAL)}
              </span>
              <span
                role="button"
                onClick={() => {
                  setSelectedUserType(USER_TYPES.INTERNAL);
                }}
                className={`userType ${selectedUserType === USER_TYPES.INTERNAL ? 'selectedUserType' : ''
                }`}
              >
                {t(TRANSLATIONS.INTERNAL)}
              </span>
            </div>
            <div className="buttonsContainer">
              <InputWithIcon
                value={searchText}
                imageStyles={{
                  top: '15px',
                  width: '15px',
                }}
                icon={searchIcon}
                placeholder={t(TRANSLATIONS.SEARCH_USERS)}
                activeBoxShadow="none"
                activeBorderColor={COLOR_CODE_NEW.PRIMARY}
                inactiveBorderColor={COLOR_CODE_NEW.BORDERS}
                onChange={e => {
                  setSearchText(e.target.value);
                }}
                style={{
                  borderRadius: 20,
                  border: `2px solid ${COLOR_CODE_NEW.BORDERS}`,
                  maxWidth: 'unset',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                }}
              />
            </div>
            <div className="usersTableContainer">
              <div className="userTableHeading">
                <div
                  style={{
                    flex: 1,
                  }}
                >
                  <img
                    alt="user"
                    src={userIcon}
                    style={{
                      width: '13px',
                      height: '17px',
                    }}
                  />
                </div>
                <span
                  className="positionColumn"
                >
                  {t(TRANSLATIONS.POSITION)}
                </span>
                <span
                  className="flexIdColumn"
                >
                  {t(TRANSLATIONS.QR_CODE)}
                </span>
                <span
                  className="flexEmailColumn"
                >
                  {t(TRANSLATIONS.EMAIL)}
                </span>
                {selectedUserType === USER_TYPES.EXTERNAL && (
                  <span
                    style={{
                      flex: 1,
                      textAlign: 'right',
                    }}
                  >
                    {t(TRANSLATIONS.ACCESS)}
                  </span>
                )}
                {selectedUserType === USER_TYPES.INTERNAL && (
                  <span
                    style={{
                      flex: 1,
                      textAlign: 'right',
                    }}
                  >
                    SAP/Sharp
                  </span>
                )}
                {props.hasManagmentAccess && (
                  <span
                    style={{
                      flex: 1,
                      textAlign: 'right',
                    }}
                  />
                )}
              </div>
              {tableLoading && <div>{t(TRANSLATIONS.LOADING)}...</div>}
              {!tableLoading && !toShowUsers.size && <div>0 {t(TRANSLATIONS.USERS)}</div>}
              {!tableLoading
                && toShowUsers.map((user: any, userIndex: number) => (
                  <div key={user.get('slug')} className="usersTableRow">
                    <span
                      style={{
                        flex: 1,
                      }}
                      className="userName"
                    >
                      {user.get('name')}
                    </span>
                    <span
                      className="flexPositionColumn"
                    >
                      {user.get('position')}
                    </span>
                    <span
                      className="slugColumn"
                    >
                      {user.get('shortSlug')}
                    </span>
                    <div
                      className="emailColumn"
                    >
                      <span className="userName">
                        {!(!user.get('pin') && user.get('email') && user.get('email').length > 3)
                          ? (user.get('showEmployeeNumber') ? getSecurityPin(user) : '******') : getSecurityPin(user)}
                      </span>
                      {
                        !(!user.get('pin') && user.get('email') && user.get('email').length > 3) && (
                          <img
                            src={eyeIcon}
                            style={{
                              marginLeft: '10px',
                            }}
                            onClick={() => {
                              setToShowUsers(toShowUsers.setIn([userIndex, 'showEmployeeNumber'], !user.get('showEmployeeNumber')));
                            }}
                            alt="Show"
                          />
                        )
                      }
                    </div>
                    {(props.hasManagmentAccess || selectedUserType === USER_TYPES.EXTERNAL) && (
                      <div>
                        <ThreeDottedMenu
                          options={
                            selectedUserType === USER_TYPES.EXTERNAL
                              ? [
                                {
                                  image: editProfileIcon,
                                  text: t(TRANSLATIONS.EDIT_INFORMATION),
                                  onClick: () => {
                                    setUserInfoPopupConfig({
                                      show: true,
                                      action: 'edit-user',
                                      userData: user.toJS(),
                                      source: 'verify-user',
                                      formTitle: t(TRANSLATIONS.EDIT_INFORMATION),
                                      distributionCenterSlug: routeParams?.distCenter,
                                      successBtnText: t(TRANSLATIONS.CONFIRM),
                                    });
                                  },
                                },
                              ]
                              : [
                                {
                                  image: editProfileIcon,
                                  text: t(TRANSLATIONS.EDIT_INFORMATION),
                                  onClick: () => {
                                    setUserInfoPopupConfig({
                                      show: true,
                                      action: 'edit-user',
                                      userData: user.toJS(),
                                      source: 'verify-user',
                                      formTitle: t(TRANSLATIONS.EDIT_INFORMATION),
                                      distributionCenterSlug: routeParams?.distCenter,
                                      successBtnText: t(TRANSLATIONS.CONFIRM),
                                    });
                                  },
                                },
                              ]
                          }
                        />
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="disclaimer-wrapper">
          <Disclaimer message={TRANSLATIONS.USER_MISSING_DISCLAIMER} />
        </div>
      </div>
      {showVerifyUser && !userInfoPopupConfig.show && !showPinPopup.show && !showDeleteUser.show
      && (
        <VerifyUser
          distributionCenterSlug={routeParams?.distCenter}
          setUserInfoPopupConfig={config => {
            setShowVerifyUser(false);
            setUserInfoPopupConfig(config);
          }}
          close={() => {
            setShowVerifyUser(false);
          }}
        />
      )}
      {!showVerifyUser && userInfoPopupConfig.show && !showPinPopup.show && !showDeleteUser.show
      && (
        <UserInfo
          setUserInfoPopupConfig={setUserInfoPopupConfig}
          config={userInfoPopupConfig}
          setShowPinPopup={config => {
            setUserInfoPopupConfig({
              show: false,
            });
            setShowPinPopup(config);
          }}
          updateData={getUsers}
          reloadUsers={reloadUsersAfterEditting}
          hasManagmentAccess={props.hasManagmentAccess}
        />
      )}

      {showPinPopup.show && !showVerifyUser && !userInfoPopupConfig.show && !showDeleteUser.show
      && (
        <UserRegisterSuccess
          pin={showPinPopup.pin}
          name={showPinPopup.name}
          position={showPinPopup.position}
          permissionsInString={showPinPopup.permissionsInString}
          close={() => {
            setShowPinPopup({
              show: false,
            });
          }}
        />
      )}
      {!showPinPopup.show && !showVerifyUser && !userInfoPopupConfig.show && showDeleteUser.show
      && (
        <DeleteUser
          close={() => {
            setShowDeleteUser({
              show: false,
            });
          }}
          user={showDeleteUser.user}
        />
      )}
    </>
  );
};

function mapStateToProps(state: any) {
  return {
    userName: state.getIn(['user', 'name']),
    hasManagmentAccess: state.getIn(['user', 'hasUserManagementAccess']),
  };
}

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators(
    {
      getIncidents,
      downloadIncidents,
      getTotalIncidentsByTime,
      setMonthlyDropdownChange,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(UserManagementDashboard);
