import EmptyState from 'components/EmptyState/EmptyState';
import LoadingSpinner from 'components/LoadingSpinner';
import { FilterParams, GetSifStatsBy, useGetSifStatsQuery } from 'generated/graphql';
import React from 'react';
import Chart from 'react-apexcharts';
import { capitalizeFirst } from 'utils/stringHelpers';

const HorizontalBarByMonth = (
  { by, title, filter, year }: {
    by: GetSifStatsBy, title: string, filter: FilterParams, year: number
  },
) => {
  const { data: chartData, loading, error } = useGetSifStatsQuery({
    variables: {
      filter,
      by,
      year,
    },
  });

  const categories: string[] = [];
  const values: number[] = [];

  if (chartData?.getSifStats) {
    chartData.getSifStats.forEach(chartDataItem => {
      categories.push(chartDataItem?.name || '');
      values.push(chartDataItem?.totalSif || 0);
    });
  }
  const series = [{
    data: values,
  }];
  const options = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      enabled: false,
    },
    colors: ['#777DB9'],
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (val: number) => {
        return val.toLocaleString();
      },
      textAnchor: 'middle',
      offsetY: 6,
    },
    xaxis: {
      categories,
      labels: {
        show: true,
      },
    },
    yaxis: {
      categories,
      labels: {
        show: true,
        formatter: (val: string) => {
          return capitalizeFirst(val);
        },
      },
    },
  };
  if (error) return <h5> Something Went Wrong. Please Try Again  </h5>;
  if (loading) return <LoadingSpinner height={200} />;
  if (chartData?.getSifStats.length === 0) return <EmptyState />;
  return (
    <>
      <h4> {title} </h4>
      {/* @ts-ignore */}
      <Chart type="bar" height={350} series={series} options={options} />
    </>
  );
};

export default HorizontalBarByMonth;
