import React, { useState } from 'react';
import { withStyles, makeStyles, createStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ArrowUpwardIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDropDown';

type RowData = {
  rowData: any[];
  index: string;
};

type CustomTableProps = {
  header: string[];
  onClickRow: (id: string) => void;
  rows: RowData[];
  sortableColumns?: number[]; // Array of column indices that are sortable
};

const StyledTableCell = withStyles(() => createStyles({
  head: {
    backgroundColor: '#fff',
    color: '#B5B5B5',
    cursor: 'pointer',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottomWidth: '2px',
    borderBottomColor: '#D6D6D6',
    fontSize: '14px',
    fontFamily: 'Jost',
  },
  body: {
    fontSize: 17,
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottomWidth: '1px',
    borderBottomColor: '#DEDEDE',
  },
}))(TableCell);

const StyledTableRow = withStyles(() => createStyles({
  root: {
    '&:hover': {
      backgroundColor: '#FAFAFA',
      cursor: 'pointer',
    },
    '&:last-child td': {
      borderBottom: 'none',
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    borderCollapse: 'collapse',
  },
  arrowIcon: {
    fontSize: '2em',
    marginLeft: '0.2em',
  },
});

const CustomTable: React.FC<CustomTableProps> = (
  { header, rows, onClickRow, sortableColumns = [] },
) => {
  const classes = useStyles();
  const [sortConfig, setSortConfig] = useState<{ key: string, direction: 'ascending' | 'descending' | null }>({ key: '', direction: 'ascending' });

  const sortedRows = [...rows].sort((a, b) => {
    if (!sortConfig.key || sortConfig.direction === null) {
      return 0;
    }
    const columnIdx = Number(sortConfig.key);
    if (sortConfig.direction === 'ascending') {
      if (a.rowData[columnIdx] < b.rowData[columnIdx]) return -1;
      if (a.rowData[columnIdx] > b.rowData[columnIdx]) return 1;
      return 0;
    }
    if (sortConfig.direction === 'descending') {
      if (a.rowData[columnIdx] > b.rowData[columnIdx]) return -1;
      if (a.rowData[columnIdx] < b.rowData[columnIdx]) return 1;
      return 0;
    }
    return 0;
  });

  const requestSort = (key: string) => {
    let direction: 'ascending' | 'descending' | null = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const renderHeaderCells = () => {
    return header.map((headerLabel, index) => {
      const isSortable = sortableColumns.includes(index);
      const isSortingColumn = sortConfig.key === String(index);
      const arrowIcon = () => {
        if (isSortable && isSortingColumn) {
          return sortConfig.direction === 'ascending' ? <ArrowUpwardIcon className={classes.arrowIcon} /> : <ArrowDownwardIcon className={classes.arrowIcon} />;
        }
        return null;
      };
      return (
        (
          <StyledTableCell
            key={headerLabel}
            onClick={() => {
              if (sortableColumns.includes(index)) {
                requestSort(String(index));
              }
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {headerLabel}
              {arrowIcon()}
            </div>
          </StyledTableCell>
        )
      );
    });
  };

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            {renderHeaderCells()}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedRows.map(row => (
            <StyledTableRow onClick={() => onClickRow(row.index)} key={row.index}>
              {row.rowData.map((cellLabel, index) => (
                <StyledTableCell key={`${row.index}-${index}`}>{cellLabel}</StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomTable;
