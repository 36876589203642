import React, { FC } from 'react';
import './OutlineButton.scss';
import { BasicButtonProps } from 'components/Button/BasicButton/BasicButton';

const OutlineButton: FC<Props> = (props: Props) => {
  const {
    text, style = {}, type = 'button', onClick = () => {}, textStyle, disabled = false, ...rest
  } = props;

  return (
    <button
      className="outline-button"
      style={{
        ...style,
        ...(disabled ? { opacity: 0.5 } : {}),
      }}
      onClick={() => !disabled && onClick()}
      type={type}
      {...rest}
    >
      <span className="pill-button-text" style={textStyle}>{text}</span>
    </button>
  );
};

interface Props extends BasicButtonProps {
  type?: 'button' | 'submit' | 'reset';
  textStyle?: any;
  disabled?: boolean;
}

export default OutlineButton;
