import LoadingSpinner from 'components/LoadingSpinner';
import React, { useEffect, useMemo, useState } from 'react';
// @ts-ignore
import ReactTable from 'react-table-legacy';
import EmptyState from 'components/EmptyState/EmptyState';
import Modal from 'components/Modal';
import ImageLinks from 'utils/ImageLinks';
import { Tooltip } from '@mui/material';

import { COMMENT_CREATE_TYPE } from 'types/enums';
import { useAddOrEditCapexCommentMutation, useCapexCommentsQuery, useDeleteCapexCommentMutation } from 'generated/graphql';
import './CapexRegionalManagerTable.scss';
import CommentSection from 'components/CommentSection';
import { CapexProvider, useCapexContext } from 'features/analyticsWrapper/Capex/CapexTable/context';
import postCapexApi from '../CapexApi/CapexPopApi';
import getCapexApi from '../CapexApi/GetCapexApi';
import { capexCommentRefetchQueries } from '../capex.helper';

type ResponseDataType = {
  id?:string;
  projectName: string;
  projectCode: string;
  progress: string;
  bu: string;
  usedBudget: number;
  totalBudget: number;
  usedBudgetPercent: string;
  country: string;
  endDate: string;
};
type DataPopup = {
  id?:string;
  projectName?: string;
  projectCode?: string;
  country?: string;
  bu?: string;
  endDate?: string;
  usedBudget?: number;
  totalBudget?: number;
  usedBudgetPercent?: string;
};

type Props = {
  searchField: string;
  type: string;
};
const CapexRegionalManagerTable = (props: Props) => {
  const { searchField } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<ResponseDataType[]>();
  const [popup, setPopup] = useState<boolean>(false);
  const [popupData, setPopupData] = useState<ResponseDataType>();
  const [popupName, setPopupName] = useState<string>('');
  const [popupCode, setPopupCode] = useState<string>('');
  let filteredRegionalManager;
  useEffect(() => {
    const getCapexTable = async () => {
      try {
        const url: string = '/api/v1/capex-project-details';
        const response: ResponseDataType[] = await getCapexApi(url, 'byBudget');
        setData(response);
      } catch (error) {
        console.log('getCapexTable e()', error);
      }
    };
    getCapexTable();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    const getCapexPopupAPI = async () => {
      const url: string = '/api/v1/capex-project';
      const res: ResponseDataType = await postCapexApi(url, popupName, popupCode).finally(() => {
        setIsLoading(false);
      });

      setPopupData(res);
    };
    getCapexPopupAPI();
    // eslint-disable-next-line
  }, [popup]);

  const OpenModal = (row: DataPopup) => {
    if (row.projectName && row.projectCode) {
      setPopupName(row.projectName);
      setPopupCode(row.projectCode);
      setPopup(true);
    }
    const ClosePopup = () => {
      setPopup(false);
    };
    return (
      <CapexProvider capex={{ popupData, id: popupData?.id }}>
        <Modal onOverlayClick={ClosePopup}>
          {popupData && popupData !== undefined
            && (
              isLoading ? <div style={{ padding: '40px' }}><LoadingSpinner /></div>
                : (
                  <>
                    <div>
                      <div className="capex-close" onClick={ClosePopup} role="button">&times;</div>
                      <div className="capex-popup-header">
                        <div>
                          <p className="font-weight700 font-size20"> {popupData.projectName} </p>
                          <p className="font-weight400 font-size14">
                            {popupData.bu}: {popupData.country}
                          </p>
                        </div>
                        <div className="font-weight400 font-size16"> Fecha final del proyecto:
                          <div className="font-weight700 font-size16"> {popupData.endDate} </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <Tooltip
                        title={`% de Implementacion actual: ${((popupData.usedBudget / popupData.totalBudget) * 100).toFixed(2)}%`}
                        arrow
                        placement="top"
                      >
                        <div className="capex-popup-bar">
                          <div className="capex-popup-progress-bar" style={{ width: `${(popupData.usedBudget / popupData.totalBudget) * 100}%` }} />
                        </div>
                      </Tooltip>
                      <div className="capex-popup-details">
                        <p className="font-weight400 font-size16"> % de Implementacion actual:
                          <div className="font-weight700 font-size16">
                            {popupData.usedBudgetPercent}
                          </div>
                        </p>
                        <p className="font-weight400 font-size14 capex-popup-second-color"> Presupuesto total $ {popupData.totalBudget}  </p>
                      </div>
                      <p>Comentarios:</p>
                      <CommentSection
                        type={COMMENT_CREATE_TYPE.CAPEX}
                        customContext={useCapexContext}
                        createEditMuation={useAddOrEditCapexCommentMutation}
                        refetchQueries={capexCommentRefetchQueries}
                        editCommentsMutation={useAddOrEditCapexCommentMutation}
                        deleteCommentsMutation={useDeleteCapexCommentMutation}
                        detailedCommentsQuery={useCapexCommentsQuery}
                        dynamicKeyName="capexId"
                        errorMessage="deleteCapexComment e()"
                        requestIdKey="capexId"
                        responseKey="capexComments"
                      />
                    </div>
                  </>
                )
            )}
        </Modal>
      </CapexProvider>
    );
  };

  const budgetColumns = useMemo(() => [
    {
      Header: 'Proyecto',
      minWidth: 110,
      id: 'projectName',
      accessor: 'projectName',
      Cell: (row: any) => (
        <p className="text-left font-bold margin-left-25">
          {row.value}
        </p>
      ),
    },
    {
      Header: 'BU',
      minWidth: 110,
      id: 'bu',
      accessor: 'bu',
      Cell: (row: any) => (
        <p className="text-left font-bold margin-left-25">
          {row.value}
        </p>
      ),
    },
    {
      Header: 'CAPEX',
      id: 'totalBudget',
      accessor: 'totalBudget',
      minWidth: 95,
      Cell: (row: any) => (
        <p className="text-left font-bold margin-left-25">
          {row.value.toLocaleString()}
        </p>
      ),
    },
    {
      Header: 'Gasto',
      id: 'usedBudget',
      accessor: 'usedBudget',
      Cell: (row: any) => (
        <p className="text-left font-bold margin-left-25">
          {row.value.toLocaleString()}
        </p>
      ),
    },
    {
      Header: '% Gasto Ejercido',
      id: 'progress',
      accessor: 'progress',
      Cell: (row: any) => (
        <p className="text-center font-bold cell-two-items">
          <div className="text-center">{Number((row.value?.split('%')[0] || 0) * 100).toFixed(2)}%</div>
          <img className="cell-chevron" src={ImageLinks.chevron_right} alt="upload file" onClick={() => OpenModal(row.original)} />
        </p>
      ),
    },
    // eslint-disable-next-line
  ], [popup, props]);

  const projectColumns = useMemo(() => [
    {
      Header: 'Proyecto',
      minWidth: 110,
      id: 'projectName',
      accessor: 'projectName',
      Cell: (row: any) => (
        <p className="text-left font-bold margin-left-25">
          {row.value}
        </p>
      ),
    },
    {
      Header: 'BU',
      minWidth: 110,
      id: 'bu',
      accessor: 'bu',
      Cell: (row: any) => (
        <p className="text-left font-bold margin-left-25">
          {row.value}
        </p>
      ),
    },
    {
      Header: '% Gasto Ejercido',
      id: 'progress',
      accessor: 'progress',
      Cell: (row: any) => (
        <p className="text-center font-bold cell-two-items">
          <div className="text-center"> {`${row.value}`} </div>
          <img className="cell-chevron" src={ImageLinks.chevron_right} alt="upload file" onClick={() => OpenModal(row.original)} />
        </p>
      ),
    },
    // eslint-disable-next-line
  ], [popup, props]);

  if (data && data !== undefined && searchField) {
    filteredRegionalManager = data.filter(
      // eslint-disable-next-line max-len
      (regionalManager: { projectName: string; bu: string; }) => regionalManager.projectName.toLowerCase().includes(searchField.toLowerCase())
        || regionalManager.bu.toLowerCase().includes(searchField.toLowerCase()),
    );
  }

  const handleEmptyData = () => {
    return (<EmptyState />);
  };

  return (
    <div className="table-by-regional-manager">
      {popup && <OpenModal />}
      <ReactTable
        data={filteredRegionalManager || data}
        columns={props.type === 'byBudget' ? budgetColumns : projectColumns}
        showPagination={false}
        defaultPageSize={data?.length}
        className="-striped max-height"
        NoDataComponent={handleEmptyData}
      />
    </div>
  );
};

export default CapexRegionalManagerTable;
