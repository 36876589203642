import LoadingSpinner from 'components/LoadingSpinner'; // eslint-disable-line
import React, { useMemo } from 'react';
import EmptyState from 'components/EmptyState/EmptyState';
import ErrorMessage from 'components/ErrorMessage';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';
// @ts-ignore
import ReactTable from 'react-table-legacy';
import { useSelector } from 'react-redux';
import selectDriverManagementSystem from 'store/selectors/driverManagementSystem.selectors';
import {
  customDMSDataSelectorByYearMonthTypeBuAndSubType,
  customDMSDataSelectorByYearMonthTypeAndSubType,
  DMSSelectorTypes,
  DMSSelectorSubTypes,
} from '../dmsHelper';
import './VehicleTopOffendersDMSStyles.scss';

type Props = {
  bu?: string,
  loading: boolean;
  month: string;
  year: number;
  error: boolean;
};

const VehicleTopOffenders = ({ bu, loading, month, year, error }: Props) => {
  const { t } = useTranslation();

  const driverManagementSystemData = useSelector(selectDriverManagementSystem);

  const tableData = useMemo(() => {
    if (!loading) {
      let selectedMonthData;

      if (bu) {
        selectedMonthData = customDMSDataSelectorByYearMonthTypeBuAndSubType(
          driverManagementSystemData,
          year,
          month,
          DMSSelectorTypes.tablePerBu,
          bu,
          DMSSelectorSubTypes.topVehicleOffenders,
        );
      } else {
        selectedMonthData = customDMSDataSelectorByYearMonthTypeAndSubType(
          driverManagementSystemData,
          year,
          month,
          DMSSelectorTypes.table,
          DMSSelectorSubTypes.topVehicleOffenders,
        );
      }
      if (selectedMonthData) {
        return selectedMonthData.slice(0, 10);
      }
      return [];
    }
    return [];
  }, [bu, driverManagementSystemData, loading, month, year]);

  const title = `${t(TRANSLATIONS.VEHICLE)}: Top Offenders`;
  const color = '#B12419';

  const columns = [
    {
      id: 'number',
      accessor: 'number',
      minWidth: 45,
      Cell: (row: any) => (
        <p className="text-left row-data margin-left-25">
          {row.index + 1}
        </p>
      ),
    },
    {
      Header: () => <span className="font-bold">BU</span>,
      id: 'bu',
      accessor: 'bu',
      minWidth: 95,
      Cell: (row: any) => (
        <p className="text-left row-data margin-left-26">
          {row.value}
        </p>
      ),
    },
    {
      Header: () => <span className="font-bold">VH</span>,
      id: 'name',
      accessor: 'name',
      Cell: (row: any) => (
        <p className="text-left row-data margin-left-26">
          {row.value}
        </p>
      ),
    },
    {
      Header: () => <span className="font-bold">Registros</span>,
      id: 'totalIncidents',
      accessor: 'totalIncidents',
      Cell: (row: any) => {
        return (
          <p style={{ color }} className="text-left margin-left-25">
            {`${(row.value || 0).toLocaleString()}`}
          </p>
        );
      },
    },
  ];

  if (error) return <ErrorMessage />;
  if (loading) {
    return (
      <div>
        <LoadingSpinner height={271} />
      </div>
    );
  }
  if (!tableData.length) {
    return (
      <div className="overview-locations-top-bottom">
        <EmptyState />
      </div>
    );
  }

  return (
    <div className="dms">
      <div className="top-vechile-offenders">
        <span className="top-vechile-offenders__title" style={{ color }}>{title}</span>
        <ReactTable
          data={tableData}
          columns={columns}
          showPagination={false}
          defaultPageSize={tableData?.length}
          className="-striped"
          key={bu}
          NoDataComponent={() => <EmptyState />}
        />
      </div>
    </div>
  );
};

export default VehicleTopOffenders;
