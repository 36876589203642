import React from 'react';
import Chart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import { selectLocationsToAccess } from 'store/selectors/globalFilters.selectors';
import { DefaultOptions } from 'utils/ChartOptions';
import { useCubeQuery } from '@cubejs-client/react';
// import { groupBy } from 'lodash';
import { Query } from '@cubejs-client/core';
import getAccidentabilityQuery from 'utils/CubeObject';
import LoadingSpinner from 'components/LoadingSpinner';
import { TRANSLATIONS } from 'types/enums';
import { useTranslation } from 'react-i18next';
import { getBusinessCalenderInfo, getWeeksInfoForBusinessCalender } from 'utils/dateHelpers';
import { format, getMonth, getYear } from 'date-fns';
import { addDays } from 'date-fns/esm';
import { capitalizeFirst } from 'utils/stringHelpers';
import EmptyState from 'components/EmptyState/EmptyState';

const DATE_INFO = new Array(12).fill(null).map((_, index) => ({
  monthNum: index,
  month: capitalizeFirst(format(new Date(1997, index, 1), 'MMMM')),
  theoricValue: 0,
  completion: 0,
  realValue: 0,
}));
interface Paramters {
  bu?: string;
  dateRange: string[];
  months: string | string[];
  selectedPillar: string
  allPillarIds: string[]
}

const StackedBarWithLine = ({
  bu,
  dateRange,
  months,
  selectedPillar,
  allPillarIds,
}: Paramters) => {
  const locationsToAccess = useSelector(selectLocationsToAccess);
  const { resultSet: theoricRoutines, isLoading: isLoadingTheoric } = useCubeQuery(
    getAccidentabilityQuery({
      dimensions: ['TheoricRoutinesCompliance.frequency'],
      measure: ['TheoricRoutinesCompliance.count'],
      dateOptions: undefined,
      filterOptions: [{
        dimension: 'TheoricRoutinesCompliance.locations',
        operator: 'equals',
        values: locationsToAccess ?? [],
      },
      {
        dimension: 'TheoricRoutinesCompliance.pillarId',
        operator: 'equals',
        values: selectedPillar === '' ? allPillarIds : [selectedPillar],
      }],
    }) as Query,
  );

  let theoricTotalMonthFourWeeks = 0;
  let theoricTotalMonthFiveWeeks = 0;

  const { t } = useTranslation();

  theoricRoutines?.tablePivot().forEach(item => {
    const count = item['TheoricRoutinesCompliance.count'] as number;
    const typeFrequency = item['TheoricRoutinesCompliance.frequency'] as string;

    if (typeFrequency === '0 0 * * *') {
      theoricTotalMonthFourWeeks += count * 6 * 4;
      theoricTotalMonthFiveWeeks += count * 6 * 5;
    }

    if (typeFrequency === '0 0 * * 0') {
      theoricTotalMonthFourWeeks += count * 4;
      theoricTotalMonthFiveWeeks += count * 5;
    }

    if (typeFrequency === '0 0 1 * *') {
      theoricTotalMonthFourWeeks += count;
      theoricTotalMonthFiveWeeks += count;
    }
  });

  DATE_INFO.forEach(i => {
    const selectedYear = getYear(addDays(new Date(dateRange[0]), 10));
    const calenderWeeksInfo = getWeeksInfoForBusinessCalender(i.monthNum, selectedYear);
    if (calenderWeeksInfo.weeks === 4) i.theoricValue = theoricTotalMonthFourWeeks;
    if (calenderWeeksInfo.weeks === 5) i.theoricValue = theoricTotalMonthFiveWeeks;
  });

  const { resultSet: realRoutines, isLoading: isLoadingReal } = useCubeQuery({
    measures: ['RealRoutinesCompliance.count'],
    timeDimensions: [{
      dimension: 'RealRoutinesCompliance.date',
      dateRange,
      granularity: 'day',
    }],
    dimensions: [
      'RealRoutinesCompliance.frequency',
      'RealRoutinesCompliance.month',
      'RealRoutinesCompliance.buName',
    ],
    filters: [
      {
        dimension: 'RealRoutinesCompliance.locations',
        operator: 'equals',
        values: locationsToAccess ?? [],
      },
      {
        dimension: 'RealRoutinesCompliance.buName',
        operator: 'contains',
        values: bu ? [bu] : [],
      },
      {
        dimension: 'RealRoutinesCompliance.pillarId',
        operator: 'equals',
        values: selectedPillar === '' ? allPillarIds : [selectedPillar],
      },
    ],
    order: {
      'RealRoutinesCompliance.count': 'desc',
    },
  });
  DATE_INFO.forEach(i => { i.realValue = 0; });

  let compliancePercentages;
  if (realRoutines?.tablePivot()) {
    realRoutines.tablePivot()
      .forEach(item => {
        const count = (item['RealRoutinesCompliance.count'] as number);
        const routineDate = new Date(item['RealRoutinesCompliance.date.day'] as string);
        const routineMonth = getBusinessCalenderInfo(routineDate).month;
        const currentInfo = DATE_INFO.find(
          info => info.monthNum === getMonth(new Date(1997, routineMonth, 12)),
        );
        if (currentInfo) {
          currentInfo.realValue += count;
        }
      });
    // console.log({DATE_INFO});
    compliancePercentages = DATE_INFO.map(i => {
      let completion = ((i.realValue / i.theoricValue) * 100);
      const percentageRest = 100 - completion;
      if (percentageRest < 0) completion += Math.abs(percentageRest);

      return {
        month: i.month,
        percentageCompliance: completion.toFixed(2),
        percentageRest: (percentageRest < 0) ? 0 : percentageRest.toFixed(2),
      };
    });
  }
  const firstMonth = months[0];
  const lastMonth = months[months.length - 1];

  const start = (compliancePercentages?.findIndex(i => i.month === firstMonth) as number);
  const end = (compliancePercentages?.findIndex(i => i.month === lastMonth) as number);

  const finalData = compliancePercentages?.slice(start, end + 1).filter(data => data.percentageCompliance !== 'NaN' && data.percentageRest !== 'NaN');
  const executedRoutines = finalData?.map(i => i.percentageCompliance);
  const pendingRoutines = finalData?.map(i => i.percentageRest);
  const monthList = finalData?.map(i => i.month);
  // console.log({executedRoutines, pendingRoutines});

  const options = {
    chart: {
      type: 'line',
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    colors: ['#5572B9', '#A33223', '#DEA153'],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10,
      },
    },
    fill: {
      opacity: 1,
      colors: ['#5572B9', '#A33223', '#DEA153'],
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [2],
      formatter(val: number) {
        return `${val.toFixed(2)} %`;
      },
    },
    stroke: {
      ...DefaultOptions.stroke,
    },
    xaxis: {
      categories: monthList ?? [],
    },
    yaxis: {
      max: 100,
      min: 0,
    },
    legend: {
      show: true,
      markers: {
        fillColors: ['#5572B9', '#A33223', '#DEA153'],
      },
    },
  };

  const series = [{
    name: t(TRANSLATIONS.ROUTINE_COMPLIANCE_EXECUTED),
    data: executedRoutines,
    type: 'column',
  }, {
    name: t(TRANSLATIONS.ROUTINE_COMPLIANCE_PENDING),
    data: pendingRoutines,
    type: 'column',
  }, {
    name: t(TRANSLATIONS.ROUTINE_COMPLIANCE_COMPLETED_PERCENTAGE),
    data: executedRoutines,
    type: 'line',
  }];

  if (isLoadingTheoric || isLoadingReal) {
    return (
      <div>
        <LoadingSpinner height={271} />
      </div>
    );
  }
  if (!finalData?.length) return <EmptyState />;

  return (
    <div>
      <Chart
        type="bar"
        // @ts-ignore
        options={options}
        series={series}
        height="300px"
      />
    </div>
  );
};

export default StackedBarWithLine;
