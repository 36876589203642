import { useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';

const { CancelToken } = axios;

export enum UploadErrorMessage {
  CANCEL_FILE_UPLOAD = 'CANCEL_FILE_UPLOAD',
  FAILED_TO_FETCH_S3_URL = 'FAILED_TO_FETCH_S3_URL',
}
interface UploadStatus {
  uploadingPercent: number;
  cancelFileUpload: ((message: string) => void) | null;
  uploaded: boolean;
  isUploading: boolean;
  errorMessage: string;
}

const useFileUploader = (): [
  UploadStatus,
  ({ s3FileUrl, data }: { s3FileUrl: string | undefined; data: File }) => Promise<void>,
  () => void,
] => {
  const [status, setStatus] = useState<UploadStatus>({
    uploadingPercent: 0,
    cancelFileUpload: null,
    uploaded: false,
    isUploading: false,
    errorMessage: '',
  });
  const { t } = useTranslation();

  const execute = async ({ s3FileUrl, data }: { s3FileUrl: string | undefined; data: File }) => {
    try {
      if (!s3FileUrl) {
        throw new Error(UploadErrorMessage.FAILED_TO_FETCH_S3_URL);
      }
      setStatus(previousState => ({
        ...previousState,
        isUploading: true,
        uploaded: false,
        errorMessage: '',
        cancelFileUpload: null,
        uploadingPercent: 0,
      }));
      await axios.put(s3FileUrl, data, {
        onUploadProgress: (ProgressEvent: { loaded: number; total: number }) => {
          const percentCompleted = Math.round((ProgressEvent.loaded * 100) / ProgressEvent.total);
          setStatus(previousState => ({
            ...previousState,
            uploadingPercent: status.uploadingPercent + percentCompleted,
          }));
        },
        cancelToken: new CancelToken((c: any) => {
          setStatus(previousState => ({
            ...previousState,
            cancelFileUpload: c,
          }));
        }),
      });

      setStatus(previousState => ({
        ...previousState,
        uploadingPercent: 100,
        uploaded: true,
        isUploading: false,
      }));
    } catch (e) {
      setStatus(previousState => ({
        ...previousState,
        uploaded: false,
        errorMessage: (e as Error).message === UploadErrorMessage.CANCEL_FILE_UPLOAD
          ? ''
          : (e as Error).message || t(TRANSLATIONS.SERVER_ERROR),
        isUploading: false,
        uploadingPercent: 0,
      }));
      if ((e as Error).message !== UploadErrorMessage.CANCEL_FILE_UPLOAD) {
        console.log('uploadFile e()', (e as Error).message);
      }
    }
  };

  const cancelUpload = () => {
    if (status.cancelFileUpload) {
      status.cancelFileUpload(UploadErrorMessage.CANCEL_FILE_UPLOAD);
    }
    setStatus({
      uploaded: false,
      uploadingPercent: 0,
      cancelFileUpload: () => {},
      errorMessage: '',
      isUploading: false,
    });
  };

  return [status, execute, cancelUpload];
};

export default useFileUploader;
