import React from 'react';

import videoIcon from 'assets/icons/video.svg';

import axios from 'axios';
import './ListItemHorizontal.scss';

type Props = {
  title: string,
  subtitle:string,
  onClick: () => void,
  hasTemplate: boolean,
  hideFileInput: boolean
};

const ListItemHorizontal = (
  {
    title,
    subtitle,
    onClick,
    hasTemplate,
    hideFileInput,
  } : Props,
) => {
  const handleSubmit = (event: any) => {
    event.preventDefault();

    const data = new FormData(event.target);

    data.set('fileType', 'CASHLESS');

    const config = {
      headers: { 'content-type': 'multipart/form-data' },
    };

    axios.post('api/v1/analytics-uploads', data, config)
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <form className="list-item-horizontal" onSubmit={handleSubmit}>
      <div className="list-item-horizontal__left">
        <p>{title}</p>
        <span>{subtitle}</span>
        {hasTemplate && <button type="button">Descargar plantilla ejemplo</button>}
      </div>
      <div className="list-item-horizontal__right">
        {!hideFileInput && <input name="analytics" type="file" />}
        <button
          type="submit"
          onClick={onClick}
        >
          <img src={videoIcon} alt="cargar icon" />Cargar archivo
        </button>
      </div>
    </form>
  );
};

export default ListItemHorizontal;
