import React, { useEffect, useState } from 'react';
import LoadingSpinner from 'components/LoadingSpinner';
import CapexBars from './CapexChart';
import getCapexApi from './CapexApi/GetCapexApi';
import './Capex.scss';

type Data = {
  usedBudget: number;
  totalBudget: number;
  usedBudgetPercent: number;
  avgImplementation: number;
};
const CapexByMonthsOrProjects = (props: { selected: number; type: string }) => {
  const [data, setData] = useState<Data>();
  const [isLoading, setIsloading] = useState<boolean>(false);
  useEffect(() => {
    const getCapexByMonthsOrProjects = async () => {
      try {
        setIsloading(true);
        const params: string = props.type;
        let url:string;
        if (props.selected === 1) url = '/api/v1/capex-by-months';
        else url = '/api/v1/capex-by-projects';
        const response: Data = await getCapexApi(url, params);
        setData(response);
      } catch (error) {
        console.log('getCapexByMonthsOrProjects e()', error);
      } finally {
        setIsloading(false);
      }
    };
    getCapexByMonthsOrProjects();
  }, [props.selected, props.type]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="row container-fluid">
      {!!data && Object.values(data).map((row, index) => {
        const budget: number[] = [];
        const usedAmount: number[] = [];
        const difference: number[] = [];
        const countries = Object.keys(row);
        Object.entries(row).forEach((Obj: any) => {
          const objData = Obj[1] as Data;
          if (Object.keys(objData).length > 0) {
            budget.push(props.type === 'byBudget' ? objData.totalBudget : 100);
            usedAmount.push(props.type === 'byBudget' ? objData.usedBudget : objData.avgImplementation);
            difference.push(props.type === 'byBudget' ? objData.totalBudget - objData.usedBudget : 100 - objData.avgImplementation);
          }
        });
        return (
          <div key={countries[index]} className="col-6 card routines-home__card">
            <span className="routines-home__card__title">{Object.keys(data)[index]}</span>
            <CapexBars
              countries={countries}
              usedBudget={usedAmount}
              differenceBudget={difference}
              type={props.type}
              max={props.type === 'byProject' ? 100 : undefined}
            />
          </div>
        );
      })}
    </div>
  );
};

export default CapexByMonthsOrProjects;
