const ChartOptions = (
  allLevelss: string[],
  currentPercentage: string[],
  lastPercentage: string[],
  goals: number[],
) => {
  const options = {
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false,
      },
    },
    stroke: {
      lineCap: 'round',
    },
    plotOptions: {
      bar: {
        columnWidth: '60%',
      },
      fill: {
        opacity: 1,
      },
    },
    colors: ['#304473', '#777DB9', '#F4C44C'],
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: allLevelss,
    },
    yaxis: {
      min: 0,
      max: 100,
      decimalsInFloat: 0,
      title: {
        text: '% de cumplimiento',
        rotate: -90,
        offsetX: 0,
        offsetY: 0,
      },
    },
    legend: {
      show: true,
      showForSingleSeries: false,
      customLegendItems: ['Actual', 'Expected'],
      markers: {
        fillColors: ['#304473', '#777DB9', '#F4C44C'],
      },
    },
  };
  const series = [{
    name: 'Mes pasado',
    data: lastPercentage,
    type: 'column',
  }, {
    name: 'Mes actual',
    data: currentPercentage,
    type: 'column',
  }, {
    name: 'Meta',
    data: goals,
    type: 'line',
  }];
  return { options, series };
};

export default ChartOptions;
