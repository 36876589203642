/* eslint-disable @typescript-eslint/no-redeclare */
import React from 'react';
import Chart from 'react-apexcharts';
import { useCubeQuery } from '@cubejs-client/react';
import { groupBy } from 'lodash';
import { CircularProgress } from '@mui/material';
import EmptyState from '../../../../components/EmptyState/EmptyState';
import ChartOptions from './ChartOptions';
import './CashlessBar.scss';
import { isAYearBefore } from './Level';

type Parameters = {
  currentMonth: string[];
  lastMonth: string[];
  year: string[];
  bu?: string[];
};

const BU = ({ currentMonth, lastMonth, bu, year }: Parameters) => {
  const {
    resultSet: byBuCurrentMonth,
    isLoading: isLoadingByCurrentMonth,
  } = useCubeQuery({
    dimensions: [
      'AnalyticsToolkit.percentage',
      'AnalyticsToolkit.bu',
      'AnalyticsToolkit.level',
      'AnalyticsToolkit.year',
      'AnalyticsToolkit.month',
    ],
    filters: [
      {
        dimension: 'AnalyticsToolkit.bu',
        operator: 'contains',
        values: bu ?? [],
      },
      {
        dimension: 'AnalyticsToolkit.month',
        operator: 'contains',
        values: currentMonth ?? [],
      },
      {
        dimension: 'AnalyticsToolkit.year',
        operator: 'contains',
        values: year ?? [],
      },
    ],
  });

  const {
    resultSet: byBuLastMonth,
    isLoading: isLoadingByLastMonth,
  } = useCubeQuery({
    dimensions: [
      'AnalyticsToolkit.percentage',
      'AnalyticsToolkit.bu',
      'AnalyticsToolkit.year',
      'AnalyticsToolkit.level',
      'AnalyticsToolkit.month',
    ],
    filters: [
      {
        dimension: 'AnalyticsToolkit.bu',
        operator: 'contains',
        values: bu ?? [],
      },
      {
        dimension: 'AnalyticsToolkit.month',
        operator: 'contains',
        values: lastMonth ?? [],
      },
      {
        dimension: 'AnalyticsToolkit.year',
        operator: 'contains',
        values: isAYearBefore({ lastMonth, year }),
      },
    ],
  });

  const data: any[] = [];

  if (byBuLastMonth?.tablePivot() && byBuCurrentMonth?.tablePivot()) {
    const currentData = byBuCurrentMonth?.tablePivot();
    const lastData = byBuLastMonth?.tablePivot();

    const currentDataG = groupBy(currentData, 'AnalyticsToolkit.level');
    const lastDataG = groupBy(lastData, 'AnalyticsToolkit.level');

    Object.entries(currentDataG).forEach(([key, value]) => {
      let currentPercentage;
      let lastPercentage;
      value.forEach(item => {
        currentPercentage = item['AnalyticsToolkit.percentage'];
        const lastD = lastDataG[key]?.find(i => i['AnalyticsToolkit.level'] === key);
        lastPercentage = lastD && lastD['AnalyticsToolkit.percentage'];
      });

      data.push({
        level: `level ${key}`,
        currentPercentage,
        lastPercentage,
      });
    });
  }

  const allLevels = data.map(i => (i.level as string).toUpperCase());
  const goals = new Array(data.length).fill(100);
  const currentPercentage = data.map(i => (
    i.currentPercentage > 0
      ? Number(i.currentPercentage).toFixed(2)
      : '0'));
  const lastPercentage = data.map(i => (
    i.lastPercentage > 0
      ? Number(i.lastPercentage).toFixed(2)
      : '0'));

  const chartOptions = ChartOptions(allLevels, currentPercentage, lastPercentage, goals);

  if (isLoadingByLastMonth || isLoadingByCurrentMonth) {
    return (
      <div className="violence-prevention">
        <div className="loaderPosition ">
          <div className="row">
            <CircularProgress size={30} />
            <p className="loading_text align-self-center"> Loading</p>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="violence-prevention">
      <div className="cashless-bar">
        {chartOptions.series[0].data
          && chartOptions.series[0].data.length > 0
          ? (
            // @ts-ignore
            <Chart
              type="line"
              options={chartOptions.options}
              series={chartOptions.series}
              height="300px"
            />
          ) : (
            <EmptyState />
          )}
      </div>
    </div>
  );
};

export default BU;
