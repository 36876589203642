/* eslint-disable @typescript-eslint/naming-convention */
Object.defineProperty(exports, '__esModule', {
  value: true,
});
exports.default = void 0;

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

const index = _interopRequireDefault(require('../../../_lib/buildLocalizeFn/index.js'));

const eraValues = {
  narrow: ['AC', 'DC'],
  abbreviated: ['AC', 'DC'],
  wide: ['antes de cristo', 'después de cristo'],
};
const quarterValues = {
  narrow: ['1', '2', '3', '4'],
  abbreviated: ['T1', 'T2', 'T3', 'T4'],
  wide: ['1º trimestre', '2º trimestre', '3º trimestre', '4º trimestre'],
};
const monthValues = {
  narrow: ['e', 'f', 'm', 'a', 'm', 'j', 'j', 'a', 's', 'o', 'n', 'd'],
  abbreviated: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
  wide: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
};
const dayValues = {
  narrow: ['d', 'l', 'm', 'm', 'j', 'v', 's'],
  short: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
  abbreviated: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sab'],
  wide: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
};
const dayPeriodValues = {
  narrow: {
    am: 'a',
    pm: 'p',
    midnight: 'mn',
    noon: 'md',
    morning: 'mañana',
    afternoon: 'tarde',
    evening: 'tarde',
    night: 'noche',
  },
  abbreviated: {
    am: 'AM',
    pm: 'PM',
    midnight: 'medianoche',
    noon: 'mediodia',
    morning: 'mañana',
    afternoon: 'tarde',
    evening: 'tarde',
    night: 'noche',
  },
  wide: {
    am: 'a.m.',
    pm: 'p.m.',
    midnight: 'medianoche',
    noon: 'mediodia',
    morning: 'mañana',
    afternoon: 'tarde',
    evening: 'tarde',
    night: 'noche',
  },
};
const formattingDayPeriodValues = {
  narrow: {
    am: 'a',
    pm: 'p',
    midnight: 'mn',
    noon: 'md',
    morning: 'de la mañana',
    afternoon: 'de la tarde',
    evening: 'de la tarde',
    night: 'de la noche',
  },
  abbreviated: {
    am: 'AM',
    pm: 'PM',
    midnight: 'medianoche',
    noon: 'mediodia',
    morning: 'de la mañana',
    afternoon: 'de la tarde',
    evening: 'de la tarde',
    night: 'de la noche',
  },
  wide: {
    am: 'a.m.',
    pm: 'p.m.',
    midnight: 'medianoche',
    noon: 'mediodia',
    morning: 'de la mañana',
    afternoon: 'de la tarde',
    evening: 'de la tarde',
    night: 'de la noche',
  },
};

function ordinalNumber(dirtyNumber) {
  const number = Number(dirtyNumber);
  return `${number}º`;
}

const localize = {
  ordinalNumber,
  era: (0, index.default)({
    values: eraValues,
    defaultWidth: 'wide',
  }),
  quarter: (0, index.default)({
    values: quarterValues,
    defaultWidth: 'wide',
    argumentCallback: quarter => Number(quarter) - 1,
  }),
  month: (0, index.default)({
    values: monthValues,
    defaultWidth: 'wide',
  }),
  day: (0, index.default)({
    values: dayValues,
    defaultWidth: 'wide',
  }),
  dayPeriod: (0, index.default)({
    values: dayPeriodValues,
    defaultWidth: 'wide',
    formattingValues: formattingDayPeriodValues,
    defaultFormattingWidth: 'wide',
  }),
};

exports.default = localize;
module.exports = exports.default;
