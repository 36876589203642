import React, { useEffect, useState } from 'react';
import './IncidentsDetails.scss';
import ImageLinks from 'utils/ImageLinks';
import { SpecificSubLocations, SubLocations, WorkLocation } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { TICKET_TYPE, TRANSLATIONS } from 'types/enums';
import { TextField } from '@mui/material';
import Autocomplete from '@mui/lab/Autocomplete';
import request from 'utils/request';
import { canUserAssignSIOsExecutors, canUserCloseSIOS, canUserEditSIOS, canUserPostponeIncidents, canUserReturnSIOS, userIsSafetyManager } from 'utils/shared/canUserAccessFeature';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/selectors/user.selectors';
import Modal from '@mui/material/Modal';
import Input from 'components/Input';
import LinkDropdown from 'components/Dropdown/LinkDropdown';
import IncidentsClosePopup from '../incidents-close-popup/IncidentsClosePopup';
import IncidentsPostponePopup from '../incidents-postpone-popup/IncidentsPostponePopup';

type Props = {
  detailsType: 'assign' | 'close';
  // incident: Incident;
  incident: any;
  isEditing: boolean
  setIsEditing: any;
  departmentsList: WorkLocation[];
  areasList: SubLocations[];
  subAreasList: SpecificSubLocations[];
  userToAssignList: any[];
  isAssigned?: boolean;
  handleIncidentAssign: () => void;
  refresh: () => void;
};

const IncidentsDetails = ({
  detailsType,
  incident,
  isEditing,
  setIsEditing,
  departmentsList,
  areasList,
  subAreasList,
  userToAssignList,
  isAssigned,
  handleIncidentAssign,
  refresh,
}: Props) => {
  const { t } = useTranslation();
  const userDetails = useSelector(selectUser);

  const [, setLoading] = useState(false);
  const [selectedUserToAssign, setSelectedUserToAssign] = useState<any>();
  const [selectedDepartment, setSelectedDepartment] = useState<WorkLocation>();
  const [selectedArea, setSelectedArea] = useState<SubLocations>();
  const [selectedSubArea, setSelectedSubArea] = useState<SpecificSubLocations>();
  const [showModalReturnIncident, setShowModalReturnIncident] = useState(false);
  const [showPostponeIncidentModal, setShowPostponeIncidentModal] = useState(false);
  const [isMoreOptionsDropdownClicked, setMoreOptionsDropdownClicked] = useState(false);

  const IMAGE_UPLOAD_PERMISSION_ERROR = new Error();
  IMAGE_UPLOAD_PERMISSION_ERROR.name = 'IMAGE_UPLOAD_PERMISSION_ERROR';
  IMAGE_UPLOAD_PERMISSION_ERROR.message = 'was not able to get permission to upload image to S3';

  const [
    showTextReturnIncidentConfirmation,
    setShowTextReturnIncidentConfirmation,
  ] = useState(false);
  const [
    showTextReturnIncidentReview,
    setShowTextReturnIncidentReview,
  ] = useState(false);
  const [returnCause, setReturnCause] = useState('');

  const [showModalCloseIncident, setShowModalCloseIncident] = useState(false);

  const dropdownItems: any[] = [];

  const sifList = [
    {
      name: t(TRANSLATIONS.NO_SIF),
      value: null,
    },
    // {
    //   name: t(TRANSLATIONS.SIF_POTENTIAL),
    //   value: 0,
    // },
    {
      name: t(TRANSLATIONS.SIF_PRECURSOR),
      value: 1,
    },
  ];
  const [selectedSIF, setSelectedSIF] = useState<typeof sifList[0]>();

  const requestImageUploadPermission = async (imageExtension: string, size: number) => {
    try {
      const url = '/api/v1/image-upload';

      const response = await request.post(url, { fileExtension: imageExtension, size, target: 'closingImages' }) as {
        preSignedUrl: string;
        fileName: string;
        target: string;
      };

      return ({
        imageUploadData: {
          preSignedUrl: response.preSignedUrl,
          fileName: response.fileName,
        },
      });
    } catch (e) {
      console.log('e requestImageUploadPermission(): ', (e as any).message || e);

      // set an error value which we can check for in uploadImage above to sniff for a failure
      return (
        {
          imageUploadData: IMAGE_UPLOAD_PERMISSION_ERROR.name,
        });
    }
  };

  const isIncidentReturnable = () => {
    if (
      !isEditing
      && (
        canUserReturnSIOS(userDetails?.get('features'))
        || (
          userIsSafetyManager(userDetails?.getIn(['roleDetails', 'slug']))
          && incident?.meta?.incidentStatusType === 'assigned'
        )
      )
      && (
        incident?.meta?.closedAt === null
        || incident?.closedAt === null
      )
    ) {
      return true;
    }
    return false;
  };

  const isIncidentClosable = () => {
    if (
      !isEditing
      && canUserCloseSIOS(userDetails?.get('features'))
      && (
        incident?.meta?.closedAt === null
        || incident?.closedAt === null
      )
      && incident?.meta?.incidentStatusType !== 'postponed'
    ) {
      return true;
    }
    return false;
  };

  const isIncidentEditable = () => {
    if (
      !isEditing
      && canUserEditSIOS(userDetails?.get('features'))
      && (
        (
          incident?.meta?.closedAt === null
          || incident?.closedAt === null
        )
        && incident?.meta?.incidentStatusType !== 'postponed'
      )
    ) {
      return true;
    }
    return false;
  };

  const isIncidentPostponable = () => {
    if (
      !isEditing
      && canUserPostponeIncidents(userDetails?.get('features'))
      && (
        incident?.meta?.postponedTill === null
        || incident?.postponedTill === null
        || incident?.meta?.incidentStatusType !== 'postponed'
      )
      && (
        incident?.meta?.closedAt === null
        || incident?.closedAt === null
      )
      && incident?.meta?.incidentStatusType !== 'assigned'
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    setSelectedDepartment(incident?.incidentLocation?.workLocation);
    setSelectedArea(incident?.incidentLocation?.subLocation);
    setSelectedSubArea(incident?.incidentLocation?.specificSubLocation);
    setSelectedSIF(sifList.find(e => e?.value === incident?.isSIF));

    setSelectedUserToAssign(undefined);
    // eslint-disable-next-line
  }, [incident, isEditing]);

  const onCancelEditing = () => {
    setIsEditing(false);
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const url = '/api/v2/edit-incident';
      const body = {
        incidentId: incident?.id,
        departmentDetails: selectedDepartment,
        area: selectedArea,
        specificSubLocation: selectedSubArea,
        isSIF: selectedSIF?.value,
        source: 'web',
      };
      await request.post(url, body);
      refresh();
      setLoading(false);
      setIsEditing(false);
    } catch (e) {
      console.log('e submitEditedIncident(): ', (e as any).message || e);
    }
  };

  const onVisibleChange = (isVisible: boolean) => {
    setMoreOptionsDropdownClicked(isVisible);
  };

  const openModalCloseIncident = () => {
    setShowModalCloseIncident(true);
  };

  const closeModalCloseIncident = () => {
    setShowModalCloseIncident(false);
  };

  const openModalPostponeIncident = () => {
    setShowPostponeIncidentModal(true);
  };

  const openModalReturnIncident = () => {
    setShowTextReturnIncidentReview(true);
    setShowModalReturnIncident(true);
  };

  const closeModalReturnIncident = () => {
    setShowTextReturnIncidentReview(false);
    setShowTextReturnIncidentConfirmation(false);
    setShowModalReturnIncident(false);
  };

  if (isIncidentReturnable() && incident?.meta?.incidentStatusType === 'assigned') {
    dropdownItems.push({
      type: 'return_sio',
      label: (
        <div
          className="id-dropdown-item"
          role="button"
          onClick={() => {
            openModalReturnIncident();
            setMoreOptionsDropdownClicked(false);
          }}
        >
          {t(TRANSLATIONS.RETURN_SIO)}
        </div>
      ),
    });
  }

  if (isIncidentEditable()) {
    dropdownItems.push({
      type: 'edit_sio',
      label: (
        <div
          className="id-dropdown-item"
          role="button"
          onClick={() => {
            setIsEditing(true);
            setMoreOptionsDropdownClicked(false);
          }}
        >
          {t(TRANSLATIONS.EDIT_SIO)}
        </div>
      ),
    });
  }

  if (isIncidentPostponable()) {
    dropdownItems.push({
      type: 'postpone_sio',
      label: (
        <div
          className="id-dropdown-item"
          role="button"
          onClick={() => {
            openModalPostponeIncident();
            setMoreOptionsDropdownClicked(false);
          }}
        >
          {t(TRANSLATIONS.POSTPONE_SIO)}
        </div>
      ),
    });
  }

  const returnIncident = async () => {
    try {
      const url = '/api/v2/return-incident';
      const body = {
        incidentId: incident?.id,
        returnCause: returnCause === '' ? null : returnCause,
      };
      await request.post(url, body);
      setReturnCause('');
      setShowTextReturnIncidentReview(false);
      setShowTextReturnIncidentConfirmation(true);
      setTimeout(() => {
        closeModalReturnIncident();
        refresh();
      }, 5000);
    } catch (e) {
      console.log('e returnIncident(): ', (e as any).message || e);
    }
  };

  const handleUserAssign = async () => {
    setLoading(true);
    try {
      const body = {
        incidents: [
          {
            id: incident?.id,
            managerSlug: selectedUserToAssign?.slug,
          },
        ],
      };
      await request.post('/api/v1/assign-incidents', body);
      handleIncidentAssign();
      refresh();
    } catch (e) {
      console.log('e handleUserAssign(): ', (e as any).message || e);
    }
  };

  if (!incident?.id) {
    return (
      <div className="id-empty-container">
        <div className="id-description¯">{t(TRANSLATIONS.TO_SEE_THE_DETAILS_OF_AN_ACI_CLICK_ON_A_CARD)}</div>
      </div>
    );
  }

  return (
    <section className="id-main-container">
      {detailsType === 'assign' && (
        <>
          { canUserAssignSIOsExecutors(userDetails?.get('features'))
            && (isAssigned
              ? (
                <div className="id-assigned-isAssigned-container">
                  <img
                    src={ImageLinks.blueFilledCheck}
                    className="id-assigned-isAssigned-icon"
                    alt="blue check"
                  />
                  <div className="id-assigned-isAssignedText">Asignado exitosamente</div>
                </div>
              )
              : (
                <section className="id-assign-container">
                  <Autocomplete
                    key={incident?.id || 'userToAssign'}
                    options={userToAssignList}
                    isOptionEqualToValue={(option, value) => option.slug === value?.slug}
                    value={selectedUserToAssign}
                    getOptionLabel={option => option.name as string}
                    className="id-assign-picker"
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Seleccionar supervisor para asignar"
                        inputProps={{
                          ...params.inputProps,
                          style: {
                            padding: 0,
                          },
                        }}
                      />
                    )}
                    disableClearable
                    onChange={(event, value) => {
                      setSelectedUserToAssign(value);
                    }}
                  />
                  <div
                    className={`id-button-solid ${!selectedUserToAssign ? 'disabled' : ''}`}
                    role="button"
                    onClick={handleUserAssign}
                  >
                    <div className="id-button-text">
                      {t(TRANSLATIONS.CONFIRM)}
                    </div>
                  </div>
                </section>

              )
            )}
        </>
      )}
      {/* </section> */}
      <section className="id-type-of-issue">
        <div>
          <div className="id-type-of-issue-title">
            {
              t(
                incident?.isUnsafe
                  ? incident?.typeOfIssue === TICKET_TYPE.CONDITION
                    ? TRANSLATIONS.UNSAFE_CONDITION
                    : TRANSLATIONS.UNSAFE_ACT
                  : incident?.typeOfIssue === TICKET_TYPE.CONDITION
                    ? TRANSLATIONS.SAFE_CONDITION
                    : TRANSLATIONS.SAFE_ACT,
              )
            }
          </div>
          <div className="id-issue-number">#{incident?.folioNumber}</div>
        </div>
        <div className="id-buttons-container">
          {isEditing && (
            <>
              <div
                className="id-button-outline"
                role="button"
                onClick={onCancelEditing}
              >
                <div className="id-button-text">
                  {t(TRANSLATIONS.CANCEL)}
                </div>
              </div>
              <div
                className="id-button-solid"
                role="button"
                onClick={handleSave}
              >
                <div className="id-button-text">
                  {t(TRANSLATIONS.SAVE)}
                </div>
              </div>
            </>
          )}

          { dropdownItems.length > 0 && (
            <LinkDropdown
              dropdownContent={dropdownItems}
              className={`id-button-outline ${isMoreOptionsDropdownClicked ? 'clicked' : ''}`}
              dropdownContentPositioning={{ right: -10 }}
              onVisibleChange={onVisibleChange}
            >
              <div className="d-flex">
                <div className="id-button-text d-flex align-items-center">
                  Más opciones
                </div>
                <img
                  src={ImageLinks.more}
                  className="id-button-image id-button-image-gap"
                  alt="more"
                />
              </div>
            </LinkDropdown>
          )}
          {isIncidentEditable() && !dropdownItems.find(item => item.type === 'edit_sio') && (
            <div
              className="id-button-outline"
              role="button"
              onClick={() => setIsEditing(true)}
            >
              <img
                className="id-button-image"
                src={ImageLinks.edit}
                alt="return"
              />
            </div>
          )}

          {isIncidentReturnable() && incident?.meta?.incidentStatusType !== 'assigned' && (
            <div className="id-button-outline" role="button" onClick={openModalReturnIncident}>
              <img
                src={ImageLinks.leftArrowIcon}
                className="id-button-image"
                alt="return"
              />
              <div className="id-button-text">
                {t(TRANSLATIONS.RETURN_SIO)}
              </div>
            </div>
          )}
          {isIncidentClosable()
            && (
              <div className="id-button-solid" role="button" onClick={openModalCloseIncident}>
                <div className="id-button-text">
                  {t(TRANSLATIONS.CLOSE_SIO)}
                </div>
              </div>
            )}
        </div>
      </section>
      <section className="id-location-row">
        <div className="id-location-container">
          <img
            src={ImageLinks.factory2}
            className="id-location-image"
            alt="location"
          />
          <div className="id-location-label">{incident?.distributionCenterName || 'N/A'}</div>
        </div>
        {((detailsType === 'assign' || incident?.meta?.incidentStatusType === 'assigned') && isEditing) ? (
          <Autocomplete
            key="department"
            options={departmentsList}
            isOptionEqualToValue={(option, value) => option.slug === value?.slug}
            value={selectedDepartment}
            getOptionLabel={option => option.name as string}
            className="id-location-picker"
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  style: {
                    padding: 0,
                  },
                }}
              />
            )}
            disableClearable
            onChange={(event, value) => {
              setSelectedDepartment(value);
              setSelectedArea(undefined);
              setSelectedSubArea(undefined);
            }}
          />
        ) : (
          <div className="id-location-container">
            <img
              src={ImageLinks.location2}
              className="id-location-image"
              alt="location"
            />
            <div className="id-location-label">{selectedDepartment?.name || 'N/A'}</div>
          </div>
        )}
      </section>
      <section className="id-issue-description">
        <div className="id-description-text">
          {incident.description}
        </div>
      </section>
      <section className="id-row-info">
        <div className="id-row-info-label">{t(TRANSLATIONS.AREA)}</div>
        {((detailsType === 'assign' || incident?.meta?.incidentStatusType === 'assigned') && isEditing) ? (
          <Autocomplete
            key="area"
            options={areasList?.filter(d => d.departmentId === selectedDepartment?.id)}
            // options={areasList}
            isOptionEqualToValue={(option, value) => option.slug === value?.slug}
            value={selectedArea}
            getOptionLabel={option => option.name as string}
            className="id-row-info-picker"
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  style: {
                    padding: 0,
                  },
                }}
              />
            )}
            disableClearable
            onChange={(event, value) => {
              setSelectedArea(value);
              setSelectedSubArea(undefined);
            }}
          />
        ) : (
          <div className="id-row-info-value">
            {selectedArea?.name || 'N/A'}
          </div>
        )}
      </section>
      <section className="id-row-info">
        <div className="id-row-info-label">{t(TRANSLATIONS.SUBAREA)}</div>
        {((detailsType === 'assign' || incident?.meta?.incidentStatusType === 'assigned') && isEditing) ? (
          <Autocomplete
            key="subArea"
            options={subAreasList?.filter(d => d.areaId === selectedArea?.id)}
            isOptionEqualToValue={(option, value) => option.slug === value?.slug}
            value={selectedSubArea}
            getOptionLabel={option => option.name as string}
            className="id-row-info-picker"
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  style: {
                    padding: 0,
                  },
                }}
              />
            )}
            disableClearable
            onChange={(event, value) => {
              setSelectedSubArea(value);
            }}
          />
        ) : (
          <div className="id-row-info-value">
            {selectedSubArea?.name || 'N/A'}
          </div>
        )}
      </section>
      <section className="id-row-info">
        <div className="id-row-info-label">{t(TRANSLATIONS.RISK_GROUP)}</div>
        <div className="id-row-info-value">{incident?.riskGroup?.name || 'N/A'}</div>
      </section>
      <section className="id-row-info">
        <div className="id-row-info-label">{t(TRANSLATIONS.TYPE_OF_RISK)}</div>
        <div className="id-row-info-value">{incident?.riskType?.name || 'N/A'}</div>
      </section>
      <div className="id-row-divider" />
      <section className="id-row-info">
        <div className="id-row-info-label">SIF</div>
        <div className="id-row-info-value">
          {selectedSIF?.name}
        </div>
      </section>
      <div className="id-row-divider" />
      <section className="id-row-info">
        <div className="id-row-info-label">{t(TRANSLATIONS.CORRECTIVE_ACTION)}</div>
        <div className="id-row-info-value">{incident?.possibleSolution || 'N/A'}</div>
      </section>

      { incident?.returnedBy && incident?.returnCause && (
        <>
          <section className="id-row-info">
            <div className="id-row-info-label">{t(TRANSLATIONS.RETURNED_BY)}:</div>
            <div className="id-row-info-value">{incident?.returnedBy}</div>
          </section>

          <section className="id-row-info">
            <div className="id-row-info-label">{t(TRANSLATIONS.RETURN_CAUSE)}:</div>
            <div className="id-row-info-value">{incident?.returnCause}</div>
          </section>
        </>
      )}
      <div className="id-row-divider" />
      <section className="id-row-info">
        <div className="id-row-info-label">{t(TRANSLATIONS.REPORTED_BY)}</div>
        <div className="id-row-info-value">{incident?.submittedBy?.name || 'N/A'}</div>
      </section>
      { incident?.assignedToUser && incident?.meta?.incidentStatusType === 'assigned' && (
        <>
          <section className="id-row-info">
            <div className="id-row-info-label">{t(TRANSLATIONS.ASSIGNED_TO)}</div>
            <div className="id-row-info-value">{incident?.assignedToUser?.name}</div>
          </section>
        </>
      )}

      {
        incident?.imageUrl
          ? (
            <img
              src={incident?.imageUrl}
              className="id-photo"
              alt="evidence"
            />
          )
          : (
            <div className="id-photo-empty">
              {t(TRANSLATIONS.NO_PHOTOS)}
            </div>
          )
      }
      <Modal
        open={showModalReturnIncident}
      >
        <div
          className="return-sio-modal"
        >
          { showTextReturnIncidentReview && (
            <>
              <div className="return-sio-section-container">
                <div className="return-sio-question-title">
                  {t(incident?.typeOfIssue === TICKET_TYPE.CONDITION
                    ? TRANSLATIONS.RETURN_CONDITION
                    : TRANSLATIONS.RETURN_ACT)}
                </div>
              </div>
              <div className="return-sio-section-container return-sio-description-container">
                {t(incident?.typeOfIssue === TICKET_TYPE.CONDITION
                  ? TRANSLATIONS.IF_THE_CONDITION_DOES_NOT_BELONG_TO_YOU_PRESS_ON_RETURN
                  : TRANSLATIONS.IF_THE_ACT_DOES_NOT_BELONG_TO_YOU_PRESS_ON_RETURN)}
              </div>
              <Input
                type="text"
                name="returnCause"
                value={returnCause}
                className="return-cause-input"
                onChange={event => setReturnCause(event.target.value)}
                placeholder={t(incident?.typeOfIssue === TICKET_TYPE.CONDITION
                  ? TRANSLATIONS.WRITE_HERE_THE_REASON_FOR_RETURNING_THIS_CONDITION
                  : TRANSLATIONS.WRITE_HERE_THE_REASON_FOR_RETURNING_THIS_ACT)}
              />
              <div className="return-sio-section-container return-sio-buttons-container">
                <div className="id-button-outline" role="button" onClick={closeModalReturnIncident}>
                  {t(TRANSLATIONS.CANCEL)}
                </div>
                <div className={`id-button-solid ${returnCause === '' ? 'disabled' : ''}`} role="button" onClick={returnIncident}>
                  <div className="id-button-text">
                    {t(TRANSLATIONS.YES_RETURN)}
                  </div>
                </div>
              </div>
            </>
          )}
          { showTextReturnIncidentConfirmation && (
            <>
              <div className="return-sio-section-container">
                <img
                  src={ImageLinks.greenFilledCheck}
                  className="id-return-isReturned-icon-check"
                  alt="green check"
                />
              </div>
              <div className="return-sio-section-container">
                <div className="return-sio-question-title">
                  {t(TRANSLATIONS.RETURNED_SIO)}
                </div>
              </div>
              <div className="return-sio-section-container return-sio-description-container">
                {t(TRANSLATIONS.THE_SIO_WAS_RETURNED_TO_BE_REASSIGNED)}
              </div>
            </>
          )}
        </div>
      </Modal>
      {
        showModalCloseIncident && (
          <IncidentsClosePopup
            incidentId={incident?.id}
            incidentTypeOfIssue={incident?.typeOfIssue}
            isImageRequired={incident?.riskType?.isGoldenRule}
            handlePopupClose={closeModalCloseIncident}
            requestImageUploadPermission={requestImageUploadPermission}
            fromIncidentsList
            refresh={refresh}
            incidentIsGoldenRule={incident?.riskType?.isGoldenRule || false}
          />
        )
      }
      <IncidentsPostponePopup
        showPostponeIncidentModal={showPostponeIncidentModal}
        setShowPostponeIncidentModal={setShowPostponeIncidentModal}
        incidentId={incident?.id}
        incidentTypeOfIssue={incident?.typeOfIssue}
        refresh={refresh}
      />
    </section>
  );
};

export default IncidentsDetails;
