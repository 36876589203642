import React from 'react';
import './IncidentsListCard.scss';
import ImageLinks from 'utils/ImageLinks';
import { useTranslation } from 'react-i18next';
import { TICKET_TYPE, TRANSLATIONS } from 'types/enums';
import { formatDistanceToNow } from 'date-fns';

type Props = {
  // incident: Incident;
  incident: any;
  isActive: boolean;
  onClick:()=>void
};

const IncidentsListCard = ({
  incident,
  isActive,
  onClick,
}:Props) => {
  const { t } = useTranslation();
  return (
    <div className="incidents-list-card" onClick={onClick} role="button">
      <section className={`ilc-container ${isActive ? 'selected' : ''}`}>
        <div className="ilc-header">
          <div className="ilc-title">
            {
              t(
                incident?.isUnsafe
                  ? incident?.typeOfIssue === TICKET_TYPE.CONDITION
                    ? TRANSLATIONS.UNSAFE_CONDITION
                    : TRANSLATIONS.UNSAFE_ACT
                  : incident?.typeOfIssue === TICKET_TYPE.CONDITION
                    ? TRANSLATIONS.SAFE_CONDITION
                    : TRANSLATIONS.SAFE_ACT,
              )
            }
          </div>
          <div className="ilc-timestamp">{formatDistanceToNow(new Date(incident?.createdAt || incident?.meta?.createdAt || new Date()), { addSuffix: true })}</div>
        </div>
        <div className="ilc-subtitle">#{incident?.folioNumber}</div>
        <section className="ilc-content">
          <div className="ilc-image-wrapper">
            <img
              src={ImageLinks.factory2}
              className="ilc-image"
              alt="location-1"
            />
            <div className="ilc-image-label">{incident?.distributionCenterName || 'N/A'}</div>
          </div>
          <div className="ilc-image-wrapper">
            <img
              src={ImageLinks.location2}
              className="ilc-image"
              alt="location-2"
            />
            <div className="ilc-image-label">{incident?.workLocation?.name || 'N/A'}</div>
          </div>
        </section>
        <p className="ilc-description">{incident?.description || 'N/A'}</p>
        { incident?.returnCause && incident?.returnedBy && (
          <>
            <p className="ilc-description">{t(TRANSLATIONS.RETURNED_BY)}: {incident?.returnedBy}</p>
            <p className="ilc-description">{t(TRANSLATIONS.RETURN_CAUSE)}: {incident?.returnCause}</p>
          </>
        )}
      </section>

    </div>
  );
};

export default IncidentsListCard;
