import React, { useEffect, useState } from 'react';
import { Popover, Typography } from '@mui/material';
import InputWithIcon from 'components/Input/InputWithIcon';
import i18next from 'i18next';
import { useKpIsListQuery } from 'generated/graphql';
import { ACTION_LOG_SUMMARY_MODAL_STATUS, TRANSLATIONS } from 'types/enums';
import ImageLinks from 'utils/ImageLinks';
import { KPIState } from 'features/action-logs/createEditActionLog/createEditActionLogModal.state';

import { useTranslation } from 'react-i18next';
import './KPIForSummaryModalStyle.scss';

type KPIForSummaryModalProps = {
  modalStatus: ACTION_LOG_SUMMARY_MODAL_STATUS;
  currentKPI: KPIState;
  onKPISelectionHandler: (kpi: KPIState) => void;
};

function KPIForSummaryModal(props: KPIForSummaryModalProps) {
  const { t } = useTranslation();
  const { modalStatus, currentKPI, onKPISelectionHandler } = props;
  const [searchText, setSearchText] = useState<string | ''>();
  const [kpiList, setKpiList] = useState<KPIState[]>();
  const [previousSelectedKPI, setPreviousSelectedKPI] = useState<KPIState>({
    label: '',
    value: '',
  });
  const [filterdKPI, setFilteredKPI] = useState<KPIState[]>();
  const [anchorEl, setAnchorEl] = useState<HTMLImageElement | null>(null);
  const { data } = useKpIsListQuery();

  const handleClick = (event: React.MouseEvent<HTMLImageElement>) => {
    if (modalStatus !== ACTION_LOG_SUMMARY_MODAL_STATUS.SUMMARY) setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSearchText('');
  };

  function filterKPI(e: React.ChangeEvent<HTMLInputElement>) {
    const trimmedText = e.target.value.trim();
    setSearchText(trimmedText);
    if (trimmedText.length) {
      setFilteredKPI(
        kpiList?.filter(kpi => kpi.label.toLowerCase().includes(trimmedText.toLowerCase())),
      );
    } else {
      setFilteredKPI(kpiList);
    }
  }
  function handleOKBtnClick() {
    if (currentKPI !== previousSelectedKPI) {
      handleClose();
    }
  }

  useEffect(() => {
    const structuredKPIsFromData: KPIState[] = [];
    (data?.getAllKPI || []).forEach(kpi => {
      const label = (i18next.language === 'en' ? kpi?.enTitle : kpi?.esTitle) || '';
      const value = kpi?.slug!;
      structuredKPIsFromData.push({ label, value });
    });
    setKpiList(structuredKPIsFromData!);
    setFilteredKPI(structuredKPIsFromData!);
    // eslint-disable-next-line
  }, [data?.getAllKPI]);

  useEffect(() => {
    setPreviousSelectedKPI(currentKPI);
    // eslint-disable-next-line
  }, [anchorEl]);

  if (modalStatus === ACTION_LOG_SUMMARY_MODAL_STATUS.SUMMARY) {
    return (
      <div className="kpi">
        <p className="extra_title">KPI</p>
        <p className="extra_sub_title kpis">{`#${currentKPI?.label}` || t(TRANSLATIONS.SELECT)}</p>
      </div>
    );
  }
  const searchKpiTxt: string = `${t(TRANSLATIONS.SEARCH)} KPI`;

  return (
    <div className="kpi">
      <p className="extra_title">KPI</p>
      <p className="extra_title select_kpi" onClick={handleClick}>
        {currentKPI?.label || t(TRANSLATIONS.SELECT)}
      </p>
      <Popover
        id="popover-edit-for-responsible"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Typography>
          <div className="kpi_popover_container">
            <div className="input_container">
              <InputWithIcon
                style={{
                  background: '#F2F2F2',
                  border: 'none',
                  color: '#808080',
                  height: '50px',
                  fontSize: '16px',
                }}
                onChange={filterKPI}
                value={searchText}
                placeholder={searchKpiTxt}
                icon={ImageLinks.searchIcon}
              />
              <p
                onClick={handleOKBtnClick}
                className={`ok_btn_secondary ${currentKPI === previousSelectedKPI ? 'disabled_ok_btn_secondary' : ''}`}
              >
                OK
              </p>
            </div>
            <div className="kpis_container">
              {filterdKPI?.length ? (
                filterdKPI?.map(kpi => (
                  <div
                    key={kpi.value}
                    role="presentation"
                    onClick={() => onKPISelectionHandler(kpi)}
                    className={`single_kpi ${currentKPI?.value === kpi?.value ? 'selected_kpi' : ''}`}
                  >
                    {kpi?.label}
                    {currentKPI?.value === kpi?.value && <div className="green_circle" />}
                  </div>
                ))
              ) : (
                <p className="no_result_found">{t(TRANSLATIONS.NO_RESULTS_FOUND)}</p>
              )}
            </div>
          </div>
        </Typography>
      </Popover>
    </div>
  );
}

export default KPIForSummaryModal;
