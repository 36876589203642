import request from '../../utils/request';

export const SET_ACIS_INDEX = 'safety-dashboard/set_acis_index';

export function setAcisIndex(index: any) {
  return { type: SET_ACIS_INDEX, index };
}

// Requests
export function getAcisIndex(distCenter: string) {
  return async (dispatch: any) => {
    try {
      dispatch(setAcisIndex(undefined));
      const url = '/api/v1/list-incidents';
      const response = await request.get(url, {
        startTime: new Date('2019-10-30T00:00:00.000Z'),
        endTime: new Date('2022-12-30T00:00:00.000Z'),
        timezoneOffsetStart: 300,
        timezoneOffsetEnd: 300,
        distributionCenter: distCenter,
      });
      dispatch(setAcisIndex(response));
    } catch (e) {
      console.log('e getAcisIndex()', e.message || e);
    }
  };
}

export function closeIncident(incidentId: number) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: any) => {
    try {
      const url = '/api/v1/close-incident';
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const response = await request.post(url, {
        incidentId,
      });
    } catch (e) {
      console.log('e getAcisIndex()', e.message || e);
    }
  };
}
