/* eslint-disable @typescript-eslint/naming-convention */
import { ACTION_LOG_STATUS } from 'types/enums';
import {
  Action_Log_Origin,
  Action_Log_Status,
  CreateEditActionLogPayload,
  ImageInputItem,
  RoutinesAvailableNameAndSlug,
} from 'generated/graphql';
import lodashIsEqual from 'lodash/isEqual';
import i18next from 'i18next';
import { isEqual } from 'date-fns';
import { ACTION_LOG_PILLAR_IMAGE } from './pillarImagesHelper';
import { Routine } from '../RoutinesListing/RoutinesListing';

export enum CreateEditActionLogState {
  START_DATE = 'startDate',
  STATUS = 'status',
  DESCRIPTION = 'description',
  RESPONSIBLES = 'responsibles',
  KPIS = 'KPIs',
  MEETING = 'meeting',
  MANAGEMENT_TOOL = 'managementTool',
  END_DATE = 'endDate',
  PILLAR = 'pillar',
  OWNER = 'owner',
  LOCATION = 'location',
  IMAGES = 'images',
  NOTE = 'note',
  ORIGIN = 'origin',
  ROUTINES_AVAILABLE = 'routinesAvailable',
}

export type Responsible = {
  userId: string;
  name: string;
  slug: string;
  position: string;
  profile: string;
  picture: string;
};

export interface LocalImage extends ImageInputItem {
  extension: string;
}

export type ActionLogLocationState = { name: string; slug: string };
export type OriginRespectiveSlug = {
  error?: string;
  routinesAvailableSlug?: string;
  owdChecklistSlug?: string;
  fiveSSlug?: string;
};
export type KPIState = { label: string; value: string };
export type ManagementTool = { label: string; value: string };
export type CreateEditActionLogStateType = {
  [CreateEditActionLogState.START_DATE]: Date;
  [CreateEditActionLogState.STATUS]: ACTION_LOG_STATUS;
  [CreateEditActionLogState.DESCRIPTION]: string;
  [CreateEditActionLogState.RESPONSIBLES]: Responsible[];
  [CreateEditActionLogState.KPIS]: KPIState;
  [CreateEditActionLogState.MANAGEMENT_TOOL]: ManagementTool;
  [CreateEditActionLogState.MEETING]: Routine;
  [CreateEditActionLogState.END_DATE]: Date;
  [CreateEditActionLogState.PILLAR]: ACTION_LOG_PILLAR_IMAGE;
  [CreateEditActionLogState.OWNER]: string;
  [CreateEditActionLogState.LOCATION]: ActionLogLocationState;
  [CreateEditActionLogState.IMAGES]: LocalImage[];
  [CreateEditActionLogState.NOTE]: string;
  [CreateEditActionLogState.ORIGIN]: Action_Log_Origin;
  [CreateEditActionLogState.ROUTINES_AVAILABLE]?: RoutinesAvailableNameAndSlug;
};

export const initialCreateEditActionLogState: CreateEditActionLogStateType = {
  [CreateEditActionLogState.START_DATE]: new Date(),
  [CreateEditActionLogState.STATUS]: ACTION_LOG_STATUS.OPEN,
  [CreateEditActionLogState.DESCRIPTION]: '',
  [CreateEditActionLogState.RESPONSIBLES]: [],
  [CreateEditActionLogState.KPIS]: { label: '', value: '' },
  [CreateEditActionLogState.MEETING]: { label: '', value: '' },
  [CreateEditActionLogState.MANAGEMENT_TOOL]: { label: '', value: '' },
  [CreateEditActionLogState.END_DATE]: new Date(),
  [CreateEditActionLogState.PILLAR]: ACTION_LOG_PILLAR_IMAGE.SAFETY,
  [CreateEditActionLogState.OWNER]: '',
  [CreateEditActionLogState.LOCATION]: { name: '', slug: '' },
  [CreateEditActionLogState.IMAGES]: [],
  [CreateEditActionLogState.NOTE]: '',
  [CreateEditActionLogState.ORIGIN]: Action_Log_Origin.ActionLog,
};

export const reducerCreateEditActionLog = (
  state: CreateEditActionLogStateType,
  action: { type: CreateEditActionLogState | 'RESET'; payload: any },
) => {
  if (action.type === 'RESET') {
    return action.payload || initialCreateEditActionLogState;
  }
  return { ...state, [action.type]: action.payload };
};

export function isStateFilledCompletely(requiredState: any) {
  /**
   * hacking requiredState.location.name and requiredState?.KPIs?.value
   */
  const {
    location,
    KPIs,
    meeting,
    responsibles,
    ...rest
  } = requiredState;
  if (
    location?.name?.length
    && KPIs?.value?.length
    && meeting.value?.length
    && responsibles.length
  ) {
    return !Object.values(rest).some(value => !String(value).trim().length);
  }
  return false;
}

export function isActionLogFilledPartially(
  state: CreateEditActionLogStateType,
  loggedInUserSlug: string,
): boolean {
  const {
    origin: _origin,
    routinesAvailable: _routinesAvailable,
    location,
    KPIs,
    managementTool,
    pillar,
    status,
    responsibles,
    startDate,
    endDate,
    owner,
    ...rest
  } = state;

  if (
    location?.name?.length
    || KPIs?.value?.length || managementTool?.value?.length
    || responsibles.length > 1
    || owner !== loggedInUserSlug
  ) {
    return true;
  }

  if (!isEqual(new Date(startDate), initialCreateEditActionLogState.startDate)) {
    return true;
  }

  if (!isEqual(new Date(endDate), initialCreateEditActionLogState.endDate)) {
    return true;
  }

  if (lodashIsEqual({
    pillar,
    status,
  },
  {
    pillar: initialCreateEditActionLogState.pillar,
    status: initialCreateEditActionLogState.status,
  })
  ) {
    return Object.values(rest).some(value => !!String(value).trim().length);
  }
  return true;
}

export function cleanDBImagePayload(images: ImageInputItem[] = []): ImageInputItem[] {
  return images.map(({ __typename: _imageTypeName, ...image }: any) => ({ ...image }));
}

export function formatPreFilledState(payload: any) {
  const {
    __typename,
    statusUpdateDate: _statusUpdateDate,
    originText: _orginText,
    images,
    routinesAvailable,
    KPI,
    ManagementTool,
    meeting,
    ...rest
  } = payload;

  const isOwnerAsResponsible = payload.responsibles?.find(
    (responsible: Responsible) => responsible.slug === payload.owner.slug);

  const imagesStateInitializer = cleanDBImagePayload(images);
  let formattedPrefilledInfo = {
    ...rest,
    [CreateEditActionLogState.LOCATION]: {
      name: payload?.location.name,
      slug: payload?.location.slug,
    },
    [CreateEditActionLogState.ROUTINES_AVAILABLE]: {
      name: routinesAvailable?.name,
      slug: routinesAvailable?.slug,
    },
    [CreateEditActionLogState.OWNER]: payload.owner.slug,
    [CreateEditActionLogState.IMAGES]: imagesStateInitializer,
    [CreateEditActionLogState.KPIS]: {
      label: (i18next.language === 'en' ? KPI?.enTitle : KPI?.esTitle) || '',
      value: KPI?.slug,
    },
    [CreateEditActionLogState.MANAGEMENT_TOOL]: {
      label: (i18next.language === 'en' ? ManagementTool?.enTitle : ManagementTool?.esTitle) || '',
      value: ManagementTool?.id,
    },
    [CreateEditActionLogState.MEETING]: {
      label: routinesAvailable?.name || meeting,
      value: routinesAvailable?.slug || meeting,
    },
  };

  if (!isOwnerAsResponsible) {
    const responsiblesArray = JSON.parse(JSON.stringify(payload.responsibles));
    responsiblesArray?.push(payload.owner);
    formattedPrefilledInfo = {
      ...formattedPrefilledInfo,
      [CreateEditActionLogState.RESPONSIBLES]: responsiblesArray,
    };
  }

  return formattedPrefilledInfo;
}

export function originAndRespectiveSlugHandler(payload: any): OriginRespectiveSlug {
  const originRespectiveSlug: OriginRespectiveSlug = {};
  switch (payload[CreateEditActionLogState.ORIGIN]) {
    case Action_Log_Origin.Routine: {
      originRespectiveSlug
        .routinesAvailableSlug = payload[CreateEditActionLogState.ROUTINES_AVAILABLE].slug;
      break;
    }
    default: {
      break;
    }
  }
  return originRespectiveSlug;
}

export function cleanActionLogDBPayload(
  payload: any,
  actionLogStatus?: Action_Log_Status,
): CreateEditActionLogPayload {
  const {
    __typename,
    routinesAvailable: _routinesAvailable,
    owner,
    statusUpdateDate: _statusUpdateDate,
    originText: _orginText,
    status,
    images,
    KPIs,
    managementTool,
    KPI,
    originText: _originText,
    meeting,
    ...rest
  } = payload;
  const formattedPayload = {
    ...rest,
    ...originAndRespectiveSlugHandler(payload),
    responsibles: payload[CreateEditActionLogState.RESPONSIBLES].map(
      (responsible: any) => responsible.slug,
    ),
    location: payload[CreateEditActionLogState.LOCATION].slug,
    images: cleanDBImagePayload(images),
    owner: owner.slug ?? owner,
    status: actionLogStatus ?? status,
    KPIs: KPIs.value || KPI.slug,
    managementTool: managementTool?.value || null,
    meeting: meeting.value,
  };
  return formattedPayload;
}
