import React, { Component } from 'react';
// @ts-ignore
import ReactTable from 'react-table-legacy';
import { withTranslation } from 'react-i18next';
import SpanishLocale from 'date-fns/locale/es';
import format from 'date-fns/format';
import InputWithLabel from 'components/Input/InputWithLabel';
import BasicButton from 'components/Button/BasicButton';
import LoadingSpinner from 'components/LoadingSpinner';
import ContentDivisionHeader from 'components/bit/ContentDivisionHeader';
import { TICKET_TYPE, TRANSLATIONS } from 'types/enums';
import userSearch from '../../../../../assets/icons/input-search.svg';

import './IncidentsIndex.scss';

type State = {
  activeFilter: IncidentsFilter;
};

type Props = {
  incidents: any;
  downloadData(): void;
  t: any;
  handleSearch?: (e: any) => void;
  search?: string;
  setSearch: (e: any) => void;
  isFactory?: any;
};

enum IncidentsFilter {
  ALL = 'ALL',
  ACTS = 'ACTS',
  CONDITIONS = 'CONDITIONS',
  ENVIRONMENT = 'ENVIRONMENT',
}

const debounce = (callback: any, delay: any) => {
  let timer: any;
  return (...args: any) => {
    clearTimeout(timer);
    timer = setTimeout(() => callback(...args), delay);
  };
};
class IncidentsIndex extends Component<Props, State> {
  static filterIncidents(incident: any, filter: IncidentsFilter) {
    if (filter === IncidentsFilter.ALL) return true;
    if (filter === IncidentsFilter.ACTS) return incident.get('typeOfIssue') === TICKET_TYPE.ACT;
    if (filter === IncidentsFilter.CONDITIONS) return incident.get('typeOfIssue') === TICKET_TYPE.CONDITION;
    if (filter === IncidentsFilter.ENVIRONMENT) return incident.get('typeOfIssue') === TICKET_TYPE.ENVIRONMENT;
  }

  debounced = debounce((searchText: any) => {
    if (this.props.handleSearch) {
      this.props.handleSearch(searchText);
    }
  }, 1500);

  constructor(props: Props) {
    super(props);
    this.state = {
      activeFilter: IncidentsFilter.ALL,
    };

    this.handleSearch = this.handleSearch.bind(this);
  }

  createTableColumns = () => {
    const columns = [] as any;
    const { t } = this.props;

    columns.push({
      Header: t(TRANSLATIONS.WHO_COMMITTED),
      id: 'doneByString',
      accessor: (dataRow: any) => dataRow.get('doneByString'),
      Cell: (row: any) => (
        <p className="text-left" style={{ paddingLeft: 25 }}>
          {row.value === undefined ? '---' : row.value}
        </p>
      ),
      sortMethod: (a: any, b: any) => (a.name < b.name ? 1 : -1),
      style: { textAlign: 'left', fontWeight: 'lighter' },
      headerStyle: { fontWeight: 'bold' },
      width: 'auto',
    });

    columns.push({
      Header: (
        <div className="text-center">
          {t(TRANSLATIONS.DESCRIPTION)}
        </div>
      ),
      id: 'description',
      accessor: (dataRow: any) => ({
        description: dataRow.get('description'),
        type: dataRow.get('typeOfIssue'),
      }),
      Cell: (row: any) => {
        let description = '';
        if (row.value.description) {
          description = row.value.description.length > 51
            ? `${row.value.description.substring(0, 50)}...`
            : row.value.description;
        }
        return (
          <div style={{ display: 'flex' }}>
            <div
              className="description-square"
              style={{
                backgroundColor: row.value.type === TICKET_TYPE.ACT
                  ? '#2574FB' : row.value.type === TICKET_TYPE.CONDITION ? '#70A5FF' : '#00A6F9',
                width: '20px !important',
                height: '20px !important',
              }}
            >
              {row.value.type === TICKET_TYPE.ACT ? 'A' : row.value.type === TICKET_TYPE.CONDITION ? 'C' : 'R'}
            </div>
            <p className="text-area" style={{ paddingLeft: 10 }}>{description}</p>
          </div>
        );
      },
      style: { textAlign: 'left', fontWeight: 'lighter' },
      headerStyle: { fontWeight: 'bold' },
      width: 360,
    });

    columns.push({
      Header: t(TRANSLATIONS.PLACE),
      id: 'place',
      accessor: (dataRow: any) => dataRow.getIn(['workLocation', 'name']),
      Cell: (row: any) => (
        <p className="text-left" style={{ paddingLeft: 25 }}>
          {row.value}
        </p>
      ),
      style: { textAlign: 'left', fontWeight: 'lighter' },
      headerStyle: { fontWeight: 'bold' },
      width: 'auto',
    });

    columns.push({
      Header: t(TRANSLATIONS.DATE_REPORTED),
      id: 'createdAt',
      accessor: (dataRow: any) => dataRow.get('createdAt'),
      Cell: (row: any) => (
        <p className="text-left" style={{ paddingLeft: 25 }}>
          {format(new Date(row.value), 'dd LLL yy', { locale: SpanishLocale })}
        </p>
      ),
      style: { textAlign: 'left', fontWeight: 'lighter' },
      headerStyle: { fontWeight: 'bold' },
      width: 'auto',
    });

    columns.push({
      Header: t(TRANSLATIONS.IMAGE),
      id: 'image',
      accessor: (dataRow: any) => dataRow.getIn(['imageUrl']),
      Cell: (row: any) => (
        <>
          { row.value ? (
            <a className="text-left" style={{ paddingLeft: 25, color: 'blue', textDecoration: 'underline' }} href={row.value}>
              {t(TRANSLATIONS.DOWNLOAD)}
            </a>
          ) : (
            <p className="text-left" style={{ paddingLeft: 25 }}>
              {t(TRANSLATIONS.NO_IMAGE)}
            </p>
          )}
        </>
      ),
      style: { textAlign: 'left', fontWeight: 'lighter' },
      headerStyle: { fontWeight: 'bold' },
      width: 'auto',
    });

    columns.push({
      Header: t(TRANSLATIONS.CLOSING_IMAGE),
      id: 'closingImage',
      accessor: (dataRow: any) => dataRow.getIn(['closingImage']),
      Cell: (row: any) => (
        <>
          { row.value ? (
            <a className="text-left" style={{ paddingLeft: 25, color: 'blue', textDecoration: 'underline' }} href={row.value}>
              {t(TRANSLATIONS.DOWNLOAD)}
            </a>
          ) : (
            <p className="text-left" style={{ paddingLeft: 25 }}>
              {t(TRANSLATIONS.NO_IMAGE)}
            </p>
          )}
        </>
      ),
      style: { textAlign: 'left', fontWeight: 'lighter' },
      headerStyle: { fontWeight: 'bold' },
      width: 'auto',
    });

    return columns;
  };

  updateFilter(filter: IncidentsFilter) {
    this.setState({ activeFilter: filter });
  }

  handleSearch(event: any) {
    this.props.setSearch(event.target.value);
    this.debounced(event.target.value);
  }

  render() {
    const { activeFilter } = this.state;
    const { incidents, downloadData, t, isFactory } = this.props;
    const columns = this.createTableColumns();
    const searchBy = `${t(TRANSLATIONS.SEARCH_BY_NAME)}, ${t(TRANSLATIONS.QR_CODE)}, ${t(TRANSLATIONS.EMAIL)}, ${t(
      TRANSLATIONS.USER_TYPE,
    )}, ${t(TRANSLATIONS.JOB_TITLE)}, ${t(TRANSLATIONS.SHARP_AND_DESCRIPTION)}
    )}.`;

    const dataToDisplay: any = incidents?.filter(
      (i: any) => IncidentsIndex.filterIncidents(i, activeFilter),
    );

    return (
      <div className="distribution-center-incidents-index">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 10,
          }}
        >
          <div
            style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
          >
            <ContentDivisionHeader title={t(TRANSLATIONS.REPORTS_LIST)} />
            <div className="table-filters" style={{ marginLeft: 40 }}>
              <span
                role="button"
                onClick={() => this.updateFilter(IncidentsFilter.ALL)}
                style={{ color: activeFilter === IncidentsFilter.ALL ? '#2574FB' : '#9DAECC' }}
              >
                {t(TRANSLATIONS.ALL)}
              </span>
              <span
                role="button"
                onClick={() => this.updateFilter(IncidentsFilter.ACTS)}
                style={{ color: activeFilter === IncidentsFilter.ACTS ? '#2574FB' : '#9DAECC' }}
              >
                {t(TRANSLATIONS.BEHAVIORS)}
              </span>
              <span
                role="button"
                onClick={() => this.updateFilter(IncidentsFilter.CONDITIONS)}
                style={{ color: activeFilter === IncidentsFilter.CONDITIONS ? '#2574FB' : '#9DAECC' }}
              >
                {t(TRANSLATIONS.CONDITIONS)}
              </span>
              {isFactory
                ? (
                  <span
                    role="button"
                    onClick={() => this.updateFilter(IncidentsFilter.ENVIRONMENT)}
                    style={{ color: activeFilter === IncidentsFilter.ENVIRONMENT ? '#2574FB' : '#9DAECC' }}
                  >
                    {t(TRANSLATIONS.ENVIRONMENTAL_RISK)}
                  </span>
                )
                : null }
            </div>
          </div>
          <InputWithLabel
            inputImg={userSearch}
            onChange={this.handleSearch}
            placeholder={searchBy}
            value={this.props.search}
            inputClassName="search-input"
            containerClassName="search"
          />
          <BasicButton
            text={t(TRANSLATIONS.DOWNLOAD_LIST)}
            onClick={downloadData}
            style={{ padding: 5, paddingLeft: 20, paddingRight: 20 }}
            disabled={!incidents?.size}
          />
        </div>
        <div className={`card ${!incidents?.size && 'py-5'}`}>
          {!incidents ? (
            <LoadingSpinner height={100} />
          ) : (
            incidents?.size ? (
              <ReactTable
                data={dataToDisplay}
                columns={columns}
                defaultPageSize={dataToDisplay.size}
                pageSize={dataToDisplay.size}
                showPagination={false}
                className="-striped -highlight"
                NoDataComponent={() => null}
              />
            ) : <p className="noRecords">{t(TRANSLATIONS.NO_RECORDS_FOUND)}</p>
          )}
        </div>
      </div>
    );
  }
}
// @ts-ignore
export default withTranslation()(IncidentsIndex);
