
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import ReactTable from 'react-table-legacy';
import { ReactComponent as DownloadIcon } from 'assets/icons/download-black.svg';
import EmptyState from 'components/EmptyState/EmptyState';
import LoadingSpinner from 'components/LoadingSpinner'; // eslint-disable-line
import ChartDropdown from 'components/Dropdown/ChartDropdown';
import { TRANSLATIONS } from 'types/enums';
import { format } from 'date-fns';
import { useCubeQuery } from '@cubejs-client/react';
import { groupBy } from 'lodash';
import './TableByRegionalManager.scss';
// @ts-ignore
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { routinesMonthSelectordropDownItems } from 'utils/dateHelpers';

const dropDownItems = routinesMonthSelectordropDownItems(true);

type Props = {
  selectedPillar: string
  allPillarIds: string[]
};

const TableByRegionalManager = ({ selectedPillar, allPillarIds }: Props) => {
  const { t } = useTranslation();

  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [selectedDropDownItem, setSelectedDropDownItem] = useState(dropDownItems[0]);

  const getDateRange = () => {
    return [
      format(selectedDropDownItem.content.startWeek, 'yyyy-MM-dd'),
      format(selectedDropDownItem.content.lastDayOfLastWeek, 'yyyy-MM-dd'),
    ];
  };

  const dateRange = getDateRange() as unknown as string[];
  const {
    resultSet: infoGRO,
    isLoading: loadingInfoGRO,
  } = useCubeQuery({
    dimensions: [
      'InfoGRO.position',
      'InfoGRO.buName',
      'InfoGRO.zoneName',
      'InfoGRO.zoneSlug',
      'InfoGRO.name',
    ],
  });

  const { resultSet: routinesToDoQuery, isLoading: isLoadingTheoric } = useCubeQuery({
    measures: [
      'TheoricRoutinesCompliance.count',
    ],
    dimensions: [
      'TheoricRoutinesCompliance.frequency',
      'TheoricRoutinesCompliance.zoneName',
      'TheoricRoutinesCompliance.zoneSlug',
      'TheoricRoutinesCompliance.buName',
    ],
    filters: [{
      dimension: 'TheoricRoutinesCompliance.buId',
      operator: 'equals',
      values: ['1', '2', '3', '4', '5'],
    },
    {
      dimension: 'TheoricRoutinesCompliance.pillarId',
      operator: 'equals',
      values: selectedPillar === '' ? allPillarIds : [selectedPillar],
    }],
  });

  const {
    resultSet: routinesDoneQuery,
    isLoading: loadingDone,
  } = useCubeQuery({
    measures: [
      'RealRoutinesCompliance.count',
    ],
    timeDimensions: [
      {
        dimension: 'RealRoutinesCompliance.date',
        granularity: 'month',
        dateRange,
      },
    ],
    dimensions: [
      'RealRoutinesCompliance.frequency',
      'RealRoutinesCompliance.zoneName',
      'RealRoutinesCompliance.zoneSlug',
      'RealRoutinesCompliance.buName',
      'RealRoutinesCompliance.month',
    ],
    filters: [{
      dimension: 'RealRoutinesCompliance.buId',
      operator: 'equals',
      values: ['1', '2', '3', '4', '5'],
    },
    {
      dimension: 'RealRoutinesCompliance.pillarId',
      operator: 'equals',
      values: selectedPillar === '' ? allPillarIds : [selectedPillar],
    }],
  });

  let dataGRO: any[] = [];

  if (routinesToDoQuery?.tablePivot() && routinesDoneQuery?.tablePivot() && infoGRO?.tablePivot()) {
    const toDo = routinesToDoQuery.tablePivot();
    const done = routinesDoneQuery.tablePivot();
    const groInfo = infoGRO.tablePivot();

    const toDoG = groupBy(toDo, 'TheoricRoutinesCompliance.zoneSlug');
    const doneG = groupBy(done, 'RealRoutinesCompliance.zoneSlug');

    Object.entries(toDoG).forEach(([key, value]) => {
      let totalRoutinesToDo = 0;
      let totalRoutinesDone = 0;
      let zoneName;
      let buName;

      const currentGRO = groInfo.find(gro => gro['InfoGRO.zoneSlug'] === key);

      value.forEach(r => {
        zoneName = r['TheoricRoutinesCompliance.zoneName'] as string;
        buName = r['TheoricRoutinesCompliance.buName'] as string;
        const frequency = r['TheoricRoutinesCompliance.frequency'] as string;
        const theoricCount = r['TheoricRoutinesCompliance.count'] as number;
        const totalDone = doneG[key]
          ?.filter(k => k['RealRoutinesCompliance.frequency'] === frequency)
          .reduce((acum, current) => acum + (current['RealRoutinesCompliance.count'] as number), 0);

        if (frequency === '0 0 * * *') totalRoutinesToDo += theoricCount * 6 * selectedDropDownItem.content.weeks;
        if (frequency === '0 0 * * 0') totalRoutinesToDo += theoricCount * selectedDropDownItem.content.weeks;
        if (frequency === '0 0 1 * *') totalRoutinesToDo += theoricCount;

        totalRoutinesDone += (totalDone ?? 0);
      });

      dataGRO.push({
        manager: currentGRO ? currentGRO['InfoGRO.name'] : 'Vacante',
        region: `${buName} - ${zoneName}`,
        planned: totalRoutinesToDo,
        real: totalRoutinesDone,
        percentage: ((totalRoutinesDone / totalRoutinesToDo) * 100).toFixed(2),
      });
    });
  }

  dataGRO = dataGRO
    .filter(i => i.manager !== undefined)
    .sort((a, b) => (Number(a.percentage) < Number(b.percentage) ? 1 : -1));

  const columns = useMemo(() => [
    {
      Header: 'GRO',
      minWidth: 110,
      id: 'manager',
      accessor: 'manager',
      Cell: (row: any) => (
        <p className="text-left font-bold margin-left-25">
          {row.value}
        </p>
      ),
    },
    {
      Header: t(TRANSLATIONS.REGION),
      minWidth: 110,
      id: 'region',
      accessor: 'region',
      Cell: (row: any) => (
        <p className="text-left font-bold margin-left-25">
          {row.value}
        </p>
      ),
    },
    {
      Header: t(TRANSLATIONS.ROUTINE_COMPLIANCE_PLANNED),
      id: 'planned',
      accessor: 'planned',
      minWidth: 95,
      Cell: (row: any) => (
        <p className="text-left font-bold margin-left-25">
          {(row.value as number).toLocaleString()}
        </p>
      ),
    },
    {
      Header: t(TRANSLATIONS.ROUTINE_COMPLIANCE_REAL),
      id: 'real',
      accessor: 'real',
      Cell: (row: any) => (
        <p className="text-left font-bold margin-left-25">
          {(row.value as number).toLocaleString()}
        </p>
      ),
    },
    {
      Header: t(TRANSLATIONS.ROUTINE_COMPLIANCE_COMPLIANCE),
      id: 'percentage',
      accessor: 'percentage',
      Cell: (row: any) => (
        <p className="text-left font-bold margin-left-25">
          {`${row.value} %`}
        </p>
      ),
    },

  ], [t]);

  // @ts-ignore
  const handleDropDownChange = ({ key }) => {
    setSelectedDropDownItem(dropDownItems.find(item => item.slug === key) || dropDownItems[0]);
    setDropDownOpen(false);
  };

  if (loadingDone || loadingInfoGRO || isLoadingTheoric) {
    return (
      <div className="overview-locations-top-bottom">
        <LoadingSpinner height={271} />
      </div>
    );
  }

  return (
    <div className="table-by-regional-manager">
      <ReactTable
        id="tabelReact"
        data={dataGRO}
        columns={columns}
        showPagination={false}
        defaultPageSize={dataGRO?.length}
        className="-striped max-height"
        NoDataComponent={() => <EmptyState />}
      />
      <table id="GRO-Table" style={{ display: 'none' }}>
        <thead>
          <th> GRO </th>
          <th> {t(TRANSLATIONS.REGION)} </th>
          <th> {t(TRANSLATIONS.ROUTINE_COMPLIANCE_PLANNED)} </th>
          <th> {t(TRANSLATIONS.ROUTINE_COMPLIANCE_REAL)} </th>
          <th> {t(TRANSLATIONS.ROUTINE_COMPLIANCE_COMPLIANCE)} </th>
        </thead>
        <tbody>
          {dataGRO.map((d: any) => {
            return (
              <tr>
                <td> {d.manager} </td>
                <td> {d.region} </td>
                <td> {d.planned} </td>
                <td> {d.real} </td>
                <td> {d.percentage} </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="table-by-regional-manager__bottom-row">
        <ChartDropdown
          onChange={handleDropDownChange}
          selectedDropDownItem={selectedDropDownItem}
          dropDownItems={dropDownItems}
          dropDownOpen={dropDownOpen}
          setDropDownOpen={setDropDownOpen}
          labelClassName="label-default"
        />
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="table-by-regional-manager__bottom-row table-by-regional-manager__bottom-row"
          table="GRO-Table"
          filename="GRO Table"
          sheet="Gro Table"
          buttonText={(
            <div className="download_btn">
              <span className="download_text">
                {t(TRANSLATIONS.DOWNLOAD)} data
              </span>
              <DownloadIcon className="icon" />
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default TableByRegionalManager;
