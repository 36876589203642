import { useCubeQuery } from '@cubejs-client/react';
import ChartDropdown from 'components/Dropdown/ChartDropdown';
import ChartTabs from 'components/Tabs/ChartTabs';
import React, { useState } from 'react';
import { format } from 'date-fns';
import { set, subDays } from 'date-fns/esm';
import { lastDayOfMonth } from 'date-fns/esm/fp';
import './LocationsTopBottomStyles.scss';
import { useSelector } from 'react-redux';
import { selectLocationsToAccess } from 'store/selectors/globalFilters.selectors';
import { TRANSLATIONS } from 'types/enums';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line
import { groupBy, mean } from 'lodash';
import LoadingSpinner from 'components/LoadingSpinner';
import EmptyState from 'components/EmptyState/EmptyState';

const LocationTopBottom = () => {
  const { t } = useTranslation();

  const dropDownItems = [{
    slug: 'last-7-days',
    label: t(TRANSLATIONS.LAST_7_DAYS),
  }, {
    slug: 'last-30-days',
    label: t(TRANSLATIONS.LAST_30_DAYS),
  }, {
    slug: 'month-to-date',
    label: t(TRANSLATIONS.MONTH_TO_DATE),
  }];

  const tabsItems = [{
    title: t(TRANSLATIONS.TOP),
  }, {
    title: t(TRANSLATIONS.BOTTOM),
  }];

  const locationsToAccess = useSelector(selectLocationsToAccess);
  const skip = !locationsToAccess.length;
  const isWorkLocations = locationsToAccess.length === 1;
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [selectedDropDownItem, setSelectedDropDownItem] = useState(dropDownItems[0]);
  const [selectedTab, setSelectedTab] = useState(tabsItems[0]);

  const getDateRange = () => {
    const currentDate = new Date();
    let filter = ['', ''] as any[];
    if (selectedDropDownItem.slug === 'last-7-days') {
      filter = [subDays(currentDate, 7), currentDate];
    }
    if (selectedDropDownItem.slug === 'last-30-days') {
      filter = [subDays(currentDate, 30), currentDate];
    }
    if (selectedDropDownItem.slug === 'month-to-date') {
      const lastMonthDay = lastDayOfMonth(currentDate);
      const firstDayLastMonth = set(lastMonthDay, { date: 1 });
      filter = [firstDayLastMonth, lastMonthDay];
    }
    return [format(filter[0], 'yyyy-MM-dd'), format(filter[1], 'yyyy-MM-dd')];
  };

  const dateRange = getDateRange() as unknown as string[];

  const { resultSet: topLocations, isLoading } = useCubeQuery(isWorkLocations ? {
    measures: ['Incidents.count'],
    timeDimensions: [
      {
        dimension: 'Incidents.incidentdatetime',
        dateRange,
      },
    ],
    dimensions: ['WorkLocations.name'],
    order: { 'Incidents.count': 'desc' },
    filters: [{
      dimension: 'Incidents.locationId',
      operator: 'equals',
      values: locationsToAccess,
    }],
    limit: 20,
  } : {
    measures: [
      'Incidents.count',
      'Incidents.uniqueReporters',
    ],
    timeDimensions: [
      {
        dimension: 'Incidents.incidentdatetime',
        granularity: 'day',
        dateRange,
      },
    ],
    dimensions: ['Locations.name'],
    order: {
      'Incidents.count': 'desc',
    },
    filters: [{
      dimension: 'Incidents.locationId',
      operator: 'equals',
      values: locationsToAccess,
    }],
  }, { skip });

  let locations = [] as any;

  if (topLocations?.tablePivot() && !isWorkLocations) {
    const gr = groupBy(topLocations?.tablePivot(), 'Locations.name');
    const calculatedLocs = Object.entries(gr).map(([, value]) => {
      const avg = mean(value
        .map((v: any) => (v['Incidents.count'] / v['Incidents.uniqueReporters']) || 0));
      return {
        avgReports: avg,
        locationName: value[0]['Locations.name'],
      };
    });
    locations = calculatedLocs
      .filter((v: any) => isFinite(v.avgReports))
      .sort((a, b) => (a.avgReports < b.avgReports ? 1 : -1));
  }

  if (topLocations?.tablePivot() && isWorkLocations) {
    locations = topLocations?.tablePivot()
      .map((l: any) => ({
        locationName: l['WorkLocations.name'],
        avgReports: l['Incidents.count'],
      }))
      .filter((l: any) => l.locationName)
      .sort((a, b) => (a.avgReports < b.avgReports ? 1 : -1));
  }

  if (selectedTab.title === t(TRANSLATIONS.BOTTOM)) locations = locations.reverse();

  const updateTab = (option: any) => {
    setSelectedTab(option);
  };

  // @ts-ignore
  const handleDropDownChange = ({ key }) => {
    setSelectedDropDownItem(dropDownItems.find(
      item => item.slug === key,
    ) || dropDownItems[0]);
    setDropDownOpen(false);
  };

  const isEmpty = (!isLoading && locations?.length === 0);

  if (isLoading || skip) return <LoadingSpinner height={231} />;

  return (
    <div className="overview-locations-top-bottom">
      <div>
        <ChartTabs
          tabsItems={tabsItems}
          setSelectedTab={updateTab}
          selectedTab={selectedTab}
        />
        <div className="table-description">
          <p />
          { isWorkLocations ? (
            <p>{ t(TRANSLATIONS.INCIDENTS_PER_AREA) }</p>
          ) : (
            <p style={{ maxWidth: 70 }}>{t(TRANSLATIONS.DAILY_AVG_PER_USER)}</p>
          ) }
        </div>
      </div>
      <div className="table">
        { isEmpty ? (
          <EmptyState />
        ) : (
          locations.map((location: any, index: number) => (
            <div className="table-row" key={String(index)}>
              <p>{ location.locationName }</p>
              <p>{ isWorkLocations ? location.avgReports : location.avgReports.toFixed(1) }</p>
            </div>
          )))}
      </div>
      <ChartDropdown
        onChange={handleDropDownChange}
        selectedDropDownItem={selectedDropDownItem}
        dropDownItems={dropDownItems}
        dropDownOpen={dropDownOpen}
        setDropDownOpen={setDropDownOpen}
        labelClassName="label-default"
      />
    </div>
  );
};

export default LocationTopBottom;
