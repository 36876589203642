import React from 'react';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';
import ImageLinks from 'utils/ImageLinks';

function EmptyStateComments() {
  const { t } = useTranslation();
  return (
    <div className="empty-comments">
      <img src={ImageLinks.comments} alt="empty-actionlog-comments" />
      <p>{t(TRANSLATIONS.THERE_ARE_NO_COMMENTS_YET) as any}</p>
    </div>
  );
}

export default EmptyStateComments;
