import React, { ChangeEvent, Component, useEffect } from 'react';
import queryStrings from 'query-string';
import './AnonymousComplaint.scss';
import { DistributionCenter, DistributionCenterMap, Zone, ZoneMap, CountryMap } from 'types/interfaces';
import request from 'utils/request';
import { fromJS } from 'immutable';
import FormDetails from 'features/external-users/AnonymousComplaint/FormDetails';
import DefineLocation from 'features/external-users/AnonymousComplaint/DefineLocation';
import { COLOR_CODE_NEW, FETCH_STATUS, LOCATION_TYPES, RISK_LEVEL, SECURITY_STATUS, SIF_TYPES, TICKET_TYPE, TRANSLATIONS, YES_NO_OPTIONS } from 'types/enums';
import { NameAndSlugRecord, WorkLocationRecord } from 'types/types';
import delay from 'utils/delay';
import withAsyncTrack from 'functions/withAsyncTrack';
import { otherRiskType } from 'features/external-users/AnonymousComplaint/FormDetails/FormDetails';
import { withTranslation } from 'react-i18next';
import Separator from 'components/Separator';
import BasicButton from 'components/Button/BasicButton';
import { connect, useSelector } from 'react-redux';

import { Supply_User_Roles } from 'generated/graphql';
import IncidentsClosePopup from 'features/incidents/components/incidents-close-popup/IncidentsClosePopup';
import Alert from 'components/alert/Alert';
import ImageLinks from 'utils/ImageLinks';
import IncidentsPostponePopup from 'features/incidents/components/incidents-postpone-popup/IncidentsPostponePopup';
import Loading from 'components/Loading';
import { useLazyQuery } from '@apollo/client';
import { GET_INCIDENTS_BY_LAST_HOUR } from 'features/incidents/incidents.graphql';
import { selectUser } from 'store/selectors/user.selectors';
import { toast, ToastOptions } from 'react-toastify';
import withLocation from 'utils/hoc/withLocation';
import { canUserBeLimitedByHourSios } from '../../../utils/shared/canUserAccessFeature';

const IMAGE_UPLOAD_PERMISSION_ERROR = new Error();
IMAGE_UPLOAD_PERMISSION_ERROR.name = 'IMAGE_UPLOAD_PERMISSION_ERROR';
IMAGE_UPLOAD_PERMISSION_ERROR.message = 'was not able to get permission to upload image to S3';

class AnonymousComplaint extends Component<Props, State> {
  static async requestImageUploadPermission(imageExtension: string, size: number) {
    try {
      const url = '/api/v1/image-upload';

      const response = await request.post(url, { fileExtension: imageExtension, size }) as {
        preSignedUrl: string;
        fileName: string;
      };

      return ({
        imageUploadData: {
          preSignedUrl: response.preSignedUrl,
          fileName: response.fileName,
        },
      });
    } catch (e) {
      console.log('e requestImageUploadPermission(): ', (e as any).message || e);

      // set an error value which we can check for in uploadImage above to sniff for a failure
      return (
        {
          imageUploadData: IMAGE_UPLOAD_PERMISSION_ERROR.name,
        });
    }
  }

  toastConfig = {
    position: 'top-right',
    autoClose: 4000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
  } as ToastOptions;

  constructor(props: Props) {
    super(props);
    this.state = {
      issueDescription: '',
      submittedByString: '',
      riskTypeDescription: '',
      incidentDateTime: new Date(),
      isFactory: false,
      showDropdowns: false,
      showDetailsSection: false,
      sapNumber: '',
      category: '',
      selectedPriority: undefined,
      showPostponeIncidentModal: false,
    };
    this.fetchZones = this.fetchZones.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.handleSubmitterChange = this.handleSubmitterChange.bind(this);
    this.handleZoneChange = this.handleZoneChange.bind(this);
    this.handleDistCenterChange = this.handleDistCenterChange.bind(this);
    this.handleComplaintTypeChange = this.handleComplaintTypeChange.bind(this);
    this.handlepriorityStatusEnvRiskChange = this.handlepriorityStatusEnvRiskChange.bind(this);
    this.handleSecurityStatusChange = this.handleSecurityStatusChange.bind(this);
    this.handleUserChange = this.handleUserChange.bind(this);
    this.handleReportedByUserChange = this.handleReportedByUserChange.bind(this);
    this.handleSuggestionSelected = this.handleSuggestionSelected.bind(this);
    this.handleSuggestionSelectedReportedBy = this.handleSuggestionSelectedReportedBy.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleLocationDescriptionChange = this.handleLocationDescriptionChange.bind(this);
    this.handleRiskLevelChange = this.handleRiskLevelChange.bind(this);
    this.handleImmediateClosure = this.handleImmediateClosure.bind(this);
    this.handleIsSIFChange = this.handleIsSIFChange.bind(this);
    this.handleIsSIFTypeChange = this.handleIsSIFTypeChange.bind(this);
    this.handleRiskGroupChange = this.handleRiskGroupChange.bind(this);
    this.handleRiskTypeChange = this.handleRiskTypeChange.bind(this);
    this.handleRiskTypeDescriptionChange = this.handleRiskTypeDescriptionChange.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.handleSubLocationChange = this.handleSubLocationChange.bind(this);
    this.handleSubAreaChange = this.handleSubAreaChange.bind(this);
    this.handleCategory = this.handleCategory.bind(this);
    this.handleSapNumber = this.handleSapNumber.bind(this);
    this.handleCorrectiveActionChange = this.handleCorrectiveActionChange.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.handleIsValidated = this.handleIsValidated.bind(this);
    this.handleHasAnonymousReport = this.handleHasAnonymousReport.bind(this);
    this.setShowPostponeIncidentModal = this.setShowPostponeIncidentModal.bind(this);
  }

  componentDidMount(): void {
    // this.fetchZones();
  }

  componentDidUpdate(prevProps: any, prevState: any): void {
    const { data, t } = this.props;
    const userLastIncidentInfo = data?.getIncidentsByLastHour;
    if (prevProps?.data?.getIncidentsByLastHour
      !== data?.getIncidentsByLastHour
      && userLastIncidentInfo) {
      const { hasReachedLimit, firstIncidentDate } = userLastIncidentInfo;
      if (hasReachedLimit) {
        this.handleHasReachedLimit(firstIncidentDate);
      }
    }
    const { selectedRiskType, selectedComplaintType } = this.state;
    const prevRiskType = prevState.selectedRiskType?.get('name');
    const newRisk = selectedRiskType?.get('name');

    if (prevRiskType !== newRisk && selectedComplaintType === 'ENVIRONMENT') {
      let defaultCategory: string = '';
      let defaultPriority: number = 0;
      switch (selectedRiskType?.get('defaultPriorityClasification')) {
        case 'MP':
          defaultCategory = t(TRANSLATIONS.PROCEDURE);
          defaultPriority = 1;
          break;
        case 'HP':
          defaultCategory = t(TRANSLATIONS.PROCEDURE);
          defaultPriority = 2;
          break;
        case 'LZ':
          defaultCategory = 'ZBB';
          defaultPriority = 0;
          break;
        case 'MZ':
          defaultCategory = 'ZBB';
          defaultPriority = 1;
          break;
        case 'HC':
          defaultCategory = 'CAPEX';
          defaultPriority = 2;
          break;
        case 'HZ':
          defaultCategory = 'ZBB';
          defaultPriority = 2;
          break;
        case 'MC':
          defaultCategory = 'CAPEX';
          defaultPriority = 1;
          break;
        default:
          this.setDefaultCategoryAndPriority('', 0);
          break;
      }
      this.setDefaultCategoryAndPriority(defaultCategory, defaultPriority);
    }
  }

  setDefaultCategoryAndPriority(category: any, priority: any) {
    this.setState({ category });
    this.setState({ selectedPriority: priority });
  }

  handleCountryChange = (e: Event) => {
    // @ts-ignore
    const countryCode = e?.target?.value;
    const { countries } = this.state;
    const chosenCountry = countries?.find((country: CountryMap) => country.get('code') === countryCode);

    if (!chosenCountry) return;

    this.setState({
      selectedCountry: chosenCountry,
      selectedDistributionCenter: undefined,
      selectedZone: undefined,
      hasUsualWorkLocation: false,
    });
  };

  handleZoneChangeV2 = (zone: ZoneMap) => {
    if (!zone) return;

    this.setState({
      selectedZone: zone,
      selectedDistributionCenter: undefined,
      hasUsualWorkLocation: false,
    });
  };

  handleLocationChangeV2 = (location: any) => {
    this.setState({
      selectedDistributionCenter: location,
      showDetailsSection: true,
      hasUsualWorkLocation: false,
    });
  };

  handleLocationTypeChange = (locationType: any) => {
    this.fetchZones(locationType);
    this.setState({
      selectedLocationType: locationType,
      selectedIsOutsideDistCenter: undefined,
      isFactory: locationType === 'FACTORY',
      showDropdowns: true,
      loadingDataStatus: FETCH_STATUS.PENDING,
      showDetailsSection: false,
      selectedCountry: undefined,
      selectedDistributionCenter: undefined,
      selectedZone: undefined,

      zones: undefined,
      countries: undefined,
      incidentDateTime: new Date(),
      submittedByString: '',
      selectedComplaintType: undefined,
      selectedSecurityStatus: undefined,
      selectedUserName: '',
      selectedUserSlug: '',
      selectedReportedByUserName: '',
      selectedReportedByUserSlug: '',
      isUserNotListed: undefined,
      isReportedByUserNotListed: undefined,
      issueDescription: '',
      sapNumber: '',
      category: '',
      selectedPriority: undefined,
      selectedLocation: undefined,
      selectedSubLocation: undefined,
      selectedSubArea: undefined,
      locationDescription: '',
      selectedRiskGroup: undefined,
      selectedRiskType: undefined,
      isRiskTypeDescriptionVisible: undefined,
      riskTypeDescription: '',
      selectedRiskLevel: undefined,
      selectedImmediateClosure: undefined,
      isSIF: undefined,
      SIFType: undefined,
      correctiveAction: '',
      file: undefined,
      imageUploadData: undefined,
      isValidated: undefined,
      hasUsualWorkLocation: undefined,
      hasAnonymousReport: undefined,
    });
  };

  setUsualWorkLocation = async (usualWorkLocation: any) => {
    const { userData } = this.props;
    const isUserFromFactory = userData?.distributionCenter?.type === 'FACTORY';
    if (usualWorkLocation && isUserFromFactory) {
      await this.fetchZones('FACTORY');
      this.setState({
        selectedCountry: usualWorkLocation.country,
        selectedDistributionCenter: usualWorkLocation.location,
        selectedZone: usualWorkLocation.businessUnit,
        hasUsualWorkLocation: true,
      });
    } else {
      this.setState({
        selectedCountry: undefined,
        selectedDistributionCenter: undefined,
        selectedZone: undefined,
        hasUsualWorkLocation: false,
      });
    }
  };

  handleIsOutsideDistCenter = (IsOutsideDistCenter: any) => {
    this.setState({
      selectedIsOutsideDistCenter: IsOutsideDistCenter,
    });
  };

  handleOpenIncidentPostponePopup = () => {
    this.setState({ showPostponeIncidentModal: true });
  };

  setShowPostponeIncidentModal = (show: boolean) => {
    this.setState({ showPostponeIncidentModal: show });
  };

  handleHasReachedLimit(firstIncidentDate: Date) {
    const { t } = this.props;
    const formatDate = new Date(firstIncidentDate);
    formatDate.setHours(formatDate.getHours() + 1);

    let hours = formatDate.getHours();
    const minutes = formatDate.getMinutes();
    const seconds = formatDate.getSeconds();
    let period = 'AM';

    if (hours >= 12) {
      period = 'PM';
      if (hours > 12) {
        hours -= 12;
      }
    }

    if (hours === 0) {
      hours = 12;
    }

    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')} ${period}`;

    toast.warn(`${t(TRANSLATIONS.YOU_HAVE_REACH_THE_LIMIT_OF_REPORTS_PLEASE_TRY_AGAIN_AT, {
      date: formattedTime,
    })}`, this.toastConfig);
  }

  handleZoneChange(e: Event) {
    // @ts-ignore
    const zoneSlug = e?.target?.value;
    const { zones } = this.state;
    // find zone using slug
    const chosenZone = zones?.find((zone: ZoneMap) => zone.get('slug') === zoneSlug);

    if (!chosenZone) return;

    this.setState({
      selectedZone: chosenZone,
      selectedDistributionCenter: undefined,
    });
  }

  // region handlers
  handleDateChange(date: Date) {
    const { incidentDateTime } = this.state;

    const currentDateCopy = new Date(incidentDateTime);
    currentDateCopy.setFullYear(date.getFullYear());
    currentDateCopy.setMonth(date.getMonth());
    currentDateCopy.setDate(date.getDate());

    this.setState({ incidentDateTime: currentDateCopy });
  }

  handleTimeChange(data: any) {
    const { hour, minute } = data;
    const { incidentDateTime } = this.state;
    const currentDateCopy = new Date(incidentDateTime);
    currentDateCopy.setHours(hour);
    currentDateCopy.setMinutes(minute);
    currentDateCopy.setSeconds(0);

    this.setState({ incidentDateTime: currentDateCopy });
  }

  handleSubmitterChange(e: ChangeEvent<HTMLTextAreaElement>) {
    this.setState({ submittedByString: e.target.value });
  }

  handleDistCenterChange(e: Event) {
    // @ts-ignore
    const distCenterSlug = e?.target?.value;
    const { selectedZone } = this.state;

    if (!selectedZone) return;

    // find zone using slug
    const chosenDistributionCenter = (selectedZone as any)?.getIn(['distributionCenters'])
      .find((distCenter: any) => distCenter.get('slug') === distCenterSlug);

    this.setState({
      selectedDistributionCenter: chosenDistributionCenter,
    });
  }

  async fetchZones(type: any) {
    const factory = type === 'FACTORY';
    // const url = '/api/v1/zones/distribution-centers';
    const url = `/api/${factory ? 'v2' : 'v1'}/locations-for-incidents`;

    // mock zones
    // const zones = await arrayer(20, mockZone);
    // const zones = await request.get(url);

    const data = await request.get(url);
    const countryRegion = factory
      ? data
      && data.locationTypes.find((locationType: any) => locationType.type === 'FACTORY').countries
      : data;
    let sortedZones: any[] = [];
    let countries: any[] = [];
    let selectedDistributionCenter;
    let selectedZone;
    const qs = queryStrings.parse(this.props.location.search);

    countryRegion
      .forEach(({ name, code, zones }: { name: string, code: string, zones: Zone[] }) => {
        // for links that come from a QR code (They already include the distribution center slug)
        if (qs.dc) {
          for (let i = 0; i < zones.length; i++) {
            const zone = zones[i];
            const matchingDc = zone.locations?.find((dc: any) => dc.slug === qs.dc);

            if (matchingDc) {
              selectedDistributionCenter = matchingDc;
              selectedZone = zone;
              break;
            }
          }
        }

        countries = [
          ...countries,
          {
            name,
            code,
          },
        ].sort((a: any, b: any) => {
          if (a.name > b.name) return 1;
          return -1;
        });

        // sort
        zones.forEach((zone: Zone) => {
          zone.locations.sort((a: DistributionCenter, b: DistributionCenter) => {
            if (a.name > b.name) return 1;
            return -1;
          });
        });

        sortedZones = fromJS((zones.map((zone: Zone) => ({
          distributionCenters: zone.locations,
          ...zone,
        })).sort((a: Zone, b: Zone) => {
          if (a.name > b.name) return 1;
          return -1;
        })));
      });

    this.setState({
      countries: fromJS(countryRegion),
      zones: sortedZones,
      selectedDistributionCenter: fromJS(selectedDistributionCenter),
      selectedZone: fromJS(selectedZone),
      loadingDataStatus: FETCH_STATUS.SUCCESS,
    });
  }

  handleSuggestionSelected(e: any, { suggestion, suggestionValue }: any) {
    // handles picking a suggestions
    this.setState({
      selectedUserSlug: suggestion?.get('slug'),
      selectedUserName: suggestionValue,
      isUserNotListed: false,
    });
  }

  handleSuggestionSelectedReportedBy(e: any, { suggestion, suggestionValue }: any) {
    // handles picking a suggestions
    this.setState({
      selectedReportedByUserSlug: suggestion?.get('slug'),
      selectedReportedByUserName: suggestionValue,
      isReportedByUserNotListed: false,
    });
  }

  handleComplaintTypeChange(value: TICKET_TYPE) {
    const { t } = this.props;
    this.setState({
      selectedComplaintType: value,
      selectedSecurityStatus:
        value === TICKET_TYPE.ENVIRONMENT ? t(SECURITY_STATUS.INSECURE) : undefined,
      selectedRiskGroup: null,
      selectedRiskType: null,
    });
  }

  handleSecurityStatusChange(value: SECURITY_STATUS) {
    this.setState({
      selectedSecurityStatus: value,
      selectedRiskGroup: null,
      selectedRiskType: null,
    });
  }

  handleUserChange(event: any, { newValue, method }: any) {
    // handle text change only. handleSuggestionSelected() handles picking from suggestions
    if (method === 'type') {
      return this.setState({
        selectedUserName: newValue,
        isUserNotListed: true,
        selectedReportedByUserSlug: '',
        selectedReportedByUserName: '',
      });
    }
  }

  handleReportedByUserChange(event: any, { newValue, method }: any) {
    // handle text change only. handleSuggestionSelected() handles picking from suggestions
    if (method === 'type') {
      return this.setState({
        selectedReportedByUserName: newValue,
        isReportedByUserNotListed: true,
      });
    }
  }

  handleDescriptionChange(e: ChangeEvent<HTMLTextAreaElement>) {
    this.setState({ issueDescription: e.target.value });
  }

  handleLocationChange(chosenLocation: WorkLocationRecord) {
    this.setState({ selectedLocation: chosenLocation });
  }

  handleSubLocationChange(chosenSubLocation: NameAndSlugRecord) {
    this.setState({ selectedSubLocation: chosenSubLocation });
  }

  handleSubAreaChange(chosenSubArea: NameAndSlugRecord) {
    this.setState({ selectedSubArea: chosenSubArea });
  }

  handleCategory(chosenCategory: any) {
    this.setState({ category: chosenCategory });
    if (chosenCategory !== 'ZBB') {
      this.setState({ sapNumber: '' });
    }
  }

  handleSapNumber(e: ChangeEvent<HTMLTextAreaElement>) {
    this.setState({ sapNumber: e.target.value });
  }

  handleHasAnonymousReport(enabled: any) {
    this.setState({ hasAnonymousReport: enabled });
  }

  handlepriorityStatusEnvRiskChange(value: number) {
    this.setState({ selectedPriority: value });
  }

  handleLocationDescriptionChange(e: ChangeEvent<HTMLTextAreaElement>) {
    this.setState({ locationDescription: e.target.value });
  }

  handleRiskGroupChange(chosenRiskGroup: NameAndSlugRecord) {
    this.setState({
      selectedRiskGroup: chosenRiskGroup,
      selectedRiskType: null,
    });
  }

  handleRiskTypeChange(chosenRiskType: NameAndSlugRecord) {
    // check if 'other' is chosen
    if (chosenRiskType?.get('slug') === otherRiskType.slug) {
      return this.setState({
        selectedRiskType: fromJS(otherRiskType),
        isRiskTypeDescriptionVisible: true,
        riskTypeDescription: '',
      });
    }

    this.setState({
      selectedRiskType: chosenRiskType,
      isRiskTypeDescriptionVisible: false,
      riskTypeDescription: '',
    });
  }

  handleRiskTypeDescriptionChange(e: ChangeEvent<HTMLTextAreaElement>) {
    this.setState({ riskTypeDescription: e.target.value });
  }

  handleRiskLevelChange(value: RISK_LEVEL) {
    this.setState({ selectedRiskLevel: value });
  }

  handleImmediateClosure(value: YES_NO_OPTIONS) {
    this.setState({ selectedImmediateClosure: value });
  }

  handleIsSIFChange(value: YES_NO_OPTIONS) {
    this.setState({ isSIF: value });
    if (value === YES_NO_OPTIONS.YES) {
      this.handleIsSIFTypeChange(SIF_TYPES.PRECURSOR);
    }
    if (value === YES_NO_OPTIONS.NO) {
      this.handleIsSIFTypeChange(undefined);
    }
  }

  handleIsSIFTypeChange(value?: SIF_TYPES) {
    this.setState({ SIFType: value });
  }

  handleCorrectiveActionChange(e: ChangeEvent<HTMLTextAreaElement>) {
    this.setState({ correctiveAction: e.target.value });
  }

  handleIsValidated(isValidated: any) {
    this.setState({ isValidated });
  }

  handleFileChange(e: ChangeEvent<HTMLInputElement>) {
    const file = e.target?.files?.[0];
    if (file) {
      this.setState({ file });
      const { size } = file;
      const extension = file.name
        && `${file.name.match(/\.\w+$/)?.[0].substring(1)}`;
      // nullify any existing imageUploadData
      this.setState({
        imageUploadData: null,
      });
      if (size < 10000000) {
        AnonymousComplaint.requestImageUploadPermission(extension || file.type.split('/')[1], size)
          .then(res => this.setState({ imageUploadData: fromJS(res.imageUploadData) }))
          .catch(err => this.setState({ imageUploadData: err.imageUploadData }));
      }
    }
  }

  // endregion

  async uploadFile(imageUploadData = this.state.imageUploadData,
    file = this.state.file): Promise<any> {
    try {
      // const { imageUploadData, file } = this.state;

      // user hasn't picked a file
      if (!file || !file.size) return true;

      // in case there's an error in getting permission to upload the image to S3
      // in requestImageUploadPermission() below
      if (imageUploadData === IMAGE_UPLOAD_PERMISSION_ERROR.name) {
        throw IMAGE_UPLOAD_PERMISSION_ERROR;
      }

      // haven't received permission yet to upload image in requestImageUploadPermission() below
      if (!imageUploadData || !imageUploadData.size) {
        // check again in a sec if the request ImageUploadPermission() returned
        await delay(1000);
        return await this.uploadFile();
      }

      const url = imageUploadData.get('preSignedUrl');

      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/octet-stream',
        },
        body: file,
      });

      if (response && (response.status >= 200 && response.status <= 299)) {
        return console.log('uploadImage() uploaded image to S3 successfully');
      }

      throw Error(`error uploading image. Status ${response.status}`);
    } catch (e) {
      console.log('e uploadImage(): ', (e as any).message || e);
      throw e;
    }
  }

  async submitForm() {
    Loading.show();
    const { t, userData } = this.props;
    const { getIncidentsByLastHour } = this.props.data || {};
    const hasReachedLimit = getIncidentsByLastHour?.hasReachedLimit;
    const firstIncidentDate = getIncidentsByLastHour?.firstIncidentDate;
    if (!hasReachedLimit) {
      this.handleIsValidated(!hasReachedLimit);
    } else {
      this.handleHasReachedLimit(firstIncidentDate);
      return;
    }
    try {
      await this.uploadFile();

      const url = '/api/v1.1/submit-incident';

      const {
        selectedDistributionCenter,
        selectedComplaintType,
        selectedSecurityStatus,
        selectedUserName,
        selectedReportedByUserName,
        selectedUserSlug,
        selectedReportedByUserSlug,
        isUserNotListed,
        isReportedByUserNotListed,
        issueDescription,
        selectedLocation,
        locationDescription,
        selectedRiskType,
        selectedRiskLevel,
        correctiveAction,
        imageUploadData,
        submittedByString,
        riskTypeDescription,
        incidentDateTime,
        selectedSubLocation,
        isSIF,
        SIFType,
        selectedSubArea,
        isFactory,
        category,
        sapNumber,
        selectedPriority,
        selectedLocationType,
      } = this.state;

      const isSecure = selectedSecurityStatus === t(SECURITY_STATUS.SECURE);
      const incidentsPilotAccess = userData?.canAccessSIOSWebChangeRequest;

      const body = {
        incidentDateTime: incidentDateTime.getTime(),
        distCenterSlug: selectedDistributionCenter?.size ? selectedDistributionCenter?.get('slug') : selectedDistributionCenter,
        typeOfIssue: selectedComplaintType,
        isUnsafe: isSecure ? 0 : 1,
        doneBy: selectedUserName || undefined,
        doneBySlug: isUserNotListed ? undefined : selectedUserSlug,
        locationSlug: selectedLocation?.get('slug'),
        locationDescription: locationDescription || undefined,
        riskTypeDescription: riskTypeDescription || undefined,
        riskTypeSlug: (selectedRiskType?.get('slug')) || undefined,
        riskLevel: (!isSecure && selectedRiskLevel) || undefined,
        description: issueDescription,
        possibleSolution: (!isSecure && correctiveAction) || undefined,
        imageUrl: imageUploadData?.get('fileName'),
        source: 'website',
        locationType: 'INSIDE_DC',
        subLocation: selectedSubLocation?.get('slug'),
        employeeNumber: submittedByString || undefined,
        reportedBy: (userData?.roleDetails?.slug === Supply_User_Roles.KioskUser)
          ? (selectedReportedByUserName || undefined)
          : userData?.name,
        reportedBySlug: (userData?.roleDetails?.slug === Supply_User_Roles.KioskUser)
          ? (isReportedByUserNotListed ? undefined : selectedReportedByUserSlug)
          : userData?.slug,
        isSIF: isSIF === YES_NO_OPTIONS.YES ? (SIFType === SIF_TYPES.PRECURSOR ? 1 : 0) : undefined,
        specificSubLocationSlug: isFactory ? selectedSubArea?.get('slug') : undefined,
        category,
        SAPControlNumber: sapNumber === '' ? undefined : sapNumber,
        priority: selectedPriority,
      };

      return await request.post(url, body).then(value => {
        Loading.hide();
        if (
          value?.desiredStructure?.id
          && selectedLocationType === 'FACTORY'
          && selectedComplaintType !== TICKET_TYPE.ENVIRONMENT
          && isSIF !== YES_NO_OPTIONS.YES
          && SIFType !== SIF_TYPES.PRECURSOR
          && incidentsPilotAccess
        ) {
          this.setState({
            incidentToCloseId: value?.desiredStructure?.id,
            incidentTypeOfIssue: value?.desiredStructure?.typeOfIssue,
          });
        } else {
          Alert.alert({
            image: { src: ImageLinks.checked },
            title: t(selectedComplaintType === TICKET_TYPE.CONDITION
              ? TRANSLATIONS.CONDITION_REGISTERED_SUCCESSFULLY
              : TRANSLATIONS.ACT_REGISTERED_SUCCESSFULLY),
            description: t(selectedComplaintType === TICKET_TYPE.CONDITION
              ? TRANSLATIONS
                .THE_CONDITION_WAS_RECORDED_AND_WILL_BE_SENT_TO_THE_APPROPRIATE_DEPARTMENT
              : TRANSLATIONS.THE_ACT_WAS_RECORDED_AND_WILL_BE_SENT_TO_THE_APPROPRIATE_DEPARTMENT),
            buttons: [
              {
                title: t(TRANSLATIONS.GO_TO_SAFETY),
                className: 'icp-close-later',
                onPress: () => {
                  window.location.assign('/');
                },
              },
              {
                title: t(TRANSLATIONS.START_ANOTHER_REPORT),
                className: 'icp-close-now',
                onPress: () => {
                  window.location.reload();
                },
              },
            ],
          });
        }
      });
    } catch (e) {
      Loading.hide();
      console.log('e submitForm(): ', (e as any).message || e);
      throw e;
    }
  }

  render() {
    const { t } = this.props;
    const {
      countries,
      zones,
      selectedZone,
      selectedDistributionCenter,
      selectedCountry,
      selectedIsOutsideDistCenter,
      selectedLocationType,
      isFactory,
      showDropdowns,
      selectedSecurityStatus,
      loadingDataStatus,
      isValidated,
      hasUsualWorkLocation,
      incidentToCloseId,
      incidentTypeOfIssue,
      showPostponeIncidentModal,
      selectedRiskType,
    } = this.state;

    const renderBasicButton = () => {
      return (
        <>
          <BasicButton
            text={t(TRANSLATIONS.CONTINUE)}
            onClick={() => this.submitForm()}
            background={COLOR_CODE_NEW.COMPLEMENTARY}
            disabled={isValidated !== true}
          />
        </>
      );
    };

    return (
      <div
        className="anonymous-complaint"
      >
        {/* page */}
        <div className="container step-page">
          {/* <SteppedPagination
            t={t}
            navigationClassName="nav-footer"
            buttonStyle={{ width: 274, marginLeft: 8 }}
          > */}
          <div>
            <DefineLocation
              countries={countries}
              zones={zones}
              selectedZone={selectedZone}
              selectedCountry={selectedCountry}
              selectedDistributionCenter={selectedDistributionCenter}
              selectedIsOutsideDistCenter={selectedIsOutsideDistCenter}
              selectedLocationType={selectedLocationType}
              handleZoneChangeV2={this.handleZoneChangeV2}
              handleLocationChangeV2={this.handleLocationChangeV2}
              handleDistCenterChange={this.handleDistCenterChange}
              handleCountryChange={this.handleCountryChange}
              handleLocationTypeChange={this.handleLocationTypeChange}
              setUsualWorkLocation={this.setUsualWorkLocation}
              handleIsOutsideDistCenter={this.handleIsOutsideDistCenter}
              isFactory={isFactory}
              showDropdowns={showDropdowns}
              hasUsualWorkLocation={hasUsualWorkLocation}
              // handlefactoriesLocationsData={this.handlefactoriesLocationsData}
              t={t}
              loadingDataStatus={loadingDataStatus}
            />
            {(this.state.showDetailsSection || selectedDistributionCenter) && (
              <>
                <Separator />
                <FormDetails
                  handleDateChange={this.handleDateChange}
                  handleTimeChange={this.handleTimeChange}
                  handleSubmitterChange={this.handleSubmitterChange}
                  handleComplaintTypeChange={this.handleComplaintTypeChange}
                  handlepriorityStatusEnvRiskChange={this.handlepriorityStatusEnvRiskChange}
                  handleCorrectiveActionChange={this.handleCorrectiveActionChange}
                  handleDescriptionChange={this.handleDescriptionChange}
                  handleFileChange={this.handleFileChange}
                  handleLocationChange={this.handleLocationChange}
                  handleSubLocationChange={this.handleSubLocationChange}
                  handleLocationDescriptionChange={this.handleLocationDescriptionChange}
                  handleRiskLevelChange={this.handleRiskLevelChange}
                  handleImmediateClosure={this.handleImmediateClosure}
                  handleIsSIFChange={this.handleIsSIFChange}
                  handleIsSIFTypeChange={this.handleIsSIFTypeChange}
                  handleRiskGroupChange={this.handleRiskGroupChange}
                  handleRiskTypeChange={this.handleRiskTypeChange}
                  handleSecurityStatusChange={this.handleSecurityStatusChange}
                  handleSuggestionSelected={this.handleSuggestionSelected}
                  handleSuggestionSelectedReportedBy={this.handleSuggestionSelectedReportedBy}
                  handleUserChange={this.handleUserChange}
                  handleReportedByUserChange={this.handleReportedByUserChange}
                  handleRiskTypeDescriptionChange={this.handleRiskTypeDescriptionChange}
                  handleSubAreaChange={this.handleSubAreaChange}
                  handleCategory={this.handleCategory}
                  handleSapNumber={this.handleSapNumber}
                  handleHasAnonymousReport={this.handleHasAnonymousReport}
                  selectedCountry={selectedCountry}
                  {...this.state}
                  distCenterSlug={selectedDistributionCenter?.size ? selectedDistributionCenter?.get('slug') : selectedDistributionCenter}
                  t={t}
                  isFactory={isFactory}
                  handleIsValidated={this.handleIsValidated}
                />
                <Separator />
              </>
            )}
          </div>
          {selectedSecurityStatus ? (
            <div className="row justify-content-center">
              {renderBasicButton()}
            </div>
          ) : null}
          {/* <SteppedPagination.Page> */}
          {/* <FormReview
            submitRequestStatus={asyncTrack.fetchStatus?.submit}
            {...this.state}
            submitForm={() => asyncTrack.makeRequest('submit', this.submitForm(), true)}
            history={history}
            t={t}
          /> */}
          {/* </SteppedPagination.Page> */}
          {/* </SteppedPagination> */}
          <IncidentsClosePopup
            incidentId={incidentToCloseId}
            isImageRequired={selectedRiskType?.get('isGoldenRule')}
            handlePopupClose={() => this.setState({ incidentToCloseId: undefined })}
            requestImageUploadPermission={AnonymousComplaint.requestImageUploadPermission}
            uploadFile={this.uploadFile}
            incidentTypeOfIssue={incidentTypeOfIssue}
            handleOpenIncidentPostponePopup={this.handleOpenIncidentPostponePopup}
          />
          <IncidentsPostponePopup
            showPostponeIncidentModal={showPostponeIncidentModal}
            setShowPostponeIncidentModal={this.setShowPostponeIncidentModal}
            incidentId={incidentToCloseId}
            incidentTypeOfIssue={incidentTypeOfIssue}
            refresh={() => window.location.reload()}
          />
        </div>
      </div>
    );
  }
}

type Props = {
  history?: any;
  asyncTrack: any;
  match: any;
  location: any;
  t: any;
  userData?: any;
  data?: any;
};
type State = {
  zones?: any;
  countries?: any;
  selectedZone?: ZoneMap;
  selectedCountry?: CountryMap;
  selectedDistributionCenter?: DistributionCenterMap;
  selectedLocationType?: LOCATION_TYPES;
  selectedIsOutsideDistCenter?: any;
  // form details
  incidentDateTime: Date,
  submittedByString: string;
  selectedComplaintType?: TICKET_TYPE;
  selectedSecurityStatus?: SECURITY_STATUS;
  selectedUserName?: string; // when typed (not found in dropdown)
  selectedUserSlug?: string; // when chosen from dropdown
  selectedReportedByUserName?: string; // for user name that reported the incident
  selectedReportedByUserSlug?: string; // for user slug that reported the incident
  isUserNotListed?: boolean; // when user is typed
  isReportedByUserNotListed?: boolean;
  issueDescription: string;
  sapNumber: string;
  category: string;
  selectedPriority?: number;
  selectedLocation?: WorkLocationRecord;
  selectedSubLocation?: NameAndSlugRecord;
  selectedSubArea?: NameAndSlugRecord;
  locationDescription?: string;
  selectedRiskGroup?: NameAndSlugRecord;
  selectedRiskType?: NameAndSlugRecord;
  isRiskTypeDescriptionVisible?: boolean;
  riskTypeDescription: string;
  selectedRiskLevel?: RISK_LEVEL;
  selectedImmediateClosure?: YES_NO_OPTIONS;
  isSIF?: YES_NO_OPTIONS;
  SIFType?: SIF_TYPES;
  correctiveAction?: string;
  file?: any;
  imageUploadData?: any;
  isFactory: boolean;
  showDropdowns: boolean;
  showDetailsSection: boolean;
  loadingDataStatus?: FETCH_STATUS
  isValidated?: any;
  hasUsualWorkLocation?: boolean;
  hasAnonymousReport?: boolean;
  incidentToCloseId?: number;
  incidentTypeOfIssue?: TICKET_TYPE;
  showPostponeIncidentModal: boolean;
};

const mapStateToProps = (state: any) => {
  return {
    userData: state.get('user')?.toJS(),
  };
};

const WithQuery = (HOCComponent: any) => {
  const WrappedComponent = (props: any) => {
    const user = useSelector(selectUser);
    const isUserMAZ = user?.getIn(['distributionCenter', 'country']) !== 'PY';
    const isLogistics = user?.getIn(['distributionCenter', 'type']) === 'DISTRIBUTION_CENTER';
    const canBeLimited = canUserBeLimitedByHourSios(user?.get('features'));
    const [runQuery, { data }] = useLazyQuery(GET_INCIDENTS_BY_LAST_HOUR, {
      variables: { userId: user?.get('id') },
      fetchPolicy: 'network-only',
    });
    useEffect(() => {
      if (isUserMAZ && canBeLimited && isLogistics) {
        runQuery();
      }
    // eslint-disable-next-line
    }, []);
    return <HOCComponent {...props} data={data} />;
  };
  return WrappedComponent;
};

export default connect(
  mapStateToProps,
)(
  // @ts-ignore
  withTranslation()(withAsyncTrack(WithQuery(withLocation(AnonymousComplaint)))),
);
