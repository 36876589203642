import { MonthlyOptions } from 'components/MonthlyTimeFilter/MonthlyTimeFilter';
import moment from 'moment';
import { format } from 'date-fns';

export const SET_TIME_FILTER = 'time-filter/set-time-filter';
export const SET_TIME_FILTER_RAW = 'time-filter/set-time-filter-raw';

export function setTimeFilter(time: { startTime: Date, endTime: Date, slug: string }) {
  return { type: SET_TIME_FILTER_RAW, time };
}

export function setMonthlyDropdownChange(option: any) {
  let time = {};
  if (option?.hasOwnProperty('startTime')) {
    time = {
      startTime: option?.startTime || moment().clone().startOf('month').toISOString(),
      endTime: option?.endTime || moment().clone().endOf('month').toISOString(),
    };
  } else {
    const selectedPeriod = MonthlyOptions.find(period => period.value === option);
    time = {
      startTime: selectedPeriod?.startTime || moment().clone().startOf('month').toISOString(),
      endTime: selectedPeriod?.endTime || moment().clone().endOf('month').toISOString(),
    };
  }

  return { type: SET_TIME_FILTER, ...time, dropdownValue: option?.startTime ? format(new Date(), 'yyyy') : option };
}
