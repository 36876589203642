import { UserMap } from 'types/interfaces';

export const capitalizeFirst = (word: string) => {
  if (!word || !word.length) return '';
  return word.charAt(0).toUpperCase() + word.toLowerCase().substring(1);
};

export function userNameExtractor(user: UserMap) {
  return user.get('employeeNumber') ? `${user.get('name')} **${user.get('employeeNumber').slice(-2)}` : `${user.get('name')}`;
}

export function getShortMonth(month: string) {
  return month.substring(0, 3);
}

export function capitalize(input: string) {
  return input.toLowerCase().split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
}
