interface DistributionCenter {
  hasAccess: boolean;
  id: number;
  name: string;
}

interface SubZone {
  id: number;
  name: string;
  slug: string;
  distributionCenters: DistributionCenter[];
}

interface Zone {
  businessUnitId: number;
  id: number;
  name: string;
  slug: string;
  subzones: SubZone[];
}

export interface AccessLocation {
  id: number;
  name: string;
  slug: string;
  zones: Zone[];
}

export const getFormattedData = (params: any) => {
  if (params) {
    const { businessUnits, arrayOfAccessLocations } = params;

    return businessUnits?.map((bu: any) => ({
      ...bu,
      zones: bu.zones.map((zone: any) => ({
        ...zone,
        subzones: zone.subzones.map((subzone: any) => ({
          ...subzone,
          distributionCenters: subzone.distributionCenters.map((dc: any) => ({
            ...dc,
            hasAccess: arrayOfAccessLocations?.includes(dc.id),
          })),
        })),
      })),
    }));
  }
};

export const getAccessLocationsIds = (params: AccessLocation[]) => {
  const locationsIds: number[] = [];
  params.forEach(item => {
    item.zones.forEach(zone => {
      zone.subzones.forEach(subzone => {
        subzone.distributionCenters.forEach(center => {
          if (center.hasAccess) {
            locationsIds.push(center.id);
          }
        });
      });
    });
  });
  return locationsIds;
};
