import axios from 'axios';

export async function requestImageUploadPermission(imageExtension: string, target: string) {
  try {
    const url = '/api/v1/image-upload';
    /*
    image validation on server side is checking if file extension is in between 3 to 4 length
    so we are splitting it in the next line of code and if file extension is not
    available the we are hard coding the image type
    */
    let fileExtension = 'jpeg';
    if (imageExtension.includes('/')) {
      [, fileExtension] = imageExtension.split('/');
    }
    return await axios.post(url, {
      fileExtension,
      target,
    });
  } catch (e) {
    console.log('e requestImageUploadPermission(): ', (e as Error).message || e);
  }
}

export async function uploadObjectImage(presignedUrl: string, uri: string) {
  try {
    const url = presignedUrl;

    const img = await fetch(uri);
    const blob = await img.blob();

    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/octet-stream',
      },
      body: blob,
    });

    if (response && response.status >= 200 && response.status <= 299) {
      console.log('uploadImage() uploaded image to S3 successfully');
      /*
      returning the parsed url
      (eg -https://arvolution-safety-development.s3.us-east-2.amazonaws.com/ACTION_LOG/c792a13b-e153-49ca-baa6-1e25d331dafa.jpeg )
      received in response from S3
      as when we created the manual url
      with function getImageUrlForS3 (removed after discussion),
      it is not accessible globally because it lack time zone
      */
      return response.url.split('?')[0];
    }
  } catch (e) {
    console.log('e uploadImage(): ', (e as Error).message || e);
  }
}
