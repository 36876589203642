import React, { useRef, useState } from 'react';
import { Tooltip } from '@mui/material';
import ImageViewer from 'react-simple-image-viewer';

import { ACTION_LOG_SUMMARY_MODAL_STATUS, TRANSLATIONS } from 'types/enums';
import { ImageInputItem } from 'generated/graphql';
import ImageLinks from 'utils/ImageLinks';
import { useTranslation } from 'react-i18next';

import './ImageSectionForSummaryModalStyle.scss';

type ImageSectionForSummaryModalProps = {
  images: ImageInputItem[];
  modalStatus: ACTION_LOG_SUMMARY_MODAL_STATUS;
  onImageAddHandler: (url: string, extension: string) => void;
  onImageRemoveHandler: (uri: string) => void;
};

function ImageSectionForSummaryModal(props: ImageSectionForSummaryModalProps) {
  const { t } = useTranslation();
  const { images, modalStatus, onImageAddHandler, onImageRemoveHandler } = props;
  const [currentImage, setCurrentImage] = useState<number | undefined>(undefined);
  const uploadInputRef = useRef<HTMLInputElement>(null);

  function openImageViewer(index: number) {
    setCurrentImage(index);
  }

  function closeImageViewer() {
    setCurrentImage(undefined);
  }

  function handleImageUpload(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files[0]) {
      const img = e.target.files[0];
      const url = URL.createObjectURL(img);
      onImageAddHandler(url, img.type);
    }
  }
  function handleImageUploadRef() {
    if (uploadInputRef?.current) {
      uploadInputRef.current.click();
    }
  }
  const addImageTxt = `${t(TRANSLATIONS.ADD)} ${t(TRANSLATIONS.IMAGE)}`;
  return (
    <>
      <div className="evidence_sections">
        {modalStatus !== ACTION_LOG_SUMMARY_MODAL_STATUS.SUMMARY && (
          <Tooltip title="Click to add images" arrow placement="top">
            <div
              role="presentation"
              onClick={handleImageUploadRef}
              key={100}
              className="evidence_sections__image add_more_images_container"
            >
              <img className="add_more_image" src={ImageLinks.attachFile} alt="evidence" />
              <p className="add_image_text">{addImageTxt}</p>
              <input
                type="file"
                ref={uploadInputRef}
                accept="image/*"
                style={{ display: 'none' }}
                onChange={handleImageUpload}
              />
            </div>
          </Tooltip>
        )}
        {images?.length
          && images?.map((image, index) => (
            <Tooltip key={image.id} title="Click to preview images" arrow placement="top">
              <div className="evidence_sections__image">
                <img
                  className="responsive_img"
                  src={image?.url}
                  alt="evidence"
                  onClick={() => openImageViewer(index)}
                />
                {ACTION_LOG_SUMMARY_MODAL_STATUS.SUMMARY !== modalStatus && (
                  <img
                    onClick={() => onImageRemoveHandler(image.url)}
                    title="Remove"
                    src={ImageLinks.grayCross}
                    className="delete_image"
                    alt="delete"
                  />
                )}
              </div>
            </Tooltip>
          ))}
      </div>
      {currentImage !== undefined && images.length && (
        <ImageViewer
          src={[...images.map(image => image?.url || '')]}
          currentIndex={currentImage}
          disableScroll={false}
          onClose={closeImageViewer}
        />
      )}
    </>
  );
}

export default ImageSectionForSummaryModal;
