import React from 'react';
import Chart from 'react-apexcharts';
import LoadingSpinner from 'components/LoadingSpinner';
import ChartOptions from '../ChartOptions';
import '../CashlessBar.scss';

type Props = {
  bu: string[]
  progress: number[]
  closed: number[]
  pending: number[]
  loading?: boolean
};

const ComplainceChart = (
  props: Props,
) => {
  const {
    bu,
    progress,
    closed,
    pending,
    loading,
  } = props;
  const series = [
    {
      name: 'Pendientes',
      data: pending,
      type: 'column',
    },
    {
      name: 'Cerrados',
      data: closed,
      type: 'column',
    },
    {
      name: 'Con retraso',
      data: progress,
      type: 'column',
    },
  ];

  if (loading) {
    return (
      <div>
        <LoadingSpinner height={271} />
      </div>
    );
  }

  return (
    <div className="cashless-bar">
      <Chart
        type="bar"
        // @ts-ignore
        options={ChartOptions(bu)}
        series={series}
        height="300px"
      />
    </div>
  );
};

export default ComplainceChart;
