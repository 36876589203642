import React from 'react';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';
import './ErrorMessageStyles.scss';

type Props = {
  title?: string;
  errorImage?: any;
  buttonText?: string;
  className?: string;
  onClickReload?: any;
};

const ErrorMessage = (props: Props) => {
  const { t } = useTranslation();
  const { title = t(TRANSLATIONS.SOMETHING_WENT_WRONG_TRY_LATER), errorImage, buttonText, className = '', onClickReload } = props;
  return (
    <div className="errorMessage">
      <div className={`error-message-container ${className}`}>
        { errorImage && (
        <img
          src={errorImage}
          className="error-img"
          alt="error"
        />
        )}
        { title
        && (
        <h5 className="message-title">{title}</h5>
        )}
        { buttonText
        && (
        <button type="button" onClick={onClickReload} className="reload-page">
          {buttonText}
        </button>
        )}
      </div>
    </div>
  );
};

export default ErrorMessage;
