import React, { useRef, useState, useEffect } from 'react';
import './Resources.scss';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';
import { toast } from 'react-toastify';
import Modal from 'components/Modal';
import axios from 'axios';
import ProgressBar from 'components/ProgressBar';
import BasicButton from 'components/Button/BasicButton';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/selectors/user.selectors';
import {
  selectSelectedLocationsSlug,
  selectSelectedLocation,
  selectGlobalFilters,
} from 'store/selectors/globalFilters.selectors';
import ImageLinks from 'utils/ImageLinks';
import { canUserAccessRoadSafetyResources } from 'utils/shared/canUserAccessFeature';
import { format } from 'date-fns';
import i18next from 'i18next';
import { es, enUS } from 'date-fns/locale';
import { useGetLastUploadedAtTimestampLazyQuery } from 'generated/graphql';
import LoadingSpinner from 'components/LoadingSpinner';

const { CancelToken } = axios;

export const roadSafetyResourcesPosition = [
  'JEFE DE SEGURIDAD',
  'JEFE DE ALMACÉN',
  'JEFE DE OPERACIONES',
  'SUPERVISOR DE ALMACÉN',
  'SUPERVISOR DE SEGURIDAD',
  'LIDER CD SAFETY',
  'LIDER CD FLEET',
  'GERENTE DE OPERACIONES',
];

const Resources = () => {
  const { t } = useTranslation();

  const fileRef = useRef<HTMLInputElement>(null);

  const cancelSource = useRef<any>(null);
  const isRequestCancelledManually = useRef<any>(false);

  const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
  const [progressPercentage, setProgressPercentage] = useState<number>(0);
  const [uploadError, setUploadError] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<any>('');
  const [errorMessage, setErrorMessage] = useState<any>('');

  const { location } = useSelector(selectSelectedLocationsSlug);
  const userDetails = useSelector(selectUser);
  const selectedDC = useSelector(selectSelectedLocation);
  const globalFilters = useSelector(selectGlobalFilters);

  const [callGetLastUploadedAtTimestampLazyQuery,
    { data: lastUploadedData }] = useGetLastUploadedAtTimestampLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const callcallGetLastUploadedAtTimestampApi = () => {
    callGetLastUploadedAtTimestampLazyQuery({
      variables: {
        locationSlug: selectedDC?.locationSlug,
      },
    });
  };

  const handleTruckFileUpload = () => {
    if (fileRef.current !== null) {
      fileRef.current.click();
    }
  };

  useEffect(() => {
    if (selectedDC?.locationSlug) {
      callcallGetLastUploadedAtTimestampApi();
    }
  // eslint-disable-next-line
  }, [selectedDC?.locationSlug]);

  const onPressCancelRequest = () => {
    setShowUploadModal(false);
    setUploadError(false);
    setProgressPercentage(0);
    if (cancelSource?.current) {
      isRequestCancelledManually.current = true;
      cancelSource.current.cancel();
    }
  };

  const closeModal = () => {
    setProgressPercentage(0);
    setShowUploadModal(false);
    setUploadError(false);
  };

  const handleAttachmentsUpload = async (e: any) => {
    setUploadError(false);
    setProgressPercentage(0);
    cancelSource.current = CancelToken.source();
    setShowUploadModal(true);
    const { files } = e.target;
    if (files?.length) {
      setSelectedFile(files);
      const config = {
        headers: { 'content-type': 'multipart/form-data' },
      };
      const data = new FormData();

      data.set('file', files[0]);
      data.set('uploadedByUserEmail', userDetails?.get('email'));
      data.set('uploadedByUserName', userDetails?.get('name'));
      data.set('locationSlug', selectedDC?.locationSlug);

      try {
        const res = await axios({
          url: '/api/v1/roadsafety/upload-trucks',
          method: 'POST',
          onDownloadProgress: (progressEvent: any) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setProgressPercentage(percentCompleted);
          },
          cancelToken: cancelSource.current.token,
          data,
          params: config,
        });
        if (res?.data?.isSuccess) {
          toast.success('Uploaded Successfully!');
          callcallGetLastUploadedAtTimestampApi();
          closeModal();
          if (fileRef.current !== null) {
            fileRef.current.value = '';
          }
        } else {
          setUploadError(true);
          setErrorMessage(res?.data?.message ?? t(TRANSLATIONS.LOADING_ERROR_PLEASE_RETRY));
        }
      } catch {
        setUploadError(true);
      }
      // e.target.value = '';
    }
  };

  const handleAttachmentsRetryUpload = async () => {
    cancelSource.current = CancelToken.source();
    setShowUploadModal(true);
    setUploadError(false);
    setProgressPercentage(0);
    if (selectedFile?.length) {
      const config = {
        headers: { 'content-type': 'multipart/form-data' },
      };
      const data = new FormData();

      data.set('file', selectedFile[0]);
      data.set('uploadedByUserEmail', userDetails?.get('email'));
      data.set('uploadedByUserName', userDetails?.get('name'));
      data.set('locationSlug', selectedDC?.locationSlug);

      try {
        const res = await axios({
          url: '/api/v1/roadsafety/upload-trucks',
          method: 'POST',
          onDownloadProgress: (progressEvent: any) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setProgressPercentage(percentCompleted);
          },
          cancelToken: cancelSource.current.token,
          data,
          params: config,
        });
        if (res?.data?.isSuccess) {
          toast.success('Uploaded Successfully!');
          callcallGetLastUploadedAtTimestampApi();
          closeModal();
          if (fileRef.current !== null) {
            fileRef.current.value = '';
          }
        } else {
          setUploadError(true);
          setErrorMessage(res?.data?.message ?? t(TRANSLATIONS.LOADING_ERROR_PLEASE_RETRY));
        }
      } catch {
        setUploadError(true);
      }
    }
  };

  const ResourcesEmptyPage = () => {
    return (
      globalFilters?.size ? (
        <div className="roadSafetyResources__selectDc">
          <img src={ImageLinks.selectDc} alt="Select Location" />
          <div className="roadSafetyResources__selectDc__info">
            <p className="roadSafetyResources__selectDc__info__title">{t(TRANSLATIONS.SELECT_A_DISTRIBUTION_CENTER)}</p>
            <p className="roadSafetyResources__selectDc__info__subTitle">{t(TRANSLATIONS.IN_ORDER_TO_ACCESS_THE_ROAD_SAFETY_SETTINGS_IT_IS_NECESSARY_TO_SELECT_A_LOCATION_USING_THE_TOP_BAR)}</p>
          </div>
        </div>
      ) : (
        <LoadingSpinner height={30} style={{ position: 'fixed', top: '50%', left: '50%' }} />
      )
    );
  };

  const downloadTemplate = () => {
    window.open('https://arvolution-safety-development.s3.us-east-2.amazonaws.com/roadSafety/rs9/RS9+Plantilla+Faro%CC%81l+de+camio%CC%81nV2.xlsx', '_blank');
  };

  const downloadDeliveryTruckRequirements = () => {
    window.open('https://arvolution-safety-development.s3.us-east-2.amazonaws.com/roadSafety/RS6/Requisitos+Entregador-Camio%CC%81n.pdf', '_blank');
  };

  const downloadRiskPathFlow = () => {
    window.open('https://arvolution-safety-development.s3.us-east-2.amazonaws.com/roadSafety/RS6/Flujo+Ruta+de+Riesgo.pdf', '_blank');
  };

  const uploadFile = () => {
    if (fileRef.current !== null) {
      fileRef.current.value = '';
    }
    handleTruckFileUpload();
  };

  return (
    <div className="roadSafetyResources">
      {showUploadModal && (
      <Modal
        modalContainerStyles={{
          width: '70vw',
          left: '20%',
          maxHeight: '90vh',
          minHeight: '20vh',
          height: 'fit-content',
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
        }}
      >
        <div className="roadSafetyResources__uploadModal">
          <div className="roadSafetyResources__uploadModal__tileWrap">
            <p className="roadSafetyResources__uploadModal__tileWrap__title">{uploadError ? `${t(TRANSLATIONS.SOMETHING_WENT_WRONG)}...` : `${t(TRANSLATIONS.UPLOADING_FILE)}...`}</p>
            {uploadError ? (
              <div role="button" onClick={closeModal}>
                <img src={ImageLinks.deleteCircle} alt="closeModal" />
              </div>
            ) : null}
          </div>
          <>
            {uploadError ? (
              <p className="roadSafetyResources__uploadModa__description">{errorMessage}</p>
            ) : (
              <div className="roadSafetyResources__uploadModal__progress_bar">
                <p className="roadSafetyResources__uploadModal__progress_bar__title">{progressPercentage}%</p>
                <ProgressBar bgcolor="#4472C4" height={16} progress={progressPercentage} />
              </div>
            )}
          </>
          {uploadError ? (
            <div className="roadSafetyResources__uploadModal__button_container">
              <BasicButton text={t(TRANSLATIONS.RETRY_AGAIN)} className="roadSafetyResources__uploadModal__button_container__download_button" onClick={handleAttachmentsRetryUpload} />
              <BasicButton text={t(TRANSLATIONS.UPLOAD_ANOTHER_FILE)} className="roadSafetyResources__uploadModal__button_container__cancel_button" onClick={uploadFile} />
            </div>
          ) : (
            <div className="roadSafetyResources__uploadModal__button_retry_container">
              <BasicButton text={t(TRANSLATIONS.CANCEL)} className="roadSafetyResources__uploadModal__button_retry_container__cancel_button" onClick={!uploadError ? onPressCancelRequest : closeModal} />
            </div>
          )}
        </div>
      </Modal>
      )}
      {location ? (
        <>
          <p className="roadSafetyResources__title">{t(TRANSLATIONS.RESOURCES)}</p>
          {(roadSafetyResourcesPosition.includes(userDetails?.get('position')?.toUpperCase()) && canUserAccessRoadSafetyResources(userDetails?.getIn(['roleDetails', 'slug']))) ? (
            <div className="roadSafetyResources__truckArchive">
              <p className="roadSafetyResources__truckArchive__title">{t(TRANSLATIONS.TRUCK_ARCHIVE)}</p>
              <div className="roadSafetyResources__truckArchive__uploadContainer">
                <div role="button" onClick={uploadFile}>
                  <p className="roadSafetyResources__truckArchive__uploadContainer__uploadNewTruckFile-title">{t(TRANSLATIONS.UPLOAD_NEW_TRUCK_FILE)}</p>
                </div>
                {lastUploadedData?.getLastUploadedAtTimestamp?.updatedAt ? (
                  <p className="roadSafetyResources__truckArchive__uploadContainer__lastUpdated-title">{format(new Date(lastUploadedData?.getLastUploadedAtTimestamp?.updatedAt), `'${t(TRANSLATIONS.LAST_UPDATED_ON_RS)}' d ${i18next.language === 'es' ? "'de'" : ''} MMMM, yyyy`, { locale: (i18next.language === 'es' ? es : enUS) })}</p>
                ) : null}
              </div>
              <div className="roadSafetyResources__truckArchive__downloadContainer" role="button" onClick={downloadTemplate}>
                <p className="roadSafetyResources__truckArchive__downloadContainer__downloadTemplate-title">{t(TRANSLATIONS.DOWNLOAD_TEMPLATE)}</p>
              </div>
            </div>
          ) : null}
          <div className="roadSafetyResources__informationResources">
            <p className="roadSafetyResources__informationResources__title">{t(TRANSLATIONS.INFORMATION_RESOURCES)}</p>
            <div role="button" onClick={downloadDeliveryTruckRequirements} className="roadSafetyResources__informationResources__delivererContainer">
              <p className="roadSafetyResources__informationResources__delivererContainer__delivererTruckPdf-title">{t(TRANSLATIONS.DELIVERER_TRUCK_REQUIREMENTS_PDF)}</p>
            </div>
            <div role="button" onClick={downloadRiskPathFlow} className="roadSafetyResources__informationResources__riskFlowContainer">
              <p className="roadSafetyResources__informationResources__riskFlowContainer__riskFlowPdf-title">{t(TRANSLATIONS.RISK_PATH_FLOW_PDF)}</p>
            </div>
          </div>
          <input
            ref={fileRef}
            className="hidden"
            type="file"
            onChange={handleAttachmentsUpload}
            name="file"
            multiple
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          />
        </>
      ) : (
        <ResourcesEmptyPage />
      )}
    </div>
  );
};

export default Resources;
