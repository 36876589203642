import React from 'react';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';

import file from 'assets/icons/file.svg';
import downloadIconBlack from 'assets/icons/download-black.svg';

import './FilePreview.scss';

const FilePreview = ({ file: {
  fileType,
  url,
} } : { file: {
  fileType: string,
  url: string,
} }) => {
  const { t } = useTranslation();

  const handleClick = () => {
    window.open(url, '_blank');
  };

  return (
    <div
      className="file-preview"
      style={{
        backgroundImage: `url(${fileType === 'image' ? url : file})`,
      }}
    >
      <button type="button" onClick={handleClick}>
        <img src={downloadIconBlack} alt="download" height={12} />
        {t(TRANSLATIONS.DOWNLOAD)}
      </button>
    </div>
  );
};

export default FilePreview;
