/* eslint-disable @typescript-eslint/naming-convention */
import {
  GetIncidentsByMonthsCategoriesAndTypeQuery,
  GetIncidentsByTypePerBuQuery,
} from 'generated/graphql';
import { BU, TRANSLATIONS } from 'types/enums';

type IncidentsObjectType = {
  [key: string]: any;
};

export enum DMS_CATEGORY {
  CRITICAL_SPEEDING = 'CRITICAL_SPEEDING',
  REGULAR_SPEEDING = 'REGULAR_SPEEDING',
  CURVE_SPEEDING = 'CURVE_SPEEDING',
  HARSH_TURNING = 'HARSH_TURNING',
  SEATBELT = 'SEATBELT',
}

export enum DMSSelectorTypes {
  perWeekGraph = 'perWeekGraph',
  perMonthGraph = 'perMonthGraph',
  perCategoryGraph = 'perCategoryGraph',
  table = 'table',
  tablePerBu = 'tablePerBu',
}

export enum DMSSelectorSubTypes {
  topOffenders = 'topOffenders',
  topBest = 'topBest',
  topVehicleOffenders = 'topVehicleOffenders',
}

export enum DMSDropDowns {
  month = 'month',
  year = 'year',
  bu = 'bu',
}

export enum DMSBuList {
  buList = 'buList',
}

type NameAndIncidentPropsForDMSGraphs = {
  name: string;
  totalIncidents: number;
};

type WeeksParametersForPerWeekGraph = {
  week: string;
  totalIncidents: number;
};

type PerWeekGraphDataType = {
  bu: string,
  month: string,
  weeks: WeeksParametersForPerWeekGraph[];
};

export type PerMonthGraphDataType = {
  bu: string,
  months: NameAndIncidentPropsForDMSGraphs[];
};

export type PerCategoryGraphDataType = {
  categoryName: string,
  bus: NameAndIncidentPropsForDMSGraphs[];
};

export const perWeekGraphHelper = (inputData: PerWeekGraphDataType[]) => {
  const perWeekGraphData: IncidentsObjectType = {
    weeks: [],
    [BU.Mexico]: [],
    [BU.Ecuador]: [],
    [BU.Colombia]: [],
    [BU.Perú]: [],
    [BU.CAC]: [],
  };

  if (inputData.length !== 0) {
    inputData.forEach(item => {
      const incidentArr: number[] = [];
      const weeksArr: string[] = [];
      if (item.weeks.length !== 0) {
        item.weeks.forEach(({ week, totalIncidents }) => {
          incidentArr.push(totalIncidents);
          weeksArr.push(week);
        });
      }
      perWeekGraphData.weeks = weeksArr;
      perWeekGraphData[item.bu] = incidentArr;
    });
  }
  return perWeekGraphData;
};
export const perMonthHelper = (inputData: PerMonthGraphDataType[]) => {
  const perMonthGraphData: IncidentsObjectType = {
    [BU.Mexico]: {
      currentMonth: {
        totalIncidents: 0,
      },
      previousMonth: {
        totalIncidents: 0,
      },
    },
    [BU.Ecuador]: {
      currentMonth: {
        totalIncidents: 0,
      },
      previousMonth: {
        totalIncidents: 0,
      },
    },
    [BU.Colombia]: {
      currentMonth: {
        totalIncidents: 0,
      },
      previousMonth: {
        totalIncidents: 0,
      },
    },
    [BU.Perú]: {
      currentMonth: {
        totalIncidents: 0,
      },
      previousMonth: {
        totalIncidents: 0,
      },
    },
    [BU.CAC]: {
      currentMonth: {
        totalIncidents: 0,
      },
      previousMonth: {
        totalIncidents: 0,
      },
    },
  };

  if (inputData.length !== 0) {
    inputData.forEach((item: any) => {
      if (item.months.length !== 0) {
        item.months.forEach((month: any, index: any) => {
          if (index === 0) {
            perMonthGraphData[item.bu].currentMonth
              .totalIncidents = month.totalIncidents;
          } else {
            perMonthGraphData[item.bu].previousMonth
              .totalIncidents = month.totalIncidents;
          }
        });
      }
    });
  }
  return perMonthGraphData;
};

export const perCategoryHelper = (inputData: PerCategoryGraphDataType[]) => {
  const perCategoryGraphData: IncidentsObjectType = {
    [DMS_CATEGORY.CRITICAL_SPEEDING]: {
      [BU.Mexico]: {
        totalIncidents: 0,
      },
      [BU.Ecuador]: {
        totalIncidents: 0,
      },
      [BU.Colombia]: {
        totalIncidents: 0,
      },
      [BU.Perú]: {
        totalIncidents: 0,
      },
      [BU.CAC]: {
        totalIncidents: 0,
      },
    },
    [DMS_CATEGORY.REGULAR_SPEEDING]: {
      [BU.Mexico]: {
        totalIncidents: 0,
      },
      [BU.Ecuador]: {
        totalIncidents: 0,
      },
      [BU.Colombia]: {
        totalIncidents: 0,
      },
      [BU.Perú]: {
        totalIncidents: 0,
      },
      [BU.CAC]: {
        totalIncidents: 0,
      },
    },
    [DMS_CATEGORY.CURVE_SPEEDING]: {
      [BU.Mexico]: {
        totalIncidents: 0,
      },
      [BU.Ecuador]: {
        totalIncidents: 0,
      },
      [BU.Colombia]: {
        totalIncidents: 0,
      },
      [BU.Perú]: {
        totalIncidents: 0,
      },
      [BU.CAC]: {
        totalIncidents: 0,
      },
    },
    [DMS_CATEGORY.HARSH_TURNING]: {
      [BU.Mexico]: {
        totalIncidents: 0,
      },
      [BU.Ecuador]: {
        totalIncidents: 0,
      },
      [BU.Colombia]: {
        totalIncidents: 0,
      },
      [BU.Perú]: {
        totalIncidents: 0,
      },
      [BU.CAC]: {
        totalIncidents: 0,
      },
    },
    [DMS_CATEGORY.SEATBELT]: {
      [BU.Mexico]: {
        totalIncidents: 0,
      },
      [BU.Ecuador]: {
        totalIncidents: 0,
      },
      [BU.Colombia]: {
        totalIncidents: 0,
      },
      [BU.Perú]: {
        totalIncidents: 0,
      },
      [BU.CAC]: {
        totalIncidents: 0,
      },
    },
  };

  if (inputData.length !== 0) {
    inputData.forEach((item: any) => {
      if (item.bus.length !== 0) {
        item.bus.forEach((bu: any) => {
          perCategoryGraphData[item.categoryName][bu.name]
            .totalIncidents = bu.totalIncidents;
        });
      }
    });
  }
  return perCategoryGraphData;
};

export function categorTitleByType(type: string, t: Function) {
  switch (type) {
    case DMS_CATEGORY.CRITICAL_SPEEDING:
      return t(TRANSLATIONS.CRITICAL_SPEEDING);
    case DMS_CATEGORY.REGULAR_SPEEDING:
      return t(TRANSLATIONS.REGULAR_SPEEDING);
    case DMS_CATEGORY.CURVE_SPEEDING:
      return t(TRANSLATIONS.CURVE_SPEEDING);
    case DMS_CATEGORY.HARSH_TURNING:
      return t(TRANSLATIONS.HARSH_TURNING);
    case DMS_CATEGORY.SEATBELT:
      return t(TRANSLATIONS.SEATBELT);
    default:
      return '';
  }
}

export function customDMSDataSelectorByYearMonthTypeBuAndSubType(
  driverManagementSystemData: any,
  year: number,
  month: string,
  type: DMSSelectorTypes,
  bu?: string,
  subType?: DMSSelectorSubTypes,
) {
  if (type === DMSSelectorTypes.tablePerBu && bu && subType) {
    return driverManagementSystemData.getIn(
      [year, month, type, bu, subType],
    )?.toJS();
  }
  if (type === DMSSelectorTypes.tablePerBu && bu) {
    return driverManagementSystemData.getIn(
      [year, month, type, bu],
    )?.toJS();
  }
  return driverManagementSystemData.getIn(
    [year, month, type],
  )?.toJS();
}

export function customDMSDataSelectorByYearMonthTypeAndSubType(
  driverManagementSystemData: any,
  year: number,
  month: string,
  type: DMSSelectorTypes,
  subType: DMSSelectorSubTypes,
) {
  return driverManagementSystemData.getIn(
    [year, month, type, subType],
  )?.toJS();
}

export function yearlyPayloadForIncidentsByMonthsCategoriesAndTypeData(
  initialState: any,
  incidentsByMonthsCategoriesAndTypeData: GetIncidentsByMonthsCategoriesAndTypeQuery,
  selectedMonth: string,
) {
  const payload = { ...initialState };
  const tableDataForZone: any = {};
  if (incidentsByMonthsCategoriesAndTypeData.getIncidentsBetweenRange) {
    payload[selectedMonth].perWeekGraph = perWeekGraphHelper(
      incidentsByMonthsCategoriesAndTypeData.getIncidentsBetweenRange as PerWeekGraphDataType[],
    );
  }
  if (incidentsByMonthsCategoriesAndTypeData.getIncidentsByMonths) {
    payload[selectedMonth].perMonthGraph = perMonthHelper(
      incidentsByMonthsCategoriesAndTypeData.getIncidentsByMonths as PerMonthGraphDataType[],
    );
  }
  if (incidentsByMonthsCategoriesAndTypeData.getIncidentsByCategories) {
    payload[selectedMonth].perCategoryGraph = perCategoryHelper(
      incidentsByMonthsCategoriesAndTypeData
        .getIncidentsByCategories as PerCategoryGraphDataType[],
    );
  }
  if (incidentsByMonthsCategoriesAndTypeData.topBest) {
    tableDataForZone.topBest = incidentsByMonthsCategoriesAndTypeData.topBest;
    payload[selectedMonth].table = tableDataForZone;
  }
  if (incidentsByMonthsCategoriesAndTypeData.topOffenders) {
    tableDataForZone.topOffenders = incidentsByMonthsCategoriesAndTypeData.topOffenders;
    payload[selectedMonth].table = tableDataForZone;
  }
  if (incidentsByMonthsCategoriesAndTypeData.topVehicleOffenders) {
    tableDataForZone
      .topVehicleOffenders = incidentsByMonthsCategoriesAndTypeData.topVehicleOffenders;
    payload[selectedMonth].table = tableDataForZone;
  }
  return payload;
}

export function yearlyPayloadForIncidentsByTypePerBuData(
  initialState: any,
  selectedMonthTableDataPerBu: any,
  incidentsByTypePerBuData: GetIncidentsByTypePerBuQuery,
  selectedMonth: string,
  selectedBu: string,
) {
  const payload = { ...initialState };
  const tableDataPerBu: any = {
    ...selectedMonthTableDataPerBu,
    [selectedBu]: {},
  };
  if (incidentsByTypePerBuData.topBest) {
    tableDataPerBu[selectedBu].topBest = incidentsByTypePerBuData.topBest;
    payload[selectedMonth].tablePerBu = tableDataPerBu;
  }
  if (incidentsByTypePerBuData.topOffenders) {
    tableDataPerBu[selectedBu].topOffenders = incidentsByTypePerBuData.topOffenders;
    payload[selectedMonth].tablePerBu = tableDataPerBu;
  }
  if (incidentsByTypePerBuData.topVehicleOffenders) {
    tableDataPerBu[
      selectedBu
    ].topVehicleOffenders = incidentsByTypePerBuData.topVehicleOffenders;
    payload[selectedMonth].tablePerBu = tableDataPerBu;
  }
  return payload;
}
