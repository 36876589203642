import request from 'utils/request';

const getCapexApi = async (url: string, type:string) => {
  try {
    const params = {
      type,
    };
    const res = await request.get(url, params);
    return (res);
  } catch (error) {
    console.log('getCapexAPI e()', error);
  }
};

export default getCapexApi;
