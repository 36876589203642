import React from 'react';
import Menu, { Item as MenuItem } from 'rc-menu';
import Dropdown from 'rc-dropdown';
import 'rc-dropdown/assets/index.css';
import './IncidentsFilterDropdown.scss';
import ChevronDown from '../../../../assets/icons/ChevronDown';

type Props = {
  onChange: (value: any) => void;
  selectedDropDownItem: any;
  dropDownItems: any[];
  dropDownOpen: boolean;
  setDropDownOpen: (opened: boolean) => void;
  labelClassName?: string;
  isLoading?: boolean;
  dropdownArrowColor?: string;
  itemLabelKey?: string;
  itemValueKey?: string;
  placeholder: string;
  multiple?: boolean;
  disabled?: boolean;
};

const IncidentsFilterDropdown = ({
  onChange,
  selectedDropDownItem,
  dropDownItems,
  dropDownOpen,
  setDropDownOpen,
  dropdownArrowColor = '#878787',
  itemLabelKey = 'name',
  itemValueKey = 'slug',
  placeholder = '',
  multiple = false,
  disabled = false,
}: Props) => {
  const menu = (
    <Menu
      onClick={onChange}
      multiple={multiple}
      selectedKeys={selectedDropDownItem?.map((e: any) => e?.[itemValueKey])}
      style={{ cursor: 'pointer' }}
    >
      {dropDownItems.map(item => {
        return (
          <MenuItem key={item[itemValueKey]}>
            {item?.img && <img alt="icon" src={item.img} style={{ marginRight: 10 }} />}{' '}
            {item?.[itemLabelKey]}
          </MenuItem>
        );
      })}
    </Menu>
  );

  const getLabel = () => {
    let label = placeholder;
    if (Array.isArray(selectedDropDownItem) && selectedDropDownItem?.length) {
      if (multiple) {
        label = `${placeholder} ${
          selectedDropDownItem.length > 1 ? ` +${selectedDropDownItem.length - 1}` : ''
        }`;
      } else {
        label = selectedDropDownItem?.[0]?.[itemLabelKey];
      }
    }

    return label;
  };

  return (
    <div
      className={`ifd-container ${selectedDropDownItem?.length ? 'ifd-container-selected' : ''} ${disabled ? 'ifd-container-disabled' : ''}`}
    >
      <Dropdown
        trigger={['click']}
        overlay={menu}
        animation="slide-up"
        visible={dropDownOpen && !disabled}
        onVisibleChange={setDropDownOpen}
      >
        <div
          className={`ifd-item-label ${
            selectedDropDownItem?.length ? 'ifd-item-label-selected' : ''
          }`}
        >
          <div>{getLabel()}</div>
          <div>
            <ChevronDown color={dropdownArrowColor} />
          </div>
        </div>
      </Dropdown>
    </div>
  );
};

export default IncidentsFilterDropdown;
