import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

import './DPOSummaryCardStyles.scss';
import { useSelector } from 'react-redux';
import { selectLocationsToAccess } from 'store/selectors/globalFilters.selectors';
import { useCubeQuery } from '@cubejs-client/react';
import EmptyState from 'components/EmptyState/EmptyState';
import LoadingSpinner from 'components/LoadingSpinner';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import request from '../../../../utils/request';
import MonthBar from './MonthBar';

export default function DPOSummaryCard() {
  const [isFirstHalf, setIsFirstHalf] = useState<boolean>(true);
  const [locationQuali, setLocationQuali] = useState('');

  const toggleIsFirstHalf = () => setIsFirstHalf(!isFirstHalf);

  const locationsToAccess = useSelector(selectLocationsToAccess);
  // const locationsToAccess = ['54'];
  const skip = !locationsToAccess.length;

  const hasMultipleLocations = locationsToAccess.length !== 1;

  const getLoc = async () => {
    if (hasMultipleLocations) return;
    const res = await request.post('/api/v1/last-qualification-value', {
      locationId: locationsToAccess[0],
    });
    setLocationQuali(res?.qualification);
  };

  useEffect(() => {
    getLoc();
    // eslint-disable-next-line
  }, [locationsToAccess, hasMultipleLocations]);

  const getDateRange = () => {
    // Todo get in touch with david to understand why thi static range why not dynamic
    // const currentDate = new Date();
    // const filter = ['', ''] as any[];
    // filter = [subDays(currentDate, 2), subDays(currentDate, 1)];
    return ['2021-01-01', '2021-07-01'];
  };

  const dateRange = getDateRange();

  const { resultSet: audits } = useCubeQuery({
    measures: [
      'Audits.avgScore',
    ],
    timeDimensions: [{
      dimension: 'Audits.audittime',
      granularity: 'month',
      dateRange,
    }],
    filters: [{
      dimension: 'Audits.locationId',
      operator: 'equals',
      values: locationsToAccess,
    }],
  }, { skip });
  const { resultSet: dailyAuditorAudits } = useCubeQuery({
    measures: ['Audits.avgScore'],
    timeDimensions: [{
      dimension: 'Audits.audittime',
      granularity: 'day',
    }],
    filters: [{
      dimension: 'Audits.isAuditor',
      operator: 'equals',
      values: ['1'],
    }, {
      dimension: 'Audits.locationId',
      operator: 'equals',
      values: locationsToAccess,
    }],
  }, { skip });
  const { resultSet: selfEvaluatioSrc } = useCubeQuery({
    measures: [
      'Audits.avgScore',
    ],
    timeDimensions: [{
      dimension: 'Audits.audittime',
      granularity: 'month',
      dateRange,
    }],
    filters: [{
      dimension: 'Audits.locationId',
      operator: 'equals',
      values: locationsToAccess,
    }, {
      dimension: 'Audits.isAuditor',
      operator: 'equals',
      values: ['NULL', '0'],
    }],
  }, { skip });
  const { resultSet: auditors } = useCubeQuery({
    measures: [
      'Audits.avgScore',
    ],
    timeDimensions: [{
      dimension: 'Audits.audittime',
      granularity: 'month',
      dateRange,
    }],
    filters: [{
      dimension: 'Audits.locationId',
      operator: 'equals',
      values: locationsToAccess,
    }, {
      dimension: 'Audits.isAuditor',
      operator: 'equals',
      values: ['1'],
    }],
  }, { skip });

  const auditData = audits?.series()[0]?.series;
  const hasLoaded = !!auditData;

  if (!hasLoaded || skip) {
    return (
      <div className="DPOSummaryCard" style={{ ...(hasMultipleLocations ? {} : { backgroundColor: 'white' }) }}>
        <LoadingSpinner />
      </div>
    );
  }

  const selfEvaluationData = selfEvaluatioSrc?.series()[0]?.series;
  const auditorData = auditors?.series()[0]?.series;

  let mainValue = 0;
  let auditorsValue = 0;
  let selfEvaluationValue = 0;
  let lastAudit = null;
  let lastAuditDate = new Date();

  if (hasLoaded && auditData) {
    mainValue = hasMultipleLocations ? (
      auditData?.reduce((a, c) => (c.value * 100) + a, 0) / (auditData?.length - 1)
    ) : (
      auditData?.reduce((a, { value }) => (value !== 0 ? (value * 100) : a), 0)
    );
    if (hasMultipleLocations && dailyAuditorAudits && auditorData) {
      auditorsValue = auditorData?.reduce(
        (a, c) => (c.value * 100) + a, 0,
        // @ts-ignore
      ) / (1);
    }
    if (hasMultipleLocations && selfEvaluationData) {
      // console.log('the ev: ', selfEvaluationData);
      selfEvaluationValue = selfEvaluationData?.reduce(
        (a, c) => (c.value * 100) + a, 0,
      ) / (1);
    }
  }

  let dateAudit = null;

  if (!hasMultipleLocations) {
    const auditorDataT = dailyAuditorAudits?.series()[0]?.series;
    lastAudit = auditorDataT && auditorDataT[0] && auditorDataT[0]?.value;
    dateAudit = auditorDataT && auditorDataT[0] && auditorDataT[0]?.category;
    lastAuditDate = dateAudit ? new Date(dateAudit) : new Date();
  }

  const chartData = {
    series: [
      {
        name: 'DPO',
        data: isFirstHalf ? [...(auditData?.map(s => s.value) || [])] : [0, 0, 0, 0, 0, 0],
      },
    ],

    options: {
      colors: ['#70AD47'],
      zoom: {
        enabled: true,
        autoScaleYaxis: true,
      },
      chart: {
        height: '50px',
        toolbar: {
          show: false,
        },
        // sparkline: {
        //   enabled: true,
        // },
        // height: 350,
        // type: 'line',
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [3, 3, 3],
        curve: 'straight',
        dashArray: [0, 4, 0],
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6,
        },
      },
      xaxis: {
        categories: isFirstHalf ? ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun'] : ['Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
        labels: {
          show: false,
          rotate: 0,
          style: {
          },
        },
      },
      yaxis: {
        floating: true,
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        labels: {
          show: false,
        },
        // opposite: true,
        // // tickAmount: 2,
        // decimalsInFloat: 0,
        // forceNiceScale: true,
      },
      legend: {
        // offsetY: 7,
      },
      tooltip: {
        y: [
          {
            title: {
              formatter(val: any) {
                return `${val}`;
              },
            },
          },
          {
            title: {
              formatter(val: any) {
                return `${val}`;
              },
            },
          },
        ],
      },
      grid: {
        borderColor: '#f1f1f1',
        padding: {
          top: 0,
          right: 25,
          bottom: 20,
          left: 25,
        },
      },
    },
  };

  const textToShow = (text: string) => {
    switch (text) {
      case 'NO QA':
        return ['¡Vamos a mejorar!', 'white', 'https://arvolution-safety-temp.s3.us-east-2.amazonaws.com/assets/icons/audit-values/no-qa.png'];
      case 'BR':
        return ['¡Vamos a mejorar!', 'white', 'https://arvolution-safety-temp.s3.us-east-2.amazonaws.com/assets/icons/audit-values/qa.png'];
      case 'QA':
        return ['¡Vamos a mejorar!', 'white', 'https://arvolution-safety-temp.s3.us-east-2.amazonaws.com/assets/icons/audit-values/route-to-st.png'];
      case 'RS':
        return ['¡Vamos a mejorar!', 'white', 'https://arvolution-safety-temp.s3.us-east-2.amazonaws.com/assets/icons/audit-values/st.png'];
      case 'ST':
        return ['¡Felicidades!', '#E4F2DA', 'https://arvolution-safety-temp.s3.us-east-2.amazonaws.com/assets/icons/audit-values/excelence.png'];

      default:
        return ['¡Vamos a mejorar!', 'white', 'https://arvolution-safety-temp.s3.us-east-2.amazonaws.com/assets/icons/audit-values/no-qa.png'];
    }
  };

  // const getBadge = (locations: any) => {
  //   switch (locations[0]) {
  //     case '70':
  //       return 'ST';
  //     case '102':
  //       return 'ST';
  //     default:
  //       return 'NO QA';
  //   }
  // };

  const levelBadge = locationQuali;

  return (
    <div className="DPOSummaryCard" style={{ ...(hasMultipleLocations ? {} : { backgroundColor: textToShow(levelBadge)[1] }) }}>
      {hasLoaded ? (
        <>
          <div className="flex-1">
            <div className="flex-1 card-container">
              <div className="card-header">
                <div>
                  <div className="sub-header">
                    {!hasMultipleLocations ? (
                      !isFirstHalf ? 0 : textToShow(levelBadge)[0]
                    ) : (
                      'Safety'
                    )}
                  </div>

                  {!hasMultipleLocations ? (
                    <div className="title-percentage" style={{ ...(hasMultipleLocations ? {} : { color: (mainValue > 73) ? 'green' : 'red' }) }}>
                      {isFirstHalf ? mainValue.toFixed(0) : 0}%
                    </div>
                  ) : (
                    <div className="evaluation-texts">
                      <div>
                        <p className="description">AUDITORIAS</p>
                        <p className="number">{auditorsValue.toFixed(0)}%</p>
                      </div>
                      <div>
                        <p className="description">AUTOEVAL.</p>
                        <p className="number" style={{ ...({ color: 'green' }) }}>{selfEvaluationValue.toFixed(0)}%</p>
                      </div>
                    </div>
                    // <div className="title-percentage" style={{ ...(hasMultipleLocations
                    // ? {} : { color: (mainValue > 73) ? 'green' : 'red' }) }}>
                    //   {isFirstHalf ? 0 : mainValue.toFixed(0)}%
                    // </div>
                  )}
                </div>
                <div>
                  {!hasMultipleLocations && (
                    <div className="badge-icon-container" style={{ justifyContent: 'center' }}>
                      <img
                        src={textToShow(levelBadge)[2]}
                        alt="New features will be here soon"
                        className="badge-icon"
                      />
                    </div>
                  )}
                  <div className="badge-icon-title" style={{ fontSize: 10, padding: 5 }}>
                    {hasMultipleLocations ? '' : levelBadge}
                  </div>
                </div>
              </div>
            </div>
            {/* @ts-ignore */}
            <Chart options={chartData.options} series={chartData.series} type="line" height="120px" />
            <div className="flex-1 card-container">
              <MonthBar
                isFirstHalf={isFirstHalf}
                auditData={auditData}
                hasMultipleLocations={hasMultipleLocations}
                lastAudit={lastAudit}
                lastAuditDate={lastAuditDate}
              />
            </div>
          </div>
          <div className="footer-text" style={{ justifyContent: 'space-between', padding: '0px 15px' }}>
            {(hasMultipleLocations) ? <p /> : (
              dateAudit ? (
                <p style={{ lineHeight: '23px' }}>
                  Últ. auditoría, {format(lastAuditDate, 'd \'de\' LLLL \'de\' yyy', { locale: es })} <span style={{ color: '#4472C4', fontWeight: 'bold' }}>{lastAudit ? (lastAudit * 100).toFixed(0) : ''}%</span>
                </p>
              ) : ''
            )}
            <div
              className="switch"
            >
              <div
                className={`switch-item ${isFirstHalf ? 'selected' : ''}`}
                role="button"
                onClick={toggleIsFirstHalf}
              >
                <p>H1</p>
              </div>
              <div
                className={`switch-item ${isFirstHalf ? '' : 'selected'}`}
                role="button"
                onClick={toggleIsFirstHalf}
              >
                <p>H2</p>
              </div>
            </div>
          </div>
        </>
      ) : levelBadge === '' ? (
        <LoadingSpinner height={100} />
      ) : <EmptyState />}
    </div>
  );
}
