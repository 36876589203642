/* eslint-disable max-len */
import BasicButton from 'components/Button/BasicButton';
import LoadingSpinner from 'components/LoadingSpinner';
import FormHeader from 'containers/FormHeader';
import React, { useState } from 'react';
import { COLOR_CODE } from 'types/enums';

const AnonymousTruckScann = () => {
  const Separator = () => (
    <div style={{
      backgroundColor: '#F2F2F2',
      height: 10,
      marginTop: 10,
      marginLeft: -15,
      marginRight: -15,
    }}
    />
  );
  const questions = [{
    header: '¿Qué tipo de negocio manejas?',
    options: [{
      label: 'Abarrotes',
    }, {
      label: 'Miscelánea',
    }, {
      label: 'Fonda social',
    }, {
      label: 'Restaurante',
    }, {
      label: 'Bar/Antro',
    }, {
      label: 'Hotel',
    }],
  }, {
    header: '¿Cuánto tiempo tienes con tu negocio?',
    options: [{
      label: 'Menos de 6 meses',
    }, {
      label: 'De 1 a 3 años',
    }, {
      label: 'Más de 5 años',
    }],
  }, {
    header: '¿Con qué periodicidad compras nuestros productos?',
    options: [{
      label: 'Semanal',
    }, {
      label: 'Quincenal',
    }, {
      label: 'Mensual',
    }],
  }];
  const initialQuestionsValue = '';
  const [selectedQuestions, setSelectedQuestion] = useState(() => new Array(questions.length).fill(initialQuestionsValue));
  const [isSavingInfo, setIsSavingInfo] = useState(false);

  const onContinuePress = () => {
    setIsSavingInfo(true);
    setTimeout(() => {
      window.location.href = 'https://clientesab-inbev.com/?country=MEX&origin=onTap';
    }, 1000);
  };

  return (
    <div className="container anonymous-truck-scann">
      <div className="row">
        <div className="col-md-6 offset-md-3" style={{ textAlign: 'left', backgroundColor: 'white' }}>
          <FormHeader
            containerClassName="justify-start indent-1"
            alternativeLabel="Vende nuestras marcas"
            hideSettingsLabel
          />
          { questions.map((question, questionIndex) => (
            <>
              <p style={{ paddingTop: 20 }}>{ question.header }</p>
              { question.options.map(option => {
                const isSelected = selectedQuestions[questionIndex] === option.label;
                return (
                  <div
                    style={{
                      padding: 10,
                      borderRadius: 10,
                      backgroundColor: isSelected ? COLOR_CODE.PRIMARY : '#F0F0F0',
                      marginTop: 5,
                    }}
                    onClick={() => {
                      const newArray = [...selectedQuestions];
                      newArray[questionIndex] = option.label;
                      setSelectedQuestion(newArray);
                    }}
                    role="button"
                  >
                    <p
                      style={{
                        color: isSelected ? 'white' : '#878787',
                      }}
                    >{ option.label }
                    </p>
                  </div>
                );
              }) }
              { questionIndex !== (questions.length - 1) && <Separator /> }
            </>
          ))}
          <p style={{ marginTop: 25 }}>
            Al dar click en continuar te redirigiremos al portal de Grupo Modelo para darte de alta. Ten a la mano la siguiente documentación:
          </p>
          <p style={{ marginTop: 5 }}>
            - INE o Pasaporte Mexicano
          </p>
          <p>
            - En caso de ser extranjero, Tarjeta de residente extranjero.
          </p>
          <div
            style={{ textAlign: 'center' }}
          >
            <img
              style={{ height: '90px', marginTop: '20px' }}
              src="https://arvolution-safety-temp.s3.us-east-2.amazonaws.com/assets/bees-logo.png"
              alt=""
            />
          </div>

          <h4 style={{ marginTop: 20, textAlign: 'center' }}>¡AYUDÁNDOTE A CRECER!</h4>
          <div style={{ textAlign: 'center', paddingTop: 10 }}>
            { isSavingInfo ? (
              <LoadingSpinner style={{ margin: 5, marginBottom: 45 }} />
            ) : (
              <BasicButton disabled={selectedQuestions.includes(initialQuestionsValue)} text="Continuar" onClick={onContinuePress} style={{ width: '100%' }} />
            )}
          </div>
          <p style={{ paddingBottom: 100, paddingTop: 20 }}>
            Si tienes alguna duda comunícate a Modelo Amigo <a style={{ color: '#2574FB' }} href="tel:018004663356">01 800 466 3356</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default AnonymousTruckScann;
